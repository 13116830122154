import PrescriptionSearchPatientForm from 'components/Common/Prescription/prescription-search-patient-form';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { getAccountDetail } from 'store/actions';
import { getFullName } from 'helpers/utils';

const PatientInformation = ({ onSelectPatient }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const { accountCode } = useParams();

    const [showPatientModal, setShowPatientModal] = useState(false);

    const accountDetail = useSelector(state => state.accounts.accountDetail);

    useEffect(() => {
        if (accountCode) {
            dispatch(getAccountDetail({ accountCode: accountCode }));
        }
    }, [accountCode]);

    const selectPatient = patient => {
        onSelectPatient(patient);
        dispatch(getAccountDetail({ accountCode: patient.accountCode }));
        setShowPatientModal(false);
    }

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    {accountDetail && accountDetail.firstName ? (
                        <React.Fragment>
                            <Row>
                                <Col sm={12}>
                                    <h4>{t('Patient')}</h4>
                                </Col>
                            </Row>
                            <Row className="align-middle">
                                {/*<Col sm={2}>
                            <img
                              src={accountDetail.picture}
                              onError={evt => (
                                (evt.target.onerror = null),
                                (evt.target.src = image)
                              )}
                            />
                          </Col>*/}
                                <Col sm={8}>
                                    <div>
                                        <span>{t('Name')}: </span>
                                        <strong>
                                            {getFullName(accountDetail.firstName,
                                            accountDetail.secondName,
                                            accountDetail.firstSurname,
                                            accountDetail.secondSurname)}
                                        </strong>
                                    </div>
                                    <div>
                                        <span>{t('Identification')}: </span><strong>{accountDetail.identification}</strong>
                                    </div>
                                    <div>
                                        <span>{t('Email')}: </span><strong>{accountDetail.email}</strong>
                                    </div>
                                    <div>
                                        <span>{t('Phone')}: </span><strong>{accountDetail.mainPhone}</strong>
                                    </div>
                                </Col>
                            </Row>
                        </React.Fragment>
                    ) : null}
                    <Row className="mt-1">
                        <Col>
                            <Button type="button" color="primary" className="button" onClick={() => { setShowPatientModal(true) }}>
                                <i className="mdi mdi-contacts me-1"></i>
                                {t("Select Patient")}
                            </Button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            {showPatientModal ? (
                <PrescriptionSearchPatientForm
                    isOpen={showPatientModal}
                    selectPatient={selectPatient}
                    onCancel={() => {
                        setShowPatientModal(false);
                    }}
                />
            ) : null}
        </React.Fragment>
    );
}

PatientInformation.propTypes = {
    onSelectPatient: PropTypes.func.isRequired
}

export default PatientInformation;