import { GET_IDENTIFICATION_TYPES_LIST, GET_IDENTIFICATION_TYPES_LIST_FAIL, GET_IDENTIFICATION_TYPES_LIST_SUCCESS } from "./actionTypes"

//Benefit Categories
export const getIdentificationTypes = () => ({
    type: GET_IDENTIFICATION_TYPES_LIST
})

export const getIdentificationTypesSuccess = response => ({
    type: GET_IDENTIFICATION_TYPES_LIST_SUCCESS,
    payload: response,
})

export const getIdentificationTypesFail = error => ({
    type: GET_IDENTIFICATION_TYPES_LIST_FAIL,
    payload: error,
})