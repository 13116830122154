import { ALERT_MESSAGE } from 'constants/constants';
import { DEFAULT_COMPANY } from 'constants/list-types';
import TermForm from 'pages/Management/Commons/term-form/term-form';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { addTerm, clearTerm, showSuccessAlert } from 'store/actions';

const AddTerm = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const termCreated = useSelector(state => state.Terms.termCreated);

    useEffect(() => {
        if(termCreated) {
            dispatch(showSuccessAlert(ALERT_MESSAGE.SAVED, () => {
                dispatch(clearTerm());
                history.push(`/terms-list`);
            }));
        }
        
    }, [termCreated]);

    const onSubmit = (formData) => {
        const data = {...formData};
        data.companyId = DEFAULT_COMPANY;
       // data.description = Buffer.from(data.description).toString('base64')
        data.isDefault = data.isDefault ? 'Y' : 'N';
        dispatch(addTerm(data));
    }

    return ( 
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t('Title App')}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h2 className="mb-2 font-size-18">{t('New Term')}</h2>
                    </div>
                    
                    <Row>
                        <TermForm 
                            onSubmit={onSubmit}
                        />
                    </Row>
                </Container>
            </div> 
        </React.Fragment> 
    );
}
 
export default AddTerm;