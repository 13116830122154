import {
  GET_FEE_LIST,
  GET_FEE_LIST_FAIL,
  GET_FEE_LIST_SUCCESS,
  SAVE_FEE_LIST,
  SAVE_FEE_LIST_FAIL,
  SAVE_FEE_LIST_SUCCESS
} from "./actionTypes"

//Medical Center List
export const getFeeList = (request) => ({
  type: GET_FEE_LIST,
  payload: request
})

export const getFeeListSuccess = response => ({
  type: GET_FEE_LIST_SUCCESS,
  payload: response,
})

export const getFeeListFail = error => ({
  type: GET_FEE_LIST_FAIL,
  payload: error,
})

export const saveFeeList = (request) => ({
  type: SAVE_FEE_LIST,
  payload: request
})

export const saveFeeListSuccess = response => ({
  type: SAVE_FEE_LIST_SUCCESS,
  payload: response,
})

export const saveFeeListFail = error => ({
  type: SAVE_FEE_LIST_FAIL,
  payload: error,
})
