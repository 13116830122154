/* PAYMENT_METHODS */
export const CLEAR_PAYMENT_METHODS_STATE = "CLEAR_PAYMENT_METHODS_STATE" 

export const GET_PAYMENT_METHOD_LIST = "GET_PAYMENT_METHOD_LIST"
export const GET_PAYMENT_METHOD_LIST_FAIL = "GET_PAYMENT_METHOD_LIST_FAIL"
export const GET_PAYMENT_METHOD_LIST_SUCCESS = "GET_PAYMENT_METHOD_LIST_SUCCESS"

export const GET_PAYMENT_METHOD_DETAIL = "GET_PAYMENT_METHOD_DETAIL"
export const GET_PAYMENT_METHOD_DETAIL_FAIL = "GET_PAYMENT_METHOD_DETAIL_FAIL"
export const GET_PAYMENT_METHOD_DETAIL_SUCCESS = "GET_PAYMENT_METHOD_DETAIL_SUCCESS"

export const ADD_PAYMENT_METHOD = "ADD_PAYMENT_METHOD"
export const ADD_PAYMENT_METHOD_FAIL = "ADD_PAYMENT_METHOD_FAIL"
export const ADD_PAYMENT_METHOD_SUCCESS = "ADD_PAYMENT_METHOD_SUCCESS"

export const UPDATE_PAYMENT_METHOD = "UPDATE_PAYMENT_METHOD"
export const UPDATE_PAYMENT_METHOD_FAIL = "UPDATE_PAYMENT_METHOD_FAIL"
export const UPDATE_PAYMENT_METHOD_SUCCESS = "UPDATE_PAYMENT_METHOD_SUCCESS"

export const DELETE_PAYMENT_METHOD = "DELETE_PAYMENT_METHOD"
export const DELETE_PAYMENT_METHOD_FAIL = "DELETE_PAYMENT_METHOD_FAIL"
export const DELETE_PAYMENT_METHOD_SUCCESS = "DELETE_PAYMENT_METHOD_SUCCESS"
