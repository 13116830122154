import { ALERT_MESSAGE } from "constants/constants"
import { 
  httpGetQuestionsByType,
  httpGetQuestions,
  httpGetQuestionDetail,
  httpAddQuestion,
  httpUpdateQuestion,
  httpDeleteQuestion,
} from "helpers/Api/api-helper"
import { call, put, takeEvery } from "redux-saga/effects"
import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions"
import { 
  getQuestionsByTypeFail, 
  getQuestionsByTypeSuccess,
  getQuestionsSuccess,
  getQuestionsFail,
  getQuestionDetailSuccess,
  getQuestionDetailFail,
  addQuestionSuccess,
  addQuestionFail,
  updateQuestionSuccess,
  updateQuestionFail,
  deleteQuestionSuccess,
  deleteQuestionFail
} from "./actions"
import { 
  GET_QUESTIONS_BY_TYPE,
  ADD_QUESTION,
  DELETE_QUESTION,
  GET_QUESTION_DETAIL,
  GET_QUESTION_LIST,
  UPDATE_QUESTION
 } from "./actionTypes"


function* getQuestionsByType(request) {
    try {
      yield put(showLoadingAlert())
      const response = yield call(httpGetQuestionsByType, request.payload)
      yield put(hideLoadingAlert())
      yield put(getQuestionsByTypeSuccess(response))
    } catch (error) {
      yield put(hideLoadingAlert())
      yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
      yield put(getQuestionsByTypeFail(error))
    }
}

function* getQuestions(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetQuestions, request.payload)
    yield put(hideLoadingAlert())
    yield put(getQuestionsSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getQuestionsFail(error))
  }
}

function* getQuestionDetail(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetQuestionDetail, request.payload)
    yield put(hideLoadingAlert())
    yield put(getQuestionDetailSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_DETAIL_ERROR))
    yield put(getQuestionDetailFail(error))
  }
}

function* addQuestion(request) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.SAVING))
    const response = yield call(httpAddQuestion, request.payload)
    yield put(hideLoadingAlert())
    yield put(addQuestionSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.ADD_ERROR))
    yield put(addQuestionFail(error))
  }
}

function* updateQuestion(request) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.SAVING))
    const response = yield call(httpUpdateQuestion, request.payload)
    yield put(hideLoadingAlert())
    yield put(updateQuestionSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.UPDATE_ERROR))
    yield put(updateQuestionFail(error))
  }
}

function* deleteQuestion(request) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.DELETING))
    const response = yield call(httpDeleteQuestion, request.payload)
    yield put(hideLoadingAlert())
    yield put(deleteQuestionSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.DELETE_ERROR))
    yield put(deleteQuestionFail(error))
  }
}

function* questionsSaga() {
    yield takeEvery(GET_QUESTIONS_BY_TYPE, getQuestionsByType)
    yield takeEvery(GET_QUESTION_LIST, getQuestions)
    yield takeEvery(GET_QUESTION_DETAIL, getQuestionDetail)
    yield takeEvery(ADD_QUESTION, addQuestion)
    yield takeEvery(UPDATE_QUESTION, updateQuestion)
    yield takeEvery(DELETE_QUESTION, deleteQuestion)
  }
  
  export default questionsSaga