import PageBody from 'components/Common/PageLayout/page-body';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import PromotionsList from './Components/promotions-list';

const PromotionsListPage = () => {

    const { t } = useTranslation();
    const history = useHistory();

    return (
        <React.Fragment>
            <PageBody
                title='Promotions List'
                actions={(
                    <React.Fragment>
                        <Button
                            color="primary"
                            className="button"
                            onClick={() => {
                                history.push("/management-promotions-add");
                            }}
                        >
                            <i className="mdi mdi-plus-circle me-1"></i>
                            {t("New Promotion")}
                        </Button>
                        <Button
                            color="light"
                            className="button"
                            onClick={() => {
                                history.push("/dashboard");
                            }}
                        >
                            <i className="mdi mdi-arrow-left me-1"></i>
                            {t("Back")}
                        </Button>
                    </React.Fragment>
                )}
            >

                <PromotionsList />
            </PageBody>
        </React.Fragment>
    );
}

export default PromotionsListPage;