import PageBody from 'components/Common/PageLayout/page-body';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { getSampleCollectList } from 'store/actions';
import SampleCollectsList from './Components/sample-collect-list';

const SampleCollectListPage = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getSampleCollectList({ 
            appointmentType: 'LAB',
            pickupType: 'HOM',
            pickupStatus: 'ALL'
         }));
    }, []);

    return (
        <React.Fragment>
            <PageBody
                title='Sample to Collect Inbox'
                actions={(
                    <Button
                        color="light"
                        className="button"
                        onClick={() => {
                            history.push("/dashboard");
                        }}
                    >
                        <i className="mdi mdi-arrow-left me-1"></i>
                        {t("Back")}
                    </Button>
                )}
            >
                <SampleCollectsList />
            </PageBody>
        </React.Fragment>
    );
}

export default SampleCollectListPage;