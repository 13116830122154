/* DELIVERY PACKAGE LIST */
export const GET_SAMPLE_COLLECT_LIST = "GET_SAMPLE_COLLECT_LIST"
export const GET_SAMPLE_COLLECT_LIST_FAIL = "GET_SAMPLE_COLLECT_LIST_FAIL"
export const GET_SAMPLE_COLLECT_LIST_SUCCESS = "GET_SAMPLE_COLLECT_LIST_SUCCESS"

/* DELIVERY PACKAGE LIST */
export const GET_SAMPLE_COLLECT_DETAIL = "GET_SAMPLE_COLLECT_DETAIL"
export const GET_SAMPLE_COLLECT_DETAIL_FAIL = "GET_SAMPLE_COLLECT_DETAIL_FAIL"
export const GET_SAMPLE_COLLECT_DETAIL_SUCCESS = "GET_SAMPLE_COLLECT_DETAIL_SUCCESS"

/* DELIVERY PACKAGE UPDATE STATUS*/
export const UPDATE_SAMPLE_PICKUP_STATUS = "UPDATE_SAMPLE_PICKUP_STATUS"
export const UPDATE_SAMPLE_PICKUP_STATUS_SUCCESS = "UPDATE_SAMPLE_PICKUP_STATUS_SUCCESS"
export const UPDATE_SAMPLE_PICKUP_STATUS_FAIL = "UPDATE_SAMPLE_PICKUP_STATUS_FAIL"
