export const menu = [
    {
        "id": "portal_administrator",
        "module": "Security",
        "color": "warning",
        "options": [
            { 
                "id": "menu_add_users", 
                "description": "Register User", 
                "iconClass": "bx bx-user-plus",
                "url": "/security-register-user",
                "visibleIn": "D"
            },
            { 
                "id": "menu_users", 
                "description": "Users", 
                "iconClass": "bx bxs-user-detail",
                "url": "/security-user",
                "visibleIn": "B"
            }, 
            { 
                "id": "menu_groups",
                "description": "Groups", 
                "iconClass": "bx bx-comment-x",
                "url": "/security-groups",
                "visibleIn": "B"
            }     
        ]
    },
    {
        "id": "portal_management_admin",
        "module": "Membership",
        "color": "success",
        "options": [
            { 
                "id": "menu_affiliateds",
                "description": "Affiliates", 
                "iconClass": "bx bx-user",
                "url": "/membership-customer-search",
                "visibleIn": "B"
            },
            { 
                "id": "membership-transactions",
                "description": "Transactions", 
                "iconClass": "bx bx-cart",
                "url": "/membership-transactions",
                "visibleIn": "M"
            },
            { 
                "id": "menu_memberships", 
                "description": "AdminMemberships", 
                "iconClass": "bx bx-gift",
                "url": "/membership-package",
                "visibleIn": "B"
            },
            { 
                "id": "menu_memberships_list", 
                "description": "ListMemberships", 
                "iconClass": "bx bx-gift",
                "url": "/membership-list",
                "visibleIn": "B"
            },
            { 
                "id": "menu_terms",
                "description": "Terms", 
                "iconClass": "bx bx-file",
                "url": "/terms-list",
                "visibleIn": "M"
            },
            { 
                "id": "menu_promotions",
                "description": "Ads", 
                "iconClass": "mdi mdi-gift",
                "url": "/management-promotions-list",
                "visibleIn": "M"
            },
            { 
                "id": "menu_promotion_notifications",
                "description": "Create Promotion Notification", 
                "iconClass": "mdi mdi-gift",
                "url": "/management-promotion-notification",
                "visibleIn": "M"
            },
            { 
                "id": "menu_service_catalog",
                "description": "Service Catalog Inbox", 
                "iconClass": "mdi mdi-package-variant-closed",
                "url": "/service-catalog",
                "visibleIn": "M"
            }
        ]
    },    
    {
        "id": "portal_management_admin_report",
        "module": "Reports",
        "color": "success",
        "options": [
            { 
                "id": "menu_memberships",
                "description": "Report MasterCard", 
                "iconClass": "bx bx-cart",
                "url": "/report/purchases",
                "visibleIn": "M"
    },
        ]
    },   
    {
        "id": "portal_autoquoter",
        "module": "autoquoter",
        "color": "info",
        "options": [
            { 
                "id": "menu_question_membership",
                "description": "Grupos de preguntas", 
                "iconClass": "bx bx-calculator",
                "url": "/question-membership-list",
                "visibleIn": "M"
            },
            { 
                "id": "menu_question_list",
                "description": "Questions and Answers Memberships", 
                "iconClass": "bx bx-question-mark",
                "url": "/questions-list",
                "visibleIn": "M"
            }
        ]
    },   
    {
        "id": "portal_doctor_admin",
        "module": "doctors",
        "color": "primary",
        "options": [
            {
                "id": "menu_medical_patients",
                "description": "Patients",
                "iconClass": "bx bxs-user-detail",
                "url": "/medical-patients",
                "visibleIn": "M"
            },
            { 
                "id": "menu_laboratory_historical_exam",
                "description": "Exams Historical", 
                "iconClass": "mdi mdi-clipboard-pulse",
                "url": "/laboratory-patient-list",
                "visibleIn": "B"
            },
            { 
                "id": "menu_medical_prescription_templates",
                "description": "Prescription Templates", 
                "iconClass": "mdi mdi-clipboard-text-multiple",
                "url": "/medical-prescription-templates",
                "visibleIn": "M"
            },
            { 
                "id": "menu_medical_add_prescription",
                "description": "Register Prescription", 
                "iconClass": "mdi mdi-clipboard-text-multiple",
                "url": "/medical-add-prescription",
                "visibleIn": "M"
            },
            { 
                "id": "menu_medical_prescription_historical",
                "description": "Prescription Historical", 
                "iconClass": "mdi mdi-clipboard-plus",
                "url": "/medical-prescription-search",
                "visibleIn": "M"
            },

            { 
                "id": "menu_laboratory_services", 
                "description": "Laboratory", 
                "iconClass": "mdi mdi-medical-bag",
                "url": "/laboratory-service-list",
                "visibleIn": "B"
            },
            { 
                "id": "menu_pharmacy_product_search", 
                "title": "Pharmacy", 
                "description": "Pharmacy Product Catalog", 
                "iconClass": "mdi mdi-medical-bag",
                "url": "/pharmacy-product-search",
                "visibleIn": "B"
            }
        ]
    },
    {
        "id": "portal_pharmacy_admin",
        "module": "Pharmacy Management",
        "color": "info",
        "options": [
            { 
                "id": "menu_pharmacy_prescription_templates",
                "description": "Prescription Templates", 
                "iconClass": "mdi mdi-clipboard-text-multiple",
                "url": "/medical-prescription-templates",
                "visibleIn": "M"
            },
            { 
                "id": "menu_pharmacy_add_prescription",
                "description": "Register Prescription", 
                "iconClass": "mdi mdi-clipboard-text-multiple",
                "url": "/medical-add-prescription",
                "visibleIn": "M"
            },
            { 
                "id": "menu_pharmacy_prescription_historical",
                "description": "Prescription Historical", 
                "iconClass": "mdi mdi-clipboard-plus",
                "url": "/medical-prescription-search",
                "visibleIn": "M"
            },
            { 
                "id": "menu_related_products",
                "description": "Related Products", 
                "iconClass": "mdi mdi-family-tree",
                "url": "/pharmacy-related-products",
                "visibleIn": "M"
            },
            { 
                "id": "menu_pharmacy_fee_list",
                "description": "Pharmacy Fee List", 
                "iconClass": "mdi mdi-package-variant-closed",
                "url": "/pharmacy-fee-range",
                "visibleIn": "M"
            },
            { 
                "id": "menu_delivery_packages_list",
                "description": "Delivery Packages Inbox", 
                "iconClass": "mdi mdi-package-variant-closed",
                "url": "/delivery-packages-list",
                "visibleIn": "M"
            },
            { 
                "id": "menu_medical_add_prescription",
                "description": "Register Prescription", 
                "iconClass": "mdi mdi-clipboard-text-multiple",
                "url": "/medical-add-prescription",
                "visibleIn": "M"
            }
        ]
    },
    {
        "id": "portal_laboratory_admin",
        "module": "Laboratory Management",
        "color": "info",
        "options": [
            { 
                "id": "menu_laboratory_prescription_templates",
                "description": "Order Templates", 
                "iconClass": "mdi mdi-clipboard-text-multiple",
                "url": "/laboratory-prescription-templates",
                "visibleIn": "M"
            },
            { 
                "id": "menu_laboratory_add_prescription",
                "description": "Register Order", 
                "iconClass": "mdi mdi-clipboard-text-multiple",
                "url": "/laboratory-add-prescription",
                "visibleIn": "M"
            },
            { 
                "id": "menu_laboratory_prescription_historical",
                "description": "Orders Historical", 
                "iconClass": "mdi mdi-clipboard-plus",
                "url": "/laboratory-prescription-search",
                "visibleIn": "M"
            },
            { 
                "id": "menu_laboratory_fee_range",
                "description": "Laboratory Fee List", 
                "iconClass": "mdi mdi-package-variant-closed",
                "url": "/laboratory-fee-range",
                "visibleIn": "M"
            },
            { 
                "id": "menu_sample_collect_list",
                "description": "Sample to Collect Inbox", 
                "iconClass": "mdi mdi-package-variant-closed",
                "url": "/sample-collect-list",
                "visibleIn": "M"
            }
        ]
    }
];