import React, { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Input, InputGroup } from "reactstrap";

const ProductSearchBox = ({
  onSearch,
  onClear,
  placeholder = "Type for search",
}) => {
  const { t } = useTranslation();
  const [searchKey, setSearchKey] = useState("");

  const keyPress = e => {
    const { key } = e;
    setSearchKey(e.target.value);
    if (key === "Enter") {
      onSearch(searchKey);
    }
  };

  const onChange = e => {
    onClear();
  };

  return (
    <React.Fragment>
      <div className="py-1">
        <div className="">
          <InputGroup>
            <Input
              autoComplete="off"
              onKeyUp={keyPress}
              onChange={onChange}
              id="search-user"
              type="text"
              className="form-control"
              placeholder={t(placeholder) + "..."}
            />
            <div
              className="input-group-text dashboard-option bg-primary"
              onClick={() => {
                onSearch(searchKey);
              }}
            >
              <i className="bx bx-search-alt search-icon text-white" />
            </div>
          </InputGroup>
        </div>
      </div>
    </React.Fragment>
  );
};

ProductSearchBox.propTypes = {
  onSearch: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

export default ProductSearchBox;
