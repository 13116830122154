import {
  ADD_SERVICE_PRESCRIPTION,
  ADD_SERVICE_PRESCRIPTION_FAIL,
  ADD_SERVICE_PRESCRIPTION_SUCCESS,
  CLEAR_SERVICE_PRESCRIPTION_STATE,
  DELETE_SERVICE_PRESCRIPTION,
  DELETE_SERVICE_PRESCRIPTION_FAIL,
  DELETE_SERVICE_PRESCRIPTION_SUCCESS,
  GET_SERVICE_PRESCRIPTION_TEMPLATE_LIST,
  GET_SERVICE_PRESCRIPTION_TEMPLATE_LIST_FAIL,
  GET_SERVICE_PRESCRIPTION_TEMPLATE_LIST_SUCCESS,
  GET_SERVICE_PRESCRIPTION_DETAIL,
  GET_SERVICE_PRESCRIPTION_DETAIL_FAIL,
  GET_SERVICE_PRESCRIPTION_DETAIL_SUCCESS,
  GET_SERVICE_PRESCRIPTION_LIST,
  GET_SERVICE_PRESCRIPTION_LIST_FAIL,
  GET_SERVICE_PRESCRIPTION_LIST_SUCCESS,
  UPDATE_SERVICE_PRESCRIPTION,
  UPDATE_SERVICE_PRESCRIPTION_FAIL,
  UPDATE_SERVICE_PRESCRIPTION_SUCCESS,
  UPLOAD_PDF_SERVICE_PRESCRIPTION,
  UPLOAD_PDF_SERVICE_PRESCRIPTION_SUCCESS,
  UPLOAD_PDF_SERVICE_PRESCRIPTION_FAIL,
} from "./actionTypes";

//Terms
export const clearServicePrescription = () => ({
  type: CLEAR_SERVICE_PRESCRIPTION_STATE,
});

export const getServicePrescriptionTemplateList = request => ({
  type: GET_SERVICE_PRESCRIPTION_TEMPLATE_LIST,
  payload: request,
});

export const getServicePrescriptionTemplateListSuccess = response => ({
  type: GET_SERVICE_PRESCRIPTION_TEMPLATE_LIST_SUCCESS,
  payload: response,
});

export const getServicePrescriptionTemplateListFail = error => ({
  type: GET_SERVICE_PRESCRIPTION_TEMPLATE_LIST_FAIL,
  payload: error,
});

export const getServicePrescriptionList = request => ({
  type: GET_SERVICE_PRESCRIPTION_LIST,
  payload: request,
});

export const getServicePrescriptionListSuccess = response => ({
  type: GET_SERVICE_PRESCRIPTION_LIST_SUCCESS,
  payload: response,
});

export const getServicePrescriptionListFail = error => ({
  type: GET_SERVICE_PRESCRIPTION_LIST_FAIL,
  payload: error,
});

//Prescription Detail
export const getServicePrescriptionDetail = request => ({
  type: GET_SERVICE_PRESCRIPTION_DETAIL,
  payload: request,
});

export const getServicePrescriptionDetailSuccess = response => ({
  type: GET_SERVICE_PRESCRIPTION_DETAIL_SUCCESS,
  payload: response,
});

export const getServicePrescriptionDetailFail = error => ({
  type: GET_SERVICE_PRESCRIPTION_DETAIL_FAIL,
  payload: error,
});

//Add Prescription
export const addServicePrescription = request => ({
  type: ADD_SERVICE_PRESCRIPTION,
  payload: request,
});

export const addServicePrescriptionSuccess = response => ({
  type: ADD_SERVICE_PRESCRIPTION_SUCCESS,
  payload: response,
});

export const addServicePrescriptionFail = error => ({
  type: ADD_SERVICE_PRESCRIPTION_FAIL,
  payload: error,
});

//Update Prescription
export const updateServicePrescription = request => ({
  type: UPDATE_SERVICE_PRESCRIPTION,
  payload: request,
});

export const updateServicePrescriptionSuccess = response => ({
  type: UPDATE_SERVICE_PRESCRIPTION_SUCCESS,
  payload: response,
});

export const updateServicePrescriptionFail = error => ({
  type: UPDATE_SERVICE_PRESCRIPTION_FAIL,
  payload: error,
});

//Upload Pdf Prescription
export const uploadPdfServicePrescription = request => ({
  type: UPLOAD_PDF_SERVICE_PRESCRIPTION,
  payload: request,
});

export const uploadPdfServicePrescriptionSuccess = response => ({
  type: UPLOAD_PDF_SERVICE_PRESCRIPTION_SUCCESS,
  payload: response,
});

export const uploadPdfServicePrescriptionFail = error => ({
  type: UPLOAD_PDF_SERVICE_PRESCRIPTION_FAIL,
  payload: error,
});

//Delete Prescription
export const deleteServicePrescription = request => ({
  type: DELETE_SERVICE_PRESCRIPTION,
  payload: request,
});

export const deleteServicePrescriptionSuccess = response => ({
  type: DELETE_SERVICE_PRESCRIPTION_SUCCESS,
  payload: response,
});

export const deleteServicePrescriptionFail = error => ({
  type: DELETE_SERVICE_PRESCRIPTION_FAIL,
  payload: error,
});

