import PageBody from 'components/Common/PageLayout/page-body';
import { ALERT_MESSAGE } from 'constants/constants';
import BeneficiaryForm from 'pages/Management/Commons/beneficiary-form/beneficiary-form';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Row } from 'reactstrap';
import { clearAffiliatedBeneficiaries, getAffiliatedBeneficiary, showSuccessAlert, updateAffiliatedBeneficiary } from 'store/actions';

const EditBeneficiary = () => {

    const { id, membershipAffiliatedId, account } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const beneficiary = useSelector(state => state.affiliatedBeneficiaries.beneficiary);
    const beneficiaryUpdated = useSelector(state => state.affiliatedBeneficiaries.beneficiaryUpdated);

    useEffect(() => {
        if (beneficiaryUpdated) {
            dispatch(showSuccessAlert(ALERT_MESSAGE.UPDATED, () => {
                dispatch(clearAffiliatedBeneficiaries());
                history.push(`/membership-customer-beneficiaries/${account}/${membershipAffiliatedId}`);
            }));
        }

    }, [beneficiaryUpdated]);

    useEffect(() => {
        dispatch(getAffiliatedBeneficiary({ id: id }));
    }, []);

    const onSubmit = (formData) => {

        let data = { ...formData };
        data.mainPhone = data.mainPhone.replaceAll(/\-/gi, '');
        data.secondaryPhone = data.secondaryPhone.replaceAll(/\-/gi, '');
        data.id = beneficiary.id;
        data.membershipAffiliatedId = membershipAffiliatedId;
        dispatch(updateAffiliatedBeneficiary(data));
    }

    return (
        <React.Fragment>
            <PageBody
                title='Edit Beneficiary'
            >
                <Row>
                    {beneficiary ? <BeneficiaryForm onSubmit={onSubmit} beneficiary={beneficiary} /> : null}
                </Row>
            </PageBody>
        </React.Fragment>
    );
}

export default EditBeneficiary;