import { takeEvery, put, call, takeLatest } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER, SOCIAL_LOGIN, FETCH_ROLES_AND_GRANTS } from "./actionTypes"
import { loginSuccess, logoutUserSuccess, apiError } from "./actions"
import json from 'jsonwebtoken';
//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper"
import {
  httpPostLogin,
  httpGetRolePrincipalListByPrincipal
} from "../../../helpers/Api/api-helper"

import {
  getRolePrincipalListSuccess,
} from "../../actions"

import { showLoadingAlert, hideLoadingAlert } from "store/actions"

const fireBaseBackend = getFirebaseBackend()

function* loginUser({ payload: { user, history } }) {
  try {
    yield put(showLoadingAlert());
    let success = false;
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(
        fireBaseBackend.loginUser,
        user.email,
        user.password
      )
      success = true;
      yield put(loginSuccess(response))

    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(httpPostLogin, {
        email: user.email,
        password: user.password,
        newPassword: user.newPassword,
        origin: {
          platform: "app",
          model: "iphoneXr",
          os: "chrome"
        }
      });

      if(user.remember) {
          localStorage.setItem('rememberEmail', user.email);
      }

      localStorage.setItem("authUser", JSON.stringify(response));

      success = (response && response.code != '308');
      yield put(loginSuccess(response));
      if(!success) {
        yield put(apiError({ message: 'Por favor verifique que sus credenciales sean correctos' }))
      }

      //Fix to download principals roles from login process
      const roles = yield call(httpGetRolePrincipalListByPrincipal, {id: response.pid})
      yield put(getRolePrincipalListSuccess(roles))

    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      
      const response = yield call(postFakeLogin, {
        email: user.email,
        password: user.password,
      });

      localStorage.setItem("authUser", JSON.stringify(response));
      
      success = true;
      yield put(loginSuccess(response));

    }
    yield put(hideLoadingAlert());
    if (success) {
      history.push("/dashboard");
    }

  } catch (error) {
    yield put(hideLoadingAlert());
    yield put(apiError({ message: 'Por favor verifique que sus credenciales sean correctos' }))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUser")

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout)
      yield put(logoutUserSuccess(response))
    }
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* socialLogin({ payload: { data, history, type } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend()
      const response = yield call(
        fireBaseBackend.socialLoginUser,
        data,
        type
      )
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    } else {
      const response = yield call(postSocialLogin, data)
      localStorage.setItem("authUser", JSON.stringify(response))
      yield put(loginSuccess(response))
    }
    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error))
  }
}

function* fetchRoleAndGrants() {
  try {

    if (localStorage.getItem("authUser")){
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const user = json.decode(obj.idToken);
      if (user) {
        //Fix to download principals roles from login process
        const roles = yield call(httpGetRolePrincipalListByPrincipal, {id: obj.pid})
        yield put(getRolePrincipalListSuccess(roles))
      }  
    }

  } catch (error) {
    yield put(apiError(error))
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeLatest(SOCIAL_LOGIN, socialLogin)
  yield takeEvery(LOGOUT_USER, logoutUser)
  yield takeEvery(FETCH_ROLES_AND_GRANTS, fetchRoleAndGrants)
}

export default authSaga
