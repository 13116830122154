export const CLEAR_LABORATORY_ORDER_HISTORICAL = "CLEAR_LABORATORY_ORDER_HISTORICAL"

/* LABORATORY HISTORICAL LIST */
export const GET_LABORATORY_ORDER_HISTORICAL_LIST = "GET_LABORATORY_ORDER_HISTORICAL_LIST"
export const GET_LABORATORY_ORDER_HISTORICAL_LIST_FAIL = "GET_LABORATORY_ORDER_HISTORICAL_LIST_FAIL"
export const GET_LABORATORY_ORDER_HISTORICAL_LIST_SUCCESS = "GET_LABORATORY_ORDER_HISTORICAL_LIST_SUCCESS"


/* LABORATORY ORDER DETAIL */
export const GET_LABORATORY_ORDER_DETAIL = "GET_LABORATORY_ORDER_DETAIL"
export const GET_LABORATORY_ORDER_DETAIL_FAIL = "GET_LABORATORY_ORDER_DETAIL_FAIL"
export const GET_LABORATORY_ORDER_DETAIL_SUCCESS = "GET_LABORATORY_ORDER_DETAIL_SUCCESS"


export const SEND_LABORATORY_EXAM_RESULT = "SEND_LABORATORY_EXAM_RESULT"
export const SEND_LABORATORY_EXAM_RESULT_FAIL = "SEND_LABORATORY_EXAM_RESULT_FAIL"
export const SEND_LABORATORY_EXAM_RESULT_SUCCESS = "SEND_LABORATORY_EXAM_RESULT_SUCCESS"
