import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import { toDateStringFormat } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import ReactApexChart from 'react-apexcharts';
import ExamPatientDetailStatus from './exam-patient-detail-status';
import { useHistory } from 'react-router-dom';

const ExamPatientDetailCard = ({order, id, account}) => {

    const { t } = useTranslation();
    const history = useHistory();
    const { medicalService, patient, laboratoryOrders, datetime } = order;
    const [series, setSeries] = useState([0]);
    const options = {
      chart: {
        height: 200,
        type: "radialBar",
        sparkline: {
          enabled: true,
        },
      },
      colors: ["#00B1BC"],
      plotOptions: {
        radialBar: {
          startAngle: 0,
          endAngle: 360,
          track: {
            background: "#e7e7e7",
            strokeWidth: "100%",
            margin: 0, // margin is in pixels
          },
    
          hollow: {
            size: "70%",
          },
    
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              offsetY: 7,
              fontSize: "32px"
            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
      stroke: {
        dashArray: 0,
      },
      labels: ["Progress"],
    };

    const getCompletionPercentage = () => {
      let percentage = 0;
      if(laboratoryOrders && laboratoryOrders.length > 0) {
          percentage = laboratoryOrders[0].completionPercentage;
          if(percentage > 100) {
              percentage = 100;
          }
      }

      return percentage;
    }

    const getStatus = examStatus => {
      let status = '';

      switch (examStatus) {
        case 'PEN':
          status = 'Pending'
          break;
        case 'FIN':
            status = 'Finalized'
            break;
        default:
          status = examStatus;
          break;
      }

      return status;
    }

    useEffect(() => {
      if(order) {
        setSeries([getCompletionPercentage()]);
      }
    }, [order]);

    

    return ( 
        <React.Fragment>
            <Card>
                <CardBody>
                    <Row>
                        <Col sm={12}>
                            <strong>#{laboratoryOrders.length > 0 ? laboratoryOrders[0].orderCode: null}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <strong>{laboratoryOrders.length > 0 && laboratoryOrders[0].laboratoryOrderResults.length > 0 ? laboratoryOrders[0].laboratoryOrderResults[0].medicalService.description: null}</strong>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <span>{laboratoryOrders.length > 0 ? toDateStringFormat(datetime): null}</span>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>
                            <span>{t('Patient')}: </span><strong>{patient ? [patient.firstName, patient.secondName, patient.firstSurname, patient.secondSurname].join(' ') : null}</strong>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12}>
                            <span>{t('Status')}: </span><strong>{laboratoryOrders.length > 0 ? t(getStatus(laboratoryOrders[0].completionStatus)): null}</strong>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Card>
                <CardBody>
                    <Row>
                        <Col sm={4}>
                        <div className="text-primary">
                          <ReactApexChart
                            options={options}
                            series={series}
                            type="radialBar"
                            height={200}
                          />
                        </div>
                        </Col>
                        <Col sm={8} lg={6}>
                          
                          { laboratoryOrders && laboratoryOrders.length > 0 ? 
                            (<ExamPatientDetailStatus laboratoryOrder={laboratoryOrders[0]}/>)
                            : null 
                          }
                        </Col>
                    </Row>

                    {laboratoryOrders && laboratoryOrders.length > 0 && laboratoryOrders[0].completionStatus == 'FIN' ?
                    <Row>
                      <Col>
                          <Button 
                            type="button"
                            color="primary"
                            onClick={() => { history.push(`/laboratory-patient-exam-result/${account}/${id}`) }}>
                              {t('View Detail')} <i className="mdi mdi-arrow-right me-1"></i>
                          </Button>
                      </Col>
                    </Row>
                    : null}
                </CardBody>
            </Card>
        </React.Fragment>
     );
}

ExamPatientDetailCard.propTypes = {
    order: PropTypes.object.isRequired,
    id: PropTypes.string.isRequired,
    account: PropTypes.string.isRequired
}

export default ExamPatientDetailCard;