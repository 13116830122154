import { call, put, takeEvery } from "redux-saga/effects";

import {
  GET_PRODUCT_SEARCH_DETAIL,
  GET_PRODUCT_SEARCH_LIST,
  GET_PRODUCT_SEARCH_LIST_BY_CATEGORY,
} from "./actionTypes";

import {
  getProductSearchListSuccess,
  getProductSearchListFail,
  getProductSearchListByCategorySuccess,
  getProductSearchListByCategoryFail,
  getProductSearchDetailFail,
  getProductSearchDetailSuccess,
} from "./actions";

//API Calls helper
import {
  httpFilterProducts,
  httpFilterProductsByCategory,
  httpGetProductDetail,
} from "../../helpers/Api/api-helper";

import {
  hideLoadingAlert,
  showLoadingAlert,
  showWarningAlert,
} from "store/actions";
import { ALERT_MESSAGE } from "constants/constants";

function* fetchProductSearch(request) {
  try {
    //yield put(showLoadingAlert());
    const response = yield call(httpFilterProducts, request.payload);
    yield put(getProductSearchListSuccess(response));
    //yield put(hideLoadingAlert());
  } catch (error) {
    //yield put(hideLoadingAlert());
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR));
    yield put(getProductSearchListFail(error));
  }
}

function* fetchProductSearchByCategory(request) {
  try {
    //yield put(showLoadingAlert());

    const response = yield call(httpFilterProductsByCategory, request.payload);
    yield put(getProductSearchListByCategorySuccess(response));
    //yield put(hideLoadingAlert());
  } catch (error) {
    //yield put(hideLoadingAlert());
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR));
    yield put(getProductSearchListByCategoryFail(error));
  }
}

function* fetchProductDetail(request) {
  try {
    //yield put(showLoadingAlert());
    const response = yield call(httpGetProductDetail, request.payload);
    yield put(getProductSearchDetailSuccess(response));
    //yield put(hideLoadingAlert());
  } catch (error) {
    //yield put(hideLoadingAlert());
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR));
    yield put(getProductSearchDetailFail(error));
  }
}

function* fetchProductSearchSaga() {
  yield takeEvery(GET_PRODUCT_SEARCH_LIST, fetchProductSearch);
  yield takeEvery(
    GET_PRODUCT_SEARCH_LIST_BY_CATEGORY,
    fetchProductSearchByCategory
  );
  yield takeEvery(GET_PRODUCT_SEARCH_DETAIL, fetchProductDetail);
}

export default fetchProductSearchSaga;
