import React, { useCallback, useContext } from 'react';
import PropTypes from 'prop-types'
import { Row, Col, Button, Modal, Card, CardBody, CardTitle } from 'reactstrap';
import cancelMembershipContext from '../context/cancelMembershipContext';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CancelMembershipSelectAddress = ({isOpen, onAccept, onCancel}) => {

    const { t } = useTranslation();
    const membershipContext = useContext(cancelMembershipContext);
    const { accountDetail, address, setAddress } = membershipContext;
    const identificationTypeList = useSelector(state => state.identificationTypes.identificationTypeList);
    
    const getIdentificationType = useCallback((id) => {
        const identification = identificationTypeList.find(current => current.id == id);
        return identification? identification.name: null;
    }); 
   
    const isSelectedAddress = useCallback((currentAddress) => {
        return address && currentAddress.id == address.id;
    });

    return ( 
        <React.Fragment>
            <Modal
                isOpen={isOpen}
                toggle={() => {onCancel()}}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {t('Select Invoice Address')}
                    </h5>
                    <button
                        type="button"
                        onClick={() => onCancel()}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
             
                {accountDetail && accountDetail.addresses? 
                accountDetail.addresses.map((currentAddress, key) => {
                return (
                <Row key={`_row_${key}`}>
                    <Col sm={12} lg={12} xl={12}>
                        <Card className="task-box dashboard-option mini-stats-wid" onClick={() => {setAddress(currentAddress)}}>
                                                
                            <CardBody className="text-black">

                                <div className='row'>
                                    <div className="text-end ms-1">
                                        <strong className='font-size-24'>
                                            <i className={`mdi ${isSelectedAddress(currentAddress)? 'mdi-check-bold' : 'bx bx-uncheck'}`}></i>
                                        </strong>
                                    </div> 
                                </div>
                                
                                <CardTitle>
                                    <h4> <i className="mdi mdi-file-move-outline font-size-24 text-primary"></i></h4>
                                </CardTitle>
                                
                                <div>
                                    <strong>{currentAddress.billingFullName}</strong>
                                </div>

                                <div>
                                    <strong>{getIdentificationType(currentAddress.billingIdentificationTypeId)}: {currentAddress.billingIdentification}</strong>
                                </div>

                                <div>
                                    <strong>{t('Phone')}: {currentAddress.billingPhone}</strong>
                                </div>
                                
                                <div>
                                    <strong>{t('Address')}: {currentAddress.address}</strong>
                                </div>
                                
                                <div>
                                    <strong>{t('Email')}: {currentAddress.billingMainEmail}</strong>
                                </div>
                                
                                <div>
                                    <strong>{t('Additional Email')}: {currentAddress.billingSecondaryEmail}</strong>
                                </div>
                                
                                
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                );
                })
                :null
                }
                <div className="modal-footer button-items mt-1">
                    <Button color="primary" className="button" onClick={() => { onAccept(); }}><i className="mdi mdi-check-bold me-1"></i>{t('Accept')}</Button>
                    <Button color="light" className="button mr-1" onClick={() => { onCancel(); }}><i className="mdi mdi-close-thick me-1"></i>{t('Cancel')}</Button>
                </div>
            </Modal>
        </React.Fragment>
     );
}

CancelMembershipSelectAddress.propTypes = {
    onAccept: PropTypes.func,
    onCancel: PropTypes.func,
    isOpen: PropTypes.bool
}
 
export default CancelMembershipSelectAddress;