import React, { useEffect } from "react";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { Form, Formik } from "formik";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import {
  clearLaboratoryOrderHistorical,
  getLaboratoryOrderHistoricalList,
  getMedicalCenterList,
  getDateFrom,
  getDateTo,
} from "store/actions";
import { useParams } from "react-router-dom";
import { toDateISOStringFormat } from "helpers/utils";

const ExamHistoricalFilter = ({formData}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { account } = useParams();

  const {medicalCenter } = formData;
  const dateFromStore = useSelector(state=>state.accounts.dateFrom);
  const dateToStore = useSelector(state => state.accounts.dateTo);

  const accountDetail = useSelector(state => state.accounts.accountDetail);

  useEffect(() => {    
    dispatch(clearLaboratoryOrderHistorical());
    //dispatch(getMedicalCenterList());
  }, []);

  useEffect(() => {
    if(accountDetail) {
      filter();
    }
  }, [accountDetail]);

  const filter = () => {  
    const request = {
      account: account,
      identification: accountDetail.identification,
      //medicalCenter: medicalCenter,
      start: toDateISOStringFormat(dateFromStore), //null
      end: toDateISOStringFormat(dateToStore, true),//null
    };
    dispatch(getLaboratoryOrderHistoricalList(request));
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Formik
            enableReinitialize={true}
            initialValues={formData}
            validationSchema={Yup.object().shape({
              startDate: Yup.string().required(),
              medicalCenter: Yup.number()//.required(),
            })}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false);
              filter();
            }}
          >
            {({ errors, status, touched }) => (
              <Form className="needs-validation form-inline">
                <Row>
                  <Col md={4}>
                    <FormGroup className="mb-4">
                      <Label>{t("Date Range")}</Label>
                      <InputGroup>
                        <Flatpickr
                          name="startDate"
                          className="form-control d-block"
                          placeholder="dd M,yyyy"
                          options={{
                            mode: "range",
                            dateFormat: "d-m-Y",
                            locale: "es",
                          }}
                          onChange={dates => {
                            dispatch(getDateFrom(dates[0]));
                            dispatch(getDateTo(dates[1]));
                          }}
                          value={[dateFromStore, dateToStore]}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <Button
                      type="submit"
                      color="primary"
                      className="button mt-button"
                    >
                      <i className="bx bx-search-alt me-1"></i>
                      {t("Search")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

ExamHistoricalFilter.propTypes = {
  formData: PropTypes.object, 
}

export default ExamHistoricalFilter;
