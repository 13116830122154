import { 
    GET_BENEFIT_CATEGORIES_LIST, 
    GET_BENEFIT_CATEGORIES_LIST_FAIL, 
    GET_BENEFIT_CATEGORIES_LIST_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  categoryList: [],
  success: false,
  loading: false,
  error: {},
}

const benefitCategories = (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_BENEFIT_CATEGORIES_LIST:
            return {
                ...state,
                categoryList: [],
                loading: true,
            }
        case GET_BENEFIT_CATEGORIES_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                categoryList: action.payload,
            }  
        case GET_BENEFIT_CATEGORIES_LIST_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        default:
            return state
    }
}

export default benefitCategories;