import { CLEAR_SUBSIDIARIES_STATE, 
         GET_SUBSIDIARY_LIST, 
         GET_SUBSIDIARY_LIST_FAIL, 
         GET_SUBSIDIARY_LIST_SUCCESS } from "./actionTypes"

//Subsidiaries
export const clearSubsidiary = () => ({
    type: CLEAR_SUBSIDIARIES_STATE
  })

  export const getSubsidiaries = (request) => ({
    type: GET_SUBSIDIARY_LIST,
    payload: request
  })
  
  export const getSubsidiariesSuccess = invoice => ({
    type: GET_SUBSIDIARY_LIST_SUCCESS,
    payload: invoice,
  })
  
  export const getSubsidiariesFail = error => ({
    type: GET_SUBSIDIARY_LIST_FAIL,
    payload: error,
  })