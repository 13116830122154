import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import json from 'jsonwebtoken';
// Redux
import { useDispatch, useSelector } from "react-redux";

// actions
import {
  getCatalogs, getIdentificationTypes, getBusinessUnits, 
  updateAccount, showSuccessAlert, clearAccount, getSubsidiaries, getCountries, getAccountDetailLogin
} from "../../store/actions";
import { DEFAULT_COMPANY } from "constants/list-types";
import AccountForm from "./acccount-form";
import { useHistory } from "react-router-dom";
import PageBody from "components/Common/PageLayout/page-body";
import { ALERT_MESSAGE } from "constants/constants";

const EditProfilePage = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const accountDetail = useSelector(state => state.accounts.accountLogin);
  const accountUpdated = useSelector(state => state.accounts.accountUpdated);

  useState(() => {
    if (localStorage.getItem("authUser")) {

      const obj = JSON.parse(localStorage.getItem("authUser"));
      const user = json.decode(obj.idToken);
      dispatch(getCountries());
      dispatch(getAccountDetailLogin({ accountCode: user.sub }));
      dispatch(getCatalogs());
      dispatch(getIdentificationTypes());
      dispatch(getBusinessUnits({ companyId: DEFAULT_COMPANY }));
      dispatch(getSubsidiaries({ companyId: DEFAULT_COMPANY }));
    }
  }, []);

  useEffect(() => {
    if (accountUpdated) {
      dispatch(showSuccessAlert(ALERT_MESSAGE.UPDATED, () => {
        dispatch(clearAccount());
        history.push(`/profile`);
      }));
    }

  }, [accountUpdated]);

  const onSubmit = (data) => {
    const account = { ...accountDetail, ...data };
    account.mainPhone = data.mainPhone.toString().replace(/\-/gi, '');
    dispatch(updateAccount(account));
  }

  return (
    <React.Fragment>
      <PageBody
        title="Edit Profile"
      >
        <Row>
          <Col xl={{ size: 10, offset: 1 }}>
            {accountDetail ?
              <AccountForm
                onSubmit={onSubmit}
                customerProfile={accountDetail}
              />
              : null}
          </Col>
        </Row>
      </PageBody>
    </React.Fragment>
  );
}

export default EditProfilePage;
