import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import { numberFormat } from "helpers/utils";

const CustomerStateBenefit = ({
  medicalEntry,
  onSelect,
  getCurrencySymbol,
}) => {
  const {
    description,
    icon,
    color,
    amountWithMembership,
    amountWithoutMembership,
  } = medicalEntry;

  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Card
        className="dashboard-option category-card"
        onClick={() => {
          onSelect(medicalCategory);
        }}
      >
        <CardBody>
          <div className="align-middle">
            <div className="float-start mt-3">
              <h5>{description}</h5>
            </div>
            <span className="float-end text-end mt-1">
              <strong>
                {t("Vitae value")}: {getCurrencySymbol("CRC")}
                {numberFormat(amountWithMembership)}
              </strong>
              <p className="text-muted">
                {t("Market value")}: {getCurrencySymbol("CRC")}
                {numberFormat(amountWithoutMembership)}
              </p>
              <strong>
                {t("Saving")}: {getCurrencySymbol("CRC")}
                {numberFormat((amountWithoutMembership - amountWithMembership))}
              </strong>
            </span>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

CustomerStateBenefit.propTypes = {
  medicalEntry: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
  getCurrencySymbol: PropTypes.func.isRequired,
};

export default CustomerStateBenefit;
