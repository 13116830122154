import PageBody from 'components/Common/PageLayout/page-body';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import { getSampleCollectList } from 'store/actions';
import ServiceCatalogList from './Components/service-catalog-list';

const ServiceCatalogListPage = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
      
    }, []);

    return (
        <React.Fragment>
            <PageBody
                title='Service Catalog Inbox'
                actions={(
                    <Button
                        color="light"
                        className="button"
                        onClick={() => {
                            history.push("/dashboard");
                        }}
                    >
                        <i className="mdi mdi-arrow-left me-1"></i>
                        {t("Back")}
                    </Button>
                )}
            >
                <ServiceCatalogList />
            </PageBody>
        </React.Fragment>
    );
}

export default ServiceCatalogListPage;