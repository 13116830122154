/* RELATED_PRODUCTS */
export const CLEAR_RELATED_PRODUCTS_STATE = "CLEAR_RELATED_PRODUCTS_STATE" 

export const GET_RELATED_PRODUCTS_LIST = "GET_RELATED_PRODUCTS_LIST"
export const GET_RELATED_PRODUCTS_LIST_FAIL = "GET_RELATED_PRODUCTS_LIST_FAIL"
export const GET_RELATED_PRODUCTS_LIST_SUCCESS = "GET_RELATED_PRODUCTS_LIST_SUCCESS"

export const ADD_RELATED_PRODUCTS = "ADD_RELATED_PRODUCTS"
export const ADD_RELATED_PRODUCTS_FAIL = "ADD_RELATED_PRODUCTS_FAIL"
export const ADD_RELATED_PRODUCTS_SUCCESS = "ADD_RELATED_PRODUCTS_SUCCESS"
