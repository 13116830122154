import DataTable from 'components/Common/DataTable/datatable';
import { toLongDateStringFormat } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'reactstrap';

const BenefitHistoricalModal = ({ isOpen, benefits, onCancel }) => {

    const { t } = useTranslation();

    const [data, setData] = useState([]);

    useEffect(() => {
        if (benefits) {
            
            setData(benefits.map(current => ({
                id: current.id,
                registeredByIdentification: current.registeredByIdentification,
                registeredByFullname: current.registeredByFullname,
                usageDate: toLongDateStringFormat(current.usageDate),
                description: current.description,
                usageChannel: t(current.usageChannel)
            })));
        }
    }, [benefits]);

    const defaultSorted = [
        {
            dataField: 'description', // if dataField is not match to any column you defined, it will be ignored.
            order: 'asc', // desc or asc
        },
    ];

    const columns = [
        /*{
                text: t('Module'),
                dataField: 'scope',
                sort: true,
                formatter: (cellContent, row) => <React.Fragment>{row.scope.code}</React.Fragment>
            },*/ 
        {
            text: t("Description"),
            dataField: "description",
            sort: true,
            formatter: (cellContent, row) => (
                <React.Fragment>{row.description}</React.Fragment>
            ),
        },
        {
            text: t("Date"),
            dataField: "usageDate",
            sort: true,
            formatter: (cellContent, row) => (
                <React.Fragment>{row.usageDate}</React.Fragment>
            ),
        },
        {
            text: t("Channel"),
            dataField: "usageChannel",
            sort: true,
            formatter: (cellContent, row) => (
                <React.Fragment>{row.usageChannel}</React.Fragment>
            ),
        },
        {
            text: t("Name"),
            dataField: "registeredByFullname",
            sort: true,
            formatter: (cellContent, row) => (
                <React.Fragment>{row.registeredByFullname}</React.Fragment>
            ),
        }
    ];

    return (
        <Modal
            isOpen={isOpen}
            toggle={() => { onCancel() }}
        >

            <div className="modal-header">
                <h5 className="modal-title mt-0" id="myModalLabel">
                    {t('Historical')}
                </h5>
                <button
                    type="button"
                    onClick={() => onCancel()}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <DataTable
                    columns={columns}
                    data={data}
                    keyField='id'
                    sort={defaultSorted}
                />
            </div>

            <div className="modal-footer button-items mt-1">
                <Button color="light" className="button mr-1" onClick={() => { onCancel(); }}><i className="mdi mdi-close-thick me-1"></i>{t('Cancel')}</Button>
            </div>
        </Modal>
    );
}

BenefitHistoricalModal.propTypes = {
    isOpen: PropTypes.bool,
    benefits: PropTypes.array,
    onCancel: PropTypes.func
}

export default BenefitHistoricalModal;