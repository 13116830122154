import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { Form, Formik } from "formik";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { getAllPurchaseTransactions } from "store/actions";
import { useParams } from "react-router-dom";
import { DEFAULT_COMPANY } from "constants/list-types";
import { toSimpleDateISOStringFormat } from "helpers/utils";
import * as url from "../../../../../helpers/Api/api-endpoint-helper";
import FormField from 'pages/Utility/form-field';
import { getAllOrderList } from "../../../../../store/Affiliated/actions"
import { showWarningAlert } from '../../../../../store/actions';

const CustomerOrderTransactionFilter = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { accountCode } = useParams();

  const [formData, setFormData] = useState({
    startDate: new Date(new Date().setDate(new Date().getDate() - 5)),
    endDate: Date.now(),
    identification: '',
  });

  const { startDate, endDate, identification } = formData;

  const filter = () => {
    const request = {
      fromDate: toSimpleDateISOStringFormat(startDate),
      toDate: toSimpleDateISOStringFormat(endDate),
      identification: identification && identification.trim().length > 0 ? identification : null
    };

    if(!identification || identification.trim().length === 0){
      let difference = new Date(endDate).getTime() - new Date(startDate).getTime();
      let totalDays = Math.ceil(difference / (1000 * 3600 * 24));
      if(totalDays > 100){
        dispatch(showWarningAlert(t('Max Limit Filter range error')));
        return;
      }
    }

    dispatch(getAllOrderList(request));
  };

  useEffect(() => {
    filter();
  }, []);

  const handleOnChange = event => {
    setFormData({
        ...formData,
        [event.target.name]: event.target.value
    });
}

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Formik
            enableReinitialize={true}
            initialValues={formData}
            validationSchema={Yup.object().shape({
              startDate: Yup.string().required()
            })}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false);
              filter();
            }}
          >
            {({ errors, status, touched }) => (
              <Form className="needs-validation form-inline">
                <Row>
                  <Col md={4}>
                    <FormGroup className="mb-4">
                      <Label>{t("Date Range")}</Label>
                      <InputGroup>
                        <Flatpickr
                          name="startDate"
                          className="form-control d-block"
                          placeholder="dd M,yyyy"
                          options={{
                            mode: "range",
                            dateFormat: "d-m-Y",
                            locale: "es",
                          }}
                          onChange={dates => {
                            setFormData({
                              ...formData,
                              startDate: dates[0],
                              endDate: dates[1],
                            });
                          }}
                          value={[startDate, endDate]}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                   
                  <FormField
                      key='identification'
                      type='text'
                      maxLength={500}
                      fieldName='identification'
                      fieldValue={identification}
                      label={t('Identification')}
                      errors={errors}
                      touched={touched}
                      handleOnChange={handleOnChange}
                  />

                  </Col>
                  <Col md={3}>
                    <Button
                      type="submit"
                      color="primary"
                      className="button mt-button mt-button-mr"
                    >
                      <i className="bx bx-search-alt me-1"></i>
                      {t("Search")}
                    </Button> {t("  ")}

                  </Col>
                
                </Row>
              </Form>
            )}
          </Formik>

          <p className="text-muted">
            {t('Filter Message')}
          </p>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default CustomerOrderTransactionFilter;
