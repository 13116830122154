import React, { useEffect, useState } from "react";
import MetaTags from 'react-meta-tags';
import { Link, useHistory, useParams } from "react-router-dom";
import { Card, CardBody, Col, Container, Row, Table, Button } from "reactstrap";
import { isEmpty, map } from "lodash";

//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import * as moment from "moment";
//Import Image
import logo from "../../../../assets/images/vitae/Logos-vitae.png";
import PropTypes from "prop-types";
import { getInvoiceDetail } from "../../../../store/invoices/actions";
import { connect, useDispatch, useSelector } from "react-redux";
import { numberFormat, toDateStringFormat } from "helpers/utils";
import { useTranslation } from "react-i18next";
import { getOrder } from "store/actions";
import { businessUnitTypes } from "constants/list-types";

const OrderDetail = () => {

  const { t } = useTranslation();
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const affiliatedOrderList = useSelector(state => state.affiliateds.affiliatedOrderList);
  const invoiceDetail = useSelector(state => state.affiliateds.order);
  const allOrderList = useSelector(state => state.affiliateds.allOrderList);

  //Print the Invoice
  const printInvoice = () => {
    window.print();
  };

  const handleBack = () => {
    history.goBack();
  }

  const getReference = (detail) => {
    let reference = '';
    if(detail.businessUnitType == 'PHA') {
      reference = invoiceDetail.sianInvoiceReference;
    } else {
      reference = invoiceDetail.sikuInvoiceReference;
    }

    return reference;
  }

  const getBusinessUnitType = code => {
    const selected = businessUnitTypes.find(current => current.code === code);
    return selected ? t(selected.value) : '';
}

  useEffect(() => {
    if (id) {
      dispatch(getOrder({ id: id }));
    }
  }, [id, affiliatedOrderList, allOrderList]);

  useEffect(() => {
    if (invoiceDetail) {
      console.log("invoiceDetail", invoiceDetail);
    }
  }, [invoiceDetail]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Vitae - Portal</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}

          {/*
            <div className="mb-2"><Link to="/membership-customer-search" className="text-body fw-bold">Buscar</Link><i className="mdi mdi-chevron-right" /><Link to={"/membership-customer-profile/" + invoiceDetail.customer?.accountCode} className="text-body fw-bold">Afiliado</Link><i className="mdi mdi-chevron-right" />Historial de Pagos</div>
            <h2 className="mb-2 font-size-18">FACTURA</h2>
          */}

          <Row>
            <Col xl="9">
              <div className="mb-2"><Link to="/membership-customer-search" className="text-body fw-bold">Buscar</Link><i className="mdi mdi-chevron-right" /><Link to="#" className="text-body fw-bold">Afiliado</Link><i className="mdi mdi-chevron-right" />Historial de transacciones</div>
              <h2 className="mb-2 font-size-18">{t("Transaction")}</h2>
            </Col>
            <Col xl="3" className="mb-2 align-right">
              <Button
                type="button"
                color="secondary"
                onClick={handleBack}
              >
                Regresar
              </Button>
            </Col>
          </Row>


          {!isEmpty(invoiceDetail) && (
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <div className="invoice-title">
                      <h4 className="float-end font-size-16">
                        No. {invoiceDetail.id}
                      </h4>
                      <div className="mb-4">
                        <img src={logo} alt="logo" height="80" />
                      </div>
                    </div>
                    <hr />
                    <Row>
                      <Col sm="3">
                        <address>
                          <strong>Cliente:</strong>
                          <br />
                          {invoiceDetail.customer?.firstName} {invoiceDetail.customer?.secondName} {invoiceDetail.customer?.fistSurname} {invoiceDetail.customer?.secondSurname}
                        </address>
                      </Col>
                      <Col sm="3">
                        <address>
                          <strong>{invoiceDetail.invoicePayments && invoiceDetail.invoicePayments.length > 0 && invoiceDetail.invoicePayments[0] && invoiceDetail.invoicePayments[0].status === 'ERR' ? t('GP Error') : t('GP Reference')}:</strong>
                          <br />
                          {invoiceDetail.invoicePayments && invoiceDetail.invoicePayments.length > 0 && invoiceDetail.invoicePayments[0] 
                            ? (invoiceDetail.invoicePayments[0].status === 'ERR' ? invoiceDetail.invoicePayments[0].processMessage : invoiceDetail.invoicePayments[0].cardAuthorization) : 'No realizado'}
                        </address>
                      </Col> 
                      <Col sm="3">
                        <address>
                          <strong>{invoiceDetail.integrationMessage ? t('Siku Error') : t('Siku Reference')}:</strong>
                          <br />
                          {getReference(invoiceDetail)} {invoiceDetail.integrationMessage ? invoiceDetail.integrationMessage : ""}
                        </address>
                      </Col>                      
                      <Col sm="3" className="text-sm-end">
                        <address className="mt-2 mt-sm-0">
                          <strong>Observaciones:</strong>
                          <br />
                          {invoiceDetail.observations}
                        </address>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" className="mt-3">
                        <address>
                          <strong></strong>
                          <br />
                          {invoiceDetail.card}
                          <br />
                          {invoiceDetail.email}
                        </address>
                      </Col>
                      <Col sm="6" className="mt-3 text-sm-end">
                        <address>
                          <strong>Fecha:</strong>
                          <br />
                          {toDateStringFormat(invoiceDetail.date)}
                          <br />
                          <br />
                        </address>
                      </Col>
                    </Row>
                    <div className="py-2 mt-3">
                      <h3 className="font-size-15 font-weight-bold">
                        Resumen de transacción
                      </h3>
                    </div>
                    <div className="table-responsive">
                      <Table className="table-nowrap">
                        <thead>
                          <tr>
                            <th colSpan={3}>{t('Service')}</th>
                            <th style={{ width: "70px" }}>{t('Quantity')}</th>
                            <th className="text-end">{t('Unit Price')}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {map(
                            invoiceDetail.detailList,
                            (item, key) => (
                              <tr key={key}>
                                <td colSpan={3}>{item.description}</td>
                                <td>{item.quantity}</td>
                                <td className="text-end">{item.unitPrice.toFixed(2) + " " + invoiceDetail.currencyCode}</td>
                              </tr>
                            )
                          )}
                          <tr>
                            <td colSpan="4" className="text-end">
                              Subtotal
                            </td>
                            <td className="text-end">
                              {invoiceDetail.subtotalAmount.toFixed(2) + " " + invoiceDetail.currencyCode}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="4" className="border-0 text-end">
                              <strong>Impuesto</strong>
                            </td>
                            <td className="border-0 text-end">
                              {invoiceDetail.totalTaxAmount.toFixed(2) + " " + invoiceDetail.currencyCode}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="4" className="border-0 text-end">
                              <strong>Total</strong>
                            </td>
                            <td className="border-0 text-end">
                              <h4 className="m-0">
                                {invoiceDetail.totalAmount.toFixed(2) + " " + invoiceDetail.currencyCode}
                              </h4>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </div>
                    <div className="d-print-none">
                      <div className="float-end">
                        <Link
                          to="#"
                          onClick={printInvoice}
                          className="btn btn-success me-1"
                        >
                          <i className="fa fa-print" />
                        </Link>{" "}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );

}

export default OrderDetail;
