import { ADD_PAYMENT_METHOD, ADD_PAYMENT_METHOD_FAIL, ADD_PAYMENT_METHOD_SUCCESS, CLEAR_PAYMENT_METHODS_STATE,
         DELETE_PAYMENT_METHOD,
         DELETE_PAYMENT_METHOD_FAIL,
         DELETE_PAYMENT_METHOD_SUCCESS,
         GET_PAYMENT_METHOD_DETAIL,
         GET_PAYMENT_METHOD_DETAIL_FAIL,
         GET_PAYMENT_METHOD_DETAIL_SUCCESS,
         GET_PAYMENT_METHOD_LIST, 
         GET_PAYMENT_METHOD_LIST_FAIL, 
         GET_PAYMENT_METHOD_LIST_SUCCESS, 
         UPDATE_PAYMENT_METHOD,
         UPDATE_PAYMENT_METHOD_FAIL,
         UPDATE_PAYMENT_METHOD_SUCCESS} from "./actionTypes"

const INIT_STATE = {
    paymentMethods: [],
    paymentMethodDetail: null,
    created: false,
    updated: false,
    deleted: false,
    success: false,
    loading: false,
    error: {}
  }
  
  const paymentMethods = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CLEAR_PAYMENT_METHODS_STATE:
        return {
            ...state,
            paymentMethods: [],
            paymentMethodDetail: null,
            created: false,
            updated: false,
            deleted: false,
            success: false,
            loading: false,
            error: {}
        }
        
        case GET_PAYMENT_METHOD_LIST:
        return {
            ...state,
            paymentMethods: [],
            created: false,
            loading: true,
        } 
        case GET_PAYMENT_METHOD_LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            paymentMethods: action.payload,
        }  
        case GET_PAYMENT_METHOD_LIST_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case GET_PAYMENT_METHOD_DETAIL:
        return {
            ...state,
            paymentMethodDetail: null,
            loading: true,
        } 
        case GET_PAYMENT_METHOD_DETAIL_SUCCESS:
        return {
            ...state,
            loading: false,
            paymentMethodDetail: action.payload,
        }  
        case GET_PAYMENT_METHOD_DETAIL_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case ADD_PAYMENT_METHOD:
        return {
            ...state,
            created: false,
            loading: true,
        } 
        case ADD_PAYMENT_METHOD_SUCCESS:
        return {
            ...state,
            loading: false,
            created: true
        }  
        case ADD_PAYMENT_METHOD_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case UPDATE_PAYMENT_METHOD:
        return {
            ...state,
            updated: false,
            loading: true,
        } 
        case UPDATE_PAYMENT_METHOD_SUCCESS:
        return {
            ...state,
            loading: false,
            updated: true
        }  
        case UPDATE_PAYMENT_METHOD_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case DELETE_PAYMENT_METHOD:
        return {
            ...state,
            deleted: false,
            loading: true,
        } 
        case DELETE_PAYMENT_METHOD_SUCCESS:
        return {
            ...state,
            loading: false,
            deleted: true
        }  
        case DELETE_PAYMENT_METHOD_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        default:
            return state
        }
    }
    
export default paymentMethods
      