import { ALERT_MESSAGE } from "constants/constants";
import {
  httpGetPrescriptionTemplateList,
  httpGetPrescriptionList,
  httpGetPrescriptionDetail,
  httpPostPrescription,
  httpPutPrescription,
  httpDeletePrescription,
  httpPutUploadPdfPrescription,
} from "helpers/Api/api-helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  hideLoadingAlert,
  showLoadingAlert,
  showWarningAlert,
} from "store/actions";
import {
  getPrescriptionListSuccess,
  getPrescriptionListFail,
  getPrescriptionTemplateListSuccess,
  getPrescriptionTemplateListFail,
  getPrescriptionDetailFail,
  getPrescriptionDetailSuccess,
  addPrescriptionSuccess,
  addPrescriptionFail,
  updatePrescriptionSuccess,
  updatePrescriptionFail,
  deletePrescriptionSuccess,
  deletePrescriptionFail,
  uploadPdfPrescriptionSuccess,
  uploadPdfPrescriptionFail,
} from "./actions";
import {
  GET_PRESCRIPTION_TEMPLATE_LIST,
  ADD_PRESCRIPTION,
  DELETE_PRESCRIPTION,
  GET_PRESCRIPTION_DETAIL,
  GET_PRESCRIPTION_LIST,
  UPDATE_PRESCRIPTION,
  UPLOAD_PDF_PRESCRIPTION,
} from "./actionTypes";

function* getPrescriptionTemplateList(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(
      httpGetPrescriptionTemplateList,
      request.payload
    );
    yield put(hideLoadingAlert());
    yield put(getPrescriptionTemplateListSuccess(response));
  } catch (error) {
    yield put(hideLoadingAlert());
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR));
    yield put(getPrescriptionTemplateListFail(error));
  }
}

function* getPrescriptionList(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(httpGetPrescriptionList, request.payload);
    yield put(hideLoadingAlert());
    yield put(getPrescriptionListSuccess(response));
  } catch (error) {
    yield put(hideLoadingAlert());
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR));
    yield put(getPrescriptionListFail(error));
  }
}

function* getPrescriptionDetail(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(httpGetPrescriptionDetail, request.payload);
    yield put(hideLoadingAlert());
    yield put(getPrescriptionDetailSuccess(response));
  } catch (error) {
    yield put(hideLoadingAlert());
    yield put(showWarningAlert(ALERT_MESSAGE.GET_DETAIL_ERROR));
    yield put(getPrescriptionDetailFail(error));
  }
}

function* addPrescription(request) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.SAVING));
    const response = yield call(httpPostPrescription, request.payload);
    yield put(hideLoadingAlert());
    yield put(addPrescriptionSuccess(response));
  } catch (error) {
    yield put(hideLoadingAlert());
    yield put(showWarningAlert(ALERT_MESSAGE.ADD_ERROR));
    yield put(addPrescriptionFail(error));
  }
}

function* updatePrescription(request) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.SAVING));
    const response = yield call(httpPutPrescription, request.payload);
    yield put(hideLoadingAlert());
    yield put(updatePrescriptionSuccess(response));
  } catch (error) {
    yield put(hideLoadingAlert());
    yield put(showWarningAlert(ALERT_MESSAGE.UPDATE_ERROR));
    yield put(updatePrescriptionFail(error));
  }
}

function* uploadPdfPrescription(request) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.SAVING));
    const response = yield call(httpPutUploadPdfPrescription, request.payload);
    yield put(hideLoadingAlert());
    yield put(uploadPdfPrescriptionSuccess(response));
  } catch (error) {
    yield put(hideLoadingAlert());
    yield put(showWarningAlert(ALERT_MESSAGE.UPDATE_ERROR));
    yield put(uploadPdfPrescriptionFail(error));
  }
}

function* deletePrescription(request) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.DELETING));
    const response = yield call(httpDeletePrescription, request.payload);
    yield put(hideLoadingAlert());
    yield put(deletePrescriptionSuccess(response));
  } catch (error) {
    yield put(hideLoadingAlert());
    yield put(showWarningAlert(ALERT_MESSAGE.DELETE_ERROR));
    yield put(deletePrescriptionFail(error));
  }
}

function* prescriptionsSaga() {
  yield takeEvery(GET_PRESCRIPTION_TEMPLATE_LIST, getPrescriptionTemplateList);
  yield takeEvery(GET_PRESCRIPTION_LIST, getPrescriptionList);
  yield takeEvery(GET_PRESCRIPTION_DETAIL, getPrescriptionDetail);
  yield takeEvery(ADD_PRESCRIPTION, addPrescription);
  yield takeEvery(UPDATE_PRESCRIPTION, updatePrescription);
  yield takeEvery(UPLOAD_PDF_PRESCRIPTION, uploadPdfPrescription);
  yield takeEvery(DELETE_PRESCRIPTION, deletePrescription);
}

export default prescriptionsSaga;

