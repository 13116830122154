import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_AFFILIATED,
  GET_AFFILIATED_FAIL,
  GET_AFFILIATED_SUCCESS,
  GET_AFFILIATED_SEARCH,
  GET_AFFILIATED_SEARCH_FAIL,
  GET_AFFILIATED_SEARCH_SUCCESS,
  GET_AFFILIATED_ORDER_LIST,
  GET_AFFILIATED_ORDER_LIST_FAIL,
  GET_AFFILIATED_ORDER_LIST_SUCCESS,
  GET_AFFILIATED_MEMBERSHIP_LIST,
  GET_AFFILIATED_MEMBERSHIP_DETAIL,
  UPGRADE_MEMBERSHIP,
  GET_ALL_ORDER_LIST,
  GET_ORDER,
  GET_ALL_PURCHASE_TRANSACTIONS
} from "./actionTypes"

import {
  getAffiliatedFail,
  getAffiliatedSuccess,
  getAffiliatedSearchFail,
  getAffiliatedSearchSuccess,
  getAffiliatedOrderListSuccess,
  getAffiliatedOrderListFail,
  getAffiliatedMembershipListSuccess,
  getAffiliatedMembershipListFail,
  getAffiliatedMembershipDetailSuccess,
  getAffiliatedMembershipDetailFail,
  upgradeMembershipSuccess,
  upgradeMembershipFail,
  getAllOrderListSuccess,
  getAllOrderListFail,
  getOrderSuccess,
  getOrderFail,
  getAllPurchaseTransactionsFail,
  getAllPurchaseTransactionsSuccess
} from "./actions"

//API Calls helper
import {
  httpGetAffiliated,
  httpGetSearchAffiliated,
  httpGetAccountDetailByExternalCode,
  httpGetAffiliatedOrderList,
  httpGetAllOrderList,
  httpGetAffiliatedMembershipList,
  httpGetAffiliatedMembershipDetail,
  httpUpgradeMembership,
  httpGetOrder,
  httpGetPurchaseTransactions,
  httpGetPurchaseTransactionsDownload
} from "../../helpers/Api/api-helper"

import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions"
import { ALERT_MESSAGE } from "constants/constants"

function* fetchAffiliated(request) {
  try {
    const response = yield call(httpGetAffiliated, request.accountCode)
    yield put(getAffiliatedSuccess(response))
  } catch (error) {
    yield put(getAffiliatedFail(error))
  }
}

function* fetchAffiliatedSearch(request) {
  try {
    yield put(showLoadingAlert());
    let response = [];
    if(request.searchCriteria.criteria === 'account'){
      const accountResponse = yield call(httpGetAccountDetailByExternalCode, request.searchCriteria.key)
      if(accountResponse && accountResponse.accountCode){
        request.searchCriteria.key = accountResponse.accountCode;
        response = yield call(httpGetSearchAffiliated, request.searchCriteria)  
      }
    } else {
      response = yield call(httpGetSearchAffiliated, request.searchCriteria)  
    }
    yield put(hideLoadingAlert())
    yield put(getAffiliatedSearchSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getAffiliatedSearchFail(error))
  }
}

function* fetchAffiliatedOrderList(request) {
  try {
    const response = yield call(httpGetAffiliatedOrderList, request.accountCode)
    yield put(getAffiliatedOrderListSuccess(response))
  } catch (error) {
    yield put(getAffiliatedOrderListFail(error))
  }
}
//
function* fetchAllOrderList(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(httpGetAllOrderList, request.payload)
    yield put(hideLoadingAlert())
    yield put(getAllOrderListSuccess(response))
  } catch (error) {
    yield put(getAllOrderListFail(error))
  }
}
//

function* fetchOrder(request) {
  try {
    const response = yield call(httpGetOrder, request.payload)
    yield put(getOrderSuccess(response))
  } catch (error) {
    yield put(getOrderFail(error))
  }
}

function* fetchAffiliatedMembershipList(request) {
  try {
    const response = yield call(httpGetAffiliatedMembershipList, request.accountCode)
    yield put(getAffiliatedMembershipListSuccess(response))
  } catch (error) {
    yield put(getAffiliatedMembershipListFail(error))
  }
}

function* fetchAffiliatedMembershipDetail(request) {
  try {
    const response = yield call(httpGetAffiliatedMembershipDetail, request.id)
    yield put(getAffiliatedMembershipDetailSuccess(response))
  } catch (error) {
    yield put(getAffiliatedMembershipDetailFail(error))
  }
}

function* fetchUpgradeMembership(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(httpUpgradeMembership, request.payload)
    yield put(hideLoadingAlert())
    yield put(upgradeMembershipSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.ADD_ERROR))
    yield put(upgradeMembershipFail(error))
  }
}

function* fetchAllPurchaseTransactions(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(httpGetPurchaseTransactions, request.payload)
    yield put(hideLoadingAlert())
    yield put(getAllPurchaseTransactionsSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(getAllPurchaseTransactionsFail(error))
  }
}

function* affiliatedsSaga() {
  yield takeEvery(GET_AFFILIATED, fetchAffiliated)
  yield takeEvery(GET_AFFILIATED_SEARCH, fetchAffiliatedSearch)
  yield takeEvery(GET_AFFILIATED_ORDER_LIST, fetchAffiliatedOrderList)
  yield takeEvery(GET_AFFILIATED_MEMBERSHIP_LIST, fetchAffiliatedMembershipList)
  yield takeEvery(GET_AFFILIATED_MEMBERSHIP_DETAIL, fetchAffiliatedMembershipDetail)
  yield takeEvery(UPGRADE_MEMBERSHIP, fetchUpgradeMembership)
  yield takeEvery(GET_ALL_ORDER_LIST, fetchAllOrderList)
  yield takeEvery(GET_ORDER, fetchOrder)
  yield takeEvery(GET_ALL_PURCHASE_TRANSACTIONS, fetchAllPurchaseTransactions)
}




export default affiliatedsSaga
