import React from 'react';
import PropTypes from 'prop-types';
import { Col, Label, Row } from 'reactstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import * as Yup from "yup";
import FormFieldBasic from "pages/Utility/form-field-basic";
import { useState } from 'react';

const PrescriptionUploadPdfForm = ({ pdfFiles, setPdfFiles, onUploadPdfFile, setShowUploadPdf }) => {

    const { t } = useTranslation();

    const [formData, setFormData] = useState({
        filesLength: 0,
    });

    const handleOnChange = event => {
        setFormData({
          ...formData,
          [event.target.name]: event.target.value,
        });
      };

    const { getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        accept: ['application/pdf'],
        onDrop: (acceptedFiles) => {
            acceptedFiles.forEach((file) => {
                const reader = new FileReader()

                reader.onabort = () => console.log('file reading was aborted')
                reader.onerror = () => console.log('file reading has failed')
                reader.onload = (evt) => {
                    const fileByteArray = [];
                    // Do whatever you want with the file contents
                    const arrayBuffer = evt.target.result,
                        array = new Uint8Array(arrayBuffer);
                    for (const a of array) {
                        fileByteArray.push(a);
                    }

                    setPdfFiles([
                        ...pdfFiles,
                        {
                            type: file.name.substring(file.name.lastIndexOf('.') + 1),
                            fileName: file.name.substring(0, file.name.lastIndexOf('.')),
                            file: fileByteArray
                        }
                    ]);

                    setFormData({
                        ...formData,
                        filesLength: 1
                    });
                }
                reader.readAsArrayBuffer(file)
            })
        }
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: formData,
        validationSchema:
            Yup.object().shape({
                filesLength: Yup.number().required().min(1, t('You have to add at least one service')),
            }),
        onSubmit: (values, actions) => {
            actions.setSubmitting(false);
            onUploadPdfFile();
        },
        onReset: (values, helper) => {

        }
    });

    const onDeleteAttachment = pdfFileIndex => {
        setPdfFiles(
            pdfFiles.filter((current, index) => index != pdfFileIndex)
        );
        setFormData({
            ...formData,
            filesLength: 0
        });
    };

    const { touched, errors, values, submitForm, handleBlur, handleSubmit, handleReset } = formik;

    return (
        <SweetAlert
            showCancel
            title={t("Attach files")}
            cancelBtnBsStyle="danger"
            cancelBtnText={t('Cancel')}
            confirmBtnText={t('Save')}
            confirmBtnBsStyle="success"
            onConfirm={() => {
                submitForm();
                
            }}
            onCancel={() => { setShowUploadPdf(false) }}
        >
            <div className="mb-3">
                <Label className="form-label">
                    {t("Attach files")}
                </Label>
                <p className="text-muted">
                    {t(
                        "You can attach any file you think is necessary"
                    )}
                </p>
                <form className="needs-validation" onSubmit={handleSubmit} onReset={handleReset}>
                    <Row>
                        <Col>
                            <div
                                className="dz-message needsclick"
                                {...getRootProps({
                                    className: "dropzone custom-dropzone",
                                })}
                            >
                                <input {...getInputProps()} />
                                <div className="mb-1 text-center">
                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h4 className="text-center">
                                    {t("Drop files here or click to upload.")}
                                </h4>
                            </div>

                            <FormFieldBasic
                                key="filesLength"
                                type="hidden"
                                maxLength={200}
                                fieldName="filesLength"
                                fieldValue={values.filesLength}
                                label={t("")}
                                errors={errors}
                                touched={touched}
                                handleOnChange={handleOnChange}
                                handleBlur={handleBlur}
                            />
                        </Col>
                    </Row>

                    {pdfFiles.map((pdfFile, pdfFileIndex) => (
                        <Row key={pdfFileIndex}>
                            <Col xs={11} className="float-start pt-1">
                                <span>{pdfFile.fileName}.{pdfFile.type}</span>
                            </Col>
                            <Col xs={1}>
                                <strong className="float-end text-end font-size-18">
                                    <i
                                        className={
                                            "text-danger mdi mdi-close-thick dashboard-option"
                                        }
                                        onClick={() => {
                                            onDeleteAttachment(pdfFileIndex);
                                        }}
                                    ></i>
                                </strong>
                            </Col>
                        </Row>
                    ))}
                </form>
            </div>
        </SweetAlert>
    );
}

PrescriptionUploadPdfForm.propTypes = {
    pdfFiles: PropTypes.array.isRequired,
    setPdfFiles: PropTypes.func.isRequired,
    onUploadPdfFile: PropTypes.func.isRequired,
    setShowUploadPdf: PropTypes.func.isRequired
}

export default PrescriptionUploadPdfForm;