import { ADD_QUESTION, ADD_QUESTION_FAIL, ADD_QUESTION_SUCCESS, CLEAR_QUESTIONS_STATE, DELETE_QUESTION, DELETE_QUESTION_FAIL, DELETE_QUESTION_SUCCESS, GET_QUESTIONS_BY_TYPE,
         GET_QUESTIONS_BY_TYPE_FAIL, GET_QUESTIONS_BY_TYPE_SUCCESS, GET_QUESTION_DETAIL, GET_QUESTION_DETAIL_FAIL, GET_QUESTION_DETAIL_SUCCESS, GET_QUESTION_LIST, GET_QUESTION_LIST_FAIL, GET_QUESTION_LIST_SUCCESS, UPDATE_QUESTION, UPDATE_QUESTION_FAIL, UPDATE_QUESTION_SUCCESS } from "./actionTypes"

const INIT_STATE = {
    questions: [],
    success: false,
    loading: false,
    error: {},
    questionDeleted: null,
    questionDetail: null,
    questionCreated: null,
    questionUpdated: null,
  }
  
  const questions = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CLEAR_QUESTIONS_STATE:
        return {
            ...state,
            questions: [],
            success: false,
            loading: false,
            error: {},
            questionDeleted: null,
            questionDetail: null,
            questionCreated: null,
            questionUpdated: null,
        }
        case GET_QUESTIONS_BY_TYPE:
        return {
            ...state,
            questions: [],
            success: false,
            loading: true,
        }
        case GET_QUESTIONS_BY_TYPE_SUCCESS:
        return {
            ...state,
            loading: false,
            success: true,
            questions: action.payload,
        }  
        case GET_QUESTIONS_BY_TYPE_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case GET_QUESTION_LIST:
        return {
            ...state,
            questions: [],
            loading: true,
        } 
        case GET_QUESTION_LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            questions: action.payload,
        }  
        case GET_QUESTION_LIST_FAIL:
        return {
            ...state,
            error: action.payload,
        }
        case GET_QUESTION_DETAIL:
        return {
            ...state,
            questionDetail: undefined,
            loading: true,
        }
        case GET_QUESTION_DETAIL_SUCCESS:
        return {
            ...state,
            loading: false,
            questionDetail: action.payload,
        }  
        case GET_QUESTION_DETAIL_FAIL:
        return {
            ...state,
            error: action.payload,
        }
        case ADD_QUESTION:
        return {
            ...state,
            success: false,
            loading: true,
            questionCreated: undefined
        }
        case ADD_QUESTION_SUCCESS:
        return {
            ...state,
            success: true,
            loading: false,
            questionCreated: action.payload,
        }  
        case ADD_QUESTION_FAIL:
        return {
            ...state,
            error: action.payload,
        }
        case UPDATE_QUESTION:
        return {
            ...state,
            success: false,
            loading: true,
            questionUpdated: undefined
        }
        case UPDATE_QUESTION_SUCCESS:
        return {
            ...state,
            success: true,
            loading: false,
            questionUpdated: action.payload,
        }  
        case UPDATE_QUESTION_FAIL:
        return {
            ...state,
            error: action.payload,
        }
        case DELETE_QUESTION:
        return {
            ...state,
            success: false,
            loading: true,
            questionDeleted: undefined
        }
        case DELETE_QUESTION_SUCCESS:
        return {
            ...state,
            success: true,
            loading: false,
            questionDeleted: action.payload,
        }  
        case DELETE_QUESTION_FAIL:
        return {
            ...state,
            error: action.payload,
        }
        default:
            return state
        }
    }
    
export default questions
      