import { ADD_TERM, ADD_TERM_FAIL, ADD_TERM_SUCCESS, CLEAR_TERMS_STATE, DELETE_TERM, DELETE_TERM_FAIL, DELETE_TERM_SUCCESS, GET_TERMS_BY_TYPE, 
         GET_TERMS_BY_TYPE_FAIL, GET_TERMS_BY_TYPE_SUCCESS, GET_TERM_DETAIL, GET_TERM_DETAIL_FAIL, GET_TERM_DETAIL_SUCCESS, GET_TERM_LIST, GET_TERM_LIST_FAIL, GET_TERM_LIST_SUCCESS, UPDATE_TERM, UPDATE_TERM_FAIL, UPDATE_TERM_SUCCESS } from "./actionTypes"

//Terms
export const clearTerm = () => ({
    type: CLEAR_TERMS_STATE
  })
  
  export const getTermsByType = (companyId, type) => ({
    type: GET_TERMS_BY_TYPE,
    payload: {companyId: companyId, type: type}
  })
  
  export const getTermsByTypeSuccess = invoice => ({
    type: GET_TERMS_BY_TYPE_SUCCESS,
    payload: invoice,
  })
  
  export const getTermsByTypeFail = error => ({
    type: GET_TERMS_BY_TYPE_FAIL,
    payload: error,
  })

  export const getTerms = (request) => ({
    type: GET_TERM_LIST,
    payload: request
  })
  
  export const getTermsSuccess = invoice => ({
    type: GET_TERM_LIST_SUCCESS,
    payload: invoice,
  })
  
  export const getTermsFail = error => ({
    type: GET_TERM_LIST_FAIL,
    payload: error,
  })
  
  //Term Detail
  export const getTermDetail = request => ({
    type: GET_TERM_DETAIL,
    payload: request
  })
  
  export const getTermDetailSuccess = term => ({
    type: GET_TERM_DETAIL_SUCCESS,
    payload: term,
  })
  
  export const getTermDetailFail = error => ({
    type: GET_TERM_DETAIL_FAIL,
    payload: error,
  })
  
  //Add Term
  export const addTerm = request => ({
    type: ADD_TERM,
    payload: request
  })
  
  export const addTermSuccess = term => ({
    type: ADD_TERM_SUCCESS,
    payload: term,
  })
  
  export const addTermFail = error => ({
    type: ADD_TERM_FAIL,
    payload: error,
  })
  
  //Update Term
  export const updateTerm = request => ({
    type: UPDATE_TERM,
    payload: request
  })
  
  export const updateTermSuccess = term => ({
    type: UPDATE_TERM_SUCCESS,
    payload: term,
  })
  
  export const updateTermFail = error => ({
    type: UPDATE_TERM_FAIL,
    payload: error,
  })
  
  //Delete Term
  export const deleteTerm = request => ({
    type: DELETE_TERM,
    payload: request
  })
  
  export const deleteTermSuccess = term => ({
    type: DELETE_TERM_SUCCESS,
    payload: term,
  })
  
  export const deleteTermFail = error => ({
    type: DELETE_TERM_FAIL,
    payload: error,
  })