import { ALERT_MESSAGE } from 'constants/constants';
import { DEFAULT_COMPANY } from 'constants/list-types';
import MembershipForm from 'pages/Management/Commons/membership-form/membership-form';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { clearMembership, getMembershipDetail, showSuccessAlert, updateMembership } from 'store/actions';

const EditMembershipPackage = () => {

    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const membership = useSelector(state => state.memberships.membershipDetail);
    const membershipUpdated = useSelector(state => state.memberships.membershipUpdated);

    useEffect(() => {
        dispatch(getMembershipDetail({ id: id }));
    }, []);

    useEffect(() => {
        if(membershipUpdated) {
            dispatch(showSuccessAlert(ALERT_MESSAGE.UPDATED, () => {
                dispatch(clearMembership());
                history.push(`/membership-package-detail/${id}`);
            }));
        }
        
    }, [membershipUpdated]);

    const onSubmit = (data) => {
        data.id = membership.id;
        data.companyId = DEFAULT_COMPANY;
        data.requireInvitation = data.requireInvitation? 1: 0;
        data.beneficiaryFreeOfCharge = data.beneficiaryFreeOfCharge? 'Y': 'N';
        data.upgrade = data.upgrade? 'Y': 'N';
        data.taxExemption = data.taxExemption? 'Y': 'N';
        data.taxRefund = data.taxRefund? 'Y': 'N';

        dispatch(updateMembership(data));
    }

    const onBack = () => {
        history.push(`/membership-package-detail/${id}`);
    }
    
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t('Title App')}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h2 className="mb-2 font-size-18">{t('Edit Membership')}</h2>
                    </div>
                    
                    <Row>
                        {
                            membership?
                            <MembershipForm 
                                membership={membership}
                                onSubmit={onSubmit}
                                onBack={onBack}
                            />
                            : null
                        }
                        
                    </Row>
                </Container>
            </div>
            
        </React.Fragment> 
    );
}
 
export default EditMembershipPackage;