import { CLEAR_PRINCIPAL_STATE,
         ADD_PRINCIPAL, 
         ADD_PRINCIPAL_FAIL, 
         ADD_PRINCIPAL_SUCCESS, 
         GET_PRINCIPAL_DETAIL,
         GET_PRINCIPAL_DETAIL_SUCCESS,
         GET_PRINCIPAL_DETAIL_FAIL,
         GET_PRINCIPAL_DETAIL_BY_ACCOUNT,
         GET_PRINCIPAL_DETAIL_BY_ACCOUNT_SUCCESS,
         GET_PRINCIPAL_DETAIL_BY_ACCOUNT_FAIL,
         SAVE_PRINCIPAL_ROLES,
         SAVE_PRINCIPAL_ROLES_SUCCESS,
         SAVE_PRINCIPAL_ROLES_FAIL,
         GET_ROLE_PRINCIPAL_LIST,
         GET_ROLE_PRINCIPAL_LIST_SUCCESS,
         GET_ROLE_PRINCIPAL_LIST_FAIL,
         GET_PRINCIPAL_BY_ACCOUNT,
         GET_PRINCIPAL_BY_ACCOUNT_SUCCESS,
         GET_PRINCIPAL_BY_ACCOUNT_FAIL,
         CLEAR_PRINCIPAL_CREATION,
         VERIFY_USER_INFORMATION_SUCCESS,
         VERIFY_USER_INFORMATION,
         VERIFY_USER_INFORMATION_FAIL,
         VERIFY_EMAIL_INFORMATION_RESET,
         VERIFY_EMAIL_INFORMATION_SUCCESS,
         VERIFY_EMAIL_INFORMATION,
         VERIFY_EMAIL_INFORMATION_FAIL} from "./actionTypes"

const INIT_STATE = {
    userCreated: false,
    principalDetail: null,
    principalData: null,
    userInformation: null,
    emailVerification: null,
    rolePrincipalList: [],
    principalRolesCreated: false,
    success: false,
    loading: false,
    verifyLoading: false,
    error: {}
  }
  
  const principals = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CLEAR_PRINCIPAL_STATE:
        return {
            ...state,
            userCreated: false,
            principalDetail: null,
            userInformation: null,
            principalRolesCreated: false,
            success: false,
            loading: false,
            verifyLoading: false,
            error: {}
        }
        case CLEAR_PRINCIPAL_CREATION:
            return {
                ...state,
                userCreated: false,
                userInformation: null,
                verifyLoading: false
            }
        case ADD_PRINCIPAL:
        return {
            ...state,
            userCreated: false,
            loading: true,
        } 
        case ADD_PRINCIPAL_SUCCESS:
        return {
            ...state,
            loading: false,
            userCreated: true,
        }  
        case ADD_PRINCIPAL_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }

        case VERIFY_USER_INFORMATION:
        return {
            ...state,
            userInformation: null,
            verifyLoading: true,
        } 
        case VERIFY_USER_INFORMATION_SUCCESS:
        return {
            ...state,
            verifyLoading: false,
            userInformation: action.payload,
        }  
        case VERIFY_USER_INFORMATION_FAIL:
        return {
            ...state,
            verifyLoading: false,
            error: action.payload,
        }


        case VERIFY_EMAIL_INFORMATION_RESET:
            return {
                ...state,
                emailVerification: null,
            } 

        case VERIFY_EMAIL_INFORMATION:
            return {
                ...state,
                emailVerification: null,
                verifyLoading: true,
            } 
            case VERIFY_EMAIL_INFORMATION_SUCCESS:
            return {
                ...state,
                verifyLoading: false,
                emailVerification: action.payload,
            }  
            case VERIFY_EMAIL_INFORMATION_FAIL:
            return {
                ...state,
                verifyLoading: false,
                error: action.payload,
            }



        case GET_PRINCIPAL_DETAIL:
        return {
            ...state,
            principalDetail: null,
            loading: true,
        } 
        case GET_PRINCIPAL_DETAIL_SUCCESS:
        return {
            ...state,
            loading: false,
            principalDetail: action.payload,
        }  
        case GET_PRINCIPAL_DETAIL_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case GET_PRINCIPAL_DETAIL_BY_ACCOUNT:
        return {
            ...state,
            principalDetail: null,
            loading: true,
        } 
        case GET_PRINCIPAL_DETAIL_BY_ACCOUNT_SUCCESS:
        return {
            ...state,
            loading: false,
            principalDetail: action.payload,
        }  
        case GET_PRINCIPAL_DETAIL_BY_ACCOUNT_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case SAVE_PRINCIPAL_ROLES:
        return {
            ...state,
            principalRolesCreated: false,
            loading: true,
        } 
        case SAVE_PRINCIPAL_ROLES_SUCCESS:
        return {
            ...state,
            loading: false,
            principalRolesCreated: true
        }  
        case SAVE_PRINCIPAL_ROLES_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }

        case GET_PRINCIPAL_BY_ACCOUNT:
        return {
            ...state,
            principalData: null,
            loading: true,
        } 
        case GET_PRINCIPAL_BY_ACCOUNT_SUCCESS:
        return {
            ...state,
            loading: false,
            principalData: action.payload,
        }  
        case GET_PRINCIPAL_BY_ACCOUNT_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }

        case GET_ROLE_PRINCIPAL_LIST:
        return {
            ...state,
            loading: true,
            rolePrincipalList: [] 
        }
        case GET_ROLE_PRINCIPAL_LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            rolePrincipalList: action.payload
        }
        case GET_ROLE_PRINCIPAL_LIST_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload
        }
        default:
            return state
        }
    }
    
export default principals
      