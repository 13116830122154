import { 
    ADD_PROMOTION, 
    ADD_PROMOTION_FAIL, 
    ADD_PROMOTION_SUCCESS, 
    CLEAR_PROMOTIONS_LIST_STATE, 
    DELETE_PROMOTION, 
    DELETE_PROMOTION_FAIL, 
    DELETE_PROMOTION_SUCCESS, 
    GET_PROMOTION_DETAIL, 
    GET_PROMOTION_DETAIL_FAIL, 
    GET_PROMOTION_DETAIL_SUCCESS, 
    GET_PROMOTIONS_LIST, 
    GET_PROMOTIONS_LIST_FAIL, 
    GET_PROMOTIONS_LIST_SUCCESS, 
    UPDATE_PROMOTION, 
    UPDATE_PROMOTION_FAIL, 
    UPDATE_PROMOTION_SUCCESS, 
    SEND_PROMOTION_NOTIFICATION,
    SEND_PROMOTION_NOTIFICATION_SUCCESS,
    SEND_PROMOTION_NOTIFICATION_FAIL
} from "./actionTypes"

const INIT_STATE = {
    promotions: [],
    success: false,
    loading: false,
    error: {},
    promotionDeleted: false,
    promotionDetail: null,
    promotionCreated: false,
    promotionNotificationSent: false,
    promotionUpdated: false,
  }
  
  const promotionsList = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CLEAR_PROMOTIONS_LIST_STATE:
        return {
            ...state,
            promotions: [],
            success: false,
            loading: false,
            error: {},
            promotionDeleted: false,
            promotionDetail: null,
            promotionCreated: false,
            promotionNotificationSent: false,
            promotionUpdated: false,
        }
        case GET_PROMOTIONS_LIST:
        return {
            ...state,
            promotions: [],
            loading: true,
        } 
        case GET_PROMOTIONS_LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            promotions: action.payload,
        }  
        case GET_PROMOTIONS_LIST_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case GET_PROMOTION_DETAIL:
        return {
            ...state,
            promotionDetail: undefined,
            loading: true,
        }
        case GET_PROMOTION_DETAIL_SUCCESS:
        return {
            ...state,
            loading: false,
            promotionDetail: action.payload,
        }  
        case GET_PROMOTION_DETAIL_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case ADD_PROMOTION:
        return {
            ...state,
            success: false,
            loading: true,
            promotionCreated: undefined
        }
        case ADD_PROMOTION_SUCCESS:
        return {
            ...state,
            success: true,
            loading: false,
            promotionCreated: action.payload,
        }  
        case ADD_PROMOTION_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case UPDATE_PROMOTION:
        return {
            ...state,
            success: false,
            loading: true,
            promotionUpdated: undefined
        }
        case UPDATE_PROMOTION_SUCCESS:
        return {
            ...state,
            success: true,
            loading: false,
            promotionUpdated: action.payload,
        }  
        case UPDATE_PROMOTION_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case DELETE_PROMOTION:
        return {
            ...state,
            success: false,
            loading: true,
            promotionDeleted: undefined
        }
        case DELETE_PROMOTION_SUCCESS:
        return {
            ...state,
            success: true,
            loading: false,
            promotionDeleted: action.payload,
        }  
        case DELETE_PROMOTION_FAIL:
        return {
            ...state,
            error: action.payload,
        }
        case SEND_PROMOTION_NOTIFICATION:
        return {
            ...state,
            success: false,
            loading: true,
            promotionNotificationSent: false
        }
        case SEND_PROMOTION_NOTIFICATION_SUCCESS:
        return {
            ...state,
            success: true,
            loading: false,
            promotionNotificationSent: action.payload,
        }  
        case SEND_PROMOTION_NOTIFICATION_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        default:
            return state
        }
    }
    
export default promotionsList;
      