import DataTable from 'components/Common/DataTable/datatable';
import { toDateStringFormat } from 'helpers/utils';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

const DeliveryPackagesList = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const deliveryPackages = useSelector(state => state.deliveryPackages.deliveryPackageList);
    const [data, setData] = useState([]);

    useEffect(() => {
        if(deliveryPackages) {
            setData(deliveryPackages.map(current => ({
                id: current.id,
                invoiceId: current.invoiceId,
                pickupDate: toDateStringFormat(current.productOrderPickups[0].pickupDate),
                patientFullName: current.productOrderPickups[0].pickupPersonFullname,
                pickupStatus: current.productOrderPickups[0].pickupStatus
            })));
        }
    }, [deliveryPackages]);

    const getPickupStatus = pickupStatus => {
        switch (pickupStatus) {
          case "PEN":
            return "Pending";
          case "DON":
            return "Done";
          case "FIN":
            return "Done";
          case "CAN":
            return "Cancelled";
          default:
            return pickupStatus;
        }
      };

    const columns = [
        {
            text: t('Invoice'),
            dataField: 'invoiceId',
            sort: true,
            formatter: (cellContent, row) => <React.Fragment>{row.invoiceId}</React.Fragment>
        },
        {
            text: t('Date'),
            dataField: 'pickupDate',
            sort: true,
            formatter: (cellContent, row) => <React.Fragment>{row.pickupDate}</React.Fragment>
        },
        {
            text: t('Patient'),
            dataField: 'patientFullName',
            sort: true,
            formatter: (cellContent, row) => <React.Fragment>{row.patientFullName}</React.Fragment>
        },
        {
            text: t('Status'),
            dataField:"pickupStatus",
            sort: true,
            formatter: (cellContent, row) => <React.Fragment>{t(getPickupStatus(row.pickupStatus))}</React.Fragment>
        },
        {
            dataField: 'menu',
            isDummyField: true,
            editable: false,
            text: '',
            formatter: (cellContent, row) => (
                <div className="d-flex gap-3">
                    <Button type='button' color="success" onClick={() => viewDetail(row)}>
                        {t('View Detail')}
                        <i className="mdi mdi-arrow-riht" />
                    </Button>
                </div>
            ),
        }
    ];

    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        }
    ];

    const viewDetail = order => {
        history.push(`/delivery-packages-detail/${order.id}`);
    }
    return (
        <React.Fragment>
            {/* factura, fecha, paciente */}
            <Row>
                <Col lg={12}>
                    <DataTable
                        data={data}
                        keyField="id"
                        columns={columns}
                        sort={defaultSorted}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default DeliveryPackagesList;