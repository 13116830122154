import PageHeader from 'components/Common/PageLayout/page-header';
import PageTitle from 'components/Common/PageLayout/page-title';
import RewardProgram from 'pages/Management/Reward/reward-program';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { cancelPrincipal, getAffiliated, getAffiliatedMembershipList, getCatalogs, lockUser } from 'store/actions';
import CustomerProfile from '../CustomerProfile/customer-profile';
import AffiliatedMembershipList from './affiliated-membership-list';
import SweetAlert from 'react-bootstrap-sweetalert';

const AffiliatedDetailPage = () => {

  const { id } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    dispatch(getCatalogs());
  }, []);
  useEffect(() => {
    if (id) {
      dispatch(getAffiliated(id));
      dispatch(getAffiliatedMembershipList(id));
    }
  }, [id]);

  const customerProfile = useSelector(state => state.affiliateds.affiliated);
  const membershipList = useSelector(state => state.affiliateds.affiliatedMembershipList);
  const results = membershipList.map(current => ({ ...current, membershipName: current.membership.name, currencyCode: current.membership.currencyCode, membershipPrice: current.membership.price, maxBeneficiaryQuantity: current.membership.maxBeneficiaryQuantity, validityMonths: current.membership.validityMonths }));

  const confirmAction = () => {
    setShowConfirm(true);
}

const onSend = () => {
    setShowConfirm(false);
    const payload = {
      accountCode: id
    };
    dispatch(cancelPrincipal(payload));
    
    setTimeout(()=>{
      history.push(`/membership-customer-search`);
  }, 1200);
}

  return (
    <React.Fragment>
      <div className="page-content">
        <PageTitle />
        <Container fluid>
          {/* Render Breadcrumbs */}

          <PageHeader title='Affiliated Detail'>

            <Button
              color="primary"
              className="button mr-1"
              onClick={() => { history.push(`/membership-customer-profile-upgrade/${id}`) }}>
              <i className="mdi mdi-plus-circle-outline me-1"></i>{t('New Membership')}
            </Button>

            <Button
              color="primary"
              className="button mr-1"
              onClick={() => { history.push(`/membership-customer-profile-status/${id}`) }}>
              <i className="mdi mdi-medical-bag me-1"></i>{t('Services Consumed')}
            </Button>

            <Button
              color="light"
              className="button mr-1"
              onClick={() => { history.push(`/membership-customer-search`) }}>
              <i className="mdi mdi-arrow-left me-1"></i>{t('Back')}
            </Button>

          </PageHeader>

          <Row>
            <Col xl={12}>
              {customerProfile ? <CustomerProfile customerProfile={customerProfile} /> : null}
            </Col>
          </Row>

          <Row>
            <Col xl={12}>
              <AffiliatedMembershipList membershipList={results} />
            </Col>
          </Row>

          <Row>
            <Col sm={12}>
              <Button
                color="light"
                className="button mr-1"
                onClick={confirmAction}>
                <i className="mdi mdi-medical-bag me-1"></i>{t('Lock Account')}
              </Button>
            </Col>
          </Row>


        </Container>
      </div>

      {showConfirm ?
        <SweetAlert
            title={t('Lock Account Confirm')}
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            confirmBtnText={t('Yes')}
            cancelBtnText={t('No')}
            onConfirm={() => {
                onSend();
            }}
            onCancel={() => {
                setShowConfirm(false);
            }}
        >

        </SweetAlert>
      : null}

    </React.Fragment>
  );
}

export default AffiliatedDetailPage;