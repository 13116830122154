import {
  GET_MEDICAL_CENTER_LIST,
  GET_MEDICAL_CENTER_LIST_FAIL,
  GET_MEDICAL_CENTER_LIST_SUCCESS
} from "./actionTypes"

//Medical Center List
export const getMedicalCenterList = () => ({
  type: GET_MEDICAL_CENTER_LIST
})

export const getMedicalCenterListSuccess = response => ({
  type: GET_MEDICAL_CENTER_LIST_SUCCESS,
  payload: response,
})

export const getMedicalCenterListFail = error => ({
  type: GET_MEDICAL_CENTER_LIST_FAIL,
  payload: error,
})
