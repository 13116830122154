import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import * as moment from "moment";
import {
  Button,
  Row,
  Badge,
} from "reactstrap";
import { getAllOrderList } from "store/Affiliated/actions"
import { withTranslation } from "react-i18next"
import PageBody from "components/Common/PageLayout/page-body";
import DataTable from "components/Common/DataTable/datatable";
import CustomerOrderTransactionFilter from "./filter/customer-order-transaction-filter";

const MAX_WIDTH_FOR_MD = 1300;
const MAX_WIDTH_FOR_SM = 768;
class CustomerOrderTransactions extends Component {

  constructor(props) {
    super(props);
    this.node = React.createRef();

    this.state = {
      viewmodal: false,
      modal: false,
      modal_large: false,
      selectedInvoice: null,
      orders: [],
      order: "",
      currentUserAccount: null,
      deleteModal: false,
      isMobile: /iPhone|iPad|iPod|Android/i.test(navigator.userAgent),
    };
  }

  getWidth() {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  }

  takeColumns() {

    const columnsSm = [
      {
        dataField: "customer.identification",
        text: "Afiliado",
        sort: true,
        formatter: (cellContent, row) => (
          <div>
            <span className="text-body fw-bold">Fecha: </span>{row.stringDate}<br />
            <span className="text-body fw-bold">Afiliado: </span>{row.customer.identification}<br />
            <span className="text-body fw-bold">{' ' + row.customer.firstName + ' ' + row.customer.firstSurname}</span><br />
            <Link to={"/membership-customer-order/" + row.id}>
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded"
              >
                Ver
              </Button>
            </Link>
          </div>
        ),
      },
      {
        dataField: null,
        text: "Detalle",
        sort: true,
        formatter: (cellContent, row) => (
          <div>
            <div style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
              <p className="fw-bold">
                {row.businessUnitType && row.businessUnitType === 'MEM' ?
                  'Membresia' :
                  (row.businessUnitType && row.businessUnitType === 'LAB' ?
                    'Laboratorio' : null)}. {row.detailList && row.detailList.length > 0 ?
                      (row.businessUnitType && row.businessUnitType === 'LAB' ? row.detailList[0].description + " / " + (row.detailList[0].quantity > 1 ? row.detailList[0].quantity + " pruebas" : row.detailList[0].quantity + " prueba") : row.detailList[0].description)
                      : null}
              </p>
            </div>
          <br />
          <span className="text-body fw-bold">Total:  {row.totalAmount.toFixed(2) + " " + row.currencyCode}</span>
          <br />

          <span>Pago: </span>
          {row.invoicePayments && row.invoicePayments.length > 0 && row.invoicePayments[0] 
                            ? (row.invoicePayments[0].status === 'ERR' ? 
                              (<Badge
                              className={"font-size-12 badge-soft-danger"}
                              color={"danger"}
                              pill
                            >
                              ERROR
                            </Badge>) : (<Badge
                              className={"font-size-12 badge-soft-success"}
                              color={"success"}
                              pill
                            >
                              REALIZADO
                            </Badge>)) : (<Badge
                              className={"font-size-12 badge-soft-danger"}
                              color={"danger"}
                              pill
                            >
                              PENDIENTE
                            </Badge>)}
                            <br />
            <span>Factura: </span>               
            {!row.integrationStatus || row.integrationStatus === 'APR' ? (
                (row.sikuInvoiceReference ? row.sikuInvoiceReference : '')
            ) : (
              <Badge
                className={"font-size-12 badge-soft-danger"}
                color={"danger"}
                pill
              >
                ERROR
              </Badge>

            )}
          </div>
        ),
      }
    ]

    const columnsMd = [
      {
        dataField: "stringDate",
        text: "Fecha",
        sort: true,
        formatter: (cellContent, row) =>
          <>
            {row.stringDate}
          </>
      },
      {
        dataField: "customer.identification",
        text: "Identificación",
        sort: true,
        formatter: (cellContent, row) => (
          <>
           <Link to={"/membership-customer-profile/" + row.customer.accountCode}>
            <Button
              type="button"
              color="light"
              className="btn-sm btn-rounded"
            >
              <i className="bx bx-user" />
            </Button>
          </Link> 
          <span className="fw-bold">{' ' + row.customer.firstName + ' ' + row.customer.firstSurname}</span><br />
          {row.customer.identification}
          </>
        ),
      },
      {
        dataField: null,
        text: "Detalle",
        sort: true,
        formatter: (cellContent, row) => (
          <div>

          <div style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
            <p className="fw-bold">
            {row.businessUnitType && row.businessUnitType === 'MEM' ?
              'Membresia' :
              (row.businessUnitType && row.businessUnitType === 'LAB' ?
                'Laboratorio' : null)}. {row.detailList && row.detailList.length > 0 ?
                  (row.businessUnitType && row.businessUnitType === 'LAB' ? row.detailList[0].description + " / " + (row.detailList[0].quantity > 1 ? row.detailList[0].quantity + " pruebas" : row.detailList[0].quantity + " prueba") : row.detailList[0].description)
                  : null}<br />
            </p>
          </div>

          Pago: 
          {row.invoicePayments && row.invoicePayments.length > 0 && row.invoicePayments[0] 
                          ? (row.invoicePayments[0].status === 'ERR' ? 
                            (<Badge
                            className={"font-size-12 badge-soft-danger"}
                            color={"danger"}
                            pill
                          >
                            ERROR
                          </Badge>) : (<Badge
                            className={"font-size-12 badge-soft-success"}
                            color={"success"}
                            pill
                          >
                            REALIZADO
                          </Badge>)) : (<Badge
                            className={"font-size-12 badge-soft-danger"}
                            color={"danger"}
                            pill
                          >
                            PENDIENTE
                          </Badge>)}<br />

            Factura: 
            {!row.integrationStatus || row.integrationStatus === 'APR' ? (
                <span className="text-body fw-bold">{'  '+(row.sikuInvoiceReference ? row.sikuInvoiceReference : '')}</span>
            ) : (
              <Badge
                className={"font-size-12 badge-soft-danger"}
                color={"danger"}
                pill
              >
                ERROR
              </Badge>

            )}

          </div>
        ),
      },
      {
        dataField: "totalAmount",
        text: "Total",
        sort: true,
        style: {
          textAlign: 'right'
        },
        formatter: (cellContent, row) => (
          <>
            <span className="fw-bold">{row.totalAmount.toFixed(2) + " " + row.currencyCode}<br /></span>



          </>
        ),
      },
      {
        dataField: null,
        isDummyField: true,
        text: "",
        sort: false,
        formatter: (cellContent, row) => (
          <>

            <Link to={"/membership-customer-order/" + row.id}>
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded"
              >
                Ver
              </Button>
            </Link>


          </>

        ),
      }
    ]

    const columnsLg = [
      /*{
        dataField: "id",
        text: "No.",
        sort: true,
        formatter: (cellContent, row) => <React.Fragment>
          <Link to="#" className="text-body fw-bold">
            {row.id}
          </Link></React.Fragment>
      },*/
      {
        dataField: "stringDate",
        text: "Fecha",
        sort: true,
        formatter: (cellContent, row) =>
          <>
            {row.stringDate}
          </>
      },
      {
        dataField: "customer.identification",
        text: "Afiliado",
        sort: true,
        formatter: (cellContent, row) => (
          <>
            <Link to={"/membership-customer-profile/" + row.customer.accountCode}>
              <Button
                type="button"
                color="light"
                className="btn-sm btn-rounded"
              >
                <i className="bx bx-user" />
              </Button>
          </Link> 
          <span className="fw-bold">{' ' + row.customer.firstName + ' ' + row.customer.firstSurname}</span><br />
          {row.customer.identification}
          </>
        ),
      },
      {
        dataField: null,
        text: "Detalle",
        sort: true,
        formatter: (cellContent, row) => (
          <div style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
            <p>
            {row.businessUnitType && row.businessUnitType === 'MEM' ?
              'Membresia' :
              (row.businessUnitType && row.businessUnitType === 'LAB' ?
                'Laboratorio' : null)}. {row.detailList && row.detailList.length > 0 ?
                  (row.businessUnitType && row.businessUnitType === 'LAB' ? row.detailList[0].description + " / " + (row.detailList[0].quantity > 1 ? row.detailList[0].quantity + " pruebas" : row.detailList[0].quantity + " prueba") : row.detailList[0].description)
                  : null}
            </p>
          </div>
        ),
      },
      {
        dataField: "totalAmount",
        text: "Total",
        sort: true,
        style: {
          textAlign: 'right'
        },
        formatter: (cellContent, row) => (
          <>
            <span className="fw-bold">{row.totalAmount.toFixed(2) + " " + row.currencyCode}</span>
          </>
        ),
      },
      {
        dataField: "paymentStatus",
        text: "Pago",
        sort: true,
        formatter: (cellContent, row) => (
          <>

            {row.invoicePayments && row.invoicePayments.length > 0 && row.invoicePayments[0] 
                            ? (row.invoicePayments[0].status === 'ERR' ? 
                              (<Badge
                              className={"font-size-12 badge-soft-danger"}
                              color={"danger"}
                              pill
                            >
                              ERROR
                            </Badge>) : (<Badge
                className={"font-size-12 badge-soft-success"}
                color={"success"}
                pill
              >
                REALIZADO
                            </Badge>)) : (<Badge
                              className={"font-size-12 badge-soft-danger"}
                              color={"danger"}
                pill
              >
                PENDIENTE
                            </Badge>)}
   
          </>
        ),
      },
      {
        dataField: "integrationStatus",
        text: "Factura",
        sort: true,
        formatter: (cellContent, row) => (
          <>
            {!row.integrationStatus || row.integrationStatus === 'APR' ? (
              <div className="display-linebreak">
                {row.sikuInvoiceReference}
              </div>
            ) : (
              <Badge
                className={"font-size-12 badge-soft-danger"}
                color={"danger"}
                pill
              >
                ERROR
              </Badge>

            )}
          </>
        ),
      },
      {
        dataField: null,
        isDummyField: true,
        text: "",
        sort: false,
        formatter: (cellContent, row) => (
          <>

            <Link to={"/membership-customer-order/" + row.id}>
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded"
              >
                Ver
              </Button>
            </Link>


          </>

        ),
      }
    ]
    
    const currentWith = this.getWidth();
    return currentWith < MAX_WIDTH_FOR_SM  ? columnsSm : 
            currentWith < MAX_WIDTH_FOR_MD  ? columnsMd : columnsLg;
  }

  componentDidMount() {
    const {
      match: { params },
      onGetAffiliatedOrderList,
    } = this.props
    if (params && params.id) {
      this.setState({ currentUserAccount: params.id });
    }
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { affiliatedOrderList, t } = this.props;

    const pageOptions = {
      sizePerPage: 20,
      totalSize: affiliatedOrderList.length,
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: "documentNumber",
        order: "desc",
      },
    ];

    const selectRow = {
      mode: "checkbox",
    };



    return (
      <React.Fragment>
        <PageBody
          title={t("Transactions")}
          actions={(
            <Button
              color="light"
              className="button"
              onClick={() => {
                this.props.history.push("/dashboard");
              }}
            >
              <i className="mdi mdi-arrow-left me-1"></i>
              {t("Back")}
            </Button>
          )}
        >



        <CustomerOrderTransactionFilter />

          <Row>
            <DataTable
              columns={this.takeColumns()}
              data={affiliatedOrderList}
              keyField='id'
              pageSize={50}
            />
          </Row>
        </PageBody>
      </React.Fragment>
    );
  }
}

CustomerOrderTransactions.propTypes = {
  affiliatedOrderList: PropTypes.array,
  onGetAffiliatedOrderList: PropTypes.func,
  match: PropTypes.object,
  t: PropTypes.any,
  className: PropTypes.any,
  history: PropTypes.any,
  loading: PropTypes.bool,
};

const mapStateToProps = ({ affiliateds }) => ({
  loading: affiliateds.loading,
  affiliatedOrderList: affiliateds.allOrderList,
})

const mapDispatchToProps = dispatch => ({
  onGetAffiliatedOrderList: () => dispatch(getAllOrderList()),
  onAddNewTest: (delivery, history) => dispatch(saveInvoiceDetailDelivery(delivery, history))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(CustomerOrderTransactions)));
