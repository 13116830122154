import { numberFormat } from 'helpers/utils';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardTitle, Col, Row, Table } from 'reactstrap';
import cancelMembershipContext from '../context/cancelMembershipContext';

const CancelMembershipDebit = () => {
    
    const { t } = useTranslation();
    const membershipContext = useContext(cancelMembershipContext);
    const { membershipDetail, membershipDebt, getCurrencySymbol } = membershipContext;

    return (
        <Row>
            <Col xl="7">
                <Card>
                    <CardBody>
                        <CardTitle className="mb-4 h4">{t('Membership')}</CardTitle>

                        <div className="table-responsive">
                            <Table className="table mb-0">
                                <tbody>
                                    {membershipDetail ?

                                        <tr key={"membership"}>
                                            <td><img src={membershipDetail.membership.picture} alt="" className="img-fluid" /></td>
                                            <td colSpan={4}>
                                                <strong>{t('Name')}</strong>
                                                <p>{membershipDetail.membership.name}</p>
                                                <strong>{t('Beneficiaries')}</strong>
                                                <p>{membershipDetail.membership.maxBeneficiaryQuantity}</p>
                                                <strong>{t('Price')}</strong>
                                                <p>{getCurrencySymbol(membershipDetail.membership.currencyCode)}{membershipDetail.membership.price}</p>
                                                <strong>{t('Minimum Validity Period')}</strong>
                                                <p>{membershipDetail.membership.validityMonths} meses</p>

                                            </td>
                                        </tr>
                                        : null}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>


            </Col>
            <Col xl={5}>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-4 h4">{t('Pending')}</CardTitle>
                        <div className="table-responsive">
                            <Table className="table mb-0">
                                <tbody>
                                    {membershipDebt && membershipDetail ?

                                        <tr key={"membership"}>
                                            <td colSpan={4}>
                                                <strong>{t('Subtotal pending')}</strong>
                                                <p>{getCurrencySymbol(membershipDebt.currencyCode ? membershipDebt.currencyCode : membershipDetail.membership.currencyCode)}{numberFormat(membershipDebt.subTotalPendingAmount)}</p>
                                                <strong>{t('Tax')}</strong>
                                                <p>{getCurrencySymbol(membershipDebt.currencyCode ? membershipDebt.currencyCode : membershipDetail.membership.currencyCode)}{numberFormat(membershipDebt.totalPendingTaxAmount)}</p>
                                                <strong>{t('Total pending')}</strong>
                                                <p>{getCurrencySymbol(membershipDebt.currencyCode ? membershipDebt.currencyCode : membershipDetail.membership.currencyCode)}{numberFormat(membershipDebt.totalPendingAmount)}</p>
                                            </td>
                                        </tr>
                                        : null}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    );
}

export default CancelMembershipDebit;