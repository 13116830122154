import { ALERT_MESSAGE } from "constants/constants"
import { 
  httpAddQuoterMembership,
  httpDeleteQuoterMembership,
  httpGetQuoterMembershipDetail,
  httpGetQuoterMemberships,
  httpUpdateQuoterMembership
} from "helpers/Api/api-helper"
import { call, put, takeEvery } from "redux-saga/effects"
import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions"
import {
  getQuoterMembershipsSuccess,
  getQuoterMembershipsFail,
  getQuoterMembershipDetailSuccess,
  getQuoterMembershipDetailFail,
  addQuoterMembershipSuccess,
  addQuoterMembershipFail,
  updateQuoterMembershipSuccess,
  updateQuoterMembershipFail,
  deleteQuoterMembershipSuccess,
  deleteQuoterMembershipFail
} from "./actions"
import { 
  ADD_QUOTER_MEMBERSHIP,
  DELETE_QUOTER_MEMBERSHIP,
  GET_QUOTER_MEMBERSHIP_DETAIL,
  GET_QUOTER_MEMBERSHIP_LIST,
  UPDATE_QUOTER_MEMBERSHIP
 } from "./actionTypes"



function* getQuoterMemberships(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetQuoterMemberships, request.payload)
    yield put(hideLoadingAlert())
    yield put(getQuoterMembershipsSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getQuoterMembershipsFail(error))
  }
}

function* getQuoterMembershipDetail(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetQuoterMembershipDetail, request.payload)
    yield put(hideLoadingAlert())
    yield put(getQuoterMembershipDetailSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_DETAIL_ERROR))
    yield put(getQuoterMembershipDetailFail(error))
  }
}

function* addQuoterMembership(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpAddQuoterMembership, request.payload)
    yield put(hideLoadingAlert(ALERT_MESSAGE.SAVING))
    yield put(addQuoterMembershipSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.ADD_ERROR))
    yield put(addQuoterMembershipFail(error))
  }
}

function* updateQuoterMembership(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpUpdateQuoterMembership, request.payload)
    yield put(hideLoadingAlert(ALERT_MESSAGE.SAVING))
    yield put(updateQuoterMembershipSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.UPDATE_ERROR))
    yield put(updateQuoterMembershipFail(error))
  }
}

function* deleteQuoterMembership(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpDeleteQuoterMembership, request.payload)
    yield put(hideLoadingAlert(ALERT_MESSAGE.DELETING))
    yield put(deleteQuoterMembershipSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.DELETE_ERROR))
    yield put(deleteQuoterMembershipFail(error))
  }
}

function* quoterMembershipsSaga() {
    yield takeEvery(GET_QUOTER_MEMBERSHIP_LIST, getQuoterMemberships)
    yield takeEvery(GET_QUOTER_MEMBERSHIP_DETAIL, getQuoterMembershipDetail)
    yield takeEvery(ADD_QUOTER_MEMBERSHIP, addQuoterMembership)
    yield takeEvery(UPDATE_QUOTER_MEMBERSHIP, updateQuoterMembership)
    yield takeEvery(DELETE_QUOTER_MEMBERSHIP, deleteQuoterMembership)
  }
  
  export default quoterMembershipsSaga