/* QUOTER_MEMBERSHIPS */
export const CLEAR_QUOTER_MEMBERSHIPS_STATE = "CLEAR_QUOTER_MEMBERSHIPS_STATE" 

export const GET_QUOTER_MEMBERSHIP_LIST = "GET_QUOTER_MEMBERSHIP_LIST"
export const GET_QUOTER_MEMBERSHIP_LIST_FAIL = "GET_QUOTER_MEMBERSHIP_LIST_FAIL"
export const GET_QUOTER_MEMBERSHIP_LIST_SUCCESS = "GET_QUOTER_MEMBERSHIP_LIST_SUCCESS"

export const GET_QUOTER_MEMBERSHIP_DETAIL = "GET_QUOTER_MEMBERSHIP_DETAIL"
export const GET_QUOTER_MEMBERSHIP_DETAIL_FAIL = "GET_QUOTER_MEMBERSHIP_DETAIL_FAIL"
export const GET_QUOTER_MEMBERSHIP_DETAIL_SUCCESS = "GET_QUOTER_MEMBERSHIP_DETAIL_SUCCESS"

export const ADD_QUOTER_MEMBERSHIP = "ADD_QUOTER_MEMBERSHIP"
export const ADD_QUOTER_MEMBERSHIP_FAIL = "ADD_QUOTER_MEMBERSHIP_FAIL"
export const ADD_QUOTER_MEMBERSHIP_SUCCESS = "ADD_QUOTER_MEMBERSHIP_SUCCESS"

export const UPDATE_QUOTER_MEMBERSHIP = "UPDATE_QUOTER_MEMBERSHIP"
export const UPDATE_QUOTER_MEMBERSHIP_FAIL = "UPDATE_QUOTER_MEMBERSHIP_FAIL"
export const UPDATE_QUOTER_MEMBERSHIP_SUCCESS = "UPDATE_QUOTER_MEMBERSHIP_SUCCESS"

export const DELETE_QUOTER_MEMBERSHIP = "DELETE_QUOTER_MEMBERSHIP"
export const DELETE_QUOTER_MEMBERSHIP_FAIL = "DELETE_QUOTER_MEMBERSHIP_FAIL"
export const DELETE_QUOTER_MEMBERSHIP_SUCCESS = "DELETE_QUOTER_MEMBERSHIP_SUCCESS"
