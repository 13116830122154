import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'reactstrap';
import PageHeader from './page-header';
import PageTitle from './page-title';

const PageBody = ({ title, actions, children }) => {
    return (
        <div className="page-content">
            <PageTitle />
            <Container fluid>
                <PageHeader title={title}>
                    {actions}
                </PageHeader>
                {children}
            </Container>
        </div>
    );
}

PageBody.propTypes = {
    title: PropTypes.string.isRequired,
    actions: PropTypes.any,
    children: PropTypes.any
}

export default PageBody;