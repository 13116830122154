import PageBody from 'components/Common/PageLayout/page-body';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import AffiliatedMembershipCancelWizard from './affiliated-membership-cancel-wizard';
import CancelMembershipState from './context/cancelMembershipState';



const AffiliatedMembershipCancelPage = () => {

    const { account, membershipId } = useParams();
    const { t } = useTranslation();
    const history = useHistory();

    return (
        <React.Fragment>
            <CancelMembershipState>
                <PageBody
                    title='Cancel Membership'
                    actions={(
                        <Button color="light" className="button mr-1"
                            onClick={() => { history.push(`/membership-customer-detail/${account}/${membershipId}`) }}>
                            <i className="mdi mdi-arrow-left me-1"></i>{t('Back')}
                        </Button>
                    )}
                >
                    <AffiliatedMembershipCancelWizard />
                </PageBody>
            </CancelMembershipState>
        </React.Fragment>
    );
}

export default AffiliatedMembershipCancelPage;