import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardBody, Button } from "reactstrap";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import FormField from "pages/Utility/form-field";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { getRealms } from "store/actions";

const RoleForm = ({ onSubmit, onBack, role }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  Yup.setLocale({
    mixed: {
      required: t("This value is required"),
    },
    number: {
      min: t("The value must be greater than or equal to zero"),
    },
  });

  const realms = useSelector(state => state.roles.realms);

  useEffect(() => {
    dispatch(getRealms());
  }, []);

  const [formData, setFormData] = useState({
    realmId: role && role.realm.id ? role.realm.id : "",
    code: role && role.code ? role.code : "",
    description: role && role.description ? role.description : "",
  });

  const { realmId, code, description } = formData;

  const handleOnChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <React.Fragment>
      <Row>
        <Col xl={{ size: 10, offset: 1 }}>
          <Card>
            <CardBody>
              {/* <CardTitle className="h5">Floating labels</CardTitle> */}
              {/* <p className="card-title-desc">Create beautifully simple form labels that float over your input fields.</p> */}

              <Formik
                enableReinitialize={true}
                initialValues={formData}
                validationSchema={Yup.object().shape({
                  realmId: Yup.string().required(),
                  code: Yup.string().required(),
                  description: Yup.string().required(),
                })}
                onSubmit={(values, actions) => {
                  actions.setSubmitting(false);
                  onSubmit(formData);
                }}
              >
                {({ errors, status, touched }) => (
                  <Form className="needs-validation">
                    <Row>
                      <Col md={6}>
                        <FormField
                          key="code"
                          type="text"
                          maxLength={45}
                          fieldName="code"
                          fieldValue={code}
                          label={t("Code")}
                          errors={errors}
                          touched={touched}
                          handleOnChange={handleOnChange}
                        />
                      </Col>
                      <Col md={6}>
                        <FormField
                          key="description"
                          type="text"
                          maxLength={45}
                          fieldName="description"
                          fieldValue={description}
                          label={t("Description")}
                          errors={errors}
                          touched={touched}
                          handleOnChange={handleOnChange}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormField
                          key="realmId"
                          type="select"
                          fieldName="realmId"
                          fieldValue={realmId}
                          label={t("Realm")}
                          errors={errors}
                          touched={touched}
                          handleOnChange={handleOnChange}
                        >
                          <option key="realmSelected" value="">
                            {t("Not Selected")}
                          </option>
                          {realms.map(current => {
                            return (
                              <option key={current.id} value={current.id}>
                                {t(current.description)}
                              </option>
                            );
                          })}
                        </FormField>
                      </Col>
                    </Row>

                    <div className="button-items">
                      <Button type="submit" color="primary" className="button">
                        <i className="mdi mdi-content-save me-1"></i>
                        {t("Save")}
                      </Button>
                      <Button
                        color="light"
                        className="button mr-1"
                        onClick={() => {
                          onBack();
                        }}
                      >
                        <i className="mdi mdi-arrow-left me-1"></i>
                        {t("Back")}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

RoleForm.propTypes = {
  onSubmit: PropTypes.func,
  role: PropTypes.any,
  onBack: PropTypes.func,
};

export default RoleForm;

