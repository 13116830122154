import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, NavLink, NavItem, Row, TabContent, TabPane, Button } from 'reactstrap';
import classnames from "classnames";
import { useTranslation } from 'react-i18next';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import FormField from 'pages/Utility/form-field';
import { useDispatch, useSelector } from 'react-redux';
import { changePasswordRequestCode, changePasswordUpdate, changePasswordValidateCode, clearChangePassword, showSuccessAlert } from 'store/actions';
import { Link, useHistory } from 'react-router-dom';
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import { ALERT_MESSAGE } from 'constants/constants';
import passwordImg from "../../assets/images/vitae/password.png";

const RecoveryPassword = () => {

    const [activeTab, setActiveTab] = useState(1);
    const [passedSteps, setPassedSteps] = useState([]);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const verificationCodeRequested = useSelector(state => state.changePasswords.verificationCodeRequested);
    const token = useSelector(state => state.changePasswords.token);
    const passwordChanged = useSelector(state => state.changePasswords.passwordChanged);

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
          if (tab >= 1 && tab <= 3) {
            var modifiedSteps = [...passedSteps, tab];
            setActiveTab(tab);
            setPassedSteps(modifiedSteps);
          }
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
          event.preventDefault();
        }
      }

    const [formData, setFormData] = useState({
        email: '',
        verificationCode: '',
        password: '',
        passwordConfirmation: ''
    });

    const [action, setAction] = useState('');

    const { email, verificationCode, password, passwordConfirmation } = formData;

    useEffect(() => {
        if(verificationCodeRequested) {
            toggleTab(2);
        }
    }, [verificationCodeRequested]);

    useEffect(() => {
        if(token) {
            toggleTab(3);
        }
    }, [token]);

    useEffect(() => {
        if(passwordChanged) {
            dispatch(showSuccessAlert(ALERT_MESSAGE.UPDATED, () => {
                dispatch(clearChangePassword());
                history.push(`/login`);
            }));
        }
    }, [passwordChanged]);

    useEffect(() => {
        function handleKeyPress(event) {
          if (event.key === "Enter") {
            event.preventDefault();
          }
        }
    
        document.addEventListener("keydown", handleKeyPress);
    
        return () => {
          document.removeEventListener("keydown", handleKeyPress);
        };
      }, []);

    const getValidator = () => {
        switch (activeTab) {
            case 1:
                return Yup.object().shape({
                    email: Yup.string().email().required()
                });
                
            case 2:
                return Yup.object().shape({
                    verificationCode: Yup.number().typeError(t('The Value Must be a Number')).required().integer()
                });
                
            case 3: 
                return Yup.object({
                    password: Yup.string().required().matches(
                        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z_.$&#+\-!@%\^*\(\)_=,\.\?\\\/])[0-9a-zA-Z_.$&#+\-!@%\^*\(\)_=,\.\?\\\/]{6,20}$/,
                        // /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\.$&#+\-]*)[A-Za-z\d\.$&#+\-]{10,15}$/,
                         t('Invalid Password')
                       ),
                    passwordConfirmation: Yup.string()
                       .oneOf([Yup.ref('password'), null], t('Passwords Must Match'))
                });
            default:
                break;
        }
    }

    const handleOnChange = event => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }

    const requestVerificationCode = () => {
        const request = {
            email: email
        }
        dispatch(changePasswordRequestCode(request));
    }

    const validateVerificationCode = () => {
        const request = {
            email: email,
            code: verificationCode
        }
        dispatch(changePasswordValidateCode(request));
    }

    const changePassword = () => {
        const request = {
            email: email,
            token: token.token,
            password: password,
            origin: {
                platform: 'app',
                model: 'iphoneXr',
                os: 'chrome'
            }
        }
        dispatch(changePasswordUpdate(request));
    }

    return ( 
        <React.Fragment>
        
            <div className="account-pages my-3 pt-sm-5">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={12} lg={10} xl={7}>
                            <Card className="overflow-hidden">
                                <div className="bg-primary bg-soft">
                                    <Row>
                                    <Col className="col-12">
                                        <div>
                                            <img
                                                src={passwordImg}
                                                className="img-fluid"
                                            />
                                        </div>                                        
                                    </Col>
                                    </Row>
                                </div>
                                <CardBody className="pt-0">
                                    <div>
                                       
                                    </div>
                                    <div className="p-2">
                                        <Formik
                                            enableReinitialize={true}
                                            initialValues={formData}
                                            validationSchema={
                                                getValidator()
                                            }

                                            onSubmit={(values, actions) => {
                                                actions.setSubmitting(false);
                                                if(action == 'next') {
                                                    if(activeTab == 1) {
                                                        //validar email
                                                        requestVerificationCode();
                                                    } else if(activeTab == 2) {
                                                        //validar codigo
                                                        validateVerificationCode();
                                                    } else if(activeTab == 3) {
                                                        //guardar y presentar mensaje de cambio y volver al perfil
                                                        changePassword();
                                                    } 
                                                    
                                                } else if(action == 'prev') {
                                                    toggleTab(activeTab - 1);
                                                }
                                                //onSubmit(formData);
                                            }}
                                        >
                                            {({ errors, status, touched }) => (
                                                <Form className='needs-validation' >
                                                    <div className="wizard clearfix">
                                                        <div className="steps clearfix">
                                                            <ul>
                                                                <NavItem
                                                                    className={classnames({
                                                                        current: activeTab === 1
                                                                    })}
                                                                >
                                                                    <NavLink
                                                                        className={classnames({
                                                                            active: activeTab === 1
                                                                        })}
                                                                        
                                                                    >
                                                                        <span className="number">1.</span>{" "}
                                                                        {t('Account')}
                                                                    </NavLink>
                                                                </NavItem>

                                                                <NavItem
                                                                    className={classnames({
                                                                        current: activeTab === 2
                                                                    })}
                                                                >
                                                                    <NavLink
                                                                        disabled={!(passedSteps || []).includes(2)}
                                                                        className={classnames({
                                                                            active: activeTab === 2
                                                                        })}
                                                                        
                                                                    >
                                                                        <span className="number">2.</span>{" "}
                                                                        {t('Verification')}
                                                                    </NavLink>
                                                                </NavItem>

                                                                <NavItem
                                                                    className={classnames({
                                                                        current: activeTab === 3
                                                                    })}
                                                                >
                                                                    <NavLink
                                                                        disabled={!(passedSteps || []).includes(3)}
                                                                        className={classnames({
                                                                            active: activeTab === 3
                                                                        })}
                                                                        
                                                                    >
                                                                        <span className="number">3.</span>{" "}
                                                                        {t('Password')}
                                                                    </NavLink>
                                                                </NavItem>
                                                            </ul>
                                                        </div>
                                                        <div className="content clearfix">
                                                        
                                                                <TabContent
                                                                    activeTab={activeTab}
                                                                    className="body"
                                                                >
                                                                    <TabPane tabId={1}>
                                                                        <h5 style={{marginBottom: 30}}>{t('Email Message')}</h5>
                                                                        <Row>
                                                                            <Col md={{ offset: 3, size: 6 }}>
                                                                                
                                                                                <FormField
                                                                                    key='email'
                                                                                    type='text'
                                                                                    maxLength={100}
                                                                                    fieldName='email'
                                                                                    fieldValue={email}
                                                                                    label={t('Email')}
                                                                                    errors={errors}
                                                                                    touched={touched}
                                                                                    handleOnChange={handleOnChange}
                                                                                />
                                                                                
                                                                            </Col>
                                                                            
                                                                        </Row>
                                                                    </TabPane>
                                                                    <TabPane tabId={2}>
                                                                        <h5 style={{marginBottom: 30}}>{t('Verification Code Message')}{email != '' && email.indexOf('@') != -1 ? (`${email.charAt(0)}****${email.substring(email.indexOf('@'))}`) : ''}{t('Verification Code Message2')}</h5>
                                                                        <Row>
                                                                            <Col md={{ offset: 3, size: 6 }}>
                                                                                
                                                                                <FormField
                                                                                    key='verificationCode'
                                                                                    type='text'
                                                                                    maxLength={8}
                                                                                    fieldName='verificationCode'
                                                                                    fieldValue={verificationCode}
                                                                                    label={t('Verification Code')}
                                                                                    errors={errors}
                                                                                    touched={touched}
                                                                                    handleOnChange={handleOnChange}
                                                                                />
                                                                                
                                                                            </Col>
                                                                        </Row>
                                                                    </TabPane>
                                                                    <TabPane tabId={3}>
                                                                        <h5 style={{marginBottom: 30}}>{t('Change Password Message')}</h5>
                                                                        <Row>
                                                                            <Col md={{ offset: 3, size: 6 }}>
                                                                                
                                                                                <FormField
                                                                                    key='password'
                                                                                    type='password'
                                                                                    maxLength={15}
                                                                                    fieldName='password'
                                                                                    fieldValue={password}
                                                                                    label={t('Password')}
                                                                                    errors={errors}
                                                                                    touched={touched}
                                                                                    handleOnChange={handleOnChange}
                                                                                />
                                                                                
                                                                            </Col>
                                                                        </Row>
                                                                        <Row>
                                                                            <Col md={{ offset: 3, size: 6 }}>
                                                                                
                                                                                <FormField
                                                                                    key='passwordConfirmation'
                                                                                    type='password'
                                                                                    maxLength={15}
                                                                                    fieldName='passwordConfirmation'
                                                                                    fieldValue={passwordConfirmation}
                                                                                    label={t('Confirm Password')}
                                                                                    errors={errors}
                                                                                    touched={touched}
                                                                                    handleOnChange={handleOnChange}
                                                                                />
                                                                                
                                                                            </Col>
                                                                        </Row>
                                                                        <p>{t('Password Policy')}</p>
                                                                        <ul>
                                                                            <li>{t('Password Policy1')}</li>
                                                                            <li>{t('Password Policy2')}</li>
                                                                            <li>{t('Password Policy3')}</li>
                                                                            <li>{t('Password Policy4')}</li>
                                                                        </ul>
                                                                    </TabPane>
                                                                </TabContent>
                                                            
                                                            
                                                        </div>
                                                        <div className="actions clearfix">
                                                            <ul>
                                                                <li
                                                                    className={
                                                                    activeTab === 1
                                                                        ? "previous disabled"
                                                                        : "previous"
                                                                    }
                                                                >
                                                                    <Button
                                                                        disabled={activeTab === 1}
                                                                        color='primary'
                                                                        name='action'
                                                                        type='submit'
                                                                        value='prev'
                                                                        onClick={() => {
                                                                            setAction('prev');
                                                                            //toggleTab(activeTab - 1);
                                                                        }}
                                                                    >
                                                                        {t('Previous')}
                                                                    </Button>
                                                                </li>
                                                                <li
                                                                    className={
                                                                    activeTab === 4
                                                                        ? "next disabled"
                                                                        : "next"
                                                                    }
                                                                >
                                                                    <Button
                                                                        disabled={activeTab === 4}
                                                                        color='primary'
                                                                        name='action'
                                                                        type='submit'
                                                                        value='next'
                                                                        onClick={() => {
                                                                            setAction('next');
                                                                            //toggleTab(activeTab + 1);
                                                                        }}
                                                                    >
                                                                        {activeTab == 3 ? t('Confirm') : t('Next')}
                                                                    </Button>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </Form>
                                            
                                            )}
                                        </Formik>
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-5 text-center">
                                <p>
                                    {t('Return to')} {" "}
                                    <Link to="login" className="fw-medium text-primary">
                                    {t('Login Page')}
                                    </Link>{" "}
                                </p>
                                <p>
                                © {new Date().getFullYear()} Vitae Services, Inc.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment> 
    );
}
 
export default RecoveryPassword;