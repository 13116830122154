import React from "react";
import { Button, Container } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PageTitle from "components/Common/PageLayout/page-title";
import PageHeader from "components/Common/PageLayout/page-header";
import LaboratoryPrescriptionHistoricalFilter from "./laboratory-prescription-historical-filter";
import LaboratoryPrescriptionHistoricalResult from "./laboratory-prescription-historical-result";

const LaboratoryPrescriptionHistoricalPage = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <React.Fragment>
      <div className="page-content">
        <PageTitle />
        <Container fluid>
          {/* Render Breadcrumb */}
          <PageHeader title="Orders Historical">
            <Button
              color="light"
              className="button"
              onClick={() => {
                history.goBack();//push("/medical-prescription-search");
              }}
            >
              <i className="mdi mdi-arrow-left me-1"></i>
              {t("Back")}
            </Button>
          </PageHeader>
          <LaboratoryPrescriptionHistoricalFilter />
          <LaboratoryPrescriptionHistoricalResult />
        </Container>
      </div>
    </React.Fragment>
  );
};

export default LaboratoryPrescriptionHistoricalPage;
