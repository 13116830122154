import { ALERT_MESSAGE } from "constants/constants"
import { 
  httpDeleteAccount,
  httpGetAccountDetail, 
  httpGetAccounts, 
  httpPutAccount,
  httpGetLaboratoriesSync
} from "helpers/Api/api-helper"
import { call, put, takeEvery } from "redux-saga/effects"
import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions"
import {
  getAccountDetailSuccess,
  getAccountDetailFail,
  updateAccountSuccess,
  updateAccountFail,
  getAccountsSuccess,
  getAccountsFail,
  getAccountDetailLoginSuccess,
  getAccountDetailLoginFail,
  laboratoriesSyncSuccess,
  laboratoriesSyncFail
} from "./actions"
import { 
  DELETE_ACCOUNT,
  GET_ACCOUNT_DETAIL, 
  GET_ACCOUNT_DETAIL_LOGIN, 
  GET_ACCOUNT_LIST, 
  UPDATE_ACCOUNT, 
  LABORATORIES_SYNC
 } from "./actionTypes"

 function* getAccounts(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetAccounts, request.payload)
    yield put(hideLoadingAlert())
    yield put(getAccountsSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getAccountsFail(error))
  }
}

function* getAccountDetail(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetAccountDetail, request.payload)
    yield put(hideLoadingAlert())
    yield put(getAccountDetailSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getAccountDetailFail(error))
  }
}

function* getAccountDetailLogin(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetAccountDetail, request.payload)
    yield put(hideLoadingAlert())
    yield put(getAccountDetailLoginSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_DETAIL_ERROR))
    yield put(getAccountDetailLoginFail(error))
  }
}

function* updateAccount(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpPutAccount, request.payload)
    yield put(hideLoadingAlert())
    yield put(updateAccountSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(updateAccountFail(error))
  }
}

function* laboratoriesSync(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(httpGetLaboratoriesSync, request.payload);
    yield put(hideLoadingAlert());
    yield put(laboratoriesSyncSuccess(true));
  } catch (error) {
    yield put(hideLoadingAlert());
    yield put(showWarningAlert(ALERT_MESSAGE.UPDATE_ERROR));
    yield put(laboratoriesSyncFail(false));
  }
}


function* deleteAccount(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpDeleteAccount, request.payload)
    yield put(hideLoadingAlert())
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.DELETE_ERROR))
    yield put(updateAccountFail(error))
  }
}


function* accountSaga() {
  yield takeEvery(GET_ACCOUNT_LIST, getAccounts)  
  yield takeEvery(GET_ACCOUNT_DETAIL, getAccountDetail)
  yield takeEvery(GET_ACCOUNT_DETAIL_LOGIN, getAccountDetailLogin)
  yield takeEvery(UPDATE_ACCOUNT, updateAccount)
  yield takeEvery(DELETE_ACCOUNT, deleteAccount)
  yield takeEvery(LABORATORIES_SYNC, laboratoriesSync)

}
  
export default accountSaga