import { ALERT_MESSAGE } from 'constants/constants';
import { businessUnitTypes } from 'constants/list-types';
import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { useTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { clearMembership, deleteBenefit, getBenefits, showSuccessAlert } from 'store/actions';

const BenefitList = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { id } = useParams();
    const history = useHistory();
    const node = React.createRef();
    const [showDelete, setShowDelete] = useState(false);
    const [benefit, setBenefit] = useState(null);
    const { SearchBar } = Search;

    const benefits = useSelector(state => state.memberships.benefits);
    const benefitDeleted = useSelector(state => state.memberships.benefitDeleted);


    const [pageOptions, setPageOptions] = useState({
        sizePerPage: 10,
        totalSize: 0,
        custom: true
    });

    useEffect(() => {
        dispatch(getBenefits({membershipId: id}));
    }, []);

    useEffect(() => {
        if(benefitDeleted) {
            dispatch(showSuccessAlert(ALERT_MESSAGE.DELETED, () => {
                dispatch(clearMembership());
                dispatch(getBenefits({membershipId: id}));
            }));
        }
        
    }, [benefitDeleted]);

    useEffect(() => {
        if(benefits) {
            setPageOptions({
                ...pageOptions,
                totalSize: benefits.length
            });
        }
    }, [benefits]);

    const columns = [
        {
            text: t('#'),
            dataField: 'id',
            sort: true,
            formatter: (cellContent, row) => <React.Fragment>{row.id}</React.Fragment>
        },
        {
            text: t('header.description'),
            dataField: 'description',
            sort: true,
            formatter: (cellContent, row) => <React.Fragment>{row.description}</React.Fragment>
        },
        {
            text: t('header.business.unit'),
            dataField: 'businessUnitType',
            sort: true,
            formatter: (cellContent, row) => {
                const unitType = businessUnitTypes.find(current => current.code == row.businessUnitType);
                return (<React.Fragment>{t(unitType ? unitType.value : row.businessUnitType)}</React.Fragment>);
            }
        },
        {
          dataField: 'menu',
          isDummyField: true,
          editable: false,
          text: '',
          formatter: (cellContent, row) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => editRow(row)}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => deleteRow(row)}
                ></i>
              </Link>
            </div>
          ),
        }
    ];
    
    const onPaginationPageChange = page => {
        if (
          node &&
          node.current &&
          node.current.props &&
          node.current.props.pagination &&
          node.current.props.pagination.options
        ) {
          node.current.props.pagination.options.onPageChange(page);
        }
    };

    

    const defaultSorted = [
    {
        dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
    },
    ];

    const editRow = row => {
        history.push(`/membership-benefit-edit/${id}/${row.id}`);
    };

    const deleteRow = row => {
        setBenefit(row);
        setShowDelete(true);
    };
 
    const onDelete = () => {
        setShowDelete(false);
        dispatch(deleteBenefit(benefit));
    }

    return ( 
        <React.Fragment>

            {showDelete?
                
                <SweetAlert
                    title={t('confirm.delete')}
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText={t('Yes')}
                    cancelBtnText={t('No')}
                    onConfirm={() => {
                        onDelete();
                    }}
                    onCancel={() => {
                        setShowDelete(false);
                    }}
                >

                </SweetAlert>
            : null}

            <div className="page-content">
                <MetaTags>
                    <title>{t('Title App')}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h2 className="mb-2 font-size-18">{t('Benefits')}</h2>
                        <div className="button-items">
                            <Button color="primary" className="button" onClick={() => { history.push(`/membership-benefit-new/${id}`) }}><i className="mdi mdi-plus-circle-outline me-1"></i>{t('Create')}</Button>
                            <Button color="light" className="button mr-1" onClick={() => { history.push(`/membership-package-detail/${id}`) }}><i className="mdi mdi-close-thick me-1"></i>{t('Back')}</Button>
                        </div>
                    </div>
                    
                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField="id"
                                        columns={columns}
                                        data={benefits}
                                    >
                                    {({ paginationProps, paginationTableProps }) => (
                                        <ToolkitProvider
                                            keyField="id"
                                            columns={columns}
                                            data={benefits}
                                            search
                                        >
                                            {toolkitprops => (
                                                <React.Fragment>
                                                    <Row className="mb-2">
                                                        <Col sm={4}>
                                                            <div className="search-box ms-2 mb-2 d-inline-block">
                                                                <div className="position-relative">
                                                                <SearchBar
                                                                    {...toolkitprops.searchProps}
                                                                    placeholder={t('Search')}
                                                                />
                                                                <i className="bx bx-search-alt search-icon" />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col sm="8">
                                                            
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col xl={12}>
                                                            <div className="table-responsive">
                                                                <BootstrapTable
                                                                    {...toolkitprops.baseProps}
                                                                    {...paginationTableProps}
                                                                    defaultSorted={defaultSorted}
                                                                    classes={
                                                                        "table align-middle table-nowrap table-hover"
                                                                    }
                                                                    bordered={false}
                                                                    striped={false}
                                                                    responsive
                                                                    ref={node}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row className="align-items-md-center mt-30">
                                                        <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                        <PaginationListStandalone
                                                            {...paginationProps}
                                                        />
                                                        </Col>
                                                    </Row>
                                                </React.Fragment>
                                            )}
                                        </ToolkitProvider>
                                    )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                        

                    </Row>
                </Container>
            </div>
        </React.Fragment> 
    );
}
 
export default BenefitList;