import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom'
import { clearQuoterMembership, getQuoterMemberships, showSuccessAlert } from 'store/actions';
import DataTable from 'components/Common/DataTable/datatable';
import { ALERT_MESSAGE } from 'constants/constants';

const QuestionMembershipList = ({quoterMemberships, setShowDelete, setQuoterMembership}) => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();

    const deleted = useSelector(state => state.quoterMemberships.deleted);


    const [pageOptions, setPageOptions] = useState({
        sizePerPage: 10,
        totalSize: 0,
        custom: true
    });

    useEffect(() => {
        dispatch(getQuoterMemberships());
    }, []);

    useEffect(() => {
        if(deleted) {
            dispatch(showSuccessAlert(ALERT_MESSAGE.DELETED, () => {
                dispatch(clearQuoterMembership());
                dispatch(getQuoterMemberships());
            }));
        }
        
    }, [deleted]);

    useEffect(() => {
        if(quoterMemberships) {
            setPageOptions({
                ...pageOptions,
                totalSize: quoterMemberships.length
            });
        }
    }, [quoterMemberships]);

    const columns = [
        {
            text: t('Name'),
            dataField: 'name',
            sort: true,
            formatter: (cellContent, row) => <React.Fragment>{row.name}</React.Fragment>
        },
        {
            text: t('Membership'),
            dataField: 'membershipName',
            sort: true,
            formatter: (cellContent, row) => {
                return (<React.Fragment>{row.membershipName}</React.Fragment>);
            }
        },
        {
          dataField: 'menu',
          isDummyField: true,
          editable: false,
          text: '',
          formatter: (cellContent, row) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => editRow(row)}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => deleteRow(row)}
                ></i>
              </Link>
            </div>
          ),
        }
    ];
    
    const onPaginationPageChange = page => {
        if (
          node &&
          node.current &&
          node.current.props &&
          node.current.props.pagination &&
          node.current.props.pagination.options
        ) {
          node.current.props.pagination.options.onPageChange(page);
        }
    };

    

    const defaultSorted = [
    {
        dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
        order: "desc", // desc or asc
    },
    ];

    const editRow = row => {
        history.push(`/question-membership-edit/${row.id}`);
    };

    const deleteRow = row => {
        setQuoterMembership(row);
        setShowDelete(true);
    };

    return ( 
        <React.Fragment>
            <DataTable
                data={quoterMemberships}
                columns={columns}
                keyField='id'
                sort={defaultSorted}
            />
        </React.Fragment> 
    );
}

QuestionMembershipList.propTypes = {
    quoterMemberships: PropTypes.array,
    setShowDelete: PropTypes.func.isRequired, 
    setQuoterMembership: PropTypes.func.isRequired
}

export default QuestionMembershipList;