import PageBody from 'components/Common/PageLayout/page-body';
import { ALERT_MESSAGE } from 'constants/constants';
import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row } from 'reactstrap';
import { clearQuoterMembership, deleteQuoterMembership, getQuoterMemberships, showSuccessAlert } from 'store/actions';
import QuestionMembershipList from './question-membership-list';

const QuestionMembershipPage = () => {

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const [showDelete, setShowDelete] = useState(false);
    const [quoterMembership, setQuoterMembership] = useState(null);

    const quoterMemberships = useSelector(state => state.quoterMemberships.quoterMemberships);
    const deleted = useSelector(state => state.quoterMemberships.deleted);

    const data = quoterMemberships.map(current => ({...current, membershipName: current.membership.name}));
    useEffect(() => {
        dispatch(getQuoterMemberships());
    }, []);

    useEffect(() => {
        if (deleted) {
            dispatch(showSuccessAlert(ALERT_MESSAGE.DELETED, () => {
                dispatch(clearQuoterMembership());
                dispatch(getQuoterMemberships());
            }));
        }

    }, [deleted]);


    const onDelete = () => {
        setShowDelete(false);
        dispatch(deleteQuoterMembership(quoterMembership));
    }

    return (
        <React.Fragment>

            {showDelete ?

                <SweetAlert
                    title={t('confirm.delete')}
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText={t('Yes')}
                    cancelBtnText={t('No')}
                    onConfirm={() => {
                        onDelete();
                    }}
                    onCancel={() => {
                        setShowDelete(false);
                    }}
                >

                </SweetAlert>
                : null}

            <PageBody
                title='Question Groups'
                actions={(
                    <React.Fragment>
                        <Button 
                            color="primary" 
                            className="button" 
                            onClick={() => { history.push(`/question-membership-new`) }}>
                            <i className="mdi mdi-plus-circle-outline me-1"></i>{t('Create')}
                        </Button>
                        <Button
                            color="light"
                            className="button"
                            onClick={() => { history.push('/dashboard') }}>
                            <i className="mdi mdi-arrow-left me-1"></i>{t('Back')}
                        </Button>
                    </React.Fragment>
                )}
            >
                <QuestionMembershipList 
                    quoterMemberships={data} 
                    setQuoterMembership={setQuoterMembership}
                    setShowDelete={setShowDelete}
                />
            </PageBody>
        
        </React.Fragment>
    );
}

export default QuestionMembershipPage;