import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import ProductSearchCategoryCard from "./product-search-category-card";
import { Col } from "reactstrap";

const ProductSearchCategories = ({ onSelect, categorySelectedId }) => {
  const productCategoryList = useSelector(
    state => state.productCategories.productCategoryList
  );
  return (
    <React.Fragment>
      {productCategoryList.map(category => (
        <Col xs={12} sm={6} md={4} lg={3} key={category.id} className="mb-2">
          <ProductSearchCategoryCard
            category={category}
            onSelect={onSelect}
            selected={category.id === categorySelectedId}
          />
        </Col>
      ))}
    </React.Fragment>
  );
};

ProductSearchCategories.propTypes = {
  onSelect: PropTypes.func,
  categorySelectedId: PropTypes.number,
};

export default ProductSearchCategories;

