import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Badge, Button } from 'reactstrap';
import DataTable from 'components/Common/DataTable/datatable';

const AffiliatedMembershipList = ({ membershipList }) => {

    const { id } = useParams();
    const { t } = useTranslation();
    const history = useHistory();
    const currencyCodeList = useSelector(state => state.catalogs.currencyCodeList);

    const [pageOptions, setPageOptions] = useState({
        sizePerPage: 5,
        totalSize: 0,
        custom: true
    });

    const getCurrencySymbol = (currencyCode) => {
        const found = currencyCodeList.find(current => current.code == currencyCode);
        return found ? found.currency : currencyCode;
    }

    useEffect(() => {
        if (membershipList) {
            setPageOptions({
                ...pageOptions,
                totalSize: membershipList.length
            });
        }
    }, [membershipList]);

    const columns = [
        {
            text: t('#'),
            dataField: 'id',
            hidden: true,
            sort: true,
            formatter: (cellContent, row) => <React.Fragment>{row.id}</React.Fragment>
        },
        {
            text: t('Name'),
            dataField: 'membershipName',
            sort: true,
            formatter: (cellContent, row) => <React.Fragment>{row.membershipName}</React.Fragment>
        },
        {
            text: t('Status'),
            dataField: 'membershipStatus',
            sort: true,
            formatter: (cellContent, row) => (
                <>
                    {row.membershipStatus === 'ACT' ? (
                        <Badge
                            className={"font-size-12 badge-soft-success"}
                            color={"success"}
                            pill
                        >
                            {t('Membership Activated')}
                        </Badge>
                    ) : row.membershipStatus === 'CAN' ? (
                        <Badge
                            className={"font-size-12 badge-soft-danger"}
                            color={"danger"}
                            pill
                        >
                            {t('Membership Canceled')}

                        </Badge>

                    ) : (
                        <Badge
                            className={"font-size-12 badge-soft-danger"}
                            color={"danger"}
                            pill
                        >
                            {t('Membership Inactivated')}
                        </Badge>

                    )}
                </>
            )
        },
        {
            text: t('Price'),
            dataField: 'membershipPrice',
            sort: true,
            align: 'right',
            formatter: (cellContent, row) => {

                return (<React.Fragment>{getCurrencySymbol(row.currencyCode) + ' ' + row.membershipPrice}</React.Fragment>);
            }
        },
        {
            text: t('Beneficiaries'),
            dataField: 'maxBeneficiaryQuantity',
            headerStyle: (colum, colIndex) => {
                return { width: '120px', textAlign: 'left' };
            },
            align: 'right',
            sort: true,
            formatter: (cellContent, row) => {

                return (<React.Fragment>{row.maxBeneficiaryQuantity}</React.Fragment>);
            }
        },
        {
            text: t('Minimum Validity Period'),
            dataField: 'validityMonths',
            headerStyle: (colum, colIndex) => {
                return { width: '140px', textAlign: 'left' };
            },
            sort: true,
            formatter: (cellContent, row) => {

                return (<React.Fragment>{row.validityMonths + ' ' + t('months')}</React.Fragment>);
            }
        },
        {
            dataField: 'menu',
            isDummyField: true,
            editable: false,
            text: '',
            formatter: (cellContent, row) => (
                <div className="d-flex gap-3">
                    <Button
                        type="button"
                        color="success"
                        className="btn-md"
                        onClick={() => viewDetail(row)}
                    >
                        <i className="mdi mdi-arrow-right me-1"></i>{t('View Detail')}
                    </Button>
                </div>
            ),
        }
    ];

    const viewDetail = row => {
        history.push(`/membership-customer-detail/${id}/${row.id}`);
    };

    return (
        <React.Fragment>
            <DataTable
                columns={columns}
                data={membershipList}
                keyField='id'
            />
        </React.Fragment>
    );
}

AffiliatedMembershipList.propTypes = {
    membershipList: PropTypes.array.isRequired
}

export default AffiliatedMembershipList;