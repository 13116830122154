import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import FormField from "pages/Utility/form-field";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  getBusinessUnits,
  getCatalogs,
  getIdentificationTypes,
  getSubsidiaries,
  verifyUser,
  verifyEmail
} from "store/actions";
import InputMask from "react-input-mask";
import { DEFAULT_COMPANY } from "constants/list-types";

const RegisterForm = ({ onSubmit }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const identificationTypeList = useSelector(
    state => state.identificationTypes.identificationTypeList
  );
  const businessUnitList = useSelector(
    state => state.businessUnits.businessUnits
  );
  const subsidiaryList = useSelector(state => state.subsidiaries.subsidiaries);

  const userInformation = useSelector(state => state.principals.userInformation);
  const emailVerification = useSelector(state => state.principals.emailVerification);

  const [requiresInformation, setRequiresInformation] = useState(false);
  const [verified, setVerified] = useState(false);

  useEffect(() => {
    setRequiresInformation(false);
    setVerified(false);
    dispatch(getCatalogs());
    dispatch(getIdentificationTypes());
    dispatch(getBusinessUnits({ companyId: DEFAULT_COMPANY }));
    dispatch(getSubsidiaries({ companyId: DEFAULT_COMPANY }));
  }, []);

  useEffect(() => {
    if (userInformation) {
      if (!userInformation.requiresInformation) {
        setVerified(true);
        onSubmit({
          ...formData,
          firstName: userInformation.firstName,
          secondName: userInformation.secondName,
          firstSurname: userInformation.firstSurname,
          secondSurname: userInformation.secondSurname,
          birthDate: userInformation.birthDate,
          gender: userInformation.gender
        });
      } else {
        setVerified(true);
        setRequiresInformation(true);
      }

    }
  }, [userInformation]);

  useEffect(() => {
    if (emailVerification && emailVerification.existsIndicator && emailVerification.existsIndicator === "N") {
      verifyUserInformation();
    }
  }, [emailVerification]);

  const [identificationTypeSelected, setIdentificationTypeSelected] =
    useState(null);

  const [formData, setFormData] = useState({
    email: "",
    identificationTypeId: "",
    identification: "",
    mobilePhone: "",
    subsidiaryId: "",
    businessUnitId: "",
    firstName: '',
    secondName: '',
    firstSurname: '',
    secondSurname: '',
    birthDate: new Date().toISOString().slice(0, 10),
    gender:'',
    professionLicenseCode: ''
  });

  const {
    email,
    identificationTypeId,
    identification,
    mobilePhone,
    subsidiaryId,
    businessUnitId,
    firstName,
    secondName,
    firstSurname,
    secondSurname,
    professionLicenseCode
  } = formData;

  const handleOnChange = event => {
    const { name, value } = event.target;
    if(name == 'identification') {
      // setRequiresInformation(false);
      setVerified(false);
    }

    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleOnChangeIdentificationType = event => {
    
    
    setIdentificationTypeSelected(
      identificationTypeList.find(current => current.id == event.target.value)
    );
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const prepareFormat = format => {
    return format.replace(/#/gi, "9").replace(/@/gi, "\*");
  };

  const verifyEmailInformation = () => {
    dispatch(verifyEmail({ email: email, identificationType: identificationTypeId, identification: identification, realm: 'PORTAL_REALM' }));    
  }

  const verifyUserInformation = () => {
    if(identificationTypeSelected.code == 'CNA') {
      if(verified) {
        onSubmit(formData);
      } else {
        dispatch(verifyUser({ identificationType: identificationTypeId, identification: identification }));
      }
      
    } else {
      setVerified(true);
      setRequiresInformation(true);
      onSubmit(formData);
    }
    
  }

  useEffect(() => {
    if(identificationTypeSelected) {
      if(identificationTypeSelected.code != 'CNA') {
        setRequiresInformation(true);
        setVerified(true);  
      } else {
        setRequiresInformation(false);
        setVerified(false);
      }  
    } else {
      setVerified(false);
      setRequiresInformation(false);
    }
  }, [identificationTypeSelected]);

  return (
    <React.Fragment>
      <Row>
        <Col xl={{ size: 10, offset: 1 }}>
          <Card>
            <CardBody>
              {/* <CardTitle className="h5">Floating labels</CardTitle> */}
              {/* <p className="card-title-desc">Create beautifully simple form labels that float over your input fields.</p> */}

              <Formik
                enableReinitialize={true}
                initialValues={formData}
                validationSchema={Yup.object().shape({
                  identificationTypeId: Yup.string().required(),
                  identification: Yup.string().required(),
                  email: Yup.string().required().email(),
                  mobilePhone: Yup.string().required(),
                  subsidiaryId: Yup.string(),
                  businessUnitId: Yup.string(),
                  firstName: requiresInformation ? Yup.string().required() : Yup.string(),
                  secondName: Yup.string(),
                  firstSurname: requiresInformation ? Yup.string().required() : Yup.string(),
                  secondSurname: Yup.string(),
                  professionLicenseCode: Yup.string()
                })}
                onSubmit={(values, actions) => {
                  actions.setSubmitting(false);                  
                  verifyEmailInformation();
                }}
              >
                {({ errors, status, touched }) => (
                  <Form className="needs-validation">
                    <Row>
                      <Col md={6}>
                        <FormField
                          key="identificationTypeId"
                          type="select"
                          fieldName="identificationTypeId"
                          fieldValue={identificationTypeId}
                          label={t("Identification Type")}
                          errors={errors}
                          touched={touched}
                          handleOnChange={handleOnChangeIdentificationType}
                        >
                          <option key="identificationTypeNotSelected" value="">
                            {t("Not Selected")}
                          </option>
                          {identificationTypeList.map(current => {
                            return (
                              <option key={current.id} value={current.id}>
                                {t(current.name)}
                              </option>
                            );
                          })}
                        </FormField>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Identification")}
                          </Label>
                          <InputMask
                            type="text"
                            mask={
                              identificationTypeSelected
                                ? prepareFormat(
                                  identificationTypeSelected.format
                                )
                                : ""
                            }
                            className={
                              "form-control input-mask" +
                              (errors.identification && touched.identification
                                ? " is-invalid"
                                : "")
                            }
                            name="identification"
                            value={identification}
                            onChange={handleOnChange}
                          />
                          <ErrorMessage
                            name="identification"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormField
                          key="email"
                          type="text"
                          maxLength={100}
                          fieldName="email"
                          fieldValue={email}
                          label={t("Email")}
                          errors={errors}
                          touched={touched}
                          handleOnChange={handleOnChange}
                        />
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">{t("Phone")}</Label>
                          <InputMask
                            type="text"
                            mask={"(999)99999999"}
                            className={
                              "form-control input-mask" +
                              (errors.mobilePhone && touched.mobilePhone
                                ? " is-invalid"
                                : "")
                            }
                            name="mobilePhone"
                            value={mobilePhone}
                            onChange={handleOnChange}
                          />
                          <ErrorMessage
                            name="mobilePhone"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </Col>
                    </Row>

                    {/*<Row>
                      <Col md={6}>
                        <FormField
                          key="subsidiaryId"
                          type="select"
                          fieldName="subsidiaryId"
                          fieldValue={subsidiaryId}
                          label={t("Subsidiary")}
                          errors={errors}
                          touched={touched}
                          handleOnChange={handleOnChange}
                        >
                          <option key="subsidiaryNotSelected" value="">
                            {t("Not Selected")}
                          </option>
                          {subsidiaryList.map(current => {
                            return (
                              <option key={current.id} value={current.id}>
                                {t(current.name)}
                              </option>
                            );
                          })}
                        </FormField>
                      </Col>
                      <Col md={6}>
                        <FormField
                          key="businessUnitId"
                          type="select"
                          fieldName="businessUnitId"
                          fieldValue={businessUnitId}
                          label={t("Business Unit")}
                          errors={errors}
                          touched={touched}
                          handleOnChange={handleOnChange}
                        >
                          <option key="businessUnitNotSelected" value="">
                            {t("Not Selected")}
                          </option>
                          {businessUnitList.map(current => {
                            return (
                              <option key={current.id} value={current.id}>
                                {t(current.name)}
                              </option>
                            );
                          })}
                        </FormField>
                      </Col>
                        </Row>*/}

                    <Row>
                      <Col md={6}>
                        <FormField
                          key="professionLicenseCode"
                          type="text"
                          maxLength={255}
                          fieldName="professionLicenseCode"
                          fieldValue={professionLicenseCode}
                          label={t("Doctor code")}
                          errors={errors}
                          touched={touched}
                          handleOnChange={handleOnChange}
                        />
                      </Col>
                    </Row>

                    <Row hidden={!requiresInformation}>
                      <Col md={12}>
                        <h3>{t('Additional Information')}</h3>
                        <hr />
                      </Col>
                    </Row>

                    <Row hidden={!requiresInformation}>
                      <Col md={6}>
                        <FormField
                          key="firstName"
                          type="text"
                          maxLength={50}
                          fieldName="firstName"
                          fieldValue={firstName}
                          label={t("First Name")}
                          errors={errors}
                          touched={touched}
                          handleOnChange={handleOnChange}
                        />
                      </Col>

                      <Col md={6}>
                        <FormField
                          key="secondName"
                          type="text"
                          maxLength={50}
                          fieldName="secondName"
                          fieldValue={secondName}
                          label={t("Second Name")}
                          errors={errors}
                          touched={touched}
                          handleOnChange={handleOnChange}
                        />
                      </Col>
                    </Row>

                    <Row hidden={!requiresInformation}>
                      <Col md={6}>
                        <FormField
                          key="firstSurname"
                          type="text"
                          maxLength={50}
                          fieldName="firstSurname"
                          fieldValue={firstSurname}
                          label={t("First Surname")}
                          errors={errors}
                          touched={touched}
                          handleOnChange={handleOnChange}
                        />
                      </Col>

                      <Col md={6}>
                        <FormField
                          key="secondSurname"
                          type="text"
                          maxLength={50}
                          fieldName="secondSurname"
                          fieldValue={secondSurname}
                          label={t("Second Surname")}
                          errors={errors}
                          touched={touched}
                          handleOnChange={handleOnChange}
                        />
                      </Col>
                    </Row>

                    <div className="button-items">
                      <Button type="submit" color="primary" className="button">
                        <i className="mdi mdi-content-save me-1"></i>
                        {t("Save")}
                      </Button>
                      <Button
                        color="light"
                        className="button mr-1"
                        onClick={() => {
                          history.goBack();
                        }}
                      >
                        <i className="mdi mdi-arrow-left me-1"></i>
                        {t("Back")}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

RegisterForm.propTypes = {
  onSubmit: PropTypes.func,
};

export default RegisterForm;

