import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';

const DataTable = ({ title, data, columns, keyField, sort, selectRow, pageSize = 10 }) => {

    const { t } = useTranslation();
    const node = React.createRef();
    const { SearchBar } = Search;

    const [pageOptions, setPageOptions] = useState({
        sizePerPage: pageSize,
        totalSize: data.length,
        custom: true
    });


    useEffect(() => {
        if (data) {
          setPageOptions({
            ...pageOptions,
            sizePerPage: pageSize,
            totalSize: data.length,
          });
        }
      }, [data]);

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <CardTitle>
                        <h4>{title}</h4>
                    </CardTitle>
                    
                    <PaginationProvider
                        pagination={paginationFactory(pageOptions)}
                        keyField={keyField}
                        columns={columns}
                        data={data}
                    >
                        {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                                keyField={keyField}
                                columns={columns}
                                data={data}
                                search
                            >
                                {toolkitprops => (
                                    <React.Fragment>
                                        <Row className="mb-2">
                                            <Col sm={4}>
                                                <div className="search-box ms-2 mb-2 d-inline-block">
                                                    <div className="position-relative">
                                                        <SearchBar
                                                            {...toolkitprops.searchProps}
                                                            placeholder={t('Search')}
                                                        />
                                                        <i className="bx bx-search-alt search-icon" />
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col sm="8">

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xl={12}>
                                                <div className="table-responsive">
                                                    <BootstrapTable
                                                        {...toolkitprops.baseProps}
                                                        {...paginationTableProps}
                                                        defaultSorted={sort}
                                                        classes={
                                                            "table align-middle table-nowrap table-hover"
                                                        }
                                                        bordered={false}
                                                        striped={false}
                                                        selectRow={selectRow}
                                                        responsive
                                                        ref={node}
                                                    />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className="align-items-md-center mt-30">
                                            <Col className="pagination pagination-rounded justify-content-end mb-2">
                                                <PaginationListStandalone
                                                    {...paginationProps}
                                                />
                                            </Col>
                                        </Row>
                                    </React.Fragment>
                                )}
                            </ToolkitProvider>
                        )}
                    </PaginationProvider>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

DataTable.propTypes = {
    title: PropTypes.string,
    data: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    keyField: PropTypes.string.isRequired,
    sort: PropTypes.array,
    selectRow: PropTypes.object,
    pageSize: PropTypes.number
}

export default DataTable;