import PageBody from 'components/Common/PageLayout/page-body';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ErrorMessage, Form, Formik } from "formik";
import { Row, Col, Card, CardBody, Button, Label } from "reactstrap";
import FormField from "pages/Utility/form-field";
import SweetAlert from 'react-bootstrap-sweetalert';
import * as Yup from "yup";
import { modifyMedicalService } from 'store/actions';

const ServiceCatalogDetailPage = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [showConfirm, setShowConfirm] = useState(false);
    const medicalServices = useSelector(state => state.medicalServices.medicalServiceList);
    const [formData, setFormData] = useState({
        description: "",
        price: 0,
        marketPrice: 0,
        displayStatus: "ACT",
        billingReference: "",
        type: ""
      });

    const {
        description,
        price,
        marketPrice,
        displayStatus,
        billingReference,
        type,
        taxPercentage,
        crossReference,
        billingExternalCode,
        billingChannel,
        billingTaxCode,
        billingTaxRate,
        currencyCode
    } = formData;

    useEffect(() => {
        let foundService;
        if (id) {
            foundService = medicalServices.find(item => item.id === +id);
        }
        if(foundService){
            setFormData({
                ...formData,
                description: foundService.description,
                price: foundService.price,
                marketPrice: foundService.marketPrice,
                displayStatus: foundService.displayStatus,
                billingReference: foundService.billingReference,
                taxPercentage: foundService.taxPercentage,
                crossReference: foundService.crossReference,
                billingExternalCode: foundService.billingExternalCode,
                billingChannel: foundService.billingChannel,
                billingTaxCode: foundService.billingTaxCode,
                billingTaxRate: foundService.billingTaxRate,
                currencyCode: foundService.currencyCode,

                type: foundService.type
              });
        }

    }, [id]);

    const handleOnChange = event => {
        const { name, value } = event.target;
        setFormData({
          ...formData,
          [event.target.name]: event.target.value,
        });
      };


    const confirmAction = () => {
        setShowConfirm(true);
    }

    const onSend = () => {
        setShowConfirm(false);

        const payload = {
            id: +id,
            description: formData.description,
            price: formData.price,
            marketPrice: formData.marketPrice,
            displayStatus: formData.displayStatus,
            billingReference: formData.billingReference,
            taxPercentage: formData.taxPercentage,
            crossReference: formData.crossReference,
            billingExternalCode: formData.billingExternalCode,
            billingChannel: formData.billingChannel,
            billingTaxCode: formData.billingTaxCode,
            billingTaxRate: formData.billingTaxRate,
            currencyCode: formData.currencyCode            
        };

        dispatch(modifyMedicalService(payload));
        setTimeout(()=>{
            history.goBack();
        }, 1200);
    }

    return (
        <React.Fragment>
            <PageBody
                title='Service Catalog Detail'
                actions={(
                    <Button
                        color="light"
                        className="button"
                        onClick={() => {
                            history.push("/service-catalog");
                        }}
                    >
                        <i className="mdi mdi-arrow-left me-1"></i>
                        {t("Back")}
                    </Button>
                )}
            >
                
                <Row>
                    <Col xl={{ size: 10, offset: 1 }}>
                    <Card>
                        <CardBody>
                        <Formik
                            enableReinitialize={true}
                            initialValues={formData}
                            validationSchema={
                                Yup.object().shape({
                                    description: Yup.string().required(),
                                    price: Yup.number().min(0).required(),
                                    marketPrice: Yup.number().min(0).required(),
                                    displayStatus: Yup.string().required(),
                                    billingReference: Yup.string(),
                                    taxPercentage: Yup.number(),
                                    crossReference: Yup.string(),
                                    billingExternalCode: Yup.string(),
                                    billingChannel: Yup.string(),
                                    billingTaxCode: Yup.number(),
                                    billingTaxRate: Yup.number(),
                                })
                            }
                            onSubmit={(values, actions) => {
                                actions.setSubmitting(false);       
                                confirmAction();           
                            }}
                        >
                            {({ errors, status, touched }) => (
                            <Form className="needs-validation">
                                <Row>
                                <Col md={6}>
                                    <FormField
                                        key="description"
                                        type="text"
                                        maxLength={255}
                                        fieldName="description"
                                        fieldValue={description}
                                        label={t("Service Catalog Form Name")}
                                        errors={errors}
                                        touched={touched}
                                        handleOnChange={handleOnChange}
                                        />
                                </Col>
                                <Col md={6}>
                                    <FormField
                                        key='displayStatus'
                                        type='select'
                                        fieldName='displayStatus'
                                        fieldValue={displayStatus}
                                        label={t('Service Catalog Form Display')}
                                        errors={errors}
                                        touched={touched}
                                        handleOnChange={handleOnChange}
                                    >
                                        <option key="ACT" value="ACT">{t('Yes')}</option>
                                        <option key="INA" value="INA">{t('No')}</option>
                                    </FormField>
                                </Col>
                                </Row>

                                <Row>
                                <Col md={6}>
                                    <FormField
                                        key='price'
                                        type='number'
                                        maxLength={20}
                                        fieldName='price'
                                        fieldValue={price}
                                        label={t('Service Catalog Form Price')}
                                        errors={errors}
                                        touched={touched}
                                        handleOnChange={handleOnChange}
                                    />
                                </Col>

                                <Col md={6}>
                                    <FormField
                                        key='marketPrice'
                                        type='number'
                                        maxLength={20}
                                        fieldName='marketPrice'
                                        fieldValue={marketPrice}
                                        label={t('Service Catalog Form MarkerPrice')}
                                        errors={errors}
                                        touched={touched}
                                        handleOnChange={handleOnChange}
                                    />
                                </Col>
                                </Row>


                                <Row>
                                    <Col md={6}>
                                        <FormField
                                        key='currencyCode'
                                        type='select'
                                        fieldName='currencyCode'
                                        fieldValue={currencyCode}
                                        label={t('Currency')}
                                            errors={errors}
                                            touched={touched}
                                            handleOnChange={handleOnChange}
                                    >
                                        <option key="CRC" value="CRC">{t('CRC')}</option>
                                        <option key="USD" value="USD">{t('USD')}</option>
                                    </FormField>
                                    </Col>

                                    <Col md={6}>
                                    
                                    </Col>
                            </Row>

<Row>
                                            <Col xs={12}>
                                                <h3 style={{marginTop: 30}}>{t('Billing')}</h3>
                                                <hr />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>

                                                <FormField
                                                    key='billingReference'
                                                    type='text'
                                                    maxLength={255}
                                                    fieldName='billingReference'
                                                    fieldValue={billingReference}
                                                    label={t('Billing Reference')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />
                                            </Col>
                                            <Col md={6}>

                                                <FormField
                                                    key='taxPercentage'
                                                    type='text'
                                                    maxLength={20}
                                                    fieldName='taxPercentage'
                                                    fieldValue={taxPercentage}
                                                    label={t('Billing Tax Percentage')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>

                                            <Col md={6}>

                                                <FormField
                                                    key='crossReference'
                                                    type='text'
                                                    maxLength={45}
                                                    fieldName='crossReference'
                                                    fieldValue={crossReference}
                                                    label={t('Reference Code')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />

                                            </Col>
                                            <Col md={6}>
                                                <FormField
                                                    key='billingExternalCode'
                                                    type='text'
                                                    maxLength={45}
                                                    fieldName='billingExternalCode'
                                                    fieldValue={billingExternalCode}
                                                    label={t('Billing External Code')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />
                                            </Col>



                                        </Row>

                                        <Row>
                                            <Col md={6}>

                                                <FormField
                                                    key='billingChannel'
                                                    type='text'
                                                    maxLength={45}
                                                    fieldName='billingChannel'
                                                    fieldValue={billingChannel}
                                                    label={t('Billing Channel')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />

                                            </Col>
                                            <Col md={6}>

                                                <FormField
                                                    key='billingTaxCode'
                                                    type='text'
                                                    maxLength={45}
                                                    fieldName='billingTaxCode'
                                                    fieldValue={billingTaxCode}
                                                    label={t('Billing Tax Code')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>

                                                <FormField
                                                    key='billingTaxRate'
                                                    type='text'
                                                    maxLength={20}
                                                    fieldName='billingTaxRate'
                                                    fieldValue={billingTaxRate}
                                                    label={t('Billing Tax Rate')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />
                                            </Col>


                                        </Row>                            



                                <div className="button-items">
                                    <Button type="submit" color="primary" className="button">
                                        <i className="mdi mdi-content-save me-1"></i>
                                        {t("Save")}
                                    </Button>
                                    <Button
                                        color="light"
                                        className="button mr-1"
                                        onClick={() => {
                                            history.goBack();
                                        }}
                                    >
                                        <i className="mdi mdi-arrow-left me-1"></i>
                                        {t("Back")}
                                    </Button>
                                </div>
                            </Form>
                            )}
                        </Formik>


                        <Row>
                        <Col style={{paddingTop: '25px'}}>
                            <p>{t('Service label')}</p>
                            <ul>
                                <li>{t('Service label1')}</li>
                                <li>{t('Service label2')}</li>
                                <li>{t('Service label3')}</li>
                                <li>{t('Service label4')}</li>
                            </ul>
                        </Col>
                        </Row>

                        </CardBody>
                    </Card>
                    </Col>
                </Row>

                {showConfirm ?

                <SweetAlert
                    title={t('Service Catalog Form Confirm')}
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText={t('Yes')}
                    cancelBtnText={t('No')}
                    onConfirm={() => {
                        onSend();
                    }}
                    onCancel={() => {
                        setShowConfirm(false);
                    }}
                >

                </SweetAlert>
                : null}

            </PageBody>
        </React.Fragment>
    );
}

export default ServiceCatalogDetailPage;