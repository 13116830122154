import { ALERT_MESSAGE } from 'constants/constants';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { getServicePrescriptionDetail, clearServicePrescription, showSuccessAlert, updateServicePrescription } from 'store/actions';
import LaboratoryPrescriptionTemplateForm from './Components/laboratory-prescription-template-form';

const LaboratoryPrescriptionTemplateEditPage = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();
    const prescriptionCreated = useSelector(state => state.servicePrescriptions.updated);
    const prescriptionDetail = useSelector(state => state.servicePrescriptions.prescriptionDetail);

    useEffect(() => {
        if(id) {
            dispatch(getServicePrescriptionDetail({id: id}));
        }
    }, [id]);

    useEffect(() => {
        if(prescriptionCreated) {
            dispatch(showSuccessAlert(ALERT_MESSAGE.SAVED, () => {
                dispatch(clearServicePrescription());
                onBack();
            }));
        }
    }, [prescriptionCreated]);
    
    const onSubmit = (data) => {

        const prescription = {
            ...data,
            doctorFullname: prescriptionDetail.doctorFullname,
            doctorMedicalCode: prescriptionDetail.doctorMedicalCode,
            doctorMedicalPhone: prescriptionDetail.doctorMedicalPhone
        };

        dispatch(updateServicePrescription(prescription));
    }

    const onBack = () => {
        history.push('/laboratory-prescription-templates')
    }
    
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t('Title App')}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h2 className="mb-2 font-size-18">{t('Edit Prescription Template')}</h2>
                    </div>
                    
                    <Row>
                        {prescriptionDetail ?
                        <LaboratoryPrescriptionTemplateForm 
                            onSubmit={onSubmit}
                            onBack={onBack}
                            template={prescriptionDetail}
                        />
                        : null}
                    </Row>
                </Container>
            </div>
            
        </React.Fragment> 
    );
}
 
export default LaboratoryPrescriptionTemplateEditPage;