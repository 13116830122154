import React, { useCallback } from 'react';
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { numberFormat } from 'helpers/utils';

const MedicalServiceDetailCard = ({service}) => {

    const { t } = useTranslation();
    const currencyCodeList = useSelector(state => state.catalogs.currencyCodeList);

    const getCurrencySymbol = useCallback((currencyCode) => {
        const currency = currencyCodeList.find(current => current.code === currencyCode);
        return currency ? currency.currency : currencyCode;
    });
/*
"id": 17,
  "description": "Anticuerpos SARS",
  "observations": "Sin observaciones",
  "medicalInstruction": "Asistir con mascarilla",
  "currencyCode": "CRC",
  "price": 25000,
  "reservationPrice": 2500,
  "taxPercentage": 13,
  "membershipPrice": 25000
*/ 
    return ( 
        <React.Fragment>
            <Card className='task-box service-card'>
                <CardBody>
                    <Row>
                        <Col xs={12}>
                            <h5>{service.description}</h5>
                            <h6>{t('Consultation Fee')}: {getCurrencySymbol(service.currencyCode)}{numberFormat(service.price)}</h6>
                            <h6>{t('Observations')}: {service.observations}</h6>
                            <h6>{t('Medical Instructions')}: {service.medicalInstruction}</h6>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
     );
}

MedicalServiceDetailCard.propTypes = {
    service: PropTypes.object
};

export default MedicalServiceDetailCard;