import {
    SHOW_SUCCESS_ALERT,
    SHOW_WARNING_ALERT,
    SHOW_LOADING_ALERT,
    HIDE_SUCCESS_ALERT,
    HIDE_WARNING_ALERT,
    HIDE_LOADING_ALERT
  } from "./actionTypes"
  
  const INIT_STATE = {
    showSuccess: false,
    showWarning: false,
    showLoading: false,
    messageSuccess: '',
    messageWarning: '',
    messageLoading: '',
    callback: undefined
  }
  
  const Alerts = (state = INIT_STATE, action) => {
    switch (action.type) {
      case SHOW_SUCCESS_ALERT:
        return {
          ...state,
          showSuccess: true,
          messageSuccess: action.payload.message,
          callback: action.payload.callback
        }
  
      case HIDE_SUCCESS_ALERT:
        return {
          ...state,
          showSuccess: false,
          messageSuccess: '',
          callback: undefined
        }
  
      case SHOW_WARNING_ALERT:
        return {
          ...state,
          showWarning: true,
          messageWarning: action.payload
        }
  
      case HIDE_WARNING_ALERT:
        return {
          ...state,
          showWarning: false,
          messageWarning: ''
        }
      case SHOW_LOADING_ALERT:
        return {
          ...state,
          showLoading: true,
          messageLoading: action.payload
        }
  
      case HIDE_LOADING_ALERT:
        return {
          ...state,
          showLoading: false,
          messageLoading: ''
        }
  
      default:
        return state
    }
  }
  
  export default Alerts
  