/* MEDICAL CATEGORY LIST */
export const GET_MEDICAL_CATEGORY_LIST = "GET_MEDICAL_CATEGORY_LIST"
export const GET_MEDICAL_CATEGORY_LIST_FAIL = "GET_MEDICAL_CATEGORY_LIST_FAIL"
export const GET_MEDICAL_CATEGORY_LIST_SUCCESS = "GET_MEDICAL_CATEGORY_LIST_SUCCESS"

/* MEDICAL SERVICE LIST */
export const GET_MEDICAL_SERVICE_LIST = "GET_MEDICAL_SERVICE_LIST"
export const GET_MEDICAL_SERVICE_LIST_FAIL = "GET_MEDICAL_SERVICE_LIST_FAIL"
export const GET_MEDICAL_SERVICE_LIST_SUCCESS = "GET_MEDICAL_SERVICE_LIST_SUCCESS"

/* ALL MEDICAL SERVICE LIST */
export const GET_ALL_MEDICAL_SERVICE_LIST = "GET_ALL_MEDICAL_SERVICE_LIST"
export const GET_ALL_MEDICAL_SERVICE_LIST_FAIL = "GET_ALL_MEDICAL_SERVICE_LIST_FAIL"
export const GET_ALL_MEDICAL_SERVICE_LIST_SUCCESS = "GET_ALL_MEDICAL_SERVICE_LIST_SUCCESS"

export const MODIFY_MEDICAL_SERVICE = "MODIFY_MEDICAL_SERVICE"
export const MODIFY_MEDICAL_SERVICE_FAIL = "MODIFY_MEDICAL_SERVICE_FAIL"
export const MODIFY_MEDICAL_SERVICE_SUCCESS = "MODIFY_MEDICAL_SERVICE_SUCCESS"

/* ALL MEDICAL SERVICE LIST */
export const GET_ALL_MEDICAL_SERVICE_BY_TYPE_LIST = "GET_ALL_MEDICAL_SERVICE_BY_TYPE_LIST"
export const GET_ALL_MEDICAL_SERVICE_BY_TYPE_LIST_FAIL = "GET_ALL_MEDICAL_SERVICE_BY_TYPE_LIST_FAIL"
export const GET_ALL_MEDICAL_SERVICE_BY_TYPE_LIST_SUCCESS = "GET_ALL_MEDICAL_SERVICE_BY_TYPE_LIST_SUCCESS"

/* MEDICAL SERVICE DETAIL */
export const GET_MEDICAL_SERVICE_DETAIL = "GET_MEDICAL_SERVICE_DETAIL"
export const GET_MEDICAL_SERVICE_DETAIL_FAIL = "GET_MEDICAL_SERVICE_DETAIL_FAIL"
export const GET_MEDICAL_SERVICE_DETAIL_SUCCESS = "GET_MEDICAL_SERVICE_DETAIL_SUCCESS"