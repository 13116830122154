import React, { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { getPaymentMethods } from 'store/actions';
import cancelMembershipContext from '../context/cancelMembershipContext';
import CancelMembershipPaymentMethodForm from './cancel-membership-payment-method-form';
import visa from '../../../../../assets/images/brands/visa-128.png';
import mastercard from '../../../../../assets/images/brands/mastercard-128.png';


const CancelMembershipPaymentMethod = () => {

    const membershipContext = useContext(cancelMembershipContext);
    const { showPaymentForm, setShowPaymentForm, 
            payment, setPayment, selectedPayment, setSelectedPayment,
            addPayment } = membershipContext;

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const account = useSelector(state => state.accounts.accountDetail);
    const paymentMethods = useSelector(state => state.paymentMethods.paymentMethods);
    const paymentMethodCreated = useSelector(state => state.paymentMethods.created);

    useEffect(() => {
        if(account) {
            dispatch(getPaymentMethods({accountCode: account.accountCode}));
        }
    }, [account]);

    useEffect(() => {
        if(paymentMethods) {
            const paymentDefault = paymentMethods.find(current => current.isDefault == 'Y');
            if(paymentDefault) {
                setSelectedPayment(paymentDefault);
            }
        }
    }, [paymentMethods]);

    useEffect(() => {
        if(paymentMethodCreated) {
            dispatch(getPaymentMethods({accountCode: account.accountCode}));
        }
    }, [paymentMethodCreated]);

    const addRow = () => {
        setPayment(null);
        setShowPaymentForm(true);
    };

    const isSelectedPayment = (option) => {
        return selectedPayment && option.id == selectedPayment.id;
    }

    return ( 
        <React.Fragment>
            
            <div className="">
                {/*<Container fluid>*/}
                    {/* Render Breadcrumbs */}
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h2 className="mb-2 font-size-18">{t('Payment Methods')}</h2>
                        <div className="button-items">
                            <Button color="primary" className="button" onClick={() => { addRow() }}><i className="mdi mdi-plus-circle-outline me-1"></i>{t('Create')}</Button>
                        </div>
                    </div>
                    <Row>
                        {paymentMethods.map((option, key) => (
                            <Col md={6} lg={6} xl={4} key={`_col_${key}`}>
                                {/*<Card color="primary" className="text-white-50 mini-stats-wid dashboard-option" onClick={() => {setSelectedPayment(option)}}>*/}
                                <Card className={"task-box dashboard-option payment-option mini-stats-wid " + (isSelectedPayment(option) ? 'selected-payment' : '')} onClick={() => {setSelectedPayment(option)}}>
                                    
                                    <CardBody className="text-white">
                                        <div className='row'>
                                            <div className="text-end ms-1">
                                                <strong className='font-size-24'>
                                                    <i className={`mdi ${isSelectedPayment(option)? 'mdi-check-bold' : 'bx bx-uncheck'}`}></i>
                                                </strong>
                                            </div> 
                                        </div>

                                        <Row className="row">
                                            <Col xs={12} className="float-start col-xs-12">
                                                <h4 className="mb-0 text-white">XXXX-XXXX-XXXX-{option.lastDigits}</h4>
                                              
                                            </Col>
                                            
                                        </Row>

                                        <Row className="row">
                                            <Col xs={9} className="text-start">
                                                <p className="fw-medium text-white">
                                                    {option.holderName}
                                                </p>
                                                {option.isDefault == 'Y' ? <span className='badge badge-soft-secondary text-white'>{t('Default')}</span> : null}
                                            </Col>
                                            <Col xs={3} className="text-end">
                                                <img width={50} src={ option.brand == "VIS"? visa : mastercard } />
                                            </Col>
                                        </Row>
                                        
                                    </CardBody>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                {/*</Container>*/}
            </div>
            {showPaymentForm ?
            <CancelMembershipPaymentMethodForm 
                isOpen={showPaymentForm} 
                paymentMethod={payment}
                onSubmit={(paymentData) => {
                    addPayment(paymentData);
                }} 
                onCancel={() => {
                    setPayment(null);
                    setShowPaymentForm(false);
                }}
            /> : null}
        </React.Fragment>
    );
}
 
export default CancelMembershipPaymentMethod;