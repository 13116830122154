import { ADD_QUESTION, ADD_QUESTION_FAIL, ADD_QUESTION_SUCCESS, CLEAR_QUESTIONS_STATE, DELETE_QUESTION, DELETE_QUESTION_FAIL, DELETE_QUESTION_SUCCESS, GET_QUESTIONS_BY_TYPE, 
         GET_QUESTIONS_BY_TYPE_FAIL, GET_QUESTIONS_BY_TYPE_SUCCESS, GET_QUESTION_DETAIL, GET_QUESTION_DETAIL_FAIL, GET_QUESTION_DETAIL_SUCCESS, GET_QUESTION_LIST, GET_QUESTION_LIST_FAIL, GET_QUESTION_LIST_SUCCESS, UPDATE_QUESTION, UPDATE_QUESTION_FAIL, UPDATE_QUESTION_SUCCESS } from "./actionTypes"

//Questions
export const clearQuestion = () => ({
    type: CLEAR_QUESTIONS_STATE
  })
  
  export const getQuestionsByType = (companyId, type) => ({
    type: GET_QUESTIONS_BY_TYPE,
    payload: {companyId: companyId, type: type}
  })
  
  export const getQuestionsByTypeSuccess = invoice => ({
    type: GET_QUESTIONS_BY_TYPE_SUCCESS,
    payload: invoice,
  })
  
  export const getQuestionsByTypeFail = error => ({
    type: GET_QUESTIONS_BY_TYPE_FAIL,
    payload: error,
  })

  export const getQuestions = (request) => ({
    type: GET_QUESTION_LIST,
    payload: request
  })
  
  export const getQuestionsSuccess = invoice => ({
    type: GET_QUESTION_LIST_SUCCESS,
    payload: invoice,
  })
  
  export const getQuestionsFail = error => ({
    type: GET_QUESTION_LIST_FAIL,
    payload: error,
  })
  
  //Question Detail
  export const getQuestionDetail = request => ({
    type: GET_QUESTION_DETAIL,
    payload: request
  })
  
  export const getQuestionDetailSuccess = question => ({
    type: GET_QUESTION_DETAIL_SUCCESS,
    payload: question,
  })
  
  export const getQuestionDetailFail = error => ({
    type: GET_QUESTION_DETAIL_FAIL,
    payload: error,
  })
  
  //Add Question
  export const addQuestion = request => ({
    type: ADD_QUESTION,
    payload: request
  })
  
  export const addQuestionSuccess = question => ({
    type: ADD_QUESTION_SUCCESS,
    payload: question,
  })
  
  export const addQuestionFail = error => ({
    type: ADD_QUESTION_FAIL,
    payload: error,
  })
  
  //Update Question
  export const updateQuestion = request => ({
    type: UPDATE_QUESTION,
    payload: request
  })
  
  export const updateQuestionSuccess = question => ({
    type: UPDATE_QUESTION_SUCCESS,
    payload: question,
  })
  
  export const updateQuestionFail = error => ({
    type: UPDATE_QUESTION_FAIL,
    payload: error,
  })
  
  //Delete Question
  export const deleteQuestion = request => ({
    type: DELETE_QUESTION,
    payload: request
  })
  
  export const deleteQuestionSuccess = question => ({
    type: DELETE_QUESTION_SUCCESS,
    payload: question,
  })
  
  export const deleteQuestionFail = error => ({
    type: DELETE_QUESTION_FAIL,
    payload: error,
  })