import { ALERT_MESSAGE } from 'constants/constants';
import BenefitForm from 'pages/Management/Commons/benefit-form/benefit-form';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { addBenefit, clearMembership, showSuccessAlert } from 'store/actions';

const AddBenefit = () => {

    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const benefitCreated = useSelector(state => state.memberships.benefitCreated);

    useEffect(() => {
        if(benefitCreated) {
            dispatch(showSuccessAlert(ALERT_MESSAGE.SAVED, () => {
                dispatch(clearMembership());
                history.push(`/membership-package-benefits/${id}`);
            }));
        }
        
    }, [benefitCreated]);

    const onSubmit = (data) => {
        data.membershipId = id;
        dispatch(addBenefit(data));
    }

    const onBack = () => {
        history.push(`/membership-package-benefits/${id}`);
    }

    return ( 
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t('Title App')}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h2 className="mb-2 font-size-18">{t('New Benefit')}</h2>
                    </div>
                    
                    <Row>
                        <BenefitForm 
                            onSubmit={onSubmit}
                            onBack={onBack}
                        />
                    </Row>
                </Container>
            </div> 
        </React.Fragment> 
    );
}
 
export default AddBenefit;