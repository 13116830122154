import { ALERT_MESSAGE } from 'constants/constants';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { getPrescriptionDetail, clearPrescription, showSuccessAlert, updatePrescription } from 'store/actions';
import PrescriptionTemplateForm from './Components/prescription-template-form';

const PrescriptionTemplateEditPage = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { id } = useParams();
    const prescriptionCreated = useSelector(state => state.prescriptions.updated);
    const prescriptionDetail = useSelector(state => state.prescriptions.prescriptionDetail);

    useEffect(() => {
        if(id) {
            dispatch(getPrescriptionDetail({id: id}));
        }
    }, [id]);

    useEffect(() => {
        if(prescriptionCreated) {
            dispatch(showSuccessAlert(ALERT_MESSAGE.SAVED, () => {
                dispatch(clearPrescription());
                onBack();
            }));
        }
    }, [prescriptionCreated]);
    
    const onSubmit = (data) => {
        dispatch(updatePrescription(data));
    }

    const onBack = () => {
        history.push('/medical-prescription-templates')
    }
    
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t('Title App')}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h2 className="mb-2 font-size-18">{t('Edit Prescription Template')}</h2>
                    </div>
                    
                    <Row>
                        {prescriptionDetail ?
                        <PrescriptionTemplateForm 
                            onSubmit={onSubmit}
                            onBack={onBack}
                            template={prescriptionDetail}
                        />
                        : null}
                    </Row>
                </Container>
            </div>
            
        </React.Fragment> 
    );
}
 
export default PrescriptionTemplateEditPage;