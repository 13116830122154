import React from "react"
import PropTypes from 'prop-types'
import { Route, Redirect } from "react-router-dom"
import { useSelector } from "react-redux";
import { checkOption } from "helpers/Security/role_helper";

const AppRoute = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  permission = "any",
  ...rest
}) => {

  const principal = useSelector(state => state.principals.principalData);
  
  return (
    
    <Route
      {...rest}
      render={props => {

        if (isAuthProtected && !localStorage.getItem("authUser")) {
          
          return (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )

        } else if (isAuthProtected && 
                   localStorage.getItem("authUser") && 
                   permission &&
                   permission !== "any" && 
                   principal && 
                   !checkOption(permission, principal.rolePrincipals)) {
                    
          return (
            <Redirect
              to={{ pathname: "/", state: { from: props.location } }}
          />
          )
        }
        
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        )
      }}
    />
  )
}
AppRoute.propTypes = {
  isAuthProtected: PropTypes.bool,
  permission: PropTypes.string,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any
}

export default AppRoute
