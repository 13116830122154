import DataTable from "components/Common/DataTable/datatable";
import PageBody from "components/Common/PageLayout/page-body";
import { ALERT_MESSAGE } from "constants/constants";
import { isNumber } from "lodash";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Button
} from "reactstrap";
import {
  clearRole,
  getAccountDetail,
  getPrincipalDetail,
  getPrincipalDetailByAccount,
  getRoles,
  savePrincipalRoles,
  showSuccessAlert,
} from "store/actions";

const RoleUserList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const [selectedRoleResources, setSelectedRoleResources] = useState([]);

  const resources = useSelector(state => state.roles.roles);
  //const roleResources = useSelector(state => state.roles.roleResources);
  const account = useSelector(state => state.accounts.accountDetail);
  const principal = useSelector(state => state.principals.principalDetail);
  const principalRolesCreated = useSelector(
    state => state.principals.principalRolesCreated
  );

  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getAccountDetail({ accountCode: id }));
    dispatch(getPrincipalDetailByAccount({ accountCode: id }));
    dispatch(getRoles());
  }, []);

  useEffect(() => {
    if (principalRolesCreated) {
      dispatch(
        showSuccessAlert(ALERT_MESSAGE.UPDATED, () => {
          dispatch(clearRole());
          dispatch(getAccountDetail({ accountCode: id }));
          dispatch(getPrincipalDetailByAccount({ accountCode: id }));
          dispatch(getRoles());
        })
      );
    }
  }, [principalRolesCreated]);

  useEffect(() => {
    if (principal) {
      let data = principal.rolePrincipals.map(
        rolePrincipal => isNumber(rolePrincipal.role) ? rolePrincipal.role : rolePrincipal.role.id
      );
      setSelectedRoleResources(data);
    }
  }, [principal]);

  useEffect(() => {
    if (resources) {
      setData(resources.map(current => ({
        id: current.id,
        code: current.code,
        description: t(current.description)
      })));
    }
  }, [resources]);

  const columns = [
    /*{
            text: t('Module'),
            dataField: 'scope',
            sort: true,
            formatter: (cellContent, row) => <React.Fragment>{row.scope.code}</React.Fragment>
        },*/ {
      text: t("Code"),
      dataField: "code",
      sort: true,
      formatter: (cellContent, row) => (
        <React.Fragment>{row.code}</React.Fragment>
      ),
    },
    {
      text: t("Description"),
      dataField: "description",
      sort: true,
      formatter: (cellContent, row) => (
        <React.Fragment>{row.description}</React.Fragment>
      ),
    },
  ];

  const selectRow = {
    mode: "checkbox",
    selected: selectedRoleResources,
    onSelect: (row, isSelect, rowIndex, e) => {
      
      if (isSelect) {
        setSelectedRoleResources([...selectedRoleResources, row.id]);
      } else {
        const copy = [...selectedRoleResources];
        setSelectedRoleResources([
          ...copy.filter(current => current != row.id),
        ]);
      }
    },
    onSelectAll: (isSelect, rows, e) => {
      
      if (isSelect) {
        setSelectedRoleResources([...selectedRoleResources, rows]);
      } else {
        let copy = [...selectedRoleResources];
        rows.map(row => (copy = copy.filter(current => current != row.id)));
        setSelectedRoleResources([...copy]);
      }
    },
  };

  const defaultSorted = [
    {
      dataField: 'description', // if dataField is not match to any column you defined, it will be ignored.
      order: 'asc', // desc or asc
    },
  ];

  const save = () => {
    const request = {
      tenantId: principal.tenantId,
      principal: {
        id: principal.id,
        tenantId: principal.tenantId,
      },
      roles: selectedRoleResources.map(current => ({ roleId: current })),
    };
    dispatch(savePrincipalRoles(request));
  };

  const getTitle = () => {
    return account ? `${t("User")}: ${[
      account.firstName,
      account.secondName,
      account.firstSurname,
      account.secondSurname,
    ].join(" ")}` : '';
  }

  return (
    <PageBody
      title="Groups"
      actions={
        <React.Fragment>
          <Button
            color="primary"
            className="button"
            onClick={() => {
              save();
            }}
          >
            <i className="mdi mdi-content-save me-1"></i>
            {t("Save")}
          </Button>
          <Button
            color="light"
            className="button"
            onClick={() => {
              history.push("/security-user");
            }}
          >
            <i className="mdi mdi-arrow-left me-1"></i>
            {t("Back")}
          </Button>
        </React.Fragment>
      }
    >

      <DataTable
        title={getTitle()}
        columns={columns}
        data={data}
        keyField={'id'}
        selectRow={selectRow}
        sort={defaultSorted}
      />
    </PageBody>
  );
};

export default RoleUserList;

