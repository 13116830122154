import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { toDateStringFormat } from "helpers/utils";
import { useHistory } from 'react-router-dom';


const ExampPatientResultCard = ({ result, datetime, resultId, appointmentId, account }) => {
  const { t } = useTranslation();
  let {
    laboratoryOrder,
    medicalService,
    completionStatus,
  } = result;

  const history = useHistory();

  const getCompletionStatus = completionStatus => {
    switch (completionStatus) {
      case "PEN":
        return "Pending";
      case "DON":
        return "Done";
      case "FIN":
        return "Done";
      case "WAI":
        return "Waiting List";
      case "CAN":
        return "Cancelled";
      default:
        return completionStatus;
    }
  };

  const getResultStatusColor = result => {
    let redAlert = 0;
    if (result) {
      if (result.laboratoryOrderResultValues.length > 0) {
        result.laboratoryOrderResultValues.map(value => {
          if (value.alarmIndicator === "Y") {
            redAlert++;
          }
        })
      }
    }
    if (redAlert > 0) {
      return "danger";
    } else {
      return "success"
    }
  }

  { result.resultFileLocation ? completionStatus = 'FIN' : completionStatus = 'PEN' }

  return (
    <Col sm={6} md={4} lg={4}>
      <Card>
        <CardBody>
          <Row>
            <Col sm={8}>
              {/* {
                result.resultFileLocation
                  ? <div className="float-start">#{laboratoryOrder}-R{result.id}</div>
                  : <div className="float-start">#{laboratoryOrder}-S{result.id}</div>
              } */}
              {
                <div className="float-start">#{laboratoryOrder}</div>
              }
            </Col>
            <Col sm={4}>
              <div className="float-end">{toDateStringFormat(datetime)}</div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col sm={8}>
              <div className="float-start">
                <strong>{medicalService ? medicalService.description : ''}</strong>
              </div>
            </Col>
            <Col sm={4}>
              <div className="float-end">
                <strong>{t(getCompletionStatus(completionStatus))}</strong>
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col sm={8}>
              <div className="float-start">
                Sku: {medicalService ? medicalService.itemCode : ''}
              </div>
            </Col>
            <Col sm={4}>
              <div className="align-middle float-end text-right mt-2">
                {
                  result.laboratoryOrderResultValues ?
                  result.laboratoryOrderResultValues.length > 0
                    ? <div className="align-middle float-end text-right mt-2">
                      <Button
                        color={getResultStatusColor(result)}
                        className="button"
                        onClick={() => {
                          history.push(`/laboratory-patient-exam-result/${account}/${appointmentId}/${resultId}`)
                        }}
                      >
                        {t("View Detail")} <i className="mdi mdi-arrow-right" />
                      </Button>
                    </div>
                    : null
                    : null
                }
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col >
  );
};

ExampPatientResultCard.propTypes = {
  result: PropTypes.object,
  datetime: PropTypes.string,
  onRedirectDetail: PropTypes.func,
  appointmentId: PropTypes.string.isRequired,
  account: PropTypes.string.isRequired,
  resultId: PropTypes.number,
};

export default ExampPatientResultCard;
