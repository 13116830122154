import { 
    CLEAR_AFFILIATED_MEMBERSHIP_CANCELLATION,
    GET_AFFILIATED_MEMBERSHIP_CANCEL,
    GET_AFFILIATED_MEMBERSHIP_CANCEL_FAIL,
    GET_AFFILIATED_MEMBERSHIP_CANCEL_SUCCESS,
    GET_AFFILIATED_MEMBERSHIP_DEBT,
    GET_AFFILIATED_MEMBERSHIP_DEBT_FAIL,
    GET_AFFILIATED_MEMBERSHIP_DEBT_SUCCESS
} from "./actionTypes"

export const clearAffiliatedMembershipCancellation = () => ({
    type: CLEAR_AFFILIATED_MEMBERSHIP_CANCELLATION
})

//Affiliated Membership Debt
export const getAffiliatedMembershipDebt = request => ({
    type: GET_AFFILIATED_MEMBERSHIP_DEBT,
    request
})

export const getAffiliatedMembershipDebtSuccess = response => ({
    type: GET_AFFILIATED_MEMBERSHIP_DEBT_SUCCESS,
    payload: response,
})

export const getAffiliatedMembershipDebtFail = error => ({
    type: GET_AFFILIATED_MEMBERSHIP_DEBT_FAIL,
    payload: error,
})

//Affiliated Membership Cancel
export const getAffiliatedMembershipCancel = request => ({
    type: GET_AFFILIATED_MEMBERSHIP_CANCEL,
    request
})

export const getAffiliatedMembershipCancelSuccess = response => ({
    type: GET_AFFILIATED_MEMBERSHIP_CANCEL_SUCCESS,
    payload: response,
})

export const getAffiliatedMembershipCancelFail = error => ({
    type: GET_AFFILIATED_MEMBERSHIP_CANCEL_FAIL,
    payload: error,
})