// global version
const VERSION="1.0.22.2";

// api url
const BASE_URL_LOCAL="http://localhost";
const BASE_URL_LOCAL_QA="https://z3d4lbcxte.execute-api.us-east-1.amazonaws.com/qa";
const BASE_URL_LOCAL_UAT="https://jeg7lpzkz7.execute-api.us-east-1.amazonaws.com/uat";
const BASE_URL_VITAE_UAT="https://rest.dev.vitae.care";
const BASE_URL_VITAE_PROD="https://rest.vitae.care";
const BASE_URL_VITAE_MVP_LOCAL="http://localhost:8085";
const BASE_URL_VITAE_MVP_STG="http://provider-env.eba-aupuvbpm.us-east-1.elasticbeanstalk.com";
const BASE_URL_VITAE_MVP_PROD="https://api.vitae.care";

const localConfig = {
    Security: BASE_URL_LOCAL + `:8088/security`,
    Accounts: BASE_URL_LOCAL + `:8087/account`,
    Management: BASE_URL_LOCAL + `:8083/management`,
    Financing: BASE_URL_LOCAL + `:8082/financing`,
    FinancingLegacy: BASE_URL_VITAE_MVP_STG + `/financing`,
    Medical: BASE_URL_LOCAL + `:8084/medical`,
    Notification: BASE_URL_LOCAL + `:8085/notification`,
    Pharmacy: BASE_URL_LOCAL + `:8086/pharmacy`,
    Version: VERSION+`-ldev`,
  };
  
  const localMixConfig = {
    Security: BASE_URL_LOCAL_UAT + `/security`,
    Accounts: BASE_URL_LOCAL_UAT + `/account`,
    Management: BASE_URL_LOCAL_UAT + `/management`,
    Financing: BASE_URL_LOCAL_UAT + `/financing`,
    FinancingLegacy: BASE_URL_VITAE_MVP_LOCAL + `/financing`,
    Medical: BASE_URL_LOCAL_UAT + `/medical`,
    Notification: BASE_URL_LOCAL_UAT + `/notification`,
    Pharmacy: BASE_URL_LOCAL_UAT + `/pharmacy`,
    Version: VERSION+`-mix`,
  };
  
  const localQAConfig = {
    Security: BASE_URL_LOCAL_QA + `/security`,
    Accounts: BASE_URL_LOCAL_QA + `/account`,
    Management: BASE_URL_LOCAL_QA + `/management`,
    Financing: BASE_URL_LOCAL_QA + `/financing`,
    FinancingLegacy: BASE_URL_VITAE_MVP_STG + `/financing`,
    Medical: BASE_URL_LOCAL_QA + `/medical`,
    Notification: BASE_URL_LOCAL_QA + `/notification`,
    Pharmacy: BASE_URL_LOCAL_QA + `/pharmacy`,
    Version: VERSION+`-lqa`,
  };
  
  const localUATConfig = {
    Security: BASE_URL_LOCAL_UAT + `/security`,
    Accounts: BASE_URL_LOCAL_UAT + `/account`,
    Management: BASE_URL_LOCAL_UAT + `/management`,
    Financing: BASE_URL_LOCAL_UAT + `/financing`,
    FinancingLegacy: BASE_URL_VITAE_MVP_STG + `/financing`,
    Medical: BASE_URL_LOCAL_UAT + `/medical`,
    Notification: BASE_URL_LOCAL_UAT + `/notification`,
    Pharmacy: BASE_URL_LOCAL_UAT + `/pharmacy`,
    Version: VERSION+`-luat`,
  };
  
  const uatConfig = {
    Security: BASE_URL_VITAE_UAT + `/security`,
    Accounts: BASE_URL_VITAE_UAT + `/account`,
    Management: BASE_URL_VITAE_UAT + `/management`,
    Financing: BASE_URL_VITAE_UAT + `/financing`,
    FinancingLegacy: BASE_URL_VITAE_MVP_PROD + `/financing`,
    Medical: BASE_URL_VITAE_UAT + `/medical`,
    Notification: BASE_URL_VITAE_UAT + `/notification`,
    Pharmacy: BASE_URL_VITAE_UAT + `/pharmacy`,
    Version: VERSION+`-auat`,
  };
  
  const prodConfig = {
    Security: BASE_URL_VITAE_PROD + `/security`,
    Accounts: BASE_URL_VITAE_PROD + `/account`,
    Management: BASE_URL_VITAE_PROD + `/management`,
    Financing: BASE_URL_VITAE_PROD + `/financing`,
    FinancingLegacy: BASE_URL_VITAE_MVP_PROD + `/financing`,
    Medical: BASE_URL_VITAE_PROD + `/medical`,
    Notification: BASE_URL_VITAE_PROD + `/notification`,
    Pharmacy: BASE_URL_VITAE_PROD + `/pharmacy`,
    Version: VERSION,
  };
  
  // current profile
  const Variables = prodConfig;
  
  export default Variables;