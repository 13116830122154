import { CLEAR_PRINCIPAL_STATE, 
         ADD_PRINCIPAL, 
         ADD_PRINCIPAL_FAIL, 
         ADD_PRINCIPAL_SUCCESS, 
         GET_PRINCIPAL_DETAIL,
         GET_PRINCIPAL_DETAIL_SUCCESS,
         GET_PRINCIPAL_DETAIL_FAIL,
         GET_PRINCIPAL_DETAIL_BY_ACCOUNT_FAIL,
         GET_PRINCIPAL_DETAIL_BY_ACCOUNT_SUCCESS,
         GET_PRINCIPAL_DETAIL_BY_ACCOUNT,
         SAVE_PRINCIPAL_ROLES,
         SAVE_PRINCIPAL_ROLES_SUCCESS,
         SAVE_PRINCIPAL_ROLES_FAIL,
         GET_ROLE_PRINCIPAL_LIST,
         GET_ROLE_PRINCIPAL_LIST_SUCCESS,
         GET_ROLE_PRINCIPAL_LIST_FAIL,
         GET_PRINCIPAL_BY_ACCOUNT,
         GET_PRINCIPAL_BY_ACCOUNT_SUCCESS,
         GET_PRINCIPAL_BY_ACCOUNT_FAIL,
         CLEAR_PRINCIPAL_CREATION,
         VERIFY_USER_INFORMATION,
         VERIFY_USER_INFORMATION_SUCCESS,
         VERIFY_USER_INFORMATION_FAIL,
         DELETE_PRINCIPAL,
         CANCEL_PRINCIPAL,
         VERIFY_EMAIL_INFORMATION_SUCCESS,
         VERIFY_EMAIL_INFORMATION,
         VERIFY_EMAIL_INFORMATION_FAIL,
         VERIFY_EMAIL_INFORMATION_RESET} from "./actionTypes"

//Principals
export const clearPrincipal = () => ({
    type: CLEAR_PRINCIPAL_STATE
  })

  export const clearPrincipalCreation = () => ({
    type: CLEAR_PRINCIPAL_CREATION
  })

  export const addPrincipal = (request) => ({
    type: ADD_PRINCIPAL,
    payload: request
  })
  
  export const addPrincipalSuccess = invoice => ({
    type: ADD_PRINCIPAL_SUCCESS,
    payload: invoice,
  })
  
  export const addPrincipalFail = error => ({
    type: ADD_PRINCIPAL_FAIL,
    payload: error,
  })

  export const verifyUser = (request) => ({
    type: VERIFY_USER_INFORMATION,
    payload: request
  })
  
  export const verifyUserSuccess = response => ({
    type: VERIFY_USER_INFORMATION_SUCCESS,
    payload: response
  })
  
  export const verifyUserFail = error => ({
    type: VERIFY_USER_INFORMATION_FAIL,
    payload: error,
  })



  export const verifyEmail = (request) => ({
    type: VERIFY_EMAIL_INFORMATION,
    payload: request
  })
  
  export const verifyEmailSuccess = response => ({
    type: VERIFY_EMAIL_INFORMATION_SUCCESS,
    payload: response
  })
  
  export const verifyEmailFail = error => ({
    type: VERIFY_EMAIL_INFORMATION_FAIL,
    payload: error,
  })

  export const verifyEmailReset = () => ({
    type: VERIFY_EMAIL_INFORMATION_RESET    
  })



  export const getPrincipalDetail = (request) => ({
    type: GET_PRINCIPAL_DETAIL,
    payload: request
  })
  
  export const getPrincipalDetailSuccess = invoice => ({
    type: GET_PRINCIPAL_DETAIL_SUCCESS,
    payload: invoice,
  })
  
  export const getPrincipalDetailFail = error => ({
    type: GET_PRINCIPAL_DETAIL_FAIL,
    payload: error,
  })

  export const getPrincipalDetailByAccount = (request) => ({
    type: GET_PRINCIPAL_DETAIL_BY_ACCOUNT,
    payload: request
  })
  
  export const getPrincipalDetailByAccountSuccess = invoice => ({
    type: GET_PRINCIPAL_DETAIL_BY_ACCOUNT_SUCCESS,
    payload: invoice,
  })
  
  export const getPrincipalDetailByAccountFail = error => ({
    type: GET_PRINCIPAL_DETAIL_BY_ACCOUNT_FAIL,
    payload: error,
  })

  export const savePrincipalRoles = (request) => ({
    type: SAVE_PRINCIPAL_ROLES,
    payload: request
  })
  
  export const savePrincipalRolesSuccess = invoice => ({
    type: SAVE_PRINCIPAL_ROLES_SUCCESS,
    payload: invoice,
  })
  
  export const savePrincipalRolesFail = error => ({
    type: SAVE_PRINCIPAL_ROLES_FAIL,
    payload: error,
  })

  export const getPrincipalByAccount = (request) => ({
    type: GET_PRINCIPAL_BY_ACCOUNT,
    payload: request
  })
  
  export const getPrincipalByAccountSuccess = invoice => ({
    type: GET_PRINCIPAL_BY_ACCOUNT_SUCCESS,
    payload: invoice,
  })
  
  export const getPrincipalByAccountFail = error => ({
    type: GET_PRINCIPAL_BY_ACCOUNT_FAIL,
    payload: error,
  })
  
  export const getRolePrincipalList = (request) => ({
    type: GET_ROLE_PRINCIPAL_LIST,
    payload: request
  })

  export const getRolePrincipalListSuccess = response => ({
    type: GET_ROLE_PRINCIPAL_LIST_SUCCESS,
    payload: response
  })

  export const getRolePrincipalListFail = error => ({
    type: GET_ROLE_PRINCIPAL_LIST_FAIL,
    payload: error
  })

  export const deletePrincipal = (request) => ({
    type : DELETE_PRINCIPAL,
    payload: request
  })

  export const cancelPrincipal = (request) => ({
    type : CANCEL_PRINCIPAL,
    payload: request
  })  