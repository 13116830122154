import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { numberFormat } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import image from 'assets/images/vitae/Logos-vitae.png'

const ProductRelatedParentCard = ({ productDetail, getCurrencySymbol }) => {

    const { productMedias, currencyCode, name, price, description } = productDetail;
    const { t } = useTranslation();

    const getProductPicture = () => {
        let picture =
        productMedias && productMedias.length > 0 ? productMedias[0] : null;

        return picture ? picture.filePath : "";
    };

    return (
        <React.Fragment>
            <Row className='mt-1'>
                <Col sm={6}>
                    <Row className="align-middle">
                        <Col sm={4} >
                            <img
                                className='related-image'
                                src={getProductPicture()}
                                onError={(evt) => (evt.target.onerror = null, evt.target.src = image)}
                            />
                        </Col>
                        <Col sm={8} >
                            <p>{name}</p>
                            <strong className='font-size-16 text-end '>
                                {getCurrencySymbol(currencyCode)}{numberFormat(price)}
                            </strong>
                        </Col>
                    </Row>
                </Col>
                <Col sm={6}>
                    <Row className="align-middle">
                        <Col sm={11}>
                            <div><span>{description}</span></div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    );
}
ProductRelatedParentCard.propTypes = {
    productDetail: PropTypes.object.isRequired,
    getCurrencySymbol: PropTypes.func.isRequired
};

export default ProductRelatedParentCard;