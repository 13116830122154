import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { Row, Col, Button, Modal } from 'reactstrap';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormField from 'pages/Utility/form-field';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import DualListBox from 'react-dual-listbox';

const QuestionListForm = ({isOpen, onSubmit, onCancel, answer}) => {

    const { t } = useTranslation();

    Yup.setLocale({
        mixed: {
            required: t('This value is required')
        },
        number: {
            min: t('The value must be greater than or equal to zero')
        }
    });

    const [formData, setFormData] = useState({
        id: answer  && answer.id ? answer.id : null, 
        value: answer && answer.value ? answer.value : '',
        quoterValues: answer && answer.quoterValues ? answer.quoterValues : []
    }); 
    
    const { 
        value, quoterValues 
    } = formData;

    const [selected, setSelected] = useState([]);
    const [data, setData] = useState([]);
    
    const membershipList = useSelector(state => state.quoterMemberships.quoterMemberships);

    useEffect(() => {
        if(answer && answer.quoterValues) {
            let dataFormatted = [];
            answer.quoterValues.map(quoterValue => {
                const membership = quoterValue.quoterMembership;
                dataFormatted.push(membership.id);
            });
            setSelected(dataFormatted);
        }
    }, [answer]);

    useEffect(() => {
        if(membershipList) {
            let dataFormatted = [];
            membershipList.map(current => {
                dataFormatted.push({ value: current.id, label: current.name });
                
            });
            setData(dataFormatted);
        }
        
    }, [membershipList]);

    const handleOnChange = event => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }
    const onListChange = newSelection => {
        setSelected(newSelection);
    }

    

    return ( 
        <React.Fragment>
            <Modal
                isOpen={isOpen}
                toggle={() => {onCancel()}}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {answer? t('Edit Answer') : t('New Answer')}
                    </h5>
                    <button
                    type="button"
                    onClick={() => onCancel()}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                    >
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                
                    <Formik
                        enableReinitialize={true}
                        initialValues={formData}
                        validationSchema={
                            Yup.object().shape({
                                value: Yup.string().required(),
                                quoterValues: Yup.array()
                            })
                        }
                        onSubmit={(values, actions) => {
                            actions.setSubmitting(false);
                            let selectedMemberships = [];
                            
                            selected.map(current => {
                                const membership = membershipList.find(currentMembership => currentMembership.id == current);
                                selectedMemberships.push({
                                    id: null,
                                    quoterMembershipId: current,
                                    quoterMembership: {
                                        id: membership.id,
                                        name: membership.name
                                    }
                                });
                                
                            });

                            let copy = {...formData};
                            copy.quoterValues = selectedMemberships;
                            
                            onSubmit(copy);
                        
                        }}
                    >
                        {({ errors, status, touched }) => (
                            <Form className='needs-validation' >
                                <div className="modal-body">
                                    <Row>
                                        <Col md={12}>
                                            <FormField
                                                key='value'
                                                type='text'
                                                maxLength={255}
                                                fieldName='value'
                                                fieldValue={value}
                                                label={t('Answer')}
                                                errors={errors}
                                                touched={touched}
                                                handleOnChange={handleOnChange}
                                            />
                                            
                                        </Col>
                                    </Row>

                                    <Row>
                                        <Col md={12}>

                                            <FormField
                                                key='quoterValues'
                                                type='hidden'
                                                fieldName='quoterValues'
                                                fieldValue={quoterValues}
                                                label={t('Memberships')}
                                                errors={errors}
                                                touched={touched}
                                                handleOnChange={handleOnChange}
                                            />

                                            <DualListBox
                                                className='mb-1'
                                                showHeaderLabels={true}
                                                lang={{
                                                    availableHeader: t('Available'),
                                                    selectedHeader: t('Selected')
                                                }}
                                                options={data}
                                                selected={selected}
                                                onChange={onListChange}
                                            />
                                            
                                            
                                        </Col>
                                    </Row>
                                </div>
                                <div className="modal-footer button-items mt-1">
                                    <Button type='submit' color="primary" className="button"><i className="mdi mdi-content-save me-1"></i>{t('Save')}</Button>
                                    <Button color="light" className="button mr-1" onClick={() => { onCancel(); }}><i className="mdi mdi-close-thick me-1"></i>{t('Cancel')}</Button>
                                </div>
                            </Form>
                        )}
                    </Formik>
               
            </Modal>
           
        </React.Fragment>
     );
}

QuestionListForm.propTypes = {
    isOpen: PropTypes.bool,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    answer: PropTypes.any
}
 
export default QuestionListForm;