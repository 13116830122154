import {
  CLEAR_PRODUCT_SEARCH_LIST,
  GET_PRODUCT_SEARCH_DETAIL,
  GET_PRODUCT_SEARCH_DETAIL_FAIL,
  GET_PRODUCT_SEARCH_DETAIL_SUCCESS,
  GET_PRODUCT_SEARCH_LIST,
  GET_PRODUCT_SEARCH_LIST_BY_CATEGORY,
  GET_PRODUCT_SEARCH_LIST_BY_CATEGORY_FAIL,
  GET_PRODUCT_SEARCH_LIST_BY_CATEGORY_SUCCESS,
  GET_PRODUCT_SEARCH_LIST_FAIL,
  GET_PRODUCT_SEARCH_LIST_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  productResults: [],
  productDetail: null,
  error: {},
  loading: false,
  hasMore: true,
};

const productSearches = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_PRODUCT_SEARCH_LIST:
      return {
        ...state,
        productResults: [],
        productDetail: null,
        error: {},
        loading: false,
        hasMore: true,
      };
    case GET_PRODUCT_SEARCH_LIST:
      return {
        ...state,
        loading: true,
        productResults: action.payload.pageNumber > 0? state.productResults: [],
        hasMore: action.payload.pageNumber > 0? state.hasMore: true,
      };
    case GET_PRODUCT_SEARCH_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        productResults: state.productResults.concat(action.payload),
        hasMore: action.payload.length > 0,
      };
    case GET_PRODUCT_SEARCH_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_PRODUCT_SEARCH_LIST_BY_CATEGORY:
      return {
        ...state,
        loading: true,
        productResults: action.payload.pageNumber > 0? state.productResults: [],
        hasMore: action.payload.pageNumber > 0? state.hasMore: true,
      };
    case GET_PRODUCT_SEARCH_LIST_BY_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        productResults: state.productResults.concat(action.payload),
        hasMore: action.payload.length > 0,
      };
    case GET_PRODUCT_SEARCH_LIST_BY_CATEGORY_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_PRODUCT_SEARCH_DETAIL:
      return {
        ...state,
        loading: true,
        productDetail: null,
      };
    case GET_PRODUCT_SEARCH_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        productDetail: action.payload,
      };
    case GET_PRODUCT_SEARCH_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default productSearches;
