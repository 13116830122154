import {
  GET_MEDICAL_CATEGORY_LIST,
  GET_MEDICAL_CATEGORY_LIST_FAIL,
  GET_MEDICAL_CATEGORY_LIST_SUCCESS,
  GET_MEDICAL_SERVICE_DETAIL,
  GET_MEDICAL_SERVICE_DETAIL_FAIL,
  GET_MEDICAL_SERVICE_DETAIL_SUCCESS,
  GET_MEDICAL_SERVICE_LIST,
  GET_MEDICAL_SERVICE_LIST_FAIL,
  GET_MEDICAL_SERVICE_LIST_SUCCESS,
  GET_ALL_MEDICAL_SERVICE_LIST,
  GET_ALL_MEDICAL_SERVICE_LIST_FAIL,
  GET_ALL_MEDICAL_SERVICE_LIST_SUCCESS,
  GET_ALL_MEDICAL_SERVICE_BY_TYPE_LIST,
  GET_ALL_MEDICAL_SERVICE_BY_TYPE_LIST_SUCCESS,
  GET_ALL_MEDICAL_SERVICE_BY_TYPE_LIST_FAIL
} from "./actionTypes"

const INIT_STATE = {
  medicalServiceList: [],
  medicalServiceDetail: null,
  medicalCategoryList: [],
  error: {},
  loading: false
}

const medicalServices = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MEDICAL_CATEGORY_LIST:
      return {
        ...state,
        medicalCategoryList: [],
        medicalServiceList: [],
        medicalServiceDetail: null,
        loading: true,
      }
    case GET_MEDICAL_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        medicalCategoryList: action.payload,
      }
    case GET_MEDICAL_CATEGORY_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_MEDICAL_SERVICE_LIST:
      return {
        ...state,
        medicalServiceList: [],
        loading: true,
      }
    case GET_MEDICAL_SERVICE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        medicalServiceList: action.payload,
      }
    case GET_MEDICAL_SERVICE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case GET_ALL_MEDICAL_SERVICE_LIST:
      return {
        ...state,
        medicalServiceList: [],
        loading: true,
      }
    case GET_ALL_MEDICAL_SERVICE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        medicalServiceList: action.payload,
      }
    case GET_ALL_MEDICAL_SERVICE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case GET_ALL_MEDICAL_SERVICE_BY_TYPE_LIST:
      return {
        ...state,
        medicalServiceList: [],
        loading: true,
      }
    case GET_ALL_MEDICAL_SERVICE_BY_TYPE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        medicalServiceList: action.payload,
      }
    case GET_ALL_MEDICAL_SERVICE_BY_TYPE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case GET_MEDICAL_SERVICE_DETAIL:
      return {
        ...state,
        loading: true,
        medicalServiceDetail: null,
      }
    case GET_MEDICAL_SERVICE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        medicalServiceDetail: action.payload,
      }
    case GET_MEDICAL_SERVICE_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    default:
      return state
  }
}

export default medicalServices;
