import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getValueFromDTO, toDateISOStringFormat } from "helpers/utils";
import FormFieldBasic from "pages/Utility/form-field-basic";
import PrescriptionFiles from "components/Common/Prescription/prescription-files";
import LaboratoryPrescriptionServices from "components/Common/Prescription/laboratory-prescription-services";
import LaboratoryPrescriptionSearchTemplateForm from "components/Common/Prescription/laboratory-prescription-search-template-form";
import { DEFAULT_COMPANY } from "constants/list-types";
import { useSelector } from "react-redux";

const LaboratoryPrescriptionForm = ({ validateMedicalInformation, onSubmit, onBack, template, onDownloadPdf, setShowUploadPdf }) => {
  const { t } = useTranslation();
  const { accountCode } = useParams();
  
  const [services, setServices] = useState(
    template ? template.prescriptionServices : []
  );
  const [attachments, setAttachments] = useState(
    template && template.prescriptionFiles ? template.prescriptionFiles : []
  );

  const [showTemplateModal, setShowTemplateModal] = useState(false);

  const prescriptionCreated = useSelector(state => state.servicePrescriptions.created);
  const prescriptionUploaded = useSelector(state => state.servicePrescriptions.uploaded);

  const [formData, setFormData] = useState({
    description: getValueFromDTO(template, "doctorPrescription"),
    servicesLength: 0,
  });

  const handleOnChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };
 /*
  useEffect(() => {
   
    if (acceptedFiles && acceptedFiles.length > 0) {
      let copy = [...attachments];
      setAttachments(copy.concat(acceptedFiles));
    }
  }, [acceptedFiles]);*/

    useEffect(() => {
      setFormData({ ...formData, servicesLength: services.length });
    }, [services]);

    const prepareToSave = (isTemplate) => {
      //console.info('save')
      let data = {
        ...formData,
        accountCode: accountCode,
        //medicalCenterId: 101,
        companyId: DEFAULT_COMPANY,
        countryCode: "CR",
        helpIndicator: "N",
        doctorPrescription: formData.description,
        doctorFullname: "",
        doctorMedicalCode: "",
        doctorDiagnosticDate: toDateISOStringFormat(new Date()),
        prescriptionStatus: null
      };

      if (isTemplate) {
        data.prescriptionStatus = "TEM";
      } else {
        data.patientObservation = '';
      }

      if (attachments.length > 0) {
        data.prescriptionFiles = attachments;
        /*
        for (let index = 0; index < attachments.length; index++) {
          const file = attachments[index];
          const arrayBuffer = await file.arrayBuffer;
          data.prescriptionFiles.push({
            id: file.id,
            prescriptionId: file.prescriptionId,
            type: file.name.substring(file.name.lastIndexOf(".")),
            fileName: file.name.substring(0, file.name.lastIndexOf(".") - 1),
            arrayBuffer: arrayBuffer,
          });
        }*/
      }

      data.prescriptionServices = services.map(service => ({
        id: service.id,
        medicalServiceId: service.medicalService.id,
        doctorPrescription: service.doctorPrescription,
        currencyCode: service.medicalService.currencyCode,
        price: service.medicalService.price,
        quantity: service.quantity,
        taxPercentage: service.medicalService.taxPercentage,
        medicalService: {
          id: service.medicalService.id,
          name: service.medicalService.name,
          price: service.medicalService.price,
          prescription: service.medicalService.prescription,
          taxPercentage: service.medicalService.taxPercentage,
        },
      }));

      if (template) {
        data.id = template.id;
      }

      onSubmit(data);
    };

    const selectTemplate = template => {
      setShowTemplateModal(false);
      setServices(template.prescriptionServices);
      setAttachments(template.prescriptionFiles);
      setFormData({ ...formData, description: template.doctorPrescription });
    }

    const formik = useFormik({
      enableReinitialize: true,
      initialValues: formData,
      validationSchema:
          Yup.object().shape({
            description: Yup.string().required().max(200),
            servicesLength: Yup.number().required().min(1, t('You have to add at least one service')),
          }),
      onSubmit: (values, actions) => {
        actions.setSubmitting(false);
        prepareToSave();
      },
      onReset: (values, helper) => {

      }
  });

  const { touched, errors, values, submitForm, handleBlur, handleSubmit, handleReset } = formik;

    return (
      <React.Fragment>

        <Col xl={{ size: 10, offset: 1 }}>
          <Card>
            <CardBody>

              <Row className="mb-3">
                <Col sm={12}>
                  <div className="button-items">
                    <Button
                      type="button"
                      color="primary"
                      onClick={() => {
                        setShowTemplateModal(true);
                      }}
                    >
                      <i className="mdi mdi-file-download" /> {t("Load Template")}
                    </Button>
                  </div>
                </Col>
              </Row>
                  <form className="needs-validation" onSubmit={handleSubmit} onReset={handleReset}>
                    <Row>
                      <Col md={6}>
                        <FormFieldBasic
                          key="description"
                          type="textarea"
                          maxLength={200}
                          fieldName="description"
                          fieldValue={values.description}
                          label={t("Description")}
                          errors={errors}
                          touched={touched}
                          handleOnChange={handleOnChange}
                          handleBlur={handleBlur}
                        />
                      </Col>
                      <Col md={6}>
                        {/* attachments */}
                        <PrescriptionFiles
                          attachments={attachments}
                          setAttachments={setAttachments}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={12}>
                        <h4>{t("Service to include in the prescription")}</h4>
                        {/*Prescrption Services*/}
                        <LaboratoryPrescriptionServices
                          services={services}
                          setServices={setServices}
                        />
                        <FormFieldBasic
                          key="servicesLength"
                          type="hidden"
                          maxLength={200}
                          fieldName="servicesLength"
                          fieldValue={values.servicesLength}
                          label={t("")}
                          errors={errors}
                          touched={touched}
                          handleOnChange={handleOnChange}
                          handleBlur={handleBlur}
                        />
                      </Col>
                    </Row>


                    <Row>
                      <Col sm={12}>
                        <div className="button-items">

                          {prescriptionCreated ?
                            <Button 
                              color="primary" 
                              type="button" 
                              onClick={() => { onDownloadPdf(); }}>
                                {t('Download Prescription')}
                            </Button>
                          : null}

                          {prescriptionCreated && !prescriptionUploaded ?
                            <Button
                              color="primary"
                              type="button"
                              onClick={() => { setShowUploadPdf(true); }}
                            >
                              {t('Upload Signed Prescription')}
                            </Button>
                            : null
                          }
                          <Button type="button" color="primary" className="button" onClick={() => { validateMedicalInformation(); submitForm(); }}>
                            <i className="mdi mdi-content-save me-1"></i>
                            {t("send")}
                          </Button>
                          <Button
                            type="button"
                            color="primary"
                            className="button"
                            onClick={() => {
                              prepareToSave(true);
                            }}
                          >
                            <i className="mdi mdi-content-save me-1"></i>
                            {t("Save as Template")}
                          </Button>
                          <Button
                            color="light"
                            className="button mr-1"
                            onClick={() => {
                              onBack();
                            }}
                          >
                            <i className="mdi mdi-arrow-left me-1"></i>
                            {t("Back")}
                          </Button>
                        </div>
                      </Col>
                    </Row>

                  </form>
                
            </CardBody>
          </Card>
        </Col>
        {showTemplateModal ? (
          <LaboratoryPrescriptionSearchTemplateForm
            isOpen={showTemplateModal}
            onSelect={selectTemplate}
            onCancel={() => {
              setShowTemplateModal(false);
            }}
          />
        ) : null}
      </React.Fragment>
    );
  };

  LaboratoryPrescriptionForm.propTypes = {
    validateMedicalInformation: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    template: PropTypes.any,
    onBack: PropTypes.func.isRequired,
    onDownloadPdf: PropTypes.func.isRequired,
    setShowUploadPdf: PropTypes.func.isRequired
  };

  export default LaboratoryPrescriptionForm;
