import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { toDateStringFormat } from "helpers/utils";
import EmptyResult from "components/empty-result/empty-result";
import DataTable from "components/Common/DataTable/datatable";

const LaboratoryPrescriptionHistoricalResult = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { accountCode } = useParams();
  const prescriptions = useSelector(
    state => state.servicePrescriptions.prescriptions
  );

  const [result, setResult] = useState([]);

  const [pageOptions, setPageOptions] = useState({
    sizePerPage: 10,
    totalSize: 0,
    custom: true,
  });

  useEffect(() => {
    if (prescriptions) {

      setPageOptions({
        ...pageOptions,
        totalSize: prescriptions.length,
      });

      setResult(prescriptions.map(current => ({
        id: current.id,
        doctorDiagnosticDate: current.doctorDiagnosticDate,
        fullName: [current.patient.firstName, current.patient.secondName, current.patient.firstSurname, current.patient.secondSurname].join(' '),
        medicalCenterName: current.medicalCenter ? current.medicalCenter.name: ''
      })));
    }
  }, [prescriptions]);

  const columns = [
    {
      text: t("Date"),
      dataField: "doctorDiagnosticDate",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <React.Fragment>
            {toDateStringFormat(row.doctorDiagnosticDate)}
          </React.Fragment>
        );
      },
    },
    {
      text: t("Patient"),
      dataField: "fullName",
      sort: true,
      formatter: (cellContent, row) => {
        return (
          <React.Fragment>
            {row.fullName}
          </React.Fragment>
        );
      },
    },
    {
      text: t("Medical Center"),
      dataField: "medicalCenterName",
      sort: true,
      formatter: (cellContent, row) => {
        return <React.Fragment>{row.medicalCenterName}</React.Fragment>;
      },
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "",
      formatter: (cellContent, row) => (
        <div className="d-flex gap-3">
          <Button type="button" color="success" onClick={() => editRow(row)}>
            {t("View Detail")}
            <i className="mdi mdi-arrow-right " id="edittooltip"></i>
          </Button>
        </div>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const editRow = row => {
    history.push(`/laboratory-prescription-detail/${accountCode}/${row.id}`);
  };

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          {prescriptions && prescriptions.length > 0 ? (
            <DataTable
              data={result}
              columns={columns}
              keyField={'id'}
              sort={defaultSorted}
            />

          ) : (
            <Card>
              <CardBody>
                <EmptyResult />
              </CardBody>
            </Card>

          )}
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default LaboratoryPrescriptionHistoricalResult;
