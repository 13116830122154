import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, CardImg, Button } from "reactstrap";
import { numberFormat } from "helpers/utils";
import { useTranslation } from "react-i18next";

const PrescriptionServiceCard = ({ service, getCurrencySymbol, onSelect }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Col sm={3} md={4} xl={3}>
        <Card className="plan-box">
          <CardBody className="p-2">
            
            <div className="d-flex pt-2">
              <div className="flex-grow-1">
                <p className="text-muted">
                  {service.medicalService.medicalCategory? 
                    <span key={`medical-category-${service.medicalService.medicalCategory.id}`}>
                      {service.medicalService.medicalCategory.name}
                    </span>
                  : null}
                </p>
                <h5>{service.medicalService.description}</h5>
              </div>
            </div>
            <div className="py-1">
              <h5>
                <strong>{t("Pharmacy indications")}</strong>
              </h5>
              <h5>{service.doctorPrescription}</h5>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

PrescriptionServiceCard.propTypes = {
  service: PropTypes.object.isRequired,
  getCurrencySymbol: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default PrescriptionServiceCard;
