import {
  GET_PRODUCT_CATEGORY_LIST,
  GET_PRODUCT_CATEGORY_LIST_FAIL,
  GET_PRODUCT_CATEGORY_LIST_SUCCESS
} from "./actionTypes"

//Medical Center List
export const getProductCategoryList = () => ({
  type: GET_PRODUCT_CATEGORY_LIST
})

export const getProductCategoryListSuccess = response => ({
  type: GET_PRODUCT_CATEGORY_LIST_SUCCESS,
  payload: response,
})

export const getProductCategoryListFail = error => ({
  type: GET_PRODUCT_CATEGORY_LIST_FAIL,
  payload: error,
})
