import { CLEAR_CHANGE_PASSWORD_STATE, 
         CHANGE_PASSWORD_REQUEST_CODE, 
         CHANGE_PASSWORD_REQUEST_CODE_FAIL, 
         CHANGE_PASSWORD_REQUEST_CODE_SUCCESS, 
         CHANGE_PASSWORD_VALIDATE_CODE, 
         CHANGE_PASSWORD_VALIDATE_CODE_FAIL, 
         CHANGE_PASSWORD_VALIDATE_CODE_SUCCESS, 
         CHANGE_PASSWORD_UPDATE,
         CHANGE_PASSWORD_UPDATE_FAIL,
         CHANGE_PASSWORD_UPDATE_SUCCESS} from "./actionTypes"

const INIT_STATE = {
    verificationCodeRequested: false,
    token: null,
    passwordChanged: false,
    success: false,
    loading: false,
    error: {}
  }
  
  const changePasswords = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CLEAR_CHANGE_PASSWORD_STATE:
        return {
            ...state,
            verificationCodeRequested: false,
            token: null,
            passwordChanged: false,
            success: false,
            loading: false,
            error: {}
        }
        
        case CHANGE_PASSWORD_REQUEST_CODE:
        return {
            ...state,
            verificationCodeRequested: false,
            loading: true,
        } 
        case CHANGE_PASSWORD_REQUEST_CODE_SUCCESS:
        return {
            ...state,
            loading: false,
            verificationCodeRequested: true,
        }  
        case CHANGE_PASSWORD_REQUEST_CODE_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case CHANGE_PASSWORD_VALIDATE_CODE:
        return {
            ...state,
            token: null,
            loading: true,
        } 
        case CHANGE_PASSWORD_VALIDATE_CODE_SUCCESS:
        return {
            ...state,
            loading: false,
            token: action.payload,
        }  
        case CHANGE_PASSWORD_VALIDATE_CODE_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case CHANGE_PASSWORD_UPDATE:
        return {
            ...state,
            passwordChanged: false,
            loading: true,
        } 
        case CHANGE_PASSWORD_UPDATE_SUCCESS:
        return {
            ...state,
            loading: false,
            passwordChanged: true,
        }  
        case CHANGE_PASSWORD_UPDATE_FAIL:
        return {
            ...state,
            error: action.payload,
        }
        default:
            return state
        }
    }
    
export default changePasswords
      