import DataTable from 'components/Common/DataTable/datatable';
import React, { useContext, useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { getCatalogs, getIdentificationTypes, showWarningAlert } from 'store/actions';
import upgradeMembershipContext from '../context/upgradeMembershipContext';
import MembershipBeneficiaryForm from './membership-beneficiary-form';

const BeneficiaryList = () => {

    const membershipContext = useContext(upgradeMembershipContext);
    const { membership, beneficiaries, addBeneficiary, editBeneficiary, deleteBeneficiary, showForm, setShowForm, beneficiary, setBeneficiary } = membershipContext;

    const dispatch = useDispatch();
    const { t } = useTranslation();
    const node = React.createRef();
    const { SearchBar } = Search;
    const [showDelete, setShowDelete] = useState(false);
    const consanguinityLevelList = useSelector(state => state.catalogs.consanguinityLevelList);

    useEffect(() => {
        dispatch(getCatalogs());
        dispatch(getIdentificationTypes());
    }, []);    

    const [pageOptions, setPageOptions] = useState({
        sizePerPage: 10,
        totalSize: 0,
        custom: true
    });

    const columns = [
        {
            text: t('Identification'),
            dataField: 'identification',
            sort: true,
            formatter: (cellContent, row) => <React.Fragment>{row.identification}</React.Fragment>
        },
        {
            text: t('Name'),
            dataField: 'fullName',
            sort: true,
            formatter: (cellContent, row) => <React.Fragment>{row.fullName}</React.Fragment>
        },
        {
            text: t('Mobile'),
            dataField: 'mainPhone',
            sort: true,
            formatter: (cellContent, row) => {
                return (<React.Fragment>{row.mainPhone}</React.Fragment>);
            }
        },
        {
            text: t('Email'),
            dataField: 'email',
            sort: true,
            formatter: (cellContent, row) => {
                return (<React.Fragment>{row.email}</React.Fragment>);
            }
        },
        {
            text: t('Relation'),
            dataField: 'consanguinityLevelDescription',
            sort: true,
            formatter: (cellContent, row) => {
                return (<React.Fragment>{row.consanguinityLevelDescription}</React.Fragment>);
            }
        },
        {
          dataField: 'menu',
          isDummyField: true,
          editable: false,
          text: '',
          formatter: (cellContent, row) => (
            <div className="d-flex gap-3">
              <Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => editRow(row)}
                ></i>
              </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => deleteRow(row)}
                ></i>
              </Link>
            </div>
          ),
        }
    ];

    const defaultSorted = [
        {
            dataField: "beneficiaryId", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        }
    ];

    const addRow = () => {
        if(membership && beneficiaries.length < membership.maxBeneficiaryQuantity) {
            setBeneficiary(null);
            setShowForm(true);
        } else if(membership && membership.maxBeneficiaryQuantity == 0) {
            dispatch(showWarningAlert(t('membershipZeroBeneficiaries')));
        } else {
            dispatch(showWarningAlert(t('membershipMaxBeneficiaries')));
        }
    };

    const editRow = row => {
        setBeneficiary(row);
        setShowForm(true);
    };

    const deleteRow = row => {
        setBeneficiary(row);
        setShowDelete(true);
    };

    const onDelete = () => {
        setShowDelete(false);
        deleteBeneficiary(beneficiary);
    }

    return ( 
        <React.Fragment>
            {showDelete?
                
                <SweetAlert
                    title={t('confirm.delete')}
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText={t('Yes')}
                    cancelBtnText={t('No')}
                    onConfirm={() => {
                        onDelete();
                    }}
                    onCancel={() => {
                        setShowDelete(false);
                    }}
                >

                </SweetAlert>
            : null}

            <div className="">
                {/*<Container fluid>*/}
                    {/* Render Breadcrumbs */}
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h2 className="mb-2 font-size-18">{t('Beneficiaries List')}</h2>
                        <div className="button-items">
                            <Button color="primary" className="button" onClick={() => { addRow() }}><i className="mdi mdi-plus-circle-outline me-1"></i>{t('New Beneficiary')}</Button>
                        </div>
                    </div>
                    
                    <Row>
                        <Col lg={12}>

                            <DataTable
                                columns={columns}
                                data={beneficiaries}
                                keyField='beneficiaryId'
                                sort={defaultSorted}
                            />
                        </Col>
                    </Row>
                {/*</Container>*/}
            </div>
            {showForm ?
            <MembershipBeneficiaryForm 
                isOpen={showForm} 
                beneficiary={beneficiary}
                onSubmit={(beneficiaryData) => {
                    
                    const copy = {...beneficiaryData};
                    
                    copy.mainPhone = beneficiaryData.mainPhone.replace(/\-/gi, '');
                    
                    if(beneficiaryData.secondaryPhone) {
                        copy.secondaryPhone = beneficiaryData.secondaryPhone.replace(/\-/gi, '');
                    }

                    copy.fullName = [copy.firstName, copy.secondName, copy.firstSurname, copy.secondSurname].join(' ');
                    
                    const level = consanguinityLevelList.find(current => current.code == copy.consanguinityLevel);
                    copy.consanguinityLevelDescription = level ? level.description: '';

                    if(beneficiary == null) {
                        addBeneficiary(copy);
                    } else {
                        copy.beneficiaryId = beneficiary.beneficiaryId;
                        editBeneficiary(copy);
                    }
                }} 
                onCancel={() => {
                    setBeneficiary(null);
                    setShowForm(false);
                }}
            /> : null}
        </React.Fragment>
    );
}
 
export default BeneficiaryList;