import { benefitTypes, businessUnitTypes, currencyTypes, discountTypes } from 'constants/list-types';
import { Form, Formik } from 'formik';
import FormField from 'pages/Utility/form-field';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { getBenefitCategories } from 'store/actions';
import * as Yup from 'yup';

const BenefitForm = ({ onSubmit, onBack, benefit }) => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const benefitCategories = useSelector(state => state.benefitCategories.categoryList);

    useEffect(() => {
        dispatch(getBenefitCategories());
    }, []);

    const [formData, setFormData] = useState({
        membershipId: benefit && benefit.membershipId ? benefit.membershipId : '',
        membershipBenefitCategoryId: benefit && benefit.membershipBenefitCategory.id ? benefit.membershipBenefitCategory.id : '',
        itemCode: benefit && benefit.itemCode ? benefit.itemCode : '',
        description: benefit && benefit.description ? benefit.description : '',
        detail: benefit && benefit.detail ? benefit.detail : '',
        type: benefit && benefit.type ? benefit.type : '',
        restrictionLimit: benefit && benefit.restrictionLimit ? benefit.restrictionLimit : '',
        value: benefit && benefit.value ? benefit.value : '',
        currencyCode: benefit && benefit.currencyCode ? benefit.currencyCode : '',
        discount: benefit && benefit.discount ? benefit.discount : '',
        discountType: benefit && benefit.discountType ? benefit.discountType : '',
        businessUnitType: benefit && benefit.businessUnitType ? benefit.businessUnitType : '',
        billingReference: benefit && benefit.billingReference ? benefit.billingReference : '',
        billingChannel: benefit && benefit.billingChannel ? benefit.billingChannel : '',
        billingTaxCode: benefit && benefit.billingTaxCode ? benefit.billingTaxCode : '',
        billingTaxRate: benefit && benefit.billingTaxRate ? benefit.billingTaxRate : '',
    });

    const {
        membershipId, membershipBenefitCategoryId,
        itemCode, description, detail, type, restrictionLimit,
        value, currencyCode, discount, discountType, businessUnitType,
        billingChannel, billingReference, billingTaxCode, billingTaxRate } = formData;

    const handleOnChange = event => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }

    return (
        <React.Fragment>
            <Row>
                <Col xl={{ size: 10, offset: 1 }}>
                    <Card>
                        <CardBody>
                            {/* <CardTitle className="h5">Floating labels</CardTitle> */}
                            {/* <p className="card-title-desc">Create beautifully simple form labels that float over your input fields.</p> */}

                            <Formik
                                enableReinitialize={true}
                                initialValues={formData}
                                validationSchema={
                                    Yup.object().shape({
                                        membershipBenefitCategoryId: Yup.string().required(),
                                        itemCode: Yup.string().required(),
                                        description: Yup.string().required(),
                                        detail: Yup.string().required(),
                                        type: Yup.string().required(),
                                        restrictionLimit: Yup.number().min(0).required(),
                                        value: Yup.string().required(),
                                        currencyCode: Yup.string().required(),
                                        discount: type == 'DIS' ? Yup.number().min(0) : Yup.number(),
                                        discountType: type == 'DIS' ? Yup.string(): Yup.string(),
                                        businessUnitType: Yup.string()
                                    })
                                }
                                onSubmit={(values, actions) => {
                                    actions.setSubmitting(false);
                                    let formValues = {...formData};
                                    if(type == 'DIS') {
                                        formValues.discount = 0;
                                        formValues.discountType = null;
                                    }
                                    onSubmit(formData);
                                }}
                            >
                                {({ errors, status, touched }) => (
                                    <Form className='needs-validation' >
                                        <Row>
                                            <Col md={6}>

                                                <FormField
                                                    key='itemCode'
                                                    type='text'
                                                    maxLength={45}
                                                    fieldName='itemCode'
                                                    fieldValue={itemCode}
                                                    label={t('Code')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />

                                            </Col>
                                            <Col md={6}>

                                                <FormField
                                                    key='description'
                                                    type='text'
                                                    maxLength={500}
                                                    fieldName='description'
                                                    fieldValue={description}
                                                    label={t('Description Benefit')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>

                                                <FormField
                                                    key='detail'
                                                    type='text'
                                                    maxLength={255}
                                                    fieldName='detail'
                                                    fieldValue={detail}
                                                    label={t('Detail')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />

                                            </Col>
                                            <Col md={6}>

                                                <FormField
                                                    key='type'
                                                    type='select'
                                                    fieldName='type'
                                                    fieldValue={type}
                                                    label={t('Type')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                >
                                                    <option key="typeNotSelected" value="">{t('Not Selected')}</option>
                                                    {benefitTypes.map(current => {
                                                        return (<option key={current.code} value={current.code}>{t(current.value)}</option>);
                                                    })}
                                                </FormField>
                                            </Col>
                                        </Row>

                                        <Row>

                                            <Col md={6}>

                                                <FormField
                                                    key='currencyCode'
                                                    type='select'
                                                    fieldName='currencyCode'
                                                    fieldValue={currencyCode}
                                                    label={t('Currency')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                >
                                                    <option key="currencyTypeNotSelected" value="">{t('Not Selected')}</option>
                                                    {currencyTypes.map(current => {
                                                        return (<option key={current.code} value={current.code}>{t(current.value)}</option>);
                                                    })}
                                                </FormField>

                                            </Col>
                                            <Col md={6}>

                                                <FormField
                                                    key='discount'
                                                    type='number'
                                                    disabled={type != 'DIS'}
                                                    maxLength={20}
                                                    fieldName='discount'
                                                    fieldValue={discount}
                                                    label={t('Discount Amount')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />

                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col md={6}>

                                                <FormField
                                                    key='discountType'
                                                    type='select'
                                                    disabled={type != 'DIS'}
                                                    fieldName='discountType'
                                                    fieldValue={discountType}
                                                    label={t('Discount Type')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                >
                                                    <option key="discountTypeNotSelected" value="">{t('Not Selected')}</option>
                                                    {discountTypes.map(current => {
                                                        return (<option key={current.code} value={current.code}>{t(current.value)}</option>);
                                                    })}
                                                </FormField>

                                            </Col>
                                            <Col md={6}>

                                                <FormField
                                                    key='membershipBenefitCategoryId'
                                                    type='select'
                                                    fieldName='membershipBenefitCategoryId'
                                                    fieldValue={membershipBenefitCategoryId}
                                                    label={t('Category')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                >
                                                    <option key="benefitCategoryNotSelected" value="">{t('Not Selected')}</option>
                                                    {benefitCategories.map(current => {
                                                        return (<option key={current.id} value={current.id}>{t(current.description)}</option>);
                                                    })}
                                                </FormField>

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>

                                                <FormField
                                                    key='restrictionLimit'
                                                    type='number'
                                                    maxLength={20}
                                                    fieldName='restrictionLimit'
                                                    fieldValue={restrictionLimit}
                                                    label={t('Restriction Limit')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />

                                            </Col>
                                            <Col md={6}>

                                                <FormField
                                                    key='value'
                                                    type='number'
                                                    maxLength={5}
                                                    fieldName='value'
                                                    fieldValue={value}
                                                    label={t('Value Benefit')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>

                                                <FormField
                                                    key='businessUnitType'
                                                    type='select'
                                                    fieldName='businessUnitType'
                                                    fieldValue={businessUnitType}
                                                    label={t('Business Unit Type')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                >
                                                    <option key="businessUnitTypeNotSelected" value="">{t('Not Selected')}</option>
                                                    {businessUnitTypes.map(current => {
                                                        return (<option key={current.code} value={current.code}>{t(current.value)}</option>);
                                                    })}
                                                </FormField>

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12}>
                                                <h3>{t('Billing')}</h3>
                                                <hr />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>

                                                <FormField
                                                    key='billingReference'
                                                    type='text'
                                                    maxLength={255}
                                                    fieldName='billingReference'
                                                    fieldValue={billingReference}
                                                    label={t('Billing Reference')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />
                                            </Col>
                                            <Col md={6}>

                                                <FormField
                                                    key='billingChannel'
                                                    type='text'
                                                    maxLength={45}
                                                    fieldName='billingChannel'
                                                    fieldValue={billingChannel}
                                                    label={t('Billing Channel')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>

                                                <FormField
                                                    key='billingTaxCode'
                                                    type='text'
                                                    maxLength={45}
                                                    fieldName='billingTaxCode'
                                                    fieldValue={billingTaxCode}
                                                    label={t('Billing Tax Code')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />
                                            </Col>

                                            <Col md={6}>

                                                <FormField
                                                    key='billingTaxRate'
                                                    type='text'
                                                    maxLength={20}
                                                    fieldName='billingTaxRate'
                                                    fieldValue={billingTaxRate}
                                                    label={t('Billing Tax Rate')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />
                                            </Col>
                                        </Row>

                                        <div className="button-items">
                                            <Button type='submit' color="primary" className="button"><i className="mdi mdi-content-save me-1"></i>{t('Save')}</Button>
                                            <Button color="light" className="button mr-1" onClick={() => { onBack() }}><i className="mdi mdi-close-thick me-1"></i>{t('Back')}</Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
}

BenefitForm.propTypes = {
    onSubmit: PropTypes.func,
    benefit: PropTypes.any,
    onBack: PropTypes.func
}

export default BenefitForm;