import { ErrorMessage, Field } from 'formik';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Label } from 'reactstrap';

const FormField = (props) => {

    const [passType, setPassType] = useState('password');

    const {type, fieldName, fieldValue, label, errors, touched, handleOnChange, disabled = false, maxLength = 500, divClassName = 'mb-3', children } = props;
    
    const togglePass = () => {
        if(passType === 'password') {
            setPassType('text');
        } else {
            setPassType('password');
        }
    }
    
    return (
        <div className={divClassName}>
                                                        
            {type != 'checkbox' && type != 'hidden' ? <Label className="form-label">{label}</Label> : null}
            {type == 'checkbox' ? <Label className="form-label"></Label> : null}
            {type == 'select' ?
                <Field 
                    as="select"
                    className={"form-select" + (errors[fieldName] && touched[fieldName] ? " is-invalid": "")} 
                    name={fieldName}
                    value={fieldValue}
                    onChange={handleOnChange}
                    disabled={disabled}
                    >

                    {children}

                </Field> 
                :
                (
                type == 'checkbox' ?
                <div className="form-check form-check-primary mt-1">
                    <Field 
                        type={type} 
                        className={(type == 'checkbox' ? "form-check-input" :"form-control") + (errors[fieldName] && touched[fieldName] ? " is-invalid": "")} 
                        maxLength={maxLength}
                        name={fieldName}
                        checked={fieldValue}
                        onChange={handleOnChange}
                        disabled={disabled}
                    />
                    <Label className="form-check-label">{label}</Label>
                </div>
                :
                (
                type == 'password' ?
                <div className="input-group auth-pass-inputgroup">
                    <Field 
                        type={passType} 
                        className={(type == 'checkbox' ? "form-check-input" :"form-control") + (errors[fieldName] && touched[fieldName] ? " is-invalid": "")} 
                        maxLength={maxLength}
                        name={fieldName}
                        value={fieldValue}
                        onChange={handleOnChange}
                        disabled={disabled}
                    />
                    <button
                        className="btn btn-light "
                        type="button"
                        id="password-addon"
                        onClick={()=> {togglePass();}}
                    >
                        <i className={`mdi ${passType === 'password' ? 'mdi-eye-outline': 'mdi-eye-off-outline'}`}></i>
                    </button>
                </div>
                :
                (
                type == 'textarea' ?
                <Field 
                    as="textarea"
                    type={type} 
                    className={"form-control" + (errors[fieldName] && touched[fieldName] ? " is-invalid": "")} 
                    maxLength={maxLength}
                    rows={6}
                    name={fieldName}
                    value={fieldValue}
                    onChange={handleOnChange}
                    disabled={disabled}
                />
                :
                <Field 
                    type={type} 
                    className={(type == 'checkbox' ? "form-check-input" :"form-control") + (errors[fieldName] && touched[fieldName] ? " is-invalid": "")} 
                    maxLength={maxLength}
                    name={fieldName}
                    value={fieldValue}
                    onChange={handleOnChange}
                    disabled={disabled}
                />
                )
                )
                )
            }
            

            <ErrorMessage
                name={fieldName}
                component="div"
                className="invalid-feedback"
            />  
        </div> 
    );
}
FormField.propTypes = {
    disabled: PropTypes.bool,
    type: PropTypes.string.isRequired,
    fieldName: PropTypes.string.isRequired,
    fieldValue: PropTypes.any.isRequired,
    label: PropTypes.string.isRequired,
    errors: PropTypes.any.isRequired,
    touched: PropTypes.any.isRequired,
    maxLength: PropTypes.number,
    handleOnChange: PropTypes.func.isRequired,
    divClassName: PropTypes.string,
    children: PropTypes.any
};
export default FormField;