import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  InputGroup,
  Label,
  Row,
} from "reactstrap";
import { Form, Formik } from "formik";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { getServicePrescriptionList } from "store/actions";
import { useParams } from "react-router-dom";
import { DEFAULT_COMPANY } from "constants/list-types";
import { toDateISOStringFormat } from "helpers/utils";

const LaboratoryPrescriptionHistoricalFilter = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { accountCode } = useParams();

  const [formData, setFormData] = useState({
    startDate: Date.now(),
    endDate: Date.now(),
    medicalCenter: 0,
  });

  const { startDate, endDate } = formData;

  const filter = () => {
    const request = {
      account: accountCode,
      companyId: DEFAULT_COMPANY,
      formDate: toDateISOStringFormat(startDate),
      toDate: toDateISOStringFormat(endDate),
      pageNumber: 0,
    };

    dispatch(getServicePrescriptionList(request));
  };

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Formik
            enableReinitialize={true}
            initialValues={formData}
            validationSchema={Yup.object().shape({
              startDate: Yup.string().required(),
              medicalCenter: Yup.number().required(),
            })}
            onSubmit={(values, actions) => {
              actions.setSubmitting(false);
              filter();
            }}
          >
            {({ errors, status, touched }) => (
              <Form className="needs-validation form-inline">
                <Row>
                  <Col md={4}>
                    <FormGroup className="mb-4">
                      <Label>{t("Date Range")}</Label>
                      <InputGroup>
                        <Flatpickr
                          name="startDate"
                          className="form-control d-block"
                          placeholder="dd M,yyyy"
                          options={{
                            mode: "range",
                            dateFormat: "d-m-Y",
                            locale: "es",
                          }}
                          onChange={dates => {
                            setFormData({
                              ...formData,
                              startDate: dates[0],
                              endDate: dates[1],
                            });
                          }}
                          value={[startDate, endDate]}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <Button
                      type="submit"
                      color="primary"
                      className="button mt-button"
                    >
                      <i className="bx bx-search-alt me-1"></i>
                      {t("Search")}
                    </Button>
                  </Col>
                </Row>
              </Form>
            )}
          </Formik>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default LaboratoryPrescriptionHistoricalFilter;
