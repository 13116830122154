import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row, Table } from "reactstrap";
import profile1 from "assets/images/profile-img.png";
import images from "assets/images";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
const CardProfile = ({ customerProfile }) => {
  const { t } = useTranslation();

  const identificationTypeList = useSelector(
    state => state.identificationTypes.identificationTypeList
  );
  const businessUnitList = useSelector(
    state => state.businessUnits.businessUnits
  );
  const subsidiaryList = useSelector(state => state.subsidiaries.subsidiaries);

  const {
    businessUnitId,
    subsidiaryId,
    accountCode,
    identificationTypeId,
    identification,
    firstName,
    secondName,
    firstSurname,
    secondSurname,
    email,
    mainPhone,
    picture,
  } = customerProfile;

  const getIdentificationType = useCallback(code => {
    const identificationTypeFiltered = identificationTypeList.find(
      current => current.id === code
    );
    return identificationTypeFiltered ? identificationTypeFiltered.name : code;
  });

  const getBusinessUnit = useCallback(code => {
    const businessUnitListFiltered = businessUnitList.find(
      current => current.id === code
    );
    return businessUnitListFiltered ? businessUnitListFiltered.name : code;
  });

  const getSubsidiary = useCallback(code => {
    const subsidiaryFiltered = subsidiaryList.find(
      current => current.id === code
    );
    return subsidiaryFiltered ? subsidiaryFiltered.name : code;
  });

  const fullName = [firstName, secondName, firstSurname, secondSurname].join(' ');

  return (
    <Card className="overflow-hidden">
      <div className="bg-primary bg-soft">
        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              <h5 className="text-primary">{t("Affiliate Information")}</h5>
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            <img src={profile1} alt="" className="img-fluid" />
          </Col>
        </Row>
      </div>
      <CardBody className="pt-0">
        <Row>
          <Col sm="12">
            <div className="avatar-md profile-user-wid mb-4">
              <img
                src={picture ? picture : images["avatar"]}
                alt=""
                className="img-thumbnail rounded-circle"
              />
            </div>
            <h5 className="font-size-15 text-truncate">
              {fullName}
            </h5>
            <p className="text-muted mb-0 text-truncate">{email}</p>
          </Col>
        </Row>

        <Row>
          <div className="table-responsive">
            <Table className="table-nowrap mb-0">
              <tbody>
                <tr>
                  <th scope="row">{t("Identification Type")}:</th>
                  <td>{getIdentificationType(identificationTypeId)}</td>
                </tr>
                <tr>
                  <th scope="row">{t("Identification")}:</th>
                  <td>{identification}</td>
                </tr>
                <tr>
                  <th scope="row">{t("Mobile")}:</th>
                  <td>{mainPhone}</td>
                </tr>

                <tr>
                  <th scope="row">{t("Business Unit")}:</th>
                  <td>{getBusinessUnit(businessUnitId)}</td>
                </tr>
                <tr>
                  <th scope="row">{t("Subsidiary")}:</th>
                  <td>{getSubsidiary(subsidiaryId)}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </Row>
      </CardBody>
    </Card>
  );
};

CardProfile.propTypes = {
  customerProfile: PropTypes.object
};

export default CardProfile;

