import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import * as moment from "moment";
import {
  Button,
  Row,
  Badge,
} from "reactstrap";
import { getAllPurchaseTransactions } from "store/Affiliated/actions"
import { withTranslation } from "react-i18next"
import PageBody from "components/Common/PageLayout/page-body";
import DataTable from "components/Common/DataTable/datatable";
import CustomerOrderPurchaseFilter from "./filter/customer-order-purchase-filter";

const MAX_WIDTH_FOR_MD = 1300;
const MAX_WIDTH_FOR_SM = 768;

class CustomerOrderPurchases extends Component {

  constructor(props) {
    super(props);
    this.node = React.createRef();

    this.state = {
      viewmodal: false,
      modal: false,
      modal_large: false,
      selectedInvoice: null,
      orders: [],
      order: "",
      currentUserAccount: null,
      deleteModal: false
    };
  }

  getWidth() {
    return window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
  }

  takeColumns() {

    const columnsSm = [
      {
        dataField: "datetime",
        text: "Fecha",
        sort: true,
        formatter: (cellContent, row) =>
          <>
            {row.datetime}
          </>
      },
      {
        dataField: "firstName",
        text: "Nombre",
        sort: true,
        formatter: (cellContent, row) => (
          <>
            <div style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
              <p className="fw-bold">
              {row.firstName} {row.firstSurname} {row.secondSurname}
              </p>
            </div>
          </>
        ),
      },
      {
        dataField: "productName",
        text: "Detalle",
        sort: true,
        formatter: (cellContent, row) => (
          <>
          <div style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
              <p className="fw-bold">
              {row.productName}
              </p>
            </div>            
          </>
        ),
      },
      {
        dataField: "totalAmount",
        text: "Total",
        sort: true,
        formatter: (cellContent, row) => (
          <>
            {row.totalAmount.toFixed(2) + " " + row.currencyCode}
          </>
        ),
      },
    ]

    const columnsLg = [
      {
        dataField: "datetime",
        text: "Fecha",
        sort: true,
        formatter: (cellContent, row) =>
          <>
            {row.datetime}
          </>
      },
      {
        dataField: "type",
        text: "Tipo",
        sort: true,
        formatter: (cellContent, row) => <>
            {row.type && (row.type === 'MEM' || row.type === 'MEB') ? 'Membresia' :
             row.type && row.type === 'LAB' ? 'Laboratorio' : 
             row.type && row.type === 'PHA' ? 'Farmacia' : 
             null}
        </>
        },
      {
        dataField: "customerId",
        text: "Cliente No.",
        sort: true,
        formatter: (cellContent, row) =>
          <>
            {row.customerId}
          </>
      },      
      {
        dataField: "identification",
        text: "Identificación",
        sort: true,
        formatter: (cellContent, row) => (
          <>
            {row.identification}
          </>
        ),
      },
      {
        dataField: "firstName",
        text: "Nombre",
        sort: true,
        formatter: (cellContent, row) => (
          <>
            <div style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
              <p className="fw-bold">
            {row.firstName} {row.firstSurname} {row.secondSurname}
              </p>
            </div>
          </>
        ),
      },
      {
        dataField: "invoiceId",
        text: "Documento No.",
        sort: true,
        formatter: (cellContent, row) => (
          <>
            {row.invoiceId}
          </>
        ),
      },
      {
        dataField: "productName",
        text: "Detalle",
        sort: true,
        formatter: (cellContent, row) => (
          <>
          <div style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
              <p className="fw-bold">
            {row.productName}
              </p>
            </div>            
          </>
        ),
      },
      {
        dataField: "totalAmount",
        text: "Total",
        sort: true,
        formatter: (cellContent, row) => (
          <>
            {row.totalAmount.toFixed(2) + " " + row.currencyCode}
          </>
        ),
      },
      {
        dataField: "cardBin",
        text: "BIN",
        sort: true,
        formatter: (cellContent, row) => (
          <>
            {row.cardBin}
          </>
        ),
      },
    ]

    const currentWith = this.getWidth();
    return currentWith < MAX_WIDTH_FOR_SM  ? columnsSm : columnsLg;
  }

  componentDidMount() {
    const {
      match: { params },
      onGetPurchaseTransactions,
    } = this.props
    if (params && params.id) {
      this.setState({ currentUserAccount: params.id });
    }
    onGetPurchaseTransactions();
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  render() {
    const { purchaseTransactions, t } = this.props;

    const pageOptions = {
      sizePerPage: 20,
      totalSize: purchaseTransactions.length,
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: "documentNumber",
        order: "desc",
      },
    ];

    const selectRow = {
      mode: "checkbox",
    };



    return (
      <React.Fragment>
        <PageBody
          title={t("Transactions")}
          actions={(
            <Button
              color="light"
              className="button"
              onClick={() => {
                this.props.history.push("/dashboard");
              }}
            >
              <i className="mdi mdi-arrow-left me-1"></i>
              {t("Back")}
            </Button>
          )}
        >

        <CustomerOrderPurchaseFilter />

          <Row>
            <DataTable
              columns={this.takeColumns()}
              data={purchaseTransactions}
              keyField='id'
              pageSize={50}
            />
          </Row>
         
        </PageBody>
      </React.Fragment>
    );
  }
}

CustomerOrderPurchases.propTypes = {
  purchaseTransactions: PropTypes.array,
  onGetPurchaseTransactions: PropTypes.func,
  match: PropTypes.object,
  t: PropTypes.any,
  className: PropTypes.any,
  history: PropTypes.any,
  loading: PropTypes.bool,
};

const mapStateToProps = ({ affiliateds }) => ({
  loading: affiliateds.loading,
  purchaseTransactions: affiliateds.purchaseTransactions,
})

const mapDispatchToProps = dispatch => ({
  onGetPurchaseTransactions: () => dispatch(getAllPurchaseTransactions())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation()(CustomerOrderPurchases)));
