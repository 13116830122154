import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormField from 'pages/Utility/form-field';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { benefitTypes, businessUnitTypes, currencyTypes, discountTypes } from 'constants/list-types';
import { getMemberships } from 'store/actions';

const QuoterMembershipForm = ({onSubmit, onBack, quoterMembership}) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    Yup.setLocale({
        mixed: {
            required: t('This value is required')
        },
        number: {
            min: t('The value must be greater than or equal to zero')
        }
    });

    const memberships = useSelector(state => state.memberships.membershipList);

    useEffect(() => {
        dispatch(getMemberships());
    }, []);

    const [formData, setFormData] = useState({
        membershipId: quoterMembership && quoterMembership.membership.id ? quoterMembership.membership.id : '',
        name: quoterMembership && quoterMembership.name ? quoterMembership.name : ''
        
    }); 
    
    const { 
        membershipId, name } = formData;

    const handleOnChange = event => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }

    return ( 
        <React.Fragment>
            <Row>
                <Col xl={{size: 10, offset: 1}}>
                    <Card>
                        <CardBody>
                            {/* <CardTitle className="h5">Floating labels</CardTitle> */}
                            {/* <p className="card-title-desc">Create beautifully simple form labels that float over your input fields.</p> */}

                                <Formik
                                    enableReinitialize={true}
                                    initialValues={formData}
                                    validationSchema={
                                        Yup.object().shape({
                                            membershipId: Yup.string().required(),
                                            name: Yup.string().required()
                                        })
                                    }
                                    onSubmit={(values, actions) => {
                                        actions.setSubmitting(false);
                                        onSubmit(formData);
                                    }}
                                >
                                {({ errors, status, touched }) => (
                                    <Form className='needs-validation' >
                                        <Row>
                                            <Col md={6}>
                                                
                                                <FormField
                                                    key='name'
                                                    type='text'
                                                    maxLength={45}
                                                    fieldName='name'
                                                    fieldValue={name}
                                                    label={t('Name')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />
                                                
                                            </Col>
                                            <Col md={6}>

                                                <FormField
                                                    key='membershipId'
                                                    type='select'
                                                    fieldName='membershipId'
                                                    fieldValue={membershipId}
                                                    label={t('Membership')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                >
                                                    <option key="membershipNotSelected" value="">{t('Not Selected')}</option>
                                                            {memberships.map(current => {
                                                                return (<option key={current.id} value={current.id}>{t(current.name)}</option>);
                                                             })}
                                                </FormField>

                                            </Col>
                                        </Row>

                                        <div className="button-items">
                                            <Button type='submit' color="primary" className="button"><i className="mdi mdi-content-save me-1"></i>{t('Save')}</Button>
                                            <Button color="light" className="button mr-1" onClick={() => { onBack() }}><i className="mdi mdi-arrow-left me-1"></i>{t('Back')}</Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
     );
}

QuoterMembershipForm.propTypes = {
    onSubmit: PropTypes.func,
    quoterMembership: PropTypes.any,
    onBack: PropTypes.func
}
 
export default QuoterMembershipForm;