import { ALERT_MESSAGE } from 'constants/constants';
import BenefitForm from 'pages/Management/Commons/benefit-form/benefit-form';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { clearQuoterMembership, getQuoterMembershipDetail, showSuccessAlert, updateQuoterMembership } from 'store/actions';
import QuoterMembershipForm from './question-membership-form';

const EditQuestionMembership = () => {

    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const quoterMembership = useSelector(state => state.quoterMemberships.quoterMembershipDetail);
    const updated = useSelector(state => state.quoterMemberships.updated);

    useEffect(() => {
        dispatch(getQuoterMembershipDetail({ id: id }));
    }, []);

    useEffect(() => {
        if(updated) {
            dispatch(showSuccessAlert(ALERT_MESSAGE.UPDATED, () => {
                dispatch(clearQuoterMembership());
                history.push(`/question-membership-list`);
            }));
        }
        
    }, [updated]);

    const onSubmit = (data) => {
        data.id = quoterMembership.id;
        data.tenantId = quoterMembership.tenantId;
        //data.membershipId = quoterMembership.membership.id;
        dispatch(updateQuoterMembership(data));
    }

    const onBack = () => {
        history.push(`/question-membership-list`);
    }

    return ( 
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t('Title App')}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h2 className="mb-2 font-size-18">{t('Edit Quoter Membership')}</h2>
                    </div>
                    
                    <Row>
                        {
                            quoterMembership?
                            <QuoterMembershipForm 
                                quoterMembership={quoterMembership}
                                onSubmit={onSubmit}
                                onBack={onBack}
                            />
                            : null
                        }
                        
                    </Row>
                </Container>
            </div>    
        </React.Fragment>
    );
}
 
export default EditQuestionMembership;