import { ALERT_MESSAGE } from 'constants/constants';
import { DEFAULT_COMPANY } from 'constants/list-types';
import QuestionForm from 'pages/Management/Commons/question-form/question-form';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { clearQuestion, getQuestionDetail, showSuccessAlert, updateQuestion } from 'store/actions';

const EditQuestion = () => {

    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const question = useSelector(state => state.questions.questionDetail);
    const questionUpdated = useSelector(state => state.questions.questionUpdated);

    useEffect(() => {
        dispatch(getQuestionDetail({ id: id }));
    }, []);

    useEffect(() => {
        if(questionUpdated) {
            dispatch(showSuccessAlert(ALERT_MESSAGE.UPDATED, () => {
                dispatch(clearQuestion());
                history.push(`/questions-list`);
            }));
        }
        
    }, [questionUpdated]);

    const onSubmit = (data) => {
        data.id = question.id;
        data.companyId = DEFAULT_COMPANY;
        data.tenantId = DEFAULT_COMPANY;
        dispatch(updateQuestion(data));
    }

    return ( 
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t('Title App')}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h2 className="mb-2 font-size-18">{t('Edit Question')}</h2>
                    </div>
                    
                    <Row>
                        {
                            question?
                            <QuestionForm 
                                question={question}
                                onSubmit={onSubmit}
                            />
                            : null
                        }
                        
                    </Row>
                </Container>
            </div>    
        </React.Fragment>
    );
}
 
export default EditQuestion;