import { ALERT_MESSAGE } from "constants/constants"
import { 
  httpGetTermsByType,
  httpGetTerms,
  httpGetTermDetail,
  httpAddTerm,
  httpUpdateTerm,
  httpDeleteTerm,
} from "helpers/Api/api-helper"
import { call, put, takeEvery } from "redux-saga/effects"
import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions"
import { 
  getTermsByTypeFail, 
  getTermsByTypeSuccess,
  getTermsSuccess,
  getTermsFail,
  getTermDetailSuccess,
  getTermDetailFail,
  addTermSuccess,
  addTermFail,
  updateTermSuccess,
  updateTermFail,
  deleteTermSuccess,
  deleteTermFail
} from "./actions"
import { 
  GET_TERMS_BY_TYPE,
  ADD_TERM,
  DELETE_TERM,
  GET_TERM_DETAIL,
  GET_TERM_LIST,
  UPDATE_TERM
 } from "./actionTypes"


function* getTermsByType(request) {
    try {
      yield put(showLoadingAlert())
      const response = yield call(httpGetTermsByType, request.payload)
      yield put(hideLoadingAlert())
      yield put(getTermsByTypeSuccess(response))
    } catch (error) {
      yield put(hideLoadingAlert())
      yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
      yield put(getTermsByTypeFail(error))
    }
}

function* getTerms(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetTerms, request.payload)
    yield put(hideLoadingAlert())
    yield put(getTermsSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getTermsFail(error))
  }
}

function* getTermDetail(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetTermDetail, request.payload)
    yield put(hideLoadingAlert())
    yield put(getTermDetailSuccess(response[0]))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_DETAIL_ERROR))
    yield put(getTermDetailFail(error))
  }
}

function* addTerm(request) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.SAVING))
    const response = yield call(httpAddTerm, request.payload)
    yield put(hideLoadingAlert())
    yield put(addTermSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.ADD_ERROR))
    yield put(addTermFail(error))
  }
}

function* updateTerm(request) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.SAVING))
    const response = yield call(httpUpdateTerm, request.payload)
    yield put(hideLoadingAlert())
    yield put(updateTermSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.UPDATE_ERROR))
    yield put(updateTermFail(error))
  }
}

function* deleteTerm(request) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.DELETING))
    const response = yield call(httpDeleteTerm, request.payload)
    yield put(hideLoadingAlert())
    yield put(deleteTermSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.DELETE_ERROR))
    yield put(deleteTermFail(error))
  }
}

function* termsSaga() {
    yield takeEvery(GET_TERMS_BY_TYPE, getTermsByType)
    yield takeEvery(GET_TERM_LIST, getTerms)
    yield takeEvery(GET_TERM_DETAIL, getTermDetail)
    yield takeEvery(ADD_TERM, addTerm)
    yield takeEvery(UPDATE_TERM, updateTerm)
    yield takeEvery(DELETE_TERM, deleteTerm)
  }
  
  export default termsSaga