import {
    ADD_MEMBERSHIP_AFFILIATED_BENEFIT, 
    ADD_MEMBERSHIP_AFFILIATED_BENEFIT_FAIL, 
    ADD_MEMBERSHIP_AFFILIATED_BENEFIT_SUCCESS, 
    CLEAR_MEMBERSHIP_AFFILIATED_BENEFIT_STATE,
    GET_MEMBERSHIP_AFFILIATED_BENEFIT_LIST,
    GET_MEMBERSHIP_AFFILIATED_BENEFIT_LIST_FAIL,
    GET_MEMBERSHIP_AFFILIATED_BENEFIT_LIST_SUCCESS
} from "./actionTypes"

//MembershipAffiliatedBenefits
export const clearMembershipAffiliatedBenefit = () => ({
    type: CLEAR_MEMBERSHIP_AFFILIATED_BENEFIT_STATE
})

export const getMembershipAffiliatedBenefitList = (request) => ({
    type: GET_MEMBERSHIP_AFFILIATED_BENEFIT_LIST,
    payload: request
})

export const getMembershipAffiliatedBenefitListSuccess = response => ({
    type: GET_MEMBERSHIP_AFFILIATED_BENEFIT_LIST_SUCCESS,
    payload: response,
})

export const getMembershipAffiliatedBenefitListFail = error => ({
    type: GET_MEMBERSHIP_AFFILIATED_BENEFIT_LIST_FAIL,
    payload: error,
})

export const addMembershipAffiliatedBenefit = (request) => ({
    type: ADD_MEMBERSHIP_AFFILIATED_BENEFIT,
    payload: request
})

export const addMembershipAffiliatedBenefitSuccess = response => ({
    type: ADD_MEMBERSHIP_AFFILIATED_BENEFIT_SUCCESS,
    payload: response,
})

export const addMembershipAffiliatedBenefitFail = error => ({
    type: ADD_MEMBERSHIP_AFFILIATED_BENEFIT_FAIL,
    payload: error,
})

