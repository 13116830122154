import {
  ADD_PRESCRIPTION,
  ADD_PRESCRIPTION_FAIL,
  ADD_PRESCRIPTION_SUCCESS,
  CLEAR_PRESCRIPTION_STATE,
  DELETE_PRESCRIPTION,
  DELETE_PRESCRIPTION_FAIL,
  DELETE_PRESCRIPTION_SUCCESS,
  GET_PRESCRIPTION_TEMPLATE_LIST,
  GET_PRESCRIPTION_TEMPLATE_LIST_FAIL,
  GET_PRESCRIPTION_TEMPLATE_LIST_SUCCESS,
  GET_PRESCRIPTION_DETAIL,
  GET_PRESCRIPTION_DETAIL_FAIL,
  GET_PRESCRIPTION_DETAIL_SUCCESS,
  GET_PRESCRIPTION_LIST,
  GET_PRESCRIPTION_LIST_FAIL,
  GET_PRESCRIPTION_LIST_SUCCESS,
  UPDATE_PRESCRIPTION,
  UPDATE_PRESCRIPTION_FAIL,
  UPDATE_PRESCRIPTION_SUCCESS,
  UPLOAD_PDF_PRESCRIPTION,
  UPLOAD_PDF_PRESCRIPTION_SUCCESS,
  UPLOAD_PDF_PRESCRIPTION_FAIL,
} from "./actionTypes";

//Terms
export const clearPrescription = () => ({
  type: CLEAR_PRESCRIPTION_STATE,
});

export const getPrescriptionTemplateList = request => ({
  type: GET_PRESCRIPTION_TEMPLATE_LIST,
  payload: request,
});

export const getPrescriptionTemplateListSuccess = response => ({
  type: GET_PRESCRIPTION_TEMPLATE_LIST_SUCCESS,
  payload: response,
});

export const getPrescriptionTemplateListFail = error => ({
  type: GET_PRESCRIPTION_TEMPLATE_LIST_FAIL,
  payload: error,
});

export const getPrescriptionList = request => ({
  type: GET_PRESCRIPTION_LIST,
  payload: request,
});

export const getPrescriptionListSuccess = response => ({
  type: GET_PRESCRIPTION_LIST_SUCCESS,
  payload: response,
});

export const getPrescriptionListFail = error => ({
  type: GET_PRESCRIPTION_LIST_FAIL,
  payload: error,
});

//Prescription Detail
export const getPrescriptionDetail = request => ({
  type: GET_PRESCRIPTION_DETAIL,
  payload: request,
});

export const getPrescriptionDetailSuccess = response => ({
  type: GET_PRESCRIPTION_DETAIL_SUCCESS,
  payload: response,
});

export const getPrescriptionDetailFail = error => ({
  type: GET_PRESCRIPTION_DETAIL_FAIL,
  payload: error,
});

//Add Prescription
export const addPrescription = request => ({
  type: ADD_PRESCRIPTION,
  payload: request,
});

export const addPrescriptionSuccess = response => ({
  type: ADD_PRESCRIPTION_SUCCESS,
  payload: response,
});

export const addPrescriptionFail = error => ({
  type: ADD_PRESCRIPTION_FAIL,
  payload: error,
});

//Update Prescription
export const updatePrescription = request => ({
  type: UPDATE_PRESCRIPTION,
  payload: request,
});

export const updatePrescriptionSuccess = response => ({
  type: UPDATE_PRESCRIPTION_SUCCESS,
  payload: response,
});

export const updatePrescriptionFail = error => ({
  type: UPDATE_PRESCRIPTION_FAIL,
  payload: error,
});

//Upload Pdf Prescription
export const uploadPdfPrescription = request => ({
  type: UPLOAD_PDF_PRESCRIPTION,
  payload: request,
});

export const uploadPdfPrescriptionSuccess = response => ({
  type: UPLOAD_PDF_PRESCRIPTION_SUCCESS,
  payload: response,
});

export const uploadPdfPrescriptionFail = error => ({
  type: UPLOAD_PDF_PRESCRIPTION_FAIL,
  payload: error,
});

//Delete Prescription
export const deletePrescription = request => ({
  type: DELETE_PRESCRIPTION,
  payload: request,
});

export const deletePrescriptionSuccess = response => ({
  type: DELETE_PRESCRIPTION_SUCCESS,
  payload: response,
});

export const deletePrescriptionFail = error => ({
  type: DELETE_PRESCRIPTION_FAIL,
  payload: error,
});

