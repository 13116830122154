import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";
import json from 'jsonwebtoken';
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Row } from "reactstrap";
import {
  addServicePrescription,
  clearAccount,
  clearServicePrescription,
  getAccountDetailLogin,
  showSuccessAlert,
  uploadPdfServicePrescription,
} from "store/actions";
import MedicalInformation from "components/Common/Prescription/medical-information";
import { useFormik } from "formik";
import * as Yup from "yup";
import PageBody from "components/Common/PageLayout/page-body";
import PatientInformation from "components/Common/Prescription/patient-information";
import LaboratoryPrescriptionForm from "./Components/laboratory-prescription-form";
import PrescriptionUploadPdfForm from "components/Common/Prescription/prescription-upload-pdf-form";
import { ALERT_MESSAGE } from "constants/constants";

const LaboratoryPrescriptionAddPage = () => {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();

  const prescriptionCreated = useSelector(state => state.servicePrescriptions.created);
  const prescriptionUploaded = useSelector(state => state.servicePrescriptions.uploaded);
  const accountLogin = useSelector(state => state.accounts.accountLogin);


  const [showSaveTemplate, setShowSaveTemplate] = useState(false);
  const [showUploadPdf, setShowUploadPdf] = useState(false);
  const [prescriptionData, setPrescriptionData] = useState(null);
  const [templateName, setTemplateName] = useState('');
  const [professionLicenseCode, setProfessionLicenseCode] = useState('');
  const [isTemplate, setIsTemplate] = useState(false);
  const [pdfFiles, setPdfFiles] = useState([]);

  const medicalFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      professionLicenseCode: professionLicenseCode ? professionLicenseCode : ''
    },
    validationSchema:
      Yup.object().shape({
        professionLicenseCode: Yup.string().required()
      }),
    onSubmit: (values, actions) => {
      actions.setSubmitting(false);
    },
    onReset: (values, helper) => {

    }
  });

  const { touched, errors, values, validateForm, handleBlur, handleSubmit, handleReset } = medicalFormik;

  useEffect(() => {
    dispatch(clearAccount());
    dispatch(clearServicePrescription());
    if (localStorage.getItem("authUser")) {

      const obj = JSON.parse(localStorage.getItem("authUser"));
      const user = json.decode(obj.idToken);
      dispatch(getAccountDetailLogin({ accountCode: user.sub }));
    }
  }, []);

  useEffect(() => {
    if (accountLogin) {
      setProfessionLicenseCode(accountLogin.professionLicenseCode);
    }
  }, [accountLogin]);

  useEffect(() => {
    if (prescriptionCreated) {
      dispatch(
        showSuccessAlert(ALERT_MESSAGE.SAVED, () => {
          // downloadPdf();
          //if (!isTemplate) {
            //dispatch(clearServicePrescription());
            //onBack();
          //}
        })
      );
    }
  }, [prescriptionCreated]);

  useEffect(() => {
    if (prescriptionUploaded) {
      dispatch(
        showSuccessAlert(ALERT_MESSAGE.UPLOADED, () => {
          setShowUploadPdf(false);
          //downloadPdf();
            //dispatch(clearServicePrescription());
            //onBack();
        })
      );
    }
  }, [prescriptionUploaded]);

  const downloadPdf = () => {
    const downloadLink = document.createElement('a');
    downloadLink.href = prescriptionUploaded? prescriptionUploaded.pdfUrl : prescriptionCreated.pdfUrl;
    downloadLink.setAttribute('download', 'prescription');
    document.body.appendChild(downloadLink);
    downloadLink.click()
  }

  const onSubmit = (data) => {

    //await formik.submitForm();
    let prescription = {
      ...data,
      doctorFullname: [accountLogin.firstName,
      accountLogin.secondName,
      accountLogin.firstSurname,
      accountLogin.secondSurname].join(' '),
      doctorMedicalCode: professionLicenseCode,
      doctorMedicalPhone: accountLogin.mainPhone,
      medicalCenterId: 18
    };
    if (medicalFormik.isValid) {

      setPrescriptionData(prescription);
      setIsTemplate(prescription.prescriptionStatus == "TEM");

      if (prescription.prescriptionStatus == "TEM") {
        setShowSaveTemplate(true);
      } else {
        dispatch(addServicePrescription(prescription));
      }
    }

  };

  const onBack = () => {
    history.goBack();//.push("/dashboard");
  };

  const selectPatient = patient => {
    history.push(`/laboratory-add-prescription/${patient.accountCode}`);
  }

  const onHandleChange = evt => {
    setTemplateName(evt.target.value);
  }

  const onUploadPdfFile = () => {
    const request = {
      ...pdfFiles[0],
      prescriptionId: prescriptionCreated.id
    };

    dispatch(uploadPdfServicePrescription(request));
  }

  return (
    <React.Fragment>

      <PageBody
        title={t("Register Order")}
      >
        <Row>
          <Col xl={{ size: 10, offset: 1 }}>
            {
              accountLogin ?
                <MedicalInformation
                  professionLicenseCode={professionLicenseCode}
                  setProfessionLicenseCode={setProfessionLicenseCode}
                  accountLogin={accountLogin}
                  values={values}
                  errors={errors}
                  handleBlur={handleBlur}
                  handleReset={handleReset}
                  handleSubmit={handleSubmit}
                  touched={touched}
                />
                : null
            }
          </Col>
        </Row>
        <Row>
          <Col xl={{ size: 10, offset: 1 }}>
            <PatientInformation
              onSelectPatient={selectPatient}
            />

          </Col>
        </Row>
        <Row>
          <LaboratoryPrescriptionForm 
            onSubmit={onSubmit} 
            onBack={onBack} 
            onDownloadPdf={downloadPdf}
            setShowUploadPdf={setShowUploadPdf}
            validateMedicalInformation={() => {
              validateForm();
            }}
          />
        </Row>
      </PageBody>



      {showSaveTemplate ? (
        <SweetAlert
          showCancel
          title={t("Template's Name")}
          cancelBtnBsStyle="danger"
          cancelBtnText={t('Cancel')}
          confirmBtnText={t('Save')}
          confirmBtnBsStyle="success"
          onConfirm={() => {
            prescriptionData.patientObservation = templateName;
            dispatch(addServicePrescription(prescriptionData));
            setTemplateName('');
            setShowSaveTemplate(false);
          }}
          onCancel={() => { setShowSaveTemplate(false) }}
        >
          <input
            type="text"
            value={templateName}
            className="form-control"
            placeholder={t("Template's Name")}
            onChange={onHandleChange}
          />
        </SweetAlert>
      ) : null}

      {showUploadPdf ? 
        <PrescriptionUploadPdfForm
          pdfFiles={pdfFiles}
          setPdfFiles={setPdfFiles}
          onUploadPdfFile={onUploadPdfFile}
          setShowUploadPdf={setShowUploadPdf}
        /> : null
      }
    </React.Fragment>
  );
};

export default LaboratoryPrescriptionAddPage;
