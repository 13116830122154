import PageBody from 'components/Common/PageLayout/page-body';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { getDeliveryPackageDetail } from 'store/actions';
import DeliveryPackageDetail from './Components/delivery-package-detail';

const DeliveryPackageDetailPage = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    const order = useSelector(state => state.deliveryPackages.deliveryPackageDetail);

    useEffect(() => {
        if (id) {
            dispatch(getDeliveryPackageDetail({
                id: id
            }));
        }

    }, [id]);

    return (
        <React.Fragment>
            <PageBody
                title='Package Detail'
                actions={(
                    <Button
                        color="light"
                        className="button"
                        onClick={() => {
                            history.push("/delivery-packages-list");
                        }}
                    >
                        <i className="mdi mdi-arrow-left me-1"></i>
                        {t("Back")}
                    </Button>
                )}
            >
                {order ? <DeliveryPackageDetail order={order} /> : null}
            </PageBody>
        </React.Fragment>
    );
}

export default DeliveryPackageDetailPage;