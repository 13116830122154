import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const PageHeader = ({title, children}) => {

    const { t } = useTranslation();

    return (
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h2 className="mb-2 font-title">{t(title)}</h2>
            <div className="button-items">
                {children}
            </div>
        </div>
    );
}

PageHeader.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.any
}

export default PageHeader;