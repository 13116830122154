import { call, put, takeEvery } from "redux-saga/effects";

import {
    AFFILIATED_REWARD_PROGRAM_BY_ACCOUNT
} from "./actionTypes";

import {
    getAffiliatedRewardProgramFail,
    getAffiliatedRewardProgramSuccess
} from "./actions";

import {
    httpGetAffiliatedRewardPrograms
} from "../../helpers/Api/api-helper";

import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions"
import { ALERT_MESSAGE } from "constants/constants";

function* fetchgetAffiliatedRewardProgram(request) {
    try {
      yield put(showLoadingAlert())
      const response = yield call(httpGetAffiliatedRewardPrograms, request.payload)
      yield put(hideLoadingAlert())
      yield put(getAffiliatedRewardProgramSuccess(response))
    } catch (error) {
      yield put(hideLoadingAlert())
      yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
      yield put(getAffiliatedRewardProgramFail(error))
    }
}

function* rewardProgramSaga() {
    yield takeEvery(AFFILIATED_REWARD_PROGRAM_BY_ACCOUNT, fetchgetAffiliatedRewardProgram)
}

export default rewardProgramSaga;