import { ALERT_MESSAGE } from 'constants/constants';
import { DEFAULT_COMPANY } from 'constants/list-types';
import MembershipForm from 'pages/Management/Commons/membership-form/membership-form';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { addMembership, clearMembership } from 'store/actions';
import { showSuccessAlert } from '../../../../store/Alert/actions';

const AddMembershipPackage = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const membershipCreated = useSelector(state => state.memberships.membershipCreated);

    useEffect(() => {
        if(membershipCreated) {
            dispatch(showSuccessAlert(ALERT_MESSAGE.SAVED, () => {
                dispatch(clearMembership());
                history.push('/membership-package');
            }));
        }
    }, [membershipCreated]);
    
    const onSubmit = (data) => {
        data.companyId = DEFAULT_COMPANY;
        data.requireInvitation = data.requireInvitation? 1: 0;
        data.beneficiaryFreeOfCharge = data.beneficiaryFreeOfCharge? 'Y': 'N';
        data.upgrade = data.upgrade? 'Y': 'N';
        data.taxExemption = data.taxExemption? 'Y': 'N';
        data.taxRefund = data.taxRefund? 'Y': 'N';
        data.priceOffer = data.priceOffer? data.priceOffer : 0


        dispatch(addMembership(data));
    }

    const onBack = () => {
        history.goBack();//('/membership-package')
    }
    
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t('Title App')}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h2 className="mb-2 font-size-18">{t('New Membership')}</h2>
                    </div>
                    
                    <Row>
                        <MembershipForm 
                            onSubmit={onSubmit}
                            onBack={onBack}
                        />
                    </Row>
                </Container>
            </div>
            
        </React.Fragment> 
    );
}
 
export default AddMembershipPackage;