import PageBody from 'components/Common/PageLayout/page-body';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { getCatalogs, getCategoryList, getMedicalServiceList } from 'store/actions';
import MedicalCategoryList from './MedicalService/medical-category-list';
import MedicalServiceDetailCard from './MedicalService/medical-service-detail-card';
import MedicalServiceList from './MedicalService/medical-service-list';

const LaboratoryServicePage = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [selectedService, setSelectedService] = useState(null);

    useEffect(() => {
        setSelectedCategory(null);
        setSelectedService(null);
        dispatch(getCatalogs());
        dispatch(getCategoryList({ medicalCategoryType: 'LAB' }));
    }, []);

    const onSelectCategory = (category) => {
        setSelectedCategory(category);
        setSelectedService(null);
        dispatch(getMedicalServiceList({ medicalCategoryId: category.id, pageNumber: 0 }));
    }

    const onSelectService = (service) => {
        setSelectedService(service);
    }

    return (
        <React.Fragment>
            <PageBody
                title='Laboratory Services'
                actions={(
                    <Button
                        color="light"
                        className="button"
                        onClick={() => {
                            history.push("/dashboard");
                        }}
                    >
                        <i className="mdi mdi-arrow-left me-1"></i>
                        {t("Back")}
                    </Button>
                )}
            >
                <Row>
                    <Col xs={12}>
                        <h3>{t('Explore Categories')}</h3>
                        <hr />
                        {/* Render categories */}
                        <MedicalCategoryList
                            onSelect={onSelectCategory}
                            categorySelectedId={selectedCategory ? selectedCategory.id : 0}
                        />
                    </Col>

                    <Col xs={12}>
                        <h3>{t(selectedCategory ? selectedCategory.description : 'Explore Services')}</h3>
                        <hr />
                        {/* Render services */}
                        <MedicalServiceList
                            onSelect={onSelectService}
                        />
                    </Col>
                    <Col xs={12}>
                        <h3>{t(selectedService ? selectedService.description : 'Select a Service')}</h3>
                        <hr />
                        {/* Render selected service */}
                        {
                            selectedService ?
                                <MedicalServiceDetailCard
                                    service={selectedService}
                                />
                                : null
                        }
                    </Col>
                </Row>
            </PageBody>

        </React.Fragment>
    );
}

export default LaboratoryServicePage;