import { ALERT_MESSAGE } from 'constants/constants';
import BenefitForm from 'pages/Management/Commons/benefit-form/benefit-form';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { clearMembership, getBenefitDetail, showSuccessAlert, updateBenefit } from 'store/actions';

const EditBenefit = () => {

    const { t } = useTranslation();
    const { membershipId, id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const benefit = useSelector(state => state.memberships.benefitDetail);
    const benefitUpdated = useSelector(state => state.memberships.benefitUpdated);

    useEffect(() => {
        dispatch(getBenefitDetail({ id: id }));
    }, []);

    useEffect(() => {
        if(benefitUpdated) {
            dispatch(showSuccessAlert(ALERT_MESSAGE.UPDATED, () => {
                dispatch(clearMembership());
                history.push(`/membership-package-benefits/${benefit.membership.id}`);
            }));
        }
        
    }, [benefitUpdated]);

    const onSubmit = (data) => {
        data.id = benefit.id;
        data.membershipId = benefit.membership.id;
        dispatch(updateBenefit(data));
    }

    const onBack = () => {
        history.push(`/membership-package-benefits/${membershipId}`);
    }

    return ( 
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t('Title App')}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h2 className="mb-2 font-size-18">{t('Edit Benefit')}</h2>
                    </div>
                    
                    <Row>
                        {
                            benefit?
                            <BenefitForm 
                                benefit={benefit}
                                onSubmit={onSubmit}
                                onBack={onBack}
                            />
                            : null
                        }
                        
                    </Row>
                </Container>
            </div>    
        </React.Fragment>
    );
}
 
export default EditBenefit;