import React, { useEffect } from "react";
import { Row, Button } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import MembershipListCard from "../../Commons/membership-card/membership-list-card";
import { useTranslation } from "react-i18next";
import { getCatalogs, getMemberships } from "store/actions";
import PageBody from "components/Common/PageLayout/page-body";

const MembershipList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    dispatch(getCatalogs());
    dispatch(getMemberships());
  }, []);

  const currencyCodeList = useSelector(
    state => state.catalogs.currencyCodeList
  );
  const membershipList = useSelector(state => state.memberships.membershipList);

  const getCurrencySymbol = currencyCode => {
    const found = currencyCodeList.find(
      current => current.code == currencyCode
    );
    return found ? found.currency : currencyCode;
  };

  return (
    <React.Fragment>
      <PageBody
        title="Memberships"
        actions={
          <React.Fragment>            
            <Button
              color="light"
              className="button"
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              <i className="mdi mdi-arrow-left me-1"></i>
              {t("Back")}
            </Button>
          </React.Fragment>
        }
      >
        <Row>
          {/* Render pricing cards */}

          {membershipList &&
            membershipList.map((membership, i) => (
              <MembershipListCard
                history={history}
                membership={membership}
                key={"membershipSummaryCard" + i}
                selectText={t("View Detail")}
                onSelect={() => {
                  history.push(`/membership-package-detail/${membership.id}`);
                }}
                getCurrencySymbol={getCurrencySymbol}
              />
            ))}
        </Row>
      </PageBody>
    </React.Fragment>
  );
};

export default MembershipList;

