import {
  GET_MEDICAL_CENTER_LIST,
  GET_MEDICAL_CENTER_LIST_FAIL,
  GET_MEDICAL_CENTER_LIST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  medicalCenterList: [],
  error: {},
  loading: false
}

const medicalCenters = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_MEDICAL_CENTER_LIST:
      return {
        ...state,
        medicalCenterList: [],
        loading: true,
      }         
    case GET_MEDICAL_CENTER_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        medicalCenterList: action.payload,
      }
    case GET_MEDICAL_CENTER_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default medicalCenters;
