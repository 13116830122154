import PageBody from 'components/Common/PageLayout/page-body';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { getSampleCollectDetail } from 'store/actions';
import SampleCollectDetail from './Components/sample-collect-detail';

const SampleCollectDetailPage = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    const appointment = useSelector(state => state.sampleCollect.sampleCollectDetail);

    useEffect(() => {
        if (id) {
            dispatch(getSampleCollectDetail({
                id: id
            }));
        }

    }, [id]);

    return (
        <React.Fragment>
            <PageBody
                title='Sample to Collect Detail'
                actions={(
                    <Button
                        color="light"
                        className="button"
                        onClick={() => {
                            history.push("/sample-collect-list");
                        }}
                    >
                        <i className="mdi mdi-arrow-left me-1"></i>
                        {t("Back")}
                    </Button>
                )}
            >
                {appointment && appointment.appointmentSamples && appointment.appointmentSamples.length > 0 ? <SampleCollectDetail order={appointment} /> : null}
            </PageBody>
        </React.Fragment>
    );
}

export default SampleCollectDetailPage;