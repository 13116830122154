import { call, put, takeEvery } from "redux-saga/effects";

import {
    INVOICE_PAYMENT_MEMBERSHIP_CACELLATION
} from "./actionTypes";

import {
    invoicePaymentMembershipCancellationSuccess,
    invoicePaymentMembershipCancellationFail
} from "./actions";

import {
    httpInvoicePaymentCancellation
} from "../../helpers/Api/api-helper";

import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions"
import { ALERT_MESSAGE } from "constants/constants";

function* fetchInvoicePaymentMembershipCancellation(request) {
    try {
      yield put(showLoadingAlert(ALERT_MESSAGE.SAVING))
      const response = yield call(httpInvoicePaymentCancellation, request.payload)
      yield put(hideLoadingAlert())
      yield put(invoicePaymentMembershipCancellationSuccess(response))
    } catch (error) {
      yield put(hideLoadingAlert())
      yield put(showWarningAlert(ALERT_MESSAGE.ADD_ERROR))
      yield put(invoicePaymentMembershipCancellationFail(error))
    }
}

function* invoicePaymentSaga() {
    yield takeEvery(INVOICE_PAYMENT_MEMBERSHIP_CACELLATION, fetchInvoicePaymentMembershipCancellation)
}

export default invoicePaymentSaga;