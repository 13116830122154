import { call, put, takeEvery } from "redux-saga/effects"
import { ALERT_MESSAGE } from "constants/constants"
import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions"

import {
    getPatientListSuccess,
    getPatientListFail
} from "./actions"

import {
    GET_PATIENT_LIST
} from "./actionTypes"


//API Calls helper
import {
    httpGetPatients
} from "helpers/Api/api-helper"


function* getPatients(request) {
    try {
        yield put(showLoadingAlert())
        const response = yield call(httpGetPatients, request.payload)
        yield put(hideLoadingAlert())
        yield put(getPatientListSuccess(response))
    } catch (error) {
        yield put(hideLoadingAlert())
        yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
        yield put(getPatientListFail(error))
    }
}

function* medicalPatientsSaga() {
    yield takeEvery(GET_PATIENT_LIST, getPatients)
}

export default medicalPatientsSaga
