import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getCatalogs } from 'store/actions';
import PromotionDetailCard from './promotion-detail-card';
import { toDateStringFormat } from 'helpers/utils';
import { benefitTypes, businessUnitTypesForPromos, promotionSectionTypes, promotionActionTypes, promotionTypes } from 'constants/list-types';

const PromotionsDetail = ({ detail }) => {

    const {
        actionType, businessUnitType, code, sectionType,
        name, summary, picture, discount,
        discountType, fromDate, id, promotionDetails,
        promotionType, redeemptionLimit, redeemptionType,
        toDate, value
    } = detail;

    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        dispatch(getCatalogs());
    }, []);

    const currencyCodeList = useSelector(
        state => state.catalogs.currencyCodeList
    );

    const getCurrencySymbol = currencyCode => {
        const found = currencyCodeList.find(
            current => current.code == currencyCode
        );
        return found ? found.currency : currencyCode;
    };

    const findCodeInList = (code, list) => {
        const value = list.find(current => current.code == code);
        return value ? value.value : code;
    }

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Row>
                        <Col sm={6}>
                            <img src={picture} width={'80%'} />
                        </Col>
                        <Col sm={6}>
                            <span>{t('Business Unit Type')}: <b>{t(findCodeInList(businessUnitType, businessUnitTypesForPromos))}</b></span><br/>
                            <span>{t('Display Section')}: <b>{t(findCodeInList(sectionType, promotionSectionTypes))}</b></span><br/>
                            <span>{t('Date')}: <b>{toDateStringFormat(fromDate)} - {toDateStringFormat(toDate)}</b></span><br/>
                            <span>{t('Name')}: <b>{name}</b></span><br/>
                            <span>{t('Summary')}: <b>{summary}</b></span><br/>
                        </Col>
                    </Row>

                </CardBody>
            </Card>

            <Card>
                <CardBody>
                    <b>{t('Products')}</b>
                    {promotionDetails.map((productDetail, index) => (
                        <PromotionDetailCard
                            key={index}
                            productDetail={productDetail}
                            getCurrencySymbol={getCurrencySymbol}
                        />
                    ))}
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

PromotionsDetail.propTypes = {
    detail: PropTypes.object.isRequired
}

export default PromotionsDetail;