import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, CardBody, Button, Label, InputGroup } from "reactstrap";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import FormField from "pages/Utility/form-field";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import InputMask from "react-input-mask"
import { phoneMasks } from "constants/list-types";

const AccountForm = ({ customerProfile, onSubmit }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const identificationTypeList = useSelector(
    state => state.identificationTypes.identificationTypeList
  );
  const businessUnitList = useSelector(
    state => state.businessUnits.businessUnits
  );
  const subsidiaryList = useSelector(state => state.subsidiaries.subsidiaries);

  const countryList = useSelector(state => state.countries.countryList);

  const [identificationTypeSelected, setIdentificationTypeSelected] =
    useState(null);
  const [formData, setFormData] = useState({
    firstName: customerProfile ? customerProfile.firstName : "",
    secondName: customerProfile ? customerProfile.secondName : "",
    firstSurname: customerProfile ? customerProfile.firstSurname : "",
    secondSurname: customerProfile ? customerProfile.secondSurname : "",
    email: customerProfile ? customerProfile.email : "",
    identificationTypeId: customerProfile
      ? customerProfile.identificationTypeId
      : "",
    identification: customerProfile ? customerProfile.identification : "",
    subsidiaryId: customerProfile ? customerProfile.subsidiaryId : "",
    businessUnitId: customerProfile ? customerProfile.businessUnitId : "",
    phoneBooks: customerProfile ? customerProfile.phoneBooks: [],
    mainPhone: ''
  });

  const {
    firstName,
    secondName,
    firstSurname,
    secondSurname,
    email,
    identificationTypeId,
    identification,
    subsidiaryId,
    businessUnitId,
    phoneBooks,
    mainPhoneBook = phoneBooks.length > 0 ? phoneBooks.find(current => current.isDefault == 'Y') : null,
  } = formData;

  const [phoneData, setPhoneData] = useState(mainPhoneBook ? mainPhoneBook : { areaCode: 506, phoneNumber: '', isDefault: 'Y', type: 'PER', description: '' });
  const { areaCode, phoneNumber } = phoneData;

  const phoneMask = phoneMasks.find(current => current.areaCode == areaCode).phoneMask;

  useEffect(() => {
    if (customerProfile && customerProfile.identificationTypeId) {
      setIdentificationTypeSelected(
        identificationTypeList.find(
          current => current.id == customerProfile.identificationTypeId
        )
      );
    }
  }, [customerProfile]);

  useEffect(()=>{
    if(phoneData) {
      setFormData({
        ...formData,
        mainPhone: phoneData.phoneNumber
      });
    }
  }, [phoneData]);

  const handleOnChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleOnChangePhoneData = event => {

    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });

    setPhoneData({
      ...phoneData,
      phoneNumber: event.target.value,
    });
  };

  const handleOnChangeAreaCode = event => {
    setPhoneData({
      ...phoneData,
      [event.target.name]: event.target.value,
    });
  };

  const handleOnChangeIdentificationType = event => {
    setIdentificationTypeSelected(
      identificationTypeList.find(current => current.id == event.target.value)
    );
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const prepareFormat = format => {
    return format.replace(/#/gi, "9").replace(/@/gi, "\*");
  };

  return (
    <React.Fragment>
      <Row>
        <Col xl={{ size: 10, offset: 1 }}>
          <Card>
            <CardBody>
              {/* <CardTitle className="h5">Floating labels</CardTitle> */}
              {/* <p className="card-title-desc">Create beautifully simple form labels that float over your input fields.</p> */}

              <Formik
                enableReinitialize={true}
                initialValues={formData}
                validationSchema={Yup.object().shape({
                  firstName: Yup.string().required(),
                  secondName: Yup.string(),
                  firstSurname: Yup.string().required(),
                  secondSurname: Yup.string().required(),
                  identificationTypeId: Yup.string().required(),
                  identification: Yup.string().required(),
                  email: Yup.string().required().email(),
                  mainPhone: Yup.string().required(),
                  subsidiaryId: Yup.string()/*.required()*/,
                  businessUnitId: Yup.string()/*.required()*/,
                })}
                onSubmit={(values, actions) => {
                  actions.setSubmitting(false);
                  let data = {...formData};
                  let phone = { ...phoneData, phoneNumber: phoneData.phoneNumber.toString().replaceAll(/\-/gi, '') };
                  data.phoneBooks = customerProfile.phoneBooks.filter(current => current.id != phoneData.id);
                  data.phoneBooks.push(phone);
                  onSubmit(data);
                }}
              >
                {({ errors, status, touched }) => (
                  <Form className="needs-validation">
                    <Row>
                      <Col md={6}>
                        <FormField
                          key="identificationTypeId"
                          type="select"
                          fieldName="identificationTypeId"
                          fieldValue={identificationTypeId}
                          label={t("Identification Type")}
                          errors={errors}
                          touched={touched}
                          handleOnChange={handleOnChangeIdentificationType}
                        >
                          <option key="identificationTypeNotSelected" value="">
                            {t("Not Selected")}
                          </option>
                          {identificationTypeList.map(current => {
                            return (
                              <option key={current.id} value={current.id}>
                                {t(current.name)}
                              </option>
                            );
                          })}
                        </FormField>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Identification")}
                          </Label>
                          <InputMask
                            type="text"
                            mask={
                              identificationTypeSelected
                                ? prepareFormat(
                                  identificationTypeSelected.format
                                )
                                : ""
                            }
                            className={
                              "form-control input-mask" +
                              (errors.identification && touched.identification
                                ? " is-invalid"
                                : "")
                            }
                            name="identification"
                            value={identification}
                            onChange={handleOnChange}
                          />
                          <ErrorMessage
                            name="identification"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormField
                          key="firstName"
                          type="text"
                          maxLength={50}
                          fieldName="firstName"
                          fieldValue={firstName}
                          label={t("First Name")}
                          errors={errors}
                          touched={touched}
                          handleOnChange={handleOnChange}
                        />
                      </Col>

                      <Col md={6}>
                        <FormField
                          key="secondName"
                          type="text"
                          maxLength={50}
                          fieldName="secondName"
                          fieldValue={secondName}
                          label={t("Second Name")}
                          errors={errors}
                          touched={touched}
                          handleOnChange={handleOnChange}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormField
                          key="firstSurname"
                          type="text"
                          maxLength={50}
                          fieldName="firstSurname"
                          fieldValue={firstSurname}
                          label={t("First Surname")}
                          errors={errors}
                          touched={touched}
                          handleOnChange={handleOnChange}
                        />
                      </Col>

                      <Col md={6}>
                        <FormField
                          key="secondSurname"
                          type="text"
                          maxLength={50}
                          fieldName="secondSurname"
                          fieldValue={secondSurname}
                          label={t("Second Surname")}
                          errors={errors}
                          touched={touched}
                          handleOnChange={handleOnChange}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormField
                          key="email"
                          type="text"
                          maxLength={255}
                          fieldName="email"
                          fieldValue={email}
                          label={t("Email")}
                          errors={errors}
                          touched={touched}
                          handleOnChange={handleOnChange}
                        />
                      </Col>

                      <Col md={6}>
                        <div className="col-sm-auto mb-3">
                          <Label className="form-label">{t("Phone")}</Label>
                          <InputGroup>
                          <div className="input-group-append">
                          <select
                              className="form-select"
                              name="areaCode"
                              onChange={handleOnChangeAreaCode}
                              value={areaCode}
                          >
                              {countryList.map(current =>
                              (<option
                                key={current.areaCode}
                                value={current.areaCode}>
                                {current.areaCode}
                              </option>))}
                            </select>
                          </div>
                            
                            <InputMask
                              type="text"
                              mask={phoneMask}
                              className={
                                "form-control input-mask" +
                                (errors.mainPhone && touched.mainPhone
                                  ? " is-invalid"
                                  : "")
                              }
                              name="mainPhone"
                              value={phoneNumber}
                              onChange={handleOnChangePhoneData}
                            />
                          </InputGroup>

                          <ErrorMessage
                            name="mainPhone"
                            component="div"
                            className="invalid-feedback"
                          />
                        </div>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <FormField
                          key="subsidiaryId"
                          type="select"
                          fieldName="subsidiaryId"
                          fieldValue={subsidiaryId}
                          label={t("Subsidiary")}
                          errors={errors}
                          touched={touched}
                          handleOnChange={handleOnChange}
                        >
                          <option key="subsidiaryNotSelected" value="">
                            {t("Not Selected")}
                          </option>
                          {subsidiaryList.map(current => {
                            return (
                              <option key={current.id} value={current.id}>
                                {t(current.name)}
                              </option>
                            );
                          })}
                        </FormField>
                      </Col>
                      <Col md={6}>
                        <FormField
                          key="businessUnitId"
                          type="select"
                          fieldName="businessUnitId"
                          fieldValue={businessUnitId}
                          label={t("Business Unit")}
                          errors={errors}
                          touched={touched}
                          handleOnChange={handleOnChange}
                        >
                          <option key="businessUnitNotSelected" value="">
                            {t("Not Selected")}
                          </option>
                          {businessUnitList.map(current => {
                            return (
                              <option key={current.id} value={current.id}>
                                {t(current.name)}
                              </option>
                            );
                          })}
                        </FormField>
                      </Col>
                    </Row>

                    <div className="button-items">
                      <Button type="submit" color="primary" className="button">
                        <i className="mdi mdi-content-save me-1"></i>
                        {t("Save")}
                      </Button>
                      <Button
                        color="light"
                        className="button mr-1"
                        onClick={() => {
                          history.push(`/profile`);
                        }}
                      >
                        <i className="mdi mdi-arrow-left me-1"></i>
                        {t("Back")}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

AccountForm.propTypes = {
  customerProfile: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default AccountForm;
