import {
  GET_DELIVERY_PACKAGE_LIST,
  GET_DELIVERY_PACKAGE_LIST_FAIL,
  GET_DELIVERY_PACKAGE_LIST_SUCCESS,
  GET_DELIVERY_PACKAGE_DETAIL,
  GET_DELIVERY_PACKAGE_DETAIL_FAIL,
  GET_DELIVERY_PACKAGE_DETAIL_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  deliveryPackageList: [],
  deliveryPackageDetail: null,
  error: {},
  loading: false
}

const deliveryPackages = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DELIVERY_PACKAGE_LIST:
      return {
        ...state,
        deliveryPackageList: [],
        loading: true,
      }         
    case GET_DELIVERY_PACKAGE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        deliveryPackageList: action.payload,
      }
    case GET_DELIVERY_PACKAGE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
      case GET_DELIVERY_PACKAGE_DETAIL:
      return {
        ...state,
        deliveryPackageDetail: null,
        loading: true,
      }         
    case GET_DELIVERY_PACKAGE_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        deliveryPackageDetail: action.payload,
      }
    case GET_DELIVERY_PACKAGE_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default deliveryPackages;
