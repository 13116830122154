import AffiliatedSearch from "components/Common/AffiliatedSearch/affiliated-search";
import PageBody from "components/Common/PageLayout/page-body";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";

const PatientList = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <React.Fragment>
      <PageBody
        title={t('Patients')}
        actions={(
          <Button
            color="light"
            className="button"
            onClick={() => { history.push('/dashboard') }}>
            <i className="mdi mdi-arrow-left me-1"></i>{t('Back')}
          </Button>
        )}
      >
        <AffiliatedSearch
          flagType={"patientList"}
        />
      </PageBody>
    </React.Fragment>
  );
};

export default PatientList;

