import React, { useEffect, useState, useReducer } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import classnames from "classnames";
import { QrReader } from "react-qr-reader";
import {
  clearGetAffiliated,
  clearPatient,
  getAffiliatedSearch,
  getCriteria,
  getKey,
  getUrl,
  getPatientList
} from "store/actions";
import AffiliatedList from "./affiliated-list";
import { useTranslation } from "react-i18next";
import SearchBox from "../searchbox/searchbox";
import SweetAlert from "react-bootstrap-sweetalert";



const AffiliatedSearch = ({ onSelectAffiliated, flagType }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const affiliatedList = useSelector(state => state.affiliateds.affiliatedList);
  const criteriaStore = useSelector(state => state.affiliateds);

  const patientList = useSelector(state => state.medicalPatients.patients);

  const [readQR, setReadQR] = useState(false);
  const [qrResult, setQrResult] = useState(null);
  const [isValidQR, setIsValidQR] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [searchText, setSearchText] = useState("");

  const [showKeyEmpty, setShowKeyEmpty] = useState(false);


  const searchResult = affiliatedList.map(affiliated => ({
    ...affiliated,
    fullName: [
      affiliated.firstName,
      affiliated.secondName,
      affiliated.firstSurname,
      affiliated.secondSurname
    ].join(' ')
  }));

  const searchResultPatients = patientList.map(patient => ({
    ...patient,
    fullName: [
      patient.firstName,
      patient.secondName,
      patient.firstSurname,
      patient.secondSurname
    ].join(' ')
  }))

  const isMobile = () => {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    }

  useEffect(() => {
    dispatch(clearGetAffiliated());
    dispatch(clearPatient());
  }, []);

  const toggleTab = tab => {
    onClear();
    setQrResult(null);

    setReadQR(tab == "3");
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    let url2 = criteriaStore.url;

    if (url2 != window.location.pathname) {
      dispatch(getCriteria(null));
      dispatch(getKey(null));
      dispatch(clearGetAffiliated());
      dispatch(clearPatient());
      setSearchText("");
    }

    dispatch(getUrl(window.location.pathname));

    if (criteriaStore.criteria && criteriaStore.key) {
      const searchCriteria = {
        criteria: criteriaStore.criteria,
        key: criteriaStore.key,
      };
      if (url2 == window.location.pathname) {
        setSearchText(searchCriteria.key);
        callSearchEnpoint(searchCriteria);
      }
    }
    if (criteriaStore.criteria) {
      if (criteriaStore.criteria === "name") {
        setActiveTab("2");
      }
    }
  }, []);

  const callSearchEnpoint = (searchCriteria) => {
    if (flagType === "patientList") {
      dispatch(getPatientList(searchCriteria));
    } else {
      dispatch(getAffiliatedSearch(searchCriteria));
    }
  };
  
  const search = searchKey => {
    const criteria =
      activeTab === "1" ? "id" : activeTab === "2" ? "name" : "account";
    const searchCriteria = {
      criteria: criteria,
      key: searchKey,
    };

    if (searchCriteria.key.length > 0) {
      setSearchText(searchCriteria.key);
      dispatch(getCriteria(searchCriteria.criteria));
      dispatch(getKey(searchCriteria.key));
      callSearchEnpoint(searchCriteria);
    } else {
      setShowKeyEmpty(true);
    }
  };

  const parseUserAccount = (userData) => {
    let accountData = null;
    if(userData){
      let actualUserData = userData.replace("{", "").replace("}", "");
      const pairArray = actualUserData.split(",");
      if(pairArray && pairArray.length > 0){
        pairArray.forEach(element => {
          const elementPair = element.split("=");
          if(elementPair && elementPair.length > 0){
            if(elementPair[0].indexOf('accountCode') > -1){
              accountData = elementPair[1];              
            }
          }
        });
      }
    }
    return accountData;
  }

  const onClear = () => {
    dispatch(clearGetAffiliated());
    dispatch(clearPatient());
  };

  return (
    <React.Fragment>
      <Row>
        <Col md={5} lg={5} xl={4}>
          <h5 className="font-size-12 mb-3">
            {t("Search Criteria")}
          </h5>
          <Nav pills justified>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  toggleTab("1");
                }}
              >
                <i className="mdi mdi-id-card font-size-20 d-md-none" />
                <span className="d-none d-md-block">
                  {t("Identification")}
                </span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  toggleTab("2");
                }}
              >
                <i className="bx bx-group font-size-20 d-md-none" />
                <span className="d-none d-md-block">
                  {t("Name")}
                </span>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === "3",
                })}
                onClick={() => {
                  toggleTab("3");
                }}
              >
                <i className="mdi mdi-qrcode font-size-20 d-md-none" />
                <span className="d-none d-md-block">
                  {t("QR Vitae")}
                </span>
              </NavLink>
            </NavItem>
          </Nav>
          {activeTab != "3" ? (
            <SearchBox
              activeTab={activeTab}
              key={"searchBox"}
              onSearch={search}
              onClear={onClear}
              placeholder={"Find Affiliates"}
              keyText={searchText}
              criteriaText={criteriaStore.criteria}
            />
          ) : null}
          {readQR ? (
            <QrReader
              constraints={ {facingMode: isMobile() ? 'environment' : 'user'} }
              onResult={(result, error) => {
                if (result && result.text) {
                  try {
                    const accountData = parseUserAccount(result.text);
                    setQrResult(accountData);
                    search(accountData);
                    if (accountData) {
                      setIsValidQR(true);
                    } else {
                      setIsValidQR(false);
                    }
                  } catch (e) {
                    setIsValidQR(false);
                  }
                  setReadQR(false);
                }
              }}
              style={{ width: "100%" }}
            />
          ) : null}
          {activeTab == "3" && !readQR ? (
            <Card className="mt-2">
              {isValidQR ? (
                <CardBody>
                  <Row>
                    <Col>{t("Qr Processed")}</Col>
                  </Row>
                </CardBody>
              ) : null}
              {!isValidQR ? (
                <CardBody>
                  <Row>
                    <Col>{t("Invalid QR Code")}</Col>
                  </Row>
                </CardBody>
              ) : null}
              <CardFooter>
                <Button
                  type="button"
                  color="primary"
                  onClick={() => {
                    setReadQR(true);
                  }}
                >
                  <i className="mdi mdi-refresh"></i> {t("Retry")}
                </Button>
              </CardFooter>
            </Card>
          ) : null}
        </Col>
        <Col md={7} lg={7} xl={8}>
          <AffiliatedList
            affiliates={flagType === "patientList" ? searchResultPatients : searchResult}
            onSelectAffiliated={onSelectAffiliated}
            flagType={flagType}
          />
        </Col>
      </Row>


      {showKeyEmpty ?
        <SweetAlert
          title={t('Empty Search Criteria')}
          warning
          confirmBtnBsStyle="success"
          confirmBtnText={t('Accept')}
          onConfirm={() => {
            setShowKeyEmpty(false);
          }}
        >
        </SweetAlert>
        : null}
    </React.Fragment>
  );
};

AffiliatedSearch.propTypes = {
  onSelectAffiliated: PropTypes.func,
  flagType: PropTypes.string
};

export default AffiliatedSearch;
