import PageBody from "components/Common/PageLayout/page-body";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import {
  getCatalogs,
  getProductCategoryList,
  getProductSearchDetail,
} from "store/actions";
import ProductSearchDetailCard from "./ProductSearch/product-search-detail-card";

const ProductSearchDetailPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCatalogs());
    dispatch(getProductCategoryList());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getProductSearchDetail({ id: id }));
    }
  }, [id]);

  const currencyCodeList = useSelector(
    state => state.catalogs.currencyCodeList
  );

  const getCurrencySymbol = currencyCode => {
    const found = currencyCodeList.find(
      current => current.code == currencyCode
    );
    return found ? found.currency : currencyCode;
  };

  const productDetail = useSelector(
    state => state.productSearches.productDetail
  );

  return (
    <React.Fragment>
      <PageBody
        title="Product Detail"
        actions={
          <Button
            color="light"
            className="button"
            onClick={() => {
              history.goBack();
            }}
          >
            <i className="mdi mdi-arrow-left me-1"></i>
            {t("Back")}
          </Button>
        }
      >
        {/* Render selected service */}
        {productDetail && (
          <ProductSearchDetailCard
            product={productDetail}
            getCurrencySymbol={getCurrencySymbol}
          />
        )}
      </PageBody>
    </React.Fragment>
  );
};

export default ProductSearchDetailPage;
