import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { toDateStringFormat } from 'helpers/utils';

const ExamPatientDetailStatus = ({ laboratoryOrder }) => {

    const { orderDate, laboratoryOrderStatus } = laboratoryOrder;
    const { t } = useTranslation();

    return (
        <React.Fragment>
            {
                laboratoryOrderStatus.map((orderStatus, index) => (
                    <Card key={orderStatus.id} className='category-card'>
                        <CardBody>

                            <div className="avatar-xs float-start font-size-24 me-1">
                                <span className={`avatar-title rounded-circle ${orderStatus.completionStatus == 'FIN' ? 'bg-primary' : ''}`}>
                                    {index + 1}
                                </span>
                            </div>
                            <div className="align-middle">
                                <div className="float-start me-1">
                                    <h5>{orderStatus.completionStatusDescription}</h5>
                                    <h6><i className='text-primary mdi mdi-calendar'></i> {toDateStringFormat(orderDate)}</h6>
                                    <h5>{orderStatus.medicalService ? orderStatus.medicalService.description : ''}</h5>
                                </div>
                                <div className='font-size-24 float-start me-1'>
                                    <i className={`me-1 text-primary mdi ${orderStatus.completionStatus == 'FIN' ? 'mdi-check-circle-outline' : 'bx bx-uncheck'}`}></i>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                ))
            }
        </React.Fragment>
    );
}

ExamPatientDetailStatus.propTypes = {
    laboratoryOrder: PropTypes.any.isRequired
}

export default ExamPatientDetailStatus;