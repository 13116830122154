import {
  ADD_BENEFIT,
  ADD_BENEFIT_FAIL,
  ADD_BENEFIT_SUCCESS,
  ADD_MEMBERSHIP,
  ADD_MEMBERSHIP_FAIL,
  ADD_MEMBERSHIP_SUCCESS,
  CLEAR_MEMBERSHIP_STATE,
  DELETE_BENEFIT,
  DELETE_BENEFIT_FAIL,
  DELETE_BENEFIT_SUCCESS,
  DELETE_MEMBERSHIP,
  DELETE_MEMBERSHIP_FAIL,
  DELETE_MEMBERSHIP_SUCCESS,
  GET_BENEFIT_DETAIL,
  GET_BENEFIT_DETAIL_FAIL,
  GET_BENEFIT_DETAIL_SUCCESS,
  GET_BENEFIT_LIST,
  GET_BENEFIT_LIST_FAIL,
  GET_BENEFIT_LIST_SUCCESS,
  GET_MEMBERSHIP_DETAIL,
  GET_MEMBERSHIP_DETAIL_FAIL,
  GET_MEMBERSHIP_DETAIL_SUCCESS,
  GET_MEMBERSHIP_LIST,
  GET_MEMBERSHIP_LIST_FAIL,
  GET_MEMBERSHIP_LIST_SUCCESS,
  UPDATE_BENEFIT,
  UPDATE_BENEFIT_FAIL,
  UPDATE_BENEFIT_SUCCESS,
  UPDATE_MEMBERSHIP,
  UPDATE_MEMBERSHIP_FAIL,
  UPDATE_MEMBERSHIP_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  membershipList: [],
  success: false,
  loading: false,
  membershipDetail: null,
  membershipCreated: null,
  membershipUpdated: null,
  membershipDeleted: null,
  benefitDetail: null,
  benefitCreated: null,
  benefitUpdated: null,
  benefitDeleted: null,
  benefits: [],
  error: {},
}

const memberships = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_MEMBERSHIP_STATE:
    return {
      ...state,
      membershipList: [],
      success: false,
      loading: false,
      membershipDetail: null,
      membershipCreated: null,
      membershipUpdated: null,
      membershipDeleted: null,
      benefitDetail: null,
      benefitCreated: null,
      benefitUpdated: null,
      benefitDeleted: null,
      benefits: [],
      error: {},
    }
    case GET_MEMBERSHIP_LIST:
      return {
        ...state,
        membershipList: [],
        loading: true,
      }
    case GET_MEMBERSHIP_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        membershipList: action.payload,
      }  
    case GET_MEMBERSHIP_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_MEMBERSHIP_DETAIL:
      return {
        ...state,
        membershipDetail: undefined,
        loading: true,
      }
    case GET_MEMBERSHIP_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        membershipDetail: action.payload,
      }  
    case GET_MEMBERSHIP_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_MEMBERSHIP:
      return {
        ...state,
        success: false,
        loading: true,
        membershipCreated: undefined
      }
    case ADD_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        membershipCreated: action.payload,
      }  
    case ADD_MEMBERSHIP_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_MEMBERSHIP:
      return {
        ...state,
        success: false,
        loading: true,
        membershipUpdated: undefined
      }
    case UPDATE_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        membershipUpdated: action.payload,
      }  
    case UPDATE_MEMBERSHIP_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_MEMBERSHIP:
      return {
        ...state,
        success: false,
        loading: true,
        membershipDeleted: undefined
      }
    case DELETE_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        membershipDeleted: action.payload,
      }  
    case DELETE_MEMBERSHIP_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_BENEFIT_LIST:
      return {
        ...state,
        benefits: [],
        loading: true,
      }
    case GET_BENEFIT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        benefits: action.payload,
      }  
    case GET_BENEFIT_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_BENEFIT_DETAIL:
      return {
        ...state,
        benefitDetail: undefined,
        loading: true,
      }
    case GET_BENEFIT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        benefitDetail: action.payload,
      }  
    case GET_BENEFIT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_BENEFIT:
      return {
        ...state,
        success: false,
        loading: true,
        benefitCreated: undefined
      }
    case ADD_BENEFIT_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        benefitCreated: action.payload,
      }  
    case ADD_BENEFIT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_BENEFIT:
      return {
        ...state,
        success: false,
        loading: true,
        benefitUpdated: undefined
      }
    case UPDATE_BENEFIT_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        benefitUpdated: action.payload,
      }  
    case UPDATE_BENEFIT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_BENEFIT:
      return {
        ...state,
        success: false,
        loading: true,
        benefitDeleted: undefined
      }
    case DELETE_BENEFIT_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        benefitDeleted: action.payload,
      }  
    case DELETE_BENEFIT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default memberships;
