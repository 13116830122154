import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, CardImg, Button } from "reactstrap";
import { numberFormat } from "helpers/utils";
import { useTranslation } from "react-i18next";
import image from 'assets/images/vitae/Logos-vitae.png'

const PrescriptionProductCard = ({ product, getCurrencySymbol, onSelect }) => {
  const { t } = useTranslation();

  const getProductPicture = () => {
    let picture =
    product.product.productMedias && product.product.productMedias.length > 0
        ? product.product.productMedias[0]
        : null;

    return picture ? picture.filePath : "";
  };

  return (
    <React.Fragment>
      <Col sm={3} md={4} xl={3}>
        <Card className="plan-box">
          <CardBody className="p-2">
            <CardImg
              className="img-fluid product-image"
              src={getProductPicture()}
              alt="Product"
              onError={(evt) => (evt.target.onerror = null, evt.target.src = image)}
            />
            <div className="d-flex pt-2">
              <div className="flex-grow-1">
                <p className="text-muted">
                  {product.product.productCategories.map(productCategory => (
                    <span key={`product-category-${productCategory.id}`}>
                      {productCategory.category.name}
                    </span>
                  ))}
                </p>
                <h5>{product.product.name}</h5>
              </div>
            </div>
            <div className="py-1">
              <h5>
                <strong>{t("Pharmacy indications")}</strong>
              </h5>
              <h5>{product.doctorPrescription}</h5>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

PrescriptionProductCard.propTypes = {
  product: PropTypes.object.isRequired,
  getCurrencySymbol: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default PrescriptionProductCard;
