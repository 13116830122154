import PageBody from "components/Common/PageLayout/page-body";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import { getAffiliatedExpenses, getCatalogs } from "store/actions";
import CustomerStateBenefit from "./customer-state-benefit";
import CustomerStateChart from "./customer-state-chart";

const CustomerStatePage = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [medicalCategories, setMedicalCategories] = useState([]);
  useEffect(() => {
    dispatch(getCatalogs());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getAffiliatedExpenses(id));
    }
  }, [id]);

  const currencyCodeList = useSelector(
    state => state.catalogs.currencyCodeList
  );
  const expenses = useSelector(state => state.affiliatedExpenses.expenses);

  useEffect(() => {
    if (expenses) {
      let data = [];
      expenses.medicalCategories.forEach(current =>
        data.push({
          ...current,
          color: "#000000",
        })
      );
      setMedicalCategories(data);
    }
  }, [expenses]);
  const getCurrencySymbol = currencyCode => {
    const found = currencyCodeList.find(
      current => current.code == currencyCode
    );
    return found ? found.currency : currencyCode;
  };

  return (
    <React.Fragment>
      <PageBody
        title="Services Consumed"
        actions={
          <Button
            color="light"
            className="button mr-1"
            onClick={() => {
              history.push(`/membership-customer-search`);
            }}
          >
            <i className="mdi mdi-arrow-left me-1"></i>
            {t("Back")}
          </Button>
        }
      >
        <Row>
          <Col md={6}>
            {medicalCategories &&
              medicalCategories.map((current, index) => (
                <CustomerStateBenefit
                  key={index}
                  medicalEntry={current}
                  onSelect={setSelectedCategory}
                  getCurrencySymbol={getCurrencySymbol}
                />
              ))}

            {!medicalCategories || medicalCategories.length === 0 ? (
               <h5>{t("No services")}</h5>
            ) : null}
          </Col>
          <Col md={6}>
            {expenses && medicalCategories ? (
              <CustomerStateChart
                amountWithMembership={expenses.amountWithMembership}
                amountWithoutMembership={expenses.amountWithoutMembership}
                totalSavings={expenses.totalSavings}
                medicalCategories={medicalCategories}
                selectedCategory={selectedCategory}
                getCurrencySymbol={getCurrencySymbol}
              />
            ) : null}
          </Col>
        </Row>
      </PageBody>
    </React.Fragment>
  );
};

export default CustomerStatePage;
