import {
  GET_MEDICAL_CATEGORY_LIST,
  GET_MEDICAL_CATEGORY_LIST_FAIL,
  GET_MEDICAL_CATEGORY_LIST_SUCCESS,
  GET_MEDICAL_SERVICE_DETAIL,
  GET_MEDICAL_SERVICE_DETAIL_FAIL,
  GET_MEDICAL_SERVICE_DETAIL_SUCCESS,
  GET_MEDICAL_SERVICE_LIST,
  GET_MEDICAL_SERVICE_LIST_FAIL,
  GET_MEDICAL_SERVICE_LIST_SUCCESS,
  GET_ALL_MEDICAL_SERVICE_LIST,
  GET_ALL_MEDICAL_SERVICE_LIST_SUCCESS,
  GET_ALL_MEDICAL_SERVICE_LIST_FAIL,
  GET_ALL_MEDICAL_SERVICE_BY_TYPE_LIST,
  GET_ALL_MEDICAL_SERVICE_BY_TYPE_LIST_SUCCESS,
  GET_ALL_MEDICAL_SERVICE_BY_TYPE_LIST_FAIL,

  MODIFY_MEDICAL_SERVICE,
  MODIFY_MEDICAL_SERVICE_FAIL,
  MODIFY_MEDICAL_SERVICE_SUCCESS
} from "./actionTypes"

//Category List
export const getCategoryList = medicalCategoryType => ({
  type: GET_MEDICAL_CATEGORY_LIST,
  payload: medicalCategoryType
})

export const getCategoryListSuccess = response => ({
  type: GET_MEDICAL_CATEGORY_LIST_SUCCESS,
  payload: response,
})

export const getCategoryListFail = error => ({
  type: GET_MEDICAL_CATEGORY_LIST_FAIL,
  payload: error,
})

//Medical Service List
export const getMedicalServiceList = medicalCategoryId => ({
  type: GET_MEDICAL_SERVICE_LIST,
  payload: medicalCategoryId
})

export const getMedicalServiceListSuccess = response => ({
  type: GET_MEDICAL_SERVICE_LIST_SUCCESS,
  payload: response,
})

export const getMedicalServiceListFail = error => ({
  type: GET_MEDICAL_SERVICE_LIST_FAIL,
  payload: error,
})

//All Medical Service List
export const getAllMedicalServiceList = () => ({
  type: GET_ALL_MEDICAL_SERVICE_LIST
})

export const modifyMedicalService = request => ({
  type: MODIFY_MEDICAL_SERVICE,
  payload: request
})

export const getAllMedicalServiceListSuccess = response => ({
  type: GET_ALL_MEDICAL_SERVICE_LIST_SUCCESS,
  payload: response,
})

export const getAllMedicalServiceListFail = error => ({
  type: GET_ALL_MEDICAL_SERVICE_LIST_FAIL,
  payload: error,
})

//All Medical Service by Type List
export const getAllMedicalServiceByTypeList = (request) => ({
  type: GET_ALL_MEDICAL_SERVICE_BY_TYPE_LIST,
  payload: request
})

export const getAllMedicalServiceByTypeListSuccess = response => ({
  type: GET_ALL_MEDICAL_SERVICE_BY_TYPE_LIST_SUCCESS,
  payload: response,
})

export const getAllMedicalServiceByTypeListFail = error => ({
  type: GET_ALL_MEDICAL_SERVICE_BY_TYPE_LIST_FAIL,
  payload: error,
})

//Medical Service Detail
export const getMedicalServiceDetail = medicalServiceId => ({
  type: GET_MEDICAL_SERVICE_DETAIL,
  payload: medicalServiceId
})

export const getMedicalServiceDetailSuccess = response => ({
  type: GET_MEDICAL_SERVICE_DETAIL_SUCCESS,
  payload: response,
})

export const getMedicalServiceDetailFail = error => ({
  type: GET_MEDICAL_SERVICE_DETAIL_FAIL,
  payload: error,
})