import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { Row, Col, Button, Modal, Card, CardBody } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getServicePrescriptionTemplateList } from 'store/actions';
import { DEFAULT_COMPANY } from 'constants/list-types';
import { toDateISOStringFormat, toDateStringFormat } from 'helpers/utils';
import json from 'jsonwebtoken';
import DataTable from '../DataTable/datatable';

const LaboratoryPrescriptionSearchTemplateForm = ({ isOpen, onSelect, onCancel }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const templateList = useSelector(
        state => state.servicePrescriptions.prescriptionTemplates
    );

    const [pageOptions, setPageOptions] = useState({
        sizePerPage: 10,
        totalSize: 0,
        custom: true,
    });
    
    useEffect(() => {
        
        let accountCode = null;
        
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            const user = json.decode(obj.idToken);
            accountCode = user.sub;
        }

        const request = {
            companyId: DEFAULT_COMPANY,
            account: accountCode,
            fromDate: toDateISOStringFormat(new Date()),
            toDate: toDateISOStringFormat(new Date()),
            pageNumber: 0,
        };
        dispatch(getServicePrescriptionTemplateList(request));
    }, []);

    useEffect(() => {
        if (templateList) {
            setPageOptions({
                ...pageOptions,
                totalSize: templateList.length,
            });
        }
    }, [templateList]);

    const columns = [
        {
            text: t("Name"),
            dataField: "patientObservation",
            sort: true,
            formatter: (cellContent, row) => {

                return (
                    <React.Fragment>
                        {row.patientObservation}
                    </React.Fragment>
                );
            },
        },
        {
            text: t("Date"),
            dataField: "doctorDiagnosticDate",
            sort: true,
            formatter: (cellContent, row) => (
                <React.Fragment>{toDateStringFormat(row.doctorDiagnosticDate)}</React.Fragment>
            ),
        },
        {
            dataField: "menu",
            isDummyField: true,
            editable: false,
            text: "",
            formatter: (cellContent, row) => (
                <div className="d-flex gap-3">
                    <Button
                        type='button'
                        color='primary'
                        onClick={() => { onSelect(row); }}>
                        {t('Select')}
                        <i
                            className="mdi mdi-arrow-right"
                            id="edittooltip"
                        ></i>
                    </Button>

                </div>
            ),
        },
    ];


    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        },
    ];

    return (
        <React.Fragment>
            <Modal
                size='xl'

                isOpen={isOpen}
                toggle={() => { onCancel() }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {t('Templates Search')}
                    </h5>
                    <button
                        type="button"
                        onClick={() => onCancel()}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div className="modal-body">
                    <Row>
                        <Col lg={12}>
                            <DataTable
                                data={templateList}
                                columns={columns}
                                sort={defaultSorted}
                                keyField='id'
                            />
                        </Col>
                    </Row>
                </div>

                <div className="modal-footer button-items mt-1">
                    {/*<Button type='submit' color="primary" className="button"><i className="mdi mdi-content-save me-1"></i>{t('Save')}</Button>*/}
                    <Button color="light" className="button mr-1" onClick={() => { onCancel(); }}><i className="mdi mdi-close-thick me-1"></i>{t('Close')}</Button>
                </div>

            </Modal>
        </React.Fragment>
    );
}

LaboratoryPrescriptionSearchTemplateForm.propTypes = {
    onSelect: PropTypes.func,
    onCancel: PropTypes.func,
    isOpen: PropTypes.bool
}

export default LaboratoryPrescriptionSearchTemplateForm;