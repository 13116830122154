import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Col, Row, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { toDateStringFormat, toHourStringFormat } from 'helpers/utils';
import SweetAlert from "react-bootstrap-sweetalert";
import { updateDeliveryPackageStatus } from 'store/actions';
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";

const DeliveryPackageDetail = ({ order }) => {

    const { t } = useTranslation();
    const {
        productOrderPickups
    } = order;

    const {
        pickupPersonFullname, pickupPersonPhone,
        pickupAddress,
        pickupLatitude, pickupLongitude, pickupDate,
        pickupTimeFrom, pickupTimeTo, id, pickupStatus
    } = productOrderPickups.length > 0 ? productOrderPickups[0] : {};

    const [showDelete, setShowDelete] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const dispatch = useDispatch();
    const history = useHistory();

    const onConfirm = () => {

        if (showConfirm) {
            dispatch(updateDeliveryPackageStatus({ id: id, pickupStatus: "FIN" }));
        } else {
            dispatch(updateDeliveryPackageStatus({ id: id, pickupStatus: "CAN" }));
        }
        setShowDelete(false);
        setShowConfirm(false);
        history.goBack();

    };

    const data = [
        {
            "id": 1, "date": "2022-05-18T23:01:36", "invoiceId": 955,
            "pharmacy": {
                "id": 1, "countryCode": "CR", "companyId": 1,
                "subsidiaryId": 1, "name": "Farmacia", "phone": "22140396",
                "email": "farmacia@farma.com", "geoDistribution1": "SAN JOSE",
                "geoDistribution2": "SAN JOSE", "geoDistribution3": "SAN JOSE",
                "address": "Frente al Hospittal Calderon Guardia",
                "latitude": "25.20342", "longitude": "-180.213123"
            },
            "patient": {
                "id": 1, "countryCode": "CR", "companyId": 1,
                "accountCode": "0f1f5eff-65f7-4e3f-a420-5baf84bd6092",
                "identificationTypeId": 1, "identification": "115210965",
                "firstName": "Adrián", "secondName": "José", "firstSurname": "Medina", "secondSurname": "Barrientos",
                "email": "amedina@coliand.com", "mainPhone": "89494749",
                "gender": "MAL", "maritalStatus": "SIN",
                "birthDate": "1992-03-20T09:33:00"
            },
            "productOrderDetails": [
                {
                    "id": 1, "currencyCode": "CR", "price": 95.00000,
                    "quantity": 5.00000, "taxPercentage": 13.00000, "productOrder": 1,
                    "product": {
                        "id": 1, "code": "66036", "name": "Padimate O, Petrolatum",
                        "description": "Phasellus sit amet erat. Nulla tempus. Vivamus in felis eu sapien cursus vestibulum.\n\nProin eu mi. Nulla ac enim. In tempor, turpis nec euismod scelerisque, quam turpis adipiscing lorem, vitae mattis nibh ligula nec sem.",
                        "patientPlanIndicator": "N", "pharmaceuticalForm": "inyeccion", "potency": "30 miligramos",
                        "prescription": "Late effect, war injury",
                        "featuredIndicator": "N", "deliveryIndicator": "Y",
                        "currencyCode": "CRC", "stock": 18, "price": 95.00000,
                        "priceOffer": 0.00000, "taxPercentage": 13.00000, "discount": 0.00000,
                        "productCategories": [],
                        "productDisplays": [],
                        "productMedias": [
                            { "id": 1, "type": "png", "fileName": "Octinoxate", "filePath": "http://dummyimage.com/100x100.png/cc0000/ffffff", "product": 1 }
                        ]
                    },
                    "productRatings": [
                        { "id": 1, "rating": 5, "feedback": "Muy buen producto, similar a la descripcion del mismo", "productOrderDetail": 1 },
                        { "id": 2, "rating": 5, "feedback": "Muy buen producto, similar a la descripcion del mismo", "productOrderDetail": 1 },
                        { "id": 3, "rating": 5, "feedback": "", "productOrderDetail": 1 },
                        { "id": 4, "rating": 5, "feedback": "Muy buen producto, similar a la descripcion del mismo", "productOrderDetail": 1 }
                    ]
                },
                {
                    "id": 2, "currencyCode": "CR", "price": 95.00000, "quantity": 5.00000,
                    "taxPercentage": 13.00000, "productOrder": 1,
                    "product": {
                        "id": 2, "code": "14", "name": "Black Pepper",
                        "description": "Mauris enim leo, rhoncus sed, vestibulum sit amet, cursus id, turpis. Integer aliquet, massa id lobortis convallis, tortor risus dapibus augue, vel accumsan tellus nisi eu orci. Mauris lacinia sapien quis libero.\n\nNullam sit amet turpis elementum ligula vehicula consequat. Morbi a ipsum. Integer a nibh.\n\nIn quis justo. Maecenas rhoncus aliquam lacus. Morbi quis tortor id nulla ultrices aliquet.",
                        "patientPlanIndicator": "N", "pharmaceuticalForm": "inyeccion", "potency": "30 miligramos", "prescription": "Shoulder region dis NEC", "featuredIndicator": "N", "deliveryIndicator": "Y", "currencyCode": "CRC", "stock": 1139, "price": 99.00000, "priceOffer": 0.00000, "taxPercentage": 13.00000, "discount": 10.00000, "discountType": "PER", "productCategories": [], "productDisplays": [],
                        "productMedias": [
                            {
                                "id": 2, "type": "jpg", "fileName": "MOUSE EPITHELIA",
                                "filePath": "http://dummyimage.com/100x100.png/cc0000/ffffff",
                                "product": 2
                            }
                        ]
                    }, "productRatings": []
                },
                {
                    "id": 3, "currencyCode": "CR", "price": 95.00000,
                    "quantity": 5.00000, "taxPercentage": 13.00000,
                    "productOrder": 1,
                    "product": {
                        "id": 3, "code": "141",
                        "name": "METFORMIN HYDROCHLORIDE",
                        "description": "Vestibulum ac est lacinia nisi venenatis tristique. Fusce congue, diam id ornare imperdiet, sapien urna pretium nisl, ut volutpat sapien arcu sed augue. Aliquam erat volutpat.",
                        "patientPlanIndicator": "N", "pharmaceuticalForm": "crema",
                        "potency": "30 miligramos", "prescription": "Malnutrition mod degree", "featuredIndicator": "N",
                        "deliveryIndicator": "Y", "currencyCode": "USD", "stock": 5, "price": 54.00000,
                        "priceOffer": 0.00000, "taxPercentage": 13.00000, "discount": 10.00000,
                        "discountType": "PER", "productCategories": [], "productDisplays": [],
                        "productMedias": [
                            { "id": 3, "type": "jpg", "fileName": "Lovastatin", "filePath": "http://dummyimage.com/100x100.png/5fa2dd/ffffff", "product": 3 }
                        ]
                    }, "productRatings": []
                }, {
                    "id": 4, "currencyCode": "CR", "price": 95.00000, "quantity": 5.00000,
                    "taxPercentage": 13.00000, "productOrder": 1,
                    "product": {
                        "id": 4, "code": "7625", "name": "Niacinamide, Adenosine", "description": "Aliquam quis turpis eget elit sodales scelerisque. Mauris sit amet eros. Suspendisse accumsan tortor quis turpis.\n\nSed ante. Vivamus tortor. Duis mattis egestas metus.\n\nAenean fermentum. Donec ut mauris eget massa tempor convallis. Nulla neque libero, convallis eget, eleifend luctus, ultricies eu, nibh.", "patientPlanIndicator": "N", "pharmaceuticalForm": "capsula", "potency": "30 miligramos", "prescription": "Inj port/splen vess NEC", "featuredIndicator": "N", "deliveryIndicator": "N", "currencyCode": "CRC", "stock": 6620, "price": 61.00000, "priceOffer": 0.00000, "taxPercentage": 13.00000, "discount": 15.00000, "discountType": "PER", "productCategories": [], "productDisplays": [], "productMedias": [{ "id": 4, "type": "jpg", "fileName": "Hazelnut", "filePath": "http://dummyimage.com/100x100.png/5fa2dd/ffffff", "product": 4 }]
                    }, "productRatings": []
                }
            ],
            "productOrderPickups": [
                {
                    "id": 1, "pickupType": "HOM", "pickupPersonFullname": "Adrian Medina Barrientos",
                    "pickupPersonIdentification": "114970402", "pickupPersonPhone": "89494749",
                    "pickupDate": "2022-04-16T06:33:00", "pickupTimeFrom": "2022-04-16T06:33:00",
                    "pickupTimeTo": "2022-04-16T08:33:00", "pickupGeoDistribution1": "SAN JOSE",
                    "pickupGeoDistribution2": "SAN JOSE", "pickupGeoDistribution3": "HATILLO",
                    "pickupAddress": "Hatillo 3 alameda 6", "pickupLatitude": "25.15151", "pickupLongitude": "-25.151511", "productOrder": 1
                }
            ]
        }
    ];
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Row>
                        <Col md={10}>
                            <Row>
                                <Col md={6}>
                                    <h5>{t('Address')}: {pickupAddress}</h5>
                                </Col>
                                <Col md={6}>
                                    <h5>{t('GPS')}: {pickupLatitude} {pickupLongitude}</h5>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <h5>{t('Patient')}: {pickupPersonFullname}</h5>
                                </Col>
                                <Col md={6}>
                                    <h5>{t('Phone')}: {pickupPersonPhone}</h5>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={12}>
                                    <h5>{t('Date')}: {toDateStringFormat(pickupDate)}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <h5>{t('Hour')}:</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={6}>
                                    <h5>{t('From')}: {toHourStringFormat(pickupTimeFrom)}</h5>
                                </Col>
                                <Col md={6}>
                                    <h5>{t('To')}: {toHourStringFormat(pickupTimeTo)}</h5>
                                </Col>
                            </Row>
                        </Col>

                        {
                            pickupStatus === "PEN" ?
                                <Col md={2}>
                                    <Row>
                                        <Col me={2}>
                                            <Button type="button"
                                                className="btn btn-success btn-lg me-2"
                                                onClick={() => { setShowConfirm(true); }}
                                            >
                                                {t("Done")}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <br></br>
                                    </Row>
                                    <Row>
                                        <Col me={2}>
                                            <Button type="button"
                                                className="btn btn-danger btn-lg me-2"
                                                onClick={() => { setShowDelete(true); }}
                                            >
                                                {t("Cancel")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                : null
                        }
                    </Row>
                </CardBody>
            </Card>


            {showDelete ?

                <SweetAlert
                    title={t("confirm.cancel.pharmacy.order")}
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText={t("Yes")}
                    cancelBtnText={t("No")}
                    onConfirm={() => {
                        onConfirm();
                    }}
                    onCancel={() => {
                        setShowDelete(false);
                    }}
                ></SweetAlert>
                : null
            }

            {showConfirm ?
                <SweetAlert
                    title={t("confirm.completed.pharmacy.order")}
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText={t("Yes")}
                    cancelBtnText={t("No")}
                    onConfirm={() => {
                        onConfirm();
                    }}
                    onCancel={() => {
                        setShowConfirm(false);
                    }}
                ></SweetAlert>
                : null
            }

        </React.Fragment>
    );
}

DeliveryPackageDetail.propTypes = {
    order: PropTypes.object.isRequired
}

export default DeliveryPackageDetail;