import React from 'react';
import PropTypes from "prop-types";
import { Card, CardBody, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const MedicalCategoryCard = ({category, onSelect, selected}) => {

    const { t } = useTranslation();

    return ( 
        <React.Fragment>
            <Col sm={6} md={3}>
                <Card className='dashboard-option category-card' onClick={() => {onSelect(category)}}>
                    <CardBody className='text-center p-1'>

                        <i className={`mdi ${category.icon} font-size-48 text-primary`}></i>
                        
                        <div className="align-middle">
                            <div className="text-center">
                                <h5>{category.description}</h5>
                                <h6>{category.medicalServices.length} {t('available services')}</h6>
                            </div>
                            <strong className='font-size-24 float-end text-end '>
                                <i className={`text-primary mdi ${selected? 'mdi-check-bold' : 'bx bx-uncheck'}`}></i>
                            </strong>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
     );
}

MedicalCategoryCard.propTypes = {
    category: PropTypes.object,
    onSelect: PropTypes.func,
    selected: PropTypes.bool
};

export default MedicalCategoryCard;