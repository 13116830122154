import MembershipDetailCard from 'pages/Management/Commons/membership-card/membership-detail-card';
import React, { useContext } from 'react';
import { Row } from 'reactstrap';
import upgradeMembershipContext from '../context/upgradeMembershipContext';

const MembershipDetail = () => {

    const membershipContext = useContext(upgradeMembershipContext);
    const { membership, getCurrencySymbol } = membershipContext;

    return ( 
        <Row>
            {membership ? <MembershipDetailCard membership={membership} getCurrencySymbol={getCurrencySymbol}/>: null}
        </Row> 
    );
}
 
export default MembershipDetail;