import { ALERT_MESSAGE } from "constants/constants";
import { httpGetAffiliatedMembershipCancel, httpGetAffiliatedMembershipDebt } from "helpers/Api/api-helper";
import { call, put, takeEvery } from "redux-saga/effects";
import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions";
import { getAffiliatedMembershipCancelFail, getAffiliatedMembershipCancelSuccess, getAffiliatedMembershipDebtFail, getAffiliatedMembershipDebtSuccess } from "./actions";
import { GET_AFFILIATED_MEMBERSHIP_DEBT, GET_AFFILIATED_MEMBERSHIP_CANCEL } from "./actionTypes";

function* fetchAffiliatedMembershipDebt({request}) {
    try {
        yield put(showLoadingAlert());
        const response = yield call(httpGetAffiliatedMembershipDebt, request)
        yield put(hideLoadingAlert())
        yield put(getAffiliatedMembershipDebtSuccess(response))
    } catch (error) {
        yield put(hideLoadingAlert())
        yield put(showWarningAlert(ALERT_MESSAGE.GET_DETAIL_ERROR))
        yield put(getAffiliatedMembershipDebtFail(error))
    }
}

function* fetchAffiliatedMembershipCancel({request}) {
    try {
        yield put(showLoadingAlert());
        const response = yield call(httpGetAffiliatedMembershipCancel, request)
        yield put(hideLoadingAlert())
        yield put(getAffiliatedMembershipCancelSuccess(response))
    } catch (error) {
        yield put(hideLoadingAlert())
        yield put(showWarningAlert(ALERT_MESSAGE.GET_DETAIL_ERROR))
        yield put(getAffiliatedMembershipCancelFail(error))
    }
}

function* affiliatedMembershipCancellationSaga() {
    yield takeEvery(GET_AFFILIATED_MEMBERSHIP_DEBT, fetchAffiliatedMembershipDebt)
    yield takeEvery(GET_AFFILIATED_MEMBERSHIP_CANCEL, fetchAffiliatedMembershipCancel)
}

export default affiliatedMembershipCancellationSaga;