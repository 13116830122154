import DataTable from "components/Common/DataTable/datatable";
import PageBody from "components/Common/PageLayout/page-body";
import { ALERT_MESSAGE } from "constants/constants";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  Col,
  Row,
} from "reactstrap";
import {
  clearRole,
  getResources,
  getRoleDetail,
  saveRoleResources,
  showSuccessAlert,
} from "store/actions";

const RoleResourceList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const { id } = useParams();
  const [selectedRoleResources, setSelectedRoleResources] = useState([]);
  const [data, setData] = useState([]);

  const resources = useSelector(state => state.roles.resources);
  //const roleResources = useSelector(state => state.roles.roleResources);
  const role = useSelector(state => state.roles.roleDetail);
  const roleResourceCreated = useSelector(
    state => state.roles.roleResourceCreated
  );

  useEffect(() => {
    dispatch(getRoleDetail({ id: id }));
    dispatch(getResources());
    //dispatch(getRoleResources({ id: id }));
  }, []);

  useEffect(() => {
    if (roleResourceCreated) {
      dispatch(
        showSuccessAlert(ALERT_MESSAGE.UPDATED, () => {
          dispatch(clearRole());
          dispatch(getRoleDetail({ id: id }));
          dispatch(getResources());
          //dispatch(getRoleResources({ id: id }));
        })
      );
    }
  }, [roleResourceCreated]);

  useEffect(() => {
    if (role) {
      let data = role.roleResources.map(
        roleResource => roleResource.resource.id
      );
      setSelectedRoleResources(data);
    }
  }, [role]);

  useEffect(() => {
    if (resources) {
      setData(resources.map(current => ({
        id: current.id,
        scope: t(current.scope.code),
        code: current.code,
        description: t(current.description)
      })));
    }
  }, [resources]);

  const columns = [
    {
      text: t("Module"),
      dataField: "scope",
      sort: true,
      formatter: (cellContent, row) => (
        <React.Fragment>{row.scope}</React.Fragment>
      ),
    },
    {
      text: t("Description"),
      dataField: "description",
      sort: true,
      formatter: (cellContent, row) => (
        <React.Fragment>{row.description}</React.Fragment>
      ),
    },
    {
      text: t("Code"),
      dataField: "code",
      sort: true,
      formatter: (cellContent, row) => (
        <React.Fragment>{row.code}</React.Fragment>
      ),
    },
   
  ];

  const selectRow = {
    mode: "checkbox",
    selected: selectedRoleResources,
    onSelect: (row, isSelect, rowIndex, e) => {
      
      if (isSelect) {
        setSelectedRoleResources([...selectedRoleResources, row.id]);
      } else {
        const copy = [...selectedRoleResources];
        setSelectedRoleResources([
          ...copy.filter(current => current != row.id),
        ]);
      }
    },
    onSelectAll: (isSelect, rows, e) => {
  
      if (isSelect) {
        setSelectedRoleResources([...selectedRoleResources, rows]);
      } else {
        let copy = [...selectedRoleResources];
        rows.map(row => (copy = copy.filter(current => current != row.id)));
        setSelectedRoleResources([...copy]);
      }
    },
  };

  const defaultSorted = [
    {
      dataField: 'scope',
      order: 'asc'
    },
    {
      dataField: 'description', // if dataField is not match to any column you defined, it will be ignored.
      order: 'asc' // desc or asc
    }
  ];

  const save = () => {
    const request = {
      role: {
        id: role.id,
        tenantId: role.tenantId,
        code: role.code,
        realm: role.realm,
      },
      resources: selectedRoleResources.map(current => ({
        resourceId: current,
        roleId: role.id,
      })),
    };
    dispatch(saveRoleResources(request));
  };

  const getTitle = () => {
    return t("Permissions");
  }

  return (
    <React.Fragment>
      <PageBody
        title={getTitle()}
        actions={
          <React.Fragment>
            <Button
                color="primary"
                className="button"
                onClick={() => {
                  save();
                }}
              >
                <i className="mdi mdi-content-save me-1"></i>
                {t("Save")}
              </Button>
              <Button
                color="light"
                className="button mr-1"
                onClick={() => {
                  history.push(`/security-groups`);
                }}
              >
                <i className="mdi mdi-arrow-left me-1"></i>
                {t("Back")}
              </Button>
          </React.Fragment>
        }
      >
        <Row>
          <Col lg={12}>
            <DataTable
              title={t('Group') + ': ' + (role ? t(role.description) : '')}
              columns={columns}
              data={data}
              keyField={'id'}
              selectRow={selectRow}
              sort={defaultSorted}
            />
          </Col>
        </Row>
      </PageBody>
    </React.Fragment>
  );
};

export default RoleResourceList;

