import React from 'react';
import { useTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';

const PageTitle = () => {

    const { t } = useTranslation();
    
    return ( 
        <MetaTags>
            <title>{t('Title App')}</title>
        </MetaTags> 
    );
}
 
export default PageTitle;