export const planTypes = [
    {
        code: 'MEM',
        value: 'Membership'
    }, {
        code: 'OTH',
        value: 'Commercial Packages'
    }
];


export const ageRanges = [
    {
        code: 'ADU',
        value: 'Adult'
    }, {
        code: 'CHI',
        value: 'Children'
    }, {
        code: 'ALL',
        value: 'All'
    }
];

export const membershipStates = [
    {
        code: 'PSL',
        value: 'Public for Sales'
    }, {
        code: 'PNS',
        value: 'Public Not Sales'
    }, {
        code: 'DRF',
        value: 'Draft'
    },
    {
        code: 'ADD',
        value: 'Beneficieries Add'
    }    
];

export const discountTypes = [
    {
        code: 'FLA',
        value: 'Flat Amount'
    }, {
        code: 'PER',
        value: 'Percentage'
    }
];

export const mcIndicatorList = [
    {
        code: 'N',
        value: 'No'
    }, {
        code: 'Y',
        value: 'SI'
    }
];

export const mcTypeList = [
    {
        code: 'ALL',
        value: 'Todas las tarjetas'
    },
    {
        code: 'STD',
        value: 'Standard'
    },
    {
        code: 'GOL',
        value: 'Gold'
    },
    {
        code: 'PLA',
        value: 'Platinum'
    },
    {
        code: 'BLA',
        value: 'Black'
    },


    {
        code: 'CSG',
        value: 'Standard & Gold'
    },
    {
        code: 'CSP',
        value: 'Standard & Platinum'
    },
    {
        code: 'CSB',
        value: 'Standard & Black'
    },


    {
        code: 'CGP',
        value: 'Gold & Platinum'
    },
    {
        code: 'CGB',
        value: 'Gold & Black'
    },
    

    {
        code: 'CPB',
        value: 'Platinum & Black'
    }
];


export const discountTypesPromo = [
    {
        code: 'NOT',
        value: 'Do Not Apply'
    },
    {
        code: 'FLA',
        value: 'Flat Amount'
    }, {
        code: 'PER',
        value: 'Percentage'
    }
];

export const beneficiaryTypes = [
    {
        code: 'OWR',
        value: 'Type beneficiary Individual'
    }, {
        code: 'GRP',
        value: 'Type Beneficiary Group'
    }
];

export const benefitTypes = [
    {
        code: 'QTY',
        value: 'Visit Quantity'
    }, {
        code: 'DIS',
        value: 'Discount'
    }, 
    {
        code: 'TIM',
        value: 'Time'
    },
    {
        code: 'INF ',
        value: 'Type Benefit Informative'
    }
];

export const promoRedemptionTypes = [
    {
        code: 'INF ',
        value: 'Type Benefit Informative'
    },
    {
        code: 'DIS',
        value: 'Discount'
    }
];

export const businessUnitTypes = [
    {
        code: 'MED',
        value: 'Medical Service'
    }, /*{
        code: 'PHA',
        value: 'Pharmacy Purchase'
    },*/ {
        code: 'LAB',
        value: 'Laboratory'
    }
];

export const businessUnitTypesForPromos = [
    {
        code: 'MEM',
        value: 'Membership Promo'
    }, 
    {
        code: 'MED',
        value: 'Medical Service Promo'
    }, 
    {
        code: 'PHA',
        value: 'Pharmacy Promo'
    }, 
    {
        code: 'LAB',
        value: 'Laboratory Promo'
    }
];

export const restrictionLimitTypes = [
    {
        code: 'QTY',
        value: 'Quantity'
    }, {
        code: 'TIM',
        value: 'Time'
    }
];

export const currencyTypes = [
    {
        code: 'CRC',
        value: 'CRC'
    }, {
        code: 'USD',
        value: 'USD'
    }
];

export const consanguinityLevels = [
    {
        code: 'FAT',
        value: 'Padre'
    },
    {
        code: 'MOT',
        value: 'Madre'
    },
    {
        code: 'CHI',
        value: 'Hijo(a)'
    },
    {
        code: 'BRO',
        value: 'Hermano(a)'
    },
    {
        code: 'COU',
        value: 'Primo(a)'
    },
    {
        code: 'UNC',
        value: 'Tío(a)'
    },
    {
        code: 'NEP',
        value: 'Sobrino(a)'
    },
    {
        code: 'GRA',
        value: 'Abuelo(a)'
    },
    {
        code: 'GRA',
        value: 'Nieto(a)'
    },
    {
        code: 'OTH',
        value: 'Otro'
    }
];

export const questionValueType = [
    {
        code: 'VAL',
        value: 'Fixed Value'
    },
    {
        code: 'LST',
        value: 'List'
    }
];

export const questionRenderType = [
    {
        code: 'LST',
        value: 'List'
    },
    {
        code: 'RAD',
        value: 'Unique Radio'
    }
];

export const promotionTypes = [
    {
        code: 'INF',
        value: 'Informative'
    },
    {
        code: 'ITE',
        value: 'Items or Services'
    }, 

];

export const promotionActionTypes = [
    {
        code: 'DIS',
        value: 'Discount'
    }, {
        code: 'ITE',
        value: 'Items or Services'
    }
];

export const promotionSectionTypes = [
    {
        code: 'HOM',
        value: 'Lobby'
    }, {
        code: 'OFR',
        value: 'Offer Lobby'
    }
];

export const phoneMasks = [
    {
        areaCode: 506,
        phoneMask: '9999-9999'
    },
    {
        areaCode: 507,
        phoneMask: '999-9999'
    }
];



export const defaultRegistrations = [
    {
        code: false,
        value: 'Membresía normal'
    }, {
        code: true,
        value: 'Membresía Default'
    }
];

export const termDocumentType = [
    {
        code: "LIN",
        value: 'Enlace'
    }, 
    {
        code: "HTM",
        value: 'Texto HTML'
    }
];

export const DEFAULT_COMPANY = 1;
export const TERMS_MEMBERSHIP_TYPE = 'MEM';