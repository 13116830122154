import { ALERT_MESSAGE } from 'constants/constants';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { addRole, clearRole, showSuccessAlert } from 'store/actions';
import RoleForm from './role-form';

const AddRole = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const roleCreated = useSelector(state => state.roles.created);

    useEffect(() => {
        if(roleCreated) {
            dispatch(showSuccessAlert(ALERT_MESSAGE.SAVED, () => {
                dispatch(clearRole());
                history.push(`/security-groups`);
            }));
        }
        
    }, [roleCreated]);

    const onSubmit = (data) => {
        data.tenantId = 1;
        dispatch(addRole(data));
    }

    const onBack = () => {
        history.push(`/security-groups`);
    }

    return ( 
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t('Title App')}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h2 className="mb-2 font-size-18">{t('New User Group')}</h2>
                    </div>
                    
                    <Row>
                        <RoleForm 
                            onSubmit={onSubmit}
                            onBack={onBack}
                        />
                    </Row>
                </Container>
            </div> 
        </React.Fragment> 
    );
}
 
export default AddRole;