import { call, put, takeEvery } from "redux-saga/effects"

import {
  ADD_MEMBERSHIP,
  DELETE_MEMBERSHIP,
  GET_MEMBERSHIP_DETAIL,
  GET_MEMBERSHIP_LIST,
  UPDATE_MEMBERSHIP,
  GET_BENEFIT_LIST,
  GET_BENEFIT_DETAIL,
  ADD_BENEFIT,
  UPDATE_BENEFIT,
  DELETE_BENEFIT
} from "./actionTypes"

import {
  getMembershipsSuccess,
  getMembershipsFail,
  getMembershipDetailSuccess,
  getMembershipDetailFail,
  addMembershipSuccess,
  addMembershipFail,
  updateMembershipSuccess,
  updateMembershipFail,
  deleteMembershipSuccess,
  deleteMembershipFail,
  getBenefitsSuccess,
  getBenefitsFail,
  getBenefitDetailSuccess,
  getBenefitDetailFail,
  addBenefitSuccess,
  addBenefitFail,
  updateBenefitSuccess,
  updateBenefitFail,
  deleteBenefitSuccess,
  deleteBenefitFail
} from "./actions"

//API Calls helper
import {
  httpGetMemberships,
  httpGetMembershipDetail,
  httpAddMembership,
  httpUpdateMembership,
  httpDeleteMembership,
  httpGetBenefits,
  httpGetBenefitDetail,
  httpAddBenefit,
  httpUpdateBenefit,
  httpDeleteBenefit
} from "../../helpers/Api/api-helper"
import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions"
import { ALERT_MESSAGE } from "constants/constants"

function* getMemberships(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetMemberships, request.invoice)
    yield put(hideLoadingAlert())
    yield put(getMembershipsSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getMembershipsFail(error))
  }
}

function* getMembershipDetail({request}) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetMembershipDetail, request.id)
    yield put(hideLoadingAlert())
    yield put(getMembershipDetailSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_DETAIL_ERROR))
    yield put(getMembershipDetailFail(error))
  }
}

function* addMembership({request}) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.SAVING))
    const response = yield call(httpAddMembership, request)
    yield put(hideLoadingAlert())
    yield put(addMembershipSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.ADD_ERROR))
    yield put(addMembershipFail(error))
  }
}

function* updateMembership({request}) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.SAVING))
    const response = yield call(httpUpdateMembership, request)
    yield put(hideLoadingAlert())
    yield put(updateMembershipSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.UPDATE_ERROR))
    yield put(updateMembershipFail(error))
  }
}

function* deleteMembership({request}) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.DELETING))
    const response = yield call(httpDeleteMembership, request)
    yield put(hideLoadingAlert())
    yield put(deleteMembershipSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.DELETE_ERROR))
    yield put(deleteMembershipFail(error))
  }
}

function* getBenefits({request}) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetBenefits, request.membershipId)
    yield put(hideLoadingAlert())
    yield put(getBenefitsSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getBenefitsFail(error))
  }
}

function* getBenefitDetail({request}) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetBenefitDetail, request.id)
    yield put(hideLoadingAlert())
    yield put(getBenefitDetailSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_DETAIL_ERROR))
    yield put(getBenefitDetailFail(error))
  }
}

function* addBenefit({request}) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.SAVING))
    const response = yield call(httpAddBenefit, request)
    yield put(hideLoadingAlert())
    yield put(addBenefitSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.ADD_ERROR))
    yield put(addBenefitFail(error))
  }
}

function* updateBenefit({request}) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.SAVING))
    const response = yield call(httpUpdateBenefit, request)
    yield put(hideLoadingAlert())
    yield put(updateBenefitSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.UPDATE_ERROR))
    yield put(updateBenefitFail(error))
  }
}

function* deleteBenefit({request}) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.DELETING))
    const response = yield call(httpDeleteBenefit, request)
    yield put(hideLoadingAlert())
    yield put(deleteBenefitSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.DELETE_ERROR))
    yield put(deleteBenefitFail(error))
  }
}

function* membershipSaga() {
  yield takeEvery(GET_MEMBERSHIP_LIST, getMemberships)
  yield takeEvery(GET_MEMBERSHIP_DETAIL, getMembershipDetail)
  yield takeEvery(ADD_MEMBERSHIP, addMembership)
  yield takeEvery(UPDATE_MEMBERSHIP, updateMembership)
  yield takeEvery(DELETE_MEMBERSHIP, deleteMembership)
  yield takeEvery(GET_BENEFIT_LIST, getBenefits)
  yield takeEvery(GET_BENEFIT_DETAIL, getBenefitDetail)
  yield takeEvery(ADD_BENEFIT, addBenefit)
  yield takeEvery(UPDATE_BENEFIT, updateBenefit)
  yield takeEvery(DELETE_BENEFIT, deleteBenefit)
}

export default membershipSaga;
