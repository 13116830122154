import { ALERT_MESSAGE } from "constants/constants"
import { 
  httpAddRole,
  httpAddRoleResources,
  httpDeleteRole,
  httpGetRealms,
  httpGetResources,
  httpGetRoleDetail,
  httpGetRoleResources,
  httpGetRoles,
  httpUpdateRole
} from "helpers/Api/api-helper"
import { call, put, takeEvery } from "redux-saga/effects"
import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions"
import {
  getRolesSuccess,
  getRolesFail,
  getRoleDetailSuccess,
  getRoleDetailFail,
  addRoleSuccess,
  addRoleFail,
  updateRoleSuccess,
  updateRoleFail,
  deleteRoleSuccess,
  deleteRoleFail,
  getRealmsSuccess,
  getRealmsFail,
  getResourcesSuccess,
  getResourcesFail,
  getRoleResourcesSuccess,
  getRoleResourcesFail,
  saveRoleResourcesSuccess,
  saveRoleResourcesFail
} from "./actions"
import { 
  ADD_ROLE,
  DELETE_ROLE,
  GET_REALM_LIST,
  GET_ROLE_DETAIL,
  GET_ROLE_LIST,
  UPDATE_ROLE,
  GET_RESOURCE_LIST,
  GET_ROLE_RESOURCE_LIST,
  SAVE_ROLE_RESOURCE_LIST
 } from "./actionTypes"



function* getRoles(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetRoles, request.payload)
    yield put(hideLoadingAlert())
    yield put(getRolesSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getRolesFail(error))
  }
}

function* getRoleDetail(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetRoleDetail, request.payload)
    yield put(hideLoadingAlert())
    yield put(getRoleDetailSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_DETAIL_ERROR))
    yield put(getRoleDetailFail(error))
  }
}

function* addRole(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpAddRole, request.payload)
    yield put(hideLoadingAlert(ALERT_MESSAGE.SAVING))
    yield put(addRoleSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.ADD_ERROR))
    yield put(addRoleFail(error))
  }
}

function* updateRole(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpUpdateRole, request.payload)
    yield put(hideLoadingAlert(ALERT_MESSAGE.SAVING))
    yield put(updateRoleSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.UPDATE_ERROR))
    yield put(updateRoleFail(error))
  }
}

function* deleteRole(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpDeleteRole, request.payload)
    yield put(hideLoadingAlert(ALERT_MESSAGE.DELETING))
    yield put(deleteRoleSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.DELETE_ERROR))
    yield put(deleteRoleFail(error))
  }
}

function* getRealms(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetRealms, request.payload)
    yield put(hideLoadingAlert())
    yield put(getRealmsSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getRealmsFail(error))
  }
}

function* getResources(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetResources, request.payload)
    yield put(hideLoadingAlert())
    yield put(getResourcesSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getResourcesFail(error))
  }
}

function* getRoleResources(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetRoleResources, request.payload)
    yield put(hideLoadingAlert())
    yield put(getRoleResourcesSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getRoleResourcesFail(error))
  }
}

function* saveRoleResources(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpAddRoleResources, request.payload)
    yield put(hideLoadingAlert())
    yield put(saveRoleResourcesSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(saveRoleResourcesFail(error))
  }
}

function* rolesSaga() {
    yield takeEvery(GET_ROLE_LIST, getRoles)
    yield takeEvery(GET_ROLE_DETAIL, getRoleDetail)
    yield takeEvery(ADD_ROLE, addRole)
    yield takeEvery(UPDATE_ROLE, updateRole)
    yield takeEvery(DELETE_ROLE, deleteRole)
    yield takeEvery(GET_REALM_LIST, getRealms)
    yield takeEvery(GET_RESOURCE_LIST, getResources)
    yield takeEvery(GET_ROLE_RESOURCE_LIST, getRoleResources)
    yield takeEvery(SAVE_ROLE_RESOURCE_LIST, saveRoleResources)
  }
  
  export default rolesSaga