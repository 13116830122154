import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, Col, Label, Row } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormField from 'pages/Utility/form-field';
import { getValueFromDTO, formatBytes, toDateISOStringFormat } from 'helpers/utils';
import PrescriptionProducts from 'components/Common/Prescription/prescription-products';
import json from 'jsonwebtoken';

const PrescriptionTemplateForm = ({ onSubmit, onBack, template }) => {
    const { t } = useTranslation();
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone();
    const [products, setProducts] = useState(template ? template.prescriptionProducts : []);
    const [attachments, setAttachments] = useState(template ? template.prescriptionFiles : []);
    const [formData, setFormData] = useState({
        name: getValueFromDTO(template, 'patientObservation'),
        description: getValueFromDTO(template, 'doctorPrescription'),
        productsLength: 0
    });

    const { name, description, productsLength } = formData;

    const handleOnChange = event => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }

    const [accountCode, setAccountCode] = useState(null);

    useEffect(() => {

        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            const user = json.decode(obj.idToken);
            setAccountCode(user.sub);
        }
    }, []);

    useEffect(() => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            let copy = [...attachments];
            setAttachments(copy.concat(acceptedFiles));
        }
    }, [acceptedFiles]);

    useEffect(() => {
        setFormData({ ...formData, productsLength: products.length });
    }, [products]);

    const onDeleteAttachment = attachmentIndex => {
        setAttachments(attachments.filter((current, index) => index != attachmentIndex));
    }

    const prepareToSave = async () => {

        let data = {
            ...formData,
            accountCode: accountCode,
            helpIndicator: "N",
            patientObservation: formData.name,
            doctorPrescription: formData.description,
            prescriptionStatus: 'TEM',
            doctorFullname: '',
            doctorMedicalCode: '',
            doctorDiagnosticDate: toDateISOStringFormat(new Date())
        };

        if (attachments.length > 0) {
            data.prescriptionFiles = [];
            for (let index = 0; index < attachments.length; index++) {
                const file = attachments[index];
                const arrayBuffer = await file.arrayBuffer;
                data.prescriptionFiles.push({
                    id: file.id,
                    prescriptionId: file.prescriptionId,
                    type: file.name.substring(file.name.lastIndexOf('.')),
                    fileName: file.name.substring(0, file.name.lastIndexOf('.') - 1),
                    arrayBuffer: arrayBuffer
                });
            }
        }

        data.prescriptionProducts = products.map(product => ({
            ...product,
            id: product.id,
            productId: product.product.id,
            doctorPrescription: product.doctorPrescription,
            //currencyCode: product.currencyCode,
            //price: product.price,
            quantity: product.quantity,
            //taxPercentage: product.taxPercentage,
            /*product: {
                //id: product.id,
                name: product.name,
                price: product.price,
                prescription: product.prescription,
                taxPercentage: product.taxPercentage,
            }*/
        }));

        if (template) {
            data.id = template.id;
        }

        onSubmit(data);
    }

    return (
        <React.Fragment>
            <Row>
                <Col xl={{ size: 10, offset: 1 }}>
                    <Card>
                        <CardBody>
                            <Formik
                                enableReinitialize={true}
                                initialValues={formData}
                                validationSchema={
                                    Yup.object().shape({
                                        name: Yup.string().required().max(100),
                                        description: Yup.string().required().max(200),
                                        productsLength: Yup.number().required().min(1, t('You have to add at least one product'))
                                    })
                                }
                                onSubmit={(values, actions) => {
                                    actions.setSubmitting(false);
                                    prepareToSave();
                                }}
                            >
                                {({ errors, status, touched }) => (
                                    <Form className='needs-validation'>
                                        <Row>
                                            <Col md={6}>

                                                <FormField
                                                    key='name'
                                                    type='text'
                                                    maxLength={100}
                                                    fieldName='name'
                                                    fieldValue={name}
                                                    label={t('Name')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />

                                                <FormField
                                                    key='description'
                                                    type='textarea'
                                                    maxLength={200}
                                                    fieldName='description'
                                                    fieldValue={description}
                                                    label={t('Description')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                {/* attachments */}
                                                <div className='mb-3'>
                                                    <Label className="form-label">{t('Attach files')}</Label>
                                                    <p className='text-muted'>{t('You can attach any file you think is necessary')}</p>

                                                    <Row>
                                                        <Col>
                                                            <div
                                                                className="dz-message needsclick"
                                                                {...getRootProps({ className: 'dropzone custom-dropzone' })}
                                                            >
                                                                <input {...getInputProps()} />
                                                                <div className="mb-1 text-center">
                                                                    <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                </div>
                                                                <h4 className='text-center'>{t('Drop files here or click to upload.')}</h4>
                                                            </div>
                                                        </Col>

                                                    </Row>


                                                    {attachments.map((attachment, attachmentIndex) => (
                                                        <Row key={attachmentIndex}>
                                                            <Col xs={11} className="float-start pt-1">
                                                                <span>{attachment.name ? attachment.name : `${attachment.fileName}.${attachment.type}`}</span>
                                                            </Col>
                                                            <Col xs={1}>
                                                                <strong className='float-end text-end font-size-18'>
                                                                    <i className={'text-danger mdi mdi-close-thick dashboard-option'} onClick={() => { onDeleteAttachment(attachmentIndex) }}></i>
                                                                </strong>
                                                            </Col>

                                                        </Row>
                                                    ))}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12}>
                                                <h4>{t('Medicine to include in the prescription')}</h4>
                                                {/*Prescrption Products*/}
                                                <PrescriptionProducts
                                                    products={products}
                                                    setProducts={setProducts}
                                                />
                                                <FormField
                                                    key='productsLength'
                                                    type='hidden'
                                                    maxLength={200}
                                                    fieldName='productsLength'
                                                    fieldValue={productsLength}
                                                    label={t('')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />
                                            </Col>
                                        </Row>

                                        <div className="button-items">
                                            <Button type='submit' color="primary" className="button"><i className="mdi mdi-content-save me-1"></i>{t('Save')}</Button>
                                            <Button color="light" className="button mr-1" onClick={() => { onBack() }}><i className="mdi mdi-arrow-left me-1"></i>{t('Back')}</Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </React.Fragment>
    );
}

PrescriptionTemplateForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    template: PropTypes.any,
    onBack: PropTypes.func.isRequired
};

export default PrescriptionTemplateForm;