  import axios from "axios"
import { getAuthUser } from "helpers/utils"
  //import accessToken from "../jwt-token-access/accessToken"

  //pass new generated access token here
  //const token = accessToken

  //apply base url for axios
  const API_URL = ""

  const axiosApi = axios.create({
    baseURL: API_URL
  })

  const getBearerToken = () => {
    const authUser = getAuthUser();
    if (authUser) {
      const currentTime = new Date().getTime();
      const dateDiff = authUser.exp * 1000 - currentTime;
      
      if(dateDiff > 0) {
        const obj = JSON.parse(localStorage.getItem("authUser"))
        return 'Bearer ' + obj.idToken;
      } else {
        
        if(window.location.pathname !== '/login') {
          window.location.href = '/login';
        }
      }
      
    }
    return "1";
  }

  axiosApi.interceptors.response.use(
    response => response,
    (error) => {
      
      if (error.response.status === 401) {

          return window.location.href = '/login'
      }

      return Promise.reject(error);
    }
  )

  const getHeaders = () => { 
    return {
    'Content-Type': 'application/json',
    'X-Vitae-Country': 'CR',
    'Authorization': getBearerToken()
    }
  }

  export async function get(url, config = {}) {
    return await axiosApi.get(url, { headers: getHeaders(), ...config }).then(response => response.data);
  }

  export async function post(url, data, config = {}) {
    return axiosApi
      .post(url, { ...data }, { headers: getHeaders(), ...config })
      .then(response => response.data)
  }

  export async function put(url, data, config = {}) {
    return axiosApi
      .put(url, { ...data }, { headers: getHeaders(), ...config })
      .then(response => response.data)
  }

  export async function del(url, config = {}) {
    return await axiosApi
      .delete(url, { headers: getHeaders(), ...config })
      .then(response => response.data)
  }
