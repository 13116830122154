import {
  GET_DELIVERY_PACKAGE_LIST,
  GET_DELIVERY_PACKAGE_LIST_FAIL,
  GET_DELIVERY_PACKAGE_LIST_SUCCESS,
  GET_DELIVERY_PACKAGE_DETAIL,
  GET_DELIVERY_PACKAGE_DETAIL_FAIL,
  GET_DELIVERY_PACKAGE_DETAIL_SUCCESS,
  UPDATE_DELIVERY_PACKAGE_STATUS,
  UPDATE_DELIVERY_PACKAGE_STATUS_SUCCESS,
  UPDATE_DELIVERY_PACKAGE_STATUS_FAIL
} from "./actionTypes"

//Medical Center List
export const getDeliveryPackageList = (request) => ({
  type: GET_DELIVERY_PACKAGE_LIST,
  payload: request
})

export const getDeliveryPackageListSuccess = response => ({
  type: GET_DELIVERY_PACKAGE_LIST_SUCCESS,
  payload: response,
})

export const getDeliveryPackageListFail = error => ({
  type: GET_DELIVERY_PACKAGE_LIST_FAIL,
  payload: error,
})

export const getDeliveryPackageDetail = (request) => ({
  type: GET_DELIVERY_PACKAGE_DETAIL,
  payload: request
})

export const getDeliveryPackageDetailSuccess = response => ({
  type: GET_DELIVERY_PACKAGE_DETAIL_SUCCESS,
  payload: response,
})

export const getDeliveryPackageDetailFail = error => ({
  type: GET_DELIVERY_PACKAGE_DETAIL_FAIL,
  payload: error,
})

export const updateDeliveryPackageStatus = (request) =>({
  type : UPDATE_DELIVERY_PACKAGE_STATUS,
  payload: request
})

export const updateDeliveryPackageStatusSuccess = response =>({
  type: UPDATE_DELIVERY_PACKAGE_STATUS_SUCCESS,
  payload: response
})

export const updateDeliveryPackageStatusFail = error => ({
  type: UPDATE_DELIVERY_PACKAGE_STATUS_FAIL,
  payload: error
})
