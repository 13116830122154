import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import { Link, useHistory } from 'react-router-dom';
import DataTable from 'components/Common/DataTable/datatable';

const UserList = ({accounts, deleteRow}) => {

  const { t } = useTranslation();
  const history = useHistory();

  

  const columns = [
    {
      text: t("Email"),
      dataField: "email",
      sort: true,
      formatter: (cellContent, row) => (
        <React.Fragment>{row.email}</React.Fragment>
      ),
    },
    {
      text: t("Name"),
      dataField: "fullName",
      sort: true,
      formatter: (cellContent, row) => (
        <React.Fragment>
          {row.fullName}
        </React.Fragment>
      ),
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "",
      formatter: (cellContent, row) => (
        <div className="d-flex gap-3">
          {/*<Link className="text-success" to="#">
                <i
                  className="mdi mdi-pencil font-size-18"
                  id="edittooltip"
                  onClick={() => editRow(row)}
                ></i>
          </Link>*/}
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-lock-check font-size-18"
              id="resourcestooltip"
              onClick={() => viewGroups(row)}
            ></i>
          </Link>
              <Link className="text-danger" to="#">
                <i
                  className="mdi mdi-delete font-size-18"
                  id="deletetooltip"
                  onClick={() => deleteRow(row)}
                ></i>
          </Link>
        </div>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: "accountCode", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const editRow = row => {
    history.push(`/security-groups-edit/${row.accountCode}`);
  };

  const viewGroups = row => {
    history.push(`/security-user-groups/${row.accountCode}`);
  };

  return ( 
    <React.Fragment>
      <DataTable
        columns={columns}
        data={accounts}
        keyField='accountCode'
        sort={defaultSorted}
      />
    </React.Fragment> 
  );
}

UserList.propTypes = {
  accounts: PropTypes.array.isRequired,
  deleteRow: PropTypes.func
}
 
export default UserList;