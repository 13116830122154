import { ADD_PAYMENT_METHOD, ADD_PAYMENT_METHOD_FAIL, ADD_PAYMENT_METHOD_SUCCESS, CLEAR_PAYMENT_METHODS_STATE, 
         DELETE_PAYMENT_METHOD, 
         DELETE_PAYMENT_METHOD_FAIL, 
         DELETE_PAYMENT_METHOD_SUCCESS, 
         GET_PAYMENT_METHOD_DETAIL, 
         GET_PAYMENT_METHOD_DETAIL_FAIL, 
         GET_PAYMENT_METHOD_DETAIL_SUCCESS, 
         GET_PAYMENT_METHOD_LIST, 
         GET_PAYMENT_METHOD_LIST_FAIL, 
         GET_PAYMENT_METHOD_LIST_SUCCESS, 
         UPDATE_PAYMENT_METHOD,
         UPDATE_PAYMENT_METHOD_FAIL,
         UPDATE_PAYMENT_METHOD_SUCCESS} from "./actionTypes"

//PaymentMethods
export const clearPaymentMethod = () => ({
    type: CLEAR_PAYMENT_METHODS_STATE
  })

  export const getPaymentMethods = (request) => ({
    type: GET_PAYMENT_METHOD_LIST,
    payload: request
  })
  
  export const getPaymentMethodsSuccess = response => ({
    type: GET_PAYMENT_METHOD_LIST_SUCCESS,
    payload: response,
  })
  
  export const getPaymentMethodsFail = error => ({
    type: GET_PAYMENT_METHOD_LIST_FAIL,
    payload: error,
  })

  export const getPaymentMethodDetail = (request) => ({
    type: GET_PAYMENT_METHOD_DETAIL,
    payload: request
  })
  
  export const getPaymentMethodDetailSuccess = response => ({
    type: GET_PAYMENT_METHOD_DETAIL_SUCCESS,
    payload: response,
  })
  
  export const getPaymentMethodDetailFail = error => ({
    type: GET_PAYMENT_METHOD_DETAIL_FAIL,
    payload: error,
  })

  export const addPaymentMethod = (request) => ({
    type: ADD_PAYMENT_METHOD,
    payload: request
  })
  
  export const addPaymentMethodSuccess = response => ({
    type: ADD_PAYMENT_METHOD_SUCCESS,
    payload: response,
  })
  
  export const addPaymentMethodFail = error => ({
    type: ADD_PAYMENT_METHOD_FAIL,
    payload: error,
  })

  export const updatePaymentMethod = (request) => ({
    type: UPDATE_PAYMENT_METHOD,
    payload: request
  })
  
  export const updatePaymentMethodSuccess = response => ({
    type: UPDATE_PAYMENT_METHOD_SUCCESS,
    payload: response,
  })
  
  export const updatePaymentMethodFail = error => ({
    type: UPDATE_PAYMENT_METHOD_FAIL,
    payload: error,
  })

  export const deletePaymentMethod = (request) => ({
    type: DELETE_PAYMENT_METHOD,
    payload: request
  })
  
  export const deletePaymentMethodSuccess = response => ({
    type: DELETE_PAYMENT_METHOD_SUCCESS,
    payload: response,
  })
  
  export const deletePaymentMethodFail = error => ({
    type: DELETE_PAYMENT_METHOD_FAIL,
    payload: error,
  })
