import {
  SAVE_INVOICE,
  SAVE_INVOICE_FAIL,
  SAVE_INVOICE_SUCCESS,
  SAVE_INVOICE_DETAIL_DELIVERY,
  SAVE_INVOICE_DETAIL_DELIVERY_FAIL,
  SAVE_INVOICE_DETAIL_DELIVERY_SUCCESS
} from "./actionTypes"

//Invoice
export const saveInvoice = (invoice, history) => ({
  type: SAVE_INVOICE,
  payload: { invoice, history }
})

export const saveInvoiceSuccess = invoice => ({
  type: SAVE_INVOICE_SUCCESS,
  payload: invoice,
})

export const saveInvoiceFail = error => ({
  type: SAVE_INVOICE_FAIL,
  payload: error,
})

//Invoice Detail Delivery
export const saveInvoiceDetailDelivery = (delivery, history) => ({
  type: SAVE_INVOICE_DETAIL_DELIVERY,
  payload: { delivery, history }
})

export const saveInvoiceDetailDeliverySuccess = invoice => ({
  type: SAVE_INVOICE_DETAIL_DELIVERY_SUCCESS,
  payload: delivery,
})

export const saveInvoiceDetailDeliveryFail = error => ({
  type: SAVE_INVOICE_DETAIL_DELIVERY_FAIL,
  payload: error,
})

