import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import EmptyResult from "components/empty-result/empty-result";
import { useTranslation } from 'react-i18next';
import { Button, Container, Row, Col } from 'reactstrap';
import PageTitle from 'components/Common/PageLayout/page-title';
import PageHeader from 'components/Common/PageLayout/page-header';
import { getLaboratoryOrderDetail } from "store/actions";
import { useDispatch, useSelector } from 'react-redux';
import ExampPatientResultCard from "./exam-patient-result-card"


const ExamPatientResultByMedicalServicePage = () => {
  const { id, account, flag} = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();


  const order = useSelector(state => state.laboratoryOrders.orderDetail);

  useEffect(() => {
    if (id) {
      dispatch(getLaboratoryOrderDetail({ id: id }));
    }
  }, [id]);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };
  const [isHovering, setIsHovering] = useState(false);


  return (
    <React.Fragment>
      <div className="page-content">
        <PageTitle />
        <Container fluid>
          <PageHeader title={order ? t('Exams Historical Sub Exams') + order.laboratoryOrders[0].orderCode : t('Exams Historical Sub Exams')}>

            <Button
              color="light"
              className="button"
              onClick={() => { flag === "patientList" ? history.push("/medical-patients") : history.goBack() }}>
              <i className="mdi mdi-arrow-left me-1"></i>{t('Back')}
            </Button>

            {/* <Button
              color="light"
              className="button"
              onMouseOver={handleMouseOver}
              onMouseOut={handleMouseOut}>
              Codigo de colores
            </Button> */}
          </PageHeader>

          <Row>

            <div className="button-items d-flex flex-row-reverse">
              <h5 className="result-danger">
                {t("Abnormal Results")}
              </h5>
              <h5 className="result-normal">
                {t("Normal Results")}
              </h5>
            </div>

          </Row>

          {/* {isHovering && <Row className="mb-2 font-color-legend">
            <h6 >
              <span className="mdi mdi-solid icon-color-success"></span> {t("Normal Results")}
            </h6>
            <h6>
              <span className="mdi mdi-solid icon-color-danger"></span> {t("Abnormal Results")}
            </h6>
          </Row>} */}

          {order ? (
            <Row>
              {
                order.laboratoryOrders && order.laboratoryOrders.length > 0
                  ? order.laboratoryOrders.map(laboratoryOrder => (
                    laboratoryOrder.laboratoryOrderResults.length > 0
                      ? laboratoryOrder.laboratoryOrderResults.map(result => (
                        <ExampPatientResultCard
                          key={laboratoryOrder.id + '-' + result.id}
                          result={{ ...result, medicalService: result.medicalService, account }}
                          datetime={order.datetime}
                          resultId={result.id}
                          appointmentId={id}
                          account={account}
                        />
                      ))
                      : null
                  ))
                  : null
              }

              {
                order.laboratoryOrders && order.laboratoryOrders.length > 0
                  ? order.laboratoryOrders.map(laboratoryOrder => (
                    laboratoryOrder.laboratoryOrderStatus.map(status =>
                      status.completionStatus === 'PEN'
                        ? <ExampPatientResultCard
                          key={laboratoryOrder.id + '-' + status.id}
                          result={{ ...status, medicalService: status.medicalService, account }}
                          datetime={order.datetime}
                          resultId={status.id}
                          appointmentId={id}
                          account={account}
                        />
                        : null
                    )
                  ))
                  : null
              }
            </Row>
          ) : (
            <EmptyResult />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
}

export default ExamPatientResultByMedicalServicePage;
