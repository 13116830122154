/* PRODUCT SEARCH LIST */
export const CLEAR_PRODUCT_SEARCH_LIST = "CLEAR_PRODUCT_SEARCH_LIST";
export const GET_PRODUCT_SEARCH_LIST = "GET_PRODUCT_SEARCH_LIST";
export const GET_PRODUCT_SEARCH_LIST_FAIL = "GET_PRODUCT_SEARCH_LIST_FAIL";
export const GET_PRODUCT_SEARCH_LIST_SUCCESS =
  "GET_PRODUCT_SEARCH_LIST_SUCCESS";

/* PRODUCT SEARCH LSIT BY CATEGORY */
export const GET_PRODUCT_SEARCH_LIST_BY_CATEGORY =
  "GET_PRODUCT_SEARCH_LIST_BY_CATEGORY";
export const GET_PRODUCT_SEARCH_LIST_BY_CATEGORY_FAIL =
  "GET_PRODUCT_SEARCH_LIST_BY_CATEGORY_FAIL";
export const GET_PRODUCT_SEARCH_LIST_BY_CATEGORY_SUCCESS =
  "GET_PRODUCT_SEARCH_LIST_BY_CATEGORY_SUCCESS";
export const GET_PRODUCT_SEARCH_DETAIL = "GET_PRODUCT_SEARCH_DETAIL";
export const GET_PRODUCT_SEARCH_DETAIL_SUCCESS =
  "GET_PRODUCT_SEARCH_DETAIL_SUCCESS";
export const GET_PRODUCT_SEARCH_DETAIL_FAIL = "GET_PRODUCT_SEARCH_DETAIL_FAIL";
