/* DELIVERY PACKAGE LIST */
export const GET_DELIVERY_PACKAGE_LIST = "GET_DELIVERY_PACKAGE_LIST"
export const GET_DELIVERY_PACKAGE_LIST_FAIL = "GET_DELIVERY_PACKAGE_LIST_FAIL"
export const GET_DELIVERY_PACKAGE_LIST_SUCCESS = "GET_DELIVERY_PACKAGE_LIST_SUCCESS"

/* DELIVERY PACKAGE LIST */
export const GET_DELIVERY_PACKAGE_DETAIL = "GET_DELIVERY_PACKAGE_DETAIL"
export const GET_DELIVERY_PACKAGE_DETAIL_FAIL = "GET_DELIVERY_PACKAGE_DETAIL_FAIL"
export const GET_DELIVERY_PACKAGE_DETAIL_SUCCESS = "GET_DELIVERY_PACKAGE_DETAIL_SUCCESS"

/* UPDATE DELIVERY PACKAGE STATUS */

export const UPDATE_DELIVERY_PACKAGE_STATUS = "UPDATE_DELIVERY_PACKAGE_STATUS"
export const UPDATE_DELIVERY_PACKAGE_STATUS_SUCCESS = "UPDATE_DELIVERY_PACKAGE_STATUS_SUCCESS"
export const UPDATE_DELIVERY_PACKAGE_STATUS_FAIL = "UPDATE_DELIVERY_PACKAGE_STATUS_FAIL"
