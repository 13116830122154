/* MEMBERSHIPS */
export const CLEAR_MEMBERSHIP_STATE = "CLEAR_MEMBERSHIP_STATE" 

export const GET_MEMBERSHIP_LIST = "GET_MEMBERSHIP_LIST"
export const GET_MEMBERSHIP_LIST_FAIL = "GET_MEMBERSHIP_LIST_FAIL"
export const GET_MEMBERSHIP_LIST_SUCCESS = "GET_MEMBERSHIP_LIST_SUCCESS"

export const GET_MEMBERSHIP_DETAIL = "GET_MEMBERSHIP_DETAIL"
export const GET_MEMBERSHIP_DETAIL_FAIL = "GET_MEMBERSHIP_DETAIL_FAIL"
export const GET_MEMBERSHIP_DETAIL_SUCCESS = "GET_MEMBERSHIP_DETAIL_SUCCESS"

export const ADD_MEMBERSHIP = "ADD_MEMBERSHIP"
export const ADD_MEMBERSHIP_FAIL = "ADD_MEMBERSHIP_FAIL"
export const ADD_MEMBERSHIP_SUCCESS = "ADD_MEMBERSHIP_SUCCESS"

export const UPDATE_MEMBERSHIP = "UPDATE_MEMBERSHIP"
export const UPDATE_MEMBERSHIP_FAIL = "UPDATE_MEMBERSHIP_FAIL"
export const UPDATE_MEMBERSHIP_SUCCESS = "UPDATE_MEMBERSHIP_SUCCESS"

export const DELETE_MEMBERSHIP = "DELETE_MEMBERSHIP"
export const DELETE_MEMBERSHIP_FAIL = "DELETE_MEMBERSHIP_FAIL"
export const DELETE_MEMBERSHIP_SUCCESS = "DELETE_MEMBERSHIP_SUCCESS"

export const GET_BENEFIT_LIST = "GET_BENEFIT_LIST"
export const GET_BENEFIT_LIST_FAIL = "GET_BENEFIT_LIST_FAIL"
export const GET_BENEFIT_LIST_SUCCESS = "GET_BENEFIT_LIST_SUCCESS"

export const GET_BENEFIT_DETAIL = "GET_BENEFIT_DETAIL"
export const GET_BENEFIT_DETAIL_FAIL = "GET_BENEFIT_DETAIL_FAIL"
export const GET_BENEFIT_DETAIL_SUCCESS = "GET_BENEFIT_DETAIL_SUCCESS"

export const ADD_BENEFIT = "ADD_BENEFIT"
export const ADD_BENEFIT_FAIL = "ADD_BENEFIT_FAIL"
export const ADD_BENEFIT_SUCCESS = "ADD_BENEFIT_SUCCESS"

export const UPDATE_BENEFIT = "UPDATE_BENEFIT"
export const UPDATE_BENEFIT_FAIL = "UPDATE_BENEFIT_FAIL"
export const UPDATE_BENEFIT_SUCCESS = "UPDATE_BENEFIT_SUCCESS"

export const DELETE_BENEFIT = "DELETE_BENEFIT"
export const DELETE_BENEFIT_FAIL = "DELETE_BENEFIT_FAIL"
export const DELETE_BENEFIT_SUCCESS = "DELETE_BENEFIT_SUCCESS"
