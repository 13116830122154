import {
  GET_MEMBERSHIP_LIST,
  GET_MEMBERSHIP_LIST_FAIL,
  GET_MEMBERSHIP_LIST_SUCCESS,
  GET_MEMBERSHIP_DETAIL,
  GET_MEMBERSHIP_DETAIL_SUCCESS,
  GET_MEMBERSHIP_DETAIL_FAIL,
  ADD_MEMBERSHIP,
  ADD_MEMBERSHIP_SUCCESS,
  ADD_MEMBERSHIP_FAIL,
  UPDATE_MEMBERSHIP,
  UPDATE_MEMBERSHIP_SUCCESS,
  UPDATE_MEMBERSHIP_FAIL,
  DELETE_MEMBERSHIP,
  DELETE_MEMBERSHIP_SUCCESS,
  DELETE_MEMBERSHIP_FAIL,
  CLEAR_MEMBERSHIP_STATE,
  GET_BENEFIT_LIST,
  GET_BENEFIT_LIST_SUCCESS,
  GET_BENEFIT_LIST_FAIL,
  GET_BENEFIT_DETAIL,
  GET_BENEFIT_DETAIL_SUCCESS,
  GET_BENEFIT_DETAIL_FAIL,
  ADD_BENEFIT,
  ADD_BENEFIT_SUCCESS,
  ADD_BENEFIT_FAIL,
  UPDATE_BENEFIT,
  UPDATE_BENEFIT_SUCCESS,
  UPDATE_BENEFIT_FAIL,
  DELETE_BENEFIT,
  DELETE_BENEFIT_SUCCESS,
  DELETE_BENEFIT_FAIL
} from "./actionTypes"

//Memberships
export const clearMembership = () => ({
  type: CLEAR_MEMBERSHIP_STATE
})

export const getMemberships = () => ({
  type: GET_MEMBERSHIP_LIST
})

export const getMembershipsSuccess = invoice => ({
  type: GET_MEMBERSHIP_LIST_SUCCESS,
  payload: invoice,
})

export const getMembershipsFail = error => ({
  type: GET_MEMBERSHIP_LIST_FAIL,
  payload: error,
})

//Membership Detail
export const getMembershipDetail = request => ({
  type: GET_MEMBERSHIP_DETAIL,
  request
})

export const getMembershipDetailSuccess = membership => ({
  type: GET_MEMBERSHIP_DETAIL_SUCCESS,
  payload: membership,
})

export const getMembershipDetailFail = error => ({
  type: GET_MEMBERSHIP_DETAIL_FAIL,
  payload: error,
})

//Add Membership
export const addMembership = request => ({
  type: ADD_MEMBERSHIP,
  request
})

export const addMembershipSuccess = membership => ({
  type: ADD_MEMBERSHIP_SUCCESS,
  payload: membership,
})

export const addMembershipFail = error => ({
  type: ADD_MEMBERSHIP_FAIL,
  payload: error,
})

//Update Membership
export const updateMembership = request => ({
  type: UPDATE_MEMBERSHIP,
  request
})

export const updateMembershipSuccess = membership => ({
  type: UPDATE_MEMBERSHIP_SUCCESS,
  payload: membership,
})

export const updateMembershipFail = error => ({
  type: UPDATE_MEMBERSHIP_FAIL,
  payload: error,
})

//Delete Membership
export const deleteMembership = request => ({
  type: DELETE_MEMBERSHIP,
  request
})

export const deleteMembershipSuccess = membership => ({
  type: DELETE_MEMBERSHIP_SUCCESS,
  payload: membership,
})

export const deleteMembershipFail = error => ({
  type: DELETE_MEMBERSHIP_FAIL,
  payload: error,
})

export const getBenefits = request => ({
  type: GET_BENEFIT_LIST,
  request
})

export const getBenefitsSuccess = invoice => ({
  type: GET_BENEFIT_LIST_SUCCESS,
  payload: invoice,
})

export const getBenefitsFail = error => ({
  type: GET_BENEFIT_LIST_FAIL,
  payload: error,
})

//Benefit Detail
export const getBenefitDetail = request => ({
  type: GET_BENEFIT_DETAIL,
  request
})

export const getBenefitDetailSuccess = benefit => ({
  type: GET_BENEFIT_DETAIL_SUCCESS,
  payload: benefit,
})

export const getBenefitDetailFail = error => ({
  type: GET_BENEFIT_DETAIL_FAIL,
  payload: error,
})

//Add Benefit
export const addBenefit = request => ({
  type: ADD_BENEFIT,
  request
})

export const addBenefitSuccess = benefit => ({
  type: ADD_BENEFIT_SUCCESS,
  payload: benefit,
})

export const addBenefitFail = error => ({
  type: ADD_BENEFIT_FAIL,
  payload: error,
})

//Update Benefit
export const updateBenefit = request => ({
  type: UPDATE_BENEFIT,
  request
})

export const updateBenefitSuccess = benefit => ({
  type: UPDATE_BENEFIT_SUCCESS,
  payload: benefit,
})

export const updateBenefitFail = error => ({
  type: UPDATE_BENEFIT_FAIL,
  payload: error,
})

//Delete Benefit
export const deleteBenefit = request => ({
  type: DELETE_BENEFIT,
  request
})

export const deleteBenefitSuccess = benefit => ({
  type: DELETE_BENEFIT_SUCCESS,
  payload: benefit,
})

export const deleteBenefitFail = error => ({
  type: DELETE_BENEFIT_FAIL,
  payload: error,
})
