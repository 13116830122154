
export const checkOption = (option, rolePrincipals) => {
    if(option && rolePrincipals) {
        
        let found = false;
        let index = 0;
        let rolesLength = rolePrincipals.length;
        
        while(!found && index < rolesLength) {
            
            const rolePrincipal = rolePrincipals[index];
            const resources = rolePrincipal.role && rolePrincipal.role.roleResources ? rolePrincipal.role.roleResources : null;
            if(resources && resources.length > 0){
                const resource = resources.find(roleResource => 
                    roleResource.resource &&
                    roleResource.resource.code &&
                    roleResource.resource.code == option);
                
                if(resource) {
                    found = true;
                }
            }

            index++;
        }
        
        return found;
    } else {
        return false;
    }
}

export const checkModule = (module, moduleOptions, rolePrincipals) => {
    if(module && rolePrincipals) {
        
        let found = false;
        let index = 0;
        let rolesLength = rolePrincipals.length;
        
        while(!found && index < rolesLength) {
            for(let j=0; j < moduleOptions.length; j++){

                const optionFound =  checkOption(moduleOptions[j].id, rolePrincipals);
                if(optionFound){
                    found = true;
                    break;
                }
            }

            index++;
        }
        
        return found;
    } else {
        return false;
    }
}