import React, { useState } from "react";
import PropTypes from 'prop-types';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { toDateStringFormat, toHourStringFormat } from 'helpers/utils';
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch } from 'react-redux';
import { updateSamplePickupStatus } from 'store/actions';
import { useHistory } from "react-router-dom";




const SampleCollectDetail = ({ order }) => {

    const { t } = useTranslation();
    const {
        appointmentSamples
    } = order;

    const [showDelete, setShowDelete] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const history = useHistory();


    const dispatch = useDispatch();

    const {
        pickupPersonFullname, pickupPersonPhone,
        pickupAddress,
        pickupLatitude, pickupLongitude, pickupDate,
        pickupTimeFrom, pickupTimeTo, id, pickupStatus
    } = appointmentSamples[0];

    const onConfirm = () => {

        if (showConfirm) {
            dispatch(updateSamplePickupStatus({ id: id, pickupStatus: "FIN" }));
        } else {
            dispatch(updateSamplePickupStatus({ id: id, pickupStatus: "CAN" }));
        }
        setShowDelete(false);
        setShowConfirm(false);
        history.goBack();

    };

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Row>
                        <Col md={10}>
                            <Row>
                                <Col md={5}>
                                    <h5>{t('Address')}: {pickupAddress}</h5>
                                </Col>
                                <Col md={5}>
                                    <h5>{t('GPS')}: {pickupLatitude}  {pickupLongitude}</h5>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={5}>
                                    <h5>{t('Patient')}: {pickupPersonFullname}</h5>
                                </Col>
                                <Col md={5}>
                                    <h5>{t('Phone')}: {pickupPersonPhone}</h5>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={10}>
                                    <h5>{t('Date')}: {toDateStringFormat(pickupDate)}</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={10}>
                                    <h5>{t('Hour')}:</h5>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={5}>
                                    <h5>{t('From')}: {toHourStringFormat(pickupTimeFrom)}</h5>
                                </Col>
                                <Col md={5}>
                                    <h5>{t('To')}: {toHourStringFormat(pickupTimeTo)}</h5>
                                </Col>
                            </Row>
                        </Col>

                        {
                            pickupStatus === "PEN" ?
                                <Col md={2}>
                                    <Row>
                                        <Col me={2}>
                                            <Button type="button"
                                                className="btn btn-success btn-lg me-2"
                                                onClick={() => { setShowConfirm(true); }}
                                            >
                                                {t("Done")}
                                            </Button>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <br></br>
                                    </Row>
                                    <Row>
                                        <Col me={2}>
                                            <Button type="button"
                                                className="btn btn-danger btn-lg me-2"
                                                onClick={() => { setShowDelete(true); }}
                                            >
                                                {t("Cancel")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Col>
                                : null
                        }

                    </Row>
                </CardBody>
            </Card>

            {showDelete ?

                <SweetAlert
                    title={t("confirm.cancel.sample")}
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText={t("Yes")}
                    cancelBtnText={t("No")}
                    onConfirm={() => {
                        onConfirm();
                    }}
                    onCancel={() => {
                        setShowDelete(false);
                    }}
                ></SweetAlert>
                : null
            }

            {showConfirm ?
                <SweetAlert
                    title={t("confirm.completed.sample")}
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText={t("Yes")}
                    cancelBtnText={t("No")}
                    onConfirm={() => {
                        onConfirm();
                    }}
                    onCancel={() => {
                        setShowConfirm(false);
                    }}
                ></SweetAlert>
                : null
            }
        </React.Fragment>
    );
}

SampleCollectDetail.propTypes = {
    order: PropTypes.object.isRequired
}

export default SampleCollectDetail;