import { 
  ADD_PROMOTION, 
  ADD_PROMOTION_FAIL, 
  ADD_PROMOTION_SUCCESS, 
  CLEAR_PROMOTIONS_LIST_STATE, 
  DELETE_PROMOTION, 
  DELETE_PROMOTION_FAIL, 
  DELETE_PROMOTION_SUCCESS, 
  GET_PROMOTION_DETAIL, 
  GET_PROMOTION_DETAIL_FAIL, 
  GET_PROMOTION_DETAIL_SUCCESS, 
  GET_PROMOTIONS_LIST, 
  GET_PROMOTIONS_LIST_FAIL, 
  GET_PROMOTIONS_LIST_SUCCESS, 
  UPDATE_PROMOTION, 
  UPDATE_PROMOTION_FAIL, 
  UPDATE_PROMOTION_SUCCESS, 
  SEND_PROMOTION_NOTIFICATION,
  SEND_PROMOTION_NOTIFICATION_SUCCESS,
  SEND_PROMOTION_NOTIFICATION_FAIL
} from "./actionTypes"

//Promotions List
export const clearPromotionList = () => ({
    type: CLEAR_PROMOTIONS_LIST_STATE
  })
  
  export const getPromotionsList = request => ({
    type: GET_PROMOTIONS_LIST,
    payload: request
  })
  
  export const getPromotionsListSuccess = response => ({
    type: GET_PROMOTIONS_LIST_SUCCESS,
    payload: response,
  })
  
  export const getPromotionsListFail = error => ({
    type: GET_PROMOTIONS_LIST_FAIL,
    payload: error,
  })
  
  //Promotion Detail
  export const getPromotionDetail = request => ({
    type: GET_PROMOTION_DETAIL,
    payload: request
  })
  
  export const getPromotionDetailSuccess = response => ({
    type: GET_PROMOTION_DETAIL_SUCCESS,
    payload: response,
  })
  
  export const getPromotionDetailFail = error => ({
    type: GET_PROMOTION_DETAIL_FAIL,
    payload: error,
  })
  
  //Add Promotion
  export const addPromotion = request => ({
    type: ADD_PROMOTION,
    payload: request
  })
  
  export const addPromotionSuccess = response => ({
    type: ADD_PROMOTION_SUCCESS,
    payload: response,
  })
  
  export const addPromotionFail = error => ({
    type: ADD_PROMOTION_FAIL,
    payload: error,
  })
  
  //Update Promotion
  export const updatePromotion = request => ({
    type: UPDATE_PROMOTION,
    payload: request
  })
  
  export const updatePromotionSuccess = response => ({
    type: UPDATE_PROMOTION_SUCCESS,
    payload: response,
  })
  
  export const updatePromotionFail = error => ({
    type: UPDATE_PROMOTION_FAIL,
    payload: error,
  })
  
  //Delete Promotion
  export const deletePromotion = request => ({
    type: DELETE_PROMOTION,
    payload: request
  })
  
  export const deletePromotionSuccess = response => ({
    type: DELETE_PROMOTION_SUCCESS,
    payload: response,
  })
  
  export const deletePromotionFail = error => ({
    type: DELETE_PROMOTION_FAIL,
    payload: error,
  })

  //Send Promotion Notification
  export const sendPromotionNotification = request => ({
    type: SEND_PROMOTION_NOTIFICATION,
    payload: request
  })
  
  export const sendPromotionNotificationSuccess = response => ({
    type: SEND_PROMOTION_NOTIFICATION_SUCCESS,
    payload: response,
  })
  
  export const sendPromotionNotificationFail = error => ({
    type: SEND_PROMOTION_NOTIFICATION_FAIL,
    payload: error,
  })