import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_FEE_LIST,
  SAVE_FEE_LIST
} from "./actionTypes"

import {
  getFeeListSuccess,
  getFeeListFail,
  saveFeeListSuccess,
  saveFeeListFail
} from "./actions"

//API Calls helper
import {
  httpGetFeeLists,
  httpPostFeeList
} from "../../helpers/Api/api-helper"

import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions"
import { ALERT_MESSAGE } from "constants/constants"

function* fetchFeeList(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(httpGetFeeLists, request.payload)
    yield put(getFeeListSuccess(response))
    yield put(hideLoadingAlert())
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getFeeListFail(error))
  }
}

function* fetchSaveFeeList(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(httpPostFeeList, request.payload)
    yield put(saveFeeListSuccess(response))
    yield put(hideLoadingAlert())
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_DETAIL_ERROR))
    yield put(saveFeeListFail(error))
  }
}

function* feeListsSaga() {
  yield takeEvery(GET_FEE_LIST, fetchFeeList)
  yield takeEvery(SAVE_FEE_LIST, fetchSaveFeeList)
}

export default feeListsSaga;
