import React, { useState } from "react";
import { Button, Col, Row } from "reactstrap";
import json from 'jsonwebtoken';
// Redux
import { useDispatch, useSelector } from "react-redux";

// actions
import { getAccountDetail, getCatalogs, getIdentificationTypes,
         getBusinessUnits, getSubsidiaries, getCountries } from "../../store/actions";
import { DEFAULT_COMPANY } from "constants/list-types";
import CardProfile from "./card-profile";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import PageBody from "components/Common/PageLayout/page-body";

const UserProfilePage = () => {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();

  const accountDetail = useSelector(state => state.accounts.accountDetail);

  useState(() => {
    if (localStorage.getItem("authUser")) {

      const obj = JSON.parse(localStorage.getItem("authUser"));
      const user = json.decode(obj.idToken);
      
      dispatch(getCountries());
      dispatch(getAccountDetail({ accountCode: user.sub }));
      dispatch(getCatalogs());
      dispatch(getIdentificationTypes());
      dispatch(getBusinessUnits({ companyId: DEFAULT_COMPANY }));
      dispatch(getSubsidiaries({ companyId: DEFAULT_COMPANY }));
      
    }
  }, []);

  return (
    <React.Fragment>
      <PageBody
        title="Profile"
        actions={(
          <React.Fragment>
            <Button color="primary" className="button" onClick={() => { history.push(`/edit-profile`) }}><i className="bx bx-user me-1"></i>{t('Edit Profile')}</Button>
            <Button color="primary" className="button" onClick={() => { history.push(`/change-password`) }}><i className="mdi mdi-security me-1"></i>{t('Change Password')}</Button>
            <Button color="light" className="button mr-1" onClick={() => { history.push(`/dashboard`) }}><i className="mdi mdi-arrow-left me-1"></i>{t('Back')}</Button>
          </React.Fragment>
        )}
      >
        <Row>
          <Col xl={{ size: 6, offset: 3 }}>
            {accountDetail ? <CardProfile customerProfile={accountDetail} /> : null}
          </Col>
        </Row>
      </PageBody>
    </React.Fragment>
  );
}

export default UserProfilePage;
