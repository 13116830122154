import {
    AFFILIATED_REWARD_PROGRAM_BY_ACCOUNT, 
    AFFILIATED_REWARD_PROGRAM_BY_ACCOUNT_FAIL, 
    AFFILIATED_REWARD_PROGRAM_BY_ACCOUNT_SUCCESS } from "./actionTypes"

//Affiliated Reward Program
export const getAffiliatedRewardProgram = (request) => ({
    type: AFFILIATED_REWARD_PROGRAM_BY_ACCOUNT,
    payload: request
})

export const getAffiliatedRewardProgramSuccess = response => ({
    type: AFFILIATED_REWARD_PROGRAM_BY_ACCOUNT_SUCCESS,
    payload: response,
})

export const getAffiliatedRewardProgramFail = error => ({
    type: AFFILIATED_REWARD_PROGRAM_BY_ACCOUNT_FAIL,
    payload: error,
})