import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormField from 'pages/Utility/form-field';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
// Import Editor
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { getCatalogs } from 'store/actions';
import { convertToRaw, EditorState, ContentState,  } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { termDocumentType } from 'constants/list-types';

const TermForm = ({ onSubmit, term }) => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [editor, setEditor] = useState(() => EditorState.createEmpty());
    const [temporalMarkup, setTemporalMarkup] = useState(null);
    const termTypeList = useSelector(state => state.catalogs.termList);
    const [manualEditorChanged, setManualEditorChanged] = useState(false);

    useEffect(() => {
        dispatch(getCatalogs());
    }, []);

    const [formData, setFormData] = useState({
        type: term && term.type ? term.type : '',
        name: term && term.name ? term.name : '',
        documentType: term && term.documentType ? term.documentType : 'HTM',
        locationType: term && term.locationType ? term.locationType : 'APP',
        description: term && term.description ? (term && term.documentType === 'HTM' ? Buffer.from(term.description, "base64").toString() : term.description) : '',
        isDefault: term && term.isDefault == 'Y' ? true : false
    });

    const {
        type, name,
        description, isDefault, documentType } = formData;

    useEffect(() => {
        if (term && term.description) {

            //const html = Buffer.from(term.description, "base64").toString();
            const blocksFromHtml = htmlToDraft(term.description);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            const editorState = EditorState.createWithContent(contentState);
            setEditor(editorState);
        }
    }, [term]);

    const handleOnChange = event => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }

    const handleDocumentTypeOnChange = event => {
        let newFormData = {...formData};

        if(newFormData.documentType === 'HTM'){
            setTemporalMarkup(newFormData.description);
            newFormData.description = '';
        } else {
            newFormData.description = temporalMarkup;
        }
        setFormData({
            ...newFormData,
            documentType: event.target.value
        });
    }

    const handleCheckOnChange = event => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.checked
        });
    }

    const onEditorStateChange = (editorState) => {
        setManualEditorChanged(true);
        setEditor(editorState);
        const rawContent = convertToRaw(editorState.getCurrentContent());
        const markup = draftToHtml(
            rawContent
        );

        setFormData({
            ...formData,
            description: markup
        });
    }

    const updateDataFromEditorState = () => {
        if(formData.documentType === 'HTM'){
        const rawContent = convertToRaw(editor.getCurrentContent());
        const markup = draftToHtml(
            rawContent
        );

        setFormData({
            ...formData,
            description: markup
        });

        onSubmit({
            ...formData,
            description: markup
        });
        } else {
            onSubmit(formData);
        }
    }

    return (
        <React.Fragment>
            <Row>
                <Col xl={{ size: 10, offset: 1 }}>
                    <Card>
                        <CardBody>
                            {/* <CardTitle className="h5">Floating labels</CardTitle> */}
                            {/* <p className="card-title-desc">Create beautifully simple form labels that float over your input fields.</p> */}

                            <Formik
                                enableReinitialize={true}
                                initialValues={formData}
                                validationSchema={
                                    Yup.object().shape({
                                        type: Yup.string().required(),
                                        name: Yup.string().required(),
                                        description: Yup.string().required(),
                                        isDefault: Yup.string()
                                    })
                                }
                                onSubmit={(values, actions) => {
                                    actions.setSubmitting(false);
                                    if(manualEditorChanged){
                                    onSubmit(formData);
                                    } else {
                                        updateDataFromEditorState();                                       
                                    }
                                }}
                            >
                                {({ errors, status, touched }) => (
                                    <Form className='needs-validation' >
                                        <Row>
                                            <Col md={6}>

                                                <FormField
                                                    key='type'
                                                    type='select'
                                                    fieldName='type'
                                                    fieldValue={type}
                                                    label={t('Type')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                >
                                                    <option key="typeNotSelected" value="">{t('Not Selected')}</option>
                                                    {termTypeList.map(current => {
                                                        return (<option key={current.code} value={current.code}>{t(current.description)}</option>);
                                                    })}
                                                </FormField>

                                            </Col>
                                            <Col md={6}>

                                                <FormField
                                                    key='name'
                                                    type='text'
                                                    maxLength={500}
                                                    fieldName='name'
                                                    fieldValue={name}
                                                    label={t('Name')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>

                                            <Col md={6}>

                                                <FormField
                                                    key='documentType'
                                                    type='select'
                                                    fieldName='documentType'
                                                    fieldValue={documentType}
                                                    label={t('Document Type')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleDocumentTypeOnChange}
                                                >
                                                    {termDocumentType.map(current => {
                                                        return (<option key={current.code} value={current.code}>{current.value}</option>);
                                                    })}
                                                </FormField>

                                            </Col>

                                            <Col md={6}>

                                                <FormField
                                                    key='isDefault'
                                                    type='checkbox'
                                                    fieldName='isDefault'
                                                    fieldValue={isDefault}
                                                    label={t('Default')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleCheckOnChange}
                                                />

                                            </Col>
                                            <Col md={6}>
                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col md={12}>

                                            {formData.documentType === 'HTM' ? (
                                            <>
                                                <FormField
                                                    key='description'
                                                    type='hidden'
                                                    maxLength={2000}
                                                    fieldName='description'
                                                    fieldValue={description}
                                                    label={t('Description')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />
                                                <Editor
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName={(errors.description && touched.description) ? " is-invalid" : ""}
                                                    placeholder={t('Place Your Content Here')}
                                                    locale='es'
                                                    editorState={editor}
                                                    onEditorStateChange={onEditorStateChange}
                                                />
                                            </>) : (
                                                    <FormField
                                                        key='description'
                                                        type='text'
                                                        maxLength={512}
                                                        fieldName='description'
                                                        fieldValue={description}
                                                        label={t('Description')}
                                                        errors={errors}
                                                        touched={touched}
                                                        handleOnChange={handleOnChange}
                                                />
                                            )}


                                            </Col>
                                        </Row>

                                        <div className="button-items mt-3">
                                            <Button type='submit' color="primary" className="button"><i className="mdi mdi-content-save me-1"></i>{t('Save')}</Button>
                                            <Button color="light" className="button mr-1" onClick={() => { history.push('/terms-list') }}><i className="mdi mdi-arrow-left me-1"></i>{t('Back')}</Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
    );
}

TermForm.propTypes = {
    onSubmit: PropTypes.func,
    term: PropTypes.any
}

export default TermForm;