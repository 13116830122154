import { ADD_AFFILIATED_BENEFICIARY, ADD_AFFILIATED_BENEFICIARY_FAIL, ADD_AFFILIATED_BENEFICIARY_SUCCESS, CLEAR_AFFILIATED_BENEFICIARIES, DELETE_AFFILIATED_BENEFICIARY, DELETE_AFFILIATED_BENEFICIARY_FAIL, DELETE_AFFILIATED_BENEFICIARY_SUCCESS, GET_AFFILIATED_BENEFICIARIES, GET_AFFILIATED_BENEFICIARIES_FAIL, GET_AFFILIATED_BENEFICIARIES_SUCCESS, GET_AFFILIATED_BENEFICIARY, GET_AFFILIATED_BENEFICIARY_FAIL, GET_AFFILIATED_BENEFICIARY_SUCCESS, UPDATE_AFFILIATED_BENEFICIARY, UPDATE_AFFILIATED_BENEFICIARY_FAIL, UPDATE_AFFILIATED_BENEFICIARY_SUCCESS } from "./actionTypes"


const INIT_STATE = {
    beneficiaries: [],
    beneficiary: null,
    error: {},
    loading: false,
    beneficiaryCreated: false,
    beneficiaryUpdated: false,
    beneficiaryDeleted: false
}

const affiliatedBeneficiaries = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CLEAR_AFFILIATED_BENEFICIARIES:
            return {
                ...state,
                beneficiaries: [],
                beneficiary: null,
                error: {},
                loading: false,
                beneficiaryCreated: false,
                beneficiaryUpdated: false,
                beneficiaryDeleted: false
            }
        case GET_AFFILIATED_BENEFICIARIES:
            return {
                ...state,
                beneficiaries: [],
                loading: true,
                error: {}
            }
        case GET_AFFILIATED_BENEFICIARIES_SUCCESS:
            return {
                ...state,
                beneficiaries: action.payload,
            }  
        case GET_AFFILIATED_BENEFICIARIES_FAIL:
            return {
                ...state,
                error: action.payload,
            }
        case GET_AFFILIATED_BENEFICIARY:
            return {
                ...state,
                beneficiary: null,
                loading: true,
                error: {}
            }
        case GET_AFFILIATED_BENEFICIARY_SUCCESS:
            return {
                ...state,
                beneficiary: action.payload,
            }  
        case GET_AFFILIATED_BENEFICIARY_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case ADD_AFFILIATED_BENEFICIARY:
            return {
                ...state,
                beneficiaryCreated: false,
                loading: true,
                error: {}
            }
        case ADD_AFFILIATED_BENEFICIARY_SUCCESS:
            return {
                ...state,
                beneficiaryCreated: true,
            }  
        case ADD_AFFILIATED_BENEFICIARY_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case UPDATE_AFFILIATED_BENEFICIARY:
            return {
                ...state,
                beneficiaryUpdated: false,
                loading: true,
                error: {}
            }
        case UPDATE_AFFILIATED_BENEFICIARY_SUCCESS:
            return {
                ...state,
                beneficiaryUpdated: true,
            }  
        case UPDATE_AFFILIATED_BENEFICIARY_FAIL:
            return {
                ...state,
                error: action.payload,
            }

        case DELETE_AFFILIATED_BENEFICIARY:
            return {
                ...state,
                beneficiaryDeleted: false,
                loading: true,
                error: {}
            }
        case DELETE_AFFILIATED_BENEFICIARY_SUCCESS:
            return {
                ...state,
                beneficiaryDeleted: true,
            }  
        case DELETE_AFFILIATED_BENEFICIARY_FAIL:
            return {
                ...state,
                error: action.payload,
            }
            
        default:
            return state
    }
  }
  
export default affiliatedBeneficiaries;