import PageBody from 'components/Common/PageLayout/page-body';
import { ALERT_MESSAGE } from 'constants/constants';
import BeneficiaryForm from 'pages/Management/Commons/beneficiary-form/beneficiary-form';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Row } from 'reactstrap';
import { addAffiliatedBeneficiary, clearAffiliatedBeneficiaries, showSuccessAlert } from 'store/actions';

const AddBeneficiaryPage = () => {

    const { membershipAffiliatedId, account } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const beneficiaryCreated = useSelector(state => state.affiliatedBeneficiaries.beneficiaryCreated);

    useEffect(() => {
        if (beneficiaryCreated) {
            dispatch(showSuccessAlert(ALERT_MESSAGE.SAVED, () => {
                dispatch(clearAffiliatedBeneficiaries());
                history.push(`/membership-customer-beneficiaries/${account}/${membershipAffiliatedId}`);
            }));
        }

    }, [beneficiaryCreated]);

    const onSubmit = (formData) => {

        let data = {...formData};
        data.mainPhone = data.mainPhone.replaceAll(/\-/gi, '');
        data.secondaryPhone = data.secondaryPhone.replaceAll(/\-/gi, '');
        data.membershipAffiliatedId = membershipAffiliatedId;

        dispatch(addAffiliatedBeneficiary(data));
    }

    return (
        <React.Fragment>
            <PageBody
                title='New Beneficiary'
            >
                <Row>
                    <BeneficiaryForm
                        onSubmit={onSubmit}
                    />
                </Row>
            </PageBody>
        </React.Fragment>
    );
}

export default AddBeneficiaryPage;