import { call, put, takeEvery } from "redux-saga/effects"

import {
  SAVE_INVOICE,
  SAVE_INVOICE_FAIL,
  SAVE_INVOICE_SUCCESS,
  SAVE_INVOICE_DETAIL_DELIVERY
} from "./actionTypes"

import {
  saveInvoiceFail,
  saveInvoiceSuccess,
  saveInvoiceDetailDeliveryFail,
  saveInvoiceDetailDeliverySuccess
} from "./actions"

//API Calls helper
import {
  httpSaveInvoiceDetailDelivery  
} from "../../helpers/Api/api-helper"

//API Calls helper
import {
  httpSaveInvoice,
} from "../../helpers/Api/api-helper"

function* saveInvoice({ payload: { invoice, history } }) {
  try {
    const response = yield call(httpSaveInvoice, invoice)
    yield put(saveInvoiceSuccess(response))
    history.push("/membership-customer-profile/" + invoice.accountCode)
  } catch (error) {
    yield put(saveInvoiceFail(error))
  }
}

function* saveInvoiceDetailDelivery({ payload: { delivery, history } }) {
  try {
    const response = yield call(httpSaveInvoiceDetailDelivery, delivery)
    yield put(saveInvoiceDetailDeliverySuccess(response))    
  } catch (error) {
    yield put(saveInvoiceDetailDeliveryFail(error))
  }
}


function* affiliatedInvoicesSaga() {
  yield takeEvery(SAVE_INVOICE, saveInvoice)
  yield takeEvery(SAVE_INVOICE_DETAIL_DELIVERY, saveInvoiceDetailDelivery)
}

export default affiliatedInvoicesSaga
