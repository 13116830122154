import {
  CLEAR_LABORATORY_ORDER_HISTORICAL,
  GET_LABORATORY_ORDER_DETAIL,
  GET_LABORATORY_ORDER_DETAIL_FAIL,
  GET_LABORATORY_ORDER_DETAIL_SUCCESS,
  GET_LABORATORY_ORDER_HISTORICAL_LIST,
  GET_LABORATORY_ORDER_HISTORICAL_LIST_FAIL,
  GET_LABORATORY_ORDER_HISTORICAL_LIST_SUCCESS,
  SEND_LABORATORY_EXAM_RESULT,
  SEND_LABORATORY_EXAM_RESULT_FAIL,
  SEND_LABORATORY_EXAM_RESULT_SUCCESS
} from "./actionTypes"

export const clearLaboratoryOrderHistorical = () => ({
  type: CLEAR_LABORATORY_ORDER_HISTORICAL
})

//Laboratory Order Historical List
export const getLaboratoryOrderHistoricalList = (request) => ({
  type: GET_LABORATORY_ORDER_HISTORICAL_LIST,
  payload: request
})

export const getLaboratoryOrderHistoricalListSuccess = response => ({
  type: GET_LABORATORY_ORDER_HISTORICAL_LIST_SUCCESS,
  payload: response,
})

export const getLaboratoryOrderHistoricalListFail = error => ({
  type: GET_LABORATORY_ORDER_HISTORICAL_LIST_FAIL,
  payload: error,
})

//Laboratory Order Detail
export const getLaboratoryOrderDetail = (request) => ({
  type: GET_LABORATORY_ORDER_DETAIL,
  payload: request
})

export const getLaboratoryOrderDetailSuccess = response => ({
  type: GET_LABORATORY_ORDER_DETAIL_SUCCESS,
  payload: response,
})

export const getLaboratoryOrderDetailFail = error => ({
  type: GET_LABORATORY_ORDER_DETAIL_FAIL,
  payload: error,
})

//Send Laboratory Exam Result
export const sendLaboratoryExamResult = (request) => ({
  type: SEND_LABORATORY_EXAM_RESULT,
  payload: request
})

export const sendLaboratoryExamResultSuccess = response => ({
  type: SEND_LABORATORY_EXAM_RESULT_SUCCESS,
  payload: response,
})

export const sendLaboratoryExamResultFail = error => ({
  type: SEND_LABORATORY_EXAM_RESULT_FAIL,
  payload: error,
})