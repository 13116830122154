import {
  CLEAR_UPGRADE_MEMBERSHIP,
  CLEAR_GET_AFFILIATED,
  GET_AFFILIATED,
  GET_AFFILIATED_FAIL,
  GET_AFFILIATED_SUCCESS,
  GET_AFFILIATED_SEARCH,
  GET_AFFILIATED_SEARCH_FAIL,
  GET_AFFILIATED_SEARCH_SUCCESS,
  GET_AFFILIATED_ORDER_LIST,
  GET_AFFILIATED_ORDER_LIST_FAIL,
  GET_AFFILIATED_ORDER_LIST_SUCCESS,
  GET_AFFILIATED_MEMBERSHIP_LIST,
  GET_AFFILIATED_MEMBERSHIP_LIST_SUCCESS,
  GET_AFFILIATED_MEMBERSHIP_LIST_FAIL,
  GET_AFFILIATED_MEMBERSHIP_DETAIL,
  GET_AFFILIATED_MEMBERSHIP_DETAIL_SUCCESS,
  GET_AFFILIATED_MEMBERSHIP_DETAIL_FAIL,
  UPGRADE_MEMBERSHIP,
  UPGRADE_MEMBERSHIP_SUCCESS,
  UPGRADE_MEMBERSHIP_FAIL,
  GET_ALL_ORDER_LIST,
  GET_ALL_ORDER_LIST_SUCCESS,
  GET_ALL_ORDER_LIST_FAIL,
  GET_ORDER,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAIL,
  GET_CRITERIA,
  GET_KEY,
  GET_CURRENT_URL,

  GET_ALL_PURCHASE_TRANSACTIONS_SUCCESS,
  GET_ALL_PURCHASE_TRANSACTIONS_FAIL
} from "./actionTypes"

const INIT_STATE = {
  affiliatedList: [],
  affiliated: {},
  affiliatedOrderList: [],
  allOrderList: [],
  order: null,
  affiliatedMembershipList: [],
  affiliatedMembershipDetail: null,
  error: {},
  upgradeCreated: false,
  criteria: null,
  key: null,
  url: null,
  purchaseTransactions: [],
}

const affiliateds = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_GET_AFFILIATED:
      return {
        ...state,
        affiliatedList: [],
        affiliated: {},
        affiliatedOrderList: [],
        order: null,
        affiliatedMembershipList: [],
        affiliatedMembershipDetail: null,
        error: {}
      }
    case CLEAR_UPGRADE_MEMBERSHIP:
      return {
        ...state,
        upgradeCreated: false
      }
    case GET_AFFILIATED:
      return {
        ...state,
        affiliated: {},
        loading: true,
      }
    case GET_AFFILIATED_SUCCESS:
      return {
        ...state,
        affiliated: action.payload,
        loading: false,
      }
    case GET_AFFILIATED_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_AFFILIATED_SEARCH:
      return {
        ...state,
        affiliatedList: [],
        loading: true,
      }
    case GET_AFFILIATED_SEARCH_SUCCESS:
      return {
        ...state,
        affiliatedList: action.payload,
        loading: false,
      }

    case GET_AFFILIATED_SEARCH_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_AFFILIATED_ORDER_LIST:
      return {
        ...state,
        affiliatedOrderList: [],
        loading: true,
      }
    case GET_AFFILIATED_ORDER_LIST_SUCCESS:
      return {
        ...state,
        affiliatedOrderList: action.payload,
        loading: false,
      }

    case GET_AFFILIATED_ORDER_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_ALL_ORDER_LIST:
      return {
        ...state,
        allOrderList: [],
        loading: true,
      }
    case GET_ALL_ORDER_LIST_SUCCESS:
      return {
        ...state,
        allOrderList: action.payload,
        loading: false,
      }

    case GET_ALL_ORDER_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_ORDER:
      return {
        ...state,
        order: null,
        loading: true,
      }
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        order: action.payload,
        loading: false,
      }

    case GET_ORDER_FAIL:
      return {
        ...state,
        error: action.payload,
        loading: false,
      }

    case GET_AFFILIATED_MEMBERSHIP_LIST:
      return {
        ...state,
        affiliatedMembershipList: [],
        loading: true,
      }
    case GET_AFFILIATED_MEMBERSHIP_LIST_SUCCESS:
      return {
        ...state,
        affiliatedMembershipList: action.payload,
      }

    case GET_AFFILIATED_MEMBERSHIP_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_AFFILIATED_MEMBERSHIP_DETAIL:
      return {
        ...state,
        affiliatedMembershipDetail: null,
        loading: true,
      }
    case GET_AFFILIATED_MEMBERSHIP_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        affiliatedMembershipDetail: action.payload,
      }

    case GET_AFFILIATED_MEMBERSHIP_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

      case GET_ALL_PURCHASE_TRANSACTIONS_SUCCESS:
        return {
          ...state,
          loading: false,
          purchaseTransactions: action.payload,
        }
  
      case GET_ALL_PURCHASE_TRANSACTIONS_FAIL:
        return {
          ...state,
          loading: false,
          error: action.payload,
        }

    case UPGRADE_MEMBERSHIP:
      return {
        ...state,
        loading: true,
        upgradeCreated: false,
      }
    case UPGRADE_MEMBERSHIP_SUCCESS:
      return {
        ...state,
        loading: false,
        upgradeCreated: true,
      }
    case UPGRADE_MEMBERSHIP_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      }
    case GET_CRITERIA:
      return{
        ...state,
        loading : false,
        criteria: action.payload
      }
    case GET_KEY:
      return{
        ...state,
        loading : false,
        key: action.payload
      }
    case GET_CURRENT_URL:
      return{
        ...state,
        loading : false,
        url: action.payload
      }
    default:
      return state
  }
}

export default affiliateds
