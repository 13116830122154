import React from 'react';
import PropTypes from 'prop-types';
import { Col, Label, Row } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

const PrescriptionFiles = ({ attachments, setAttachments }) => {
    
    const { t } = useTranslation();
    const { getRootProps, getInputProps } = useDropzone({
        //maxFiles: 1,
        accept: ['image/png', 'image/jpg', 'application/pdf'],
        onDrop: (acceptedFiles) => {
          acceptedFiles.forEach((file) => {
            const reader = new FileReader()
    
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = (evt) => {
              const fileByteArray = [];
              // Do whatever you want with the file contents
              const arrayBuffer = evt.target.result,
                array = new Uint8Array(arrayBuffer);
              for (const a of array) {
                fileByteArray.push(a);
              }
    
              setAttachments([
                ...attachments,
                {
                  type: file.name.substring(file.name.lastIndexOf('.') + 1),
                  fileName: file.name.substring(0, file.name.lastIndexOf('.')),
                  file: fileByteArray
                }
              ]);
            }
            reader.readAsArrayBuffer(file)
          })
        }
      });

    const onDeleteAttachment = attachmentIndex => {
        setAttachments(
            attachments.filter((current, index) => index != attachmentIndex)
        );
    };

    return (
        <div className="mb-3">
            <Label className="form-label">
                {t("Attach files")}
            </Label>
            <p className="text-muted">
                {t(
                    "You can attach any file you think is necessary"
                )}
            </p>

            <Row>
                <Col>
                    <div
                        className="dz-message needsclick"
                        {...getRootProps({
                            className: "dropzone custom-dropzone",
                        })}
                    >
                        <input {...getInputProps()} />
                        <div className="mb-1 text-center">
                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                        </div>
                        <h4 className="text-center">
                            {t("Drop files here or click to upload.")}
                        </h4>
                    </div>
                </Col>
            </Row>

            {attachments.map((attachment, attachmentIndex) => (
                <Row key={attachmentIndex}>
                    <Col xs={11} className="float-start pt-1">
                        <span>{attachment.fileName}.{attachment.type}</span>
                    </Col>
                    <Col xs={1}>
                        <strong className="float-end text-end font-size-18">
                            <i
                                className={
                                    "text-danger mdi mdi-close-thick dashboard-option"
                                }
                                onClick={() => {
                                    onDeleteAttachment(attachmentIndex);
                                }}
                            ></i>
                        </strong>
                    </Col>
                </Row>
            ))}
        </div>
    );
}


PrescriptionFiles.propTypes = {
    attachments: PropTypes.array.isRequired,
    setAttachments: PropTypes.func.isRequired
}

export default PrescriptionFiles;