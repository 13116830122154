import React, { Component } from "react"
import PropTypes from "prop-types";
import { Link } from "react-router-dom"
import { connect } from "react-redux";
import MetaTags from 'react-meta-tags';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Row,
  Table,
  Form,
  Modal,
  ModalHeader,
  ModalBody  
} from "reactstrap"
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import * as moment from "moment";
//Import Breadcrumb
import Breadcrumbs from "../../../../components/Common/Breadcrumb"
import { saveInvoice } from "store/AffiliatedInvoice/actions"
import { getECommerceServices } from "store/ECommerce/actions"

class CustomerAddInvoice extends Component {
  
  constructor(props) {
    super(props)
    this.state = {
      selectedFiles: [],
      invoiceDateField: '',
      invoiceObsField: '',
      invoiceCurrencyField: 'CRC',
      invoiceSubTotalField: 0.00,
      invoiceTaxField: 0.00,
      invoiceTotalField: 0.00,
      modal: false,
      invoice: {},
      invoiceDetailList: [],
      tableColumns: [
        {
          dataField: "reference",
          text: "Referencia",
          sort: true,
        },
        {
          dataField: "description",
          text: "Descripción",
          sort: true,
        },      
        {
          dataField: "view",
          isDummyField: true,
          text: "",
          sort: true,
          formatter: (cellContent, row) => (
              <Button
                type="button"
                color="primary"
                className="btn-sm btn-rounded"
                onClick={() => {this.addService(row)}}
              >
                Seleccionar
              </Button>              
          ),
        }
      ],      
    }
    this.toggle = this.toggle.bind(this);    
  }

  componentDidMount() {
    this.setState({ invoiceDateField: moment(new Date()).format("DD/MM/yyyy")});
  }

  handleBack = () => {
    this.props.history.goBack();
  }

  keyPress = e => {
    const { key } = e;
    if (key === "Enter") {     
      this.toggle();
      const { onGetSearchServices } = this.props; 
      onGetSearchServices({code: e.target.value, description: ''});
    }
  };

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  addService(data) {
    this.toggle();
    let invoiceDetailList = this.state.invoiceDetailList;
    if(!invoiceDetailList) invoiceDetailList = [];

    var foundItem = invoiceDetailList.find(obj => {
      return obj.code === data.code;
    })

    if(!foundItem) {
      invoiceDetailList.push(data);
      this.setState({ invoiceDetailList: invoiceDetailList});  
      this.updateTotals()
    }
  }

  removeService(data) {
    let invoiceDetailList = this.state.invoiceDetailList;
    if(!invoiceDetailList) invoiceDetailList = [];

    var foundIndex = invoiceDetailList.findIndex(obj => {
      return obj.code === data.code;
    })
    if (foundIndex > -1) {
      invoiceDetailList.splice(foundIndex, 1);
    }    
    this.setState({ invoiceDetailList: invoiceDetailList});
    this.updateTotals()
  }

  updateTotals(){
    let invoiceDetailList = this.state.invoiceDetailList;
    let subtotal = 0;
    let totaltax = 0;
    let total = 0;
    invoiceDetailList.forEach(detail => {
      subtotal += detail.price;
      const unittax = (detail.price * detail.taxRate) / 100;
      totaltax += unittax;
    })

    total = subtotal + totaltax;

    this.setState({ 
        invoiceSubTotalField: subtotal, 
        invoiceTaxField: totaltax, 
        invoiceTotalField: total});
  }

  addNewInvoice = () => {
    const { customerProfile } = this.props;
    const invoiceDetailList = this.state.invoiceDetailList;
    const invoice = {
      countryCode: 'CR',
      accountCode: customerProfile.account?.code,
      currencyCode: "CRC",
      observations: this.state.invoiceObsField,
      subTotalAmount: this.state.invoiceSubTotalField,
      taxAmount: this.state.invoiceTaxField,
      totalAmount: this.state.invoiceTotalField,
      taxPercentage: 0
    };

    let details = [];
    invoiceDetailList.forEach(detail => {
      details.push({
          itemCode: detail.code,
          description: detail.description,
          quantity: 1,
          unitPrice: detail.price,
      });      
    })

    invoice.detailList = details;

    this.setState({ invoice: invoice});

    const { onAddNewInvoice } = this.props;
    onAddNewInvoice(invoice, this.props.history);
  };  

  render() {    
    const { customerProfile, serviceList } = this.props;

    const pageOptions = {
      sizePerPage: 10,
      totalSize: serviceList.length,
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: "orderId",
        order: "desc",
      },
    ];

    const selectRow = {
      mode: "checkbox",
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Vitae Admin Portal</title>
          </MetaTags>
          <Container fluid>

            <Modal
              isOpen={this.state.modal}
              size="lg"
            >
              <ModalHeader
                className="regular-modal"
                toggle={this.toggle}
                tag="h4"
              >
                  {"Seleccionar servicio"}
              </ModalHeader>
              <ModalBody>
                  <PaginationProvider
                      pagination={paginationFactory(pageOptions || [])}
                      keyField="id"
                      columns={this.state.tableColumns || []}
                      data={serviceList || []}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={serviceList}
                          columns={this.state.tableColumns || []}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <div className="table-responsive">
                                <BootstrapTable
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  responsive
                                  defaultSorted={defaultSorted}
                                  bordered={false}
                                  striped={false}
                                  selectRow={selectRow}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  ref={this.node}
                                />
                              </div>
                              <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
              </ModalBody>
            </Modal>

            <Row>
                <Col xl="9">
                <div className="mb-2"><Link to="/membership-customer-search" className="text-body fw-bold">Buscar</Link><i className="mdi mdi-chevron-right" /><Link to={"/membership-customer-profile/" + customerProfile.account?.code} className="text-body fw-bold">Afiliado</Link><i className="mdi mdi-chevron-right" />Factura</div>
                <h2 className="mb-2 font-size-18">{customerProfile.account?.firstName} {customerProfile.account?.secondName} {customerProfile.account?.fistSurname} {customerProfile.account?.secondSurname}</h2>              
                </Col>
                <Col xl="3" className="mb-2 align-right">
                    <Button
                        type="button"
                        color="secondary"                      
                        onClick={this.handleBack}
                      >
                        Regresar
                    </Button>                
                </Col>
              </Row>


                <Row>
                  <Col xs="12">                

                    <Card>
                      <CardBody>
                        <CardTitle className="h4">NUEVA FACTURA</CardTitle>
                        <p className="card-title-desc">Datos generales de la factura</p>
                        <Form>
                            <Row>
                              <Col sm="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="productname">Fecha</Label>
                                  <Input
                                    disabled="disabled"
                                    id="date"
                                    name="date"
                                    type="text"
                                    value={this.state.invoiceDateField}
                                    className="form-control"
                                  />
                                </FormGroup>
                                <FormGroup className="mb-3">
                                  <Label className="control-label">Moneda</Label>
                                  <select 
                                    className="form-control select2" 
                                    disabled="disabled"
                                    name="currencyCode"
                                    onChange={event => this.setState({ invoiceCurrencyField: event.target.value})}>
                                    <option>Seleccionar </option>
                                    <option value="CRC" selected>CRC - Colones Costa Rica</option>
                                    <option value="USD">USD Dolares Americano</option>
                                  </select>
                                </FormGroup>
                              </Col>

                              <Col sm="6">
                                <FormGroup className="mb-3">
                                  <Label htmlFor="productdesc">
                                    Observaciones
                                  </Label>
                                  <textarea
                                    onChange={event => this.setState({ invoiceObsField: event.target.value})}
                                    className="form-control"
                                    id="observation"
                                    name="observation"
                                    rows="5"
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                        </Form>
                          
                      </CardBody>
                    </Card>               

                    <Card>
                      <CardBody>

                        <Row>
                          <Col sm={12}>
                              <div className="search-box py-4">
                                <div className="position-relative">
                                  <Input
                                    autoComplete="off"
                                    onKeyUp={this.keyPress}
                                    id="search-user"
                                    type="text"
                                    className="form-control"
                                    placeholder="Digite la palabra y presione Enter para buscar servicio..."
                                  />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                          </Col>
                        </Row>

                        <Row>                        
                          <Col sm={12}>
                            <Table className="table mb-0">
                              <thead>
                                <tr>
                                  <th>Cod.</th>
                                  <th>Descripci&oacute;n</th>
                                  <th>Cantidad</th>
                                  <th>Precio unitario</th>
                                  <th>Impuesto unitario</th>
                                  <th>Total unitario</th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                              {!this.state.invoiceDetailList || this.state.invoiceDetailList.length === 0 ? (
                                    <tr>
                                      <td colSpan={12}>No ha incluido servicios</td>
                                    </tr>
                                ) : <b>&nbsp;</b> }                                
                              {this.state.invoiceDetailList && this.state.invoiceDetailList.map((detail, i) => (
                                    <tr key={'detail ' + i}>
                                      <td>{detail.reference}</td>
                                      <td>{detail.description}</td>
                                      <td>1</td>
                                      <td>{detail.price.toFixed(2)}</td>
                                      <td>{(detail.price * detail.taxRate / 100).toFixed(2)}</td>
                                      <td>{(detail.price + (detail.price * detail.taxRate / 100)).toFixed(2)}</td>
                                      <td>
                                        <Button
                                          type="submit"
                                          color="danger"
                                          className="me-1"
                                          onClick={() => {this.removeService(detail)}}
                                        >
                                          Remover
                                        </Button>                                          
                                      </td>
                                    </tr>
                                ))}
                              </tbody>
                            </Table>
                          </Col>                         
                        </Row>                    
                      </CardBody>
                    </Card>

                    <Card>
                      <CardBody>
                            <Row>
                              <Col sm="6">
                        
                              </Col>
                              <Col sm="6">
                                  <Table className="table mb-0">
                                    <thead>
                                      <tr>
                                        <th>Subtotal</th>
                                        <th>Total impuesto</th>
                                        <th>Total</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                          <td>{this.state.invoiceSubTotalField.toFixed(2)}</td>
                                          <td>{this.state.invoiceTaxField.toFixed(2)}</td>
                                          <td>{this.state.invoiceTotalField.toFixed(2)}</td>
                                        </tr>                                    
                                    </tbody>
                                  </Table>     
                              </Col>
                            </Row>

                      </CardBody>
                    </Card>

                  </Col>
                </Row>

                <Row>
                  <Col sm={12}>
                    <Button
                        color="primary"
                        className="me-1"
                        type="button"
                        onClick={this.addNewInvoice}
                      >
                        Guardar
                      </Button>{" "}
                      <Button
                        type="submit"
                        color="secondary"
                        onClick={this.handleBack}
                      >
                        Cancelar
                    </Button>
                  </Col>
                </Row>
                      
          </Container>
        </div>
      </React.Fragment>
    )
  }

}

CustomerAddInvoice.propTypes = {
  customerProfile: PropTypes.object,
  onGetSearchServices: PropTypes.func,
  serviceList: PropTypes.array,
  invoiceDetailList: PropTypes.array,
  onAddNewInvoice: PropTypes.func,
  history: PropTypes.any,
  t: PropTypes.any
};

const mapStateToProps = ({ ecommerce, affiliateds }) => ({
  customerProfile: affiliateds.affiliated,
  serviceList: ecommerce?.serviceList,
})

const mapDispatchToProps = dispatch => ({
  onGetSearchServices: search => dispatch(getECommerceServices(search)),
  onAddNewInvoice: (invoice, history) => dispatch(saveInvoice(invoice, history))
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerAddInvoice);
