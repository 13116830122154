import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { Row, Col, Card, CardBody, Button } from 'reactstrap';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormField from 'pages/Utility/form-field';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { questionValueType, questionRenderType } from 'constants/list-types';
import QuestionListForm from '../quoter-list-form/question-list-form';
import { useDispatch } from 'react-redux';
import { getMemberships, getQuoterMemberships } from 'store/actions';

const QuestionForm = ({onSubmit, question}) => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const [formData, setFormData] = useState({
        valueType: question && question.valueType ? question.valueType : '',
        renderType: question && question.renderType ? question.renderType : '',
        description: question && question.description ? question.description : '',
        quoterQuestionLists: question && question.quoterQuestionLists ? question.quoterQuestionLists : []
    });

    const [showValueForm, setShowValueForm] = useState(false);
    const [answer, setAnswer] = useState(null);
    const [answerIndex, setAnswerIndex] = useState(-1);
    
    const { 
        valueType, renderType, 
        description, quoterQuestionLists } = formData;

    useEffect(() => {
        dispatch(getQuoterMemberships());
    }, []);

    
    const handleOnChange = event => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }

    const addAnswer = () => {
        setAnswer(null);
        setAnswerIndex(-1);
        setShowValueForm(true);
    }

    const editAnswer = (answer, index) => {
        setAnswer(answer);
        setAnswerIndex(index);
        setShowValueForm(true);
    }

    const deleteAnswer = (answer, selectedIndex) => {
        setFormData({...formData, quoterQuestionLists: quoterQuestionLists.filter((current, index) => index !== selectedIndex)});
    }

    return ( 
        <React.Fragment>
            <Row>
                <Col xl={{size: 10, offset: 1}}>
                    <Card>
                        <CardBody>
                            {/* <CardTitle className="h5">Floating labels</CardTitle> */}
                            {/* <p className="card-title-desc">Create beautifully simple form labels that float over your input fields.</p> */}

                                <Formik
                                    enableReinitialize={true}
                                    initialValues={formData}
                                    validationSchema={
                                        Yup.object().shape({
                                            valueType: Yup.string().required(),
                                            renderType: Yup.string().required(),
                                            description: Yup.string().required()
                                        })
                                    }
                                    onSubmit={(values, actions) => {
                                        actions.setSubmitting(false);
                                        let copy = {...formData};
                                        copy.quoterQuestionLists.forEach((element, index) => {
                                            let quoterValues = [...element.quoterValues];
                                            quoterValues.forEach((quoterValue, index) => {
                                                const membership = quoterValue.quoterMembership;
                                                quoterValues[index] = {
                                                    ...quoterValue,
                                                    id: null,
                                                    quoterMembershipId: membership.id ? membership.id: 1,
                                                    quoterMembership: {
                                                        id: membership.id,
                                                        name: membership.name,
                                                        tenantId: membership.tenantId
                                                    }
                                                };
                                            });
                                            copy.quoterQuestionLists[index] = {
                                                //id: element.id, 
                                                quoterQuestionId: copy.id, 
                                                tenantId: element.tenantId, 
                                                value: element.value, 
                                                quoterValues: quoterValues
                                            };
                                        });
                                        
                                        onSubmit(copy);
                                    }}
                                >
                                {({ errors, status, touched }) => (
                                    <Form className='needs-validation' >

                                        <Row>
                                            <Col md={12}>
                                                <FormField
                                                    key='description'
                                                    type='text'
                                                    maxLength={255}
                                                    fieldName='description'
                                                    fieldValue={description}
                                                    label={t('Description')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />
                                                
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>
                                                
                                                <FormField
                                                    key='type'
                                                    type='select'
                                                    fieldName='valueType'
                                                    fieldValue={valueType}
                                                    label={t('Value Type')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                >
                                                    <option key="valueTypeNotSelected" value="">{t('Not Selected')}</option>
                                                            {questionValueType.map(current => {
                                                                return (<option key={current.code} value={current.code}>{t(current.value)}</option>);
                                                             })}
                                                </FormField>
                                                
                                            </Col>
                                            <Col md={6}>

                                                <FormField
                                                    key='type'
                                                    type='select'
                                                    fieldName='renderType'
                                                    fieldValue={renderType}
                                                    label={t('Render Type')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                >
                                                    <option key="renderTypeNotSelected" value="">{t('Not Selected')}</option>
                                                            {questionRenderType.map(current => {
                                                                return (<option key={current.code} value={current.code}>{t(current.value)}</option>);
                                                             })}
                                                </FormField>
                                            </Col>
                                        </Row>


                                        <Row>
                                            <Col md={12}>
                                                <h3>{t('Answers')}</h3>
                                                <hr/>

                                                <Button type="button" color='primary' className='button' 
                                                    onClick={(event) => { 
                                                        event.preventDefault(); 
                                                        addAnswer();
                                                    }}
                                                >
                                                    <i className="mdi mdi-plus-circle-outline me-1"></i>{t('Add')}
                                                </Button>
                                           
                                                <table className="mt-2 mb-2 table align-middle">
                                                    <thead>
                                                        <tr><th>{t('Answer')}</th><th>{t('Memberships')}</th><th></th></tr>
                                                    </thead>
                                                    <tbody>
                                                        {quoterQuestionLists.map((current, index) => (
                                                            <tr key={`answer-${index}`}>
                                                                <td>{current.value}</td>
                                                                <td>{current.quoterValues.length == 0 ? 
                                                                    'Sin Membresías' : 
                                                                    current.quoterValues.map((quoterValue, quoterValueIndex) => (
                                                                        <span 
                                                                            key={`quoter-value-${quoterValueIndex}`} 
                                                                            className="badge bg-primary font-size-12 p-2 me-3 mb-2">
                                                                                {quoterValue.quoterMembership.name}
                                                                        </span>)
                                                                    )
                                                                    }
                                                                </td>
                                                                <td width="110">
                                                                    <Button type="button" color='primary' className='button me-1' onClick={(event) => { event.preventDefault(); editAnswer(current, index);}}>
                                                                        <i className="mdi mdi-pencil font-size-15"></i>
                                                                    </Button>
                                                                    <Button type="button" color='danger' className='button' onClick={(event) => { event.preventDefault(); deleteAnswer(current, index);}}>
                                                                        <i className="mdi mdi-delete font-size-15"></i>
                                                                    </Button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                        
                                                    </tbody>
                                                </table>
                                            </Col>
                                        </Row>

                                        <div className="button-items">
                                            <Button type='submit' color="primary" className="button"><i className="mdi mdi-content-save me-1"></i>{t('Save')}</Button>
                                            <Button color="light" className="button mr-1" onClick={() => { history.push('/questions-list') }}><i className="mdi mdi-arrow-left me-1"></i>{t('Back')}</Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            {showValueForm ?
            <QuestionListForm 
                isOpen={showValueForm} 
                answer={answer}
                onSubmit={(questionListData) => {
                    setAnswer(null);
                    setShowValueForm(false);
                    if(answerIndex == -1) {
                        setFormData({...formData, quoterQuestionLists: [...quoterQuestionLists, questionListData]});
                    } else {
                        let copy = [...quoterQuestionLists];
                        copy[answerIndex] = questionListData;
                        setFormData({...formData, quoterQuestionLists: copy});
                    }
                }} 
                onCancel={() => {
                    setAnswer(null);
                    setShowValueForm(false);
                }}
            /> : null}
        </React.Fragment>
     );
}

QuestionForm.propTypes = {
    onSubmit: PropTypes.func,
    question: PropTypes.any
}
 
export default QuestionForm;