import {
  GET_SERVICES_LIST,
  GET_SERVICES_LIST_FAIL,
  GET_SERVICES_LIST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  serviceList: [],
  error: {},
}

const ecommerce = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SERVICES_LIST:
      return {
        ...state,
        serviceList: [],
        loading: true,
      }
    case GET_SERVICES_LIST_SUCCESS:
      return {
        ...state,
        serviceList: action.payload,
      }  
    case GET_SERVICES_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default ecommerce
