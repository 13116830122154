import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card, CardBody, Row, Col, CardTitle, Button, Table } from 'reactstrap';
import cancelMembershipContext from '../context/cancelMembershipContext';
import visa from '../../../../../assets/images/brands/visa-128.png';
import mastercard from '../../../../../assets/images/brands/mastercard-128.png';
import CancelMembershipSelectAddress from './cancel-membership-select-address';
import { numberFormat } from 'helpers/utils';


const CancelMembershipConfirmation = () => {

    const membershipContext = useContext(cancelMembershipContext);
    const { membershipDebt, accountDetail, membershipDetail, selectedPayment, address, setAddress, getCurrencySymbol } = membershipContext;
    
    const identificationTypeList = useSelector(state => state.identificationTypes.identificationTypeList);
    const { t } = useTranslation();
    
    const [showAddressModal, setShowAddressModal] = useState(false);

    const getIdentificationType = useCallback((id) => {
        const identification = identificationTypeList.find(current => current.id == id);
        return identification? identification.name: null;
    });
    
    useEffect(() => {
        if(accountDetail && accountDetail.addresses.length > 0) {
            const defaultAddress = accountDetail.addresses.find(address => address.isDefault == 'Y');
            if(defaultAddress) {
                setAddress(defaultAddress);
            } else {
                setAddress(accountDetail.addresses[0]);
            }
        }
    }, [accountDetail]);

    const onConfirm = () => {
        setShowConfirm(false);
    };

    const showModal = () => {
        setShowAddressModal(true);
    };

    return ( 
        <React.Fragment>

            <Card>
                <CardBody>
                    <h3>{t('Membership')}</h3>
                    <h4>{membershipDetail ? membershipDetail.membership.name: null}</h4>
                </CardBody>
            </Card>
        
            {membershipDebt && membershipDetail ?
            <Card>
                <CardBody>
                    <Table>
                        <tbody>
                            <tr>
                                <td><h4>{t('Subtotal')}</h4></td>
                                <td className="text-end"><h4>{getCurrencySymbol(membershipDebt.currencyCode ? membershipDebt.currencyCode : membershipDetail.membership.currencyCode)}{ ' ' }{numberFormat(membershipDebt.subTotalPendingAmount)}</h4></td>
                            </tr>
                            <tr>
                                <td><h5>{t('I.V.A.')}</h5></td>
                                <td className="text-end"><h5>{getCurrencySymbol(membershipDebt.currencyCode ? membershipDebt.currencyCode : membershipDetail.membership.currencyCode)}{ ' ' }{numberFormat(membershipDebt.totalPendingTaxAmount)}</h5></td>
                            </tr>
                            <tr>
                                <td><h4>{t('Total')}</h4></td>
                                <td className="text-end"><h4>{getCurrencySymbol(membershipDebt.currencyCode ? membershipDebt.currencyCode : membershipDetail.membership.currencyCode)}{ ' ' }{numberFormat(membershipDebt.totalPendingAmount)}</h4></td>
                            </tr>
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
            : null
            }

            {selectedPayment && membershipDebt && membershipDebt.totalPendingAmount > 0 ? 
            <Row>
                <Col sm={8} lg={6} xl={6}>
                    <Card className="task-box dashboard-option mini-stats-wid">
                                            
                        <CardBody className="text-black">
                            <CardTitle>
                                <h4> <i className="mdi mdi-credit-card-check-outline font-size-24 text-primary"></i> {t('Payment Method')}</h4>
                            </CardTitle>
                            <div className='row'>
                                <div className="text-end ms-1">
                                    <strong className='font-size-24'>
                                        <i className={`mdi bx bx-uncheck`}></i>
                                    </strong>
                                </div> 
                            </div>

                            <Row className="row">
                                <Col xs={12} className="float-start col-xs-12">
                                    <h4 className="mb-0 text-black">XXXX-XXXX-XXXX-{selectedPayment.lastDigits}</h4>
                                </Col>
                            </Row>

                            <Row className="row">
                                <Col xs={9} className="text-start">
                                    <p className="fw-medium text-black">
                                        {selectedPayment.holderName}
                                    </p>
                                </Col>
                                <Col xs={3} className="text-end">
                                    <img width={50} src={ selectedPayment.brand == "VIS" ? visa : mastercard } />
                                </Col>
                            </Row>
                            
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            :null
            }

            {address && membershipDebt && membershipDebt.totalPendingAmount > 0 ? 
            <Row>
                <Col sm={12} lg={12} xl={12}>
                    <Card className="task-box dashboard-option mini-stats-wid">
                                            
                        <CardBody className="text-black">
                            <CardTitle>
                                <h4> <i className="mdi mdi-file-move-outline font-size-24 text-primary"></i> {t('Electronic Invoice')}</h4>
                            </CardTitle>
                            
                            <div>
                                <strong>{address.billingFullName}</strong>
                            </div>

                            <div>
                                <strong>{getIdentificationType(address.billingIdentificationTypeId)}: {address.billingIdentification}</strong>
                            </div>

                            <div>
                                <strong>{t('Phone')}: {address.billingPhone}</strong>
                            </div>
                            
                            <div>
                                <strong>{t('Address')}: {address.address}</strong>
                            </div>
                            
                            <div>
                                <strong>{t('Email')}: {address.billingMainEmail}</strong>
                            </div>
                            
                            <div>
                                <strong>{t('Additional Email')}: {address.billingSecondaryEmail}</strong>
                            </div>
                            
                            <Button color="primary" className="button mt-1" onClick={() => {showModal()}}>{t('Change')}</Button>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            :null
            }
            {showAddressModal?
            <CancelMembershipSelectAddress 
                onAccept={() => {setShowAddressModal(false)}}
                onCancel={() => {setShowAddressModal(false)}}
                isOpen={showAddressModal}
            />
            : null}
        </React.Fragment>
     );
}
 
export default CancelMembershipConfirmation;
