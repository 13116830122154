import { ADD_QUOTER_MEMBERSHIP, ADD_QUOTER_MEMBERSHIP_FAIL, ADD_QUOTER_MEMBERSHIP_SUCCESS, CLEAR_QUOTER_MEMBERSHIPS_STATE, 
         DELETE_QUOTER_MEMBERSHIP, 
         DELETE_QUOTER_MEMBERSHIP_FAIL, 
         DELETE_QUOTER_MEMBERSHIP_SUCCESS, 
         GET_QUOTER_MEMBERSHIP_DETAIL, 
         GET_QUOTER_MEMBERSHIP_DETAIL_FAIL, 
         GET_QUOTER_MEMBERSHIP_DETAIL_SUCCESS, 
         GET_QUOTER_MEMBERSHIP_LIST, 
         GET_QUOTER_MEMBERSHIP_LIST_FAIL, 
         GET_QUOTER_MEMBERSHIP_LIST_SUCCESS, 
         UPDATE_QUOTER_MEMBERSHIP,
         UPDATE_QUOTER_MEMBERSHIP_FAIL,
         UPDATE_QUOTER_MEMBERSHIP_SUCCESS} from "./actionTypes"

//QuoterMemberships
export const clearQuoterMembership = () => ({
    type: CLEAR_QUOTER_MEMBERSHIPS_STATE
  })

  export const getQuoterMemberships = (request) => ({
    type: GET_QUOTER_MEMBERSHIP_LIST,
    payload: request
  })
  
  export const getQuoterMembershipsSuccess = response => ({
    type: GET_QUOTER_MEMBERSHIP_LIST_SUCCESS,
    payload: response,
  })
  
  export const getQuoterMembershipsFail = error => ({
    type: GET_QUOTER_MEMBERSHIP_LIST_FAIL,
    payload: error,
  })

  export const getQuoterMembershipDetail = (request) => ({
    type: GET_QUOTER_MEMBERSHIP_DETAIL,
    payload: request
  })
  
  export const getQuoterMembershipDetailSuccess = response => ({
    type: GET_QUOTER_MEMBERSHIP_DETAIL_SUCCESS,
    payload: response,
  })
  
  export const getQuoterMembershipDetailFail = error => ({
    type: GET_QUOTER_MEMBERSHIP_DETAIL_FAIL,
    payload: error,
  })

  export const addQuoterMembership = (request) => ({
    type: ADD_QUOTER_MEMBERSHIP,
    payload: request
  })
  
  export const addQuoterMembershipSuccess = response => ({
    type: ADD_QUOTER_MEMBERSHIP_SUCCESS,
    payload: response,
  })
  
  export const addQuoterMembershipFail = error => ({
    type: ADD_QUOTER_MEMBERSHIP_FAIL,
    payload: error,
  })

  export const updateQuoterMembership = (request) => ({
    type: UPDATE_QUOTER_MEMBERSHIP,
    payload: request
  })
  
  export const updateQuoterMembershipSuccess = response => ({
    type: UPDATE_QUOTER_MEMBERSHIP_SUCCESS,
    payload: response,
  })
  
  export const updateQuoterMembershipFail = error => ({
    type: UPDATE_QUOTER_MEMBERSHIP_FAIL,
    payload: error,
  })

  export const deleteQuoterMembership = (request) => ({
    type: DELETE_QUOTER_MEMBERSHIP,
    payload: request
  })
  
  export const deleteQuoterMembershipSuccess = response => ({
    type: DELETE_QUOTER_MEMBERSHIP_SUCCESS,
    payload: response,
  })
  
  export const deleteQuoterMembershipFail = error => ({
    type: DELETE_QUOTER_MEMBERSHIP_FAIL,
    payload: error,
  })
