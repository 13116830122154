import { ALERT_MESSAGE } from 'constants/constants';
import { DEFAULT_COMPANY } from 'constants/list-types';
import QuestionForm from 'pages/Management/Commons/question-form/question-form';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { addQuestion, clearQuestion, showSuccessAlert } from 'store/actions';

const AddQuestion = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const questionCreated = useSelector(state => state.questions.questionCreated);

    useEffect(() => {
        if(questionCreated) {
            dispatch(showSuccessAlert(ALERT_MESSAGE.SAVED, () => {
                dispatch(clearQuestion());
                history.push(`/questions-list`);
            }));
        }
        
    }, [questionCreated]);

    const onSubmit = (data) => {
        data.companyId = DEFAULT_COMPANY;
        data.tenantId = DEFAULT_COMPANY;
        dispatch(addQuestion(data));
    }

    return ( 
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t('Title App')}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h2 className="mb-2 font-size-18">{t('New Question')}</h2>
                    </div>
                    
                    <Row>
                        <QuestionForm 
                            onSubmit={onSubmit}
                        />
                    </Row>
                </Container>
            </div> 
        </React.Fragment> 
    );
}
 
export default AddQuestion;