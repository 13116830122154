import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_LABORATORY_ORDER_DETAIL,
  GET_LABORATORY_ORDER_HISTORICAL_LIST,
  SEND_LABORATORY_EXAM_RESULT
} from "./actionTypes"

import {
  getLaboratoryOrderHistoricalListSuccess,
  getLaboratoryOrderHistoricalListFail,
  getLaboratoryOrderDetailSuccess,
  getLaboratoryOrderDetailFail,
  sendLaboratoryExamResultSuccess,
  sendLaboratoryExamResultFail
} from "./actions"

//API Calls helper
import {
  httpGetLaboratoryOrderDetail,
  httpGetLaboratoryOrderHistoricalList,
  httpSendExamResult
} from "../../helpers/Api/api-helper"

import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions"
import { ALERT_MESSAGE } from "constants/constants"

function* fetchLaboratoryOrderHistoricalList(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(httpGetLaboratoryOrderHistoricalList, request.payload)
    yield put(getLaboratoryOrderHistoricalListSuccess(response))
    yield put(hideLoadingAlert())
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getLaboratoryOrderHistoricalListFail(error))
  }
}

function* fetchLaboratoryOrderDetail(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(httpGetLaboratoryOrderDetail, request.payload)
    yield put(getLaboratoryOrderDetailSuccess(response))
    yield put(hideLoadingAlert())
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_DETAIL_ERROR))
    yield put(getLaboratoryOrderDetailFail(error))
  }
}

function* fetchSendLaboratoryResultNotification(request) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.SENDING))
    const response = yield call(httpSendExamResult, request.payload)
    yield put(hideLoadingAlert())
    yield put(sendLaboratoryExamResultSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.SEND_ERROR))
    yield put(sendLaboratoryExamResultFail(error))
  }
}

function* fetchLaboratoryOrderHistoricalListSaga() {
  yield takeEvery(GET_LABORATORY_ORDER_HISTORICAL_LIST, fetchLaboratoryOrderHistoricalList)
  yield takeEvery(GET_LABORATORY_ORDER_DETAIL, fetchLaboratoryOrderDetail)
  yield takeEvery(SEND_LABORATORY_EXAM_RESULT, fetchSendLaboratoryResultNotification)
}

export default fetchLaboratoryOrderHistoricalListSaga;
