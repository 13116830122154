import { combineReducers } from "redux";

// Front
import Layout from "./layout/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

//Customer
import affiliateds from "./Affiliated/reducer";
import affiliatedInvoices from "./Affiliated/reducer";
import affiliatedBeneficiaries from "./AffiliatedBeneficiary/reducer";
import affiliatedMembershipCancellation from "./AffiliatedMembershipCancellation/reducer";

import memberships from "./Membership/reducer";
import ecommerce from "./ECommerce/reducer";
import businessUnits from "./BusinessUnit/reducer";
import subsidiaries from "./Subsidiary/reducer";
import invoicePayments from "./InvoicePayment/reducer";
//Alerts
import Alerts from "./Alert/reducer";

//E-commerce
//import ecommerce from "./e-commerce/reducer"

//Calendar
import calendar from "./calendar/reducer";

//chat
import chat from "./chat/reducer";

//crypto
import crypto from "./crypto/reducer";

//invoices
import invoices from "./invoices/reducer";

//projects
import projects from "./projects/reducer";

//tasks
import tasks from "./tasks/reducer";

//contacts
import contacts from "./contacts/reducer";

//mails
import mails from "./mails/reducer";

//Dashboard
import Dashboard from "./dashboard/reducer";

//Dasboard saas
import DashboardSaas from "./dashboard-saas/reducer";

//Terms
import Terms from "./Term/reducer";

//Benefit Categories
import benefitCategories from "./BenefitCategory/reducer";
//Catalogs
import catalogs from "./Catalog/reducer";
//Identification Types
import identificationTypes from "./IdentificationType/reducer";

//Questions
import questions from "./Question/reducer";

//Quoter Membership
import quoterMemberships from "./QuoterMembership/reducer";

//Roles
import roles from "./Role/reducer";

//Payment Method
import paymentMethods from "./PaymentMethod/reducer";

import principals from "./Principal/reducer";

import accounts from "./Account/reducer";

//Change Password
import changePasswords from "./ChangePassword/reducer";

//Medical Services
import medicalServices from "./MedicalService/reducer";

//Medical Centers
import medicalCenters from "./MedicalCenter/reducer";

//Medical Patients
import medicalPatients from "./MedicalPatient/reducer"

//Laboratory Orders
import laboratoryOrders from "./LaboratoryOrder/reducer";

import rewardPrograms from "./Reward/reducer";

import productCategories from "./ProductCategory/reducer";
import affiliatedExpenses from "./AffiliatedExpenses/reducer";
import productSearches from "./ProductSearch/reducer";
import prescriptions from "./Prescription/reducer";
import servicePrescriptions from "./LaboratoryPrescription/reducer";

import relatedProducts from "./RelatedProduct/reducer";

import promotionsList from "./PromotionsList/reducer";
import deliveryPackages from "./DeliveryPackage/reducer";
import sampleCollect from "./SampleCollect/reducer";
import feeLists from "./Fee/reducer";
import countries from "./Country/reducer";

//Membership Affiliated Benefit
import membershipAffiliatedBenefits from "./MembershipAffiliatedBenefit/reducer";

//Membership Affiliated Details
import membershipAffiliatedDetails from "./MembershipAffiliatedDetail/reducer";

const rootReducer = combineReducers({
  affiliateds,
  affiliatedInvoices,
  memberships,
  countries,
  // public
  Layout,
  Login,
  Account,
  ForgetPassword,
  Profile,
  ecommerce,
  calendar,
  chat,
  mails,
  crypto,
  invoices,
  projects,
  tasks,
  contacts,
  Dashboard,
  DashboardSaas,
  Alerts,
  Terms,
  benefitCategories,
  affiliatedBeneficiaries,
  catalogs,
  identificationTypes,
  affiliatedMembershipCancellation,
  questions,
  quoterMemberships,
  businessUnits,
  subsidiaries,
  principals,
  accounts,
  paymentMethods,
  roles,
  changePasswords,
  medicalServices,
  medicalCenters,
  laboratoryOrders,
  invoicePayments,
  rewardPrograms,
  productCategories,
  affiliatedExpenses,
  productSearches,
  prescriptions,
  relatedProducts,
  promotionsList,
  deliveryPackages,
  sampleCollect,
  feeLists,
  servicePrescriptions,
  membershipAffiliatedBenefits,
  membershipAffiliatedDetails,
  medicalPatients
});

export default rootReducer;
