import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { addPaymentMethod, clearUpgradeMembership, getAccountDetail, getBenefits, getCatalogs, invoicePaymentMembershipCancellation, invoicePaymentMembershipCancellationClear, showSuccessAlert, showWarningAlert, upgradeMembership } from 'store/actions';

import upgradeMembershipContext from './upgradeMembershipContext';
import { useHistory, useParams } from 'react-router-dom';

const UpgradeMembershipState = props => {

    const [membership, setMembership] = useState(null);
    const [beneficiaries, setBeneficiaries] = useState([]);
    const [payment, setPayment] = useState(null);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [beneficiary, setBeneficiary] = useState(null);
    const [address, setAddress] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);

    const dispatch = useDispatch();
    const { id } = useParams();
    const history = useHistory();

    const account = useSelector(state => state.accounts.accountDetail);
    const upgradeCreated = useSelector(state => state.affiliateds.upgradeCreated);
    const currencyCodeList = useSelector(state => state.catalogs.currencyCodeList);

    useEffect(() => {
        dispatch(invoicePaymentMembershipCancellationClear());
        dispatch(clearUpgradeMembership());
        dispatch(getCatalogs());
    }, []);

    useEffect(() => {
        if(id) {
            dispatch(getAccountDetail({accountCode: id}));
        }
    }, [id]);

    useEffect(() => {
        if(membership) {
            dispatch(getBenefits({ membershipId: membership.id }));
        }
    }, [membership]);

    useEffect(() => {
        if(upgradeCreated) {
            dispatch(showSuccessAlert('The membership has been created', () => { history.push(`/membership-customer-profile/${id}`) }));
        }
    }, [upgradeCreated]);

    const getCurrencySymbol = (currencyCode) => {
        const found = currencyCodeList.find(current => current.code == currencyCode);
        return found ? found.currency : currencyCode;
    }

    const addPayment = data => {
        data.accountCode = id;
        data.cardToken = 'XXXX';
        data.lastDigits = data.cardNumber.substring(data.cardNumber.length - 4);
        data.isDefault = data.isDefault? 'Y' : 'N'
        dispatch(addPaymentMethod(data));
        setShowPaymentForm(false);
        setPayment(null);
    }

    const addBeneficiary = beneficiary => {
        let foundIdentification = beneficiaries.find(current => current.identification == beneficiary.identification);

        let found = beneficiaries.find(current => current.beneficiaryId == beneficiary.beneficiaryId);

        if(found || foundIdentification) {
            dispatch(showWarningAlert('beneficiary.exists'));
        } else {
            beneficiary.beneficiaryId = beneficiaries.length > 0 ? beneficiaries[beneficiaries.length - 1].beneficiaryId + 1 : 1;
            setBeneficiaries([...beneficiaries, beneficiary]);
            setShowForm(false);
            setBeneficiary(null);
        }
    }

    const editBeneficiary = beneficiary => {
        let beneficiaryIndex = beneficiaries.findIndex(current => current.beneficiaryId == beneficiary.beneficiaryId);
        let beneficiaryIdentificationIndex = beneficiaries.findIndex(current => current.identification == beneficiary.identification);

        if(beneficiaryIndex == -1) {
            dispatch(showWarningAlert('beneficiary.not.exists'));
        } else if(beneficiaryIdentificationIndex != -1 && beneficiaryIdentificationIndex != beneficiaryIndex) {
            dispatch(showWarningAlert('beneficiary.exists'));
        } else {
            let copy = [...beneficiaries];
            copy[beneficiaryIndex] = beneficiary;
            setBeneficiaries(copy);
            setShowForm(false);
            setBeneficiary(null);
        }
    }

    const deleteBeneficiary = beneficiary => {
        let beneficiaryIndex = beneficiaries.findIndex(current => current.beneficiaryId == beneficiary.beneficiaryId);

        if(beneficiaryIndex == -1) {
            dispatch(showWarningAlert('beneficiary.not.exists'));
        } else {
            let copy = beneficiaries.filter(current => current.beneficiaryId != beneficiary.beneficiaryId);
            setBeneficiaries(copy);
        }
    }

    const onConfirm = () => {
/*
discountPercentage: membership.discountType && membership.discountType == 'PER' ? membership.discount :  "0",
totalDiscountAmount: membership.discountType && membership.discountType == 'FLA' ? membership.discount :  "0",
totalPendingTaxPercentage: "0",
taxRate: membership.taxPercentage,
                        
*/
        let payload = {
            accountCode: id,
            currencyCode: membership.currencyCode,
            subTotalAmount: membership.price,
            taxAmount: membership.price * (membership.taxPercentage / 100),
            totalAmount: membership.price + (membership.price * (membership.taxPercentage / 100)),
            paymentMethodId: selectedPayment.id,
            billingAddress: {
                id: address.id,
                address: address.address,
                billingIdentificationTypeId: address.billingIdentificationTypeId,
                billingIdentification: address.billingIdentification,
                billingMainEmail: address.billingMainEmail,
                billingSecondaryEmail: address.billingSecondaryEmail,
                billingPhone: address.billingPhone
            },
            membership: {
                id: membership.id,
                beneficiaries: membership.beneficiaryType == 'OWR' ? [
                    {
                        id: 1,
                        identificationTypeId: account.identificationTypeId,
                        identification: account.identification,
                        firstName: account.firstName,
                        secondName: account.secondName,
                        firstSurname: account.firstSurname,
                        secondSurname: account.secondSurname,
                        email: account.email,
                        mainPhone: account.email,
                        secondaryPhone: account.secondaryPhone,
                        gender: account.gender,
                        consanguinityLevel: 'FAT'
                    }
                ]
                : beneficiaries
            }
        };
        dispatch(upgradeMembership(payload));
    }

    return (
        <upgradeMembershipContext.Provider
            value={{
                getCurrencySymbol,
                membership,
                setMembership,
                beneficiaries,
                addBeneficiary,
                editBeneficiary,
                deleteBeneficiary,
                payment,
                setPayment,
                selectedPayment,
                setSelectedPayment,
                showForm,
                setShowForm,
                beneficiary, 
                setBeneficiary,
                showPaymentForm,
                setShowPaymentForm,
                addPayment,
                address,
                setAddress,
                showConfirm, 
                setShowConfirm,
                onConfirm
            }}
        >
            {props.children}
        </upgradeMembershipContext.Provider>
    );
}

UpgradeMembershipState.propTypes = {
    children: PropTypes.any
}

export default UpgradeMembershipState;
