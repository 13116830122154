import { ALERT_MESSAGE } from "constants/constants"
import { 
  httpGetPromotionsList,
  httpGetPromotionDetail,
  httpAddPromotion,
  httpUpdatePromotion,
  httpDeletePromotion,
  httpSendPromotionNotification
} from "helpers/Api/api-helper"
import { call, put, takeEvery } from "redux-saga/effects"
import { DEFAULT_COMPANY } from "constants/list-types";
import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions"
import {
  getPromotionDetailSuccess,
  getPromotionDetailFail,
  addPromotionSuccess,
  addPromotionFail,
  updatePromotionSuccess,
  updatePromotionFail,
  deletePromotionSuccess,
  deletePromotionFail,
  getPromotionsListSuccess,
  getPromotionsListFail,
  sendPromotionNotificationSuccess,
  sendPromotionNotificationFail
} from "./actions"
import { 
  ADD_PROMOTION,
  DELETE_PROMOTION,
  GET_PROMOTION_DETAIL,
  GET_PROMOTIONS_LIST,
  UPDATE_PROMOTION,
  SEND_PROMOTION_NOTIFICATION
 } from "./actionTypes"

function* getPromotionsList(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetPromotionsList, request.payload)
    yield put(hideLoadingAlert())
    yield put(getPromotionsListSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getPromotionsListFail(error))
  }
}

function* getPromotionDetail(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetPromotionDetail, request.payload)
    yield put(hideLoadingAlert())
    yield put(getPromotionDetailSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_DETAIL_ERROR))
    yield put(getPromotionDetailFail(error))
  }
}

function* addPromotion(request) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.SAVING))
    const response = yield call(httpAddPromotion, request.payload)
    yield put(hideLoadingAlert())
    yield put(addPromotionSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.ADD_ERROR))
    yield put(addPromotionFail(error))
  }
}

function* sendPromotionNotification(request) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.SENDING))
    const response = yield call(httpSendPromotionNotification, request.payload)
    yield put(hideLoadingAlert())
    yield put(sendPromotionNotificationSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.SEND_ERROR))
    yield put(sendPromotionNotificationFail(error))
  }
}

function* updatePromotion(request) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.SAVING))
    const response = yield call(httpUpdatePromotion, request.payload)
    yield put(hideLoadingAlert())
    yield put(updatePromotionSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.UPDATE_ERROR))
    yield put(updatePromotionFail(error))
  }
}

function* deletePromotion(request) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.DELETING))
    const response = yield call(httpDeletePromotion, request.payload)
    yield put(hideLoadingAlert())
    yield put(deletePromotionSuccess(response))
    //yield put(getPromotionsList({companyId: DEFAULT_COMPANY}))
    const listResponse = yield call(httpGetPromotionsList, {})
    yield put(getPromotionsListSuccess(listResponse))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.DELETE_ERROR))
    yield put(deletePromotionFail(error))
  }
}

function* promotionsListSaga() {
    yield takeEvery(GET_PROMOTIONS_LIST, getPromotionsList)
    yield takeEvery(GET_PROMOTION_DETAIL, getPromotionDetail)
    yield takeEvery(ADD_PROMOTION, addPromotion)
    yield takeEvery(UPDATE_PROMOTION, updatePromotion)
    yield takeEvery(DELETE_PROMOTION, deletePromotion)
    yield takeEvery(SEND_PROMOTION_NOTIFICATION, sendPromotionNotification)
  }
  
  export default promotionsListSaga;