

export const ALERT_MESSAGE = {
    SAVED: 'alert.save',
    UPDATED: 'alert.update',
    UPLOADED: 'alert.uploaded',
    DELETED: 'alert.delete',
    BENEFIT_APPLIED: 'alert.benefit.applied',
    PROMOTION_NOTIFICATION_SENT: 'alert.promotion.notification.sent',

    SENDING: 'alert.sending',
    LOADING: 'alert.loading',
    SAVING: 'alert.saving',
    DELETING: 'alert.deleting',

    DELETE_ERROR: 'alert.delete.error',
    UPDATE_ERROR: 'alert.update.error',
    SEND_ERROR: 'alert.send.error',
    ADD_ERROR: 'alert.add.error',
    GET_DETAIL_ERROR: 'alert.get.detail.error',
    GET_LIST_ERROR: 'alert.get.list.error',

    USER_ALREADY_EXISTS: 'alert.user.exists.error',
}