/* AFFILIATED BENEFICIARIES */
export const CLEAR_AFFILIATED_BENEFICIARIES = "CLEAR_AFFILIATED_BENEFICIARIES"

export const GET_AFFILIATED_BENEFICIARIES = "GET_AFFILIATED_BENEFICIARIES"
export const GET_AFFILIATED_BENEFICIARIES_FAIL = "GET_AFFILIATED_BENEFICIARIES_FAIL"
export const GET_AFFILIATED_BENEFICIARIES_SUCCESS = "GET_AFFILIATED_BENEFICIARIES_SUCCESS"

export const GET_AFFILIATED_BENEFICIARY = "GET_AFFILIATED_BENEFICIARY"
export const GET_AFFILIATED_BENEFICIARY_FAIL = "GET_AFFILIATED_BENEFICIARY_FAIL"
export const GET_AFFILIATED_BENEFICIARY_SUCCESS = "GET_AFFILIATED_BENEFICIARY_SUCCESS"

export const ADD_AFFILIATED_BENEFICIARY = "ADD_AFFILIATED_BENEFICIARY"
export const ADD_AFFILIATED_BENEFICIARY_FAIL = "ADD_AFFILIATED_BENEFICIARY_FAIL"
export const ADD_AFFILIATED_BENEFICIARY_SUCCESS = "ADD_AFFILIATED_BENEFICIARY_SUCCESS"

export const UPDATE_AFFILIATED_BENEFICIARY = "UPDATE_AFFILIATED_BENEFICIARY"
export const UPDATE_AFFILIATED_BENEFICIARY_FAIL = "UPDATE_AFFILIATED_BENEFICIARY_FAIL"
export const UPDATE_AFFILIATED_BENEFICIARY_SUCCESS = "UPDATE_AFFILIATED_BENEFICIARY_SUCCESS"

export const DELETE_AFFILIATED_BENEFICIARY = "DELETE_AFFILIATED_BENEFICIARY"
export const DELETE_AFFILIATED_BENEFICIARY_FAIL = "DELETE_AFFILIATED_BENEFICIARY_FAIL"
export const DELETE_AFFILIATED_BENEFICIARY_SUCCESS = "DELETE_AFFILIATED_BENEFICIARY_SUCCESS"