import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardBody, Col, Label, Row } from 'reactstrap';
import Dropzone from 'react-dropzone';
import { ageRanges, beneficiaryTypes, currencyTypes, DEFAULT_COMPANY, mcIndicatorList, mcTypeList, discountTypes, membershipStates, planTypes, TERMS_MEMBERSHIP_TYPE, defaultRegistrations } from 'constants/list-types';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormField from 'pages/Utility/form-field';
import { useDispatch, useSelector } from 'react-redux';
import { getTermsByType } from 'store/actions';
import { getValueFromDTO } from 'helpers/utils';
import { getMemberships } from 'store/actions';


const MembershipForm = ({ onSubmit, onBack, membership }) => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const terms = useSelector(state => state.Terms.terms);
    const [allRequired , setAllRequired] = useState(false);


    const memberships = useSelector(state => state.memberships.membershipList);


    useEffect(() => {
        dispatch(getTermsByType(DEFAULT_COMPANY, TERMS_MEMBERSHIP_TYPE));
        dispatch(getMemberships());
        if(getValueFromDTO(membership, 'membershipStatus') === "PSL" || getValueFromDTO(membership, 'membershipStatus') === "PNS"){
            setAllRequired(true);
        }else{
            setAllRequired(false);
        }
    }, []);

    const [formData, setFormData] = useState({
        name: getValueFromDTO(membership, 'name'),
        picture: getValueFromDTO(membership, 'picture'),
        description: getValueFromDTO(membership, 'description'),
        ageRange: getValueFromDTO(membership, 'ageRange'),
        currencyCode: getValueFromDTO(membership, 'currencyCode'),
        price: getValueFromDTO(membership, 'price'),
        maxOfferQuantity: getValueFromDTO(membership, 'maxOfferQuantity'),
        priceOffer: getValueFromDTO(membership, 'priceOffer', '0'),
        taxPercentage: getValueFromDTO(membership, 'taxPercentage'),
        validityMonths: getValueFromDTO(membership, 'validityMonths'),
        maxBeneficiaryQuantity: getValueFromDTO(membership, 'maxBeneficiaryQuantity'),
        discount: getValueFromDTO(membership, 'discount', '0'),
        discountType: getValueFromDTO(membership, 'discountType'),
        membershipStatus: getValueFromDTO(membership, 'membershipStatus'),
        referenceCode: getValueFromDTO(membership, 'referenceCode'),
        billingReference: getValueFromDTO(membership, 'billingReference'),
        billingChannel: getValueFromDTO(membership, 'billingChannel'),
        billingTaxCode: getValueFromDTO(membership, 'billingTaxCode'),
        billingTaxRate: getValueFromDTO(membership, 'billingTaxRate'),
        termsId: membership && membership.terms ? membership.terms.id : '',

        mcType: getValueFromDTO(membership, 'mcType'),
        mcIndicator: getValueFromDTO(membership, 'mcIndicator'),

        taxExemption: getValueFromDTO(membership, 'taxExemption') == 'Y' ? true : false,
        beneficiaryType: getValueFromDTO(membership, 'beneficiaryType'),
        taxBillingPercentage: getValueFromDTO(membership, 'taxBillingPercentage'),
        beneficiaryFreeOfCharge: getValueFromDTO(membership, 'beneficiaryFreeOfCharge') == 'Y' ? true : false,
        upgrade: getValueFromDTO(membership, 'upgrade') == 'Y' ? true : false,
        planType: getValueFromDTO(membership, 'planType'),
        
        priorityOrder: getValueFromDTO(membership, 'priorityOrder'),

        taxRefund: getValueFromDTO(membership, 'taxRefund') == 'Y' ? true : false,
        requireInvitation: getValueFromDTO(membership, 'requireInvitation') == '1' ? true : false,
        invitationCode: membership && membership.membershipCodes && membership.membershipCodes.length > 0 ? membership.membershipCodes[0].code : '',
        billingExternalCode: getValueFromDTO(membership, 'billingExternalCode'),
        billingBeneficiaryExternalCode: getValueFromDTO(membership, 'billingBeneficiaryExternalCode'),
        priceBeneficiary: getValueFromDTO(membership, 'priceBeneficiary'),


        membershipAdditionalId: membership && membership.membershipAdditionalId ? membership.membershipAdditionalId : '',
        nameMembership: membership && membership.name ? membership.name : '',
        defaultRegistration: getValueFromDTO(membership, 'defaultRegistration'),
    });

    const {
        name, picture, description, currencyCode,
        price, maxOfferQuantity, priceOffer, taxPercentage, validityMonths,priorityOrder,
        maxBeneficiaryQuantity, discount, discountType,
        membershipStatus, ageRange,
        referenceCode, billingChannel,
        billingReference, billingTaxCode,
        billingTaxRate, termsId, mcIndicator,mcType,
        taxExemption, beneficiaryType,
        taxBillingPercentage,
        beneficiaryFreeOfCharge,
        upgrade,
        planType, taxRefund, requireInvitation,
        invitationCode,
        billingExternalCode, membershipId, billingBeneficiaryExternalCode, priceBeneficiary, membershipAdditionalId, defaultRegistration
    } = formData

    const [file, setFile] = useState({});

    const handleOnChange = event => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
        if(event.target.name === "membershipStatus" && (event.target.value === "PSL" || event.target.value === "PNS")){
            setAllRequired(true);
        }else if(event.target.name === "membershipStatus"){
            setAllRequired(false);
        }
    }

    const handleCheckOnChange = event => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.checked
        });
    }

    const handleAcceptedFiles = useCallback((files) => {

        let currentFile = files[0];
        const fileType = currentFile.type.split('/')[1];
        const fileName = currentFile.name.replace('.' + fileType, '');
        const fileByteArray = [];

        Object.assign(currentFile, {
            preview: URL.createObjectURL(currentFile),
            formattedSize: formatBytes(currentFile.size)
        });

        let reader = new FileReader();
        reader.onload = (evt) => {
            const arrayBuffer = evt.target.result,
                array = new Uint8Array(arrayBuffer);
            for (const a of array) {
                fileByteArray.push(a);
            }
            setFile({
                fileName: fileName,
                type: fileType,
                file: fileByteArray
            });
        };

        reader.readAsArrayBuffer(currentFile);

        setFormData({
            ...formData,
            picture: currentFile.preview
        });

    });

    /**
     * Formats the size
     */
    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes"
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

        const i = Math.floor(Math.log(bytes) / Math.log(k))
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
    }

    return (
        <React.Fragment>
            <Row>
                <Col xl={{ size: 10, offset: 1 }}>
                    <Card>
                        <CardBody>
                            {/* <CardTitle className="h5">Floating labels</CardTitle> */}
                            {/* <p className="card-title-desc">Create beautifully simple form labels that float over your input fields.</p> */}
                            <Formik
                                enableReinitialize={true}
                                initialValues={formData}
                                validationSchema={
                                    Yup.object().shape({
                                        name: Yup.string().required(),
                                        picture: Yup.string().required(t('Image is required')),
                                        description: Yup.string().required(),
                                        ageRange: Yup.string().required(),
                                        currencyCode: Yup.string().required(),
                                        price: Yup.number().min(0).required(),
                                        maxOfferQuantity: Yup.number(),
                                        priceOffer: Yup.number().min(0),
                                        priorityOrder: Yup.number()
                                            .min(0),
                                        taxPercentage: Yup.number().min(0).required(),
                                        validityMonths: Yup.number().min(0).required(),
                                        maxBeneficiaryQuantity: Yup.number().min(0).required(),
                                        discountType: Yup.string().required(),
                                        discount: Yup.number().min(0).required(),
                                        membershipStatus: Yup.string().required(),
                                        referenceCode: Yup.string().required(),
                                        //billingReference: Yup.string().required(),
                                        billingReference: Yup.string()
                                            .when([], {
                                                is : () => allRequired === true,
                                                then: Yup.string().required(),
                                                otherwise: Yup.string().notRequired(),
                                            }),
                                        billingChannel: Yup.string().required(),
                                        billingTaxCode: Yup.string()
                                            .when([], {
                                                is: () => allRequired === true,
                                                then: Yup.string().required(),
                                                otherwise: Yup.string().notRequired(),
                                            }),
                                        billingTaxRate: Yup.string().required(),
                                        termsId: Yup.string().required(),
                                        taxExemption: Yup.string(),
                                        beneficiaryType: Yup.string()
                                            .when([], {
                                                is : () => allRequired === true,
                                                then: Yup.string().required(),
                                                otherwise: Yup.string().notRequired(),
                                            }),
                                        taxBillingPercentage: Yup.number()
                                            .when([], {
                                                is: () => allRequired === true,
                                                then: Yup.number().required(),
                                                otherwise: Yup.number().notRequired(),
                                            }),
                                        beneficiaryFreeOfCharge: Yup.string().required(),
                                        upgrade: Yup.string().required(),
                                        planType: Yup.string().required(),
                                        taxRefund: Yup.string().required(),
                                        requireInvitation: Yup.string().required(),
                                        invitationCode: requireInvitation ? Yup.string().required() : Yup.string(),
                                        billingExternalCode: Yup.number().required(),
                                        billingBeneficiaryExternalCode: Yup.string(),
                                        priceBeneficiary: Yup.number(),
                                        membershipAdditionalId: Yup.number(),
                                        defaultRegistration: Yup.boolean()
                                            .when([], {
                                                is: () => allRequired === true,
                                                then: Yup.boolean().required(),
                                                otherwise: Yup.boolean().notRequired(),
                                            }),
                                    })
                                }
                                onSubmit={(values, actions) => {
                                    actions.setSubmitting(false);
                                    if (!file.file) {
                                        formData.fileDto = null;
                                    } else {
                                        formData.fileDto = file;
                                    }

                                    formData.membershipCodes = [
                                        {
                                            code: invitationCode
                                        }
                                    ];

                                    onSubmit(formData);
                                }}
                            >
                                {({ errors, status, touched }) => (
                                    <Form className='needs-validation' >
                                        <Row>
                                            <Col md={6}>

                                                <FormField
                                                    key='name'
                                                    type='text'
                                                    maxLength={255}
                                                    fieldName='name'
                                                    fieldValue={name}
                                                    label={t('Name')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />

                                            </Col>
                                            <Col md={6}>

                                                <FormField
                                                    key='description'
                                                    type='text'
                                                    maxLength={500}
                                                    fieldName='description'
                                                    fieldValue={description}
                                                    label={t('Description')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label">{t('Picture')}</Label>
                                                    <Dropzone
                                                        onDrop={acceptedFiles =>
                                                            handleAcceptedFiles(acceptedFiles)
                                                        }
                                                    >
                                                        {({ getRootProps, getInputProps }) => (
                                                            <div className="dropzone custom-dropzone">
                                                                <div
                                                                    className="dz-message needsclick"
                                                                    {...getRootProps()}
                                                                >
                                                                    <input {...getInputProps()} />
                                                                    <div className="mb-1">
                                                                        <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                                    </div>
                                                                    <h4>{t('Drop files here or click to upload.')}</h4>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </Dropzone>
                                                </div>
                                            </Col>
                                            <Col sm={6}>
                                                <div
                                                    className="dropzone-previews mb-3"
                                                    id="file-previews"
                                                >
                                                    <Label className='form-label'></Label>
                                                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                                        <div className="p-2">
                                                            <Row className="align-items-center">
                                                                <Col className="col-auto">
                                                                    <img
                                                                        data-dz-thumbnail=""
                                                                        height="180"
                                                                        style={{ maxWidth: '100%' }}
                                                                        className="avatar-xxl rounded bg-light"
                                                                        alt={file.name}
                                                                        src={file.preview ? file.preview : picture}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Card>
                                                    <Field
                                                        type="hidden"
                                                        className={"form-control" + (errors.picture && touched.picture ? " is-invalid" : "")}
                                                        name="picture"
                                                        maxLength={500}
                                                        value={picture}
                                                        onChange={handleOnChange}
                                                    />

                                                    <ErrorMessage
                                                        name="picture"
                                                        component="div"
                                                        className="invalid-feedback"
                                                    />
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12}>
                                                <h3 style={{marginTop: 30}}>{t('General')}</h3>
                                                <hr />
                                            </Col>
                                        </Row>

                                        <Row>

                                            <Col md={6}>

                                                <FormField
                                                    key='currencyCode'
                                                    type='select'
                                                    fieldName='currencyCode'
                                                    fieldValue={currencyCode}
                                                    label={t('Currency')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                >
                                                    <option key="currencyTypeNotSelected" value="">{t('Not Selected')}</option>
                                                    {currencyTypes.map(current => {
                                                        return (<option key={current.code} value={current.code}>{t(current.value)}</option>);
                                                    })}
                                                </FormField>

                                            </Col>

                                            <Col md={6}>

                                                <FormField
                                                    key='price'
                                                    type='number'
                                                    maxLength={20}
                                                    fieldName='price'
                                                    fieldValue={price}
                                                    label={t('Price')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />

                                            </Col>

                                        </Row>

                                        <Row>
                                            <Col md={6}>

                                                <FormField
                                                    key='taxPercentage'
                                                    type='number'
                                                    maxLength={20}
                                                    fieldName='taxPercentage'
                                                    fieldValue={taxPercentage}
                                                    label={t('Tax Percentage')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>

                                                <FormField
                                                    key='discountType'
                                                    type='select'
                                                    fieldName='discountType'
                                                    fieldValue={discountType}
                                                    label={t('Discount Type')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                >
                                                    <option key="discountTypeNotSelected" value="">{t('Not Selected')}</option>
                                                    {discountTypes.map(current => {
                                                        return (<option key={current.code} value={current.code}>{t(current.value)}</option>);
                                                    })}
                                                </FormField>

                                            </Col>
                                            <Col md={6}>

                                                <FormField
                                                    key='discount'
                                                    type='number'
                                                    maxLength={20}
                                                    fieldName='discount'
                                                    fieldValue={discount}
                                                    label={t('Discount Amount')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />

                                            </Col>
                                        </Row>


                                        <Col md={6}>
                                            <FormField
                                                key='defaultRegistration'
                                                type='select'
                                                fieldName='defaultRegistration'
                                                fieldValue={defaultRegistration}
                                                label={t('Membresia Default')}
                                                errors={errors}
                                                touched={touched}
                                                handleOnChange={handleOnChange}
                                            >
                                                <option key="beneficiaryTypeNotSelected" value="">{t('Not Selected')}</option>
                                                {defaultRegistrations.map(current => {
                                                    return (<option key={current.code} value={current.code}>{t(current.value)}</option>);
                                                })}
                                            </FormField>
                                        </Col>




                                        <Row>
                                            <Col md={6}>
                                                <FormField
                                                    key='beneficiaryType'
                                                    type='select'
                                                    fieldName='beneficiaryType'
                                                    fieldValue={beneficiaryType}
                                                    label={t('Beneficiary Type')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                >
                                                    <option key="beneficiaryTypeNotSelected" value="">{t('Not Selected')}</option>
                                                    {beneficiaryTypes.map(current => {
                                                        return (<option key={current.code} value={current.code}>{t(current.value)}</option>);
                                                    })}
                                                </FormField>
                                            </Col>
                                            <Col md={3}>
                                                <FormField
                                                    key='beneficiaryFreeOfCharge'
                                                    type='checkbox'
                                                    fieldName='beneficiaryFreeOfCharge'
                                                    fieldValue={beneficiaryFreeOfCharge}
                                                    label={t('Beneficiary Free of Charge')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleCheckOnChange}
                                                />

                                                </Col>
                                                <Col md={3}>
                                                <FormField
                                                    key='upgrade'
                                                    type='checkbox'
                                                    fieldName='upgrade'
                                                    fieldValue={upgrade}
                                                    label={t('Allow Upgrade')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleCheckOnChange}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={6}>

                                                <FormField
                                                    key='maxBeneficiaryQuantity'
                                                    type='number'
                                                    maxLength={5}
                                                    fieldName='maxBeneficiaryQuantity'
                                                    fieldValue={maxBeneficiaryQuantity}
                                                    label={t('Beneficiaries')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />

                                            </Col>
                                            <Col md={6}>

                                                <FormField
                                                    key='validityMonths'
                                                    type='number'
                                                    maxLength={5}
                                                    fieldName='validityMonths'
                                                    fieldValue={validityMonths}
                                                    label={t('Membership Term')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>

                                                <FormField
                                                    key='ageRange'
                                                    type='select'
                                                    fieldName='ageRange'
                                                    fieldValue={ageRange}
                                                    label={t('Age Range')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                >
                                                    <option key="ageRangeNotSelected" value="">{t('Not Selected')}</option>
                                                    {ageRanges.map(current => {
                                                        return (<option key={current.code} value={current.code}>{t(current.value)}</option>);
                                                    })}
                                                </FormField>

                                            </Col>

                                            <Col md={6}>

                                                <FormField
                                                    key='membershipStatus'
                                                    type='select'
                                                    fieldName='membershipStatus'
                                                    fieldValue={membershipStatus}
                                                    label={t('Status')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                >
                                                    <option key="membershipStatusNotSelected" value="">{t('Not Selected')}</option>
                                                    {membershipStates.map(current => {
                                                        return (<option key={current.code} value={current.code}>{t(current.value)}</option>);
                                                    })}
                                                </FormField>

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>
                                                <FormField
                                                    key='termsId'
                                                    type='select'
                                                    fieldName='termsId'
                                                    fieldValue={termsId}
                                                    label={t('Terms')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                >
                                                    <option key="TermsNotSelected" value="">{t('Not Selected')}</option>
                                                    {terms.map(current => {
                                                        return (<option key={current.id} value={current.id}>{t(current.name)}</option>);
                                                    })}
                                                </FormField>

                                            </Col>
                                            <Col md={6}>
                                                <FormField
                                                    key='planType'
                                                    type='select'
                                                    fieldName='planType'
                                                    fieldValue={planType}
                                                    label={t('Plan Type')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                >
                                                    <option key="planTypeNotSelected" value="">{t('Not Selected')}</option>
                                                    {planTypes.map(current => {
                                                        return (<option key={current.code} value={current.code}>{t(current.value)}</option>);
                                                    })}
                                                </FormField>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>

                                                <FormField
                                                    key='priorityOrder'
                                                    type='number'
                                                    maxLength={2}
                                                    fieldName='priorityOrder'
                                                    fieldValue={priorityOrder}
                                                    label={t('Priority Order')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />

                                            </Col>
                                            <Col md={6}>

                                              

                                            </Col>
                                        </Row>                                        

                                        <Row>
                                            <Col xs={12}>
                                                <h3 style={{marginTop: 30}}>{t('Master Card')}</h3>
                                                <hr />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>
                                                <FormField
                                                    key='mcIndicator'
                                                    type='select'
                                                    fieldName='mcIndicator'
                                                    fieldValue={mcIndicator}
                                                    label={t('MasterCard Type')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                >
                                                    {mcIndicatorList.map(current => {
                                                        return (<option key={current.code} value={current.code}>{current.value}</option>);
                                                    })}
                                                </FormField>

                                            </Col>
                                            <Col md={6}>
                                                <FormField
                                                    key='mcType'
                                                    type='select'
                                                    fieldName='mcType'
                                                    fieldValue={mcType}
                                                    label={t('MasterCard Cards')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                >
                                                    {mcTypeList.map(current => {
                                                        return (<option key={current.code} value={current.code}>{current.value}</option>);
                                                    })}
                                                </FormField>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12}>
                                                <h3 style={{marginTop: 30}}>{t('Invitation')}</h3>
                                                <hr />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>
                                                <FormField
                                                    key='requireInvitation'
                                                    type='checkbox'
                                                    fieldName='requireInvitation'
                                                    fieldValue={requireInvitation}
                                                    label={t('Require Invitation')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleCheckOnChange}
                                                />
                                            </Col>

                                            <Col md={6}>
                                                <FormField
                                                    key='invitationCode'
                                                    type='text'
                                                    maxLength={45}
                                                    fieldName='invitationCode'
                                                    fieldValue={invitationCode}
                                                    label={t('Invitation Code')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                    disabled={!requireInvitation}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12}>
                                                <h3 style={{marginTop: 30}}>{t('Offer Configuration')}</h3>
                                                <hr />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>

                                                <FormField
                                                    key='maxOfferQuantity'
                                                    type='number'
                                                    maxLength={20}
                                                    fieldName='maxOfferQuantity'
                                                    fieldValue={maxOfferQuantity}
                                                    label={t('Max Offer Quantity')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />

                                            </Col>
                                            <Col md={6}>

                                                <FormField
                                                    key='priceOffer'
                                                    type='number'
                                                    maxLength={20}
                                                    fieldName='priceOffer'
                                                    fieldValue={priceOffer}
                                                    label={t('Price Offer')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />

                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col xs={12}>
                                                <h3 style={{marginTop: 30}}>{t('Membresía Beneficiario Adicional')}</h3>
                                                <hr />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>

                                                <FormField
                                                    key='membershipAdditionalId'
                                                    type='select'
                                                    fieldName='membershipAdditionalId'
                                                    fieldValue={membershipAdditionalId}
                                                    label={t('Membresía Relacionada')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                >
                                                    <option key="membershipNotSelected" value="">{t('Not Selected')}</option>
                                                    {memberships.map(current => {
                                                        return (<option key={current.id} value={current.id}>{t(current.name)}</option>);
                                                    })}
                                                </FormField>

                                            </Col>

                                        </Row>


                                        <Row>
                                            <Col xs={12}>
                                                <h3 style={{marginTop: 30}}>{t('Billing')}</h3>
                                                <hr />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>

                                                <FormField
                                                    key='billingReference'
                                                    type='text'
                                                    maxLength={255}
                                                    fieldName='billingReference'
                                                    fieldValue={billingReference}
                                                    label={t('Billing Reference')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />
                                            </Col>
                                            <Col md={6}>

                                                <FormField
                                                    key='taxBillingPercentage'
                                                    type='text'
                                                    maxLength={20}
                                                    fieldName='taxBillingPercentage'
                                                    fieldValue={taxBillingPercentage}
                                                    label={t('Billing Tax Percentage')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>

                                            <Col md={6}>

                                                <FormField
                                                    key='referenceCode'
                                                    type='text'
                                                    maxLength={45}
                                                    fieldName='referenceCode'
                                                    fieldValue={referenceCode}
                                                    label={t('Reference Code')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />

                                            </Col>
                                            <Col md={6}>
                                                <FormField
                                                    key='billingExternalCode'
                                                    type='text'
                                                    maxLength={45}
                                                    fieldName='billingExternalCode'
                                                    fieldValue={billingExternalCode}
                                                    label={t('Billing External Code')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />
                                            </Col>



                                        </Row>

                                        <Row>
                                            <Col md={6}>

                                                <FormField
                                                    key='billingChannel'
                                                    type='text'
                                                    maxLength={45}
                                                    fieldName='billingChannel'
                                                    fieldValue={billingChannel}
                                                    label={t('Billing Channel')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />

                                            </Col>
                                            <Col md={6}>

                                                <FormField
                                                    key='billingTaxCode'
                                                    type='text'
                                                    maxLength={45}
                                                    fieldName='billingTaxCode'
                                                    fieldValue={billingTaxCode}
                                                    label={t('Billing Tax Code')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col md={6}>

                                                <FormField
                                                    key='billingTaxRate'
                                                    type='text'
                                                    maxLength={20}
                                                    fieldName='billingTaxRate'
                                                    fieldValue={billingTaxRate}
                                                    label={t('Billing Tax Rate')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />
                                            </Col>


                                        </Row>

                                        <Row>
                                            <Col md={6}>
                                                <FormField
                                                    key='taxExemption'
                                                    type='checkbox'
                                                    fieldName='taxExemption'
                                                    fieldValue={taxExemption}
                                                    label={t('Tax Exemption')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleCheckOnChange}
                                                />
                                            </Col>
                                            <Col md={6}>
                                                <FormField
                                                    key='taxRefund'
                                                    type='checkbox'
                                                    fieldName='taxRefund'
                                                    fieldValue={taxRefund}
                                                    label={t('Tax Refund')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleCheckOnChange}
                                                />
                                            </Col>
                                        </Row>

                                        <div className="button-items">
                                            <Button type='submit' color="primary" className="button"><i className="mdi mdi-content-save me-1"></i>{t('Save')}</Button>
                                            <Button color="light" className="button mr-1" onClick={() => { onBack() }}><i className="mdi mdi-arrow-left me-1"></i>{t('Back')}</Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </CardBody>
                    </Card>
                </Col>

            </Row>
        </React.Fragment>
    );
}

MembershipForm.propTypes = {
    onSubmit: PropTypes.func,
    membership: PropTypes.any,
    onBack: PropTypes.func
}

export default MembershipForm;