import React, { useContext, useState } from 'react';
import { Card, CardBody, Col, NavLink, NavItem, Row, TabContent, TabPane, Button } from 'reactstrap';
import classnames from "classnames";
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MembershipSelect from './stapes/membership-select';
import MembershipDetail from './stapes/membership-detail';
import BeneficiaryList from './stapes/beneficiary-list';
import MembershipPaymentMethod from './stapes/membership-payment-method';
import MembershipConfirmation from './stapes/membership-confirmation';
import upgradeMembershipContext from './context/upgradeMembershipContext';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch } from 'react-redux';
import { showWarningAlert } from 'store/actions';
import PageBody from 'components/Common/PageLayout/page-body';


const UpgradeMembershipWizard = () => {

    const [activeTab, setActiveTab] = useState(1);
    const [passedSteps, setPassedSteps] = useState([]);
    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();
    const membershipContext = useContext(upgradeMembershipContext);
    const { membership, address, showConfirm, setShowConfirm, onConfirm, selectedPayment } = membershipContext;

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 5) {
                var modifiedSteps = [...passedSteps, tab];
                setActiveTab(tab);
                setPassedSteps(modifiedSteps);
            }
        }
    }

    return (
        <React.Fragment>
            {showConfirm ?

                <SweetAlert
                    title={t('Confirm Purchase')}
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText={t('Yes')}
                    cancelBtnText={t('No')}
                    onConfirm={() => {
                        onConfirm();
                        setShowConfirm(false);
                    }}
                    onCancel={() => {
                        setShowConfirm(false);
                    }}
                >

                </SweetAlert>
                : null}

            <PageBody
                title='Upgrade Membership'
                actions={(
                    <Button color="light" className="button mr-1"
                        onClick={() => { history.push(`/membership-customer-profile/${id}`) }}>
                        <i className="mdi mdi-arrow-left me-1"></i>{t('Back')}
                    </Button>
                )}
            >
                <Row>
                    <Col lg={12}>
                        <Card>
                            <CardBody>
                                <div className="wizard clearfix">
                                    <div className="steps clearfix">
                                        <ul>
                                            <NavItem
                                                className={classnames({
                                                    current: activeTab === 1
                                                })}
                                            >
                                                <NavLink
                                                    className={classnames({
                                                        active: activeTab === 1
                                                    })}
                                                >
                                                    <span className="number">1.</span>{" "}
                                                    {t('Memberships')}
                                                </NavLink>
                                            </NavItem>

                                            <NavItem
                                                className={classnames({
                                                    current: activeTab === 2
                                                })}
                                            >
                                                <NavLink
                                                    disabled={!(passedSteps || []).includes(2)}
                                                    className={classnames({
                                                        active: activeTab === 2
                                                    })}
                                                >
                                                    <span className="number">2.</span>{" "}
                                                    {t('Detail')}
                                                </NavLink>
                                            </NavItem>

                                            <NavItem
                                                className={classnames({
                                                    current: activeTab === 3
                                                })}
                                            >
                                                <NavLink
                                                    disabled={!(passedSteps || []).includes(3)}
                                                    className={classnames({
                                                        active: activeTab === 3
                                                    })}
                                                >
                                                    <span className="number">3.</span>{" "}
                                                    {t('Beneficiary List')}
                                                </NavLink>
                                            </NavItem>

                                            <NavItem
                                                className={classnames({
                                                    current: activeTab === 4
                                                })}
                                            >
                                                <NavLink
                                                    disabled={!(passedSteps || []).includes(4)}
                                                    className={classnames({
                                                        active: activeTab === 4
                                                    })}
                                                >
                                                    <span className="number">4.</span>{" "}
                                                    {t('Payment Method')}
                                                </NavLink>
                                            </NavItem>

                                            <NavItem
                                                className={classnames({
                                                    current: activeTab === 5
                                                })}
                                            >
                                                <NavLink
                                                    disabled={!(passedSteps || []).includes(5)}
                                                    className={classnames({
                                                        active: activeTab === 5
                                                    })}
                                                >
                                                    <span className="number">5.</span>{" "}
                                                    {t('Confirm')}
                                                </NavLink>
                                            </NavItem>
                                        </ul>
                                    </div>
                                    <div className="content clearfix">
                                        <TabContent
                                            activeTab={activeTab}
                                            className="body"
                                        >
                                            <TabPane tabId={1}>
                                                <MembershipSelect onNext={() => { toggleTab(activeTab + 1); }} />
                                            </TabPane>
                                            <TabPane tabId={2}>
                                                <MembershipDetail />
                                            </TabPane>
                                            <TabPane tabId={3}>
                                                <BeneficiaryList />
                                            </TabPane>
                                            <TabPane tabId={4}>
                                                <MembershipPaymentMethod />
                                            </TabPane>
                                            <TabPane tabId={5}>
                                                <MembershipConfirmation />
                                            </TabPane>
                                        </TabContent>
                                    </div>
                                    <div className="actions clearfix">
                                        <ul>
                                            <li
                                                className={
                                                    activeTab === 1
                                                        ? "previous disabled"
                                                        : "previous"
                                                }
                                            >
                                                <Link
                                                    to="#"
                                                    onClick={() => {
                                                        toggleTab(activeTab - 1);
                                                    }}
                                                >
                                                    {t('Previous')}
                                                </Link>
                                            </li>
                                            <li
                                                className={
                                                    activeTab === 6
                                                        ? "next disabled"
                                                        : "next"
                                                }
                                            >
                                                <Link
                                                    disabled={(activeTab === 1)}
                                                    to="#"
                                                    onClick={() => {
                                                        if (activeTab == 1) {
                                                            if (membership) {
                                                                toggleTab(activeTab + 1);
                                                            } else {
                                                                dispatch(showWarningAlert(t('Membership Missing')));
                                                            }

                                                        } else if (activeTab == 4) {
                                                            if (selectedPayment) {
                                                                toggleTab(activeTab + 1);
                                                            } else {
                                                                dispatch(showWarningAlert(t('Payment Method Missing')));
                                                            }

                                                        } else if (activeTab == 5) {
                                                            if (address) {
                                                                setShowConfirm(true);
                                                            } else {
                                                                dispatch(showWarningAlert(t('Invoice Address Missing')));
                                                            }

                                                        } else {
                                                            toggleTab(activeTab + 1);
                                                        }

                                                    }}
                                                >
                                                    {activeTab == 5 ? t('Confirm') : t('Next')}
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </PageBody>
        </React.Fragment>

    );
}

export default UpgradeMembershipWizard;