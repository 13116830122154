import PageBody from "components/Common/PageLayout/page-body";
import ProductSearchBox from "components/Common/searchbox/product/product-searchbox";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import { clearProductSearchList, getCatalogs, getProductCategoryList } from "store/actions";
import ProductSearchCategories from "./ProductSearch/product-search-categories";

const ProductSearchPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCatalogs());
    dispatch(getProductCategoryList());
  }, []);

  const onSelectCategory = category => {
    dispatch(clearProductSearchList());
    history.push(`/pharmacy-product-search-results/c/${category.id}`);
  };

  const onSearch = filter => {
    dispatch(clearProductSearchList());
    history.push(`/pharmacy-product-search-results/q/${filter}`);
  };

  return (
    <React.Fragment>
      <PageBody
        title="Product Search"
        actions={
          <Button
            color="light"
            className="button"
            onClick={() => {
              history.push("/dashboard");
            }}
          >
            <i className="mdi mdi-arrow-left me-1"></i>
            {t("Back")}
          </Button>
        }
      >
        <Row>
          <Col>
            <h3>{t("Explore Products")}</h3>
            <hr />
          </Col>
        </Row>
        <Row className="mb-2">
          <Col xs={12} sm={8}>
            {/* Render selected service */}
            <ProductSearchBox onSearch={onSearch} onClear={() => {}} />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <h3>{t("Explore Categories")}</h3>

            <hr />
            {/* Render services */}
            <Row>
              <ProductSearchCategories
                onSelect={onSelectCategory}
                categorySelectedId={0}
              />
            </Row>
          </Col>
        </Row>
      </PageBody>
    </React.Fragment>
  );
};

export default ProductSearchPage;
