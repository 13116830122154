import { ALERT_MESSAGE } from "constants/constants"
import { 
  httpGetBusinessUnits
} from "helpers/Api/api-helper"
import { call, put, takeEvery } from "redux-saga/effects"
import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions"
import {
  getBusinessUnitsSuccess,
  getBusinessUnitsFail
} from "./actions"
import { 
  GET_BUSINESS_UNIT_LIST
 } from "./actionTypes"



function* getBusinessUnits(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetBusinessUnits, request.payload)
    yield put(hideLoadingAlert())
    yield put(getBusinessUnitsSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getBusinessUnitsFail(error))
  }
}

function* businessUnitsSaga() {
    yield takeEvery(GET_BUSINESS_UNIT_LIST, getBusinessUnits)
  }
  
  export default businessUnitsSaga