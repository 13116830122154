import Variables from "../../Variables";

//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register";

//LOGIN
export const POST_FAKE_LOGIN = "/post-fake-login";
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login";
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd";
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd";
export const SOCIAL_LOGIN = "/social-login";

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile";
export const POST_EDIT_PROFILE = "/post-fake-profile";

//CUSTOMERS
export const GET_AFFILIATED = Variables.Management + "/affiliated";
export const GET_AFFILIATED_SEARCH =
  Variables.Management + "/affiliated/list/filter";
export const GET_AFFILIATED_ORDER_LIST = Variables.Financing + "/invoice/list";
export const GET_AFFILIATED_MEMBERSHIP_LIST =
  Variables.Management + "/membership/account";
export const GET_AFFILIATED_MEMBERSHIP_DETAIL =
  Variables.Management + "/membership/account/id";
export const UPGRADE_MEMBERSHIP = Variables.Management + "/membership/checkout";
//LOGIN
export const POST_LOGIN = Variables.Security + "/auth/login";

//SIGNUP
export const POST_REGISTER = Variables.Security + "/auth/signup";

export const ADD_NEW_USER = Variables.Security + "/principal";
export const VERIFY_EMAIL = Variables.Security + "/auth/verify";
export const VERIFY_USER = Variables.Security + "/auth/verify/user";
export const GET_PRINCIPAL_DETAIL = Variables.Security + "/principal";
export const GET_PRINCIPAL_DETAIL_BY_ACCOUNT =
  Variables.Security + "/principal/account";
export const GET_PRINCIPAL_ROLE_LIST_BY_PRINCIPAL =
  Variables.Security + "/role/principal/list/principal";

export const SAVE_PRINCIPAL_ROLES = Variables.Security + "/role/principal";
export const DELETE_USER = Variables.Security + "/principal/delete"
export const CANCEL_ACCOUNT = Variables.Security + "/principal/cancel"

//SAVE INVOICE
export const SAVE_INVOICE = Variables.FinancingLegacy + "/invoice";
export const SAVE_INVOICE_DETAIL_DELIVERY = Variables.FinancingLegacy + "/invoice/detail/delivery";
export const GET_ALL_ORDER_LIST = Variables.FinancingLegacy + "/invoice/all";
export const GET_ORDER = Variables.FinancingLegacy + "/invoice/id";
export const GET_TRANSACTIONS_PURCHASE = Variables.FinancingLegacy + "/management/report/purchase";
export const GET_TRANSACTIONS_PURCHASE_DOWNLOAD = Variables.FinancingLegacy + "/management/report/purchase/download";

//MEMBERSHIPS
export const GET_MEMBERSHIPS_LIST = Variables.Management + "/membership";
export const GET_MEMBERSHIPS_DETAIL = Variables.Management + "/membership/id";
export const ADD_MEMBERSHIP = Variables.Management + "/membership";
export const UPDATE_MEMBERSHIP = Variables.Management + "/membership";
export const DELETE_MEMBERSHIP = Variables.Management + "/membership/del";
export const GET_QUOTER_MEMBERSHIP_LIST =
  Variables.Management + "/quoter/membership/list";
export const GET_QUOTER_MEMBERSHIP_DETAIL =
  Variables.Management + "/quoter/membership";
export const ADD_QUOTER_MEMBERSHIP = Variables.Management + "/quoter/membership";
export const UPDATE_QUOTER_MEMBERSHIP =
  Variables.Management + "/quoter/membership";
export const DELETE_QUOTER_MEMBERSHIP =
  Variables.Management + "/quoter/membership";

export const GET_ROLE_LIST = Variables.Security + "/role/list";
export const GET_ROLE_DETAIL = Variables.Security + "/role";
export const ADD_ROLE = Variables.Security + "/role";
export const UPDATE_ROLE = Variables.Security + "/role";
export const DELETE_ROLE = Variables.Security + "/role";
export const GET_RESOURCE_LIST = Variables.Security + "/resource/list";
export const GET_ROLE_RESOURCE_LIST =
  Variables.Security + "/role/resource/list/role";
export const ADD_ROLE_RESOURCE_LIST = Variables.Security + "/role/resource";
export const GET_REALM_LIST = Variables.Security + "/realm/list";

export const GET_PAYMENT_METHOD_LIST =
  Variables.Accounts + "/payment/method/list";
export const GET_PAYMENT_METHOD_DETAIL = Variables.Accounts + "/payment/method";
export const ADD_PAYMENT_METHOD = Variables.Accounts + "/payment/method";
export const UPDATE_PAYMENT_METHOD = Variables.Accounts + "/payment/method";
export const DELETE_PAYMENT_METHOD = Variables.Accounts + "/payment/method";

export const GET_BUSINESS_UNIT_LIST =
  Variables.Management + "/businessunit/list";
export const GET_SUBSIDIARY_LIST = Variables.Management + "/subsidiary/list";

//MEMBERSHIP DEBT
export const GET_MEMBERSHIP_DEBT =
  Variables.Management + "/membership/cancellation/summary";
//MEMBERSHIP CANCEL
export const GET_MEMBERSHIP_CANCEL =
  Variables.Management + "/membership/cancellation/process";
//AFFILIATED EXPENSES
export const GET_AFFILIATED_EXPENSES =
  Variables.Management + "/membership/account/expenses";

export const GET_AFFILIATED_MEDICAL_EXPENSES =
  Variables.Medical + "/savings/expenses";

//BENIFICIARIES
export const GET_AFFILIATED_BENEFICIARIES =
  Variables.Management + "/membership/beneficiary/list/affiliated";
export const GET_AFFILIATED_BENEFICIARY =
  Variables.Management + "/membership/beneficiary";
export const ADD_AFFILIATED_BENEFICIARY =
  Variables.Management + "/membership/beneficiary";
export const UPDATE_AFFILIATED_BENEFICIARY =
  Variables.Management + "/membership/beneficiary";
export const DELETE_AFFILIATED_BENEFICIARY =
  Variables.Management + "/membership/beneficiary";

//IDENTIFICATION TYPES
export const GET_IDENTIFICATION_TYPES =
  Variables.Management + "/identification/type/list";
//CATALOGS
export const GET_CATALOGS = Variables.Management + "/catalog";

//INVOICE PAYMENT MEMBERSHIP CANCELLATIO
export const INVOICE_PAYMENT_MEMBERSHIP_CACELLATION =
  Variables.Financing + "/payment/process";

//AFFILIATED REWARD PROGRAMS
export const GET_AFFILIATED_REWARD_PROGRAMS_LIST =
  Variables.Management + "/affiliated/reward/program/list";

//BENEFITS
export const GET_BENEFITS_LIST =
  Variables.Management + "/membership/benefit/list";
export const GET_BENEFITS_DETAIL = Variables.Management + "/membership/benefit";
export const ADD_BENEFIT = Variables.Management + "/membership/benefit";
export const UPDATE_BENEFIT = Variables.Management + "/membership/benefit";
export const DELETE_BENEFIT = Variables.Management + "/membership/benefit";

//BENEFIT CATEGORIES
export const GET_BENEFIT_CATEGORIES_LIST =
  Variables.Management + "/membership/benefit/category/list";

//TERMS
export const GET_TERMS_BY_TYPE = Variables.Management + "/terms/byType";
export const GET_TERMS = Variables.Management + "/terms/list";
export const GET_TERM_DETAIL = Variables.Management + "/terms";
export const ADD_TERM = Variables.Management + "/terms";
export const UPDATE_TERM = Variables.Management + "/terms";
export const DELETE_TERM = Variables.Management + "/terms/delete";


//RELATED PRODUCTS
export const GET_RELATED_PRODUCTS = Variables.Pharmacy + "/productrelated/list";
export const POST_RELATED_PRODUCTS = Variables.Pharmacy + "/productrelated";

//PRESCRIPTIONS
export const GET_PRESCRIPTION_TEMPLATE_LIST =
  Variables.Pharmacy + "/prescription/list";
export const GET_PRESCRIPTION_LIST = Variables.Pharmacy + "/prescription/list";
export const GET_PRESCRIPTION_DETAIL = Variables.Pharmacy + "/prescription";
export const ADD_PRESCRIPTION = Variables.Pharmacy + "/prescription";
export const UPDATE_PRESCRIPTION = Variables.Pharmacy + "/prescription";
export const UPLOAD_PDF_PRESCRIPTION = Variables.Pharmacy + "/prescription/upload/pdf";
export const DELETE_PRESCRIPTION = Variables.Pharmacy + "/prescription";

//SERVICE PRESCRIPTIONS
export const GET_SERVICE_PRESCRIPTION_TEMPLATE_LIST =
  Variables.Medical + "/prescription/list";
export const GET_SERVICE_PRESCRIPTION_LIST = Variables.Medical + "/prescription/list";
export const GET_SERVICE_PRESCRIPTION_DETAIL = Variables.Medical + "/prescription";
export const ADD_SERVICE_PRESCRIPTION = Variables.Medical + "/prescription";
export const UPDATE_SERVICE_PRESCRIPTION = Variables.Medical + "/prescription";
export const UPLOAD_PDF_SERVICE_PRESCRIPTION = Variables.Medical + "/prescription/upload/pdf";
export const DELETE_SERVICE_PRESCRIPTION = Variables.Medical + "/prescription";

//QUESTIONS
export const GET_QUESTIONS_BY_TYPE =
  Variables.Management + "/quoter/question//byType";
export const GET_QUESTIONS =
  Variables.Management + "/quoter/question/question/list";
export const GET_QUESTION_DETAIL = Variables.Management + "/quoter/question/";
export const ADD_QUESTION = Variables.Management + "/quoter/question/";
export const UPDATE_QUESTION = Variables.Management + "/quoter/question/";
export const DELETE_QUESTION = Variables.Management + "/quoter/question/";

//QUESTIONS
export const GET_QUESTIONS_LIST_BY_TYPE =
  Variables.Management + "/quoter/question//byType";
export const GET_QUESTIONS_LIST =
  Variables.Management + "/quoter/question/question/list";
export const GET_QUESTION_LIST_DETAIL =
  Variables.Management + "/quoter/question/";
export const ADD_QUESTION_LIST = Variables.Management + "/quoter/question/";
export const UPDATE_QUESTION_LIST = Variables.Management + "/quoter/question/";
export const DELETE_QUESTION_LIST = Variables.Management + "/quoter/question/";

export const GET_COUNTRIES = Variables.Management + "/country";

export const GET_ECOMMERCE_SERVICES_LIST =
  Variables.Accounts + "/ecommerce/service/search";

export const GET_ACCOUNT = Variables.Accounts + "/account";
export const GET_ACCOUNT_EXTERNAL_CODE = Variables.Accounts + "/account/externalcode";
export const GET_ACCOUNTS = Variables.Accounts + "/view/account/list";
export const UPDATE_ACCOUNT = Variables.Accounts + "/account";
export const DELETE_ACCOUNT = Variables.Accounts + "/account/delete";

export const REQUEST_VERIFICATION_CODE = Variables.Security + "/auth/code";
export const VALIDATE_VERIFICATION_CODE =
  Variables.Security + "/auth/code/validate";
export const UPDATE_PASSWORD = Variables.Security + "/auth/password";

//MEDICAL PATIENTS
export const GET_PATIENTS = Variables.Medical + "/patient/list/filter" ;

//MEDICAL CATEGORY
export const GET_MEDICAL_CATEGORIES = Variables.Medical + "/category/list";

//MEDICAL CENTER
export const GET_MEDICAL_CENTERS = Variables.Medical + "/center/list";

//PHARMACIES
export const GET_PHARMACY_LIST = Variables.Pharmacy + "/pharmacy/list";

//PRODUCT CATEGORIES
export const GET_PRODUCT_CATEGORIES = Variables.Pharmacy + "/category/list";

export const FILTER_PRODUCTS = Variables.Pharmacy + "/product/list";
export const GET_PRODUCT_DETAIL = Variables.Pharmacy + "/product";

export const FILTER_PRODUCTS_BY_CATEGORY =
  Variables.Pharmacy + "/product/category/list";

//MEDICAL SERVICE
export const GET_MEDICAL_SERVICES = Variables.Medical + "/service/list";


export const GET_ALL_MEDICAL_SERVICES = Variables.Medical + "/service/list/all";

export const GET_ALL_MEDICAL_SERVICES_BY_TYPE = Variables.Medical + "/service/list/all/type";

//MEDICAL SERVICE DETAIL
export const GET_MEDICAL_SERVICE_DETAIL = Variables.Medical + "/service";
export const UPDATE_MEDICAL_SERVICE_DETAIL = Variables.Medical + "/service/simple/update";

//LABORATORY ORDER
export const GET_LABORATORY_ORDER_HISTORICAL =
  Variables.Medical + "/appointment/list/filter";
export const GET_LABORATORY_ORDER_DETAIL = Variables.Medical + "/appointment";

//DELIVERY PACKAGE
export const GET_DELIVERY_PACKAGES_LIST =
  Variables.Pharmacy + "/product/order/pending";
export const GET_DELIVERY_PACKAGE_DETAIL = Variables.Pharmacy + "/product/order";

export const UPDATE_DELIVERY_PACKAGE_DETAIL = Variables.Pharmacy + "/product/order/pickup/change/status"

//FEE LIST
export const GET_FEE_LIST =
  Variables.Management + "/fee/filter";
export const SAVE_FEE_LIST = Variables.Management + "/fee";


//SAMPLE COLLECT
export const GET_SAMPLE_COLLECT_LIST =
Variables.Medical + "/appointment/pending";
export const GET_SAMPLE_COLLECT_DETAIL = Variables.Medical + "/appointment";
export const UPDATE_SAMPLE_PICKUP_STATUS = Variables.Medical + "/appointmentsample/change/status";

//PROMOTIONS LIST
export const GET_PROMOTIONS_LIST = Variables.Management + "/promotion/list";
export const GET_PROMOTION_DETAIL = Variables.Management + "/promotion/";
export const ADD_PROMOTION = Variables.Management + "/promotion/";
export const SEND_PROMOTION_NOTIFICATION = Variables.Management + "/promotion/notification";
export const SEND_BATCH_NOTIFICATION = Variables.Notification + "/batch";
export const UPDATE_PROMOTION = Variables.Management + "/promotion/";
export const DELETE_PROMOTION = Variables.Management + "/promotion/del";

export const GET_MEMBERSHIP_AFFILIATED_DETAIL_LIST = Variables.Management + "/membership/affiliated/detail/membership/";
export const GET_MEMBERSHIP_AFFILIATED_BENEFIT_LIST = Variables.Management + "/membership/affiliated/benefit/membership/id";
export const ADD_MEMBERSHIP_AFFILIATED_BENEFIT = Variables.Management + "/membership/affiliated/benefit/";

export const SEND_EXAM_RESULT = Variables.Medical + "/appointment/exam/result/notification";


//SYNC LABORATORIES 
export const LABORATORY_SYNC = Variables.Medical + "/appointment/lab/import"
