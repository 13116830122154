import moment from 'moment';
import json from 'jsonwebtoken';

export const numberFormat = num => {
    return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : "0.00"
};

export const toDateStringFormat = date => moment(date).format('DD/MM/yyyy');

export const toSimpleDateISOStringFormat = date => moment(date).format('YYYY-MM-DD');

export const toLongDateStringFormat = date => moment(date).format('DD/MM/yyyy hh:mm:ss a');

export const toHourStringFormat = date => moment(date).format('hh:mm a');

/**
 * Formats the date in the string format (YYYY-MM-DDT00:00:00)
 * @param {*} date target object
 * @param {*} isToDate indicates if is the last date in a range
 * @returns 
 */
export const toDateISOStringFormat = (date, isToDate) => moment(date).format(isToDate ? 'YYYY-MM-DDT23:59:59' : 'YYYY-MM-DDT00:00:00');

/**
 * Formats the date in the string format (YYYY-MM-DDTHH:mm:ss)
 * @param {*} date target object
 * @returns 
 */
 export const toFullDateISOStringFormat = (date) => moment(date).format('YYYY-MM-DDTHH:mm:ss');

/**
 * Obtains the property value from the target object, returns an empty string by default
 * when not found the property or the object is undefined
 * @param {object} dto target object
 * @param {string} propertyName target property
 * @param {string} defaultValue default value
 * @param {boolean} isDate indicates if is date
 * @returns {string} property value
 */
export const getValueFromDTO = (dto, propertyName, defaultValue = '', isDate = false) => {
    return dto && (dto[propertyName] || dto[propertyName] == 0) ? (isDate ? new Date(dto[propertyName]) : dto[propertyName]) : defaultValue;
}


/**
 * Formats the size
 */
export const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes"
    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"]

    const i = Math.floor(Math.log(bytes) / Math.log(k))
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i]
}

/**
 * Obtains the current user
 * @returns {json.JwtPayload}
 */
export const getAuthUser = () => {
    
    let authUser = null;

    if (localStorage.getItem("authUser")) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        authUser = json.decode(obj.idToken);
    }

    return authUser;
}

/**
 * Downloads the selected file
 * @param {string} fileUrl the file URL
 * @param {string} name the file name
 */
export const downloadFile = (fileUrl, name) => {
    const downloadLink = document.createElement('a');
    downloadLink.href = fileUrl;
    downloadLink.target = '_blank';
    downloadLink.rel = 'noopener noreferrer';
    downloadLink.download =  name;
    downloadLink.click()
}

/**
 * Obtains the fullname
 * @param {string} firsName 
 * @param {string} secondName 
 * @param {string} firstSurname 
 * @param {string} secondSurname 
 * @returns fullName
 */
export const getFullName = (firsName, secondName, firstSurname, secondSurname) => {
    let name = [firsName];
    if(secondName) {
        name.push(secondName);
    }
    name.push(firstSurname);
    name.push(secondSurname);

    return name.join(' ');
}