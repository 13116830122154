import {
  ADD_PRESCRIPTION,
  ADD_PRESCRIPTION_FAIL,
  ADD_PRESCRIPTION_SUCCESS,
  CLEAR_PRESCRIPTION_STATE,
  DELETE_PRESCRIPTION,
  DELETE_PRESCRIPTION_FAIL,
  DELETE_PRESCRIPTION_SUCCESS,
  GET_PRESCRIPTION_TEMPLATE_LIST,
  GET_PRESCRIPTION_TEMPLATE_LIST_FAIL,
  GET_PRESCRIPTION_TEMPLATE_LIST_SUCCESS,
  GET_PRESCRIPTION_DETAIL,
  GET_PRESCRIPTION_DETAIL_FAIL,
  GET_PRESCRIPTION_DETAIL_SUCCESS,
  GET_PRESCRIPTION_LIST,
  GET_PRESCRIPTION_LIST_FAIL,
  GET_PRESCRIPTION_LIST_SUCCESS,
  UPDATE_PRESCRIPTION,
  UPDATE_PRESCRIPTION_FAIL,
  UPDATE_PRESCRIPTION_SUCCESS,
  UPLOAD_PDF_PRESCRIPTION,
  UPLOAD_PDF_PRESCRIPTION_SUCCESS,
  UPLOAD_PDF_PRESCRIPTION_FAIL,
} from "./actionTypes";

const INIT_STATE = {
  prescriptions: [],
  prescriptionTemplates: [],
  success: false,
  loading: false,
  error: {},
  deleted: null,
  prescriptionDetail: null,
  created: null,
  updated: null,
  uploaded: undefined
};

const prescriptions = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_PRESCRIPTION_STATE:
      return {
        ...state,
        prescriptions: [],
        prescriptionTemplates: [],
        success: false,
        loading: false,
        error: {},
        deleted: null,
        prescriptionDetail: null,
        created: null,
        updated: null,
        uploaded: undefined
      };
    case GET_PRESCRIPTION_TEMPLATE_LIST:
      return {
        ...state,
        prescriptionTemplates: [],
        success: false,
        loading: true,
      };
    case GET_PRESCRIPTION_TEMPLATE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        prescriptionTemplates: action.payload,
      };
    case GET_PRESCRIPTION_TEMPLATE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_PRESCRIPTION_LIST:
      return {
        ...state,
        prescriptions: [],
        loading: true,
      };
    case GET_PRESCRIPTION_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        prescriptions: action.payload,
      };
    case GET_PRESCRIPTION_LIST_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_PRESCRIPTION_DETAIL:
      return {
        ...state,
        prescriptionDetail: undefined,
        loading: true,
      };
    case GET_PRESCRIPTION_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        prescriptionDetail: action.payload,
      };
    case GET_PRESCRIPTION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case ADD_PRESCRIPTION:
      return {
        ...state,
        success: false,
        loading: true,
        created: undefined,
      };
    case ADD_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        created: action.payload,
      };
    case ADD_PRESCRIPTION_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case UPDATE_PRESCRIPTION:
      return {
        ...state,
        success: false,
        loading: true,
        updated: undefined,
      };
    case UPDATE_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        updated: action.payload,
      };
    case UPDATE_PRESCRIPTION_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case UPLOAD_PDF_PRESCRIPTION:
      return {
        ...state,
        success: false,
        loading: true,
        uploaded: undefined,
      };
    case UPLOAD_PDF_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        uploaded: action.payload,
      };
    case UPLOAD_PDF_PRESCRIPTION_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case DELETE_PRESCRIPTION:
      return {
        ...state,
        success: false,
        loading: true,
        deleted: undefined,
      };
    case DELETE_PRESCRIPTION_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        deleted: action.payload,
      };
    case DELETE_PRESCRIPTION_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default prescriptions;

