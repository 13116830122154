import React from 'react';
import PropTypes from 'prop-types';
import FormFieldBasic from 'pages/Utility/form-field-basic';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { getFullName } from 'helpers/utils';

const MedicalInformation = ({ setProfessionLicenseCode, accountLogin, touched, errors, values, handleBlur, handleSubmit, handleReset }) => {

    const { t } = useTranslation();

    const handleOnChange = evt => {
        setProfessionLicenseCode(evt.target.value);
    }

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <form className='needs-validation mt-3' onSubmit={handleSubmit} onReset={handleReset}>
                        <Row>
                            <Col sm={12}>
                                <h4>{t('Doctor information')}</h4>
                            </Col>
                        </Row>
                        <Row className="align-middle">
                            <Col sm={2}>
                                <FormFieldBasic
                                    type='text'
                                    label={t('Doctor code')}
                                    maxLength={255}
                                    fieldName='professionLicenseCode'
                                    fieldValue={values.professionLicenseCode}
                                    handleOnChange={handleOnChange}
                                    errors={errors}
                                    touched={touched}
                                    handleBlur={handleBlur}
                                />
                            </Col>
                            <Col sm={8}>
                                <div>
                                    <span>{t('Name')}: </span>
                                    <strong>
                                        {getFullName(
                                            accountLogin.firstName,
                                            accountLogin.secondName,
                                            accountLogin.firstSurname,
                                            accountLogin.secondSurname)}
                                    </strong>
                                </div>
                                <div>
                                    <span>{t('Identification')}: </span><strong>{accountLogin.identification}</strong>
                                </div>                                
                                <div>
                                    <span>{t('Phone')}: </span><strong>{accountLogin.mainPhone}</strong>
                                </div>
                            </Col>
                        </Row>

                    </form>
                </CardBody>
            </Card>


        </React.Fragment>
    );
}

MedicalInformation.propTypes = {
    setProfessionLicenseCode: PropTypes.func,
    accountLogin: PropTypes.object,
    touched: PropTypes.any, 
    errors: PropTypes.any, 
    values: PropTypes.any, 
    handleBlur: PropTypes.func, 
    handleSubmit: PropTypes.func, 
    handleReset: PropTypes.func
}

export default MedicalInformation;