import { ALERT_MESSAGE } from "constants/constants";
import {
  httpGetServicePrescriptionTemplateList,
  httpGetServicePrescriptionList,
  httpGetServicePrescriptionDetail,
  httpPostServicePrescription,
  httpPutServicePrescription,
  httpDeleteServicePrescription,
  httpPutUploadPdfServicePrescription,
} from "helpers/Api/api-helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  hideLoadingAlert,
  showLoadingAlert,
  showWarningAlert,
} from "store/actions";
import {
  getServicePrescriptionListSuccess,
  getServicePrescriptionListFail,
  getServicePrescriptionTemplateListSuccess,
  getServicePrescriptionTemplateListFail,
  getServicePrescriptionDetailFail,
  getServicePrescriptionDetailSuccess,
  addServicePrescriptionSuccess,
  addServicePrescriptionFail,
  updateServicePrescriptionSuccess,
  updateServicePrescriptionFail,
  deleteServicePrescriptionSuccess,
  deleteServicePrescriptionFail,
  uploadPdfServicePrescriptionSuccess,
  uploadPdfServicePrescriptionFail,
} from "./actions";
import {
  GET_SERVICE_PRESCRIPTION_TEMPLATE_LIST,
  ADD_SERVICE_PRESCRIPTION,
  DELETE_SERVICE_PRESCRIPTION,
  GET_SERVICE_PRESCRIPTION_DETAIL,
  GET_SERVICE_PRESCRIPTION_LIST,
  UPDATE_SERVICE_PRESCRIPTION,
  UPLOAD_PDF_SERVICE_PRESCRIPTION,
} from "./actionTypes";

function* getServicePrescriptionTemplateList(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(
      httpGetServicePrescriptionTemplateList,
      request.payload
    );
    yield put(hideLoadingAlert());
    yield put(getServicePrescriptionTemplateListSuccess(response));
  } catch (error) {
    yield put(hideLoadingAlert());
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR));
    yield put(getServicePrescriptionTemplateListFail(error));
  }
}

function* getServicePrescriptionList(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(httpGetServicePrescriptionList, request.payload);
    yield put(hideLoadingAlert());
    yield put(getServicePrescriptionListSuccess(response));
  } catch (error) {
    yield put(hideLoadingAlert());
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR));
    yield put(getServicePrescriptionListFail(error));
  }
}

function* getServicePrescriptionDetail(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(httpGetServicePrescriptionDetail, request.payload);
    yield put(hideLoadingAlert());
    yield put(getServicePrescriptionDetailSuccess(response));
  } catch (error) {
    yield put(hideLoadingAlert());
    yield put(showWarningAlert(ALERT_MESSAGE.GET_DETAIL_ERROR));
    yield put(getServicePrescriptionDetailFail(error));
  }
}

function* addServicePrescription(request) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.SAVING));
    const response = yield call(httpPostServicePrescription, request.payload);
    yield put(hideLoadingAlert());
    yield put(addServicePrescriptionSuccess(response));
  } catch (error) {
    yield put(hideLoadingAlert());
    yield put(showWarningAlert(ALERT_MESSAGE.ADD_ERROR));
    yield put(addServicePrescriptionFail(error));
  }
}

function* updateServicePrescription(request) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.SAVING));
    const response = yield call(httpPutServicePrescription, request.payload);
    yield put(hideLoadingAlert());
    yield put(updateServicePrescriptionSuccess(response));
  } catch (error) {
    yield put(hideLoadingAlert());
    yield put(showWarningAlert(ALERT_MESSAGE.UPDATE_ERROR));
    yield put(updateServicePrescriptionFail(error));
  }
}

function* uploadPdfServicePrescription(request) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.SAVING));
    const response = yield call(httpPutUploadPdfServicePrescription, request.payload);
    yield put(hideLoadingAlert());
    yield put(uploadPdfServicePrescriptionSuccess(response));
  } catch (error) {
    yield put(hideLoadingAlert());
    yield put(showWarningAlert(ALERT_MESSAGE.UPDATE_ERROR));
    yield put(uploadPdfServicePrescriptionFail(error));
  }
}

function* deleteServicePrescription(request) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.DELETING));
    const response = yield call(httpDeleteServicePrescription, request.payload);
    yield put(hideLoadingAlert());
    yield put(deleteServicePrescriptionSuccess(response));
  } catch (error) {
    yield put(hideLoadingAlert());
    yield put(showWarningAlert(ALERT_MESSAGE.DELETE_ERROR));
    yield put(deleteServicePrescriptionFail(error));
  }
}

function* servicePrescriptionsSaga() {
  yield takeEvery(GET_SERVICE_PRESCRIPTION_TEMPLATE_LIST, getServicePrescriptionTemplateList);
  yield takeEvery(GET_SERVICE_PRESCRIPTION_LIST, getServicePrescriptionList);
  yield takeEvery(GET_SERVICE_PRESCRIPTION_DETAIL, getServicePrescriptionDetail);
  yield takeEvery(ADD_SERVICE_PRESCRIPTION, addServicePrescription);
  yield takeEvery(UPDATE_SERVICE_PRESCRIPTION, updateServicePrescription);
  yield takeEvery(UPLOAD_PDF_SERVICE_PRESCRIPTION, uploadPdfServicePrescription);
  yield takeEvery(DELETE_SERVICE_PRESCRIPTION, deleteServicePrescription);
}

export default servicePrescriptionsSaga;

