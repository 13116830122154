import { ADD_TERM, ADD_TERM_FAIL, ADD_TERM_SUCCESS, CLEAR_TERMS_STATE, DELETE_TERM, DELETE_TERM_FAIL, DELETE_TERM_SUCCESS, GET_TERMS_BY_TYPE,
         GET_TERMS_BY_TYPE_FAIL, GET_TERMS_BY_TYPE_SUCCESS, GET_TERM_DETAIL, GET_TERM_DETAIL_FAIL, GET_TERM_DETAIL_SUCCESS, GET_TERM_LIST, GET_TERM_LIST_FAIL, GET_TERM_LIST_SUCCESS, UPDATE_TERM, UPDATE_TERM_FAIL, UPDATE_TERM_SUCCESS } from "./actionTypes"

const INIT_STATE = {
    terms: [],
    success: false,
    loading: false,
    error: {},
    termDeleted: null,
    termDetail: null,
    termCreated: null,
    termUpdated: null,
  }
  
  const terms = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CLEAR_TERMS_STATE:
        return {
            ...state,
            terms: [],
            success: false,
            loading: false,
            error: {},
            termDeleted: null,
            termDetail: null,
            termCreated: null,
            termUpdated: null,
        }
        case GET_TERMS_BY_TYPE:
        return {
            ...state,
            terms: [],
            success: false,
            loading: true,
        }
        case GET_TERMS_BY_TYPE_SUCCESS:
        return {
            ...state,
            loading: false,
            success: true,
            terms: action.payload,
        }  
        case GET_TERMS_BY_TYPE_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case GET_TERM_LIST:
        return {
            ...state,
            terms: [],
            loading: true,
        } 
        case GET_TERM_LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            terms: action.payload,
        }  
        case GET_TERM_LIST_FAIL:
        return {
            ...state,
            error: action.payload,
        }
        case GET_TERM_DETAIL:
        return {
            ...state,
            termDetail: undefined,
            loading: true,
        }
        case GET_TERM_DETAIL_SUCCESS:
        return {
            ...state,
            loading: false,
            termDetail: action.payload,
        }  
        case GET_TERM_DETAIL_FAIL:
        return {
            ...state,
            error: action.payload,
        }
        case ADD_TERM:
        return {
            ...state,
            success: false,
            loading: true,
            termCreated: undefined
        }
        case ADD_TERM_SUCCESS:
        return {
            ...state,
            success: true,
            loading: false,
            termCreated: action.payload,
        }  
        case ADD_TERM_FAIL:
        return {
            ...state,
            error: action.payload,
        }
        case UPDATE_TERM:
        return {
            ...state,
            success: false,
            loading: true,
            termUpdated: undefined
        }
        case UPDATE_TERM_SUCCESS:
        return {
            ...state,
            success: true,
            loading: false,
            termUpdated: action.payload,
        }  
        case UPDATE_TERM_FAIL:
        return {
            ...state,
            error: action.payload,
        }
        case DELETE_TERM:
        return {
            ...state,
            success: false,
            loading: true,
            termDeleted: undefined
        }
        case DELETE_TERM_SUCCESS:
        return {
            ...state,
            success: true,
            loading: false,
            termDeleted: action.payload,
        }  
        case DELETE_TERM_FAIL:
        return {
            ...state,
            error: action.payload,
        }
        default:
            return state
        }
    }
    
export default terms
      