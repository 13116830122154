import { GET_COUNTRY_LIST, GET_COUNTRY_LIST_FAIL, GET_COUNTRY_LIST_SUCCESS } from "./actionTypes"

//Countries
export const getCountries = () => ({
    type: GET_COUNTRY_LIST
})

export const getCountriesSuccess = response => ({
    type: GET_COUNTRY_LIST_SUCCESS,
    payload: response,
})

export const getCountriesFail = error => ({
    type: GET_COUNTRY_LIST_FAIL,
    payload: error,
})