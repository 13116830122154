import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import json from 'jsonwebtoken';
import { addServicePrescription, clearServicePrescription, getAccountDetailLogin, showSuccessAlert } from 'store/actions';
import LaboratoryPrescriptionTemplateForm from './Components/laboratory-prescription-template-form';
import { ALERT_MESSAGE } from 'constants/constants';
import { getAuthUser } from 'helpers/utils';

const LaboratoryPrescriptionTemplateAddPage = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const prescriptionCreated = useSelector(state => state.servicePrescriptions.created);
    const accountLogin = useSelector(state => state.accounts.accountLogin);

    useEffect(() => {
        if(prescriptionCreated) {
            dispatch(showSuccessAlert(ALERT_MESSAGE.SAVED, () => {
                dispatch(clearServicePrescription());
                onBack();
            }));
        }
    }, [prescriptionCreated]);

    useEffect(() => {
        const authUser = getAuthUser();
        if (authUser) {
            dispatch(getAccountDetailLogin({ accountCode: authUser.sub }));
          }
    }, []);
    
    const onSubmit = (data) => {

        let prescription = {
            ...data,
            doctorFullname: [accountLogin.firstName,
            accountLogin.secondName,
            accountLogin.firstSurname,
            accountLogin.secondSurname].join(' '),
            doctorMedicalCode: '',
            doctorMedicalPhone: accountLogin.mainPhone
        };

        dispatch(addServicePrescription(prescription));
    }

    const onBack = () => {
        history.push('/laboratory-prescription-templates')
    }
    
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t('Title App')}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h2 className="mb-2 font-size-18">{t('New Order Template')}</h2>
                    </div>
                    
                    <Row>
                        <LaboratoryPrescriptionTemplateForm 
                            onSubmit={onSubmit}
                            onBack={onBack}
                        />
                    </Row>
                </Container>
            </div>
            
        </React.Fragment> 
    );
}
 
export default LaboratoryPrescriptionTemplateAddPage;