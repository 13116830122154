import { CLEAR_CHANGE_PASSWORD_STATE, 
         CHANGE_PASSWORD_REQUEST_CODE, 
         CHANGE_PASSWORD_REQUEST_CODE_FAIL, 
         CHANGE_PASSWORD_REQUEST_CODE_SUCCESS, 
         CHANGE_PASSWORD_VALIDATE_CODE, 
         CHANGE_PASSWORD_VALIDATE_CODE_FAIL, 
         CHANGE_PASSWORD_VALIDATE_CODE_SUCCESS, 
         CHANGE_PASSWORD_UPDATE,
         CHANGE_PASSWORD_UPDATE_FAIL,
         CHANGE_PASSWORD_UPDATE_SUCCESS} from "./actionTypes"

  //Change Password
  export const clearChangePassword = () => ({
    type: CLEAR_CHANGE_PASSWORD_STATE
  })

  export const changePasswordRequestCode = (request) => ({
    type: CHANGE_PASSWORD_REQUEST_CODE,
    payload: request
  })
  
  export const changePasswordRequestCodeSuccess = response => ({
    type: CHANGE_PASSWORD_REQUEST_CODE_SUCCESS,
    payload: response,
  })
  
  export const changePasswordRequestCodeFail = error => ({
    type: CHANGE_PASSWORD_REQUEST_CODE_FAIL,
    payload: error,
  })

  export const changePasswordValidateCode = (request) => ({
    type: CHANGE_PASSWORD_VALIDATE_CODE,
    payload: request
  })
  
  export const changePasswordValidateCodeSuccess = response => ({
    type: CHANGE_PASSWORD_VALIDATE_CODE_SUCCESS,
    payload: response,
  })
  
  export const changePasswordValidateCodeFail = error => ({
    type: CHANGE_PASSWORD_VALIDATE_CODE_FAIL,
    payload: error,
  })

  export const changePasswordUpdate = (request) => ({
    type: CHANGE_PASSWORD_UPDATE,
    payload: request
  })
  
  export const changePasswordUpdateSuccess = response => ({
    type: CHANGE_PASSWORD_UPDATE_SUCCESS,
    payload: response,
  })
  
  export const changePasswordUpdateFail = error => ({
    type: CHANGE_PASSWORD_UPDATE_FAIL,
    payload: error,
  })