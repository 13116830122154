import { CLEAR_BUSINESS_UNITS_STATE,
         GET_BUSINESS_UNIT_LIST, 
         GET_BUSINESS_UNIT_LIST_FAIL, 
         GET_BUSINESS_UNIT_LIST_SUCCESS } from "./actionTypes"

const INIT_STATE = {
    businessUnits: [],
    success: false,
    loading: false,
    error: {}
  }
  
  const businessUnits = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CLEAR_BUSINESS_UNITS_STATE:
        return {
            ...state,
            businessUnits: [],
            success: false,
            loading: false,
            error: {}
        }
        
        case GET_BUSINESS_UNIT_LIST:
        return {
            ...state,
            businessUnits: [],
            loading: true,
        } 
        case GET_BUSINESS_UNIT_LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            businessUnits: action.payload,
        }  
        case GET_BUSINESS_UNIT_LIST_FAIL:
        return {
            ...state,
            error: action.payload,
        }
        default:
            return state
        }
    }
    
export default businessUnits
      