import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";

const ProductSearchCategoryCard = ({ category, onSelect, selected }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Card
        className="dashboard-option category-card text-center"
        onClick={() => {
          onSelect(category);
        }}
      >
        <CardBody className="p-1 pt-3 pb-2">
          <div className="align-middle">
            <div className="text-center">
              <h5>{category.name}</h5>
              <h6>
                {category.productCounter} {t("products")}
              </h6>
            </div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

ProductSearchCategoryCard.propTypes = {
  category: PropTypes.object,
  onSelect: PropTypes.func,
  selected: PropTypes.bool,
};

export default ProductSearchCategoryCard;

