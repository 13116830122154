import React, { useEffect, useState } from "react";

import {
  Button,
  Container,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import ExamHistoricalFilter from "./exam-historical-filter";
import ExamHistoricalResult from "./exam-historical-result";
import { useHistory, useParams } from "react-router-dom";
import PageTitle from "components/Common/PageLayout/page-title";
import PageHeader from "components/Common/PageLayout/page-header";
import { useDispatch, useSelector } from "react-redux";
import { clearAccount, getAccountDetail, getDateFrom, getDateTo } from "store/actions";


const ExamHistoricalPage = () => {

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { account } = useParams();

  const dateFromStore = useSelector(state => state.accounts.dateFrom);
  const dateToStore = useSelector(state => state.accounts.dateTo);

  const getStartDate = () => {
      let date = new Date();
      date.setMonth(date.getMonth() - 12);
      return Math.floor(date.getTime() / 1000) * 1000;
  }

  const [formData, setFormData] = useState({
    startDate: getStartDate(),
    endDate: Date.now(),
    medicalCenter: 0,
  });

  useEffect(() => {
    dispatch(clearAccount());
    dispatch(getAccountDetail({ accountCode: account }));

    if (dateFromStore && dateToStore) {
      dispatch(getDateFrom(new Date(dateFromStore)))
      dispatch(getDateTo(new Date(dateToStore)))
    }else if(!dateFromStore && !dateToStore){
      dispatch(getDateFrom(getStartDate()));
      dispatch(getDateTo(new Date()));
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <PageTitle />
        <Container fluid>
          {/* Render Breadcrumb */}
          <PageHeader title='Exams Historical'>
            <Button
              color="light"
              className="button"
              onClick={() => { 
                history.goBack();
                }}>
              <i className="mdi mdi-arrow-left me-1"></i>{t('Back')}
            </Button>
          </PageHeader>
          <ExamHistoricalFilter
            formData={formData}
          />
          <ExamHistoricalResult
            formData={formData}
          />
        </Container>
      </div>
    </React.Fragment>
  );
}

export default ExamHistoricalPage;
