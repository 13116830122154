/* AFFILIATED */
export const CLEAR_GET_AFFILIATED = "CLEAR_GET_AFFILIATED"
export const GET_AFFILIATED = "GET_AFFILIATED"
export const GET_AFFILIATED_FAIL = "GET_AFFILIATED_FAIL"
export const GET_AFFILIATED_SUCCESS = "GET_AFFILIATED_SUCCESS"
export const GET_CRITERIA = "GET_CRITERIA"
export const GET_KEY = "GET_KEY"
export const GET_CURRENT_URL = "GET_CURRENT_URL"

/* SEARCH GET_AFFILIATED */
export const GET_AFFILIATED_SEARCH = "GET_AFFILIATED_SEARCH"
export const GET_AFFILIATED_SEARCH_FAIL = "GET_AFFILIATED_SEARCH_FAIL"
export const GET_AFFILIATED_SEARCH_SUCCESS = "GET_AFFILIATED_SEARCH_SUCCESS"

/* AFFILIATED ORDER LIST */
export const GET_AFFILIATED_ORDER_LIST = "GET_AFFILIATED_ORDER_LIST"
export const GET_AFFILIATED_ORDER_LIST_FAIL = "GET_AFFILIATED_ORDER_LIST_FAIL"
export const GET_AFFILIATED_ORDER_LIST_SUCCESS = "GET_AFFILIATED_ORDER_LIST_SUCCESS"

/* AFFILIATED ORDER LIST */
export const GET_ALL_ORDER_LIST = "GET_ALL_ORDER_LIST"
export const GET_ALL_ORDER_LIST_FAIL = "GET_ALL_ORDER_LIST_FAIL"
export const GET_ALL_ORDER_LIST_SUCCESS = "GET_ALL_ORDER_LIST_SUCCESS"

/* AFFILIATED ORDER */
export const GET_ORDER = "GET_ORDER_LIST"
export const GET_ORDER_FAIL = "GET_ORDER_FAIL"
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS"


/* AFFILIATED MEMBERSHIP LIST */
export const GET_AFFILIATED_MEMBERSHIP_LIST = "GET_AFFILIATED_MEMBERSHIP_LIST"
export const GET_AFFILIATED_MEMBERSHIP_LIST_FAIL = "GET_AFFILIATED_MEMBERSHIP_LIST_FAIL"
export const GET_AFFILIATED_MEMBERSHIP_LIST_SUCCESS = "GET_AFFILIATED_MEMBERSHIP_LIST_SUCCESS"

/* AFFILIATED MEMBERSHIP DETAIL */
export const GET_AFFILIATED_MEMBERSHIP_DETAIL = "GET_AFFILIATED_MEMBERSHIP_DETAIL"
export const GET_AFFILIATED_MEMBERSHIP_DETAIL_FAIL = "GET_AFFILIATED_MEMBERSHIP_DETAIL_FAIL"
export const GET_AFFILIATED_MEMBERSHIP_DETAIL_SUCCESS = "GET_AFFILIATED_MEMBERSHIP_DETAIL_SUCCESS"



/* UPGRADE MEMBERSHIP */
export const CLEAR_UPGRADE_MEMBERSHIP = "CLEAR_UPGRADE_MEMBERSHIP"
export const UPGRADE_MEMBERSHIP = "UPGRADE_MEMBERSHIP"
export const UPGRADE_MEMBERSHIP_FAIL = "UPGRADE_MEMBERSHIP_FAIL"
export const UPGRADE_MEMBERSHIP_SUCCESS = "UPGRADE_MEMBERSHIP_SUCCESS"

export const GET_ALL_PURCHASE_TRANSACTIONS = "GET_ALL_PURCHASE_TRANSACTIONS"
export const GET_ALL_PURCHASE_TRANSACTIONS_SUCCESS = "GET_ALL_PURCHASE_TRANSACTIONS_SUCCESS"
export const GET_ALL_PURCHASE_TRANSACTIONS_FAIL = "GET_ALL_PURCHASE_TRANSACTIONS_FAIL"