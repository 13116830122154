import { GET_CATALOGS_LIST, GET_CATALOGS_LIST_FAIL, GET_CATALOGS_LIST_SUCCESS } from "./actionTypes"

//Benefit Categories
export const getCatalogs = () => ({
    type: GET_CATALOGS_LIST
})

export const getCatalogsSuccess = response => ({
    type: GET_CATALOGS_LIST_SUCCESS,
    payload: response,
})

export const getCatalogsFail = error => ({
    type: GET_CATALOGS_LIST_FAIL,
    payload: error,
})