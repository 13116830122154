import PageBody from 'components/Common/PageLayout/page-body';
import { DEFAULT_COMPANY } from 'constants/list-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getCatalogs, getFeeList, saveFeeList } from 'store/actions';
import FeeListForm from './Components/fee-list-form';

const LaboratoryFeeListPage = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    const [feeList, setFeeList] = useState([]);

    const saved = useSelector(state => state.feeLists.saved);
    const feeListData = useSelector(state => state.feeLists.feeList);

    useEffect(() => {
        dispatch(getCatalogs());
        dispatch(getFeeList({
            companyId: DEFAULT_COMPANY,
            businessUnitType: 'LAB',
            type: 'DLV'
        }));
    }, []);

    useEffect(() => {
        if(saved) {

        }
    }, [saved]);

    useEffect(() => {
        if(feeListData) {
            setFeeList(feeListData);
        }
    }, [feeListData]);

    const onSave = (data) => {

        let feeData = [];

        feeList.forEach(fee => {
            feeData.push({
                name: data.name,
                type: data.type,
                businessUnitType: data.businessUnitType,
                companyId: data.companyId,
                countryCode: data.countryCode,
                currencyCode: data.currencyCode,
                fromRange: fee.fromRange,
                toRange: fee.toRange,
                fee: fee.fee
            });
        });

        dispatch(saveFeeList({
            type: data.type,
            businessUnitType: data.businessUnitType,
            companyId: data.companyId,
            feeList: feeData
        }));
    }

    return (
        <React.Fragment>
            <PageBody
                title='Laboratory Fee List'
            >
                <FeeListForm
                    businessUnit='LAB'
                    type='DLV'
                    feeList={feeList}
                    setFeeList={setFeeList}
                    onSave={onSave}
                    onCancel={() => { history.push('/dashboard') }}
                />
            </PageBody>
        </React.Fragment>
    );
}

export default LaboratoryFeeListPage;