import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_DELIVERY_PACKAGE_LIST,
  GET_DELIVERY_PACKAGE_DETAIL,
  UPDATE_DELIVERY_PACKAGE_STATUS
} from "./actionTypes"

import {
  getDeliveryPackageListSuccess,
  getDeliveryPackageListFail,
  getDeliveryPackageDetailSuccess,
  getDeliveryPackageDetailFail,
  updateDeliveryPackageStatusSuccess,
  updateDeliveryPackageStatusFail,

} from "./actions"

//API Calls helper
import {
  httpGetDeliveryPackageDetail,
  httpGetDeliveryPackages,
  httpUpdateDeliveryPackageStatus
} from "../../helpers/Api/api-helper"

import { hideLoadingAlert, showLoadingAlert, showWarningAlert, showSuccessAlert } from "store/actions"
import { ALERT_MESSAGE } from "constants/constants"

function* fetchDeliveryPackages(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(httpGetDeliveryPackages, request.payload)
    yield put(getDeliveryPackageListSuccess(response))
    yield put(hideLoadingAlert())
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getDeliveryPackageListFail(error))
  }
}

function* fetchDeliveryPackageDetail(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(httpGetDeliveryPackageDetail, request.payload)
    yield put(getDeliveryPackageDetailSuccess(response))
    yield put(hideLoadingAlert())
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_DETAIL_ERROR))
    yield put(getDeliveryPackageDetailFail(error))
  }
}

function* fetchDeliveryPackageStatus(request) {
  try{
    yield put(showLoadingAlert());
    const response = yield call(httpUpdateDeliveryPackageStatus, request.payload)
    yield put(updateDeliveryPackageStatusSuccess(response))
    yield put(hideLoadingAlert())
    yield put(showSuccessAlert(ALERT_MESSAGE.UPDATED))
  }catch(error){
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.UPDATE_ERROR))
    yield put(updateDeliveryPackageStatusFail(error))
  }
}


function* deliveryPackagesSaga() {
  yield takeEvery(GET_DELIVERY_PACKAGE_LIST, fetchDeliveryPackages)
  yield takeEvery(GET_DELIVERY_PACKAGE_DETAIL, fetchDeliveryPackageDetail)
  yield takeEvery(UPDATE_DELIVERY_PACKAGE_STATUS, fetchDeliveryPackageStatus)
}

export default deliveryPackagesSaga;
