/* AFFILIATED MEMBERSHIP DEBT */
export const CLEAR_AFFILIATED_MEMBERSHIP_CANCELLATION = "CLEAR_AFFILIATED_MEMBERSHIP_CANCELLATION"

export const GET_AFFILIATED_MEMBERSHIP_DEBT = "GET_AFFILIATED_MEMBERSHIP_DEBT"
export const GET_AFFILIATED_MEMBERSHIP_DEBT_FAIL = "GET_AFFILIATED_MEMBERSHIP_DEBT_FAIL"
export const GET_AFFILIATED_MEMBERSHIP_DEBT_SUCCESS = "GET_AFFILIATED_MEMBERSHIP_DEBT_SUCCESS"

export const GET_AFFILIATED_MEMBERSHIP_CANCEL = "GET_AFFILIATED_MEMBERSHIP_CANCEL"
export const GET_AFFILIATED_MEMBERSHIP_CANCEL_FAIL = "GET_AFFILIATED_MEMBERSHIP_CANCEL_FAIL"
export const GET_AFFILIATED_MEMBERSHIP_CANCEL_SUCCESS = "GET_AFFILIATED_MEMBERSHIP_CANCEL_SUCCESS"
