import React from 'react';
import PropTypes from 'prop-types';
import { toDateStringFormat } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Row } from 'reactstrap';

const ExamPatientInfoCard = ({ order, result }) => {

    const { t } = useTranslation();
    const { patient, laboratoryOrders } = order;

    const getStatus = examStatus => {
        let status = '';

        switch (examStatus) {
            case 'PEN':
                status = 'Pending'
                break;
            case 'FIN':
                status = 'Finalized'
                break;
            default:
                status = examStatus;
                break;
        }
        return status;
    }

    const getResult = () => {
        return laboratoryOrders[0].laboratoryOrderResults.find(item => item.id === result)
    }

    return (
        <Card>
            <CardBody>
                <Row>
                    <Col sm={12}>
                        <strong>#{laboratoryOrders.length > 0 ? laboratoryOrders[0].orderCode : null}</strong>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <strong>{laboratoryOrders.length > 0 && laboratoryOrders[0].laboratoryOrderResults.length > 0 ? getResult().medicalService.description : null}</strong>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <span>{laboratoryOrders.length > 0 ? toDateStringFormat(laboratoryOrders[0].orderDate) : null}</span>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <span>{t('Patient')}: </span><strong>{patient ? [patient.firstName, patient.secondName, patient.firstSurname, patient.secondSurname].join(' ') : null}</strong>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        <span>{t('Status')}: </span><strong>{laboratoryOrders.length > 0 ? t(getStatus(laboratoryOrders[0].completionStatus = "FIN")) : null}</strong>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}

ExamPatientInfoCard.propTypes = {
    order: PropTypes.object.isRequired,
    result: PropTypes.number.isRequired
}

export default ExamPatientInfoCard;