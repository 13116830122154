import {
    CLEAR_MEMBERSHIP_AFFILIATED_DETAIL_STATE,
    GET_MEMBERSHIP_AFFILIATED_DETAIL_LIST,
    GET_MEMBERSHIP_AFFILIATED_DETAIL_LIST_FAIL,
    GET_MEMBERSHIP_AFFILIATED_DETAIL_LIST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    benefitList: [],
    success: false,
    loading: false,
    error: {}
}

const membershipAffiliatedDetails = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CLEAR_MEMBERSHIP_AFFILIATED_DETAIL_STATE:
            return {
                ...state,
                benefitList: [],
                success: false,
                loading: false,
                error: {}
            }

        case GET_MEMBERSHIP_AFFILIATED_DETAIL_LIST:
            return {
                ...state,
                benefitList: [],
                created: false,
                loading: true,
            }
        case GET_MEMBERSHIP_AFFILIATED_DETAIL_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                benefitList: action.payload,
            }
        case GET_MEMBERSHIP_AFFILIATED_DETAIL_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}

export default membershipAffiliatedDetails
