import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import { Row, Col, Button, Modal } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { clearProductSearchList, getProductSearchList } from 'store/actions';
import ProductSearchCard from './product-search-card';
import ProductSearchBox from './product-searchbox';
import json from 'jsonwebtoken';
import { getAuthUser } from 'helpers/utils';

const SearchProductForm = ({ isOpen, addProduct, onCancel, getCurrencySymbol }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [filter, setFilter] = useState('');
    const [page, setPage] = useState(0);
    const [accountCode, setAccountCode] = useState(null);

    const productResults = useSelector(
        state => state.productSearches.productResults
    );

    const hasMore = useSelector(state => state.productSearches.hasMore);
    const loading = useSelector(state => state.productSearches.loading);

    useEffect(() => {
        const authUser = getAuthUser();
        if (authUser) {
            setAccountCode(authUser.sub);
        }
    }, []);

    const search = page => {

        dispatch(
            getProductSearchList({ name: filter, accountCode: accountCode, pageNumber: page })
        );

    }

    const onSearch = filter => {
        setFilter(filter.trim());
        if (filter.trim() != '') {
            dispatch(
                getProductSearchList({ name: filter, accountCode: accountCode, pageNumber: page })
            );
        }

    }

    const clearSearch = () => {
        setPage(0);
        dispatch(clearProductSearchList());
    }

    return (
        <React.Fragment>
            <Modal
                size='xl'

                isOpen={isOpen}
                toggle={() => { onCancel() }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {t('Products Search')}
                    </h5>
                    <button
                        type="button"
                        onClick={() => onCancel()}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div className="modal-body">
                    <Row className="mb-2">
                        <Col xs={12} sm={8}>
                            {/* Render selected service */}
                            <ProductSearchBox
                                onSearch={onSearch}
                                onClear={() => { clearSearch(); }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} sm={12}>
                            <h3>
                                {t("Search Results")}
                            </h3>
                            <hr />
                        </Col>
                    </Row>
                    {filter != '' ?
                        <InfiniteScroll
                            height={'50vh'}
                            dataLength={productResults.length}
                            next={() => {
                                search(page + 1);
                                setPage(page + 1);
                            }}
                            hasMore={hasMore}

                            loader={loading ? <h4>{t("Loading")}...</h4> : null}
                            endMessage={
                                <p style={{ textAlign: "center" }}>
                                    <b>{t("There are no more products to display")}</b>
                                </p>
                            }
                        >
                            <Row style={{ marginLeft: '0px', marginRight: '0px' }}>
                                {productResults.map(product => (
                                    <ProductSearchCard
                                        key={`product-${product.id}`}
                                        product={product}
                                        onSelect={addProduct}
                                        getCurrencySymbol={getCurrencySymbol}
                                    />
                                ))}
                            </Row>
                        </InfiniteScroll>
                        : null}
                </div>

                <div className="modal-footer button-items mt-1">
                    {/*<Button type='submit' color="primary" className="button"><i className="mdi mdi-content-save me-1"></i>{t('Save')}</Button>*/}
                    <Button color="light" className="button mr-1" onClick={() => { onCancel(); }}><i className="mdi mdi-close-thick me-1"></i>{t('Close')}</Button>
                </div>

            </Modal>
        </React.Fragment>
    );
}

SearchProductForm.propTypes = {
    addProduct: PropTypes.func,
    onCancel: PropTypes.func,
    isOpen: PropTypes.bool,
    getCurrencySymbol: PropTypes.func.isRequired
}

export default SearchProductForm;