import {
  GET_SAMPLE_COLLECT_LIST,
  GET_SAMPLE_COLLECT_LIST_FAIL,
  GET_SAMPLE_COLLECT_LIST_SUCCESS,
  GET_SAMPLE_COLLECT_DETAIL,
  GET_SAMPLE_COLLECT_DETAIL_FAIL,
  GET_SAMPLE_COLLECT_DETAIL_SUCCESS,
  UPDATE_SAMPLE_PICKUP_STATUS,
  UPDATE_SAMPLE_PICKUP_STATUS_SUCCESS,
  UPDATE_SAMPLE_PICKUP_STATUS_FAIL
} from "./actionTypes"

//Medical Center List
export const getSampleCollectList = (request) => ({
  type: GET_SAMPLE_COLLECT_LIST,
  payload: request
})

export const getSampleCollectListSuccess = response => ({
  type: GET_SAMPLE_COLLECT_LIST_SUCCESS,
  payload: response,
})

export const getSampleCollectListFail = error => ({
  type: GET_SAMPLE_COLLECT_LIST_FAIL,
  payload: error,
})

export const getSampleCollectDetail = (request) => ({
  type: GET_SAMPLE_COLLECT_DETAIL,
  payload: request
})

export const getSampleCollectDetailSuccess = response => ({
  type: GET_SAMPLE_COLLECT_DETAIL_SUCCESS,
  payload: response,
})

export const getSampleCollectDetailFail = error => ({
  type: GET_SAMPLE_COLLECT_DETAIL_FAIL,
  payload: error,
})

export const  updateSamplePickupStatus = (request) => ({
  type: UPDATE_SAMPLE_PICKUP_STATUS,
  payload: request
})
export const updateSamplePickupStatusSuccess = response=> ({
  type: UPDATE_SAMPLE_PICKUP_STATUS_SUCCESS,
  payload: response
})
export const  updateSamplePickupStatusFail = error => ({
  type: UPDATE_SAMPLE_PICKUP_STATUS_FAIL,
  payload: error
})