import { 
    GET_IDENTIFICATION_TYPES_LIST, 
    GET_IDENTIFICATION_TYPES_LIST_FAIL, 
    GET_IDENTIFICATION_TYPES_LIST_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  identificationTypeList: [],
  success: false,
  loading: false,
  error: {},
}

const identificationTypes = (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_IDENTIFICATION_TYPES_LIST:
            return {
                ...state,
                identificationTypeList: [],
                loading: true,
            }
        case GET_IDENTIFICATION_TYPES_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                identificationTypeList: action.payload,
            }  
        case GET_IDENTIFICATION_TYPES_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}

export default identificationTypes;