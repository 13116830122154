import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Card, CardBody, Row, Col, CardTitle, Button } from 'reactstrap';
import upgradeMembershipContext from '../context/upgradeMembershipContext';
import visa from '../../../../../assets/images/brands/visa-128.png';
import mastercard from '../../../../../assets/images/brands/mastercard-128.png';
import MembershipSelectAddress from './membership-select-address';

const MembershipConfirmation = () => {

    const membershipContext = useContext(upgradeMembershipContext);
    const { membership, selectedPayment, beneficiaries, address, setAddress } = membershipContext;
    const account = useSelector(state => state.accounts.accountDetail);
    const identificationTypeList = useSelector(state => state.identificationTypes.identificationTypeList);
    const { t } = useTranslation();
    
    const [showAddressModal, setShowAddressModal] = useState(false);

    const getIdentificationType = useCallback((id) => {
        const identification = identificationTypeList.find(current => current.id == id);
        return identification? identification.name: null;
    }); 

    useEffect(() => {
        if(account && account.addresses.length > 0) {
            const defaultAddress = account.addresses.find(address => address.isDefault == 'Y');
            if(defaultAddress) {
                setAddress(defaultAddress);
            } else {
                setAddress(account.addresses[0]);
            }
        }
    }, [account]);

    const onConfirm = () => {
        setShowConfirm(false);
    };

    const showModal = () => {
        setShowAddressModal(true);
    };

    return ( 
        <React.Fragment>

            <Card>
                <CardBody>
                    <h3>{t('Membership')}</h3>
                    <h4>{membership ? t(membership.name): null}</h4>
                </CardBody>
            </Card>

            <Card>
                <CardBody>
                    <h3>{t('Beneficiary List')}</h3>
                    <div>{beneficiaries && beneficiaries.length > 0 ? beneficiaries.map(current => {
                        return (
                            <div key={current.identification}>
                                <div>
                                    <strong>{[current.firstName, current.secondName, current.firstSurname, current.secondSurname].join(' ')}</strong>
                                </div>
                                <div>
                                    <strong>{getIdentificationType(current.identificationTypeId)}: {current.identification}</strong>
                                </div>

                                <div>
                                    <strong>{t('Phone')}: {current.mainPhone}</strong>
                                </div>
                                
                            </div>
                        );
                    
                    }): 
                    <div>
                        <strong>{t('Without Beneficiaries')}</strong>
                    </div>}
                </div>
                </CardBody>
            </Card>
        
            {membership ?
            <Card>
                <CardBody>
                    <table>
                        <tbody>
                            <tr>
                                <td><h4>{t('Subtotal')}</h4></td>
                                <td className="text-end"><h4>{membership.price}</h4></td>
                            </tr>
                            <tr>
                                <td><h5>{t('I.V.A.')}</h5></td>
                                <td className="text-end"><h5>{membership.price * (membership.taxPercentage / 100)}</h5></td>
                            </tr>
                            <tr>
                                <td><h4>{t('Total')}</h4></td>
                                <td className="text-end"><h4>{membership.price + (membership.price * (membership.taxPercentage / 100))}</h4></td>
                            </tr>
                        </tbody>
                    </table>
                </CardBody>
            </Card>
            : null
            }

            {selectedPayment? 
            <Row>
                <Col sm={8} lg={6} xl={6}>
                    <Card className="task-box dashboard-option mini-stats-wid">
                                            
                        <CardBody className="text-black">
                            <CardTitle>
                                <h4> <i className="mdi mdi-credit-card-check-outline font-size-24 text-primary"></i> {t('Payment Method')}</h4>
                            </CardTitle>
                            <div className='row'>
                                <div className="text-end ms-1">
                                    <strong className='font-size-24'>
                                        <i className={`mdi bx bx-uncheck`}></i>
                                    </strong>
                                </div> 
                            </div>

                            <Row className="row">
                                <Col xs={12} className="float-start col-xs-12">
                                    <h4 className="mb-0 text-black">XXXX-XXXX-XXXX-{selectedPayment.lastDigits}</h4>
                                
                                </Col>
                                
                            </Row>

                            <Row className="row">
                                <Col xs={9} className="text-start">
                                    <p className="fw-medium text-black">
                                        {selectedPayment.holderName}
                                    </p>
                                </Col>
                                <Col xs={3} className="text-end">
                                    <img width={50} src={ selectedPayment.brand == "VIS"? visa : mastercard } />
                                </Col>
                            </Row>
                            
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            :null
            }

            {address? 
            <Row>
                <Col sm={12} lg={12} xl={12}>
                    <Card className="task-box dashboard-option mini-stats-wid">
                                            
                        <CardBody className="text-black">
                            <CardTitle>
                                <h4> <i className="mdi mdi-file-move-outline font-size-24 text-primary"></i> {t('Electronic Invoice')}</h4>
                            </CardTitle>
                            
                            <div>
                                <strong>{address.billingFullName}</strong>
                            </div>

                            <div>
                                <strong>{getIdentificationType(address.billingIdentificationTypeId)}: {address.billingIdentification}</strong>
                            </div>

                            <div>
                                <strong>{t('Phone')}: {address.billingPhone}</strong>
                            </div>
                            
                            <div>
                                <strong>{t('Address')}: {address.address}</strong>
                            </div>
                            
                            <div>
                                <strong>{t('Email')}: {address.billingMainEmail}</strong>
                            </div>
                            
                            <div>
                                <strong>{t('Additional Email')}: {address.billingSecondaryEmail}</strong>
                            </div>
                            
                            <Button color="primary" className="button mt-1" onClick={() => {showModal()}}>{t('Change')}</Button>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            :null
            }
            {showAddressModal?
            <MembershipSelectAddress 
                onAccept={() => {setShowAddressModal(false)}}
                onCancel={() => {setShowAddressModal(false)}}
                isOpen={showAddressModal}
            />
            : null}
        </React.Fragment>
     );
}
 
export default MembershipConfirmation;
