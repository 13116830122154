import {
  GET_SAMPLE_COLLECT_LIST,
  GET_SAMPLE_COLLECT_LIST_FAIL,
  GET_SAMPLE_COLLECT_LIST_SUCCESS,
  GET_SAMPLE_COLLECT_DETAIL,
  GET_SAMPLE_COLLECT_DETAIL_FAIL,
  GET_SAMPLE_COLLECT_DETAIL_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  sampleCollectList: [],
  sampleCollectDetail: null,
  error: {},
  loading: false
}

const sampleCollect = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SAMPLE_COLLECT_LIST:
      return {
        ...state,
        sampleCollectList: [],
        loading: true,
      }         
    case GET_SAMPLE_COLLECT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        sampleCollectList: action.payload,
      }
    case GET_SAMPLE_COLLECT_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
      case GET_SAMPLE_COLLECT_DETAIL:
      return {
        ...state,
        sampleCollectDetail: null,
        loading: true,
      }         
    case GET_SAMPLE_COLLECT_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        sampleCollectDetail: action.payload,
      }
    case GET_SAMPLE_COLLECT_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default sampleCollect;
