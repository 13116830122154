import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import LaboratoryOrderCard from "./laboratory-order-card";
import EmptyResult from "components/empty-result/empty-result";
import { Row } from "reactstrap";
import PropTypes from 'prop-types';


const ExamHistoricalResult = ({formData}) => {
  const history = useHistory();
  const { account } = useParams();
  const orders = useSelector(
    state => state.laboratoryOrders.orderHistoricalList
  );

    const redirectToResultByMedicalService = laboratoryOrder => {

    history.push(
      `/laboratory-patient-result-by-medical-service/${account}/${laboratoryOrder.id}`
    );
  };

  return (
    <React.Fragment>
      {orders && orders.length > 0 ? (
        <Row>
          {orders.map(order =>
            order.laboratoryOrders && order.laboratoryOrders.length > 0
              ? order.laboratoryOrders.map((laboratoryOrder, index) => (
                laboratoryOrder.laboratoryOrderResults.length > 0
                  ? <LaboratoryOrderCard
                    key={laboratoryOrder.id}
                    order={{ ...laboratoryOrder, medicalService: order.medicalService }}
                    datetime={order.datetime}
                    onRedirectDetail={() => {
                      redirectToResultByMedicalService(order);
                    }}
                  />
                  : <LaboratoryOrderCard
                    key={laboratoryOrder.id}
                    order={{ ...laboratoryOrder, medicalService: order.medicalService }}
                    datetime={order.datetime}
                    onRedirectDetail={() => {
                      redirectToResultByMedicalService(order);
                    }}
                  />
              ))
              : null
          )}
        </Row>
      ) : (
        <EmptyResult />
      )}
    </React.Fragment>
  );
};

ExamHistoricalResult.propTypes = {
  formData: PropTypes.object, 
}

export default ExamHistoricalResult;
