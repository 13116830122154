import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const ExamPatientDesiredCard = ({ resultValues }) => {

  const { t } = useTranslation();

  const getResultStatusColor = resultValue => {
    if (resultValue.alarmIndicator === "Y") {
      return "#f46a6a";
    }
  }

  return (
    <React.Fragment>
      <Row>
        <Col md={6} lg={6}>
          <h3>{t('Test')}</h3>
        </Col>

        <Col md={6} lg={6}>
          <h3>{t('Result')}</h3>
        </Col>

      </Row>
      <br></br><br></br>
      {
        resultValues.map((resultValue) => {
          return (
            <>
              <Row>
                <Col sm="6">
                  <h5 style={{ color: getResultStatusColor(resultValue) }}>{resultValue.description ? resultValue.description : t('Information')}</h5>
                  <br></br><br></br>
                </Col>
                <Col sm="6">
                  <span className={`badge-result rounded-pill ${resultValue.alarmIndicator == 'N' ? 'badge-soft-primary' : 'badge-soft-danger'}`}>
                    {resultValue.valueType == 'LIS' || resultValue.valueType == 'TXT' ? resultValue.textValue : resultValue.numericValue}
                  </span>
                </Col>
              </Row>
            </>
          )
        })
      }
    </React.Fragment>
  );
}

ExamPatientDesiredCard.propTypes = {
  resultValues: PropTypes.array.isRequired
}

export default ExamPatientDesiredCard;


/*
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ExamPatientChartOption from '../Chart/exam-patient-chart-option';

const ExamPatientDesiredCard = ({ resultValues }) => {

  const { t } = useTranslation();

  const [selectedResult, setSelectedResult] = useState({});

  const onSelectResult = result => {
    setSelectedResult(result);
  }

  useEffect(() => {
    if (resultValues && resultValues.length > 0) {
      onSelectResult(resultValues[0]);
    }
  }, [resultValues]);

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Row>
            <Col md={4} lg={4}>
              <h4>{t('The desired value is show for every test')}</h4>
              <ExamPatientChartOption
                resultValues={resultValues}
                onSelect={onSelectResult}
                selectedResult={selectedResult}
              />
            </Col>
            <Col md={8} lg={8}>
              <div className="">
                <h4>{t('Result')}</h4>
                {selectedResult ?
                  (<>
                    <h5>
                      {selectedResult.description? selectedResult.description: t('Information')}: {' '}  
                   </h5>
                   <span className={`badge-result rounded-pill ${selectedResult.alarmIndicator == 'N' ? 'badge-soft-primary' : 'badge-soft-danger'}`}>
                    {selectedResult.valueType == 'LIS' || selectedResult.valueType == 'TXT'? selectedResult.textValue : selectedResult.numericValue}
                   </span>
                  </>)
                  : null}
              </div>
            </Col>

          </Row>

        </CardBody>
      </Card>
    </React.Fragment>
  );
}

ExamPatientDesiredCard.propTypes = {
  resultValues: PropTypes.array.isRequired
}

export default ExamPatientDesiredCard;

*/
