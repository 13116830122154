import { ALERT_MESSAGE } from "constants/constants";
import { httpAddAffiliatedBeneficiary, httpDeleteAffiliatedBeneficiary, 
         httpGetAffiliatedBeneficiaryDetail, httpGetAffiliatedBeneficiaryList, 
         httpUpdateAffiliatedBeneficiary } from "helpers/Api/api-helper"
import { call, put, takeEvery } from "redux-saga/effects"
import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions";
import { addAffiliatedBeneficiaryFail, addAffiliatedBeneficiarySuccess, 
         deleteAffiliatedBeneficiarySuccess, 
         deleteupdateAffiliatedBeneficiaryFail, 
         getAffiliatedBeneficiariesFail, getAffiliatedBeneficiariesSuccess, 
         getAffiliatedBeneficiaryFail, getAffiliatedBeneficiarySuccess, 
         updateAffiliatedBeneficiaryFail, updateAffiliatedBeneficiarySuccess } from "./actions"
import { ADD_AFFILIATED_BENEFICIARY, DELETE_AFFILIATED_BENEFICIARY, 
         GET_AFFILIATED_BENEFICIARIES, GET_AFFILIATED_BENEFICIARY, 
         UPDATE_AFFILIATED_BENEFICIARY } from "./actionTypes";

function* fetchAffiliatedBeneficiaries({request}) {
    try {
        yield put(showLoadingAlert());
        const response = yield call(httpGetAffiliatedBeneficiaryList, request.id)
        yield put(hideLoadingAlert())
        yield put(getAffiliatedBeneficiariesSuccess(response))
    } catch (error) {
        yield put(hideLoadingAlert())
        yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
        yield put(getAffiliatedBeneficiariesFail(error))
    }
}

function* fetchAffiliatedBeneficiary({request}) {
    try {
        yield put(showLoadingAlert());
        const response = yield call(httpGetAffiliatedBeneficiaryDetail, request.id)
        yield put(hideLoadingAlert())
        yield put(getAffiliatedBeneficiarySuccess(response))
    } catch (error) {
        yield put(hideLoadingAlert())
        yield put(showWarningAlert(ALERT_MESSAGE.GET_DETAIL_ERROR))
        yield put(getAffiliatedBeneficiaryFail(error))
    }
}

function* fetchAddAffiliatedBeneficiary({request}) {
    try {
        yield put(showLoadingAlert());
        const response = yield call(httpAddAffiliatedBeneficiary, request)
        yield put(hideLoadingAlert())
        yield put(addAffiliatedBeneficiarySuccess(response))
    } catch (error) {
        yield put(hideLoadingAlert())
        yield put(showWarningAlert(ALERT_MESSAGE.ADD_ERROR))
        yield put(addAffiliatedBeneficiaryFail(error))
    }
}

function* fetchUpdateAffiliatedBeneficiary({request}) {
    try {
        yield put(showLoadingAlert());
        const response = yield call(httpUpdateAffiliatedBeneficiary, request)
        yield put(hideLoadingAlert())
        yield put(updateAffiliatedBeneficiarySuccess(response))
    } catch (error) {
        yield put(hideLoadingAlert())
        yield put(showWarningAlert(ALERT_MESSAGE.UPDATE_ERROR))
        yield put(updateAffiliatedBeneficiaryFail(error))
    }
}

function* fetchDeleteAffiliatedBeneficiary({request}) {
    try {
        yield put(showLoadingAlert());
        const response = yield call(httpDeleteAffiliatedBeneficiary, request.id)
        yield put(hideLoadingAlert())
        yield put(deleteAffiliatedBeneficiarySuccess(response))
    } catch (error) {
        yield put(hideLoadingAlert())
        yield put(showWarningAlert(ALERT_MESSAGE.DELETE_ERROR))
        yield put(deleteupdateAffiliatedBeneficiaryFail(error))
    }
}

function* affiliatedBeneficiariesSaga() {
    yield takeEvery(GET_AFFILIATED_BENEFICIARIES, fetchAffiliatedBeneficiaries)
    yield takeEvery(GET_AFFILIATED_BENEFICIARY, fetchAffiliatedBeneficiary)
    yield takeEvery(ADD_AFFILIATED_BENEFICIARY, fetchAddAffiliatedBeneficiary)
    yield takeEvery(UPDATE_AFFILIATED_BENEFICIARY, fetchUpdateAffiliatedBeneficiary)
    yield takeEvery(DELETE_AFFILIATED_BENEFICIARY, fetchDeleteAffiliatedBeneficiary)
    
}

export default affiliatedBeneficiariesSaga;