import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_SERVICES_LIST,
  GET_SERVICES_LIST_FAIL,
  GET_SERVICES_LIST_SUCCESS
} from "./actionTypes"

import {
  getServicesSuccess,
  getServicessFail
} from "./actions"

//API Calls helper
import {
  httpGetECommerceServices,
} from "../../helpers/Api/api-helper"

function* getECommerceServices(request) {
  try {
    const response = yield call(httpGetECommerceServices, request)
    yield put(getServicesSuccess(response))
  } catch (error) {
    yield put(getServicessFail(error))
  }
}

function* ecommerceSaga() {
  yield takeEvery(GET_SERVICES_LIST, getECommerceServices)
}

export default ecommerceSaga
