import React, { useEffect, useState } from "react";
import { Alert, Card, CardBody, Col, Container, Row } from "reactstrap";
// Redux
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Formik, Form } from "formik";
import Variables from "../../Variables";
import * as Yup from "yup";
// actions
import { apiError, clearLogin, loginUser } from "../../store/actions";
// import images
import profile from "../../assets/images/profile-img.png";
import logo from "../../assets/images/logo.svg";
import lightlogo from "../../assets/images/logo-light.svg";
import loginImg from "../../assets/images/vitae/login.png";
import { useTranslation } from "react-i18next";
import FormField from "pages/Utility/form-field";

const Login = () => {

  const dispatch = useDispatch();
  const history = useHistory();
  const { t } = useTranslation();

  const [formData, setFormData] = useState({
    email: (localStorage.getItem('rememberEmail') ? localStorage.getItem('rememberEmail') : ''),
    password: '',
    newPassword: '',
    passwordConfirmation: '',
    remember: false
  });

  const { email, password, newPassword, passwordConfirmation, remember } = formData;

  const error = useSelector(state => state.Login.error);
  const loginResponse = useSelector(state => state.Login.loginResponse);

  useEffect(() => {
    dispatch(clearLogin(''));
    dispatch(apiError(''));
  }, []);

  useEffect(() => {
    if (loginResponse && loginResponse.code != '308') {

    } else {

    }
  }, [loginResponse]);

  const handleOnChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  }

  const handleCheckOnChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.checked
    });
  }

  const checkIfFirstLogin = () => {
    return loginResponse && loginResponse.code == 308;
  }
  const getValidators = () => {

    let validators = {
      email: Yup.string().email().required(),
      remember: Yup.boolean(),
      password: Yup.string().required(),
      newPassword: Yup.string(),
      passwordConfirmation: Yup.string()
    };

    if (checkIfFirstLogin()) {
      validators.newPassword = Yup.string().required().matches(
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z_.$&#+\-!@%\^*\(\)_=,\.\?\\\/])[0-9a-zA-Z_.$&#+\-!@%\^*\(\)_=,\.\?\\\/]{6,20}$/,
        // /^(?=.*[A-Za-z])(?=.*\d)(?=.*[\.$&#+\-]*)[A-Za-z\d\.$&#+\-]{10,15}$/,
        t('Invalid Password')
      );

      validators.passwordConfirmation = Yup.string()
        .oneOf([Yup.ref('newPassword'), null], t('Passwords Must Match'));

    }

    return Yup.object().shape(validators);
  }

  return (
    <React.Fragment>

      <div className="account-pages my-3 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={12} lg={10} xl={7}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col className="col-12">
                      <div>
                          <img
                            src={loginImg}
                            className="img-fluid"
                          />
                      </div>
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div className="auth-logo">
                    <Link to="/" className="auth-logo-light">
                      <div className="avatar-md profile-user-wid">
                      
                      </div>
                    </Link>
                    <Link to="/" className="auth-logo-dark">
                      <div className="avatar-md profile-user-wid">
                      
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    {error ? (
                      <Alert color="danger">{error.message}</Alert>
                    ) : null}
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        email: email,
                        password: password,
                        remember: remember,
                        newPassword: newPassword,
                        passwordConfirmation: passwordConfirmation
                      }}
                      validationSchema={getValidators()}
                      onSubmit={values => {
                        let request = {
                          email: values.email,
                          password: values.password,
                          remember: values.remember
                        }
                        if (checkIfFirstLogin()) {
                          request.newPassword = values.newPassword
                        }
                        dispatch(loginUser(request, history));
                      }}
                    >
                      {({ errors, status, touched }) => (

                        <Form className="needs-validation">
                          <FormField
                            key='email'
                            type='text'
                            maxLength={100}
                            fieldName='email'
                            fieldValue={email}
                            label={t('Email')}
                            errors={errors}
                            touched={touched}
                            handleOnChange={handleOnChange}
                          />

                          <FormField
                            key='password'
                            type='password'
                            maxLength={15}
                            fieldName='password'
                            fieldValue={password}
                            label={t('Password')}
                            errors={errors}
                            touched={touched}
                            handleOnChange={handleOnChange}
                          />
                          {
                            checkIfFirstLogin() ? (
                              <FormField
                                key='newPassword'
                                type='password'
                                maxLength={15}
                                fieldName='newPassword'
                                fieldValue={newPassword}
                                label={t('New Password')}
                                errors={errors}
                                touched={touched}
                                handleOnChange={handleOnChange}
                              />)
                              : null
                          }

                          {
                            checkIfFirstLogin() ? (
                              <Row>
                                <Col xs={12}>
                                  <FormField
                                    key='passwordConfirmation'
                                    type='password'
                                    maxLength={15}
                                    fieldName='passwordConfirmation'
                                    fieldValue={passwordConfirmation}
                                    label={t('Confirm Password')}
                                    errors={errors}
                                    touched={touched}
                                    handleOnChange={handleOnChange}
                                  />
                                </Col>
                              </Row>
                            )
                              : null
                          }
                          <FormField
                            key='remember'
                            type='checkbox'
                            fieldName='remember'
                            fieldValue={remember}
                            label={t('Remember me')}
                            errors={errors}
                            touched={touched}
                            handleOnChange={handleCheckOnChange}
                          />

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                            >
                              {t('Entrar')}
                            </button>
                          </div>

                          <div className="mt-4 text-center">
                            <Link
                              to="/recovery-password"
                              className="text-muted"
                            >
                              <i className="mdi mdi-lock me-1" />{t('Forgot your password?')}
                            </Link>
                          </div>
                        </Form>

                      )}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                {Variables.Version} © {new Date().getFullYear()} Vitae Services, Inc.
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );

}

export default Login;
