
import PageBody from 'components/Common/PageLayout/page-body';
import { ALERT_MESSAGE } from 'constants/constants';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row } from 'reactstrap';
import { addPrincipal, clearPrincipalCreation, clearRegister, showSuccessAlert } from 'store/actions';
import RegisterForm from './register-form';

const RegisterUser = () => {

    const dispatch = useDispatch();
    const history = useHistory();

    const userCreated = useSelector(state => state.principals.userCreated);

    useEffect(() => {
        dispatch(clearPrincipalCreation());
        dispatch(clearRegister());
    }, []);

    useEffect(() => {
        if (userCreated) {
            dispatch(showSuccessAlert(ALERT_MESSAGE.SAVED, () => {
                dispatch(clearRegister());
                history.goBack();
            }));
        }

    }, [userCreated]);

    const onSubmit = (data) => {
        data.mobilePhone = data.mobilePhone.replace(/\(/gi, '').replace(/\)/gi, '');
        dispatch(addPrincipal(data));
    }

    return (
        <React.Fragment>
            <PageBody
                title='Register User'
            >
                <Row>
                    <RegisterForm
                        onSubmit={onSubmit}
                    />
                </Row>
            </PageBody>
            
        </React.Fragment>
    );
}

export default RegisterUser;