import { ALERT_MESSAGE } from "constants/constants";
import { httpGetAffiliatedExpenses } from "helpers/Api/api-helper";
import { call, put, takeEvery } from "redux-saga/effects";
import {
  showLoadingAlert,
  hideLoadingAlert,
  showWarningAlert,
} from "store/actions";
import {
  getAffiliatedExpensesFail,
  getAffiliatedExpensesSuccess,
} from "./actions";
import { AFFILIATED_EXPENSES } from "./actionTypes";

function* fetchAffiliatedExpenses(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(httpGetAffiliatedExpenses, request.payload);
    yield put(getAffiliatedExpensesSuccess(response));
    yield put(hideLoadingAlert());
  } catch (error) {
    yield put(hideLoadingAlert());
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR));
    yield put(getAffiliatedExpensesFail(error));
  }
}

function* affiliatedExpensesSaga() {
  yield takeEvery(AFFILIATED_EXPENSES, fetchAffiliatedExpenses);
}

export default affiliatedExpensesSaga;
