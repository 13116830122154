import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row } from "reactstrap";
import { numberFormat } from "helpers/utils";
import { useTranslation } from "react-i18next";
import ProductSearchCarousel from "./product-search-carousel";
import image from 'assets/images/vitae/Logos-vitae.png'

const ProductSearchDetailCard = ({ product, getCurrencySymbol }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Row>
        <Col sm={6} md={4} lg={3}>
          <Card className="plan-box">
            <CardBody className="p-2">
              <ProductSearchCarousel productMedias={product.productMedias.length > 0 ? product.productMedias: [{id: 1, filePath: image, fileName: 'image'}]} />
            </CardBody>
          </Card>
        </Col>
        <Col sm={6} md={8} lg={9}>
          <Card className="plan-box">
            <CardBody className="p-2">
              <div className="d-flex pt-2">
                <div className="flex-grow-1">
                  <p className="text-muted">
                    {product.productCategories.map(productCategory => (
                      <span key={`product-category-${productCategory.id}`}>
                        {productCategory.category.name}
                      </span>
                    ))}
                  </p>
                  <h4>{product.name}</h4>
                </div>
              </div>
              <div className="py-1">
                <p>
                  {product.pharmaceuticalForm} {product.potency}
                </p>
              </div>
              <div className="py-1">
                <h2>
                  {getCurrencySymbol(product.currencyCode)}{" "}
                  {numberFormat(product.price)}
                </h2>
              </div>

              <div className="py-1">
                <h4>{t("Product Information")}</h4>
                <p>{product.description}</p>
              </div>
              {product.patientPlanIndicator == "Y" ? (
                <div className="py-1">
                  <h4>{t("Patient Plan")}</h4>
                  <p>{product.patientPlanInformation}</p>
                </div>
              ) : null}
            </CardBody>
            {/*<CardBody className="text-center">
              <Button
                color="primary"
                className="button"
                onClick={() => {
                  onSelect(product);
                }}
              >
                {t("View Detail")} <i className="mdi mdi-arrow-right me-1"></i>
              </Button>
            </CardBody>*/}
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

ProductSearchDetailCard.propTypes = {
  product: PropTypes.object.isRequired,
  getCurrencySymbol: PropTypes.func.isRequired,
};

export default ProductSearchDetailCard;
