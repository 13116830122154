import React from "react";
import { Redirect } from "react-router-dom";

// Pages Component
import Chat from "../pages/Chat/Chat";

// Pages File Manager
import FileManager from "../pages/FileManager/index";

// Pages Calendar
import Calendar from "../pages/Calendar/index";

// User profile
import UserProfilePage from "../pages/Authentication/user-profile-page";

//Tasks
import TasksList from "../pages/Tasks/tasks-list";
import TasksKanban from "../pages/Tasks/tasks-kanban";
import TasksCreate from "../pages/Tasks/tasks-create";

//Projects
import ProjectsGrid from "../pages/Projects/projects-grid";
import ProjectsList from "../pages/Projects/projects-list";
import ProjectsOverview from "../pages/Projects/ProjectOverview/projects-overview";
import ProjectsCreate from "../pages/Projects/projects-create";

//Ecommerce Pages
import EcommerceProducts from "../pages/Ecommerce/EcommerceProducts/index";
import EcommerceProductDetail from "../pages/Ecommerce/EcommerceProducts/EcommerceProductDetail";
import EcommerceOrders from "../pages/Ecommerce/EcommerceOrders/index";
import EcommerceCustomers from "../pages/Ecommerce/EcommerceCustomers/index";
import EcommerceCart from "../pages/Ecommerce/EcommerceCart";
import EcommerceCheckout from "../pages/Ecommerce/EcommerceCheckout";
import EcommerceShops from "../pages/Ecommerce/EcommerceShops/index";
import EcommerceAddProduct from "../pages/Ecommerce/EcommerceAddProduct";

//Email
import EmailInbox from "../pages/Email/email-inbox";
import EmailRead from "../pages/Email/email-read";
import EmailBasicTemplte from "../pages/Email/email-basic-templte";
import EmailAlertTemplte from "../pages/Email/email-template-alert";
import EmailTemplateBilling from "../pages/Email/email-template-billing";

//Invoices
import InvoicesList from "../pages/Invoices/invoices-list";
import InvoiceDetail from "../pages/Invoices/invoices-detail";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

// Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Login2 from "../pages/AuthenticationInner/Login2";
import Register1 from "../pages/AuthenticationInner/Register";
import Register2 from "../pages/AuthenticationInner/Register2";
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import ForgetPwd2 from "../pages/AuthenticationInner/ForgetPwd2";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2";
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail";
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2";
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification";
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2";
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification";
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2";

// Dashboard
import Dashboard from "../pages/Dashboard/Dashboard";
import DashboardSaas from "../pages/Dashboard-saas/index";
import DashboardCrypto from "../pages/Dashboard-crypto/index";
import DashboardBlog from "../pages/Dashboard-blog/index";

//Crypto
import CryptoWallet from "../pages/Crypto/CryptoWallet/crypto-wallet";
import CryptoBuySell from "../pages/Crypto/crypto-buy-sell";
import CryptoExchange from "../pages/Crypto/crypto-exchange";
import CryptoLending from "../pages/Crypto/crypto-lending";
import CryptoOrders from "../pages/Crypto/CryptoOrders/crypto-orders";
import CryptoKYCApplication from "../pages/Crypto/crypto-kyc-application";
import CryptoIcoLanding from "../pages/Crypto/CryptoIcoLanding/index";

// Charts
import ChartApex from "../pages/Charts/Apexcharts";
import ChartistChart from "../pages/Charts/ChartistChart";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import EChart from "../pages/Charts/EChart";
import SparklineChart from "../pages/Charts/SparklineChart";
import ChartsKnob from "../pages/Charts/charts-knob";
import ReCharts from "../pages/Charts/ReCharts";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";
import MapsLeaflet from "../pages/Maps/MapsLeaflet";

//Icons
import IconBoxicons from "../pages/Icons/IconBoxicons";
import IconDripicons from "../pages/Icons/IconDripicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import IconFontawesome from "../pages/Icons/IconFontawesome";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";
import DragDropTables from "../pages/Tables/DragDropTables";

// Forms
import FormElements from "../pages/Forms/FormElements/index";
import FormLayouts from "../pages/Forms/FormLayouts";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormRepeater from "../pages/Forms/FormRepeater";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";
import DualListbox from "../pages/Forms/DualListbox";

//Ui
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiColors from "../pages/Ui/UiColors";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiSweetAlert from "../pages/Ui/UiSweetAlert";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiRating from "../pages/Ui/UiRating";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiNotifications from "../pages/Ui/ui-notifications";
import UiToast from "../pages/Ui/UiToast";
import UiOffCanvas from "../pages/Ui/UiOffCanvas";
import Breadcrumb from "../pages/Ui/UiBreadcrumb";
import UiPlaceholders from "../pages/Ui/UiPlaceholders";

//Pages
import PagesStarter from "../pages/Utility/pages-starter";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import PagesTimeline from "../pages/Utility/pages-timeline";
import PagesFaqs from "../pages/Utility/pages-faqs";
import PagesPricing from "../pages/Utility/pages-pricing";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

//Contacts
import ContactsGrid from "../pages/Contacts/contacts-grid";
import ContactsList from "../pages/Contacts/ContactList/contacts-list";
import ContactsProfile from "../pages/Contacts/ContactsProfile/contacts-profile";

//Blog
import BlogList from "../pages/Blog/BlogList/index";
import BlogGrid from "../pages/Blog/BlogGrid/index";
import BlogDetails from "../pages/Blog/BlogDetails";

///////////////////////////////////////////////////////////////
//Security
import UserList from "../pages/Security/User/user-list";

//Membership
import CustomerSearchPage from "../pages/Management/Customer/CustomerSearch/customer-search-page";
import CustomerList from "../pages/Management/Customer/CustomerList/customer-list";
import CustomerOrderHistory from "../pages/Management/Customer/CustomerOrderHistory/customer-order-history";
import CustomerOrderTransactions from "../pages/Management/Customer/CustomerOrderHistory/customer-order-transactions";
import CustomerOrderPurchases from "../pages/Management/Customer/CustomerOrderHistory/customer-order-purchases";
import OrderDetail from "../pages/Management/Customer/CustomerOrderHistory/order-detail";
import MembershipPackage from "../pages/Management/Membership/Package/package-list";
import MembershipList from "../pages/Management/Membership/Package/membership-list";
import MembershipPayment from "../pages/Management/Membership/Payment/membership-payment";
import CustomerAddInvoice from "../pages/Management/Membership/Invoice/add-invoice";
import MembershipPackageDetailPage from "pages/Management/Membership/Package/package-detail-page";
import AddMembershipPackage from "pages/Management/Membership/Package/add-membership";
import EditMembershipPackage from "pages/Management/Membership/Package/edit-membership";
import BenefitList from "pages/Management/Membership/Package/benefit-list";
import EditBenefit from "pages/Management/Membership/Package/edit-benefit";
import AddBenefit from "pages/Management/Membership/Package/add-benefit";
import AffiliatedDetailPage from "pages/Management/Customer/CustomerSearch/affiliated-detail-page";
import AffiliatedMembershipBeneficiariesPage from "pages/Management/Customer/CustomerSearch/affiliated-membership-beneficiaries-page";
import AddBeneficiaryPage from "pages/Management/Customer/CustomerSearch/add-beneficiary-page";
import EditBeneficiary from "pages/Management/Customer/CustomerSearch/edit-beneficiary-page";
import AffiliatedMembershipDetailPage from "pages/Management/Customer/CustomerSearch/affiliated-membership-detail-page";
import AffiliatedMembershipCancelPage from "pages/Management/Customer/CancelMembership/affiliated-membership-cancel-page";
import TermList from "pages/Management/Term/term-list";
import AddTerm from "pages/Management/Term/add-term";
import EditTerm from "pages/Management/Term/edit-term";
import QuestionList from "pages/Management/Question/question-list";
import AddQuestion from "pages/Management/Question/add-question";
import EditQuestion from "pages/Management/Question/edit-question";
import RegisterUser from "pages/Security/User/register-user";
import EditProfilePage from "pages/Account/edit-profile-page";
import QuestionMembershipPage from "pages/Management/QuestionMembership/question-membership-page";
import AddQuestionMembership from "pages/Management/QuestionMembership/add-question-membership";
import EditQuestionMembership from "pages/Management/QuestionMembership/edit-question-membership";
import UpgradeMembershipPage from "pages/Management/Customer/UpgradeMembership/UpgradeMembershipPage";
import RoleList from "pages/Security/Role/role-list";
import AddRole from "pages/Security/Role/add-role";
import EditRole from "pages/Security/Role/edit-role";
import RoleResourceList from "pages/Security/Role/role-resource-list";
import UsersListPage from "pages/Security/User/users-list-page";
import RoleUserList from "pages/Security/User/role-user-list";
import ChangePassword from "pages/Account/change-password";
import RecoveryPassword from "pages/Authentication/recovery-password";
import LaboratoryServicePage from "pages/Medical/laboratory-service-page";
import PatientSearchPage from "pages/Laboratory/patient-search-page";
import ExamHistoricalPage from "pages/Laboratory/Exam/Historical/exam-historical-page";
import ExamPatientDetailPage from "pages/Laboratory/Exam/Historical/Detail/exam-patient-detail-page";
import ExamPatientResultByMedicalServicePage from "pages/Laboratory/Exam/Historical/Result/exam-patient-result-by-medical-service-page"
import ExamPatientResultPage from "pages/Laboratory/Exam/Historical/Result/exam-patient-result-page";
import CustomerStatePage from "pages/Management/Customer/CustomerState/customer-state-page";
import ProductSearchPage from "pages/Pharmacy/product-search-page";
import ProductSearchResultPage from "pages/Pharmacy/product-search-result-page";
import ProductSearchDetailPage from "pages/Pharmacy/product-search-detail-page";
import PatientList from "pages/Medical/Patient/patient-list";
import PrescriptionTemplatesPage from "pages/Medical/PrescriptionTemplate/prescription-templates-page";
import PrescriptionHistoricalSearchPage from "pages/Medical/prescription-historical-search-page";
import PrescriptionHistoricalPage from "pages/Medical/PrescriptionHistorical/prescription-historical-page";
import PrescriptionDetailPage from "pages/Medical/PrescriptionHistorical/prescription-detail-page";
import PrescriptionTemplateAddPage from "pages/Medical/PrescriptionTemplate/prescription-template-add-page";
import PrescriptionTemplateEditPage from "pages/Medical/PrescriptionTemplate/prescription-template-edit-page";
import PrescriptionAddPage from "pages/Medical/Prescription/prescription-add-page";
import ProductRelatedPage from "pages/Pharmacy/ProductRelated/product-related-page";
import PromotionsListPage from "pages/Management/PromotionsList/promotions-list-page";
import PromotionsDetailPage from "pages/Management/PromotionsList/promotions-detail-page";
import PromotionsAddPage from "pages/Management/PromotionsList/promotions-add-page";
import PromotionsEditPage from "pages/Management/PromotionsList/promotions-edit-page";
import DeliveryPackageListPage from "pages/DeliveryPackage/delivery-package-list-page";
import DeliveryPackageDetailPage from "pages/DeliveryPackage/delivery-package-detail-page";
import ServiceCatalogListPage from "pages/ServiceCatalog/service-catalog-list-page";
import ServiceCatalogDetailPage from "pages/ServiceCatalog/service-catalog-detail-page";
import SampleCollectListPage from "pages/SamplesCollect/sample-collect-list-page";
import SampleCollectDetailPage from "pages/SamplesCollect/sample-collect-detail-page";
import PharmacyFeeListPage from "pages/Management/FeeList/pharmacy-fee-list-page";
import LaboratoryFeeListPage from "pages/Management/FeeList/laboratory-fee-list-page";
import LaboratoryPrescriptionAddPage from "pages/Laboratory/Prescription/laboratory-prescription-add-page";
import LaboratoryPrescriptionTemplateAddPage from "pages/Laboratory/Prescription/laboratory-prescription-template-add-page";
import LaboratoryPrescriptionTemplateEditPage from "pages/Laboratory/Prescription/laboratory-prescription-template-edit-page";
import LaboratoryPrescriptionHistoricalSearchPage from "pages/Laboratory/Prescription/laboratory-prescription-historical-search-page";
import LaboratoryPrescriptionTemplatesPage from "pages/Laboratory/Prescription/laboratory-prescription-templates-page";
import LaboratoryPrescriptionHistoricalPage from "pages/Laboratory/Prescription/PrescriptionHistorical/laboratory-prescription-historical-page";
import LaboratoryPrescriptionDetailPage from "pages/Laboratory/Prescription/PrescriptionHistorical/laboratory-prescription-detail-page";
import PromotionNotificationAddPage from "pages/Management/PromotionNotification/promotion-notification-add-page";
///////////////////////////////////////////////////////////////

const authProtectedRoutes = [
  { path: "/security-user", component: UsersListPage, permission: "menu_users" },
  { path: "/security-user-groups/:id", component: RoleUserList, permission: "menu_users" },
  { path: "/security-groups", component: RoleList, permission: "menu_groups" },
  { path: "/security-groups-new", component: AddRole, permission: "menu_groups" },
  { path: "/security-groups-edit/:id", component: EditRole, permission: "menu_groups" },
  { path: "/security-groups-options/:id", component: RoleResourceList, permission: "menu_groups" },
  { path: "/security-register-user", component: RegisterUser, permission: "menu_add_users" },
  { path: "/membership-customer-search", component: CustomerSearchPage, permission: "menu_affiliateds" },
  { path: "/membership-customer", component: CustomerList, permission: "menu_affiliateds" },
  { path: "/membership-customer-profile/:id", component: AffiliatedDetailPage, permission: "menu_affiliateds" },
  {
    path: "/membership-customer-profile-status/:id",
    component: CustomerStatePage,
    permission: "menu_affiliateds"
  },
  {
    path: "/membership-customer-profile-upgrade/:id",
    component: UpgradeMembershipPage,
    permission: "menu_affiliateds"
  },
  {
    path: "/membership-customer-detail/:account/:membershipAffiliatedId",
    component: AffiliatedMembershipDetailPage,
    permission: "menu_affiliateds"
  },
  {
    path: "/membership-customer-beneficiaries/:account/:membershipAffiliatedId",
    component: AffiliatedMembershipBeneficiariesPage,
    permission: "menu_affiliateds"
  },
  {
    path: "/membership-customer-cancel/:account/:membershipId/:membershipAffiliatedId",
    component: AffiliatedMembershipCancelPage,
    permission: "menu_affiliateds"
  },
  {
    path: "/membership-beneficiary-new/:account/:membershipAffiliatedId",
    component: AddBeneficiaryPage,
    permission: "menu_affiliateds"
  },
  {
    path: "/membership-beneficiary-edit/:account/:membershipAffiliatedId/:id",
    component: EditBeneficiary,
    permission: "menu_affiliateds"
  },
  { path: "/membership-customer-orders/:id", component: CustomerOrderHistory, permission: "menu_affiliateds" },
  { path: "/membership-transactions", component: CustomerOrderTransactions, permission: "membership-transactions" },
  { path: "/report/purchases", component: CustomerOrderPurchases, permission: "membership-transactions" },
  { path: "/membership-customer-order/:id", component: OrderDetail, permission: "membership-transactions" },
  { path: "/membership-customer-invoice/:id", component: CustomerAddInvoice, permission: "membership-transactions" },
  { path: "/membership-package", component: MembershipPackage, permission: "menu_memberships" },
  { path: "/membership-list", component: MembershipList, permission: "menu_memberships_list" },
  {
    path: "/membership-package-detail/:id",
    component: MembershipPackageDetailPage,
    permission: "menu_memberships"
  },
  { path: "/membership-package-new", component: AddMembershipPackage, permission: "menu_memberships" },
  { path: "/membership-package-edit/:id", component: EditMembershipPackage, permission: "menu_memberships" },
  { path: "/membership-package-benefits/:id", component: BenefitList, permission: "menu_memberships" },
  { path: "/membership-benefit-edit/:membershipId/:id", component: EditBenefit, permission: "menu_memberships" },
  { path: "/membership-benefit-new/:id", component: AddBenefit, permission: "menu_memberships" },
  { path: "/membership-customer-payment/:id", component: MembershipPayment, permission: "menu_memberships" },
  { path: "/terms-list", component: TermList, permission: "menu_terms" },
  { path: "/terms-new", component: AddTerm, permission: "menu_terms" },
  { path: "/terms-edit/:type/:id", component: EditTerm, permission: "menu_terms" },
  { path: "/questions-list", component: QuestionList, permission: "menu_question_list" },
  { path: "/questions-new", component: AddQuestion, permission: "menu_question_list" },
  { path: "/questions-edit/:id", component: EditQuestion, permission: "menu_question_list" },

  { path: "/question-membership-list", component: QuestionMembershipPage, permission: "menu_question_membership" },
  { path: "/question-membership-new", component: AddQuestionMembership, permission: "menu_question_membership" },
  { path: "/question-membership-edit/:id", component: EditQuestionMembership, permission: "menu_question_membership" },

  { path: "/laboratory-service-list", component: LaboratoryServicePage, permission: "menu_laboratory_services" },
  { path: "/laboratory-patient-list", component: PatientSearchPage, permission: "menu_laboratory_historical_exam" },
  {
    path: "/laboratory-patient-exam-historical/:account",
    component: ExamHistoricalPage,
    permission: "menu_laboratory_historical_exam"
  },
  {
    path: "/laboratory-patient-exam-detail/:account/:id",
    component: ExamPatientDetailPage,
    permission: "menu_laboratory_historical_exam"
  },
  {
    path: "/laboratory-patient-result-by-medical-service/:account/:id",
    component: ExamPatientResultByMedicalServicePage,
    permission: "menu_laboratory_historical_exam"
  },
  {
    path: "/laboratory-patient-exam-result/:account/:appointmentId/:resultId",
    component: ExamPatientResultPage,
    permission: "menu_laboratory_historical_exam"
  },

  {
    path: "/pharmacy-product-search",
    component: ProductSearchPage,
    permission: "menu_pharmacy_product_search"
  },
  {
    path: "/pharmacy-product-search-results/:searchType/:query",
    component: ProductSearchResultPage,
    permission: "menu_pharmacy_product_search"
  },
  {
    path: "/pharmacy-product-detail/:id",
    component: ProductSearchDetailPage,
    permission: "menu_pharmacy_product_search"
  },
  {
    path: "/medical-patients",
    component: PatientList,
    permission: "menu_medical_patients"
  },
  {
    path: "/medical-prescription-templates",
    component: PrescriptionTemplatesPage,
    permission: "menu_medical_prescription_templates"
  },
  {
    path: "/medical-add-prescription/:accountCode?",
    component: PrescriptionAddPage,
    permission: "menu_medical_add_prescription"
  },
  {
    path: "/laboratory-add-prescription/:accountCode?",
    component: LaboratoryPrescriptionAddPage,
    permission: "menu_laboratory_add_prescription"
  },
  {
    path: "/pharmacy-related-products",
    component: ProductRelatedPage,
    permission: "menu_related_products"
  },

  {
    path: "/laboratory-prescription-templates",
    component: LaboratoryPrescriptionTemplatesPage,
    permission: "menu_laboratory_prescription_templates"
  },
  {
    path: "/medical-add-prescription-template",
    component: PrescriptionTemplateAddPage,
    permission: "menu_medical_prescription_templates"
  },
  {
    path: "/medical-edit-prescription-template/:id",
    component: PrescriptionTemplateEditPage,
    permission: "menu_medical_prescription_templates"
  },
  {
    path: "/laboratory-add-prescription-template",
    component: LaboratoryPrescriptionTemplateAddPage,
    permission: "menu_laboratory_prescription_templates"
  },
  {
    path: "/laboratory-edit-prescription-template/:id",
    component: LaboratoryPrescriptionTemplateEditPage,
    permission: "menu_laboratory_prescription_templates"
  },
  {
    path: "/medical-prescription-search",
    component: PrescriptionHistoricalSearchPage,
    permission: "menu_medical_prescription_historical"
  },
  {
    path: "/laboratory-prescription-search",
    component: LaboratoryPrescriptionHistoricalSearchPage,
    permission: "menu_laboratory_prescription_historical"
  },
  {
    path: "/medical-prescription-historical/:accountCode",
    component: PrescriptionHistoricalPage,
    permission: "menu_medical_prescription_historical"
  },
  {
    path: "/laboratory-prescription-historical/:accountCode",
    component: LaboratoryPrescriptionHistoricalPage,
    permission: "menu_laboratory_prescription_historical"
  },
  {
    path: "/medical-prescription-detail/:accountCode/:id",
    component: PrescriptionDetailPage,
    permission: "menu_medical_prescription_historical"
  },
  {
    path: "/laboratory-prescription-detail/:accountCode/:id",
    component: LaboratoryPrescriptionDetailPage,
    permission: "menu_medical_prescription_historical"
  },
  {
    path: "/management-promotions-list",
    component: PromotionsListPage,
    permission: "menu_promotions"
  },
  {
    path: "/management-promotions-detail/:id",
    component: PromotionsDetailPage,
    permission: "menu_promotions"
  },
  {
    path: "/management-promotions-add",
    component: PromotionsAddPage,
    permission: "menu_promotions"
  },
  {
    path: "/management-promotion-notification",
    component: PromotionNotificationAddPage,
    permission: "menu_promotion_notifications"
  },
  {
    path: "/management-promotions-edit/:id",
    component: PromotionsEditPage,
    permission: "menu_promotions"
  },
  {
    path: "/delivery-packages-list",
    component: DeliveryPackageListPage,
    permission: "menu_delivery_packages_list"
  },
  {
    path: "/delivery-packages-detail/:id",
    component: DeliveryPackageDetailPage,
    permission: "menu_delivery_packages_list"
  },
  {
    path: "/service-catalog",
    component: ServiceCatalogListPage,
    permission: "menu_sample_collect_list"
  },
  {
    path: "/service-catalog-detail/:id",
    component: ServiceCatalogDetailPage,
    permission: "menu_sample_collect_list"
  },
  {
    path: "/sample-collect-list",
    component: SampleCollectListPage,
    permission: "menu_sample_collect_list"
  },
  {
    path: "/sample-collect-detail/:id",
    component: SampleCollectDetailPage,
    permission: "menu_sample_collect_list"
  },
  {
    path: "/pharmacy-fee-range",
    component: PharmacyFeeListPage,
    permission: "menu_pharmacy_fee_list"
  },
  {
    path: "/laboratory-fee-range",
    component: LaboratoryFeeListPage,
    permission: "menu_laboratory_fee_range"
  },
  { path: "/dashboard", component: Dashboard },
  { path: "/dashboard-saas", component: DashboardSaas },
  { path: "/dashboard-crypto", component: DashboardCrypto },
  { path: "/dashboard-blog", component: DashboardBlog },

  //Crypto
  { path: "/crypto-wallet", component: CryptoWallet },
  { path: "/crypto-buy-sell", component: CryptoBuySell },
  { path: "/crypto-exchange", component: CryptoExchange },
  { path: "/crypto-lending", component: CryptoLending },
  { path: "/crypto-orders", component: CryptoOrders },
  { path: "/crypto-kyc-application", component: CryptoKYCApplication },

  //profile
  { path: "/profile", component: UserProfilePage },
  { path: "/edit-profile", component: EditProfilePage },
  { path: "/change-password", component: ChangePassword },

  //chat
  { path: "/chat", component: Chat },

  //File Manager
  { path: "/apps-filemanager", component: FileManager },

  //calendar
  { path: "/calendar", component: Calendar },

  //Ecommerce
  // { path: "/ecommerce-products/:id", component: EcommerceProducts },
  { path: "/ecommerce-products", component: EcommerceProducts },
  { path: "/ecommerce-product-details/:id", component: EcommerceProductDetail },

  { path: "/ecommerce-orders", component: EcommerceOrders },
  { path: "/ecommerce-customers", component: EcommerceCustomers },
  { path: "/ecommerce-cart", component: EcommerceCart },
  { path: "/ecommerce-checkout", component: EcommerceCheckout },
  { path: "/ecommerce-shops", component: EcommerceShops },
  { path: "/ecommerce-add-product", component: EcommerceAddProduct },

  //Email
  { path: "/email-inbox", component: EmailInbox },
  { path: "/email-read", component: EmailRead },
  { path: "/email-template-basic", component: EmailBasicTemplte },
  { path: "/email-template-alert", component: EmailAlertTemplte },
  { path: "/email-template-billing", component: EmailTemplateBilling },

  //Invoices
  { path: "/invoices-list", component: InvoicesList },
  { path: "/invoices-detail", component: InvoiceDetail },
  { path: "/invoices-detail/:id", component: InvoiceDetail },

  // Tasks
  { path: "/tasks-list", component: TasksList },
  { path: "/tasks-kanban", component: TasksKanban },
  { path: "/tasks-create", component: TasksCreate },

  //Projects
  { path: "/projects-grid", component: ProjectsGrid },
  { path: "/projects-list", component: ProjectsList },
  { path: "/projects-overview", component: ProjectsOverview },
  { path: "/projects-overview/:id", component: ProjectsOverview },
  { path: "/projects-create", component: ProjectsCreate },

  // Contacts
  { path: "/contacts-grid", component: ContactsGrid },
  { path: "/contacts-list", component: ContactsList },
  { path: "/contacts-profile", component: ContactsProfile },

  //Blog
  { path: "/blog-list", component: BlogList },
  { path: "/blog-grid", component: BlogGrid },
  { path: "/blog-details", component: BlogDetails },

  //Charts
  { path: "/apex-charts", component: ChartApex },
  { path: "/chartist-charts", component: ChartistChart },
  { path: "/chartjs-charts", component: ChartjsChart },
  { path: "/e-charts", component: EChart },
  { path: "/sparkline-charts", component: SparklineChart },
  { path: "/charts-knob", component: ChartsKnob },
  { path: "/re-charts", component: ReCharts },

  // Icons
  { path: "/icons-boxicons", component: IconBoxicons },
  { path: "/icons-dripicons", component: IconDripicons },
  { path: "/icons-materialdesign", component: IconMaterialdesign },
  { path: "/icons-fontawesome", component: IconFontawesome },

  // Tables
  { path: "/tables-basic", component: BasicTables },
  { path: "/tables-datatable", component: DatatableTables },
  { path: "/tables-responsive", component: ResponsiveTables },
  { path: "/tables-editable", component: EditableTables },
  { path: "/tables-dragndrop", component: DragDropTables },

  // Maps
  { path: "/maps-google", component: MapsGoogle },
  { path: "/maps-vector", component: MapsVector },
  { path: "/maps-leaflet", component: MapsLeaflet },

  // Forms
  { path: "/form-elements", component: FormElements },
  { path: "/form-layouts", component: FormLayouts },
  { path: "/form-advanced", component: FormAdvanced },
  { path: "/form-editors", component: FormEditors },
  { path: "/form-mask", component: FormMask },
  { path: "/form-repeater", component: FormRepeater },
  { path: "/form-uploads", component: FormUpload },
  { path: "/form-wizard", component: FormWizard },
  { path: "/form-validation", component: FormValidations },
  { path: "/form-xeditable", component: FormXeditable },
  { path: "/dual-listbox", component: DualListbox },

  // Ui
  { path: "/ui-alerts", component: UiAlert },
  { path: "/ui-buttons", component: UiButtons },
  { path: "/ui-cards", component: UiCards },
  { path: "/ui-carousel", component: UiCarousel },
  { path: "/ui-colors", component: UiColors },
  { path: "/ui-dropdowns", component: UiDropdown },
  { path: "/ui-general", component: UiGeneral },
  { path: "/ui-grid", component: UiGrid },
  { path: "/ui-images", component: UiImages },
  { path: "/ui-lightbox", component: UiLightbox },
  { path: "/ui-modals", component: UiModal },
  { path: "/ui-progressbars", component: UiProgressbar },
  { path: "/ui-sweet-alert", component: UiSweetAlert },
  { path: "/ui-tabs-accordions", component: UiTabsAccordions },
  { path: "/ui-typography", component: UiTypography },
  { path: "/ui-video", component: UiVideo },
  { path: "/ui-session-timeout", component: UiSessionTimeout },
  { path: "/ui-rating", component: UiRating },
  { path: "/ui-rangeslider", component: UiRangeSlider },
  { path: "/ui-notifications", component: UiNotifications },
  { path: "/ui-toasts", component: UiToast },
  { path: "/ui-offcanvas", component: UiOffCanvas },
  { path: "/ui-breadcrumb", component: Breadcrumb },
  { path: "/ui-placeholders", component: UiPlaceholders },
  //Utility
  { path: "/pages-starter", component: PagesStarter },
  { path: "/pages-timeline", component: PagesTimeline },
  { path: "/pages-faqs", component: PagesFaqs },
  { path: "/pages-pricing", component: PagesPricing },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/dashboard" /> },
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/recovery-password", component: RecoveryPassword },

  /*{ path: "/register", component: Register },
  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
  { path: "/crypto-ico-landing", component: CryptoIcoLanding },

  // Authentication Inner
  { path: "/pages-login", component: Login1 },
  { path: "/pages-login-2", component: Login2 },

  { path: "/pages-register", component: Register1 },
  { path: "/pages-register-2", component: Register2 },

  { path: "/page-recoverpw", component: Recoverpw },
  { path: "/pages-recoverpw-2", component: Recoverpw2 },

  { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/pages-forgot-pwd-2", component: ForgetPwd2 },

  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/auth-lock-screen-2", component: LockScreen2 },
  { path: "/page-confirm-mail", component: ConfirmMail },
  { path: "/page-confirm-mail-2", component: ConfirmMail2 },
  { path: "/auth-email-verification", component: EmailVerification },
  { path: "/auth-email-verification-2", component: EmailVerification2 },
  { path: "/auth-two-step-verification", component: TwostepVerification },
  { path: "/auth-two-step-verification-2", component: TwostepVerification2 },*/
];

export { authProtectedRoutes, publicRoutes };
