import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getCatalogs } from "store/actions";
import { Button, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import PrescriptionServiceCard from "./prescription-service-card";
import PrescriptionServiceForm from "./prescription-service-form";

const LaboratoryPrescriptionServices = ({ services, setServices }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getCatalogs());
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [showServiceAdded, setShowServiceAdded] = useState(false);

  const currencyCodeList = useSelector(
    state => state.catalogs.currencyCodeList
  );

  const getCurrencySymbol = currencyCode => {
    const found = currencyCodeList.find(
      current => current.code == currencyCode
    );
    return found ? found.currency : currencyCode;
  };

  const onDelete = productDetail => {
    setServices(
      services.filter(current => current.product.id != productDetail.product.id)
    );
  };

  const onUpdate = productDetail => {
    const productIndex = services.findIndex(
      product => product.medicalService.id == productDetail.medicalService.id
    );
    if (productIndex >= 0) {
      const copy = [...services];
      copy[productIndex] = {
        ...copy[productIndex],
        quantity: productDetail.quantity,
        doctorPrescription: productDetail.doctorPrescription,
      };
      setServices(copy);
    }
  };

  const onUpdateQuantity = (productDetail, quantity) => {
    
    const productIndex = services.findIndex(
      product => product.medicalService.id == productDetail.medicalService.id
    );
    if (productIndex >= 0) {
      const copy = [...services];
      copy[productIndex] = {
        ...copy[productIndex],
        quantity: copy[productIndex].quantity + quantity,
      };
      setServices(copy);
    }
  };

  const onAdd = serviceList => {

    let newServices = [...services];
    
    serviceList.forEach(service => {

      const productIndex = newServices.findIndex(
        product => product.medicalService.id == service.id
      );

      if (productIndex >= 0) {
        newServices[productIndex] = {
          ...newServices[productIndex],
          quantity: newServices[productIndex].quantity
        };
        //setServices(copy);
      } else {
        const prescriptionService = {
          medicalServiceId: service.id,
          currencyCode: service.currencyCode,
          price: service.price,
          quantity: 1,
          taxPercentage: service.taxPercentage,
          doctorPrescription: service.medicalInstruction,
          medicalService: service,

        };

        newServices.push(prescriptionService);

      }

      setServices(newServices);
    });
    setShowServiceAdded(true);
  };


  return (
    <React.Fragment>
      {services.length == 0 ? (
        <Row>
          <Col>
            <h5>{t("Without Services")}</h5>
          </Col>
        </Row>
      ) : null}
      {services.map((prescriptionService, index) => (
        prescriptionService.medicalService &&
        <PrescriptionServiceCard
          key={index}
          prescriptionService={prescriptionService}
          onDelete={onDelete}
          onUpdate={onUpdate}
          onUpdateQuantity={onUpdateQuantity}
          getCurrencySymbol={getCurrencySymbol}
        />

      ))}
      <Row className="mt-1">
        <Col>
          <div className="button-items">
            <Button
              type="button"
              color="primary"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <i className="mdi mdi-plus-circle-outline" /> {t("Add Service")}
            </Button>

          </div>
        </Col>
      </Row>
      {showModal ? (
        <PrescriptionServiceForm
          isOpen={showModal}
          addService={onAdd}
          services={services}
          getCurrencySymbol={getCurrencySymbol}
          onCancel={() => {
            setShowModal(false);
          }}
        />
      ) : null}

      {showServiceAdded ?

        <SweetAlert
          title={t('The service was added correctly')}
          success
          confirmBtnBsStyle="success"
          confirmBtnText={t('Accept')}
          onConfirm={() => {
            setShowServiceAdded(false);
          }}
        >

        </SweetAlert>
        : null}
    </React.Fragment>
  );
};

LaboratoryPrescriptionServices.propTypes = {
  services: PropTypes.array.isRequired,
  setServices: PropTypes.func.isRequired,
};

export default LaboratoryPrescriptionServices;
