import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";
import { numberFormat } from "helpers/utils";

const CustomerStateChart = ({
  amountWithMembership,
  amountWithoutMembership,
  totalSavings,
  medicalCategories,
  selectedCategory,
  getCurrencySymbol,
}) => {
  const { t } = useTranslation();

  const [series, setSeries] = useState([]);

  const [options, setOptions] = useState({
    chart: {
      id: "customerChart",
    },
    plotOptions: {
      pie: {
        donut: {
          size: "80%",
        },
      },
    },
    labels: ["Series 1", "Series 2", "Series 3", "Series 4"],
    colors: ["#34c38f", "#556ee6", "#f46a6a", "#50a5f1"],
    legend: {
      show: false,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  });

  useEffect(() => {

    if(medicalCategories) {
      let data = [];
      let labels = [];
      let colors = [];

      labels.push("Vitae", "Market", "Total");
      data.push(amountWithMembership, amountWithoutMembership, totalSavings);
      colors.push('#9CE245', '#00B2BC', '#39b54A');

      setOptions({
        ...options,
        labels: labels,
        colors: colors,
      });
      setSeries(data);
    }
    
  }, [medicalCategories]);

  return (
    <React.Fragment>
      <ReactApexChart
        options={options}
        series={series}
        type="donut"
        height="320"
        className="apex-charts"
      />
      <div
        style={{
          width: "100%",
          textAlign: "center",
          marginTop: "-230px",
          fontSize: "16px",
        }}
      >
        <div className="text-muted">{t("Vitae value")}</div>
        <h3>
          {getCurrencySymbol("CRC")}
          {numberFormat(amountWithMembership)}
        </h3>

        <div className="text-muted">{t("Market value")}</div>
        <h3>
          {getCurrencySymbol("CRC")}
          {numberFormat(amountWithoutMembership)}
        </h3>

        <div className="text-muted">{t("Saving")}</div>
        <h3>
          {getCurrencySymbol("CRC")}
          {numberFormat(totalSavings)}
        </h3>
      </div>
    </React.Fragment>
  );
};

CustomerStateChart.propTypes = {
  amountWithMembership: PropTypes.number.isRequired,
  amountWithoutMembership: PropTypes.number.isRequired,
  totalSavings: PropTypes.number.isRequired,
  medicalCategories: PropTypes.array.isRequired,
  selectedCategory: PropTypes.object,
  getCurrencySymbol: PropTypes.func.isRequired,
};
export default CustomerStateChart;
