import React from "react"
import PropTypes from 'prop-types'
import { Col, Card, CardBody, CardImg } from "reactstrap"
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const MembershipDetailCard = ({ membership, getCurrencySymbol }) => {

  const benefitsList = useSelector(state => state.memberships.benefits);
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Col xl="6" md="12">
        <Card className="plan-box">
          <CardBody className="p-4">
            <CardImg className="img-fluid" src={membership.picture} alt="Membership" />
          </CardBody>
        </Card>
      </Col>

      <Col xl="6" md="12">
        <Card className="plan-box">
          <CardBody className="p-4">

            <div className="d-flex">
              <div className="flex-grow-1">
                <h3>{membership.name}</h3>
                <p className="text-muted">{membership.description}</p>
              </div>
              <div className="ms-3">
                <i
                  className={"bx bx-gift h1 text-primary"}
                />
              </div>
            </div>
            <div className="py-4">
              <h2>
                <sup>
                  <small>{getCurrencySymbol(membership.currencyCode)}</small>
                </sup>{" "}
                {membership.price}/{" "}
                <span className="font-size-13">{t('by month')}</span>
              </h2>
            </div>

            <div className="py-2">
              <h2>
                <sup>
                  <small>{t('Minimum Validity Period')}{": "}
                    {membership.validityMonths ? membership.validityMonths : 0}
                  </small>{" "}{t('months')}
                </sup>
              </h2>
            </div>

            <div className="py-2">
              <h2>
                <sup>
                  <small>{t('Discount Percentage')}{": "}
                    {membership.discountType ? membership.discount : 0}
                  </small>
                </sup>
              </h2>
            </div>

            <div className="py-2">
              <h2>
                <sup>
                  <small>{t('Beneficiaries')}{": "}
                    {membership.maxBeneficiaryQuantity}
                  </small>
                </sup>
              </h2>
            </div>


          </CardBody>
        </Card>
      </Col>

      <Col xl="12" md="12">
        <Card className="plan-box">
          <CardBody className="p-4">

            <div className="d-flex">
              <div className="flex-grow-1">
                <h5>{t('Benefits')}</h5>
              </div>
              <div className="ms-3">
                <i
                  className={"bx bx-gift h1 text-primary"}
                />
              </div>
            </div>

            <div className="plan-features mt-2">
              {benefitsList && benefitsList.map((benefit, i) => (
                <p key={"membershipBenefit" + i}>
                  <i className="bx bx-comment-error text-primary me-2" />{" "}
                  {benefit.description} / {benefit.detail}
                </p>
              ))}
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );

}

MembershipDetailCard.propTypes = {
  membership: PropTypes.object,
  getCurrencySymbol: PropTypes.func
}

export default MembershipDetailCard
