/* PRINCIPAL */
export const CLEAR_PRINCIPAL_STATE = "CLEAR_PRINCIPAL_STATE" 

export const ADD_PRINCIPAL = "ADD_PRINCIPAL"
export const ADD_PRINCIPAL_FAIL = "ADD_PRINCIPAL_FAIL"
export const ADD_PRINCIPAL_SUCCESS = "ADD_PRINCIPAL_SUCCESS"

export const VERIFY_USER_INFORMATION = "VERIFY_USER_INFORMATION"
export const VERIFY_USER_INFORMATION_SUCCESS = "VERIFY_USER_INFORMATION_SUCCESS"
export const VERIFY_USER_INFORMATION_FAIL = "VERIFY_USER_INFORMATION_FAIL"

export const VERIFY_EMAIL_INFORMATION = "VERIFY_EMAIL_INFORMATION"
export const VERIFY_EMAIL_INFORMATION_SUCCESS = "VERIFY_EMAIL_INFORMATION_SUCCESS"
export const VERIFY_EMAIL_INFORMATION_FAIL = "VERIFY_EMAIL_INFORMATION_FAIL"
export const VERIFY_EMAIL_INFORMATION_RESET = "VERIFY_EMAIL_INFORMATION_RESET"

export const GET_PRINCIPAL_DETAIL = "GET_PRINCIPAL_DETAIL"
export const GET_PRINCIPAL_DETAIL_SUCCESS = "GET_PRINCIPAL_DETAIL_SUCCESS"
export const GET_PRINCIPAL_DETAIL_FAIL = "GET_PRINCIPAL_DETAIL_FAIL"

export const GET_PRINCIPAL_DETAIL_BY_ACCOUNT = "GET_PRINCIPAL_DETAIL_BY_ACCOUNT"
export const GET_PRINCIPAL_DETAIL_BY_ACCOUNT_SUCCESS = "GET_PRINCIPAL_DETAIL_BY_ACCOUNT_SUCCESS"
export const GET_PRINCIPAL_DETAIL_BY_ACCOUNT_FAIL = "GET_PRINCIPAL_DETAIL_BY_ACCOUNT_FAIL"

export const SAVE_PRINCIPAL_ROLES = "SAVE_PRINCIPAL_ROLES"
export const SAVE_PRINCIPAL_ROLES_FAIL = "SAVE_PRINCIPAL_ROLES_FAIL"
export const SAVE_PRINCIPAL_ROLES_SUCCESS = "SAVE_PRINCIPAL_ROLES_SUCCESS"

export const GET_PRINCIPAL_BY_ACCOUNT = "GET_PRINCIPAL_BY_ACCOUNT"
export const GET_PRINCIPAL_BY_ACCOUNT_SUCCESS = "GET_PRINCIPAL_BY_ACCOUNT_SUCCESS"
export const GET_PRINCIPAL_BY_ACCOUNT_FAIL = "GET_PRINCIPAL_BY_ACCOUNT_FAIL"

export const GET_ROLE_PRINCIPAL_LIST = "GET_ROLE_PRINCIPAL_LIST"
export const GET_ROLE_PRINCIPAL_LIST_SUCCESS = "GET_ROLE_PRINCIPAL_LIST_SUCCESS"
export const GET_ROLE_PRINCIPAL_LIST_FAIL = "GET_ROLE_PRINCIPAL_LIST_FAIL"

export const CLEAR_PRINCIPAL_CREATION = "CLEAR_PRINCIPAL_CREATION"
export const DELETE_PRINCIPAL = "DELETE_PRINCIPAL"
export const CANCEL_PRINCIPAL = "CANCEL_PRINCIPAL"