import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Card, CardBody, Col, FormGroup, InputGroup, Label, Row } from 'reactstrap';
import { useDropzone } from 'react-dropzone';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import FormFieldBasic from 'pages/Utility/form-field-basic';
import { getValueFromDTO, toDateISOStringFormat } from 'helpers/utils';
import PromotionProducts from './promotion-products';
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { benefitTypes, businessUnitTypesForPromos, discountTypesPromo, promotionSectionTypes, promotionTypes } from 'constants/list-types';
import { showWarningAlert } from 'store/actions';

const PromotionForm = ({ promotion, onSubmit, onBack }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const MAX_SIZE = 524288;
    const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
        maxFiles: 1,
        accept: ['image/png', 'image/jpg', 'image/jpeg'],
        maxSize: MAX_SIZE,
        onDropRejected : () => {
            dispatch(showWarningAlert(t('Attach Promo Files Size')));
        },
        onDrop: (acceptedFiles) => {
            acceptedFiles.forEach((file) => {
                const reader = new FileReader()

                reader.onabort = () => console.log('file reading was aborted')
                reader.onerror = () => console.log('file reading has failed')
                reader.onload = (evt) => {
                    const fileByteArray = [];
                    // Do whatever you want with the file contents
                    const arrayBuffer = evt.target.result,
                    array = new Uint8Array(arrayBuffer);
                    for (const a of array) {
                        fileByteArray.push(a);
                    }
                    
                    setAttachments([
                        //...attachments, 
                        {
                            fileName: file.name.substring(0, file.name.lastIndexOf('.')),
                            type: file.name.substring(file.name.lastIndexOf('.') + 1),
                            picture: fileByteArray
                        }
                    ]);
                }
                reader.readAsArrayBuffer(file)
            })
        }
    });

    const createImageObject = (url) => {

        const fileName = url.substring(url.lastIndexOf('/') + 1);

        const name = fileName.substring(0, fileName.lastIndexOf('.'));
        const extension = fileName.substring(fileName.lastIndexOf('.') + 1);

        return { 
            fileName: name, 
            type: extension, 
            picture: null 
        } 
    }
    const [products, setProducts] = useState(promotion ? promotion.promotionDetails : []);
    const [attachments, setAttachments] = useState(promotion && promotion.picture ? [ createImageObject(promotion.picture) ] : []);
    const [formData, setFormData] = useState({
        code: getValueFromDTO(promotion, 'code'),
        name: getValueFromDTO(promotion, 'name'),
        summary: getValueFromDTO(promotion, 'summary'),
        businessUnitType: getValueFromDTO(promotion, 'businessUnitType'),
        redeemptionType: getValueFromDTO(promotion, 'redeemptionType'),
        redeemptionLimit: getValueFromDTO(promotion, 'redeemptionLimit'),
        promotionType: getValueFromDTO(promotion, 'promotionType'),
        actionType: getValueFromDTO(promotion, 'actionType'),
        sectionType: getValueFromDTO(promotion, 'sectionType'),
        fromDate: getValueFromDTO(promotion, 'fromDate', '', true),
        toDate: getValueFromDTO(promotion, 'toDate', '', true),
        value: getValueFromDTO(promotion, 'value'),
        discount: getValueFromDTO(promotion, 'discount'),
        discountType: getValueFromDTO(promotion, 'discountType'),
        status: getValueFromDTO(promotion, 'status'),
        productsLength: 0,

    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: formData,
        validationSchema:
            Yup.object().shape({
                //code: Yup.string().required().max(45),
                //name: Yup.string().required().max(40),
                //summary: Yup.string().required().max(255),
                businessUnitType: Yup.string().required(),
                sectionType: Yup.string().required(),
                //productsLength: Yup.number().required().min(1, t('You have to add at least one product')),
                //redeemptionLimit: Yup.number().required().min(1),
                //redeemptionType: Yup.string().required(),
               // actionType: Yup.string().required(),
                promotionType: Yup.string().required(),
                fromDate: Yup.string().required(),
                toDate: Yup.string(),
                //discountType: Yup.string().required(),
                //discount: Yup.number().required(),
                value: Yup.number().default(1),
            }),

        onSubmit: (values, actions) => {
            
            actions.setSubmitting(false);
            prepareToSave(values && values.status === 'DRA' ? true : false);
        },
        onReset: (values, helper) => {

        }
    });

    const { touched, errors, values, handleChange, submitForm, handleBlur } = formik;

    const {
        code, name, summary, businessUnitType,
        redeemptionLimit, redeemptionType, productsLength,
        promotionType, actionType, fromDate, toDate, value,
        discount, discountType, status, sectionType
    } = formData;

    const handleOnChange = event => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }
    /*
        useEffect(() => {
            if (acceptedFiles && acceptedFiles.length > 0) {
                let copy = [...attachments];
                setAttachments(copy.concat(acceptedFiles));
            }
        }, [acceptedFiles]);*/

    useEffect(() => {
        setFormData({ ...formData, productsLength: products.length });
    }, [products]);

    const onDeleteAttachment = attachmentIndex => {
        setAttachments(attachments.filter((current, index) => index != attachmentIndex));
    }

    const prepareToSave = (saveAsDraft) => {

        let data = {
            ...formData,
            value: 1,
            fromDate: toDateISOStringFormat(fromDate),
            toDate: toDateISOStringFormat(toDate),
            status: saveAsDraft ? 'DRA' : 'ACT',
            discountType: 'NOT',
            discount: 0
        };

        if (attachments.length > 0) {
            data.promotionPictures = attachments;
            /*
            for (let index = 0; index < attachments.length; index++) {
                const file = attachments[index];
                //const arrayBuffer = await file.arrayBuffer();
                data.promotionPictures.push({
                    //id: file.id,
                    type: file.name.substring(file.name.lastIndexOf('.')),
                    fileName: file.name.substring(0, file.name.lastIndexOf('.') - 1),
                    picture: file.picture
                });
            }*/
        } else {
            dispatch(showWarningAlert(t('Promo Attachment')));
            return;
        }

        data.promotionDetails = products.map(product => ({
            //...product,
            id: product.id,
            itemCode: product.itemCode,
            name: product.name,
            businessUnitType: data.businessUnitType,
            description: product.description,
            unitPrice: product.unitPrice,
            quantity: product.quantity,
            discountPercentage: product.discountPercentage,
            //discountType: product.discountType,
            taxCode: product.taxCode,
            currencyCode: product.currencyCode
        }));

        onSubmit(data);
    }

    return (
        <React.Fragment>
            <Row>
                <Col xl={{ size: 10, offset: 1 }}>
                    <Card>
                        <CardBody>

                            <form className='needs-validation' onSubmit={formik.handleSubmit} onReset={formik.handleReset}>

                                <Row>
                                    <Col md={6}>

                                        <FormFieldBasic
                                            key='businessUnitType'
                                            type='select'
                                            maxLength={100}
                                            fieldName='businessUnitType'
                                            fieldValue={values.businessUnitType}
                                            label={t('Business Unit Type')}
                                            errors={errors}
                                            touched={touched}
                                            handleOnChange={handleOnChange}
                                            handleBlur={handleBlur}
                                            disabled={products.length > 0}
                                        >
                                            <option key="businessUnitTypeNotSelected" value="">{t('Not Selected')}</option>
                                            {businessUnitTypesForPromos.map(current => {
                                                return (<option key={current.code} value={current.code}>{t(current.value)}</option>);
                                            })}
                                        </FormFieldBasic>

                                        {/*
                                        <FormFieldBasic
                                            key='code'
                                            type='text'
                                            maxLength={45}
                                            fieldName='code'
                                            fieldValue={values.code}
                                            label={t('Code')}
                                            errors={errors}
                                            touched={touched}
                                            handleOnChange={handleOnChange}
                                            handleBlur={handleBlur}
                                        />*/}

                                        <FormFieldBasic
                                            key='name'
                                            type='text'
                                            maxLength={25}
                                            fieldName='name'
                                            fieldValue={values.name}
                                            label={t('Title')}
                                            errors={errors}
                                            touched={touched}
                                            handleOnChange={handleOnChange}
                                            handleBlur={handleBlur}
                                        />

                                        <FormFieldBasic
                                            key='summary'
                                            type='textarea'
                                            maxLength={255}
                                            fieldName='summary'
                                            fieldValue={values.summary}
                                            label={t('Summary')}
                                            errors={errors}
                                            touched={touched}
                                            handleOnChange={handleOnChange}
                                            handleBlur={handleBlur}
                                        />
                                    </Col>
                                    <Col md={6}>
                                        {/* attachments */}
                                        <div className='mb-3'>
                                            <Label className="form-label">{t('Attach files')}</Label>
                                            <p className='text-muted'>{t('Attach Promo Files')}</p>

                                            <Row>
                                                <Col>
                                                    <div
                                                        className="dz-message needsclick"
                                                        {...getRootProps({ className: 'dropzone custom-dropzone' })}
                                                    >
                                                        <input {...getInputProps()} />
                                                        <div className="mb-1 text-center">
                                                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                                                        </div>
                                                        <h4 className='text-center'>{t('Drop files here or click to upload.')}</h4>
                                                    </div>
                                                </Col>

                                            </Row>


                                            {attachments.map((attachment, attachmentIndex) => (
                                                <Row key={attachmentIndex}>
                                                    <Col xs={11} className="float-start pt-1">
                                                        <span>{attachment.fileName}.{attachment.type}</span>
                                                    </Col>
                                                    <Col xs={1}>
                                                        <strong className='float-end text-end font-size-18'>
                                                            <i className={'text-danger mdi mdi-close-thick dashboard-option'} onClick={() => { onDeleteAttachment(attachmentIndex) }}></i>
                                                        </strong>
                                                    </Col>

                                                </Row>
                                            ))}
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={6}>

                                        <FormFieldBasic
                                            key='type'
                                            type='select'
                                            fieldName='promotionType'
                                            fieldValue={values.promotionType}
                                            label={t('Promotion Type')}
                                            errors={errors}
                                            touched={touched}
                                            handleOnChange={handleOnChange}
                                            handleBlur={handleBlur}
                                        >
                                            <option key="promotionTypeNotSelected" value="">{t('Not Selected')}</option>
                                            {promotionTypes.map(current => {
                                                return (<option key={current.code} value={current.code}>{t(current.value)}</option>);
                                            })}
                                        </FormFieldBasic>
                                    </Col>
                                    <Col md={6}>
                                        {/*<FormFieldBasic
                                            key='actionType'
                                            type='select'
                                            fieldName='actionType'
                                            fieldValue={values.actionType}
                                            label={t('Action Type')}
                                            errors={errors}
                                            touched={touched}
                                            handleOnChange={handleOnChange}
                                            handleBlur={handleBlur}
                                        >
                                            <option key="actionTypeNotSelected" value="">{t('Not Selected')}</option>
                                            {promotionActionTypes.map(current => {
                                                return (<option key={current.code} value={current.code}>{t(current.value)}</option>);
                                            })}
                                        </FormFieldBasic>*/}

                                        <FormFieldBasic
                                            key='sectionType'
                                            type='select'
                                            fieldName='sectionType'
                                            fieldValue={values.sectionType}
                                            label={t('Display Section')}
                                            errors={errors}
                                            touched={touched}
                                            handleOnChange={handleOnChange}
                                            handleBlur={handleBlur}
                                        >
                                            <option key="sectionTypeNotSelected" value="">{t('Not Selected')}</option>
                                            {promotionSectionTypes.map(current => {
                                                return (<option key={current.code} value={current.code}>{t(current.value)}</option>);
                                            })}
                                        </FormFieldBasic>

                                    </Col>

                                </Row>

                                <Row>
                                    <Col md={6}>
                                        <FormGroup className="mb-4">
                                            <Label>{t("Validity Period")}</Label>
                                            <InputGroup>
                                                <Flatpickr
                                                    name="fromDate"
                                                    className="form-control d-block"
                                                    placeholder="dd M,yyyy"
                                                    options={{
                                                        mode: "range",
                                                        dateFormat: "d-m-Y",
                                                        locale: "es",
                                                    }}
                                                    onChange={dates => {
                                                        setFormData({
                                                            ...formData,
                                                            fromDate: dates[0],
                                                            toDate: dates[1],
                                                        });

                                                    }}
                                                    value={[values.fromDate, values.toDate]}
                                                />
                                            </InputGroup>

                                            {errors.fromDate ? <div className='invalid-feedback'>{errors.fromDate}</div> : null}

                                        </FormGroup>
                                    </Col>
                                    <Col md={6}>
                                        <FormFieldBasic
                                            key='value'
                                            type='hidden'
                                            maxLength={5}
                                            fieldName='value'
                                            fieldValue={values.value}
                                            label={t('Value')}
                                            errors={errors}
                                            touched={touched}
                                            handleOnChange={handleOnChange}
                                            handleBlur={handleBlur}
                                        />
                                    </Col>
                                </Row>

                                {/*<Row>
                                    <Col md={6}>

                                        <FormFieldBasic
                                            key='redeemptionType'
                                            type='select'
                                            fieldName='redeemptionType'
                                            fieldValue={values.redeemptionType}
                                            label={t('Redemption Type')}
                                            errors={errors}
                                            touched={touched}
                                            handleOnChange={handleOnChange}
                                            handleBlur={handleBlur}
                                        >
                                            <option key="redeemptionTypeNotSelected" value="">{t('Not Selected')}</option>
                                            {benefitTypes.map(current => {
                                                return (<option key={current.code} value={current.code}>{t(current.value)}</option>);
                                            })}
                                        </FormFieldBasic>
                                    </Col>
                                    <Col md={6}>
                                        <FormFieldBasic
                                            key='redeemptionLimit'
                                            type='number'
                                            maxLength={5}
                                            fieldName='redeemptionLimit'
                                            fieldValue={values.redeemptionLimit}
                                            label={t('Redemption Limit')}
                                            errors={errors}
                                            touched={touched}
                                            handleOnChange={handleOnChange}
                                            handleBlur={handleBlur}
                                        />
                                    </Col>

                                        </Row>*/}

                                {/*<Row>
                                    <Col md={6}>

                                        <FormFieldBasic
                                            key='discountType'
                                            type='select'
                                            fieldName='discountType'
                                            fieldValue={values.discountType}
                                            label={t('Discount Type')}
                                            errors={errors}
                                            touched={touched}
                                            handleOnChange={handleOnChange}
                                            handleBlur={handleBlur}
                                        >
                                            <option key="discountTypeNotSelected" value="">{t('Not Selected')}</option>
                                            {discountTypesPromo.map(current => {
                                                return (<option key={current.code} value={current.code}>{t(current.value)}</option>);
                                            })}
                                        </FormFieldBasic>
                                    </Col>
                                    <Col md={6}>
                                        <FormFieldBasic
                                            key='discount'
                                            type='number'
                                            maxLength={5}
                                            fieldName='discount'
                                            fieldValue={values.discount}
                                            label={t('Discount')}
                                            errors={errors}
                                            touched={touched}
                                            handleOnChange={handleOnChange}
                                            handleBlur={handleBlur}
                                        />
                                    </Col>

                                </Row>*/}

                                {values.businessUnitType == 'LAB' || values.businessUnitType == 'MED' || values.businessUnitType == 'PHA' ? (
                                    <Row>
                                        <Col sm={12}>
                                            <h4>{values.businessUnitType == 'PHA' ? t('Products to include in the promotion') : t('Services to include in the promotion')}</h4>
                                            <hr />
                                            {/*Prescrption Products*/}
                                            <PromotionProducts
                                                businessUnitType={values.businessUnitType}
                                                products={products}
                                                setProducts={setProducts}
                                            />
                                            <FormFieldBasic
                                                key='productsLength'
                                                type='hidden'
                                                maxLength={200}
                                                fieldName='productsLength'
                                                fieldValue={values.productsLength}
                                                label={t('')}
                                                errors={errors}
                                                touched={touched}
                                                handleOnChange={handleOnChange}
                                                handleBlur={handleBlur}
                                            />
                                        </Col>
                                    </Row>
                                ) : null}


                                <div className="button-items">
                                    <Button type='button' color="primary" className="button" onClick={() => { setFormData({ ...formData, status: 'DRA' }); submitForm(); }}>
                                        <i className="mdi mdi-content-save me-1"></i>{t('Save as Draft')}
                                    </Button>
                                    <Button type='button' color="primary" className="button" onClick={() => { setFormData({ ...formData, status: 'ACT' }); submitForm(); }}>
                                        <i className="mdi mdi-content-save me-1"></i>{t('Public for Sales')}
                                    </Button>
                                    <Button color="light" className="button mr-1" onClick={() => { onBack() }}><i className="mdi mdi-arrow-left me-1"></i>{t('Back')}</Button>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </Col>
            </Row>

        </React.Fragment>
    );
}

PromotionForm.propTypes = {
    promotion: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired
}

export default PromotionForm;