/* PROMOTIONS LIST */
export const CLEAR_PROMOTIONS_LIST_STATE = "CLEAR_PROMOTIONS_LIST_STATE" 

export const GET_PROMOTIONS_LIST = "GET_PROMOTIONS_LIST"
export const GET_PROMOTIONS_LIST_FAIL = "GET_PROMOTIONS_LIST_FAIL"
export const GET_PROMOTIONS_LIST_SUCCESS = "GET_PROMOTIONS_LIST_SUCCESS"

export const GET_PROMOTION_DETAIL = "GET_PROMOTION_DETAIL"
export const GET_PROMOTION_DETAIL_FAIL = "GET_PROMOTION_DETAIL_FAIL"
export const GET_PROMOTION_DETAIL_SUCCESS = "GET_PROMOTION_DETAIL_SUCCESS"

export const ADD_PROMOTION = "ADD_PROMOTION"
export const ADD_PROMOTION_FAIL = "ADD_PROMOTION_FAIL"
export const ADD_PROMOTION_SUCCESS = "ADD_PROMOTION_SUCCESS"

export const UPDATE_PROMOTION = "UPDATE_PROMOTION"
export const UPDATE_PROMOTION_FAIL = "UPDATE_PROMOTION_FAIL"
export const UPDATE_PROMOTION_SUCCESS = "UPDATE_PROMOTION_SUCCESS"

export const DELETE_PROMOTION = "DELETE_PROMOTION"
export const DELETE_PROMOTION_FAIL = "DELETE_PROMOTION_FAIL"
export const DELETE_PROMOTION_SUCCESS = "DELETE_PROMOTION_SUCCESS"

export const SEND_PROMOTION_NOTIFICATION = "SEND_PROMOTION_NOTIFICATION"
export const SEND_PROMOTION_NOTIFICATION_FAIL = "SEND_PROMOTION_NOTIFICATION_FAIL"
export const SEND_PROMOTION_NOTIFICATION_SUCCESS = "SEND_PROMOTION_NOTIFICATION_SUCCESS"
