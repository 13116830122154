import { ALERT_MESSAGE } from 'constants/constants';
import TermForm from 'pages/Management/Commons/term-form/term-form';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { clearTerm, getTermDetail, showSuccessAlert, updateTerm } from 'store/actions';

const EditTerm = () => {

    const { t } = useTranslation();
    const { id, type } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const term = useSelector(state => state.Terms.termDetail);
    const termUpdated = useSelector(state => state.Terms.termUpdated);

    useEffect(() => {
        dispatch(getTermDetail({ id: id, type: type }));
    }, []);

    useEffect(() => {
        if(termUpdated) {
            dispatch(showSuccessAlert(ALERT_MESSAGE.UPDATED, () => {
                dispatch(clearTerm());
                history.push(`/terms-list`);
            }));
        }
        
    }, [termUpdated]);

    const onSubmit = (formData) => {
        const data = {...formData};
        data.id = term.id;
        data.companyId = term.company.id;
        // data.description = Buffer.from(data.description).toString('base64')
        data.isDefault = data.isDefault ? 'Y' : 'N';
        dispatch(updateTerm(data));
    }

    return ( 
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t('Title App')}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h2 className="mb-2 font-size-18">{t('Edit Term')}</h2>
                    </div>
                    
                    <Row>
                        {
                            term?
                            <TermForm 
                                term={term}
                                onSubmit={onSubmit}
                            />
                            : null
                        }
                        
                    </Row>
                </Container>
            </div>    
        </React.Fragment>
    );
}
 
export default EditTerm;