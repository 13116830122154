import { checkModule, checkOption } from 'helpers/Security/role_helper';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { menu } from '../../constants/menu';
import logo from "../../assets/images/logo-vitae.png";

const Dashboard = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();


    const principal = useSelector(state => state.principals.principalData);
    const [rolePrincipalList, setRolePrincipalList] = useState([]);
    const [hasPermissions, setHasPermissions] = useState(false);
    useEffect(() => {
        if (principal) {
            setRolePrincipalList(principal.rolePrincipals);
            setHasPermissions(menu.find((currentModule) => {
                return (currentModule.options.filter((currentOption) => checkOption(currentOption.id, principal.rolePrincipals)).length > 0)
            }));
        }

    }, [principal]);

    const redirectTo = url => {
        history.push(url);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t('Title App')}</title>
                </MetaTags>
                <Container fluid>

                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h2 className="mb-2 font-size-18">{t('Dashboard')}</h2>
                    </div>

                    <Card>
                        <CardBody>
                            {rolePrincipalList.length > 0 ?menu.map((shortcut, index) => (
                                checkModule(shortcut.id, shortcut.options, rolePrincipalList) ?
                                    (<React.Fragment key={`_row_${index}`}>
                                        {/*<h2 className="mb-2 font-size-18">{t(shortcut.module)}</h2>*/}
                                        <Row>
                                            {shortcut.options.map((option, key) => (

                                                (option.visibleIn == 'D' || option.visibleIn == 'B') && checkOption(option.id, rolePrincipalList) ?
                                                    (<Col sm={6} md={4} lg={4} key={`_col_${key}`}>
                                                        <Card color={shortcut.color} className="text-white-50 mini-stats-wid dashboard-option" onClick={() => { redirectTo(option.url) }}>
                                                            <CardBody>
                                                                <div className="d-flex">
                                                                    <div className="flex-grow-1">
                                                                        <h4 className="mb-0 text-white">{t(shortcut.module/*option.title*/)}</h4>
                                                                        <p className="fw-medium text-white">
                                                                            {t(option.description)}
                                                                        </p>
                                                                    </div>
                                                                    <div className="mini-stat-icon avatar-sm rounded-circle bg-primary align-self-center">
                                                                        <span className="avatar-title">
                                                                            <i className={`${option.iconClass} font-size-24`} />
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </CardBody>
                                                        </Card>
                                                    </Col>)
                                                    :
                                                    null
                                            ))
                                            }
                                        </Row>
                                    </React.Fragment>)
                                    :
                                    null
                            )): null}
                            {!hasPermissions? <img src={logo} alt="" width="90%" />: null}
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default Dashboard;