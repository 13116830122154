import { ALERT_MESSAGE } from "constants/constants";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Container, Row } from "reactstrap";
import {
    addRelatedProducts,
    clearRelatedProducts,
    showSuccessAlert,
} from "store/actions";
import ProductRelatedForm from "./Components/product-related-form";

const ProductRelatedPage = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const created = useSelector(state => state.relatedProducts.created);

    useEffect(() => {
        dispatch(clearRelatedProducts());
    }, []);

    useEffect(() => {
        if (created) {
            dispatch(
                showSuccessAlert(ALERT_MESSAGE.SAVED, () => {
                    dispatch(clearRelatedProducts());
                    onBack();
                })
            );
        }
    }, [created]);

    const onSubmit = data => {
            dispatch(addRelatedProducts(data));
    };

    const onBack = () => {
        history.push("/dashboard");
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t("Title App")}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h2 className="mb-2 font-size-18">{t("Related Products")}</h2>
                    </div>

                    <Row>
                        <ProductRelatedForm onSubmit={onSubmit} onBack={onBack} />
                    </Row>
                </Container>
            </div>

        </React.Fragment>
    );
};

export default ProductRelatedPage;
