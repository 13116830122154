import { ALERT_MESSAGE } from 'constants/constants';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Container, Row } from 'reactstrap';
import { clearRole, getRoleDetail, showSuccessAlert, updateRole } from 'store/actions';
import RoleForm from './role-form';

const EditRole = () => {

    const { t } = useTranslation();
    const { id } = useParams();
    const dispatch = useDispatch();
    const history = useHistory();

    const role = useSelector(state => state.roles.roleDetail);
    const roleUpdated = useSelector(state => state.roles.updated);

    useEffect(() => {
        dispatch(getRoleDetail({ id: id }));
    }, []);

    useEffect(() => {
        if(roleUpdated) {
            dispatch(showSuccessAlert(ALERT_MESSAGE.UPDATED, () => {
                dispatch(clearRole());
                history.push(`/security-groups`);
            }));
        }
        
    }, [roleUpdated]);

    const onSubmit = (data) => {
        data.id = role.id;
        data.tenantId = role.tenantId;
        dispatch(updateRole(data));
    }

    const onBack = () => {
        history.push(`/security-groups`);
    }

    return ( 
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{t('Title App')}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumbs */}
                    <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                        <h2 className="mb-2 font-size-18">{t('Edit User Group')}</h2>
                    </div>
                    
                    <Row>
                        {
                            role?
                            <RoleForm 
                                role={role}
                                onSubmit={onSubmit}
                                onBack={onBack}
                            />
                            : null
                        }
                        
                    </Row>
                </Container>
            </div>    
        </React.Fragment>
    );
}
 
export default EditRole;