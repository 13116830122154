import PageBody from "components/Common/PageLayout/page-body";
import { ALERT_MESSAGE } from "constants/constants";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import {
  clearAccount,
  deletePrincipal,
  deleteRole,
  getAccounts,
  showSuccessAlert,
  verifyEmailReset,
  clearPrincipalCreation
} from "store/actions";
import UserList from "./user-list";

const UsersListPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [showDelete, setShowDelete] = useState(false);
  const [role, setRole] = useState(null);

  const accounts = useSelector(state => state.accounts.accounts);
  const roleDeleted = useSelector(state => state.roles.deleted);
  const searchResult = accounts.map(affiliated => ({ ...affiliated, fullName: [affiliated.firstName, affiliated.secondName, affiliated.firstSurname, affiliated.secondSurname].join(' ') }));

  useEffect(() => {
    dispatch(verifyEmailReset());
    dispatch(getAccounts());
    dispatch(clearPrincipalCreation());
  }, []);

  useEffect(() => {
    if (roleDeleted) {
      dispatch(
        showSuccessAlert(ALERT_MESSAGE.DELETED, () => {
          dispatch(clearAccount());
          dispatch(getAccounts());
        })
      );
    }
  }, [roleDeleted]);

  const deleteRow = row => {
    setRole(row);
    setShowDelete(true);
  };

  const onDelete = () => {
    setShowDelete(false);
    dispatch(deleteRole(role));
  };

  const onDeleteUser = () => {
    setShowDelete(false);
    dispatch(deletePrincipal(role))
  }

  return (
    <React.Fragment>
      {showDelete ? (
        <SweetAlert
          title={t("confirm.delete")}
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText={t("Yes")}
          cancelBtnText={t("No")}
          onConfirm={() => {
            onDeleteUser();
          }}
          onCancel={() => {
            setShowDelete(false);
          }}
        ></SweetAlert>
      ) : null}

      <PageBody
        title="Users"
        actions={(
          <React.Fragment>
            <Button
              color="primary"
              className="button"
              onClick={() => {
                history.push(`/security-register-user`);
              }}
            >
              <i className="mdi mdi-plus-circle-outline me-1"></i>
              {t("Create")}
            </Button>
            <Button
              color="light"
              className="button"
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              <i className="mdi mdi-arrow-left me-1"></i>
              {t("Back")}
            </Button>
          </React.Fragment>
        )}
      >
        <Row>
          <Col lg={12}>
            <UserList accounts={searchResult} deleteRow={deleteRow} />
          </Col>
        </Row>
      </PageBody>
    </React.Fragment>
  );
};

export default UsersListPage;

