import { ALERT_MESSAGE } from "constants/constants"
import { 
  httpAddPaymentMethod,
  httpDeletePaymentMethod,
  httpGetPaymentMethodDetail,
  httpGetPaymentMethods,
  httpUpdatePaymentMethod
} from "helpers/Api/api-helper"
import { call, put, takeEvery } from "redux-saga/effects"
import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions"
import {
  getPaymentMethodsSuccess,
  getPaymentMethodsFail,
  getPaymentMethodDetailSuccess,
  getPaymentMethodDetailFail,
  addPaymentMethodSuccess,
  addPaymentMethodFail,
  updatePaymentMethodSuccess,
  updatePaymentMethodFail,
  deletePaymentMethodSuccess,
  deletePaymentMethodFail
} from "./actions"
import { 
  ADD_PAYMENT_METHOD,
  DELETE_PAYMENT_METHOD,
  GET_PAYMENT_METHOD_DETAIL,
  GET_PAYMENT_METHOD_LIST,
  UPDATE_PAYMENT_METHOD
 } from "./actionTypes"



function* getPaymentMethods(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetPaymentMethods, request.payload)
    yield put(hideLoadingAlert())
    yield put(getPaymentMethodsSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getPaymentMethodsFail(error))
  }
}

function* getPaymentMethodDetail(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetPaymentMethodDetail, request.payload)
    yield put(hideLoadingAlert())
    yield put(getPaymentMethodDetailSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_DETAIL_ERROR))
    yield put(getPaymentMethodDetailFail(error))
  }
}

function* addPaymentMethod(request) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.SAVING))
    const response = yield call(httpAddPaymentMethod, request.payload)
    yield put(hideLoadingAlert())
    yield put(addPaymentMethodSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.ADD_ERROR))
    yield put(addPaymentMethodFail(error))
  }
}

function* updatePaymentMethod(request) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.SAVING))
    const response = yield call(httpUpdatePaymentMethod, request.payload)
    yield put(hideLoadingAlert())
    yield put(updatePaymentMethodSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.UPDATE_ERROR))
    yield put(updatePaymentMethodFail(error))
  }
}

function* deletePaymentMethod(request) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.DELETING))
    const response = yield call(httpDeletePaymentMethod, request.payload)
    yield put(hideLoadingAlert())
    yield put(deletePaymentMethodSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.DELETE_ERROR))
    yield put(deletePaymentMethodFail(error))
  }
}

function* paymentMethodsSaga() {
    yield takeEvery(GET_PAYMENT_METHOD_LIST, getPaymentMethods)
    yield takeEvery(GET_PAYMENT_METHOD_DETAIL, getPaymentMethodDetail)
    yield takeEvery(ADD_PAYMENT_METHOD, addPaymentMethod)
    yield takeEvery(UPDATE_PAYMENT_METHOD, updatePaymentMethod)
    yield takeEvery(DELETE_PAYMENT_METHOD, deletePaymentMethod)
  }
  
  export default paymentMethodsSaga