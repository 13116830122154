import React from 'react';
import UpgradeMembershipState from './context/upgradeMembershipState';
import UpgradeMembershipWizard from './UpgradeMembershipWizard';


const UpgradeMembershipPage = () => {
    return ( 
        <UpgradeMembershipState>
            <UpgradeMembershipWizard/>
        </UpgradeMembershipState> 
    );
}
 
export default UpgradeMembershipPage;