import {
  GET_AFFILIATED,
  GET_AFFILIATED_FAIL,
  GET_AFFILIATED_SUCCESS,

  GET_ALL_ORDER_LIST,
  GET_ALL_ORDER_LIST_FAIL,
  GET_ALL_ORDER_LIST_SUCCESS,

  GET_AFFILIATED_SEARCH,
  GET_AFFILIATED_SEARCH_FAIL,
  GET_AFFILIATED_SEARCH_SUCCESS,
  GET_AFFILIATED_ORDER_LIST,
  GET_AFFILIATED_ORDER_LIST_FAIL,
  GET_AFFILIATED_ORDER_LIST_SUCCESS,
  GET_AFFILIATED_MEMBERSHIP_LIST,
  GET_AFFILIATED_MEMBERSHIP_LIST_SUCCESS,
  GET_AFFILIATED_MEMBERSHIP_LIST_FAIL,
  GET_AFFILIATED_MEMBERSHIP_DETAIL,
  GET_AFFILIATED_MEMBERSHIP_DETAIL_SUCCESS,
  GET_AFFILIATED_MEMBERSHIP_DETAIL_FAIL,
  UPGRADE_MEMBERSHIP,
  UPGRADE_MEMBERSHIP_SUCCESS,
  UPGRADE_MEMBERSHIP_FAIL,
  CLEAR_UPGRADE_MEMBERSHIP,
  CLEAR_GET_AFFILIATED,
  GET_ORDER,
  GET_ORDER_SUCCESS,
  GET_ORDER_FAIL,
  GET_CRITERIA,
  GET_KEY,
  GET_CURRENT_URL,

  GET_ALL_PURCHASE_TRANSACTIONS,
  GET_ALL_PURCHASE_TRANSACTIONS_SUCCESS,
  GET_ALL_PURCHASE_TRANSACTIONS_FAIL
} from "./actionTypes"

//Affiliated

export const clearGetAffiliated = request => ({
  type: CLEAR_GET_AFFILIATED,
  payload: request
})

export const getCriteria = criteria => ({
  type: GET_CRITERIA,
  payload: criteria
})

export const getKey = key =>({
  type: GET_KEY,
  payload : key
})

export const getUrl = url=>({
  type : GET_CURRENT_URL,
  payload: url
})

export const getAffiliated = accountCode => ({
  type: GET_AFFILIATED,
  accountCode
})

export const getAffiliatedSuccess = customerProfile => ({
  type: GET_AFFILIATED_SUCCESS,
  payload: customerProfile,
})

export const getAffiliatedFail = error => ({
  type: GET_AFFILIATED_FAIL,
  payload: error,
})


//Affiliated search
export const getAffiliatedSearch = searchCriteria => ({
  type: GET_AFFILIATED_SEARCH,
  searchCriteria
})

export const getAffiliatedSearchSuccess = customers => ({
  type: GET_AFFILIATED_SEARCH_SUCCESS,
  payload: customers,
})

export const getAffiliatedSearchFail = error => ({
  type: GET_AFFILIATED_SEARCH_FAIL,
  payload: error,
})


//Affiliated order list
export const getAffiliatedOrderList = accountCode => ({
  type: GET_AFFILIATED_ORDER_LIST,
  accountCode
})

export const getAffiliatedOrderListSuccess = orders => ({
  type: GET_AFFILIATED_ORDER_LIST_SUCCESS,
  payload: orders,
})

export const getAffiliatedOrderListFail = error => ({
  type: GET_AFFILIATED_ORDER_LIST_FAIL,
  payload: error,
})

//All order list
export const getAllOrderList = (payload) => ({
  type: GET_ALL_ORDER_LIST,
  payload: payload,
})

export const getAllOrderListSuccess = orders => ({
  type: GET_ALL_ORDER_LIST_SUCCESS,
  payload: orders,
})

export const getAllOrderListFail = error => ({
  type: GET_ALL_ORDER_LIST_FAIL,
  payload: error,
})

//Get order
export const getOrder = (request) => ({
  type: GET_ORDER,
  payload: request
})

export const getOrderSuccess = order => ({
  type: GET_ORDER_SUCCESS,
  payload: order,
})

export const getOrderFail = error => ({
  type: GET_ORDER_FAIL,
  payload: error,
})

//Affiliated  membership list
export const getAffiliatedMembershipList = accountCode => ({
  type: GET_AFFILIATED_MEMBERSHIP_LIST,
  accountCode
})

export const getAffiliatedMembershipListSuccess = memberships => ({
  type: GET_AFFILIATED_MEMBERSHIP_LIST_SUCCESS,
  payload: memberships,
})

export const getAffiliatedMembershipListFail = error => ({
  type: GET_AFFILIATED_MEMBERSHIP_LIST_FAIL,
  payload: error,
})


//Affiliated  membership detail
export const getAffiliatedMembershipDetail = id => ({
  type: GET_AFFILIATED_MEMBERSHIP_DETAIL,
  id
})

export const getAffiliatedMembershipDetailSuccess = membership => ({
  type: GET_AFFILIATED_MEMBERSHIP_DETAIL_SUCCESS,
  payload: membership,
})

export const getAffiliatedMembershipDetailFail = error => ({
  type: GET_AFFILIATED_MEMBERSHIP_DETAIL_FAIL,
  payload: error,
})

//Upgrade Membership

export const clearUpgradeMembership = () => ({
  type: CLEAR_UPGRADE_MEMBERSHIP
})

export const upgradeMembership = request => ({
  type: UPGRADE_MEMBERSHIP,
  payload: request
})

export const upgradeMembershipSuccess = response => ({
  type: UPGRADE_MEMBERSHIP_SUCCESS,
  payload: response,
})

export const upgradeMembershipFail = error => ({
  type: UPGRADE_MEMBERSHIP_FAIL,
  payload: error,
})

export const getAllPurchaseTransactions = request => ({
  type: GET_ALL_PURCHASE_TRANSACTIONS,
  payload: request
})

export const getAllPurchaseTransactionsSuccess = list => ({
  type: GET_ALL_PURCHASE_TRANSACTIONS_SUCCESS,
  payload: list,
})

export const getAllPurchaseTransactionsFail = error => ({
  type: GET_ALL_PURCHASE_TRANSACTIONS_FAIL,
  payload: error,
})