import { ALERT_MESSAGE } from "constants/constants"
import { 
  httpGetSubsidiaries
} from "helpers/Api/api-helper"
import { call, put, takeEvery } from "redux-saga/effects"
import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions"
import {
  getSubsidiariesSuccess,
  getSubsidiariesFail
} from "./actions"
import { 
  GET_SUBSIDIARY_LIST
 } from "./actionTypes"



function* getSubsidiaries(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetSubsidiaries, request.payload)
    yield put(hideLoadingAlert())
    yield put(getSubsidiariesSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getSubsidiariesFail(error))
  }
}

function* subsidiariesSaga() {
    yield takeEvery(GET_SUBSIDIARY_LIST, getSubsidiaries)
  }
  
  export default subsidiariesSaga