import PageBody from "components/Common/PageLayout/page-body";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Col, Row, Button } from "reactstrap";
import {
  clearProductSearchList,
  getCatalogs,
  getProductCategoryList,
  getProductSearchList,
  getProductSearchListByCategory,
} from "store/actions";
import ProductSearchCard from "./ProductSearch/product-search-card";
import json from 'jsonwebtoken';

const ProductSearchResultPage = () => {
  const { t } = useTranslation();
  const { searchType, query } = useParams();
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [page, setPage] = useState(0);
  const [accountCode, setAccountCode] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const onSelectProduct = (product) => {
    history.push(`/pharmacy-product-detail/${searchType == "q"? product.id : product.id}`);
  };

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const user = json.decode(obj.idToken);
      setAccountCode(user.sub);
    }
    setPage(0);
    dispatch(getCatalogs());
    dispatch(getProductCategoryList());
    dispatch(clearProductSearchList());
  }, []);

  useEffect(() => {
    if (searchType && query && accountCode) {
      dispatch(clearProductSearchList());
      search(page);
    }
  }, [searchType, query, accountCode]);

  const currencyCodeList = useSelector(
    state => state.catalogs.currencyCodeList
  );

  const getCurrencySymbol = currencyCode => {
    const found = currencyCodeList.find(
      current => current.code == currencyCode
    );
    return found ? found.currency : currencyCode;
  };

  const search = page => {
    if (searchType == "q") {
      dispatch(
        getProductSearchList({ name: query, accountCode: accountCode, pageNumber: page })
      );
    } else if (searchType == "c") {
      dispatch(
        getProductSearchListByCategory({
          categoryId: query,
          pageNumber: page,
        })
      );
    }
  };

  const productResults = useSelector(
    state => state.productSearches.productResults
  );

  const hasMore = useSelector(state => state.productSearches.hasMore);
  const loading = useSelector(state => state.productSearches.loading);

  return (
    <React.Fragment>
      <PageBody
        title="Product Search"
        actions={
          <Button
            color="light"
            className="button"
            onClick={() => {
              history.push("/pharmacy-product-search");
            }}
          >
            <i className="mdi mdi-arrow-left me-1"></i>
            {t("Back")}
          </Button>
        }
      >
        <Row>
          <Col sm={{ offset: 4, size: 8 }}></Col>
        </Row>
        <Row>
          <Col xs={12} sm={12}>
            <h3>
              {t(
                selectedCategory
                  ? selectedCategory.description
                  : "Search Results"
              )}
            </h3>
            <hr />
          </Col>
        </Row>
        {/* Render selected service */}
        <InfiniteScroll
          dataLength={productResults.length}
          next={() => {
            search(page + 1);
            setPage(page + 1);
          }}
          hasMore={hasMore}
          loader={loading ? <h4>{t("Loading")}...</h4> : null}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <b>{t("There are no more products to display")}</b>
            </p>
          }
        >
          <Row style={{width: '100%'}}>
            {productResults.map(product => (
              product ? 
              <ProductSearchCard
                key={`product-${product.id}`}
                product={searchType == "q" ? product : product.product}
                onSelect={onSelectProduct}
                getCurrencySymbol={getCurrencySymbol}
              />
              : <></>
            ))}
          </Row>
        </InfiniteScroll>
      </PageBody>
    </React.Fragment>
  );
};

export default ProductSearchResultPage;
