import PageBody from 'components/Common/PageLayout/page-body';
import { ALERT_MESSAGE } from 'constants/constants';
import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { deletePromotion, getPromotionDetail } from 'store/actions';
import PromotionsDetail from './Components/promotions-detail';

const PromotionsDetailPage = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();

    const [showDelete, setShowDelete] = useState(false);

    const detail = useSelector(state => state.promotionsList.promotionDetail);
    const promotionDeleted = useSelector(state => state.promotionsList.deleted);

    useEffect(() => {
        if (id) {
            dispatch(getPromotionDetail({ id: id }));
        }
    }, [id]);

    useEffect(() => {
        if (promotionDeleted) {
            dispatch(
                showSuccessAlert(ALERT_MESSAGE.DELETED, () => {
                    history.push("/management-promotions-list");
                })
            );
        }
    }, [promotionDeleted]);

    const deleteRow = () => {
        setShowDelete(true);
    };

    const onDelete = () => {
        setShowDelete(false);
        dispatch(deletePromotion(detail));
        history.push("/management-promotions-list");
    };

    return (
        <React.Fragment>
            <PageBody
                title='Promotion Detail'
                actions={(
                    <React.Fragment>
                        <Button
                            color="primary"
                            className="button"
                            onClick={() => {
                                history.push(`/management-promotions-edit/${detail.id}`);
                            }}
                        >
                            <i className="mdi mdi-pencil me-1"></i>
                            {t("Edit Promotion")}
                        </Button>
                        <Button
                            color="danger"
                            className="button"
                            onClick={() => {
                                deleteRow();
                            }}
                        >
                            <i className="mdi mdi-delete me-1"></i>
                            {t("Delete Promotion")}
                        </Button>
                        <Button
                            color="light"
                            className="button"
                            onClick={() => {
                                history.push("/management-promotions-list");
                            }}
                        >
                            <i className="mdi mdi-arrow-left me-1"></i>
                            {t("Back")}
                        </Button>
                    </React.Fragment>
                )}
            >

                {detail ? <PromotionsDetail detail={detail} /> : null}
            </PageBody>

            {showDelete ? (
                <SweetAlert
                    title={t("confirm.delete")}
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText={t("Yes")}
                    cancelBtnText={t("No")}
                    onConfirm={() => {
                        onDelete();
                    }}
                    onCancel={() => {
                        setShowDelete(false);
                    }}
                ></SweetAlert>
            ) : null}
        </React.Fragment>
    );
}

export default PromotionsDetailPage;