//Affiliated Expenses

import {
  AFFILIATED_EXPENSES,
  AFFILIATED_EXPENSES_FAIL,
  AFFILIATED_EXPENSES_SUCCESS,
} from "./actionTypes";

export const getAffiliatedExpenses = accountCode => ({
  type: AFFILIATED_EXPENSES,
  payload: { accountCode: accountCode },
});

export const getAffiliatedExpensesSuccess = response => ({
  type: AFFILIATED_EXPENSES_SUCCESS,
  payload: response,
});

export const getAffiliatedExpensesFail = error => ({
  type: AFFILIATED_EXPENSES_FAIL,
  payload: error,
});
