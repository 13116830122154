import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_ALL_MEDICAL_SERVICE_BY_TYPE_LIST,
  GET_ALL_MEDICAL_SERVICE_LIST,
  GET_MEDICAL_CATEGORY_LIST,
  GET_MEDICAL_SERVICE_DETAIL,
  GET_MEDICAL_SERVICE_LIST,
  MODIFY_MEDICAL_SERVICE
} from "./actionTypes"

import {
  getCategoryListSuccess,
  getCategoryListFail,
  getMedicalServiceListSuccess,
  getMedicalServiceListFail,
  getMedicalServiceDetailSuccess,
  getMedicalServiceDetailFail,
  getAllMedicalServiceListSuccess,
  getAllMedicalServiceListFail,
  getAllMedicalServiceByTypeListSuccess,
  getAllMedicalServiceByTypeListFail
} from "./actions"

//API Calls helper
import {
  httpGetMedicalCategories,
  httpGetMedicalServices,
  httpGetMedicalServiceDetail,
  httpGetAllMedicalServices,
  httpGetAllMedicalServicesByType,
  httpPostMedicalService
} from "../../helpers/Api/api-helper"

import { hideLoadingAlert, showLoadingAlert, showWarningAlert, showSuccessAlert } from "store/actions"
import { ALERT_MESSAGE } from "constants/constants"

function* fetchMedicalCategories(request) {
  try {
    const response = yield call(httpGetMedicalCategories, request.payload)
    yield put(getCategoryListSuccess(response))
  } catch (error) {
    yield put(getCategoryListFail(error))
  }
}

function* fetchMedicalServices(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(httpGetMedicalServices, request.payload)
    yield put(hideLoadingAlert())
    yield put(getMedicalServiceListSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getMedicalServiceListFail(error))
  }
}

function* fetchAllMedicalServices(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(httpGetAllMedicalServices)
    yield put(hideLoadingAlert())
    yield put(getAllMedicalServiceListSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getAllMedicalServiceListFail(error))
  }
}

function*modifyMedicalService(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(httpPostMedicalService, request.payload)
    yield put(hideLoadingAlert())
    yield put(showSuccessAlert(ALERT_MESSAGE.UPDATED))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getAllMedicalServiceListFail(error))
  }
}

function* fetchAllMedicalServicesByType(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(httpGetAllMedicalServicesByType, request.payload)
    yield put(hideLoadingAlert())
    yield put(getAllMedicalServiceByTypeListSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getAllMedicalServiceByTypeListFail(error))
  }
}

function* fetchMedicalServiceDetail(request) {
  try {
    const response = yield call(httpGetMedicalServiceDetail, request.payload)
    yield put(getMedicalServiceDetailSuccess(response))
  } catch (error) {
    yield put(getMedicalServiceDetailFail(error))
  }
}

function* medicalServicesSaga() {
  yield takeEvery(GET_MEDICAL_CATEGORY_LIST, fetchMedicalCategories)
  yield takeEvery(GET_MEDICAL_SERVICE_LIST, fetchMedicalServices)
  yield takeEvery(GET_ALL_MEDICAL_SERVICE_BY_TYPE_LIST, fetchAllMedicalServicesByType)
  yield takeEvery(GET_ALL_MEDICAL_SERVICE_LIST, fetchAllMedicalServices)
  yield takeEvery(MODIFY_MEDICAL_SERVICE, modifyMedicalService)
  yield takeEvery(GET_MEDICAL_SERVICE_DETAIL, fetchMedicalServiceDetail)
}

export default medicalServicesSaga
