import { GET_BENEFIT_CATEGORIES_LIST, GET_BENEFIT_CATEGORIES_LIST_FAIL, GET_BENEFIT_CATEGORIES_LIST_SUCCESS } from "./actionTypes"

//Benefit Categories
export const getBenefitCategories = () => ({
    type: GET_BENEFIT_CATEGORIES_LIST
})

export const getBenefitCategoriesSuccess = response => ({
    type: GET_BENEFIT_CATEGORIES_LIST_SUCCESS,
    payload: response,
})

export const getBenefitCategoriesFail = error => ({
    type: GET_BENEFIT_CATEGORIES_LIST_FAIL,
    payload: error,
})