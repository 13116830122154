import {
    CLEAR_MEMBERSHIP_AFFILIATED_DETAIL_STATE,
    GET_MEMBERSHIP_AFFILIATED_DETAIL_LIST,
    GET_MEMBERSHIP_AFFILIATED_DETAIL_LIST_FAIL,
    GET_MEMBERSHIP_AFFILIATED_DETAIL_LIST_SUCCESS
} from "./actionTypes"

//MembershipAffiliatedDetails
export const clearMembershipAffiliatedDetail = () => ({
    type: CLEAR_MEMBERSHIP_AFFILIATED_DETAIL_STATE
})

export const getMembershipAffiliatedDetailList = (request) => ({
    type: GET_MEMBERSHIP_AFFILIATED_DETAIL_LIST,
    payload: request
})

export const getMembershipAffiliatedDetailListSuccess = response => ({
    type: GET_MEMBERSHIP_AFFILIATED_DETAIL_LIST_SUCCESS,
    payload: response,
})

export const getMembershipAffiliatedDetailListFail = error => ({
    type: GET_MEMBERSHIP_AFFILIATED_DETAIL_LIST_FAIL,
    payload: error,
})