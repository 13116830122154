import {
    GET_PATIENT_LIST,
    GET_PATIENT_LIST_FAIL,
    GET_PATIENT_LIST_SUCCESS,
    CLEAR_PATIENT_STATE
} from "./actionTypes"

const INIT_STATE = {
    patients: [],
    error: {},
    success: false,
    loading: false,
}

const medicalPatients = (state = INIT_STATE, action) => {
    switch(action.type){

        case CLEAR_PATIENT_STATE:
            return{
                ...state,
                patients: [],
                error: {},
                success: false,
                loading: false
            }
        case GET_PATIENT_LIST:
            return{
                ...state,
                patients: [],
                loading: true,
            }
        case GET_PATIENT_LIST_SUCCESS:
            return{
                ...state,
                loading: false,
                patients: action.payload,
            }
        case GET_PATIENT_LIST_FAIL:
            return{
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}

export default medicalPatients;