import { ADD_ROLE, ADD_ROLE_FAIL, ADD_ROLE_SUCCESS, CLEAR_ROLES_STATE, 
         DELETE_ROLE, 
         DELETE_ROLE_FAIL, 
         DELETE_ROLE_SUCCESS, 
         GET_ROLE_DETAIL, 
         GET_ROLE_DETAIL_FAIL, 
         GET_ROLE_DETAIL_SUCCESS, 
         GET_ROLE_LIST, 
         GET_ROLE_LIST_FAIL, 
         GET_ROLE_LIST_SUCCESS, 
         UPDATE_ROLE,
         UPDATE_ROLE_FAIL,
         UPDATE_ROLE_SUCCESS,
         GET_REALM_LIST,
         GET_REALM_LIST_SUCCESS,
         GET_REALM_LIST_FAIL,
         GET_RESOURCE_LIST,
         GET_RESOURCE_LIST_SUCCESS,
         GET_RESOURCE_LIST_FAIL,
         GET_ROLE_RESOURCE_LIST,
         GET_ROLE_RESOURCE_LIST_SUCCESS,
         GET_ROLE_RESOURCE_LIST_FAIL,
         SAVE_ROLE_RESOURCE_LIST,
         SAVE_ROLE_RESOURCE_LIST_SUCCESS,
         SAVE_ROLE_RESOURCE_LIST_FAIL} from "./actionTypes"

//Roles
export const clearRole = () => ({
    type: CLEAR_ROLES_STATE
  })

  export const getRoles = (request) => ({
    type: GET_ROLE_LIST,
    payload: request
  })
  
  export const getRolesSuccess = response => ({
    type: GET_ROLE_LIST_SUCCESS,
    payload: response,
  })
  
  export const getRolesFail = error => ({
    type: GET_ROLE_LIST_FAIL,
    payload: error,
  })

  export const getRoleDetail = (request) => ({
    type: GET_ROLE_DETAIL,
    payload: request
  })
  
  export const getRoleDetailSuccess = response => ({
    type: GET_ROLE_DETAIL_SUCCESS,
    payload: response,
  })
  
  export const getRoleDetailFail = error => ({
    type: GET_ROLE_DETAIL_FAIL,
    payload: error,
  })

  export const addRole = (request) => ({
    type: ADD_ROLE,
    payload: request
  })
  
  export const addRoleSuccess = response => ({
    type: ADD_ROLE_SUCCESS,
    payload: response,
  })
  
  export const addRoleFail = error => ({
    type: ADD_ROLE_FAIL,
    payload: error,
  })

  export const updateRole = (request) => ({
    type: UPDATE_ROLE,
    payload: request
  })
  
  export const updateRoleSuccess = response => ({
    type: UPDATE_ROLE_SUCCESS,
    payload: response,
  })
  
  export const updateRoleFail = error => ({
    type: UPDATE_ROLE_FAIL,
    payload: error,
  })

  export const deleteRole = (request) => ({
    type: DELETE_ROLE,
    payload: request
  })
  
  export const deleteRoleSuccess = response => ({
    type: DELETE_ROLE_SUCCESS,
    payload: response,
  })
  
  export const deleteRoleFail = error => ({
    type: DELETE_ROLE_FAIL,
    payload: error,
  })


  export const getRealms = (request) => ({
    type: GET_REALM_LIST,
    payload: request
  })
  
  export const getRealmsSuccess = response => ({
    type: GET_REALM_LIST_SUCCESS,
    payload: response,
  })
  
  export const getRealmsFail = error => ({
    type: GET_REALM_LIST_FAIL,
    payload: error,
  })

  export const getResources = (request) => ({
    type: GET_RESOURCE_LIST,
    payload: request
  })
  
  export const getResourcesSuccess = response => ({
    type: GET_RESOURCE_LIST_SUCCESS,
    payload: response,
  })
  
  export const getResourcesFail = error => ({
    type: GET_RESOURCE_LIST_FAIL,
    payload: error,
  })


  export const getRoleResources = (request) => ({
    type: GET_ROLE_RESOURCE_LIST,
    payload: request
  })
  
  export const getRoleResourcesSuccess = response => ({
    type: GET_ROLE_RESOURCE_LIST_SUCCESS,
    payload: response,
  })
  
  export const getRoleResourcesFail = error => ({
    type: GET_ROLE_RESOURCE_LIST_FAIL,
    payload: error,
  })

  export const saveRoleResources = (request) => ({
    type: SAVE_ROLE_RESOURCE_LIST,
    payload: request
  })
  
  export const saveRoleResourcesSuccess = response => ({
    type: SAVE_ROLE_RESOURCE_LIST_SUCCESS,
    payload: response,
  })
  
  export const saveRoleResourcesFail = error => ({
    type: SAVE_ROLE_RESOURCE_LIST_FAIL,
    payload: error,
  })