import { ALERT_MESSAGE } from "constants/constants";
import { questionValueType, DEFAULT_COMPANY } from "constants/list-types";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import {
  clearQuestion,
  deleteQuestion,
  getQuestions,
  showSuccessAlert,
} from "store/actions";

const QuestionList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const node = React.createRef();
  const [showDelete, setShowDelete] = useState(false);
  const [question, setQuestion] = useState(null);
  const { SearchBar } = Search;

  const questionList = useSelector(state => state.questions.questions);
  const questionDeleted = useSelector(state => state.questions.questionDeleted);

  const [pageOptions, setPageOptions] = useState({
    sizePerPage: 10,
    totalSize: 0,
    custom: true,
  });

  useEffect(() => {
    dispatch(getQuestions({ companyId: DEFAULT_COMPANY }));
  }, []);

  useEffect(() => {
    if (questionDeleted) {
      dispatch(
        showSuccessAlert(ALERT_MESSAGE.DELETED, () => {
          dispatch(clearQuestion());
          dispatch(getQuestions({ companyId: DEFAULT_COMPANY }));
        })
      );
    }
  }, [questionDeleted]);

  useEffect(() => {
    if (questionList) {
      setPageOptions({
        ...pageOptions,
        totalSize: questionList.length,
      });
    }
  }, [questionList]);

  const columns = [
    
    {
      text: t("Type"),
      dataField: "value_type",
      sort: true,
      formatter: (cellContent, row) => {
        const unitType = questionValueType.find(
          current => current.code == row.valueType
        );
        return (
          <React.Fragment>
            {t(unitType ? unitType.value : row.valueType)}
          </React.Fragment>
        );
      },
    },
    {
      text: t("Description"),
      dataField: "description",
      sort: true,
      formatter: (cellContent, row) => (
        <React.Fragment>{row.description}</React.Fragment>
      ),
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "",
      formatter: (cellContent, row) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => editRow(row)}
            ></i>
          </Link>
          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => deleteRow(row)}
            ></i>
          </Link>
        </div>
      ),
    },
  ];

  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const editRow = row => {
    history.push(`/questions-edit/${row.id}`);
  };

  const deleteRow = row => {
    setQuestion(row);
    setShowDelete(true);
  };

  const onDelete = () => {
    setShowDelete(false);
    dispatch(deleteQuestion(question));
  };

  return (
    <React.Fragment>
      {showDelete ? (
        <SweetAlert
          title={t("confirm.delete")}
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText={t("Yes")}
          cancelBtnText={t("No")}
          onConfirm={() => {
            onDelete();
          }}
          onCancel={() => {
            setShowDelete(false);
          }}
        ></SweetAlert>
      ) : null}

      <div className="page-content">
        <MetaTags>
          <title>{t("Title App")}</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h2 className="mb-2 font-size-18">
              {t("Questions and Answers Memberships")}
            </h2>
            <div className="button-items">
              <Button
                color="primary"
                className="button"
                onClick={() => {
                  history.push(`/questions-new`);
                }}
              >
                <i className="mdi mdi-plus-circle-outline me-1"></i>
                {t("Create")}
              </Button>
              <Button
                color="light"
                className="button"
                onClick={() => {
                  history.push("/dashboard");
                }}
              >
                <i className="mdi mdi-arrow-left me-1"></i>
                {t("Back")}
              </Button>
            </div>
          </div>

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={questionList}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={questionList}
                        search
                      >
                        {toolkitprops => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm={4}>
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitprops.searchProps}
                                      placeholder={t("Search")}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8"></Col>
                            </Row>
                            <Row>
                              <Col xl={12}>
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={false}
                                    responsive
                                    ref={node}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default QuestionList;

