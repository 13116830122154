import {
  CLEAR_PRODUCT_SEARCH_LIST,
  GET_PRODUCT_SEARCH_DETAIL,
  GET_PRODUCT_SEARCH_DETAIL_FAIL,
  GET_PRODUCT_SEARCH_DETAIL_SUCCESS,
  GET_PRODUCT_SEARCH_LIST,
  GET_PRODUCT_SEARCH_LIST_BY_CATEGORY,
  GET_PRODUCT_SEARCH_LIST_BY_CATEGORY_FAIL,
  GET_PRODUCT_SEARCH_LIST_BY_CATEGORY_SUCCESS,
  GET_PRODUCT_SEARCH_LIST_FAIL,
  GET_PRODUCT_SEARCH_LIST_SUCCESS,
} from "./actionTypes";

//Product Search List
export const clearProductSearchList = () => ({
  type: CLEAR_PRODUCT_SEARCH_LIST,
});

export const getProductSearchList = request => ({
  type: GET_PRODUCT_SEARCH_LIST,
  payload: request,
});

export const getProductSearchListSuccess = response => ({
  type: GET_PRODUCT_SEARCH_LIST_SUCCESS,
  payload: response,
});

export const getProductSearchListFail = error => ({
  type: GET_PRODUCT_SEARCH_LIST_FAIL,
  payload: error,
});

export const getProductSearchListByCategory = request => ({
  type: GET_PRODUCT_SEARCH_LIST_BY_CATEGORY,
  payload: request,
});

export const getProductSearchListByCategorySuccess = response => ({
  type: GET_PRODUCT_SEARCH_LIST_BY_CATEGORY_SUCCESS,
  payload: response,
});
export const getProductSearchListByCategoryFail = response => ({
  type: GET_PRODUCT_SEARCH_LIST_BY_CATEGORY_FAIL,
  payload: response,
});

export const getProductSearchDetail = request => ({
  type: GET_PRODUCT_SEARCH_DETAIL,
  payload: request,
});

export const getProductSearchDetailSuccess = response => ({
  type: GET_PRODUCT_SEARCH_DETAIL_SUCCESS,
  payload: response,
});

export const getProductSearchDetailFail = error => ({
  type: GET_PRODUCT_SEARCH_DETAIL_FAIL,
  payload: error,
});
