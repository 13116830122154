import PageBody from 'components/Common/PageLayout/page-body';
import { ALERT_MESSAGE } from 'constants/constants';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { clearPromotionList, getPromotionDetail, showSuccessAlert, updatePromotion } from 'store/actions';
import PromotionForm from './Components/promotion-form';

const PromotionsEditPage = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();

    const detail = useSelector(state => state.promotionsList.promotionDetail);
    const updated = useSelector(state => state.promotionsList.promotionUpdated);

    useEffect(() => {
        if(updated) {
            dispatch(showSuccessAlert(ALERT_MESSAGE.UPDATED, () => {
                dispatch(clearPromotionList());
                history.push(`/management-promotions-detail/${id}`)
            }));
        }
    }, [updated]);

    useEffect(() => {
        if(id) {
            dispatch(getPromotionDetail({id: id}));
        }
    }, [id]);

    const save = (promotionData) => {
        promotionData.id = id;
        promotionData.companyId = detail.company.id;
        promotionData.picture = detail.picture;
        dispatch(updatePromotion(promotionData));
    }

    return (
        <React.Fragment>
            <PageBody
                title='Edit Promotion'
            >
                <PromotionForm 
                    promotion={detail}
                    onSubmit={save}
                    onBack={() => { history.push(`/management-promotions-detail/${id}`) }} 
                />
            </PageBody>
        </React.Fragment>
    );
}

export default PromotionsEditPage;