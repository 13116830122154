/* ACCOUNT */
export const CLEAR_CHANGE_PASSWORD_STATE = "CLEAR_CHANGE_PASSWORD_STATE" 

export const CHANGE_PASSWORD_REQUEST_CODE = "CHANGE_PASSWORD_REQUEST_CODE"
export const CHANGE_PASSWORD_REQUEST_CODE_FAIL = "CHANGE_PASSWORD_REQUEST_CODE_FAIL"
export const CHANGE_PASSWORD_REQUEST_CODE_SUCCESS = "CHANGE_PASSWORD_REQUEST_CODE_SUCCESS"

export const CHANGE_PASSWORD_VALIDATE_CODE = "CHANGE_PASSWORD_VALIDATE_CODE"
export const CHANGE_PASSWORD_VALIDATE_CODE_FAIL = "CHANGE_PASSWORD_VALIDATE_CODE_FAIL"
export const CHANGE_PASSWORD_VALIDATE_CODE_SUCCESS = "CHANGE_PASSWORD_VALIDATE_CODE_SUCCESS"

export const CHANGE_PASSWORD_UPDATE = "CHANGE_PASSWORD_UPDATE"
export const CHANGE_PASSWORD_UPDATE_FAIL = "CHANGE_PASSWORD_UPDATE_FAIL"
export const CHANGE_PASSWORD_UPDATE_SUCCESS = "CHANGE_PASSWORD_UPDATE_SUCCESS"