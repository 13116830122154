import {
  CLEAR_RELATED_PRODUCTS_STATE,
  GET_RELATED_PRODUCTS_LIST,
  GET_RELATED_PRODUCTS_LIST_SUCCESS,
  GET_RELATED_PRODUCTS_LIST_FAIL,
  ADD_RELATED_PRODUCTS,
  ADD_RELATED_PRODUCTS_SUCCESS,
  ADD_RELATED_PRODUCTS_FAIL
} from "./actionTypes"

//Related Products
export const clearRelatedProducts = () => ({
  type: CLEAR_RELATED_PRODUCTS_STATE
})

export const getRelatedProducts = request => ({
  type: GET_RELATED_PRODUCTS_LIST,
  payload: request
})

export const getRelatedProductsSuccess = response => ({
  type: GET_RELATED_PRODUCTS_LIST_SUCCESS,
  payload: response,
})

export const getRelatedProductsFail = error => ({
  type: GET_RELATED_PRODUCTS_LIST_FAIL,
  payload: error,
})

//Add Related Products
export const addRelatedProducts = request => ({
  type: ADD_RELATED_PRODUCTS,
  payload: request
})

export const addRelatedProductsSuccess = response => ({
  type: ADD_RELATED_PRODUCTS_SUCCESS,
  payload: response,
})

export const addRelatedProductsFail = error => ({
  type: ADD_RELATED_PRODUCTS_FAIL,
  payload: error,
})
