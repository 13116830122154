import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { numberFormat } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import image from 'assets/images/vitae/Logos-vitae.png'

const PromotionDetailCard = ({ productDetail, getCurrencySymbol }) => {

    const { productMedias, currencyCode, itemCode, description, unitPrice } = productDetail;
    const { t } = useTranslation();

    const getProductPicture = () => {
        let picture =
            productMedias && productMedias.length > 0 ? productMedias[0] : null;

        return picture ? picture.filePath : "";
    };

    return (
        <React.Fragment>
            <Row className="align-middle mt-1">
                <Col sm={3} className="mt-1">
                    <span className='font-size-13'>{t('Code')}: </span>
                    <strong className='font-size-13'>
                        {itemCode}
                    </strong>
                </Col>
                <Col sm={5} className="mt-1">
                    <p className='font-size-13'>{description}</p>
                </Col>
                <Col sm={3} className="mt-1">
                    <span className='font-size-13'>{t('Price')}: </span>
                    <strong className='font-size-13'>
                        {getCurrencySymbol(currencyCode)}{numberFormat(unitPrice)}
                    </strong>
                </Col>
                
            </Row>
        </React.Fragment>
    );
}
PromotionDetailCard.propTypes = {
    productDetail: PropTypes.object.isRequired,
    getCurrencySymbol: PropTypes.func.isRequired
};

export default PromotionDetailCard;