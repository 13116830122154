import React from 'react';
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
import MedicalCategoryCard from './medical-category-card';
import { Row } from 'reactstrap';

const MedicalCategoryList = ({onSelect, categorySelectedId}) => {

    const medicalCateogoryList = useSelector(state => state.medicalServices.medicalCategoryList);
    return ( 
        <React.Fragment>
            <Row style={{
                maxWidth: '100%',
                maxHeight: '220px',
                overflowY: 'scroll'
            }}>
                {medicalCateogoryList.map(category => (
                    <MedicalCategoryCard 
                        key={category.id} 
                        category={category} 
                        onSelect={onSelect} 
                        selected={category.id === categorySelectedId}
                    />
                ))}
            </Row>
        </React.Fragment>
     );
}
 
MedicalCategoryList.propTypes = {
    onSelect: PropTypes.func,
    categorySelectedId: PropTypes.number
};

export default MedicalCategoryList;