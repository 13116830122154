import { ALERT_MESSAGE } from "constants/constants";
import { HIDE_LOADING_ALERT, HIDE_SUCCESS_ALERT, HIDE_WARNING_ALERT, SHOW_LOADING_ALERT, SHOW_SUCCESS_ALERT, SHOW_WARNING_ALERT } from "./actionTypes";


export const showSuccessAlert = (message, callback) => ({
    type: SHOW_SUCCESS_ALERT,
    payload: {message, callback}
})

export const hideSuccessAlert = () => ({
    type: HIDE_SUCCESS_ALERT
})

export const showWarningAlert = message => ({
    type: SHOW_WARNING_ALERT,
    payload: message,
})

export const hideWarningAlert = () => ({
    type: HIDE_WARNING_ALERT
})

export const showLoadingAlert = (message = ALERT_MESSAGE.LOADING) => ({
    type: SHOW_LOADING_ALERT,
    payload: message,
})

export const hideLoadingAlert = () => ({
    type: HIDE_LOADING_ALERT
})