import { CLEAR_ACCOUNT_STATE, 
         GET_ACCOUNT_DETAIL, 
         GET_ACCOUNT_DETAIL_FAIL, 
         GET_ACCOUNT_DETAIL_LOGIN, 
         GET_ACCOUNT_DETAIL_LOGIN_FAIL, 
         GET_ACCOUNT_DETAIL_LOGIN_SUCCESS, 
         GET_ACCOUNT_DETAIL_SUCCESS, 
         GET_ACCOUNT_LIST, 
         GET_ACCOUNT_LIST_FAIL, 
         GET_ACCOUNT_LIST_SUCCESS, 
         UPDATE_ACCOUNT,
         UPDATE_ACCOUNT_FAIL,
         UPDATE_ACCOUNT_SUCCESS,
         GET_DATE_FROM,
         GET_DATE_TO,
         DELETE_ACCOUNT,
         LABORATORIES_SYNC,
         LABORATORIES_SYNC_SUCCESS,
         LABORATORIES_SYNC_FAIL,
        } from "./actionTypes"

//Account
  export const clearAccount = () => ({
    type: CLEAR_ACCOUNT_STATE
  })

  export const laboratoriesSync = (request) => ({
    type: LABORATORIES_SYNC,
    payload: request
  })
  export const laboratoriesSyncSuccess = (request) => ({
    type: LABORATORIES_SYNC_SUCCESS,
    payload: request,
  })
  export const laboratoriesSyncFail = (request) => ({
    type: LABORATORIES_SYNC_FAIL,
    payload: request,
  })


  export const getAccountDetail = (request) => ({
    type: GET_ACCOUNT_DETAIL,
    payload: request
  })
  
  export const getAccountDetailSuccess = invoice => ({
    type: GET_ACCOUNT_DETAIL_SUCCESS,
    payload: invoice,
  })
  
  export const getAccountDetailFail = error => ({
    type: GET_ACCOUNT_DETAIL_FAIL,
    payload: error,
  })

  export const getAccountDetailLogin = (request) => ({
    type: GET_ACCOUNT_DETAIL_LOGIN,
    payload: request
  })
  
  export const getAccountDetailLoginSuccess = response => ({
    type: GET_ACCOUNT_DETAIL_LOGIN_SUCCESS,
    payload: response,
  })
  
  export const getAccountDetailLoginFail = error => ({
    type: GET_ACCOUNT_DETAIL_LOGIN_FAIL,
    payload: error,
  })

  export const getAccounts = (request) => ({
    type: GET_ACCOUNT_LIST,
    payload: request
  })
  
  export const getAccountsSuccess = invoice => ({
    type: GET_ACCOUNT_LIST_SUCCESS,
    payload: invoice,
  })
  
  export const getAccountsFail = error => ({
    type: GET_ACCOUNT_LIST_FAIL,
    payload: error,
  })

  export const updateAccount = (request) => ({
    type: UPDATE_ACCOUNT,
    payload: request
  })
  
  export const updateAccountSuccess = invoice => ({
    type: UPDATE_ACCOUNT_SUCCESS,
    payload: invoice,
  })
  
  export const updateAccountFail = error => ({
    type: UPDATE_ACCOUNT_FAIL,
    payload: error,
  })
  export const getDateFrom = dateFrom => ({
    type: GET_DATE_FROM,
    payload: dateFrom
  })
  export const getDateTo = dateTo =>({
    type: GET_DATE_TO,
    payload: dateTo
  })
  export const deleteAccount = request => ({
    type: DELETE_ACCOUNT,
    payload: request
  })