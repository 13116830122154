import { call, put, takeEvery } from "redux-saga/effects";

import {
    GET_BENEFIT_CATEGORIES_LIST
} from "./actionTypes";

import {
    getBenefitCategoriesSuccess,
    getBenefitCategoriesFail
} from "./actions";

import {
    httpGetBenefitCategories
} from "../../helpers/Api/api-helper";

import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions"
import { ALERT_MESSAGE } from "constants/constants";

function* getBenefitCategoryList() {
    try {
      yield put(showLoadingAlert())
      const response = yield call(httpGetBenefitCategories)
      yield put(hideLoadingAlert())
      yield put(getBenefitCategoriesSuccess(response))
    } catch (error) {
      yield put(hideLoadingAlert())
      yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
      yield put(getBenefitCategoriesFail(error))
    }
}

function* benefitCategorySaga() {
    yield takeEvery(GET_BENEFIT_CATEGORIES_LIST, getBenefitCategoryList)
}

export default benefitCategorySaga;