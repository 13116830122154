/* QUESTIONS */
export const CLEAR_QUESTIONS_STATE = "CLEAR_QUESTIONS_STATE" 

export const GET_QUESTIONS_BY_TYPE = "GET_QUESTIONS_BY_TYPE"
export const GET_QUESTIONS_BY_TYPE_FAIL = "GET_QUESTIONS_BY_TYPE_FAIL"
export const GET_QUESTIONS_BY_TYPE_SUCCESS = "GET_QUESTIONS_BY_TYPE_SUCCESS"

export const GET_QUESTION_LIST = "GET_QUESTION_LIST"
export const GET_QUESTION_LIST_FAIL = "GET_QUESTION_LIST_FAIL"
export const GET_QUESTION_LIST_SUCCESS = "GET_QUESTION_LIST_SUCCESS"

export const GET_QUESTION_DETAIL = "GET_QUESTION_DETAIL"
export const GET_QUESTION_DETAIL_FAIL = "GET_QUESTION_DETAIL_FAIL"
export const GET_QUESTION_DETAIL_SUCCESS = "GET_QUESTION_DETAIL_SUCCESS"

export const ADD_QUESTION = "ADD_QUESTION"
export const ADD_QUESTION_FAIL = "ADD_QUESTION_FAIL"
export const ADD_QUESTION_SUCCESS = "ADD_QUESTION_SUCCESS"

export const UPDATE_QUESTION = "UPDATE_QUESTION"
export const UPDATE_QUESTION_FAIL = "UPDATE_QUESTION_FAIL"
export const UPDATE_QUESTION_SUCCESS = "UPDATE_QUESTION_SUCCESS"

export const DELETE_QUESTION = "DELETE_QUESTION"
export const DELETE_QUESTION_FAIL = "DELETE_QUESTION_FAIL"
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS"