import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getValueFromDTO, toDateISOStringFormat,toFullDateISOStringFormat } from "helpers/utils";
import PrescriptionProducts from "../../../../components/Common/Prescription/prescription-products";
import PrescriptionSearchTemplateForm from "../../../../components/Common/Prescription/prescription-search-template-form";
import FormFieldBasic from "pages/Utility/form-field-basic";
import PrescriptionFiles from "components/Common/Prescription/prescription-files";
import { useSelector } from "react-redux";

const PrescriptionForm = ({ validateMedicalInformation, onSubmit, onBack, template, onDownloadPdf, setShowUploadPdf }) => {
  const { t } = useTranslation();
  const { accountCode } = useParams();

  const [products, setProducts] = useState(
    template ? template.prescriptionProducts : []
  );
  const [attachments, setAttachments] = useState(
    template && template.prescriptionFiles ? template.prescriptionFiles : []
  );

  const [showTemplateModal, setShowTemplateModal] = useState(false);

  const prescriptionCreated = useSelector(state => state.prescriptions.created);
  const prescriptionUploaded = useSelector(state => state.prescriptions.uploaded);

  const [formData, setFormData] = useState({
    description: getValueFromDTO(template, "doctorPrescription"),
    productsLength: 0,
  });

  const handleOnChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    setFormData({ ...formData, productsLength: products.length });
  }, [products]);

  const prepareToSave = (isTemplate) => {
    
    let data = {
      ...formData,
      accountCode: accountCode,
      helpIndicator: "N",
      doctorPrescription: formData.description,
      doctorFullname: "",
      doctorMedicalCode: "",
      doctorDiagnosticDate: toFullDateISOStringFormat(new Date()),
      prescriptionStatus: null
    };

    if (isTemplate) {
      data.prescriptionStatus = "TEM";
    } else {
      data.patientObservation = '';
    }

    if (attachments.length > 0) {
      data.prescriptionFiles = attachments;
      
    }

    data.prescriptionProducts = products.map(prescriptionProduct => ({
      
      id: prescriptionProduct.id,
      productId: prescriptionProduct.product.id,
      doctorPrescription: prescriptionProduct.doctorPrescription,
      currencyCode: prescriptionProduct.product.currencyCode,
      price: prescriptionProduct.product.price,
      quantity: prescriptionProduct.quantity,
      taxPercentage: prescriptionProduct.product.taxPercentage,
      product: {
        //id: product.id,
        name: prescriptionProduct.product.name,
        price: prescriptionProduct.product.price,
        prescription: prescriptionProduct.product.prescription,
        taxPercentage: prescriptionProduct.product.taxPercentage,
      },
    }));

    if (template) {
      data.id = template.id;
    }

    onSubmit(data);
  };

  const selectTemplate = template => {
    setShowTemplateModal(false);
    setProducts(template.prescriptionProducts);
    setAttachments(template.prescriptionFiles);
    setFormData({ ...formData, description: template.doctorPrescription });
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formData,
    validationSchema:
      Yup.object().shape({
        description: Yup.string().required().max(200),
        productsLength: Yup.number().required().min(1, t('You have to add at least one product')),
      }),
    onSubmit: (values, actions) => {
      actions.setSubmitting(false);
      prepareToSave();
    },
    onReset: (values, helper) => {

    }
  });

  const { touched, errors, values, submitForm, handleBlur, handleSubmit, handleReset } = formik;

  return (
    <React.Fragment>

      <Col xl={{ size: 10, offset: 1 }}>
        <Card>
          <CardBody>

            <Row className="mb-3">
              <Col sm={12}>
                <div className="button-items">
                  <Button
                    type="button"
                    color="primary"
                    onClick={() => {
                      setShowTemplateModal(true);
                    }}
                  >
                    <i className="mdi mdi-file-download" /> {t("Load Template")}
                  </Button>
                </div>
              </Col>
            </Row>
            <form className="needs-validation" onSubmit={handleSubmit} onReset={handleReset}>
              <Row>
                <Col md={6}>
                  <FormFieldBasic
                    key="description"
                    type="textarea"
                    maxLength={200}
                    fieldName="description"
                    fieldValue={values.description}
                    label={t("Description")}
                    errors={errors}
                    touched={touched}
                    handleOnChange={handleOnChange}
                    handleBlur={handleBlur}
                  />
                </Col>
                <Col md={6}>
                  {/* attachments */}
                  <PrescriptionFiles
                    attachments={attachments}
                    setAttachments={setAttachments}
                  />
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <h4>{t("Medicine to include in the prescription")}</h4>
                  {/*Prescrption Products*/}
                  <PrescriptionProducts
                    products={products}
                    setProducts={setProducts}
                  />
                  <FormFieldBasic
                    key="productsLength"
                    type="hidden"
                    maxLength={200}
                    fieldName="productsLength"
                    fieldValue={values.productsLength}
                    label={t("")}
                    errors={errors}
                    touched={touched}
                    handleOnChange={handleOnChange}
                    handleBlur={handleBlur}
                  />
                </Col>
              </Row>


              <Row>
                <Col sm={12}>
                  <div className="button-items">
                    {prescriptionCreated ?
                      <Button 
                        color="primary" 
                        type="button" 
                        onClick={onDownloadPdf}>
                          {t('Download Prescription')}
                      </Button>
                    : null}

                    {prescriptionCreated && !prescriptionUploaded ?
                      <Button
                        color="primary"
                        type="button"
                        onClick={() => { setShowUploadPdf(true); }}
                      >
                        {t('Upload Signed Prescription')}
                      </Button>
                      : null
                    }
                    <Button 
                      type="button" 
                      color="primary" 
                      className="button" 
                      onClick={() => { validateMedicalInformation(); submitForm(); }}
                    >
                      <i className="mdi mdi-content-save me-1"></i>
                      {t("send")}
                    </Button>
                    <Button
                      type="button"
                      color="primary"
                      className="button"
                      onClick={() => {
                        prepareToSave(true);
                      }}
                    >
                      <i className="mdi mdi-content-save me-1"></i>
                      {t("Save as Template")}
                    </Button>
                    <Button
                      color="light"
                      className="button mr-1"
                      onClick={() => {
                        onBack();
                      }}
                    >
                      <i className="mdi mdi-arrow-left me-1"></i>
                      {t("Back")}
                    </Button>
                  </div>
                </Col>
              </Row>

            </form>

          </CardBody>
        </Card>
      </Col>
      {showTemplateModal ? (
        <PrescriptionSearchTemplateForm
          isOpen={showTemplateModal}
          onSelect={selectTemplate}
          onCancel={() => {
            setShowTemplateModal(false);
          }}
        />
      ) : null}
    </React.Fragment>
  );
};

PrescriptionForm.propTypes = {
  validateMedicalInformation: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  template: PropTypes.any,
  onBack: PropTypes.func.isRequired,
  onDownloadPdf: PropTypes.func.isRequired,
  setShowUploadPdf: PropTypes.func.isRequired
};

export default PrescriptionForm;
