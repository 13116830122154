import DataTable from "components/Common/DataTable/datatable";
import PageBody from "components/Common/PageLayout/page-body";
import { ALERT_MESSAGE } from "constants/constants";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button, Col, Row } from "reactstrap";
import {
  clearRole,
  deleteRole,
  getRoles,
  showSuccessAlert,
} from "store/actions";

const RoleList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [showDelete, setShowDelete] = useState(false);
  const [role, setRole] = useState(null);
  const [data, setData] = useState([]);

  const roles = useSelector(state => state.roles.roles);
  const roleDeleted = useSelector(state => state.roles.deleted);

  useEffect(() => {
    dispatch(getRoles());
  }, []);

  useEffect(() => {
    if (roleDeleted) {
      dispatch(
        showSuccessAlert(ALERT_MESSAGE.DELETED, () => {
          dispatch(clearRole());
          dispatch(getRoles());
        })
      );
    }
  }, [roleDeleted]);

  useEffect(() => {
    if (roles) {

      setData(roles.map(current => ({
        id: current.id,
        //scope: t(current.scope.code),
        code: current.code,
        description: t(current.description)
      })));

    }
  }, [roles]);

  const columns = [
    {
      text: t("Code"),
      dataField: "code",
      sort: true,
      formatter: (cellContent, row) => (
        <React.Fragment>{row.code}</React.Fragment>
      ),
    },
    {
      text: t("Description"),
      dataField: "description",
      sort: true,
      formatter: (cellContent, row) => (
        <React.Fragment>{row.description}</React.Fragment>
      ),
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "",
      formatter: (cellContent, row) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => editRow(row)}
            ></i>
          </Link>
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-lock-check font-size-18"
              id="edittooltip"
              onClick={() => viewResources(row)}
            ></i>
          </Link>
          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => deleteRow(row)}
            ></i>
          </Link>
        </div>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const editRow = row => {
    history.push(`/security-groups-edit/${row.id}`);
  };

  const viewResources = row => {
    history.push(`/security-groups-options/${row.id}`);
  };

  const deleteRow = row => {
    setRole(row);
    setShowDelete(true);
  };

  const onDelete = () => {
    setShowDelete(false);
    dispatch(deleteRole(role));
  };

  return (
    <React.Fragment>
      {showDelete ? (
        <SweetAlert
          title={t("confirm.delete")}
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText={t("Yes")}
          cancelBtnText={t("No")}
          onConfirm={() => {
            onDelete();
          }}
          onCancel={() => {
            setShowDelete(false);
          }}
        ></SweetAlert>
      ) : null}

      <PageBody
        title="Groups"
        actions={
          <React.Fragment>
            <Button
              color="primary"
              className="button"
              onClick={() => {
                history.push(`/security-groups-new`);
              }}
            >
              <i className="mdi mdi-plus-circle-outline me-1"></i>
              {t("Create")}
            </Button>
            <Button
              color="light"
              className="button"
              onClick={() => {
                history.push("/dashboard");
              }}
            >
              <i className="mdi mdi-arrow-left me-1"></i>
              {t("Back")}
            </Button>
          </React.Fragment>
        }
      >
        <Row>
          <Col lg={12}>
            <DataTable
              columns={columns}
              data={data}
              sort={defaultSorted}
              keyField='id'
              pageSize={100}
            />
          </Col>
        </Row>
      </PageBody>
    </React.Fragment>
  );
};

export default RoleList;

