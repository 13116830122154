import { all, fork } from "redux-saga/effects";

import affiliatedsSaga from "./Affiliated/saga";
import affiliatedInvoicesSaga from "./AffiliatedInvoice/saga";
import membershipSaga from "./Membership/saga";
import ecommerceSaga from "./ECommerce/saga";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import calendarSaga from "./calendar/saga";
import chatSaga from "./chat/saga";
import cryptoSaga from "./crypto/saga";
import invoiceSaga from "./invoices/saga";
import projectsSaga from "./projects/saga";
import tasksSaga from "./tasks/saga";
import mailsSaga from "./mails/saga";
import contactsSaga from "./contacts/saga";
import dashboardSaga from "./dashboard/saga";
import dashboardSaasSaga from "./dashboard-saas/saga";
import termsSaga from "./Term/saga";
import benefitCategorySaga from "./BenefitCategory/saga";
import affiliatedBeneficiariesSaga from "./AffiliatedBeneficiary/saga";
import catalogSaga from "./Catalog/saga";
import identificationTypeSaga from "./IdentificationType/saga";
import affiliatedMembershipCancellationSaga from "./AffiliatedMembershipCancellation/saga";
import questionsSaga from "./Question/saga";
import quoterMembershipsSaga from "./QuoterMembership/saga";
import businessUnitsSaga from "./BusinessUnit/saga";
import subsidiariesSaga from "./Subsidiary/saga";
import principalsSaga from "./Principal/saga";
import accountSaga from "./Account/saga";
import paymentMethodsSaga from "./PaymentMethod/saga";
import rolesSaga from "./Role/saga";
import changePasswordSaga from "./ChangePassword/saga";
import medicalServicesSaga from "./MedicalService/saga";
import medicalCentersSaga from "./MedicalCenter/saga";
import fetchLaboratoryOrderHistoricalListSaga from "./LaboratoryOrder/saga";
import invoicePaymentSaga from "./InvoicePayment/saga";
import rewardProgramSaga from "./Reward/saga";
import fetchProductCategoriesSaga from "./ProductCategory/saga";
import affiliatedExpensesSaga from "./AffiliatedExpenses/saga";
import fetchProductSearchSaga from "./ProductSearch/saga";
import prescriptionsSaga from "./Prescription/saga";
import relatedProductSaga from "./RelatedProduct/saga";
import promotionsListSaga from "./PromotionsList/saga";
import deliveryPackagesSaga from "./DeliveryPackage/saga";
import sampleCollectsSaga from "./SampleCollect/saga";
import feeListsSaga from "./Fee/saga";
import countrySaga from "./Country/saga";
import servicePrescriptionsSaga from "./LaboratoryPrescription/saga";
import membershipAffiliatedBenefitSaga from "./MembershipAffiliatedBenefit/saga";
import membershipAffiliatedDetailSaga from "./MembershipAffiliatedDetail/saga";
import medicalPatientSaga from "./MedicalPatient/saga"

export default function* rootSaga() {
  yield all([
    fork(affiliatedsSaga),
    fork(affiliatedInvoicesSaga),
    fork(membershipSaga),
    fork(ecommerceSaga),

    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(ecommerceSaga),
    fork(calendarSaga),
    fork(chatSaga),
    fork(mailsSaga),
    fork(cryptoSaga),
    fork(invoiceSaga),
    fork(projectsSaga),
    fork(tasksSaga),
    fork(contactsSaga),
    fork(dashboardSaga),
    fork(dashboardSaasSaga),
    fork(termsSaga),
    fork(benefitCategorySaga),
    fork(affiliatedBeneficiariesSaga),
    fork(catalogSaga),
    fork(identificationTypeSaga),
    fork(affiliatedMembershipCancellationSaga),
    fork(questionsSaga),
    fork(quoterMembershipsSaga),
    fork(businessUnitsSaga),
    fork(subsidiariesSaga),
    fork(principalsSaga),
    fork(accountSaga),
    fork(paymentMethodsSaga),
    fork(rolesSaga),
    fork(changePasswordSaga),
    fork(medicalServicesSaga),
    fork(medicalCentersSaga),
    fork(fetchLaboratoryOrderHistoricalListSaga),
    fork(invoicePaymentSaga),
    fork(rewardProgramSaga),
    fork(fetchProductCategoriesSaga),
    fork(affiliatedExpensesSaga),
    fork(fetchProductSearchSaga),
    fork(prescriptionsSaga),
    fork(relatedProductSaga),
    fork(promotionsListSaga),
    fork(deliveryPackagesSaga),
    fork(sampleCollectsSaga),
    fork(feeListsSaga),
    fork(countrySaga),
    fork(servicePrescriptionsSaga),
    fork(membershipAffiliatedBenefitSaga),
    fork(membershipAffiliatedDetailSaga),
    fork(medicalPatientSaga)
  ]);
}
