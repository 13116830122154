import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Carousel,
  CarouselControl,
  CarouselIndicators,
  CarouselItem,
} from "reactstrap";
import image from 'assets/images/vitae/Logos-vitae.png'

const ProductSearchCarousel = ({ productMedias }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const onExiting = () => {
    setAnimating(true);
  };

  const onExited = () => {
    setAnimating(false);
  };

  const next = () => {
    if (!animating) {
      const nextIndex =
        activeIndex === productMedias.length - 1 ? 0 : activeIndex + 1;
      setActiveIndex(nextIndex);
    }
  };

  const previous = () => {
    if (!animating) {
      const nextIndex =
        activeIndex === 0 ? productMedias.length - 1 : activeIndex - 1;
      setActiveIndex(nextIndex);
    }
  };

  const goToIndex = newIndex => {
    if (!animating) {
      setActiveIndex(newIndex);
    }
  };

  const slides = productMedias.map(item => {
    return (
      <CarouselItem onExiting={onExiting} onExited={onExited} key={item.id}>
        <img
          src={item.filePath}
          className="d-block img-fluid related-image"
          alt={item.fileName}
          onError={(evt) => (evt.target.onerror = null, evt.target.src = image)}
        />
      </CarouselItem>
    );
  });

  return (
    <React.Fragment>
      <Carousel activeIndex={activeIndex} next={next} previous={previous}>
        <CarouselIndicators
          items={productMedias}
          activeIndex={activeIndex}
          onClickHandler={goToIndex}
        />
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={next}
        />
      </Carousel>
    </React.Fragment>
  );
};

ProductSearchCarousel.propTypes = {
  productMedias: PropTypes.array.isRequired,
};
export default ProductSearchCarousel;
