import { ALERT_MESSAGE } from "constants/constants"
import { 
  httpGetRelatedProducts,
  httpPostRelatedProducts
} from "helpers/Api/api-helper"
import { call, put, takeEvery } from "redux-saga/effects"
import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions"
import { 
  getRelatedProductsSuccess,
  getRelatedProductsFail,
  addRelatedProductsSuccess,
  addRelatedProductsFail
} from "./actions"
import { 
  GET_RELATED_PRODUCTS_LIST,
  ADD_RELATED_PRODUCTS
 } from "./actionTypes"


function* fetchGetRelatedProducts(request) {
    try {
      yield put(showLoadingAlert());
      const response = yield call(httpGetRelatedProducts, request.payload)
      yield put(hideLoadingAlert())
      yield put(getRelatedProductsSuccess(response))
    } catch (error) {
      yield put(hideLoadingAlert())
      yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
      yield put(getRelatedProductsFail(error))
    }
}

function* fetchAddRelatedProducts(request) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.SAVING))
    const response = yield call(httpPostRelatedProducts, request.payload)
    yield put(hideLoadingAlert())
    yield put(addRelatedProductsSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.ADD_ERROR))
    yield put(addRelatedProductsFail(error))
  }
}

function* relatedProductSaga() {
    yield takeEvery(GET_RELATED_PRODUCTS_LIST, fetchGetRelatedProducts)
    yield takeEvery(ADD_RELATED_PRODUCTS, fetchAddRelatedProducts)
  }
  
  export default relatedProductSaga;