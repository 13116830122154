import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col, Button, CardImg } from "reactstrap";
import { useTranslation } from "react-i18next";
import { toDateStringFormat } from "helpers/utils";
import PrescriptionServiceCard from "./prescription-service-card";

const LaboratoryPrescriptionCard = ({
  prescription,
  getCurrencySymbol,
  getIdentificationType,
}) => {
  const { t } = useTranslation();
  const {
    doctorDiagnosticDate,
    patient,
    medicalCenter,
    doctorFullname,
    qrCode,
    prescriptionFiles,
    prescriptionServices,
    pdfUrl,
    doctorPrescription,
  } = prescription;

  const getPatientName = () => {
    const { firstName, secondName, firstSurname, secondSurname } = patient;
    return [firstName, secondName, firstSurname, secondSurname].join(" ");
  };

  const getPicture = () => {
    return `data:image/png;base64,${qrCode}`;
  };

  const downloadFile = file => {
    const link = document.createElement("a");
    link.download = `${file.fileName}.${file.type}`;
    link.href = file.filePath;
    link.hidden = true;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  return (
    <React.Fragment>
      <Row key={'row1'}>
        <Col sm={6} md={6} lg={6}>
          <Card>
            <CardBody>
              <CardImg
                className="img-fluid p-1"
                src={getPicture()}
                alt="Prescription"
              />
              <h4>
                {t("Prescription Date")}:{" "}
                <strong> {toDateStringFormat(doctorDiagnosticDate)}</strong>
              </h4>
              <h4>
                {t("Patient")}: <strong>{getPatientName()}</strong>
              </h4>
              <h4>
                {t(getIdentificationType(patient.identificationTypeId))}:{" "}
                {patient.identification}
              </h4>
              <h4>
                {t("Phone")}: {patient.mainPhone}
              </h4>
            </CardBody>
          </Card>
        </Col>
        <Col sm={6} md={6}>
          {/*Prescription Products*/}

          <Card>
            <CardBody>
              <h4>
                {t("Doctor")}: {doctorFullname}
              </h4>
              <h4>
                <strong>
                  {t("Medical Center")}: {medicalCenter.name}
                </strong>
              </h4>
              <h4>
                <small>
                  {medicalCenter.geoDistribution1}, {medicalCenter.address}
                </small>
              </h4>
              <h4>
                <strong>{t("Prescription Observation")}: </strong>
              </h4>
              <h4>
                <small>{doctorPrescription}</small>
              </h4>

              <h4>
                <strong>{t("Prescription Files")}</strong>
              </h4>
              <hr />
              {prescriptionFiles.map(file => (
                <Row key={file.index} className="pt-1 mt-1">
                  <Col xs={9} className="pt-3">
                    <strong>
                      {file.fileName}.{file.type}
                    </strong>
                  </Col>
                  <Col xs={3} className="pt-1">
                    <Button
                      type="button"
                      className="m-1"
                      color="primary"
                      onClick={() => {
                        downloadFile(file);
                      }}
                    >
                      <i className="mdi mdi-download"></i>
                    </Button>
                  </Col>
                  <hr />
                </Row>
              ))}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row key={'row2'}>
        <Col xs={12}>
          <h4>
            <strong>{t("Laboratory Services")}</strong>
          </h4>
        </Col>
      </Row>
      <Row key={'row3'}>
        {prescriptionServices.map(service => (
          service.medicalService &&
          <PrescriptionServiceCard
            key={service.id}
            service={service}
            getCurrencySymbol={getCurrencySymbol}
            onSelect={() => {}}
          />
        ))}
      </Row>
    </React.Fragment>
  );
};

LaboratoryPrescriptionCard.propTypes = {
  prescription: PropTypes.object,
  getCurrencySymbol: PropTypes.func,
  getIdentificationType: PropTypes.func,
};

export default LaboratoryPrescriptionCard;
