import PageBody from 'components/Common/PageLayout/page-body';
import { ALERT_MESSAGE } from 'constants/constants';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { addPromotion, clearPromotionList, showSuccessAlert } from 'store/actions';
import PromotionForm from './Components/promotion-form';

const PromotionsAddPage = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();

    const created = useSelector(state => state.promotionsList.promotionCreated);

    useEffect(() => {
        if(created) {
            dispatch(showSuccessAlert(ALERT_MESSAGE.SAVED, () => {
                dispatch(clearPromotionList());
                history.push("/management-promotions-list")
            }));
        }
    }, [created]);

    const save = (promotionData) => {
        dispatch(addPromotion(promotionData));
    }

    return (
        <React.Fragment>
            <PageBody
                title='Create Promotion'
            >
                <PromotionForm 
                    onSubmit={save}
                    onBack={() => { history.push('/management-promotions-list') }} 
                />
            </PageBody>
        </React.Fragment>
    );
}

export default PromotionsAddPage;