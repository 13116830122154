import {
  AFFILIATED_EXPENSES,
  AFFILIATED_EXPENSES_FAIL,
  AFFILIATED_EXPENSES_SUCCESS,
} from "./actionTypes";

const INIT_STATE = {
  expenses: null,
  loading: false,
  error: {},
};

const affiliatedExpenses = (state = INIT_STATE, action) => {
  switch (action.type) {
    case AFFILIATED_EXPENSES:
      return {
        ...state,
        loading: true,
        expenses: null,
        error: {},
      };
    case AFFILIATED_EXPENSES_SUCCESS:
      return {
        ...state,
        loading: false,
        expenses: action.payload,
      };
    case AFFILIATED_EXPENSES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default affiliatedExpenses;
