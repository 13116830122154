import {
  GET_SERVICES_LIST,
  GET_SERVICES_LIST_FAIL,
  GET_SERVICES_LIST_SUCCESS
} from "./actionTypes"

//Memberships
export const getECommerceServices = () => ({
  type: GET_SERVICES_LIST
})

export const getServicesSuccess = invoice => ({
  type: GET_SERVICES_LIST_SUCCESS,
  payload: invoice,
})

export const getServicessFail = error => ({
  type: GET_SERVICES_LIST_FAIL,
  payload: error,
})

