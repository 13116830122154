/* ROLES */
export const CLEAR_ROLES_STATE = "CLEAR_ROLES_STATE" 

export const GET_ROLE_LIST = "GET_ROLE_LIST"
export const GET_ROLE_LIST_FAIL = "GET_ROLE_LIST_FAIL"
export const GET_ROLE_LIST_SUCCESS = "GET_ROLE_LIST_SUCCESS"

export const GET_ROLE_DETAIL = "GET_ROLE_DETAIL"
export const GET_ROLE_DETAIL_FAIL = "GET_ROLE_DETAIL_FAIL"
export const GET_ROLE_DETAIL_SUCCESS = "GET_ROLE_DETAIL_SUCCESS"

export const ADD_ROLE = "ADD_ROLE"
export const ADD_ROLE_FAIL = "ADD_ROLE_FAIL"
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS"

export const UPDATE_ROLE = "UPDATE_ROLE"
export const UPDATE_ROLE_FAIL = "UPDATE_ROLE_FAIL"
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS"

export const DELETE_ROLE = "DELETE_ROLE"
export const DELETE_ROLE_FAIL = "DELETE_ROLE_FAIL"
export const DELETE_ROLE_SUCCESS = "DELETE_ROLE_SUCCESS"

export const GET_REALM_LIST = "GET_REALM_LIST"
export const GET_REALM_LIST_SUCCESS = "GET_REALM_LIST_SUCCESS"
export const GET_REALM_LIST_FAIL = "GET_REALM_LIST_FAIL"

export const GET_RESOURCE_LIST = "GET_RESOURCE_LIST"
export const GET_RESOURCE_LIST_SUCCESS = "GET_RESOURCE_LIST_SUCCESS"
export const GET_RESOURCE_LIST_FAIL = "GET_RESOURCE_LIST_FAIL"

export const GET_ROLE_RESOURCE_LIST = "GET_ROLE_RESOURCE_LIST"
export const GET_ROLE_RESOURCE_LIST_SUCCESS = "GET_ROLE_RESOURCE_LIST_SUCCESS"
export const GET_ROLE_RESOURCE_LIST_FAIL = "GET_ROLE_RESOURCE_LIST_FAIL"

export const SAVE_ROLE_RESOURCE_LIST = "SAVE_ROLE_RESOURCE_LIST"
export const SAVE_ROLE_RESOURCE_LIST_SUCCESS = "SAVE_ROLE_RESOURCE_LIST_SUCCESS"
export const SAVE_ROLE_RESOURCE_LIST_FAIL = "SAVE_ROLE_RESOURCE_LIST_FAIL"
