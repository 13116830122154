import {
    INVOICE_PAYMENT_MEMBERSHIP_CACELLATION_CLEAR,
    INVOICE_PAYMENT_MEMBERSHIP_CACELLATION,
    INVOICE_PAYMENT_MEMBERSHIP_CACELLATION_FAIL,
    INVOICE_PAYMENT_MEMBERSHIP_CACELLATION_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    invoicePayment: false,
    success: false,
    loading: false,
    error: {},
}

const invoicePayments = (state = INIT_STATE, action) => {

    switch (action.type) {
        case INVOICE_PAYMENT_MEMBERSHIP_CACELLATION_CLEAR:
            return {
                ...state,
                invoicePayment: false,
                success: false,
                loading: false,
                error: {},
            }
        case INVOICE_PAYMENT_MEMBERSHIP_CACELLATION:
            return {
                ...state,
                invoicePayment: false,
                loading: true,
            }
        case INVOICE_PAYMENT_MEMBERSHIP_CACELLATION_SUCCESS:
            return {
                ...state,
                loading: false,
                invoicePayment: true,
            }
        case INVOICE_PAYMENT_MEMBERSHIP_CACELLATION_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}

export default invoicePayments;