import { 
    INVOICE_PAYMENT_MEMBERSHIP_CACELLATION_CLEAR,
    INVOICE_PAYMENT_MEMBERSHIP_CACELLATION, 
    INVOICE_PAYMENT_MEMBERSHIP_CACELLATION_FAIL, 
    INVOICE_PAYMENT_MEMBERSHIP_CACELLATION_SUCCESS } from "./actionTypes"

//Membership Payment

export const invoicePaymentMembershipCancellationClear = () => ({
    type: INVOICE_PAYMENT_MEMBERSHIP_CACELLATION_CLEAR
})

export const invoicePaymentMembershipCancellation = (request) => ({
    type: INVOICE_PAYMENT_MEMBERSHIP_CACELLATION,
    payload: request
})

export const invoicePaymentMembershipCancellationSuccess = response => ({
    type: INVOICE_PAYMENT_MEMBERSHIP_CACELLATION_SUCCESS,
    payload: response,
})

export const invoicePaymentMembershipCancellationFail = error => ({
    type: INVOICE_PAYMENT_MEMBERSHIP_CACELLATION_FAIL,
    payload: error,
})