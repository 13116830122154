import React, { useEffect } from 'react';
import PageBody from 'components/Common/PageLayout/page-body';
import { ALERT_MESSAGE } from 'constants/constants';
import { useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { clearPromotionList, sendPromotionNotification, showSuccessAlert } from 'store/actions';
import PromotionNotificationForm from './Components/promotion-notification-form';
import { useTranslation } from 'react-i18next';
import json from 'jsonwebtoken';

const PromotionNotificationAddPage = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [showConfirm, setShowConfirm] = useState(false);
    const [data, setData] = useState(null);

    const sent = useSelector(state => state.promotionsList.promotionNotificationSent);

    useEffect(() => {
        if (sent) {
            dispatch(showSuccessAlert(ALERT_MESSAGE.PROMOTION_NOTIFICATION_SENT, () => {
                dispatch(clearPromotionList());
                history.push("/")
            }));
        }
    }, [sent]);

    const save = (promotionData) => {
        let accountCode = null;
        if (localStorage.getItem("authUser")) {
            const obj = JSON.parse(localStorage.getItem("authUser"));
            const user = json.decode(obj.idToken);
            accountCode = user.sub;
        }
        setData({
            senderAccountCode: accountCode,
            deliveryChannel: promotionData.type,
            subject: promotionData.subject,
            message: promotionData.body,
        });
        setShowConfirm(true);
    }

    const onSend = () => {
        setShowConfirm(false);
        dispatch(sendPromotionNotification(data));
    }

    return (
        <PageBody
            title='Create Promotion Notification'
        >
            <PromotionNotificationForm
                onSubmit={save}
                onBack={() => { history.push('/') }}
            />

            {showConfirm ?

                <SweetAlert
                    title={t('confirm.send.notification')}
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText={t('Yes')}
                    cancelBtnText={t('No')}
                    onConfirm={() => {
                        onSend();
                    }}
                    onCancel={() => {
                        setShowConfirm(false);
                    }}
                >

                </SweetAlert>
                : null}
        </PageBody>
    );
}

export default PromotionNotificationAddPage;