import {
    AFFILIATED_REWARD_PROGRAM_BY_ACCOUNT, 
    AFFILIATED_REWARD_PROGRAM_BY_ACCOUNT_FAIL, 
    AFFILIATED_REWARD_PROGRAM_BY_ACCOUNT_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
    affiliatedRewards: [],
    success: false,
    loading: false,
    error: {},
}

const rewardPrograms = (state = INIT_STATE, action) => {

    switch (action.type) {
        case AFFILIATED_REWARD_PROGRAM_BY_ACCOUNT:
            return {
                ...state,
                affiliatedRewards: [],
                loading: true,
            }
        case AFFILIATED_REWARD_PROGRAM_BY_ACCOUNT_SUCCESS:
            return {
                ...state,
                loading: false,
                affiliatedRewards: action.payload,
            }
        case AFFILIATED_REWARD_PROGRAM_BY_ACCOUNT_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}

export default rewardPrograms;