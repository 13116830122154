import React, { Component } from "react"
import PropTypes from 'prop-types'
import { Col, Card, CardBody, CardImg } from "reactstrap"

class MembershipListCard extends Component {
  render() {

    const benefitsList = this.props.membership?.membershipBenefits;
  
    const getCurrencySymbol = currencyCode => {
      return currencyCode === 'USD' ? '$' : '₡';
    };

    
    return (
      <React.Fragment>
        <Col xl="4" md="4">
          <Card className="plan-box">
            <CardBody className="p-4">
              <CardImg className="img-fluid" src={this.props.membership.picture} alt="Membership" />
              <div className="d-flex pt-5">
                <div className="flex-grow-1">
                  <h5>{this.props.membership.name}</h5>
                  <p className="text-muted">{this.props.membership.description}</p>
                </div>
                <div className="ms-3">
                  <i
                    className={"bx bx-gift h1 text-primary"}
                  />
                </div>
              </div>
              <div className="py-4">
                <h2>
                  <sup>
                    <small>{getCurrencySymbol(this.props.membership.currencyCode)}</small>
                  </sup>{" "}
                  {this.props.membership.price}/{" "}
                  <span className="font-size-13">por mes</span>
                </h2>
              </div>

              <div className="plan-features mt-5">
                {benefitsList && benefitsList.map((benefit, i) => (
                  <p key={"membershipBenefit" + i}>
                    <i className="bx bx-checkbox-square text-primary me-2" />{" "}
                    {benefit.description} / {benefit.detail}
                  </p>
                ))}
              </div>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    )
  }
}

MembershipListCard.propTypes = {
  membership: PropTypes.object
}

export default MembershipListCard
