import { call, put, takeEvery } from "redux-saga/effects";

import {
    GET_COUNTRY_LIST
} from "./actionTypes";

import {
    getCountriesSuccess,
    getCountriesFail
} from "./actions";

import {
    httpGetCountries
} from "../../helpers/Api/api-helper";

import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions"
import { ALERT_MESSAGE } from "constants/constants";

function* fetchCountryList() {
    try {
      yield put(showLoadingAlert())
      const response = yield call(httpGetCountries)
      yield put(hideLoadingAlert())
      yield put(getCountriesSuccess(response))
    } catch (error) {
      yield put(hideLoadingAlert())
      yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
      yield put(getCountriesFail(error))
    }
}

function* countrySaga() {
    yield takeEvery(GET_COUNTRY_LIST, fetchCountryList)
}

export default countrySaga;