import { CLEAR_ACCOUNT_STATE,
         GET_ACCOUNT_DETAIL, 
         GET_ACCOUNT_DETAIL_FAIL, 
         GET_ACCOUNT_DETAIL_LOGIN, 
         GET_ACCOUNT_DETAIL_LOGIN_FAIL, 
         GET_ACCOUNT_DETAIL_LOGIN_SUCCESS, 
         GET_ACCOUNT_DETAIL_SUCCESS, 
         GET_ACCOUNT_LIST, 
         GET_ACCOUNT_LIST_FAIL, 
         GET_ACCOUNT_LIST_SUCCESS, 
         UPDATE_ACCOUNT,
         UPDATE_ACCOUNT_FAIL,
         UPDATE_ACCOUNT_SUCCESS,
         GET_DATE_FROM,
         GET_DATE_TO, 
         LABORATORIES_SYNC_SUCCESS,
         LABORATORIES_SYNC_FAIL} from "./actionTypes"

const INIT_STATE = {
    accountDetail: null,
    accountLogin: null,
    accountUpdated: false,
    accounts: [],
    success: false,
    loading: false,
    error: {},
    dateFrom: "",
    dateTo: "",
    laboratoriesSynctSuccess: false,
  }
  
  const accounts = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CLEAR_ACCOUNT_STATE:
        return {
            ...state,
            accountDetail: null,
            accountUpdated: false,
            accounts: [],
            success: false,
            loading: false,
            error: {},
            dateFrom: "",
            dateTo: "",
            laboratoriesSynctSuccess: false,
        }
        case LABORATORIES_SYNC_SUCCESS:
        return{
            ...state,
            laboratoriesSynctSuccess: action.payload,
            loading: false,
        }     
        case LABORATORIES_SYNC_FAIL:
        return{
            ...state,
            laboratoriesSynctSuccess: action.payload,
            loading: false,
        }
        case GET_ACCOUNT_DETAIL:
        return {
            ...state,
            accountDetail: null,
            loading: true,
        } 
        case GET_ACCOUNT_DETAIL_SUCCESS:
        return {
            ...state,
            loading: false,
            accountDetail: action.payload,
        }  
        case GET_ACCOUNT_DETAIL_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case GET_ACCOUNT_DETAIL_LOGIN:
        return {
            ...state,
            accountLogin: null,
            loading: true,
        } 
        case GET_ACCOUNT_DETAIL_LOGIN_SUCCESS:
        return {
            ...state,
            loading: false,
            accountLogin: action.payload,
        }  
        case GET_ACCOUNT_DETAIL_LOGIN_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case GET_ACCOUNT_LIST:
        return {
            ...state,
            accounts: [],
            loading: true,
        } 
        case GET_ACCOUNT_LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            accounts: action.payload,
        }  
        case GET_ACCOUNT_LIST_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case UPDATE_ACCOUNT:
        return {
            ...state,
            accountUpdated: false,
            loading: true,
        } 
        case UPDATE_ACCOUNT_SUCCESS:
        return {
            ...state,
            loading: false,
            accountUpdated: true,
        }  
        case UPDATE_ACCOUNT_FAIL:
        return {
            ...state,
            error: action.payload,
        }
        case GET_DATE_FROM:
            return{
                ...state,
                loading: false,
                dateFrom: action.payload
            }
        case GET_DATE_TO:
            return{
                ...state,
                loading: false,
                dateTo: action.payload
            }
        default:
            return state
        }
    }
    
export default accounts
      