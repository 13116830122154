import { 
    GET_CATALOGS_LIST, 
    GET_CATALOGS_LIST_FAIL, 
    GET_CATALOGS_LIST_SUCCESS } from "./actionTypes"

const INIT_STATE = {
  catalogList: [],
  genderList: [],
  maritalStatusList: [],
  consanguinityLevelList: [],
  phoneList: [],
  currencyCodeList: [],
  termList: [],
  success: false,
  loading: false,
  error: {},
}

const catalogs = (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_CATALOGS_LIST:
            return {
                ...state,
                catalogList: [],
                genderList: [],
                maritalStatusList: [],
                consanguinityLevelList: [],
                phoneList: [],
                currencyCodeList: [],
                termList: [],
                loading: true,
            }
        case GET_CATALOGS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                catalogList: action.payload,
                genderList: action.payload.find(catalog => catalog.type === 'gender').codes,
                maritalStatusList: action.payload.find(catalog => catalog.type === 'maritalStatus').codes,
                consanguinityLevelList: action.payload.find(catalog => catalog.type === 'consanguinityLevel').codes,
                phoneList: action.payload.find(catalog => catalog.type === 'phone').codes,
                currencyCodeList: action.payload.find(catalog => catalog.type === 'currencyCode').codes,
                termList: action.payload.find(catalog => catalog.type === 'termType').codes
            }  
        case GET_CATALOGS_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}

export default catalogs;