import { convertToRaw, EditorState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Form, Formik } from 'formik';
import FormField from 'pages/Utility/form-field';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import * as Yup from 'yup';
import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { useHistory } from 'react-router-dom';

import es from './../../../../constants/html-editor/i18n_es';

const PromotionNotificationForm = ({onSubmit}) => {

    const { t } = useTranslation();
    const history = useHistory();
    const [editor, setEditor] = useState(() => EditorState.createEmpty());
    const [temporalMarkup, setTemporalMarkup] = useState(null);

    const [formData, setFormData] = useState({
        type: 'IAP',
        subject: '',
        body: ''
    });

    const { type, subject, body } = formData;

    const handleOnChange = event => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }

    const handleNotificationTypeOnChange = event => {
        let newFormData = {...formData};

        if(newFormData.type === 'IAP'){
            //The user is changing from IAP to PNT so the markup is backuped
            setTemporalMarkup(newFormData.body);
            newFormData.body = '';
        } else {
            //The user is changing from PNT to IAP so the markup is restored
            newFormData.body = temporalMarkup;
        }       
        setFormData({
            newFormData,
            [event.target.name]: event.target.value
        });
    }

    const onEditorStateChange = (editorState) => {
        setEditor(editorState);
        const rawContent = convertToRaw(editorState.getCurrentContent());
        const markup = draftToHtml(
            rawContent
        );

        setFormData({
            ...formData,
            body: markup
        });
    }

    return ( 
        <React.Fragment>
            <Row>
                <Col xl={{ size: 10, offset: 1 }}>
                    <Card>
                        <CardBody>
                            <Formik
                                enableReinitialize={true}
                                initialValues={formData}
                                validationSchema={
                                    Yup.object().shape({
                                        subject: Yup.string().required(),
                                        body: Yup.string().required(),
                                        type: Yup.string().required(),
                                    })
                                }
                                onSubmit={(values, actions) => {
                                    actions.setSubmitting(false);
                                    onSubmit(formData);
                                }}
                            >
                                {({ errors, status, touched }) => (
                                    <Form className='needs-validation' >


                                        <Row>                                            
                                            <Col md={12}>
                                                <FormField
                                                        key='type'
                                                        type='select'
                                                        fieldName='type'
                                                        fieldValue={type}
                                                        label={t('Notification Type')}
                                                        errors={errors}
                                                        touched={touched}
                                                        handleOnChange={handleNotificationTypeOnChange}
                                                    >
                                                        <option key="IAP" value="IAP">{t('In App')}</option>
                                                        <option key="PNT" value="PNT">{t('Push Notification')}</option>
                                                </FormField>
                                            </Col>
                                        </Row>

                                        <Row>                                            
                                            <Col md={12}>
                                                <div style={{marginBottom: 15}}>
                                                    {formData.type === 'IAP' ? (
                                                        <span>{t('IAP Notice')}</span>
                                                    ) : (
                                                        <span>{t('PNT Notice')}</span>
                                                    )}                                                    
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row>
                                            
                                            <Col md={12}>

                                                <FormField
                                                    key='subject'
                                                    type='text'
                                                    maxLength={500}
                                                    fieldName='subject'
                                                    fieldValue={subject}
                                                    label={t('Subject')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />
                                            </Col>
                                        </Row>

                                        

                                        <Row>
                                            <Col md={12}>

                                                    {formData.type === 'IAP' ? (
                                                        <>
                                                <FormField
                                                    key='body'
                                                    type='hidden'
                                                    maxLength={4000}
                                                    fieldName='body'
                                                    fieldValue={body}
                                                    label={t('Description')}
                                                    errors={errors}
                                                    touched={touched}
                                                    handleOnChange={handleOnChange}
                                                />
                                                <Editor
                                                    toolbarClassName="toolbarClassName"
                                                    wrapperClassName="wrapperClassName"
                                                    editorClassName={(errors.body && touched.body) ? " is-invalid" : ""}
                                                                placeholder={t('Place Your Content Here')}
                                                                localization={{
                                                                    locale: 'es',
                                                                    translations: es
                                                                  }} 
                                                                  toolbar={{
                                                                    options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'link', 'emoji', 'image', 'remove', 'history'],
                                                                }}
                                                    editorState={editor}
                                                    onEditorStateChange={onEditorStateChange}
                                                            />                                                        
                                                        </>
                                                    ) : (
                                                        <FormField
                                                            key='body'
                                                            type='text'
                                                            maxLength={255}
                                                            fieldName='body'
                                                            fieldValue={body}
                                                            label={t('Description')}
                                                            errors={errors}
                                                            touched={touched}
                                                            handleOnChange={handleOnChange}
                                                />
                                                    )}    


                                            </Col>
                                        </Row>

                                        <div className="button-items mt-3">
                                            <Button type='submit' color="primary" className="button"><i className="mdi mdi-content-save me-1"></i>{t('Publish')}</Button>
                                            <Button color="light" className="button mr-1" onClick={() => { history.push('/') }}><i className="mdi mdi-arrow-left me-1"></i>{t('Back')}</Button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>
     );
}

PromotionNotificationForm.propTypes = {
    onSubmit: PropTypes.func
}

export default PromotionNotificationForm;