import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { numberFormat } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import image from 'assets/images/vitae/Logos-vitae.png'

const PromotionProductCard = ({ productDetail, onDelete, getCurrencySymbol }) => {

    const { itemCode, productMedias, currencyCode, description, unitPrice } = productDetail;
    const { t } = useTranslation();

    const getProductPicture = () => {
        let picture =
            productMedias && productMedias.length > 0 ? productMedias[0] : null;

        return picture ? picture.filePath : "";
    };

    return (
        <React.Fragment>
            <Row className="align-middle mt-1">
                <Col sm={3} className="mt-1">
                    <span className='font-size-14'>{t('Code')}: </span>
                    <strong className='font-size-14'>
                        {itemCode}
                    </strong>
                </Col>
                <Col sm={5} className="mt-1">
                    <p className="font-size-14">{description}</p>
                </Col>
                <Col sm={3} className="mt-1">
                    <span className='font-size-14'>{t('Price')}: </span>
                    <strong className='font-size-14 text-end '>
                        {getCurrencySymbol(currencyCode)}{numberFormat(unitPrice)}
                    </strong>
                </Col>
                <Col sm={1}>
                    <strong className='font-size-24 text-end dashboard-option'>
                        <i className={'text-danger mdi mdi-close-thick dahsboard-option'} onClick={() => { onDelete(productDetail) }}></i>
                    </strong>
                </Col>
            </Row>
        </React.Fragment>
    );
}
PromotionProductCard.propTypes = {
    productDetail: PropTypes.object.isRequired,
    onDelete: PropTypes.func.isRequired,
    getCurrencySymbol: PropTypes.func.isRequired
};

export default PromotionProductCard;