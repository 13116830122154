import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getValueFromDTO, numberFormat } from 'helpers/utils';
import { DEFAULT_COMPANY } from 'constants/list-types';
import FeeForm from './fee-form';
import FormFieldBasic from 'pages/Utility/form-field-basic';

const FeeListForm = ({ businessUnit, type, feeList, setFeeList, onSave, onCancel }) => {

  const currencyCodeList = useSelector(state => state.catalogs.currencyCodeList);
  const { t } = useTranslation();
  const [fee, setFee] = useState({
    fromRange: '',
    toRange: '',
    fee: 0
  });

  const [formData, setFormData] = useState({
    name: '',
    type: type,
    businessUnitType: businessUnit,
    companyId: DEFAULT_COMPANY,
    countryCode: 'CR',
    currencyCode: '',
    feesLength: 0,
  });

  const handleOnChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  useEffect(() => {
    
    if(feeList.length > 0) {
      setFormData({
        ...formData, 
        currencyCode: getValueFromDTO(feeList[0], 'currencyCode'),
        name: getValueFromDTO(feeList[0], 'name'),
        feesLength: feeList.length
      });
    } else {
      setFormData({ ...formData, feesLength: feeList.length });
    }
  }, [feeList]);



  const onDelete = feeIndex => {
    setFeeList(
      feeList.filter((current, index) => index != feeIndex)
    );
  };

  const onAdd = fee => {

    if (fee.fromRange != null && fee.toRange != null) {
      if (feeList.length > 0) {
        const lastFee = feeList[feeList.length - 1];
        if (lastFee.toRange < fee.fromRange && fee.fromRange < fee.toRange) {
          addFee(fee);
        }
      } else {
        addFee(fee);
      }
    } else {
      addFee(fee);
    }
  };

  const addFee = fee => {
    setFeeList(
      [...feeList, 
        {
          ...fee, 
          name: formData.name,
          type: formData.type,
          businessUnitType: formData.businessUnitType,
          companyId: formData.companyId,
          countryCode: formData.countryCode,
          currencyCode: formData.currencyCode
        }
      ]
    );
    
    setFee({
      fromRange: '',
      toRange: '',
      fee: 0
    });
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: formData,
    validationSchema:
      Yup.object().shape({
        name: Yup.string().required().max(200),
        currencyCode: Yup.string().required(),
        feesLength: Yup.number().required().min(1, t('You have to enter at least one rate range')),
      }),
    onSubmit: (values, actions) => {
      actions.setSubmitting(false);
      onSave(formData);
    },
    onReset: (values, helper) => {

    }
  });

  const { touched, errors, values, handleChange, submitForm, handleBlur } = formik;

  return (
    <React.Fragment>
      <Col xl={{ size: 10, offset: 1 }}>
        <Card>
          <CardBody>
            <form className='needs-validation' onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
              <Row>
                <Col md={6}>
                  <FormFieldBasic
                    key="name"
                    type="text"
                    maxLength={200}
                    fieldName="name"
                    fieldValue={values.name}
                    label={t("Name")}
                    errors={errors}
                    touched={touched}
                    handleOnChange={handleOnChange}
                    handleBlur={handleBlur}
                  />
                </Col>
                <Col md={6}>
                  <FormFieldBasic
                    key='currencyCode'
                    type='select'
                    fieldName='currencyCode'
                    fieldValue={values.currencyCode}
                    label={t('Currency')}
                    errors={errors}
                    touched={touched}
                    handleOnChange={handleOnChange}
                    handleBlur={handleBlur}
                    disabled={feeList.length > 0}
                  >
                    <option key="currencyCodeNotSelected" value="">{t('Not Selected')}</option>
                    {currencyCodeList.map(current => {
                      return (<option key={current.code} value={current.code}>{t(current.description)}</option>);
                    })}
                  </FormFieldBasic>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <h4>{t('Fee Ranges')}</h4>
                  <hr />
                  <FormFieldBasic
                    key="feesLength"
                    type="hidden"
                    maxLength={200}
                    fieldName="feesLength"
                    fieldValue={values.feesLength}
                    label={t("")}
                    errors={errors}
                    touched={touched}
                    handleOnChange={handleOnChange}
                    handleBlur={handleBlur}
                  />
                </Col>
              </Row>


            </form>

            {feeList.map((fee, index) => (
              <Row key={`fee-${index}`}>
                <Col sm={3} className="mt-2">
                  {t('From')}:{' '}
                  <strong>{fee.fromRange != null ? fee.fromRange : '-'}</strong>
                </Col>
                <Col sm={3} className="mt-2">
                  {t('To')}:{' '}
                  <strong>{fee.toRange != null ? fee.toRange : '-'}</strong>
                </Col>
                <Col sm={3} className="mt-2">
                  {t('Fee')}:{' '}
                  <strong>{numberFormat(fee.fee)}</strong>
                </Col>
                <Col sm={1}>
                  <strong className='font-size-24 text-end '>
                    <i className={'text-danger mdi mdi-close-thick dashboard-option'} onClick={() => { onDelete(index) }}></i>
                  </strong>
                </Col>
              </Row>
            ))}

            {feeList.length == 0 ? (<h5>{t('Without Ranges')}</h5>) : ''}

            <FeeForm
              onAdd={onAdd}
              fee={fee}
              setFee={setFee}
            />

            <div className="button-items mt-3">
              <Button color="primary" className="button mr-1" onClick={() => { submitForm() }}>
                <i className="mdi mdi-content-save me-1"></i>{t('Save')}
              </Button>
              <Button color="light" className="button mr-1" onClick={() => { onCancel() }}>
                <i className="mdi mdi-arrow-left me-1"></i>{t('Back')}
              </Button>
            </div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
}

FeeListForm.propTypes = {
  businessUnit: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  feeList: PropTypes.array.isRequired,
  setFeeList: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired
}

export default FeeListForm;