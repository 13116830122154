import { CLEAR_SUBSIDIARIES_STATE,
         GET_SUBSIDIARY_LIST, 
         GET_SUBSIDIARY_LIST_FAIL, 
         GET_SUBSIDIARY_LIST_SUCCESS } from "./actionTypes"

const INIT_STATE = {
    subsidiaries: [],
    success: false,
    loading: false,
    error: {}
  }
  
  const subsidiaries = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CLEAR_SUBSIDIARIES_STATE:
        return {
            ...state,
            subsidiaries: [],
            success: false,
            loading: false,
            error: {}
        }
        
        case GET_SUBSIDIARY_LIST:
        return {
            ...state,
            subsidiaries: [],
            loading: true,
        } 
        case GET_SUBSIDIARY_LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            subsidiaries: action.payload,
        }  
        case GET_SUBSIDIARY_LIST_FAIL:
        return {
            ...state,
            error: action.payload,
        }
        default:
            return state
        }
    }
    
export default subsidiaries
      