import PageBody from "components/Common/PageLayout/page-body";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "reactstrap";
import {
  getCatalogs,
  getIdentificationTypes,
  getServicePrescriptionDetail,
  getProductCategoryList,
} from "store/actions";
import LaboratoryPrescriptionCard from "./laboratory-prescription-card";

const LaboratoryPrescriptionDetailPage = () => {
  const { t } = useTranslation();
  const { accountCode, id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCatalogs());
    dispatch(getIdentificationTypes());
    dispatch(getProductCategoryList());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getServicePrescriptionDetail({ id: id }));
    }
  }, [id]);

  const currencyCodeList = useSelector(
    state => state.catalogs.currencyCodeList
  );

  const getCurrencySymbol = currencyCode => {
    const found = currencyCodeList.find(
      current => current.code == currencyCode
    );
    return found ? found.currency : currencyCode;
  };

  const identificationTypeList = useSelector(
    state => state.identificationTypes.identificationTypeList
  );

  const getIdentificationType = identificationType => {
    const found = identificationTypeList.find(
      current => current.id == identificationType
    );
    return found ? found.name : identificationType;
  };
  const prescriptionDetail = useSelector(
    state => state.servicePrescriptions.prescriptionDetail
  );

  return (
    <React.Fragment>
      <PageBody
        title="Order Detail"
        actions={
          <Button
            color="light"
            className="button"
            onClick={() => {
              history.push(`/laboratory-prescription-historical/${accountCode}`);
            }}
          >
            <i className="mdi mdi-arrow-left me-1"></i>
            {t("Back")}
          </Button>
        }
      >
        {/* Render selected service */}
        {prescriptionDetail && (
          <LaboratoryPrescriptionCard
            prescription={prescriptionDetail}
            getCurrencySymbol={getCurrencySymbol}
            getIdentificationType={getIdentificationType}
          />
        )}
      </PageBody>
    </React.Fragment>
  );
};

export default LaboratoryPrescriptionDetailPage;
