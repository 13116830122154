import { ALERT_MESSAGE } from "constants/constants"
import {
  httpGetMembershipAffiliatedDetailList
} from "helpers/Api/api-helper"
import { call, put, takeEvery } from "redux-saga/effects"
import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions"
import {
  getMembershipAffiliatedDetailListSuccess,
  getMembershipAffiliatedDetailListFail
} from "./actions"
import { 
  GET_MEMBERSHIP_AFFILIATED_DETAIL_LIST
 } from "./actionTypes"

function* getMembershipAffiliatedDetailList(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetMembershipAffiliatedDetailList, request.payload)
    yield put(hideLoadingAlert())
    yield put(getMembershipAffiliatedDetailListSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getMembershipAffiliatedDetailListFail(error))
  }
}

function* membershipAffiliatedDetailSaga() {
    yield takeEvery(GET_MEMBERSHIP_AFFILIATED_DETAIL_LIST, getMembershipAffiliatedDetailList)
  }
  
  export default membershipAffiliatedDetailSaga;