/* TERMS */
export const CLEAR_TERMS_STATE = "CLEAR_TERMS_STATE" 

export const GET_TERMS_BY_TYPE = "GET_TERMS_BY_TYPE"
export const GET_TERMS_BY_TYPE_FAIL = "GET_TERMS_BY_TYPE_FAIL"
export const GET_TERMS_BY_TYPE_SUCCESS = "GET_TERMS_BY_TYPE_SUCCESS"

export const GET_TERM_LIST = "GET_TERM_LIST"
export const GET_TERM_LIST_FAIL = "GET_TERM_LIST_FAIL"
export const GET_TERM_LIST_SUCCESS = "GET_TERM_LIST_SUCCESS"

export const GET_TERM_DETAIL = "GET_TERM_DETAIL"
export const GET_TERM_DETAIL_FAIL = "GET_TERM_DETAIL_FAIL"
export const GET_TERM_DETAIL_SUCCESS = "GET_TERM_DETAIL_SUCCESS"

export const ADD_TERM = "ADD_TERM"
export const ADD_TERM_FAIL = "ADD_TERM_FAIL"
export const ADD_TERM_SUCCESS = "ADD_TERM_SUCCESS"

export const UPDATE_TERM = "UPDATE_TERM"
export const UPDATE_TERM_FAIL = "UPDATE_TERM_FAIL"
export const UPDATE_TERM_SUCCESS = "UPDATE_TERM_SUCCESS"

export const DELETE_TERM = "DELETE_TERM"
export const DELETE_TERM_FAIL = "DELETE_TERM_FAIL"
export const DELETE_TERM_SUCCESS = "DELETE_TERM_SUCCESS"