import { call, put, takeEvery } from "redux-saga/effects";

import {
    GET_CATALOGS_LIST
} from "./actionTypes";

import {
    getCatalogsSuccess,
    getCatalogsFail
} from "./actions";

import {
    httpGetCatalogs
} from "../../helpers/Api/api-helper";

import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions"
import { ALERT_MESSAGE } from "constants/constants";

function* fetchCatalogList() {
    try {
      yield put(showLoadingAlert())
      const response = yield call(httpGetCatalogs)
      yield put(hideLoadingAlert())
      yield put(getCatalogsSuccess(response))
    } catch (error) {
      yield put(hideLoadingAlert())
      yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
      yield put(getCatalogsFail(error))
    }
}

function* catalogSaga() {
    yield takeEvery(GET_CATALOGS_LIST, fetchCatalogList)
}

export default catalogSaga;