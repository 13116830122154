/* TERMS */
export const CLEAR_SERVICE_PRESCRIPTION_STATE = "CLEAR_SERVICE_PRESCRIPTION_STATE";

export const GET_SERVICE_PRESCRIPTION_TEMPLATE_LIST = "GET_SERVICE_PRESCRIPTION_TEMPLATE_LIST";
export const GET_SERVICE_PRESCRIPTION_TEMPLATE_LIST_FAIL =
  "GET_SERVICE_PRESCRIPTION_TEMPLATE_LIST_FAIL";
export const GET_SERVICE_PRESCRIPTION_TEMPLATE_LIST_SUCCESS =
  "GET_SERVICE_PRESCRIPTION_TEMPLATE_LIST_SUCCESS";

export const GET_SERVICE_PRESCRIPTION_LIST = "GET_SERVICE_PRESCRIPTION_LIST";
export const GET_SERVICE_PRESCRIPTION_LIST_FAIL = "GET_SERVICE_PRESCRIPTION_LIST_FAIL";
export const GET_SERVICE_PRESCRIPTION_LIST_SUCCESS = "GET_SERVICE_PRESCRIPTION_LIST_SUCCESS";

export const GET_SERVICE_PRESCRIPTION_DETAIL = "GET_SERVICE_PRESCRIPTION_DETAIL";
export const GET_SERVICE_PRESCRIPTION_DETAIL_FAIL = "GET_SERVICE_PRESCRIPTION_DETAIL_FAIL";
export const GET_SERVICE_PRESCRIPTION_DETAIL_SUCCESS =
  "GET_SERVICE_PRESCRIPTION_DETAIL_SUCCESS";

export const ADD_SERVICE_PRESCRIPTION = "ADD_SERVICE_PRESCRIPTION";
export const ADD_SERVICE_PRESCRIPTION_FAIL = "ADD_SERVICE_PRESCRIPTION_FAIL";
export const ADD_SERVICE_PRESCRIPTION_SUCCESS = "ADD_SERVICE_PRESCRIPTION_SUCCESS";

export const UPDATE_SERVICE_PRESCRIPTION = "UPDATE_SERVICE_PRESCRIPTION";
export const UPDATE_SERVICE_PRESCRIPTION_FAIL = "UPDATE_SERVICE_PRESCRIPTION_FAIL";
export const UPDATE_SERVICE_PRESCRIPTION_SUCCESS = "UPDATE_SERVICE_PRESCRIPTION_SUCCESS";

export const UPLOAD_PDF_SERVICE_PRESCRIPTION = "UPLOAD_PDF_SERVICE_PRESCRIPTION";
export const UPLOAD_PDF_SERVICE_PRESCRIPTION_FAIL = "UPLOAD_PDF_SERVICE_PRESCRIPTION_FAIL";
export const UPLOAD_PDF_SERVICE_PRESCRIPTION_SUCCESS = "UPLOAD_PDF_SERVICE_PRESCRIPTION_SUCCESS";

export const DELETE_SERVICE_PRESCRIPTION = "DELETE_SERVICE_PRESCRIPTION";
export const DELETE_SERVICE_PRESCRIPTION_FAIL = "DELETE_SERVICE_PRESCRIPTION_FAIL";
export const DELETE_SERVICE_PRESCRIPTION_SUCCESS = "DELETE_SERVICE_PRESCRIPTION_SUCCESS";

