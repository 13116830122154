import React from 'react';
import PropTypes from "prop-types";
import { useSelector } from 'react-redux';
import MedicalServiceCard from './medical-service-card';
import { Row } from 'reactstrap';

const MedicalServiceList = ({onSelect}) => {

    const medicalServiceList = useSelector(state => state.medicalServices.medicalServiceList);

    return ( 
        <React.Fragment>
            <Row style={{
                maxWidth: '100%',
                maxHeight: '220px',
                overflowY: 'scroll'
            }}>
                {medicalServiceList.map(service => (
                    <MedicalServiceCard 
                        key={service.id} 
                        service={service} 
                        onSelect={onSelect}
                    />
                ))}
            </Row>
        </React.Fragment>
     );
}
 
MedicalServiceList.propTypes = {
    onSelect: PropTypes.func
};

export default MedicalServiceList;