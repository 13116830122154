import PageBody from 'components/Common/PageLayout/page-body';
import { ALERT_MESSAGE } from 'constants/constants';
import React, { useEffect, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button } from 'reactstrap';
import { getCatalogs, showSuccessAlert } from 'store/actions';
import { getAffiliatedBeneficiaries, deleteAffiliatedBeneficiary, clearAffiliatedBeneficiaries } from 'store/AffiliatedBeneficiary/actions';
import AffiliatedBeneficiaries from './affiliated-beneficiaries';

const AffiliatedMembershipBeneficiariesPage = () => {

    const { membershipAffiliatedId, account } = useParams();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const history = useHistory();
    const [showDelete, setShowDelete] = useState(false);
    const [beneficiary, setBeneficiary] = useState(null);
    const beneficiaries = useSelector(state => state.affiliatedBeneficiaries.beneficiaries);
    const beneficiaryDeleted = useSelector(state => state.affiliatedBeneficiaries.beneficiaryDeleted);

    const results = beneficiaries.map(current => ({...current, fullName: [current.firstName, current.secondName, current.firstSurname, current.secondSurname].join(' ')})); 

    useEffect(() => {
        dispatch(getCatalogs());
        dispatch(getAffiliatedBeneficiaries({ id: membershipAffiliatedId }));
    }, []);

    useEffect(() => {
        if (beneficiaryDeleted) {
            dispatch(showSuccessAlert(ALERT_MESSAGE.DELETED, () => {
                dispatch(clearAffiliatedBeneficiaries());
                dispatch(getAffiliatedBeneficiaries({ id: membershipAffiliatedId }));
            }));
        }

    }, [beneficiaryDeleted]);

    const deleteRow = row => {
        setBeneficiary(row);
        setShowDelete(true);
    };

    const onDelete = () => {
        setShowDelete(false);
        dispatch(deleteAffiliatedBeneficiary(beneficiary));
    }

    return (
        <React.Fragment>
            {showDelete ?

                <SweetAlert
                    title={t('confirm.delete')}
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText={t('Yes')}
                    cancelBtnText={t('No')}
                    onConfirm={() => {
                        onDelete();
                    }}
                    onCancel={() => {
                        setShowDelete(false);
                    }}
                >

                </SweetAlert>
                : null}

            <PageBody
                title='Beneficiaries List'
                actions={(
                    <React.Fragment>
                        <Button 
                            color="primary" 
                            className="button" 
                            onClick={() => { history.push(`/membership-beneficiary-new/${account}/${membershipAffiliatedId}`) }}
                        >
                            <i className="mdi mdi-plus-circle-outline me-1"></i>{t('New Beneficiary')}
                        </Button>
                        <Button 
                            color="light" 
                            className="button mr-1" 
                            onClick={() => { history.push(`/membership-customer-detail/${account}/${membershipAffiliatedId}`) }}
                        >
                            <i className="mdi mdi-arrow-left me-1"></i>{t('Back')}
                        </Button>
                    </React.Fragment>
                )}
            >
                <AffiliatedBeneficiaries 
                    beneficiaries={results}
                    deleteRow={deleteRow}
                />
            </PageBody>
        </React.Fragment>
    );
}

export default AffiliatedMembershipBeneficiariesPage;