import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getCatalogs } from "store/actions";
import { Button, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import PrescriptionProductCard from "components/Common/Prescription/prescription-product-card";
import SweetAlert from "react-bootstrap-sweetalert";
import SearchProductForm from "../searchbox/product/search-product-form";

const PrescriptionProducts = ({ products, setProducts }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getCatalogs());
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [showProductAdded, setShowProductAdded] = useState(false);

  const currencyCodeList = useSelector(
    state => state.catalogs.currencyCodeList
  );

  const getCurrencySymbol = currencyCode => {
    const found = currencyCodeList.find(
      current => current.code == currencyCode
    );
    return found ? found.currency : currencyCode;
  };

  const onDelete = productDetail => {
    setProducts(
      products.filter(current => current.product.id != productDetail.product.id)
    );
  };

  const onUpdate = productDetail => {
    const productIndex = products.findIndex(
      product => product.product.id == productDetail.product.id
    );
    if (productIndex >= 0) {
      const copy = [...products];
      copy[productIndex] = {
        ...copy[productIndex],
        productId: productDetail.product.id,
        quantity: productDetail.quantity,
        doctorPrescription: productDetail.doctorPrescription,
      };
      setProducts(copy);
    }
  };

  const onUpdateQuantity = (productDetail, quantity) => {
  
    const productIndex = products.findIndex(
      product => product.product.id == productDetail.product.id
    );
    if (productIndex >= 0) {
      const copy = [...products];
      copy[productIndex] = {
        ...copy[productIndex],
        quantity: copy[productIndex].quantity + quantity,
      };
      setProducts(copy);
    }
  };

  const onAdd = productDetail => {
    const productIndex = products.findIndex(
      product => product.product.id == productDetail.product.id
    );
    if (productIndex >= 0) {
      const copy = [...products];
      copy[productIndex] = {
        ...copy[productIndex],
        quantity: copy[productIndex].quantity + productDetail.quantity,
      };
      setProducts(copy);
    } else {
      setProducts([...products, { ...productDetail, productId: productDetail.product.id }]);
    }

    setShowProductAdded(true);
  };


  return (
    <React.Fragment>
      {products.length == 0 ? (
        <Row>
          <Col>
            <h5>{t("Without Products")}</h5>
          </Col>
        </Row>
      ) : null}
      {products.map((productDetail, index) => (
        productDetail.product &&
        <PrescriptionProductCard
          key={index}
          productDetail={productDetail}
          onDelete={onDelete}
          onUpdate={onUpdate}
          onUpdateQuantity={onUpdateQuantity}
          getCurrencySymbol={getCurrencySymbol}
        />

      ))}
      <Row className="mt-1">
        <Col>
          <div className="button-items">
            <Button
              type="button"
              color="primary"
              onClick={() => {
                setShowModal(true);
              }}
            >
              <i className="mdi mdi-plus-circle-outline" /> {t("Add Product")}
            </Button>

          </div>
        </Col>
      </Row>
      {showModal ? (
        <SearchProductForm
          isOpen={showModal}
          addProduct={onAdd}
          getCurrencySymbol={getCurrencySymbol}
          onCancel={() => {
            setShowModal(false);
          }}
        />
      ) : null}

      {showProductAdded ?

        <SweetAlert
          title={t('The product was added correctly')}
          success
          confirmBtnBsStyle="success"
          confirmBtnText={t('Accept')}
          onConfirm={() => {
            setShowProductAdded(false);
          }}
        >

        </SweetAlert>
        : null}
    </React.Fragment>
  );
};

PrescriptionProducts.propTypes = {
  products: PropTypes.array.isRequired,
  setProducts: PropTypes.func.isRequired,
};

export default PrescriptionProducts;
