import PageBody from 'components/Common/PageLayout/page-body';
import { ALERT_MESSAGE } from 'constants/constants';
import { toDateStringFormat } from 'helpers/utils';
import AffiliatedMembershipBenefits from 'pages/Management/Commons/membership-card/affiliated-membership-benefits';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Card, CardBody, CardTitle, Col, Row, Table } from 'reactstrap';
import { clearMembershipAffiliatedBenefit, getAffiliated, getAffiliatedMembershipDetail, getCatalogs, getMembershipAffiliatedBenefitList, getMembershipAffiliatedDetailList, showSuccessAlert } from 'store/actions';
import CustomerProfile from '../CustomerProfile/customer-profile';


const AffiliatedMembershipDetailPage = () => {

  const { account, membershipAffiliatedId } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();

  const customerProfile = useSelector(state => state.affiliateds.affiliated);
  const membershipDetail = useSelector(state => state.affiliateds.affiliatedMembershipDetail);
  const benefits = useSelector(state => state.membershipAffiliatedDetails.benefitList);
  const currencyCodeList = useSelector(state => state.catalogs.currencyCodeList);
  const membershipAffiliatedBenefitList = useSelector(state => state.membershipAffiliatedBenefits.benefitList);
  const benefitApplied = useSelector(state => state.membershipAffiliatedBenefits.created);

  useEffect(() => {
    dispatch(clearMembershipAffiliatedBenefit());
    dispatch(getCatalogs());
  }, []);

  useEffect(() => {
    if (account) {
      dispatch(getAffiliated(account));
      dispatch(getMembershipAffiliatedBenefitList({id: membershipAffiliatedId}));
      dispatch(getMembershipAffiliatedDetailList({id: membershipAffiliatedId}));
      dispatch(getAffiliatedMembershipDetail(membershipAffiliatedId));
    }
  }, [account]);

  useEffect(() => {
    if (benefitApplied) {
      dispatch(
        showSuccessAlert(ALERT_MESSAGE.BENEFIT_APPLIED, () => {
          dispatch(getMembershipAffiliatedBenefitList({id: membershipAffiliatedId}));
          dispatch(getMembershipAffiliatedDetailList({id: membershipAffiliatedId}));
        })
      );
    }
  }, [benefitApplied]);

  const getCurrencySymbol = (currencyCode) => {
    const found = currencyCodeList.find(current => current.code == currencyCode);
    return found ? found.currency : currencyCode;
  }

  return (
    <React.Fragment>
      <PageBody
        title='Membership Detail'
        actions={(
          <React.Fragment>
            <Button color="primary" className="button mr-1" type="button"
              onClick={() => { history.push(`/membership-customer-beneficiaries/${account}/${membershipDetail.id}`) }}>
              <i className="mdi mdi-arrow-right me-1"></i>{t('View Beneficiaries')}
            </Button>

            {membershipDetail && membershipDetail.membership && !membershipDetail.membership.defaultRegistration ? (
            <Button color="danger" className="button mr-1" type="button"
                onClick={() => { 
                    history.push(`/membership-customer-cancel/${account}/${membershipDetail.membership.id}/${membershipDetail.id}`) 
                  }}>
              <i className="mdi mdi-close-thick me-1"></i>{t('Cancel Membership')}
            </Button>
            ) : null}

            <Button color="light" className="button mr-1"
              onClick={() => { history.push(`/membership-customer-profile/${account}`) }}>
              <i className="mdi mdi-arrow-left me-1"></i>{t('Back')}
            </Button>
          </React.Fragment>
        )}
      >
        <Row>
          <Col xl="4">
            {customerProfile ? <CustomerProfile customerProfile={customerProfile} hideAvatar={true} /> : null}
          </Col>

          <Col xl="8">
            <Card>
              <CardBody>
                <CardTitle className="mb-4 h4">{t('Membership')}</CardTitle>

                <div className="table-responsive">
                  <Table className="table mb-0">
                    <tbody>
                      {membershipDetail ?

                        <tr key={"membership"}>
                          <td><img src={membershipDetail.membership.picture} alt="" className="img-fluid" /></td>
                          <td colSpan={4}>
                            <strong>{t('Name')}</strong>
                            <p>{membershipDetail.membership.name}</p>
                            <strong>{t('Beneficiaries')}</strong>
                            <p>{membershipDetail.membership.maxBeneficiaryQuantity}</p>
                            <strong>{t('Price')}</strong>
                            <p>{getCurrencySymbol(membershipDetail.membership.currencyCode) + ' ' + membershipDetail.membership.price}</p>
                            <strong>{t('Minimum Validity Period')}</strong>
                            <p>{membershipDetail.membership.validityMonths} {t('months')}</p>
                            <strong>{t('Membership Date')}</strong>
                            <p>{toDateStringFormat(membershipDetail.activationDate)}</p>

                          </td>
                        </tr>
                        : null}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Card>


          </Col>
        </Row>

        {membershipDetail && benefits && membershipAffiliatedBenefitList ?
          <Card key={"currentMembership"}>
            <CardBody>
              <CardTitle className="h4">{t('Benefits')}</CardTitle>
              <Row>
                <Col xl="12">
                  <AffiliatedMembershipBenefits 
                    benefits={benefits} 
                    membershipAffiliatedId={parseInt(membershipAffiliatedId, 10)}
                    membershipAffiliatedBenefitList={membershipAffiliatedBenefitList}
                  />
                </Col>
              </Row>
            </CardBody>
          </Card>
          : null}
      </PageBody>
    </React.Fragment>
  );
}

export default AffiliatedMembershipDetailPage;