import DataTable from 'components/Common/DataTable/datatable';
import { toDateStringFormat } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';

const SampleCollectList = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const sampleCollectList = useSelector(state => state.sampleCollect.sampleCollectList);
    const [result, setResult] = useState([]);

    useEffect(() => {

        if(sampleCollectList) {
            setResult(sampleCollectList.map(current => ({
                id: current.id,
                pickupDate: toDateStringFormat(current.appointmentSamples[0].pickupDate),
                patientFullName: current.appointmentSamples[0].pickupPersonFullname,
                pickupType: current.appointmentSamples[0].pickupType,
                status: current.appointmentSamples[0].pickupStatus
            })));
        }
    }, [sampleCollectList]);

    const getPickupType = pickupType => {
        switch (pickupType) {
          case "SUB":
            return "Pickup Site";
          case "HOM":
            return "Pickup Home";
          default:
            return pickupType;
        }
      };

      const getPickupStatus = pickupStatus => {
        switch (pickupStatus) {
          case "PEN":
            return "Pending";
          case "DON":
            return "Done";
          case "FIN":
            return "Done";
          case "CAN":
            return "Cancelled";
          default:
            return pickupStatus;
        }
      };
    
    const columns = [   
        {
            text: t('Date'),
            dataField: 'pickupDate',
            sort: true,
            formatter: (cellContent, row) => <React.Fragment>{row.pickupDate}</React.Fragment>
        },
        {
            text: t('Patient'),
            dataField: 'patientFullName',
            sort: true,
            formatter: (cellContent, row) => {
                return (
                    <React.Fragment>{row.patientFullName}</React.Fragment>
                );
            }
        },
        {
            text: t('Pickup Type'),
            dataField: 'pickupType',
            sort: true,
            formatter: (cellContent, row) => {
                return (
                    <React.Fragment>{t(getPickupType(row.pickupType))}</React.Fragment>
                );
            }
        },
        {
            text: t('Status'),
            dataField: 'status',
            sort: true,
            formatter: (cellContent, row) => {
                return (
                    <React.Fragment>{t(getPickupStatus(row.status))}</React.Fragment>
                );
            }
        },
        {
            dataField: 'menu',
            isDummyField: true,
            editable: false,
            text: '',
            formatter: (cellContent, row) => (
                <div className="d-flex gap-3">
                    <Button type='button' color="success" onClick={() => viewDetail(row)}>
                        {t('View Detail')}
                        <i className="mdi mdi-arrow-riht" />
                    </Button>
                </div>
            ),
        }
    ];

    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        }
    ];

    const viewDetail = appointment => {
        history.push(`/sample-collect-detail/${appointment.id}`);
    }
    return (
        <React.Fragment>
            {/* factura, fecha, paciente */}
            <Row>
                <Col lg={12}>
                    <DataTable
                        data={result}
                        keyField="id"
                        columns={columns}
                        sort={defaultSorted}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default SampleCollectList;