import {
  CLEAR_LABORATORY_ORDER_HISTORICAL,
  GET_LABORATORY_ORDER_DETAIL,
  GET_LABORATORY_ORDER_DETAIL_FAIL,
  GET_LABORATORY_ORDER_DETAIL_SUCCESS,
  GET_LABORATORY_ORDER_HISTORICAL_LIST,
  GET_LABORATORY_ORDER_HISTORICAL_LIST_FAIL,
  GET_LABORATORY_ORDER_HISTORICAL_LIST_SUCCESS,
  SEND_LABORATORY_EXAM_RESULT,
  SEND_LABORATORY_EXAM_RESULT_FAIL,
  SEND_LABORATORY_EXAM_RESULT_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  orderHistoricalList: [],
  orderDetail: null,
  error: {},
  loading: false,
  laboratoryResultNotificationSent: false,
}

const laboratoryOrders = (state = INIT_STATE, action) => {
  switch (action.type) {
    case CLEAR_LABORATORY_ORDER_HISTORICAL:
      return {
        orderHistoricalList: [],
        orderDetail: null,
        error: {},
        loading: false,
        laboratoryResultNotificationSent: false,
      }
    case GET_LABORATORY_ORDER_HISTORICAL_LIST:
      return {
        ...state,
        orderHistoricalList: [],
        loading: true,
      }
    case GET_LABORATORY_ORDER_HISTORICAL_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        orderHistoricalList: action.payload,
      }
    case GET_LABORATORY_ORDER_HISTORICAL_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case GET_LABORATORY_ORDER_DETAIL:
      return {
        ...state,
        orderDetail: null,
        loading: true,
      }
    case GET_LABORATORY_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        loading: false,
        orderDetail: action.payload,
      }
    case GET_LABORATORY_ORDER_DETAIL_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    case SEND_LABORATORY_EXAM_RESULT:
      return {
        ...state,
        success: false,
        loading: true,
        laboratoryResultNotificationSent: false
      }
    case SEND_LABORATORY_EXAM_RESULT_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        laboratoryResultNotificationSent: action.payload,
      }
    case SEND_LABORATORY_EXAM_RESULT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default laboratoryOrders;
