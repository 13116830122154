import { ADD_AFFILIATED_BENEFICIARY, ADD_AFFILIATED_BENEFICIARY_FAIL, ADD_AFFILIATED_BENEFICIARY_SUCCESS, CLEAR_AFFILIATED_BENEFICIARIES, DELETE_AFFILIATED_BENEFICIARY, DELETE_AFFILIATED_BENEFICIARY_FAIL, DELETE_AFFILIATED_BENEFICIARY_SUCCESS, GET_AFFILIATED_BENEFICIARIES, GET_AFFILIATED_BENEFICIARIES_FAIL, GET_AFFILIATED_BENEFICIARIES_SUCCESS, GET_AFFILIATED_BENEFICIARY, GET_AFFILIATED_BENEFICIARY_FAIL, GET_AFFILIATED_BENEFICIARY_SUCCESS, UPDATE_AFFILIATED_BENEFICIARY, UPDATE_AFFILIATED_BENEFICIARY_FAIL, UPDATE_AFFILIATED_BENEFICIARY_SUCCESS } from "./actionTypes"

export const clearAffiliatedBeneficiaries = () => ({
    type: CLEAR_AFFILIATED_BENEFICIARIES
})

//Affiliated Beneficiaries
export const getAffiliatedBeneficiaries = request => ({
    type: GET_AFFILIATED_BENEFICIARIES,
    request
})

export const getAffiliatedBeneficiariesSuccess = response => ({
    type: GET_AFFILIATED_BENEFICIARIES_SUCCESS,
    payload: response,
})

export const getAffiliatedBeneficiariesFail = error => ({
    type: GET_AFFILIATED_BENEFICIARIES_FAIL,
    payload: error,
})

//Affiliated Beneficiary
export const getAffiliatedBeneficiary = request => ({
    type: GET_AFFILIATED_BENEFICIARY,
    request
})

export const getAffiliatedBeneficiarySuccess = response => ({
    type: GET_AFFILIATED_BENEFICIARY_SUCCESS,
    payload: response,
})

export const getAffiliatedBeneficiaryFail = error => ({
    type: GET_AFFILIATED_BENEFICIARY_FAIL,
    payload: error,
})

//Add Affiliated Beneficiary
export const addAffiliatedBeneficiary = request => ({
    type: ADD_AFFILIATED_BENEFICIARY,
    request
})

export const addAffiliatedBeneficiarySuccess = response => ({
    type: ADD_AFFILIATED_BENEFICIARY_SUCCESS,
    payload: response,
})

export const addAffiliatedBeneficiaryFail = error => ({
    type: ADD_AFFILIATED_BENEFICIARY_FAIL,
    payload: error,
})

//Update Affiliated Beneficiary
export const updateAffiliatedBeneficiary = request => ({
    type: UPDATE_AFFILIATED_BENEFICIARY,
    request
})

export const updateAffiliatedBeneficiarySuccess = response => ({
    type: UPDATE_AFFILIATED_BENEFICIARY_SUCCESS,
    payload: response,
})

export const updateAffiliatedBeneficiaryFail = error => ({
    type: UPDATE_AFFILIATED_BENEFICIARY_FAIL,
    payload: error,
})

//Delete Affiliated Beneficiary
export const deleteAffiliatedBeneficiary = request => ({
    type: DELETE_AFFILIATED_BENEFICIARY,
    request
})

export const deleteAffiliatedBeneficiarySuccess = response => ({
    type: DELETE_AFFILIATED_BENEFICIARY_SUCCESS,
    payload: response,
})

export const deleteupdateAffiliatedBeneficiaryFail = error => ({
    type: DELETE_AFFILIATED_BENEFICIARY_FAIL,
    payload: error,
})