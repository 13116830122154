import { ADD_ROLE, ADD_ROLE_FAIL, ADD_ROLE_SUCCESS, CLEAR_ROLES_STATE,
         DELETE_ROLE,
         DELETE_ROLE_FAIL,
         DELETE_ROLE_SUCCESS,
         GET_ROLE_DETAIL,
         GET_ROLE_DETAIL_FAIL,
         GET_ROLE_DETAIL_SUCCESS,
         GET_ROLE_LIST, 
         GET_ROLE_LIST_FAIL, 
         GET_ROLE_LIST_SUCCESS, 
         UPDATE_ROLE,
         UPDATE_ROLE_FAIL,
         UPDATE_ROLE_SUCCESS,
         GET_REALM_LIST,
         GET_REALM_LIST_SUCCESS,
         GET_REALM_LIST_FAIL,
         GET_RESOURCE_LIST,
         GET_RESOURCE_LIST_SUCCESS,
         GET_RESOURCE_LIST_FAIL,
         GET_ROLE_RESOURCE_LIST,
         GET_ROLE_RESOURCE_LIST_SUCCESS,
         GET_ROLE_RESOURCE_LIST_FAIL,
         SAVE_ROLE_RESOURCE_LIST,
         SAVE_ROLE_RESOURCE_LIST_SUCCESS,
         SAVE_ROLE_RESOURCE_LIST_FAIL
        } from "./actionTypes"

const INIT_STATE = {
    roles: [],
    realms: [],
    resources: [],
    roleResources: [],
    roleDetail: null,
    created: false,
    updated: false,
    deleted: false,
    success: false,
    loading: false,
    roleResourceCreated: false,
    error: {}
  }
  
  const roles = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CLEAR_ROLES_STATE:
        return {
            ...state,
            roles: [],
            realms: [],
            resources: [],
            roleResources: [],
            roleDetail: null,
            created: false,
            updated: false,
            deleted: false,
            success: false,
            loading: false,
            roleResourceCreated: false,
            error: {}
        }
        
        case GET_ROLE_LIST:
        return {
            ...state,
            roles: [],
            loading: true,
        } 
        case GET_ROLE_LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            roles: action.payload,
        }  
        case GET_ROLE_LIST_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case GET_ROLE_DETAIL:
        return {
            ...state,
            roleDetail: null,
            loading: true,
        } 
        case GET_ROLE_DETAIL_SUCCESS:
        return {
            ...state,
            loading: false,
            roleDetail: action.payload,
        }  
        case GET_ROLE_DETAIL_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case ADD_ROLE:
        return {
            ...state,
            created: false,
            loading: true,
        } 
        case ADD_ROLE_SUCCESS:
        return {
            ...state,
            loading: false,
            created: true
        }  
        case ADD_ROLE_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case UPDATE_ROLE:
        return {
            ...state,
            updated: false,
            loading: true,
        } 
        case UPDATE_ROLE_SUCCESS:
        return {
            ...state,
            loading: false,
            updated: true
        }  
        case UPDATE_ROLE_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case DELETE_ROLE:
        return {
            ...state,
            deleted: false,
            loading: true,
        } 
        case DELETE_ROLE_SUCCESS:
        return {
            ...state,
            loading: false,
            deleted: true
        }  
        case DELETE_ROLE_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case GET_REALM_LIST:
        return {
            ...state,
            realms: [],
            loading: true,
        } 
        case GET_REALM_LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            realms: action.payload,
        }  
        case GET_REALM_LIST_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case GET_RESOURCE_LIST:
        return {
            ...state,
            resources: [],
            loading: true,
        } 
        case GET_RESOURCE_LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            resources: action.payload,
        }  
        case GET_RESOURCE_LIST_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case GET_ROLE_RESOURCE_LIST:
        return {
            ...state,
            roleResources: [],
            loading: true,
        } 
        case GET_ROLE_RESOURCE_LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            roleResources: action.payload,
        }  
        case GET_ROLE_RESOURCE_LIST_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case SAVE_ROLE_RESOURCE_LIST:
        return {
            ...state,
            roleResourceCreated: false,
            loading: true,
        } 
        case SAVE_ROLE_RESOURCE_LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            roleResourceCreated: true,
        }  
        case SAVE_ROLE_RESOURCE_LIST_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        default:
            return state
        }
    }
    
export default roles
      