import React, { useState } from 'react';
import PropTypes from 'prop-types'
import { Row, Col, Button, Modal, Label } from 'reactstrap';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormField from 'pages/Utility/form-field';
import { useTranslation } from 'react-i18next';
import InputMask from "react-input-mask"
import valid from "card-validator";

const CancelMembershipPaymentMethodForm = ({isOpen, onSubmit, onCancel, paymentMethod}) => {

    const { t } = useTranslation();

    const [cardType, setCardType] = useState(null);

    const [formData, setFormData] = useState({
        cardNumber: paymentMethod && paymentMethod.cardNumber ? paymentMethod.cardNumber : '',
        holderName: paymentMethod && paymentMethod.holderName ? paymentMethod.holderName : '',
        expirationMonth: paymentMethod && paymentMethod.expirationMonth ? paymentMethod.expirationMonth : '',
        expirationYear: paymentMethod && paymentMethod.expirationYear ? paymentMethod.expirationYear : '',
        cvvCode: paymentMethod && paymentMethod.cvvCode ? paymentMethod.cvvCode : '',
        brand: paymentMethod && paymentMethod.brand ? paymentMethod.brand : '',
        expirationDate: '',
        isDefault: false
    }); 
    
    const { 
        cardNumber, holderName, cvvCode, brand, expirationDate, isDefault } = formData;

    const handleOnChange = event => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }

    const handleCardNumberOnChange = event => {
        const value = event.target.value.replace(/-/gi, '').replace(/_/gi, '');

        const cardType = valid.number(value);
        setCardType(cardType);
        let brandValue = '';
        if(cardType.isValid && cardType.card.type == 'visa') {
            brandValue = 'VIS';
        } else if(cardType.isValid && cardType.card.type == 'mastercard') {
            brandValue = 'MAS';
        }
        
        setFormData({
            ...formData,
            brand: brandValue,
            [event.target.name]: value
        });
    }

    const handleExpirationDateOnChange = event => {
        let month = '';
        let year = '';
        const value = event.target.value;
        if(value.trim() != '' && value.length >= 2) {
            month = value.split('/')[0];
        }
        if(value.trim() != '' && value.length >= 5) {
            year = value.split('/')[1];
        }

        setFormData({
            ...formData,
            expirationMonth: month,
            expirationYear: year,
            expirationDate: value
        });
    }

    const handleCheckOnChange = event => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.checked
        });
    }

    return ( 
        <React.Fragment>
            <Modal
                isOpen={isOpen}
                toggle={() => {onCancel()}}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {paymentMethod? t('Edit Payment Method') : t('New Payment Method')}
                    </h5>
                    <button
                        type="button"
                        onClick={() => onCancel()}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
             
                <Formik
                    enableReinitialize={true}
                    initialValues={formData}
                    validationSchema={
                        Yup.object().shape({
                            cardNumber: Yup.string().test(
                                "test-number",
                                t("Credit Card number is invalid"),
                                value => valid.number(value).isValid
                            ).max(19).required(),
                            holderName: Yup.string().required(),
                            expirationDate: Yup.string().test(
                                "expiration-date",
                                t("Expiration date is invalid"),
                                value => valid.expirationDate(value).isValid
                            ).min(7).max(7).required(),
                            cvvCode: Yup.string().min(3).max(4).required(),
                            brand: Yup.string().test(
                                "test-brand",
                                t("Credit Card type is invalid, available cards: Visa, Mastercard"),
                                value => !cardType || (cardType && (value == 'VIS' || value == 'MAS'))
                            ),
                            isDefault: Yup.string().required()
                        })
                    }
                    onSubmit={(values, actions) => {
                        actions.setSubmitting(false);
                        onSubmit(formData);
                    }}
                >
                {({ errors, status, touched }) => (
                    <Form className='needs-validation' >
                        <div className="modal-body">

                            <Row>
                                <Col md={12}>
                                    
                                    <FormField
                                        key='holderName'
                                        type='text'
                                        maxLength={255}
                                        fieldName='holderName'
                                        fieldValue={holderName}
                                        label={t('Holder Name')}
                                        errors={errors}
                                        touched={touched}
                                        handleOnChange={handleOnChange}
                                    />

                                </Col>
                                
                            </Row>

                            <Row>
                                <Col md={12}>
                                    
                                    <div className='mb-3'>
                                        <Label className="form-label">{t('Card Number')}</Label>
                                        <InputMask 
                                            type="text"
                                            mask={'9999-9999-9999-9999'}
                                            className={"form-control input-mask" + (errors.cardNumber && touched.cardNumber ? " is-invalid": "")} 
                                            name="cardNumber"
                                            value={cardNumber}
                                            onChange={handleCardNumberOnChange}
                                        />
                                        <ErrorMessage
                                            name="cardNumber"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>

                                    <FormField
                                        key='brand'
                                        type='hidden'
                                        maxLength={255}
                                        fieldName='brand'
                                        fieldValue={brand}
                                        label={t('Brand')}
                                        errors={errors}
                                        touched={touched}
                                        handleOnChange={handleOnChange}
                                    />

                                </Col>

                            </Row>
                            
                            <Row>
                                <Col md={6}>
                                    
                                    <div className='mb-3'>
                                        <Label className="form-label">{t('Expiration Date')}</Label>
                                        <InputMask 
                                            type="text"
                                            mask={'99/9999'}
                                            className={"form-control input-mask" + (errors.expirationDate && touched.expirationDate ? " is-invalid": "")} 
                                            name="expirationMonth"
                                            value={expirationDate}
                                            onChange={handleExpirationDateOnChange}
                                        />
                                        <ErrorMessage
                                            name="expirationDate"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    
                                </Col>
                                <Col md={6}>

                                    <div className='mb-3'>
                                        <Label className="form-label">{t('CVV')}</Label>
                                        <InputMask 
                                            type="text"
                                            mask={cardType && cardType.card ? (cardType.card.code.size == 3 ? '999' : '9999') : '999'}
                                            className={"form-control input-mask" + (errors.cvvCode && touched.cvvCode ? " is-invalid": "")} 
                                            name="cvvCode"
                                            value={cvvCode}
                                            onChange={handleOnChange}
                                        />
                                        <ErrorMessage
                                            name="cvvCode"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <FormField
                                        key='isDefault'
                                        type='checkbox'
                                        fieldName='isDefault'
                                        fieldValue={isDefault}
                                        label={t('Default')}
                                        errors={errors}
                                        touched={touched}
                                        handleOnChange={handleCheckOnChange}
                                    />
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer button-items mt-1">
                            <Button type='submit' color="primary" className="button"><i className="mdi mdi-content-save me-1"></i>{t('Save')}</Button>
                            <Button color="light" className="button mr-1" onClick={() => { onCancel(); }}><i className="mdi mdi-close-thick me-1"></i>{t('Cancel')}</Button>
                        </div>
                    </Form>
                )}
                </Formik>
                      
            </Modal>
        </React.Fragment>
     );
}

CancelMembershipPaymentMethodForm.propTypes = {
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    isOpen: PropTypes.bool,
    paymentMethod: PropTypes.any
}
 
export default CancelMembershipPaymentMethodForm;