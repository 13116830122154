import { ALERT_MESSAGE } from "constants/constants"
import { 
  httpAddMembershipAffiliatedBenefit,
  httpGetMembershipAffiliatedBenefitList
} from "helpers/Api/api-helper"
import { call, put, takeEvery } from "redux-saga/effects"
import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions"
import {
  getMembershipAffiliatedBenefitListSuccess,
  getMembershipAffiliatedBenefitListFail,
  addMembershipAffiliatedBenefitSuccess,
  addMembershipAffiliatedBenefitFail,
} from "./actions"
import { 
  ADD_MEMBERSHIP_AFFILIATED_BENEFIT,
  GET_MEMBERSHIP_AFFILIATED_BENEFIT_LIST
 } from "./actionTypes"



function* getMembershipAffiliatedBenefitList(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetMembershipAffiliatedBenefitList, request.payload)
    yield put(hideLoadingAlert())
    yield put(getMembershipAffiliatedBenefitListSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getMembershipAffiliatedBenefitListFail(error))
  }
}

function* addMembershipAffiliatedBenefit(request) {
  try {
    yield put(showLoadingAlert(ALERT_MESSAGE.SAVING))
    const response = yield call(httpAddMembershipAffiliatedBenefit, request.payload)
    yield put(hideLoadingAlert())
    yield put(addMembershipAffiliatedBenefitSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.ADD_ERROR))
    yield put(addMembershipAffiliatedBenefitFail(error))
  }
}

function* membershipAffiliatedBenefitSaga() {
    yield takeEvery(GET_MEMBERSHIP_AFFILIATED_BENEFIT_LIST, getMembershipAffiliatedBenefitList)
    yield takeEvery(ADD_MEMBERSHIP_AFFILIATED_BENEFIT, addMembershipAffiliatedBenefit)
  }
  
  export default membershipAffiliatedBenefitSaga;