import React, { useCallback } from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import AffiliatedSearch from "components/Common/AffiliatedSearch/affiliated-search";
import PageBody from "components/Common/PageLayout/page-body";

const CustomerSearchPage = () => {

  const { t } = useTranslation();
  const history = useHistory();

  const onSelectAffiliated = useCallback((affiliated) => {
    history.push(`/membership-customer-profile/${affiliated.accountCode}`);
  });

  return (
    <React.Fragment>
      <PageBody
        title="Affiliates"
        actions={(
          <Button
            color="light"
            className="button"
            onClick={() => { history.push('/dashboard') }}>
            <i className="mdi mdi-arrow-left me-1"></i>{t('Back')}
          </Button>
        )}
      >
        <AffiliatedSearch
          onSelectAffiliated={onSelectAffiliated}
        />
      </PageBody>
    </React.Fragment>
  );
}

export default CustomerSearchPage;
