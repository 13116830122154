import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { 
    addPaymentMethod, 
    clearAffiliatedMembershipCancellation, 
    getAccountDetail, 
    getAffiliated, 
    getAffiliatedMembershipCancel, 
    getAffiliatedMembershipDebt, 
    getAffiliatedMembershipDetail, 
    getCatalogs, 
    getIdentificationTypes, 
    invoicePaymentMembershipCancellation,
    invoicePaymentMembershipCancellationClear,
    showSuccessAlert,
    upgradeMembership
} from 'store/actions';
import cancelMembershipContext from './cancelMembershipContext';
import { useHistory, useParams } from 'react-router-dom';

const CancelMembershipState = props => {

    const [payment, setPayment] = useState(null);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const [address, setAddress] = useState(null);
    const [showConfirm, setShowConfirm] = useState(false);

    const dispatch = useDispatch();
    const history = useHistory();
    const { account, membershipId, membershipAffiliatedId } = useParams();

    const customerProfile = useSelector(state => state.affiliateds.affiliated);
    const membershipDetail = useSelector(state => state.affiliateds.affiliatedMembershipDetail);
    const currencyCodeList = useSelector(state => state.catalogs.currencyCodeList);
    const membershipDebt = useSelector(state => state.affiliatedMembershipCancellation.membershipDebt);
    const membershipCancelled = useSelector(state => state.affiliatedMembershipCancellation.membershipCancelled);
    const accountDetail = useSelector(state => state.accounts.accountDetail);
    const invoicePayment = useSelector(state => state.invoicePayments.invoicePayment);

    useEffect(() => {
        dispatch(invoicePaymentMembershipCancellationClear());
        dispatch(clearAffiliatedMembershipCancellation());
        dispatch(getCatalogs());
    }, []);

    useEffect(() => {
        if(invoicePayment || membershipCancelled) {
            dispatch(showSuccessAlert('The membership has been cancelled', () => { 
                dispatch(clearAffiliatedMembershipCancellation());
                history.push(`/membership-customer-profile/${account}`); 
            }));
        }
    }, [invoicePayment, membershipCancelled]);

    useEffect(() => {
        if (account) {
            dispatch(getIdentificationTypes());
            dispatch(getAffiliated(account));
            dispatch(getAccountDetail({ accountCode: account }));
            dispatch(getAffiliatedMembershipDetail(membershipAffiliatedId));
            dispatch(getAffiliatedMembershipDebt({
                accountCode: account,
                membershipId: membershipId,
                membershipAffiliatedId: membershipAffiliatedId
            }));
        }
    }, [account]);

    const addPayment = data => {
        data.accountCode = id;
        data.cardToken = 'XXXX';
        data.lastDigits = data.cardNumber.substring(data.cardNumber.length - 4);
        data.isDefault = data.isDefault ? 'Y' : 'N'
        dispatch(addPaymentMethod(data));
        setShowPaymentForm(false);
        setPayment(null);
    }

    const onConfirm = () => {

        if(membershipDebt.totalPendingAmount > 0) {
        
            const membership = membershipDetail.membership;

            let payload = {
                accountCode: account,
                cancellation: true,
                currencyCode: membership.currencyCode,
                subTotalAmount: membershipDebt.subTotalPendingAmount,
                taxAmount: membershipDebt.totalPendingTaxAmount,
                totalAmount: membershipDebt.totalPendingAmount,
                paymentMethodId: selectedPayment.id,
                billingAddress: {
                    id: address.id,
                    address: address.address,
                    billingIdentificationTypeId: address.billingIdentificationTypeId,
                    billingIdentification: address.billingIdentification,
                    billingMainEmail: address.billingMainEmail,
                    billingSecondaryEmail: address.billingSecondaryEmail,
                    billingPhone: address.billingPhone
                },
                membership: {
                    id: membership.id,
                    membershipAffiliatedId: membershipDetail.affiliated.id
                }
            };
            
            dispatch(upgradeMembership(payload));

        } else {

            dispatch(getAffiliatedMembershipCancel({
                accountCode: account,
                membershipId: membershipId,
                membershipAffiliatedId: membershipAffiliatedId
            }));

        }
        
    }

    const getCurrencySymbol = (currencyCode) => {
        const found = currencyCodeList.find(current => current.code == currencyCode);
        return found ? found.currency : currencyCode;
    }

    return (
        <cancelMembershipContext.Provider
            value={{
                accountDetail: accountDetail,
                getCurrencySymbol,
                membershipDebt: membershipDebt,
                customerProfile: customerProfile,
                membershipDetail: membershipDetail,
                payment,
                setPayment,
                selectedPayment,
                setSelectedPayment,
                showForm,
                setShowForm,
                showPaymentForm,
                setShowPaymentForm,
                addPayment,
                address,
                setAddress,
                showConfirm,
                setShowConfirm,
                onConfirm
            }}
        >
            {props.children}
        </cancelMembershipContext.Provider>
    );
}

CancelMembershipState.propTypes = {
    children: PropTypes.any
}

export default CancelMembershipState;
