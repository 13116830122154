import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_MEDICAL_CENTER_LIST
} from "./actionTypes"

import {
  getMedicalCenterListSuccess,
  getMedicalCenterListFail
} from "./actions"

//API Calls helper
import {
  httpGetMedicalCenters
} from "../../helpers/Api/api-helper"

import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions"
import { ALERT_MESSAGE } from "constants/constants"

function* fetchMedicalCenters(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(httpGetMedicalCenters)
    yield put(getMedicalCenterListSuccess(response))
    yield put(hideLoadingAlert())
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getMedicalCenterListFail(error))
  }
}

function* medicalCentersSaga() {
  yield takeEvery(GET_MEDICAL_CENTER_LIST, fetchMedicalCenters)
}

export default medicalCentersSaga
