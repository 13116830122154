import { CLEAR_AFFILIATED_MEMBERSHIP_CANCELLATION, GET_AFFILIATED_MEMBERSHIP_CANCEL, GET_AFFILIATED_MEMBERSHIP_CANCEL_FAIL, GET_AFFILIATED_MEMBERSHIP_CANCEL_SUCCESS, GET_AFFILIATED_MEMBERSHIP_DEBT, GET_AFFILIATED_MEMBERSHIP_DEBT_FAIL, GET_AFFILIATED_MEMBERSHIP_DEBT_SUCCESS } from "./actionTypes"

const INIT_STATE = {
    membershipDebt: null,
    membershipCancelled: false,
    error: {},
    loading: false
}

const affiliatedMembershipCancellation = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CLEAR_AFFILIATED_MEMBERSHIP_CANCELLATION:
            return {
                ...state,
                membershipDebt: null,
                error: {},
                loading: false,
                membershipCancelled: false
            }
        case GET_AFFILIATED_MEMBERSHIP_DEBT:
            return {
                ...state,
                membershipDebt: null,
                loading: true,
                error: {}
            }
        case GET_AFFILIATED_MEMBERSHIP_DEBT_SUCCESS:
            return {
                ...state,
                membershipDebt: action.payload,
                loading: false
            }  
        case GET_AFFILIATED_MEMBERSHIP_DEBT_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case GET_AFFILIATED_MEMBERSHIP_CANCEL:
            return {
                ...state,
                membershipCancelled: false,
                loading: true,
                error: {}
            }
        case GET_AFFILIATED_MEMBERSHIP_CANCEL_SUCCESS:
            return {
                ...state,
                membershipCancelled: action.payload,
                loading: false
            }  
        case GET_AFFILIATED_MEMBERSHIP_CANCEL_FAIL:
            return {
                ...state,
                error: action.payload,
                loading: false
            }    
        default:
            return state
    }
  }
  
export default affiliatedMembershipCancellation;