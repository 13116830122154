import React, { useContext, useEffect, useState } from 'react';
import { Card, CardBody, Col, NavLink, NavItem, Row, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames";
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CancelMembershipPaymentMethod from './stapes/cancel-membership-payment-method';
import cancelMembershipContext from './context/cancelMembershipContext';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch } from 'react-redux';
import { showWarningAlert } from 'store/actions';
import CancelMembershipDebit from './stapes/cancel-membership-debit';
import CancelMembershipConfirmation from './stapes/cancel-membership-confirmation';


const AffiliatedMembershipCancelWizard = () => {

    const [activeTab, setActiveTab] = useState(1);
    const [passedSteps, setPassedSteps] = useState([]);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const membershipContext = useContext(cancelMembershipContext);
    const { address, showConfirm, setShowConfirm, onConfirm, membershipDebt } = membershipContext;
    const [lastTab, setLastTab] = useState(2);

    useEffect(() => {
        setLastTab((!membershipDebt || membershipDebt.totalPendingAmount == 0) ? 2 : 3);
    }, [membershipDebt]);

    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 5) {
                var modifiedSteps = [...passedSteps, tab];
                setActiveTab(tab);
                setPassedSteps(modifiedSteps);
            }
        }
    }

    return (
        <React.Fragment>
            {showConfirm ?

                <SweetAlert
                    title={t('Confirm Membership Payment')}
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText={t('Yes')}
                    cancelBtnText={t('No')}
                    onConfirm={() => {
                        onConfirm();
                        setShowConfirm(false);
                    }}
                    onCancel={() => {
                        setShowConfirm(false);
                    }}
                >

                </SweetAlert>
                : null}

            

            <Row>
                <Col lg={12}>
                    <Card>
                        <CardBody>
                            <div className="wizard clearfix">
                                <div className="steps clearfix">
                                    <ul>
                                        
                                        <NavItem
                                            className={classnames({
                                                current: activeTab === 1
                                            })}
                                        >
                                            <NavLink
                                                disabled={!(passedSteps || []).includes(1)}
                                                className={classnames({
                                                    active: activeTab === 1
                                                })}
                                            >
                                                <span className="number">1.</span>{" "}
                                                {t('Membership Detail')}
                                            </NavLink>
                                        </NavItem>

                                        <NavItem
                                            hidden={ !membershipDebt || membershipDebt.totalPendingAmount == 0 }
                                            className={classnames({
                                                current: activeTab === 2
                                            })}
                                        >
                                            <NavLink
                                                disabled={!(passedSteps || []).includes(2)}
                                                className={classnames({
                                                    active: activeTab === 2
                                                })}
                                            >
                                                <span className="number">2.</span>{" "}
                                                {t('Payment Method')}
                                            </NavLink>
                                        </NavItem>

                                        <NavItem
                                            className={classnames({
                                                current: activeTab === lastTab
                                            })}
                                        >
                                            <NavLink
                                                disabled={!(passedSteps || []).includes(lastTab)}
                                                className={classnames({
                                                    active: activeTab === lastTab
                                                })}
                                            >
                                                <span className="number">{lastTab}.</span>{" "}
                                                {t('Confirm')}
                                            </NavLink>
                                        </NavItem>
                                    </ul>
                                </div>
                                <div className="content clearfix">
                                    <TabContent
                                        activeTab={activeTab}
                                        className="body"
                                    >
                                        <TabPane tabId={1}>
                                            <CancelMembershipDebit />
                                        </TabPane>
                                        <TabPane tabId={2} hidden={!membershipDebt || membershipDebt.totalPendingAmount == 0}>
                                            <CancelMembershipPaymentMethod />
                                        </TabPane>
                                        <TabPane tabId={lastTab}>
                                            <CancelMembershipConfirmation />
                                        </TabPane>
                                    </TabContent>
                                </div>
                                <div className="actions clearfix">
                                    <ul>
                                        <li
                                            className={
                                                activeTab === 1
                                                    ? "previous disabled"
                                                    : "previous"
                                            }
                                        >
                                            <Link
                                                to="#"
                                                onClick={() => {
                                                    toggleTab(activeTab - 1);
                                                }}
                                            >
                                                {t('Previous')}
                                            </Link>
                                        </li>
                                        <li
                                            className={
                                                activeTab === 4
                                                    ? "next disabled"
                                                    : "next"
                                            }
                                        >
                                            <Link
                                                disabled={(activeTab === 1)}
                                                to="#"
                                                onClick={() => {
                                                    if (activeTab == lastTab) {
                                                        if (address) {
                                                            setShowConfirm(true);
                                                        } else {
                                                            dispatch(showWarningAlert(t('Invoice Address Missing')));
                                                        }

                                                    } else {
                                                        toggleTab(activeTab + 1);
                                                    }

                                                }}
                                            >
                                                {activeTab == lastTab ? t('Confirm') : t('Next')}
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </React.Fragment>

    );
}

export default AffiliatedMembershipCancelWizard;