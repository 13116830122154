import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Row } from 'reactstrap';
import { clearMembership, deleteMembership, getBenefits, getCatalogs, getMembershipDetail, showSuccessAlert } from 'store/actions';
import MembershipDetailCard from 'pages/Management/Commons/membership-card/membership-detail-card';
import SweetAlert from 'react-bootstrap-sweetalert';
import PageBody from 'components/Common/PageLayout/page-body';

const MembershipPackageDetailPage = () => {

    const { t } = useTranslation();
    const history = useHistory();
    const { id } = useParams();
    const dispatch = useDispatch();
    const membership = useSelector(state => state.memberships.membershipDetail);
    const membershipDeleted = useSelector(state => state.memberships.membershipDeleted);
    const currencyCodeList = useSelector(state => state.catalogs.currencyCodeList);

    const [showDelete, setShowDelete] = useState(false);

    useEffect(() => {
        dispatch(getCatalogs());
        dispatch(getMembershipDetail({ id: id }));
        dispatch(getBenefits({ membershipId: id }));
    }, []);

    useEffect(() => {
        if (membershipDeleted) {
            dispatch(showSuccessAlert('alert.delete', () => {
                dispatch(clearMembership());
                history.push('/membership-package');
            }));
        }

    }, [membershipDeleted]);

    const getCurrencySymbol = (currencyCode) => {
        const found = currencyCodeList.find(current => current.code == currencyCode);
        return found ? found.currency : currencyCode;
    }

    const onDelete = () => {
        setShowDelete(false);
        membership.companyId = membership.company.id;
        dispatch(deleteMembership(membership));
    }

    return (
        <React.Fragment>

            <PageBody
                title='Membership Detail'
                actions={(
                    <React.Fragment>
                        <Button color="primary" className="button mr-1" onClick={() => { history.push('/membership-package-new') }}><i className="mdi mdi-plus-circle-outline me-1"></i>{t('New Membership')}</Button>
                        <Button color="success" className="button mr-1" onClick={() => { history.push(`/membership-package-edit/${id}`) }}><i className="mdi mdi-pencil me-1"></i>{t('Edit Membership')}</Button>
                        <Button color="primary" className="button mr-1" onClick={() => { history.push(`/membership-package-benefits/${id}`) }}><i className="bx bx-gift me-1"></i>{t('Benefits Detail')}</Button>
                        <Button color="danger" className="button mr-1" onClick={() => { setShowDelete(true) }}><i className="mdi mdi-trash-can me-1"></i>{t('Delete Membership')}</Button>
                        <Button color="light" className="button mr-1" onClick={() => { history.push('/membership-package') }}><i className="mdi mdi-arrow-left me-1"></i>{t('Back')}</Button>
                    </React.Fragment>
                )}
            >
                <Row>
                    {membership ? <MembershipDetailCard membership={membership} getCurrencySymbol={getCurrencySymbol} /> : null}
                </Row>
                {showDelete ?

                    <SweetAlert
                        title={t('confirm.membership.delete')}
                        warning
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        confirmBtnText={t('Yes')}
                        cancelBtnText={t('No')}
                        onConfirm={() => {
                            onDelete();
                        }}
                        onCancel={() => {
                            setShowDelete(false);
                        }}
                    >

                    </SweetAlert>
                : null}
            </PageBody>
            
        </React.Fragment>
    );
}

export default MembershipPackageDetailPage;