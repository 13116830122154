import { DEFAULT_COMPANY } from "constants/list-types";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { useTranslation } from "react-i18next";
import { MetaTags } from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import {
  clearPrescription,
  deletePrescription,
  getPrescriptionTemplateList,
  showSuccessAlert,
} from "store/actions";
import { toDateISOStringFormat } from "helpers/utils";
import json from 'jsonwebtoken';
import { ALERT_MESSAGE } from "constants/constants";

const PrescriptionTemplatesPage = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const node = React.createRef();
  const [showDelete, setShowDelete] = useState(false);
  const [prescription, setPrescription] = useState(null);
  const { SearchBar } = Search;

  const templateList = useSelector(
    state => state.prescriptions.prescriptionTemplates
  );
  const prescriptionDeleted = useSelector(state => state.prescriptions.deleted);

  const [pageOptions, setPageOptions] = useState({
    sizePerPage: 10,
    totalSize: 0,
    custom: true,
  });

  const [accountCode, setAccountCode] = useState(null);

  useEffect(() => {

    if (localStorage.getItem("authUser")) {
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const user = json.decode(obj.idToken);
      setAccountCode(user.sub);
    }
  }, []);

  useEffect(() => {
    if (accountCode) {
      const request = {
        companyId: DEFAULT_COMPANY,
        account: accountCode,
        fromDate: null,//toDateISOStringFormat(new Date()),
        toDate: null,//toDateISOStringFormat(new Date()),
        pageNumber: 0,
      };
      dispatch(getPrescriptionTemplateList(request));
    }
  }, [accountCode]);

  useEffect(() => {
    if (prescriptionDeleted) {
      dispatch(
        showSuccessAlert(ALERT_MESSAGE.DELETED, () => {
          dispatch(clearPrescription());
          const request = {
            companyId: DEFAULT_COMPANY,
            account: accountCode,
            fromDate: null,//toDateISOStringFormat(new Date()),
            toDate: null,//toDateISOStringFormat(new Date()),
            pageNumber: 0,
          };
          dispatch(getPrescriptionTemplateList(request));
        })
      );
    }
  }, [prescriptionDeleted]);

  useEffect(() => {
    if (templateList) {
      setPageOptions({
        ...pageOptions,
        totalSize: templateList.length,
      });
    }
  }, [templateList]);

  const columns = [
    {
      text: t("Name"),
      dataField: "patientObservation",
      sort: true,
      formatter: (cellContent, row) => {

        return (
          <React.Fragment>
            {row.patientObservation}
          </React.Fragment>
        );
      },
    },
    {
      text: t("Date"),
      dataField: "doctorDiagnosticDate",
      sort: true,
      formatter: (cellContent, row) => (
        <React.Fragment>{row.doctorDiagnosticDate}</React.Fragment>
      ),
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "",
      formatter: (cellContent, row) => (
        <div className="d-flex gap-3">
          <Link className="text-success" to="#">
            <i
              className="mdi mdi-pencil font-size-18"
              id="edittooltip"
              onClick={() => editRow(row)}
            ></i>
          </Link>
          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => deleteRow(row)}
            ></i>
          </Link>
        </div>
      ),
    },
  ];

  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const editRow = row => {
    history.push(`/medical-edit-prescription-template/${row.id}`);
  };

  const deleteRow = row => {
    setPrescription(row);
    setShowDelete(true);
  };

  const onDelete = () => {
    setShowDelete(false);
    dispatch(deletePrescription(prescription));
  };

  return (
    <React.Fragment>
      {showDelete ? (
        <SweetAlert
          title={t("confirm.delete")}
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText={t("Yes")}
          cancelBtnText={t("No")}
          onConfirm={() => {
            onDelete();
          }}
          onCancel={() => {
            setShowDelete(false);
          }}
        ></SweetAlert>
      ) : null}

      <div className="page-content">
        <MetaTags>
          <title>{t("Title App")}</title>
        </MetaTags>
        <Container fluid>
          {/* Render Breadcrumbs */}
          <div className="page-title-box d-sm-flex align-items-center justify-content-between">
            <h2 className="mb-2 font-size-18">{t("Prescription Templates")}</h2>
            <div className="button-items">
              <Button
                color="primary"
                className="button"
                onClick={() => {
                  history.push(`/medical-add-prescription-template`);
                }}
              >
                <i className="mdi mdi-plus-circle-outline me-1"></i>
                {t("Create Prescription Template")}
              </Button>
              <Button
                color="light"
                className="button"
                onClick={() => {
                  history.push("/dashboard");
                }}
              >
                <i className="mdi mdi-arrow-left me-1"></i>
                {t("Back")}
              </Button>
            </div>
          </div>

          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={templateList}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={templateList}
                        search
                      >
                        {toolkitprops => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm={4}>
                                <div className="search-box ms-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar
                                      {...toolkitprops.searchProps}
                                      placeholder={t("Search")}
                                    />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8"></Col>
                            </Row>
                            <Row>
                              <Col xl={12}>
                                <div className="table-responsive">
                                  <BootstrapTable
                                    {...toolkitprops.baseProps}
                                    {...paginationTableProps}
                                    defaultSorted={defaultSorted}
                                    classes={
                                      "table align-middle table-nowrap table-hover"
                                    }
                                    bordered={false}
                                    striped={false}
                                    responsive
                                    ref={node}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PrescriptionTemplatesPage;
