import React, { Component } from "react"
import PropTypes from "prop-types"
import { BrowserRouter as Router, Switch } from "react-router-dom"
import { connect } from "react-redux"
import json from 'jsonwebtoken';
import * as Yup from "yup";

// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/"
import AppRoute from "./routes/route"

// layouts
import VerticalLayout from "./components/VerticalLayout/"
import HorizontalLayout from "./components/HorizontalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
import SweetAlert from "react-bootstrap-sweetalert"
import { withTranslation } from "react-i18next"
import { hideLoadingAlert, hideSuccessAlert, hideWarningAlert, getPrincipalByAccount, getRolePrincipalList, fetchUserAndGrants } from "store/actions"

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      principalLoading: false
    }
    this.getLayout = this.getLayout.bind(this)
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    let layoutCls = VerticalLayout

    switch (this.props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout
        break
      default:
        layoutCls = VerticalLayout
        break
    }
    return layoutCls
  }

  componentDidMount() {
    if (localStorage.getItem("authUser") && this.props.principal == null) {
      
      const obj = JSON.parse(localStorage.getItem("authUser"));
      const user = json.decode(obj.idToken);
      if (user) {
        this.props.getPrincipalByAccount({ accountCode: user.sub });+

        //Fix to download principals roles if reloaded
        this.props.fetchUserAndGrants();

      }
    }
  }

  componentDidUpdate(prevProps, prevState) {
    
    if(localStorage.getItem('authUser') && this.props.principal == null && !this.state.principalLoading) {
      this.setState({principalLoading : true});
      if (localStorage.getItem("authUser")) {
      
        const obj = JSON.parse(localStorage.getItem("authUser"));
        const user = json.decode(obj.idToken);
        if (user) {
          this.props.getPrincipalByAccount({ accountCode: user.sub });
        }
      }
    }
  }

  render() {
    const { showSuccess, showWarning, messageSuccess,
      messageWarning, hideSuccessAlert, hideWarningAlert, t,
      showLoading, messageLoading, callback
    } = this.props;
    const Layout = this.getLayout()


    Yup.setLocale({
        mixed: {
            required: t("This value is required"),
        },
        number: {
            min: t("The value must be greater than or equal to zero"),
        },
        string: {
          email: t("Email must be a valid email")
        }
        
    });

    return (
      <React.Fragment>
        <Router>
          <Switch>
            {publicRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={NonAuthLayout}
                component={route.component}
                key={idx}
                isAuthProtected={false}
              />
            ))}

            {authProtectedRoutes.map((route, idx) => (
              <AppRoute
                path={route.path}
                layout={Layout}
                component={route.component}
                key={idx}
                isAuthProtected={true}
                permission={route.permission}
                exact
              />
            ))}
          </Switch>
        </Router>
        {showSuccess ?

          <SweetAlert
            key="successAlert"
            title={t(messageSuccess)}
            success
            confirmBtnBsStyle="success"
            onConfirm={() => {
              if (callback) {
                callback();
              }
              hideSuccessAlert();
            }}
          ></SweetAlert>

          : null}

        {showWarning ?

          <SweetAlert
            key="failAlert"
            title={t(messageWarning)}
            warning
            confirmBtnBsStyle="success"
            onConfirm={() => { hideWarningAlert() }}
          ></SweetAlert>

          : null}

        {showLoading ?

          <SweetAlert
            key="loadingAlert"
            custom
            title={''}
            showCloseButton={false}
            showConfirm={false}
            onConfirm={() => { hideLoadingAlert() }}
          >
            <h1 className="display-2 fw-medium">

              <i className="bx bx-loader-alt bx-spin text-primary display-3" />

            </h1>
            <h1>{t(messageLoading)}</h1>
          </SweetAlert>

          : null}

      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    principal: state.principals.principalData,
    layout: state.Layout,
    showSuccess: state.Alerts.showSuccess,
    showWarning: state.Alerts.showWarning,
    showLoading: state.Alerts.showLoading,
    messageSuccess: state.Alerts.messageSuccess,
    messageLoading: state.Alerts.messageLoading,
    messageWarning: state.Alerts.messageWarning,
    callback: state.Alerts.callback
  }
}

const mapDispatchToProps = dispatch => ({
  hideSuccessAlert: () => dispatch(hideSuccessAlert()),
  hideWarningAlert: () => dispatch(hideWarningAlert()),
  hideLoadingAlert: () => dispatch(hideLoadingAlert()),
  getPrincipalByAccount: (request) => dispatch(getPrincipalByAccount(request)),
  getRolePrincipalList: (request) => dispatch(getRolePrincipalList(request)),
  fetchUserAndGrants: () => dispatch(fetchUserAndGrants())
})

App.propTypes = {
  principal: PropTypes.object,
  layout: PropTypes.object,
  t: PropTypes.any,
  showSuccess: PropTypes.bool,
  showLoading: PropTypes.bool,
  showWarning: PropTypes.bool,
  messageSuccess: PropTypes.string,
  messageLoading: PropTypes.string,
  messageWarning: PropTypes.string,
  hideSuccessAlert: PropTypes.func,
  hideWarningAlert: PropTypes.func,
  getPrincipalByAccount: PropTypes.func,
  getRolePrincipalList: PropTypes.func,
  fetchUserAndGrants: PropTypes.func,
  callback: PropTypes.func
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(App))
