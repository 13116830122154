import axios from "axios";
import { del, get, post, put } from "../Api/api-client";
import * as url from "../Api/api-endpoint-helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("user");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

// Register Method
const postFakeRegister = data => {
  return axios
    .post(url.POST_FAKE_REGISTER, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      let message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postFakeLogin = data => post(url.POST_FAKE_LOGIN, data);

// postForgetPwd
const postFakeForgetPwd = data => post(url.POST_FAKE_PASSWORD_FORGET, data);

// Edit profile
const postJwtProfile = data => post(url.POST_EDIT_JWT_PROFILE, data);

const postFakeProfile = data => post(url.POST_EDIT_PROFILE, data);

// Register Method
const postJwtRegister = (url, data) => {
  return axios
    .post(url, data)
    .then(response => {
      if (response.status >= 200 || response.status <= 299)
        return response.data;
      throw response.data;
    })
    .catch(err => {
      var message;
      if (err.response && err.response.status) {
        switch (err.response.status) {
          case 404:
            message = "Sorry! the page you are looking for could not be found";
            break;
          case 500:
            message =
              "Sorry! something went wrong, please contact our support team";
            break;
          case 401:
            message = "Invalid credentials";
            break;
          default:
            message = err[1];
            break;
        }
      }
      throw message;
    });
};

// Login Method
const postJwtLogin = data => post(url.POST_FAKE_JWT_LOGIN, data);

// postForgetPwd
const postJwtForgetPwd = data => post(url.POST_FAKE_JWT_PASSWORD_FORGET, data);

// postSocialLogin
export const postSocialLogin = data => post(url.SOCIAL_LOGIN, data);

//Login
export const httpPostLogin = data => post(`${url.POST_LOGIN}`, data);

export const httpPostRegister = data => post(`${url.POST_REGISTER}`, data);

export const httpPostNewUser = data => post(`${url.ADD_NEW_USER}`, data);

export const httpPostVerifyUser = data => post(`${url.VERIFY_USER}`, data);

export const httpPostVerifyEmail = data => post(`${url.VERIFY_EMAIL}`, data);

export const httpGetPrincipal = request =>
  get(`${url.GET_PRINCIPAL_DETAIL}/${request.email}`, {});

export const httpGetPrincipalByAccount = request =>
  get(`${url.GET_PRINCIPAL_DETAIL_BY_ACCOUNT}`, {
    params: { account: request.accountCode },
  });
  
export const httpGetRolePrincipalListByPrincipal = request =>
  get(`${url.GET_PRINCIPAL_ROLE_LIST_BY_PRINCIPAL}`, {
    params: { id: request.id },
  });

export const httpSavePrincipalRoles = data =>
  post(`${url.SAVE_PRINCIPAL_ROLES}`, data);

export const httpDeleteUser =  data => 
  post(`${url.DELETE_USER}`, data);

export const httpCancelUser =  data => 
  post(`${url.CANCEL_ACCOUNT}`, data);

//Affiliated profile fetch
export const httpGetAffiliated = accountCode =>
  get(`${url.GET_AFFILIATED}`, { params: { accountCode: accountCode } });

//Affiliated search
export const httpGetSearchAffiliated = request =>
  get(`${url.GET_AFFILIATED_SEARCH}`, {
    params: { criteria: request.criteria, key: request.key },
  });

//Affiliated orders
export const httpGetAffiliatedOrderList = accountCode =>
  get(`${url.GET_AFFILIATED_ORDER_LIST}`, {
    params: { accountCode: accountCode },
  });

//Affiliated membership list
export const httpGetAffiliatedMembershipList = accountCode =>
  get(`${url.GET_AFFILIATED_MEMBERSHIP_LIST}`, {
    params: { accountCode: accountCode },
  });

//Affiliated membership detail
export const httpGetAffiliatedMembershipDetail = id =>
  get(`${url.GET_AFFILIATED_MEMBERSHIP_DETAIL}`, { params: { id: id } });

//Affiliated membership debt
export const httpGetAffiliatedMembershipDebt = request =>
  get(`${url.GET_MEMBERSHIP_DEBT}`, {
    params: {
      accountCode: request.accountCode,
      membershipId: request.membershipId,
      membershipAffiliatedId: request.membershipAffiliatedId,
    },
  });

//Affiliated membership cancel
export const httpGetAffiliatedMembershipCancel = request =>
get(`${url.GET_MEMBERSHIP_CANCEL}`, {
  params: {
    accountCode: request.accountCode,
    membershipId: request.membershipId,
    membershipAffiliatedId: request.membershipAffiliatedId,
  },
});

//Affiliated Expenses
export const httpGetAffiliatedExpenses = request =>
  get(`${url.GET_AFFILIATED_MEDICAL_EXPENSES}`, {
    params: { 
      accountCode: request.accountCode,
      identification: '*',
    },
  });
//Filter Products
export const httpFilterProducts = request =>
  get(`${url.FILTER_PRODUCTS}`, {
    params: {
      name: request.name,
      accountCode: request.accountCode,
      pageNumber: request.pageNumber,
    },
  });

export const httpGetProductDetail = request =>
  get(`${url.GET_PRODUCT_DETAIL}`, {
    params: {
      id: request.id,
    },
  });

//Filter Products by Category
export const httpFilterProductsByCategory = request =>
  get(`${url.FILTER_PRODUCTS_BY_CATEGORY}`, {
    params: { categoryId: request.categoryId, pageNumber: request.pageNumber },
  });
//Affiliated search
export const httpUpgradeMembership = request =>
  post(`${url.UPGRADE_MEMBERSHIP}`, request);

//Affiliated Beneficiaries
export const httpGetAffiliatedBeneficiaryList = id =>
  get(`${url.GET_AFFILIATED_BENEFICIARIES}`, { params: { id: id } });

export const httpGetAffiliatedBeneficiaryDetail = id =>
  get(`${url.GET_AFFILIATED_BENEFICIARY}`, { params: { id: id } });

export const httpAddAffiliatedBeneficiary = request =>
  post(`${url.ADD_AFFILIATED_BENEFICIARY}`, request);

export const httpUpdateAffiliatedBeneficiary = request =>
  put(`${url.UPDATE_AFFILIATED_BENEFICIARY}`, request);

export const httpDeleteAffiliatedBeneficiary = id =>
  del(`${url.DELETE_AFFILIATED_BENEFICIARY}`, { params: { id: id } });

//Identification Type
export const httpGetIdentificationTypes = () =>
  get(`${url.GET_IDENTIFICATION_TYPES}`);

//Catalogs
export const httpGetCatalogs = () => get(`${url.GET_CATALOGS}`);

//All orders
export const httpGetAllOrderList = (data) =>
get(`${url.GET_ALL_ORDER_LIST}`, { params: { startDate: data.fromDate, endDate: data.toDate, identification: data.identification } })

export const httpGetOrder = request =>
get(`${url.GET_ORDER}`, { params: { id: request.id } })

//Save invoice
export const httpSaveInvoice = data => post(`${url.SAVE_INVOICE}`, data);

//Save invoice detail delivery
export const httpSaveInvoiceDetailDelivery = data => post(`${url.SAVE_INVOICE_DETAIL_DELIVERY}`, data)

//Get transactions
export const httpGetPurchaseTransactions = (data) =>
  get(`${url.GET_TRANSACTIONS_PURCHASE}`, { params: { fromDate: data.fromDate, toDate: data.toDate } })

//Memberships
export const httpGetMemberships = () => get(`${url.GET_MEMBERSHIPS_LIST}`, {});

export const httpGetMembershipDetail = id =>
  get(`${url.GET_MEMBERSHIPS_DETAIL}`, { params: { id: id } });

export const httpAddMembership = data => post(`${url.ADD_MEMBERSHIP}`, data);

export const httpUpdateMembership = data =>
  put(`${url.UPDATE_MEMBERSHIP}`, data);

export const httpDeleteMembership = request =>
  post(`${url.DELETE_MEMBERSHIP}`, { id: request.id });

//Invoice Payment Membership Cancellation
export const httpInvoicePaymentCancellation = request =>
  post(`${url.INVOICE_PAYMENT_MEMBERSHIP_CACELLATION}`, request);

//Affiliated Reward Programs
export const httpGetAffiliatedRewardPrograms = request =>
  get(`${url.GET_AFFILIATED_REWARD_PROGRAMS_LIST}`, {
    params: { accountCode: request.accountCode },
  });

//Benefits
export const httpGetBenefits = membershipId =>
  get(`${url.GET_BENEFITS_LIST}`, { params: { membershipId: membershipId } });

export const httpGetBenefitDetail = id =>
  get(`${url.GET_BENEFITS_DETAIL}`, { params: { id: id } });

export const httpAddBenefit = data => post(`${url.ADD_BENEFIT}`, data);

export const httpUpdateBenefit = data => put(`${url.UPDATE_BENEFIT}`, data);

export const httpDeleteBenefit = request =>
  del(`${url.DELETE_BENEFIT}`, { params: { id: request.id } });

//Benefit Categories
export const httpGetBenefitCategories = () =>
  get(`${url.GET_BENEFIT_CATEGORIES_LIST}`, {});

//Related Products
export const httpGetRelatedProducts = request =>
  get(`${url.GET_RELATED_PRODUCTS}`, {
    params: { productId: request.productId }
  })

export const httpPostRelatedProducts = request =>
  post(`${url.POST_RELATED_PRODUCTS}`, request)

//Terms
export const httpGetTermsByType = request =>
  get(`${url.GET_TERMS_BY_TYPE}`, {
    params: { companyId: request.companyId, type: request.type },
  });

export const httpGetTerms = request =>
  get(`${url.GET_TERMS}`, { params: { companyId: request.companyId } });

export const httpGetTermDetail = request =>
  get(`${url.GET_TERM_DETAIL}`, {
    params: { id: request.id, type: request.type },
  });

export const httpAddTerm = data => post(`${url.ADD_TERM}`, data);

export const httpUpdateTerm = data => put(`${url.UPDATE_TERM}`, data);

export const httpDeleteTerm = request =>
  post(`${url.DELETE_TERM}`, { id: request.id, type: request.type });

//Questions
export const httpGetQuestionsByType = request =>
  get(`${url.GET_QUESTIONS_BY_TYPE}`, {
    params: { companyId: request.companyId, type: request.type },
  });

export const httpGetQuestions = request =>
  get(`${url.GET_QUESTIONS}`, { params: { companyId: request.companyId } });

export const httpGetQuestionDetail = request =>
  get(`${url.GET_QUESTION_DETAIL}`, { params: { id: request.id } });

export const httpAddQuestion = data => post(`${url.ADD_QUESTION}`, data);

export const httpUpdateQuestion = data => put(`${url.UPDATE_QUESTION}`, data);

export const httpDeleteQuestion = request =>
  del(`${url.DELETE_QUESTION}`, { params: { id: request.id } });

export const httpGetQuoterMemberships = () =>
  get(`${url.GET_QUOTER_MEMBERSHIP_LIST}`, {});

export const httpGetQuoterMembershipDetail = request =>
  get(`${url.GET_QUOTER_MEMBERSHIP_DETAIL}`, { params: { id: request.id } });

export const httpAddQuoterMembership = request =>
  post(`${url.ADD_QUOTER_MEMBERSHIP}`, request);

export const httpUpdateQuoterMembership = request =>
  put(`${url.UPDATE_QUOTER_MEMBERSHIP}`, request);

export const httpDeleteQuoterMembership = request =>
  del(`${url.DELETE_QUOTER_MEMBERSHIP}`, { params: { id: request.id } });

export const httpGetRoles = () => get(`${url.GET_ROLE_LIST}`, {});

export const httpGetRoleDetail = request =>
  get(`${url.GET_ROLE_DETAIL}`, { params: { id: request.id } });

export const httpAddRole = request => post(`${url.ADD_ROLE}`, request);

export const httpUpdateRole = request => put(`${url.UPDATE_ROLE}`, request);

export const httpDeleteRole = request =>
  del(`${url.DELETE_ROLE}`, { params: { id: request.id } });

export const httpGetResources = () => get(`${url.GET_RESOURCE_LIST}`, {});

export const httpGetRoleResources = request =>
  get(`${url.GET_ROLE_RESOURCE_LIST}`, { params: { roleId: request.id } });

export const httpAddRoleResources = request =>
  post(`${url.ADD_ROLE_RESOURCE_LIST}`, request);

export const httpGetRealms = () => get(`${url.GET_REALM_LIST}`, {});

export const httpGetPaymentMethods = request =>
  get(`${url.GET_PAYMENT_METHOD_LIST}`, {
    params: { accountCode: request.accountCode },
  });

export const httpGetPaymentMethodDetail = request =>
  get(`${url.GET_PAYMENT_METHOD_DETAIL}`, { params: { id: request.id } });

export const httpAddPaymentMethod = request =>
  post(`${url.ADD_PAYMENT_METHOD}`, request);

export const httpUpdatePaymentMethod = request =>
  put(`${url.UPDATE_PAYMENT_METHOD}`, request);

export const httpDeletePaymentMethod = request =>
  del(`${url.DELETE_PAYMENT_METHOD}`, { params: { id: request.id } });

export const httpGetBusinessUnits = request =>
  get(`${url.GET_BUSINESS_UNIT_LIST}`, {
    params: { companyId: request.companyId },
  });

export const httpGetSubsidiaries = request =>
  get(`${url.GET_SUBSIDIARY_LIST}`, {
    params: { companyId: request.companyId },
  });

//ECommerce services
export const httpGetECommerceServices = request =>
  get(`${url.GET_ECOMMERCE_SERVICES_LIST}`, {
    params: { code: request.code, description: request.description },
  });

//Countries
export const httpGetCountries = () => get(`${url.GET_COUNTRIES}`, {});

//Account
export const httpGetAccounts = () => get(`${url.GET_ACCOUNTS}`, {});

//Patients
export const httpGetPatients = (request) => get(`${url.GET_PATIENTS}`, {
  params: {criteria: request.criteria, key: request.key}
});

export const httpGetAccountDetail = request =>
  get(`${url.GET_ACCOUNT}`, { params: { accountCode: request.accountCode } });

  export const httpGetAccountDetailByExternalCode = accountCode =>
  get(`${url.GET_ACCOUNT_EXTERNAL_CODE}`, { params: { accountCode: accountCode } });

export const httpPutAccount = request => put(`${url.UPDATE_ACCOUNT}`, request);

export const httpDeleteAccount = request => 
  post(`${url.DELETE_ACCOUNT}`, request);

export const httpGetLaboratoriesSync = (request) =>
  get(`${url.LABORATORY_SYNC}`, {params: {accountCode: request } });

//Change Password
export const httpRequestVerificationCode = request =>
  post(`${url.REQUEST_VERIFICATION_CODE}`, request);

export const httpValidateVerificationCode = request =>
  post(`${url.VALIDATE_VERIFICATION_CODE}`, request);

export const httpUpdatePassword = request =>
  post(`${url.UPDATE_PASSWORD}`, request);

//Medical Category
export const httpGetMedicalCategories = request =>
  get(`${url.GET_MEDICAL_CATEGORIES}`, {
    params: { medicalCategoryType: request.medicalCategoryType },
  });

//Medical Service
export const httpGetMedicalCenters = request =>
  get(`${url.GET_MEDICAL_CENTERS}`, {});

//Pharmcy List
export const httpGetPharmacyList = request =>
  get(`${url.GET_PHARMACY_LIST}`, {});

//Product Categories
export const httpGetProductCategories = request =>
  get(`${url.GET_PRODUCT_CATEGORIES}`, {});

//Laboratory Order
export const httpGetLaboratoryOrderHistoricalList = request =>
  get(`${url.GET_LABORATORY_ORDER_HISTORICAL}`, {
    params: {
      accountCode: request.account,
      //medicalCenter: request.medicalCenter,
      identification: request.identification,
      fromDate: request.start,
      toDate: request.end,
      pageNumber: 0,
      //appointmentStatus: "ALL",
      appointmentType: "LAB",
      isPortalAdmin: true
    },
  });

export const httpGetLaboratoryOrderDetail = request =>
  get(`${url.GET_LABORATORY_ORDER_DETAIL}`, { params: { id: request.id, isPortalAdmin: true } });

//Delivery Package
export const httpGetDeliveryPackages = request =>
  get(`${url.GET_DELIVERY_PACKAGES_LIST}`, {
    params: {
      pickupType: request.pickupType,
      pickupStatus: request.pickupStatus,
      isPortalAdmin: true
    },
  });

export const httpGetDeliveryPackageDetail = request =>
  get(`${url.GET_DELIVERY_PACKAGE_DETAIL}`, { params: { id: request.id } });

  //Delivery Package update status
export const httpUpdateDeliveryPackageStatus = data => put(`${url.UPDATE_DELIVERY_PACKAGE_DETAIL}`, data);

//Fee List
export const httpGetFeeLists = request =>
get(`${url.GET_FEE_LIST}`, {
  params: {
    companyId: request.companyId,
    businessUnitType: request.businessUnitType,
    type: request.type
  },
});

export const httpPostFeeList = request =>
post(`${url.SAVE_FEE_LIST}`, request);



  //Sample Collect
export const httpGetSampleCollectList = request =>
get(`${url.GET_SAMPLE_COLLECT_LIST}`, {
  params: {
    appointmentType: request.appointmentType,
    pickupType: request.pickupType,
    pickupStatus: request.pickupStatus,
    isPortalAdmin: true
  },
});

export const httpGetSampleCollectDetail = request =>
get(`${url.GET_SAMPLE_COLLECT_DETAIL}`, { params: { id: request.id } });

// change pickup status of a sample
export const httpUpdateSamplePickupStatus = data => put(`${url.UPDATE_SAMPLE_PICKUP_STATUS}`, data);

//Medical Services
export const httpGetMedicalServices = request =>
  get(`${url.GET_MEDICAL_SERVICES}`, {
    params: {
      pageNumber: request.pageNumber,
      medicalCategoryId: request.medicalCategoryId,
    },
  });

//All Medical Services
export const httpGetAllMedicalServices = request =>
  get(`${url.GET_ALL_MEDICAL_SERVICES}`);

export const httpPostMedicalService = request =>
  post(`${url.UPDATE_MEDICAL_SERVICE_DETAIL}`, request);

//All Medical Services by Type
export const httpGetAllMedicalServicesByType = request =>
  get(`${url.GET_ALL_MEDICAL_SERVICES_BY_TYPE}`, { params: { type: request.type } });

//Prescription Historical
export const httpGetPrescriptionList = request =>
  get(`${url.GET_PRESCRIPTION_LIST}`, {
    params: {
      accountCode: request.account,
      companyId: request.companyId,
      fromDate: request.fromDate,
      toDate: request.toDate,
      pageNumber: request.pageNumber,
      //prescriptionStatus: "ALL",
    },
  });

export const httpGetPrescriptionTemplateList = request =>
  get(`${url.GET_PRESCRIPTION_TEMPLATE_LIST}`, {
    params: {
      accountCode: request.account,
      companyId: request.companyId,
      fromDate: request.fromDate,
      toDate: request.toDate,
      pageNumber: request.pageNumber,
      prescriptionStatus: "TEM",
    },
  });

export const httpGetPrescriptionDetail = request =>
  get(`${url.GET_PRESCRIPTION_DETAIL}`, { params: { id: request.id } });

export const httpPostPrescription = request =>
  post(`${url.ADD_PRESCRIPTION}`, request);

export const httpPutPrescription = request =>
  put(`${url.UPDATE_PRESCRIPTION}`, request);

export const httpPutUploadPdfPrescription = request =>
  put(`${url.UPLOAD_PDF_PRESCRIPTION}`, request);

export const httpDeletePrescription = request =>
  del(`${url.DELETE_PRESCRIPTION}`, { params: { id: request.id } });

//Service Prescription Historical
export const httpGetServicePrescriptionList = request =>
  get(`${url.GET_SERVICE_PRESCRIPTION_LIST}`, {
    params: {
      accountCode: request.account,
      companyId: request.companyId,
      fromDate: request.fromDate,
      toDate: request.toDate,
      pageNumber: request.pageNumber,
      //prescriptionStatus: "ALL",
    },
  });

export const httpGetServicePrescriptionTemplateList = request =>
  get(`${url.GET_SERVICE_PRESCRIPTION_TEMPLATE_LIST}`, {
    params: {
      accountCode: request.account,
      companyId: request.companyId,
      fromDate: request.fromDate,
      toDate: request.toDate,
      pageNumber: request.pageNumber,
      prescriptionStatus: "TEM",
    },
  });

export const httpGetServicePrescriptionDetail = request =>
  get(`${url.GET_SERVICE_PRESCRIPTION_DETAIL}`, { params: { id: request.id } });

export const httpPostServicePrescription = request =>
  post(`${url.ADD_SERVICE_PRESCRIPTION}`, request);

export const httpPutServicePrescription = request =>
  put(`${url.UPDATE_SERVICE_PRESCRIPTION}`, request);

export const httpPutUploadPdfServicePrescription = request =>
  put(`${url.UPLOAD_PDF_SERVICE_PRESCRIPTION}`, request);

export const httpDeleteServicePrescription = request =>
  del(`${url.DELETE_SERVICE_PRESCRIPTION}`, { params: { id: request.id } });

export const httpGetMedicalServiceDetail = request =>
  get(`${url.GET_MEDICAL_SERVICE_DETAIL}`, {
    params: { id: request.medicalServiceId },
  });

export const httpGetPromotionsList = request =>
  get(`${url.GET_PROMOTIONS_LIST}`, { params: { companyId: request.companyId } });

export const httpGetPromotionDetail = request =>
  get(`${url.GET_PROMOTION_DETAIL}`, { params: { id: request.id } });

export const httpAddPromotion = data => post(`${url.ADD_PROMOTION}`, data);

export const httpUpdatePromotion = data => put(`${url.UPDATE_PROMOTION}`, data);

export const httpDeletePromotion = request =>
  get(`${url.DELETE_PROMOTION}`, { params: { id: request.id } });

export const httpSendPromotionNotification = data => post(`${url.SEND_BATCH_NOTIFICATION}`, data);

export const httpSendExamResult = data => post(`${url.SEND_EXAM_RESULT}`, data);

export const httpAddMembershipAffiliatedBenefit = data => 
  post(`${url.ADD_MEMBERSHIP_AFFILIATED_BENEFIT}`, data);

export const httpGetMembershipAffiliatedBenefitList = request => 
  get(`${url.GET_MEMBERSHIP_AFFILIATED_BENEFIT_LIST}`, {params: { id: request.id }});

export const httpGetMembershipAffiliatedDetailList = request => 
  get(`${url.GET_MEMBERSHIP_AFFILIATED_DETAIL_LIST}`, {params: { id: request.id }});

export {
  getLoggedInUser,
  isUserAuthenticated,
  postFakeRegister,
  postFakeLogin,
  postFakeProfile,
  postFakeForgetPwd,
  postJwtRegister,
  postJwtLogin,
  postJwtForgetPwd,
  postJwtProfile,
};
