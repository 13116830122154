import { ADD_QUOTER_MEMBERSHIP, ADD_QUOTER_MEMBERSHIP_FAIL, ADD_QUOTER_MEMBERSHIP_SUCCESS, CLEAR_QUOTER_MEMBERSHIPS_STATE,
         DELETE_QUOTER_MEMBERSHIP,
         DELETE_QUOTER_MEMBERSHIP_FAIL,
         DELETE_QUOTER_MEMBERSHIP_SUCCESS,
         GET_QUOTER_MEMBERSHIP_DETAIL,
         GET_QUOTER_MEMBERSHIP_DETAIL_FAIL,
         GET_QUOTER_MEMBERSHIP_DETAIL_SUCCESS,
         GET_QUOTER_MEMBERSHIP_LIST, 
         GET_QUOTER_MEMBERSHIP_LIST_FAIL, 
         GET_QUOTER_MEMBERSHIP_LIST_SUCCESS, 
         UPDATE_QUOTER_MEMBERSHIP,
         UPDATE_QUOTER_MEMBERSHIP_FAIL,
         UPDATE_QUOTER_MEMBERSHIP_SUCCESS} from "./actionTypes"

const INIT_STATE = {
    quoterMemberships: [],
    quoterMembershipDetail: null,
    created: false,
    updated: false,
    deleted: false,
    success: false,
    loading: false,
    error: {}
  }
  
  const quoterMemberships = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CLEAR_QUOTER_MEMBERSHIPS_STATE:
        return {
            ...state,
            quoterMemberships: [],
            quoterMembershipDetail: null,
            created: false,
            updated: false,
            deleted: false,
            success: false,
            loading: false,
            error: {}
        }
        
        case GET_QUOTER_MEMBERSHIP_LIST:
        return {
            ...state,
            quoterMemberships: [],
            loading: true,
        } 
        case GET_QUOTER_MEMBERSHIP_LIST_SUCCESS:
        return {
            ...state,
            loading: false,
            quoterMemberships: action.payload,
        }  
        case GET_QUOTER_MEMBERSHIP_LIST_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case GET_QUOTER_MEMBERSHIP_DETAIL:
        return {
            ...state,
            quoterMembershipDetail: null,
            loading: true,
        } 
        case GET_QUOTER_MEMBERSHIP_DETAIL_SUCCESS:
        return {
            ...state,
            loading: false,
            quoterMembershipDetail: action.payload,
        }  
        case GET_QUOTER_MEMBERSHIP_DETAIL_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case ADD_QUOTER_MEMBERSHIP:
        return {
            ...state,
            created: false,
            loading: true,
        } 
        case ADD_QUOTER_MEMBERSHIP_SUCCESS:
        return {
            ...state,
            loading: false,
            created: true
        }  
        case ADD_QUOTER_MEMBERSHIP_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case UPDATE_QUOTER_MEMBERSHIP:
        return {
            ...state,
            updated: false,
            loading: true,
        } 
        case UPDATE_QUOTER_MEMBERSHIP_SUCCESS:
        return {
            ...state,
            loading: false,
            updated: true
        }  
        case UPDATE_QUOTER_MEMBERSHIP_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        case DELETE_QUOTER_MEMBERSHIP:
        return {
            ...state,
            deleted: false,
            loading: true,
        } 
        case DELETE_QUOTER_MEMBERSHIP_SUCCESS:
        return {
            ...state,
            loading: false,
            deleted: true
        }  
        case DELETE_QUOTER_MEMBERSHIP_FAIL:
        return {
            ...state,
            loading: false,
            error: action.payload,
        }
        default:
            return state
        }
    }
    
export default quoterMemberships
      