import {
  GET_PRODUCT_CATEGORY_LIST,
  GET_PRODUCT_CATEGORY_LIST_FAIL,
  GET_PRODUCT_CATEGORY_LIST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  productCategoryList: [],
  error: {},
  loading: false
}

const productCategories = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCT_CATEGORY_LIST:
      return {
        ...state,
        productCategoryList: [],
        loading: true,
      }         
    case GET_PRODUCT_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        productCategoryList: action.payload,
      }
    case GET_PRODUCT_CATEGORY_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default productCategories;
