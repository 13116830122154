import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'reactstrap';
import { numberFormat } from 'helpers/utils';
import { useTranslation } from 'react-i18next';
import image from 'assets/images/vitae/Logos-vitae.png'

const PrescriptionServiceCard = ({ prescriptionService, onUpdate, onDelete, onUpdateQuantity, getCurrencySymbol }) => {

    const { productMedias, description, price, prescription } = prescriptionService.medicalService ? prescriptionService.medicalService : {};
    const { quantity, currencyCode } = prescriptionService;
    const { t } = useTranslation();

    const [doctorPrescription, setDoctorPrescription] = useState(prescriptionService.doctorPrescription? prescriptionService.doctorPrescription : prescription);
    const handleOnChange = event => {
        setDoctorPrescription(event.target.value);
        onUpdate({ ...prescriptionService, doctorPrescription: event.target.value });
    }

    const getProductPicture = () => {
        let picture =
            productMedias &&  productMedias.length > 0 ? productMedias[0] : null;

        return picture ? picture.filePath : "";
    };
    return (
        <React.Fragment>
            <Row className='mt-1'>
                <Col sm={7}>
                    <Row className="align-middle">
                        <Col sm={3} >
                            <img
                                className="img-fluid"
                                src={getProductPicture()}
                                onError={(evt) => (evt.target.onerror = null, evt.target.src = image)}
                            />
                        </Col>
                        <Col sm={6} >
                            <span>{description}</span>
                            <div>

                                <strong>{t('Quantity')}: {quantity}</strong>

                            </div>
                            <div>
                                <i className='mdi mdi-minus-circle mdi-24px' onClick={() => { if (quantity > 1) { onUpdateQuantity(prescriptionService, -1) } }}></i>
                                <span className="font-size-24">{quantity}</span>
                                <i className='mdi mdi-plus-circle mdi-24px text-primary' onClick={() => { onUpdateQuantity(prescriptionService, 1) }}></i>
                            </div>
                        </Col>
                        <Col sm={3}>
                            <strong className='font-size-16 text-end '>
                                {getCurrencySymbol(currencyCode)}{numberFormat(price)}
                            </strong>
                        </Col>

                    </Row>
                </Col>
                <Col sm={5}>
                    <Row className="align-middle">
                        <Col sm={11}>
                            <div><strong>{t('Detail for the service included')}</strong></div>
                            <textarea maxLength={200} className='form-control' value={doctorPrescription} onChange={handleOnChange} />
                        </Col>
                        <Col sm={1}>
                            <strong className='font-size-24 text-end '>
                                <i className={'text-danger mdi mdi-close-thick dashboard-option'} onClick={() => { onDelete(prescriptionService) }}></i>
                            </strong>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </React.Fragment>
    );
}
PrescriptionServiceCard.propTypes = {
    prescriptionService: PropTypes.object.isRequired,
    onUpdate: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    getCurrencySymbol: PropTypes.func.isRequired,
    onUpdateQuantity: PropTypes.func.isRequired
};

export default PrescriptionServiceCard;