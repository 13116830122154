import DataTable from 'components/Common/DataTable/datatable';
import { toDateStringFormat } from 'helpers/utils';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Button, Col, Row } from 'reactstrap';
import { getAllMedicalServiceList } from 'store/actions';

const ServiceCatalogList = () => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const [selectedMedicalServices, setSelectedMedicalServices] = useState([]);
    const [result, setResult] = useState([]);
    const medicalServices = useSelector(state => state.medicalServices.medicalServiceList);

    const [pageOptions, setPageOptions] = useState({
        sizePerPage: 5,
        totalSize: 0,
        custom: true,
    });

    useEffect(() => {
        dispatch(getAllMedicalServiceList());
    }, []);

    useEffect(() => {
        if (medicalServices) {
            setPageOptions({
                ...pageOptions,
                totalSize: medicalServices.length,
            });

            setResult(medicalServices.map(current => ({
                id: current.id,
                crossReference: current.crossReference,
                category: current.medicalCategory.description,
                serviceName: current.description,
                currencyCode: current.currencyCode,
                price: current.price,
                marketPrice: current.marketPrice,
                type: current.type,
                displayStatus: current.displayStatus,
                discount: current.discount,
                priceWithDiscount: current.priceWithDiscount
            })));
        }
    }, [medicalServices]);
    
    const columns = [   
        {
            text: t('Name'),
            dataField: 'serviceName',
            sort: true,
            formatter: (cellContent, row) => <React.Fragment>{row.serviceName}</React.Fragment>
        },
        {
            text: t('Id Suku'),
            dataField: 'crossReference',
            sort: true,
            formatter: (cellContent, row) => <React.Fragment>{row.crossReference}</React.Fragment>
        },
        {
            text: t('Price'),
            dataField: 'price',
            sort: true,
            formatter: (cellContent, row) => {
                return (
                    <React.Fragment>{row.price.toFixed(2) + " " + row.currencyCode}</React.Fragment>
                );
            }
        },
        {
            text: t('Market value'),
            dataField: 'marketPrice',
            sort: true,
            formatter: (cellContent, row) => {
                return (
                    <React.Fragment>{row.marketPrice ? (row.marketPrice.toFixed(2) + " " + row.currencyCode) : 0}</React.Fragment>
                );
            }
        },
        {
            text: t('Service Catalog Type'),
            dataField: 'type',
            sort: true,
            formatter: (cellContent, row) => {
                return (
                    <React.Fragment>{row.type && row.type === 'LAB' ? t('Service Catalog LAB') : t('Service Catalog MED')}</React.Fragment>
                );
            }
        },
        {
            text: t('Visible'),
            dataField: 'displayStatus',
            sort: true,
            formatter: (cellContent, row) => {
                return (
                    <React.Fragment>{row.displayStatus && row.displayStatus === 'ACT' ? t('Yes') : t('No')}</React.Fragment>
                );
            }
        },
        {
            dataField: 'menu',
            isDummyField: true,
            editable: false,
            text: '',
            formatter: (cellContent, row) => (
                <div className="d-flex gap-3">
                    <Button type='button' color="success" onClick={() => viewDetail(row)}>
                        {t('Modify')}
                        <i className="mdi mdi-arrow-riht" />
                    </Button>
                </div>
            ),
        }
    ];

    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        }
    ];

    const viewDetail = row => {
        history.push(`/service-catalog-detail/${row.id}`);
    }
    return (
        <React.Fragment>
            {/* factura, fecha, paciente */}
            <Row>
                <Col lg={12}>
                    <DataTable
                        data={result}
                        keyField="id"
                        columns={columns}
                        sort={defaultSorted}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
}

export default ServiceCatalogList;