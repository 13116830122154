import {
    GET_PATIENT_LIST,
    GET_PATIENT_LIST_FAIL,
    GET_PATIENT_LIST_SUCCESS,
    CLEAR_PATIENT_STATE
} from "./actionTypes"

export const clearPatient = () => ({
    type: CLEAR_PATIENT_STATE
})

export const getPatientList = (request) => ({
    type: GET_PATIENT_LIST,
    payload: request
})

export const getPatientListSuccess = response => ({
    type: GET_PATIENT_LIST_SUCCESS,
    payload: response
})

export const getPatientListFail = error => ({
    type: GET_PATIENT_LIST_FAIL,
    payload: error
})
