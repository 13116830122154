import { call, put, takeEvery } from "redux-saga/effects";

import { GET_PRODUCT_CATEGORY_LIST } from "./actionTypes";

import {
  getProductCategoryListSuccess,
  getProductCategoryListFail,
} from "./actions";

//API Calls helper
import { httpGetProductCategories } from "../../helpers/Api/api-helper";

import {
  hideLoadingAlert,
  showLoadingAlert,
  showWarningAlert,
} from "store/actions";
import { ALERT_MESSAGE } from "constants/constants";

function* fetchProductCategories(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(httpGetProductCategories);
    yield put(getProductCategoryListSuccess(response));
    yield put(hideLoadingAlert());
  } catch (error) {
    yield put(hideLoadingAlert());
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR));
    yield put(getProductCategoryListFail(error));
  }
}

function* fetchProductCategoriesSaga() {
  yield takeEvery(GET_PRODUCT_CATEGORY_LIST, fetchProductCategories);
}

export default fetchProductCategoriesSaga;
