import {
    CLEAR_RELATED_PRODUCTS_STATE,
    GET_RELATED_PRODUCTS_LIST,
    GET_RELATED_PRODUCTS_LIST_SUCCESS,
    GET_RELATED_PRODUCTS_LIST_FAIL,
    ADD_RELATED_PRODUCTS,
    ADD_RELATED_PRODUCTS_SUCCESS,
    ADD_RELATED_PRODUCTS_FAIL
} from "./actionTypes"

const INIT_STATE = {
    products: [],
    success: false,
    loading: false,
    error: {},
    created: false
}

const relatedProducts = (state = INIT_STATE, action) => {
    switch (action.type) {
        case CLEAR_RELATED_PRODUCTS_STATE:
            return {
                ...state,
                products: [],
                success: false,
                loading: false,
                error: {},
                created: false
            }
        case GET_RELATED_PRODUCTS_LIST:
            return {
                ...state,
                products: [],
                success: false,
                loading: true,
            }
        case GET_RELATED_PRODUCTS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                success: true,
                products: action.payload,
            }
        case GET_RELATED_PRODUCTS_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        case ADD_RELATED_PRODUCTS:
            return {
                ...state,
                success: false,
                loading: true,
                created: false
            }
        case ADD_RELATED_PRODUCTS_SUCCESS:
            return {
                ...state,
                success: true,
                loading: false,
                created: true,
            }
        case ADD_RELATED_PRODUCTS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload,
            }
        default:
            return state
    }
}

export default relatedProducts;
