/* TERMS */
export const CLEAR_PRESCRIPTION_STATE = "CLEAR_PRESCRIPTION_STATE";

export const GET_PRESCRIPTION_TEMPLATE_LIST = "GET_PRESCRIPTION_TEMPLATE_LIST";
export const GET_PRESCRIPTION_TEMPLATE_LIST_FAIL =
  "GET_PRESCRIPTION_TEMPLATE_LIST_FAIL";
export const GET_PRESCRIPTION_TEMPLATE_LIST_SUCCESS =
  "GET_PRESCRIPTION_TEMPLATE_LIST_SUCCESS";

export const GET_PRESCRIPTION_LIST = "GET_PRESCRIPTION_LIST";
export const GET_PRESCRIPTION_LIST_FAIL = "GET_PRESCRIPTION_LIST_FAIL";
export const GET_PRESCRIPTION_LIST_SUCCESS = "GET_PRESCRIPTION_LIST_SUCCESS";

export const GET_PRESCRIPTION_DETAIL = "GET_PRESCRIPTION_DETAIL";
export const GET_PRESCRIPTION_DETAIL_FAIL = "GET_PRESCRIPTION_DETAIL_FAIL";
export const GET_PRESCRIPTION_DETAIL_SUCCESS =
  "GET_PRESCRIPTION_DETAIL_SUCCESS";

export const ADD_PRESCRIPTION = "ADD_PRESCRIPTION";
export const ADD_PRESCRIPTION_FAIL = "ADD_PRESCRIPTION_FAIL";
export const ADD_PRESCRIPTION_SUCCESS = "ADD_PRESCRIPTION_SUCCESS";

export const UPDATE_PRESCRIPTION = "UPDATE_PRESCRIPTION";
export const UPDATE_PRESCRIPTION_FAIL = "UPDATE_PRESCRIPTION_FAIL";
export const UPDATE_PRESCRIPTION_SUCCESS = "UPDATE_PRESCRIPTION_SUCCESS";

export const UPLOAD_PDF_PRESCRIPTION = "UPLOAD_PDF_PRESCRIPTION";
export const UPLOAD_PDF_PRESCRIPTION_FAIL = "UPLOAD_PDF_PRESCRIPTION_FAIL";
export const UPLOAD_PDF_PRESCRIPTION_SUCCESS = "UPLOAD_PDF_PRESCRIPTION_SUCCESS";

export const DELETE_PRESCRIPTION = "DELETE_PRESCRIPTION";
export const DELETE_PRESCRIPTION_FAIL = "DELETE_PRESCRIPTION_FAIL";
export const DELETE_PRESCRIPTION_SUCCESS = "DELETE_PRESCRIPTION_SUCCESS";

