import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button, Col, Row } from "reactstrap";
import { useTranslation } from 'react-i18next';
import FormFieldBasic from 'pages/Utility/form-field-basic';

const FeeForm = ({ fee, setFee, onAdd }) => {

    const { t } = useTranslation();

    const handleOnChange = event => {
        setFee({
            ...fee,
            [event.target.name]: event.target.value,
        });
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: fee,
        validationSchema:
            Yup.object().shape({
                fromRange: Yup.number(),
                toRange: Yup.number(),
                fee: Yup.number().required().min(1),
            }),
        onSubmit: (values, actions) => {
            actions.setSubmitting(false);
            fee.fromRange = parseInt(fee.fromRange, 10);
            fee.toRange = parseInt(fee.toRange, 10);
            onAdd(fee);
        },
        onReset: (values, helper) => {

        }
    });


    const { touched, errors, values, handleChange, submitForm, handleBlur } = formik;

    return (
        <React.Fragment>
            <form className='needs-validation mt-3' onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <Row>
                    <Col md={4}>
                        <FormFieldBasic
                            key="fromRange"
                            type="number"
                            maxLength={200}
                            fieldName="fromRange"
                            fieldValue={values.fromRange}
                            label={t("From")}
                            errors={errors}
                            touched={touched}
                            handleOnChange={handleOnChange}
                            handleBlur={handleBlur}
                        />
                    </Col>
                    <Col md={4}>
                        <FormFieldBasic
                            key="toRange"
                            type="number"
                            maxLength={200}
                            fieldName="toRange"
                            fieldValue={values.toRange}
                            label={t("To")}
                            errors={errors}
                            touched={touched}
                            handleOnChange={handleOnChange}
                            handleBlur={handleBlur}
                        />
                    </Col>
                    <Col md={4}>
                        <FormFieldBasic
                            key="fee"
                            type="number"
                            maxLength={200}
                            fieldName="fee"
                            fieldValue={values.fee}
                            label={t("Fee")}
                            errors={errors}
                            touched={touched}
                            handleOnChange={handleOnChange}
                            handleBlur={handleBlur}
                        />
                    </Col>
                </Row>
                <div className="button-items">
                    <Button
                        type='button'
                        color="primary"
                        onClick={() => { submitForm(); }}>
                        <i className="mdi mdi-plus-circle me-1"></i>{t('Add')}
                    </Button>
                </div>
            </form>
        </React.Fragment>
    );
}

FeeForm.propTypes = {
    fee: PropTypes.object,
    setFee: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired
}

export default FeeForm;