import { ALERT_MESSAGE } from "constants/constants"
import { 
  httpDeleteAccount,
  httpDeleteUser,
  httpGetPrincipal,
  httpGetPrincipalByAccount,
  httpGetRolePrincipalListByPrincipal,
  httpPostNewUser,
  httpPostVerifyUser,
  httpSavePrincipalRoles,
  httpPostVerifyEmail,
  httpCancelUser
} from "helpers/Api/api-helper"
import { call, put, takeEvery } from "redux-saga/effects"
import { getAccounts, hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions"
import {
  addPrincipalSuccess,
  addPrincipalFail,
  getPrincipalDetailSuccess,
  getPrincipalDetailFail,
  getPrincipalDetailByAccountSuccess,
  getPrincipalDetailByAccountFail,
  savePrincipalRolesSuccess,
  savePrincipalRolesFail,
  getRolePrincipalListSuccess,
  getRolePrincipalListFail,
  getPrincipalByAccountSuccess,
  getPrincipalByAccountFail,
  verifyUserSuccess,
  verifyUserFail,
  verifyEmailSuccess,
  verifyEmailFail
} from "./actions"
import { 
  ADD_PRINCIPAL, DELETE_PRINCIPAL, CANCEL_PRINCIPAL, GET_PRINCIPAL_BY_ACCOUNT, GET_PRINCIPAL_DETAIL, GET_PRINCIPAL_DETAIL_BY_ACCOUNT, GET_ROLE_PRINCIPAL_LIST, SAVE_PRINCIPAL_ROLES, VERIFY_USER_INFORMATION,VERIFY_EMAIL_INFORMATION
 } from "./actionTypes"



function* addPrincipal(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpPostNewUser, request.payload)
    yield put(hideLoadingAlert())
    yield put(addPrincipalSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.ADD_ERROR))
    yield put(addPrincipalFail(error))
  }
}

function* fetchVerifyUser(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpPostVerifyUser, request.payload)
    yield put(hideLoadingAlert())
    yield put(verifyUserSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_DETAIL_ERROR))
    yield put(verifyUserFail(error))
  }
}

function* fetchVerifyEmail(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpPostVerifyEmail, request.payload)
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.USER_ALREADY_EXISTS))
    yield put(verifyEmailSuccess({existsIndicator: 'Y'}))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(verifyEmailSuccess({existsIndicator: 'N'}))
  }
}

function* getPrincipalDetail(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetPrincipal, request.payload)
    yield put(hideLoadingAlert())
    yield put(getPrincipalDetailSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_DETAIL_ERROR))
    yield put(getPrincipalDetailFail(error))
  }
}

function* getPrincipalDetailByAccount(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetPrincipalByAccount, request.payload)
    yield put(hideLoadingAlert())
    yield put(getPrincipalDetailByAccountSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_DETAIL_ERROR))
    yield put(getPrincipalDetailByAccountFail(error))
  }
}

function* savePrincipalRoles(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpSavePrincipalRoles, request.payload)
    yield put(hideLoadingAlert())
    yield put(savePrincipalRolesSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.ADD_ERROR))
    yield put(savePrincipalRolesFail(error))
  }
}

function* getPrincipalByAccount(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetPrincipalByAccount, request.payload)
    yield put(hideLoadingAlert())
    yield put(getPrincipalByAccountSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_DETAIL_ERROR))
    yield put(getPrincipalByAccountFail(error))
  }
}

function* getRolePrincipalListSaga(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpGetRolePrincipalListByPrincipal, request.payload);
    yield put(hideLoadingAlert())
    yield put(getRolePrincipalListSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getRolePrincipalListFail(error))
  }
}

function* deletePrincipal(request) {
  let hasError = false;
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpDeleteUser, request.payload)
    yield put(getAccounts())
    yield put(hideLoadingAlert())
  } catch (error) {
    hasError = true;
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.DELETE_ERROR))
  }
  if(!hasError){
    try {
      yield put(showLoadingAlert())
      yield call(httpDeleteAccount, request.payload)
      yield put(getAccounts())
      yield put(hideLoadingAlert())
    } catch (error) {
      yield put(hideLoadingAlert())
    }
  }
}

function* cancelPrincipal(request) {
  let hasError = false;
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpCancelUser, request.payload)
    yield put(hideLoadingAlert())
  } catch (error) {
    hasError = true;
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.DELETE_ERROR))
  }
  if(!hasError){
    try {
      yield put(showLoadingAlert())
      yield call(httpDeleteAccount, request.payload)
      yield put(getAccounts())
      yield put(hideLoadingAlert())
    } catch (error) {
      yield put(hideLoadingAlert())
    }
  }
}

function* principalsSaga() {
    yield takeEvery(ADD_PRINCIPAL, addPrincipal)
    yield takeEvery(VERIFY_USER_INFORMATION, fetchVerifyUser)
    yield takeEvery(VERIFY_EMAIL_INFORMATION, fetchVerifyEmail)
    yield takeEvery(GET_PRINCIPAL_DETAIL, getPrincipalDetail)
    yield takeEvery(GET_PRINCIPAL_DETAIL_BY_ACCOUNT, getPrincipalDetailByAccount)
    yield takeEvery(SAVE_PRINCIPAL_ROLES, savePrincipalRoles)
    yield takeEvery(GET_PRINCIPAL_BY_ACCOUNT, getPrincipalByAccount)
    yield takeEvery(GET_ROLE_PRINCIPAL_LIST, getRolePrincipalListSaga)
    yield takeEvery(DELETE_PRINCIPAL, deletePrincipal)
    yield takeEvery(CANCEL_PRINCIPAL, cancelPrincipal)
  }
  
  export default principalsSaga