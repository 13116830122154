import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody } from 'reactstrap';

const EmptyResult = () => {

    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    
                    <h6 className="display-7">
                        
                        <i className="mdi mdi-exclamation-thick text-primary display-7" />
                        <span className='display'>{t('Without Results')}</span>
                    </h6>
                </CardBody>
            </Card>
        </React.Fragment>
    );
}

export default EmptyResult;