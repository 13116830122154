import React, { useEffect, useState } from 'react';
import PropTypes from "prop-types";
import { Button, Card, Col, Row } from 'reactstrap';
import paginationFactory, { PaginationListStandalone, PaginationProvider } from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import BootstrapTable from 'react-bootstrap-table-next';
import verificationImg from "../../../assets/images/search-3.png"
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { clearAccount, laboratoriesSync } from "store/actions";
import { useDispatch, useSelector } from "react-redux";

const AffiliatedList = ({ affiliates, onSelectAffiliated, flagType }) => {

  const node = React.createRef();
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  let isFromAffiliated = false;
  const [filterAccount, setFilterAccount] = useState(null);
  const laboratoriesSyncSuccessStore = useSelector(state => state.accounts.laboratoriesSynctSuccess)



  useEffect(() => {
    if (laboratoriesSyncSuccessStore && !isFromAffiliated) {
      console.log(filterAccount)
      history.push(`/laboratory-patient-exam-historical/${filterAccount.accountCode}`);
    }
    if (laboratoriesSyncSuccessStore && isFromAffiliated) {
      console.log(filterAccount)
      onSelectAffiliated(filterAccount);
    }
    dispatch(clearAccount());
  }, [laboratoriesSyncSuccessStore]);



  const [contactListColumns] = useState([
    {
      dataField: "img",
      text: "",
      formatter: (cellContent, affiliated) => (
        <>
          {!affiliated.picture ? (
            <div className="avatar-xs">
              <span className="avatar-title rounded-circle">
                {affiliated.firstName.charAt(0)}{affiliated.firstSurname.charAt(0)}
              </span>
            </div>
          ) : (
            <div>
              <img
                className="rounded-circle avatar-xs"
                src={affiliated.picture}
                alt=""
              />
            </div>
          )}
        </>
      ),
    },
    {
      dataField: "identification",
      text: t('Identification'),
      sort: true,
    },
    {
      text: t('Name'),
      dataField: "fullName",
      sort: true,
      formatter: (cellContent, affiliated) => (
        <>
          <h5 className="font-size-14 mb-1">
            {affiliated.firstName + " "}
            {affiliated.firstSurname + " "}
            {affiliated.secondSurname ? affiliated.secondSurname : ""}
          </h5>
          <h6>{affiliated.email}</h6>
        </>
      ),
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "",
      sort: false,
      formatter: (cellContent, affiliated) => (
        flagType === "patientList"
          ? <div className="d-flex gap-3">
            <Button
              type="button"
              color="success"
              className="btn-md"
              onClick={() => { history.push(`/medical-add-prescription/${affiliated.accountCode}`); }}
            >
              {t('Register Prescription')}
            </Button>

            <Button
              type="button"
              color="success"
              className="btn-md"
              onClick={() => { history.push(`/laboratory-add-prescription/${affiliated.accountCode}`); }}
            >
              {t('Register Order')}
            </Button>

            <Button
              type="button"
              color="light"
              className="btn-md"
              onClick={() => { history.push(`/medical-prescription-historical/${affiliated.accountCode}`); }}
            >
              {t('View Orders')}
            </Button>

            <Button
              type="button"
              color="light"
              className="btn-md"
              onClick={async () => {
                isFromAffiliated = false;
                setFilterAccount(affiliated)
                await dispatch(laboratoriesSync(affiliated.accountCode))

                // await dispatch(clearAccount());
                // await history.push(`/laboratory-patient-exam-historical/${affiliated.accountCode}`);
              }}
            >
              {t('View Laboratories')}
            </Button>

          </div>
          : <Button
            type="button"
            color="success"
            className="btn-md"
            onClick={async () => {
              isFromAffiliated = true;
              setFilterAccount(affiliated)
              if (flagType === "examHistorical") {
                await dispatch(laboratoriesSync(affiliated.accountCode))
                // await dispatch(clearAccount());
                // await onSelectAffiliated(affiliated);
              } else {

              dispatch(clearAccount());
              onSelectAffiliated(affiliated);
                // isFromAffiliated = true;
                // setFilterAccount(affiliated)
                // await dispatch(laboratoriesSync(affiliated.accountCode))
              }
            }}
          >
            {flagType === "examHistorical" ? t('View Patient') : t('View Affiliate')} <i className="mdi mdi-arrow-right me-1"></i>
          </Button>
      ),
    }
  ]);

  const [pageOptions, setPageOptions] = useState({
    sizePerPage: 10,
    totalSize: 0,
    custom: true,
  });

  const defaultSorted = [
    {
      dataField: "id",
      order: "desc"
    },
  ];

  useEffect(() => {
    if (affiliates) {
      setPageOptions({
        ...pageOptions,
        totalSize: affiliates.length
      });
    }
  }, [affiliates]);


  return (
    <Card>

      {!affiliates || affiliates.length === 0 ? (

        <div className="text-center">
          <Row className="justify-content-center">
            <Col lg="10">
              <h5 className="mt-4 font-weight-semibold">
                {flagType === "patientList" ? t('Find Patients') : t('Find Affiliates')}
              </h5>
              <p className="text-muted mt-3">
                {flagType === "patientList" ? t('Find Patients Description') : t('Find Affiliates Description')}
              </p>

              <div className="mt-4">
              </div>
            </Col>
          </Row>

          <Row className="justify-content-center mt-5 mb-2">
            <Col sm="6" xs="8">
              <div>
                <img
                  src={verificationImg}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </Col>
          </Row>
        </div>

      ) : <b>&nbsp;</b>}

      {affiliates && affiliates.length > 0 ? (
        <div className="p-4 border-bottom ">
          <Row>
            <Col md="12">
              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="code"
                columns={contactListColumns}
                data={affiliates}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="code"
                    columns={contactListColumns}
                    data={affiliates}
                    search
                  >
                    {toolkitprops => (
                      <React.Fragment>
                        <Row>
                          <Col xs="12">
                            <div className="table-responsive">
                              <BootstrapTable
                                {...toolkitprops.baseProps}
                                {...paginationTableProps}
                                responsive
                                defaultSorted={defaultSorted}
                                bordered={false}
                                striped={false}
                                classes={
                                  "table align-middle table-nowrap table-check"
                                }
                                headerWrapperClasses={"table-light"}
                                ref={node}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2">
                            <PaginationListStandalone
                              {...paginationProps}
                            />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </Col>
          </Row>
        </div>

      ) : <b>&nbsp;</b>}

    </Card>
  );
}

AffiliatedList.propTypes = {
  affiliates: PropTypes.array.isRequired,
  onSelectAffiliated: PropTypes.func,
  flagType: PropTypes.string
};

export default AffiliatedList;