import React from "react"
import PropTypes from 'prop-types'
import { Col, Card, CardBody, CardImg, Button } from "reactstrap"
import { useTranslation } from "react-i18next";

const MembershipSummaryCard = ({membership, onSelect, selectText, getCurrencySymbol}) => {
  
    const benefitsList = membership?.benefits;
    const { t } = useTranslation();
    
    return (
      <React.Fragment>
        <Col xl="4" md="4">
          <Card className="plan-box">
            <CardBody className="p-4">
              <CardImg className="img-fluid" src={membership.picture} alt="Membership" />
              <div className="d-flex pt-5">
                <div className="flex-grow-1">
                  <h5>{membership.name}</h5>
                  <p className="text-muted">{membership.description}</p>
                </div>
                <div className="ms-3">
                  <i
                    className={"bx bx-gift h1 text-primary"}
                  />
                </div>
              </div>
              <div className="py-4">
                <h2>
                  <sup>
                    <small>{getCurrencySymbol(membership.currencyCode)}</small>
                  </sup>{" "}
                  {membership.price}/{" "}
                  <span className="font-size-13">{t('by month')}</span>
                </h2>
              </div>

              <div className="plan-features mt- 5">
                {benefitsList && benefitsList.map((benefit, i) => (
                  <p key={"membershipBenefit" + i}>
                    <i className="bx bx-checkbox-square text-primary me-2" />{" "}
                    {benefit.description} / {benefit.detail}
                  </p>
                ))}
              </div>
            </CardBody>
            <CardBody>
              <Button color="primary" className="button" onClick={() => { onSelect(); }}><i className="mdi mdi-arrow-right me-1"></i>{selectText}</Button>
            </CardBody>
          </Card>
        </Col>
      </React.Fragment>
    );
}

MembershipSummaryCard.propTypes = {
  membership: PropTypes.object,
  onSelect: PropTypes.func.isRequired,
  selectText: PropTypes.string.isRequired,
  getCurrencySymbol: PropTypes.func.isRequired
}

export default MembershipSummaryCard
