import { ALERT_MESSAGE } from "constants/constants"
import { 
  httpRequestVerificationCode, httpValidateVerificationCode, httpUpdatePassword
} from "helpers/Api/api-helper"
import { call, put, takeEvery } from "redux-saga/effects"
import { hideLoadingAlert, showLoadingAlert, showWarningAlert } from "store/actions"
import {
  changePasswordRequestCodeSuccess,
  changePasswordRequestCodeFail,
  changePasswordValidateCodeSuccess,
  changePasswordValidateCodeFail,
  changePasswordUpdateSuccess,
  changePasswordUpdateFail
} from "./actions"
import { 
  CHANGE_PASSWORD_REQUEST_CODE,
  CHANGE_PASSWORD_UPDATE,
  CHANGE_PASSWORD_VALIDATE_CODE
 } from "./actionTypes"

 function* changePasswordRequestCode(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpRequestVerificationCode, request.payload)
    yield put(hideLoadingAlert())
    yield put(changePasswordRequestCodeSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert('Email Not Exists'))
    yield put(changePasswordRequestCodeFail(error))
  }
}

function* changePasswordValidateCode(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpValidateVerificationCode, request.payload)
    yield put(hideLoadingAlert())
    yield put(changePasswordValidateCodeSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert('alert.verify.code.error'))
    yield put(changePasswordValidateCodeFail(error))
  }
}

function* changePasswordUpdate(request) {
  try {
    yield put(showLoadingAlert())
    const response = yield call(httpUpdatePassword, request.payload)
    yield put(hideLoadingAlert())
    yield put(changePasswordUpdateSuccess(response))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.UPDATE_ERROR))
    yield put(changePasswordUpdateFail(error))
  }
}

function* changePasswordSaga() {
  yield takeEvery(CHANGE_PASSWORD_REQUEST_CODE, changePasswordRequestCode)  
  yield takeEvery(CHANGE_PASSWORD_VALIDATE_CODE, changePasswordValidateCode)
  yield takeEvery(CHANGE_PASSWORD_UPDATE, changePasswordUpdate)
}
  
export default changePasswordSaga