import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCatalogs, getRelatedProducts } from "store/actions";
import ProductRelatedCard from "./product-related-card";
import ProductRelatedParentCard from "./product-related-parent-card";
import SweetAlert from "react-bootstrap-sweetalert";
import SearchProductForm from "../../../../components/Common/searchbox/product/search-product-form";

const ProductRelatedForm = ({ onSubmit, onBack }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [product, setProduct] = useState(null);
    const [relatedProducts, setRelatedProducts] = useState([]);
    const [showProductModal, setShowProductModal] = useState(false);
    const [showProductRelatedModal, setShowProductRelatedModal] = useState(false);
    const [showProductAdded, setShowProductAdded] = useState(false);

    useEffect(() => {
        dispatch(getCatalogs());
    }, []);

    const currencyCodeList = useSelector(
        state => state.catalogs.currencyCodeList
    );

    const productsList = useSelector(
        state => state.relatedProducts.products
    );

    useEffect(() => {
        if (productsList && productsList.length > 0) {
            setRelatedProducts(productsList);
        }
    }, [productsList]);

    const getCurrencySymbol = currencyCode => {
        const found = currencyCodeList.find(
            current => current.code == currencyCode
        );
        return found ? found.currency : currencyCode;
    };

    const onDelete = productDetail => {
        setRelatedProducts(
            relatedProducts.filter(current => current.relatedProduct.id != productDetail.relatedProduct.id)
        );
    };

    const prepareToSave = async () => {

        const products = relatedProducts.map(currentProduct => ({
            //id: currentProduct.id,
            productId: product.id,
            relatedProductId: currentProduct.relatedProduct.id
        }));

        onSubmit({
            productId: product.id,
            productRelatedDtoList: products
        });
    };

    const onSelect = productDetail => {
        setProduct(productDetail.product);
        setRelatedProducts([]);
        dispatch(getRelatedProducts({ productId: productDetail.product.id }));
    };

    const onAdd = productDetail => {
        const productIndex = relatedProducts.findIndex(
            product => product.relatedProduct.id == productDetail.product.id
        );
        if (productIndex < 0 && productDetail.product.id != product.id) {
            setRelatedProducts([
                ...relatedProducts,
                {
                    productId: product.id,
                    relatedProduct: {
                        ...productDetail.product
                    },
                    relatedProductId: productDetail.product.id
                }
            ]);

            setShowProductAdded(true);
        }

    };

    return (
        <React.Fragment>

            <Col xl={{ size: 10, offset: 1 }}>
                <Card>
                    <CardBody>
                        <Row>
                            <Col sm={12}>
                                <h4>{t('Main Product')}</h4>
                                <hr />
                            </Col>
                        </Row>

                        <Row>
                            {product ?
                                <ProductRelatedParentCard
                                    productDetail={product}
                                    getCurrencySymbol={getCurrencySymbol}
                                />
                                : null}
                        </Row>
                        <Row className="mt-2 mb-3">
                            <Col sm={12}>
                                <div className="button-items">
                                    <Button
                                        type="button"
                                        color="primary"
                                        onClick={() => {
                                            setShowProductModal(true);
                                        }}
                                    >
                                        <i className="mdi mdi-file-upload" /> {t("Load Product")}
                                    </Button>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm={12}>
                                <h4>{t('Related Products')}</h4>
                                <hr />
                            </Col>
                        </Row>


                        <Row>
                            <Col sm={12}>
                                {relatedProducts.length == 0 ? (
                                    <Row>
                                        <Col>
                                            <h5>{t("Without Products")}</h5>
                                        </Col>
                                    </Row>
                                ) : null}
                                {relatedProducts.map((productDetail, index) => (
                                    <ProductRelatedCard
                                        key={index}
                                        productDetail={productDetail}
                                        onDelete={onDelete}
                                        getCurrencySymbol={getCurrencySymbol}
                                    />
                                ))}
                            </Col>
                        </Row>
                        <Row className="mt-3">
                            <Col>
                                <div className="button-items">
                                    <Button
                                        type="button"
                                        color="primary"
                                        onClick={() => {
                                            setShowProductRelatedModal(true);
                                        }}
                                    >
                                        <i className="mdi mdi-plus-circle-outline" /> {t("Add Product")}
                                    </Button>

                                </div>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col sm={12}>
                                <div className="button-items">
                                    <Button type="button" color="primary" className="button" onClick={() => { prepareToSave(); }}>
                                        <i className="mdi mdi-content-save me-1"></i>
                                        {t("Save")}
                                    </Button>

                                    <Button
                                        color="light"
                                        className="button mr-1"
                                        onClick={() => {
                                            onBack();
                                        }}
                                    >
                                        <i className="mdi mdi-arrow-left me-1"></i>
                                        {t("Back")}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            {showProductModal ? (
                <SearchProductForm
                    key={'productModal'}
                    isOpen={showProductModal}
                    addProduct={onSelect}
                    getCurrencySymbol={getCurrencySymbol}
                    onCancel={() => {
                        setShowProductModal(false);
                    }}
                />
            ) : null}

            {showProductRelatedModal ? (
                <SearchProductForm
                    key={'productRelatedModal'}
                    isOpen={showProductRelatedModal}
                    addProduct={onAdd}
                    getCurrencySymbol={getCurrencySymbol}
                    onCancel={() => {
                        setShowProductRelatedModal(false);
                    }}
                />
            ) : null}

            {showProductAdded ?

                <SweetAlert
                    title={t('The product was added correctly')}
                    success
                    confirmBtnBsStyle="success"
                    confirmBtnText={t('Accept')}
                    onConfirm={() => {
                        setShowProductAdded(false);
                    }}
                >

                </SweetAlert>
                : null}
        </React.Fragment>
    );
};

ProductRelatedForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
};

export default ProductRelatedForm;
