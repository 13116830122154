import React, { useCallback } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardBody,
  Col,
  Row,
  Table
} from "reactstrap";
import profile1 from "assets/images/profile-img.png";
import images from "assets/images";
import { useTranslation } from "react-i18next";import { useSelector } from "react-redux";
import { toDateStringFormat } from "helpers/utils";
;

const CustomerProfile = ({customerProfile, hideAvatar}) => {
  
  
  const { t } = useTranslation();
  const { firstName, picture, secondName, firstSurname, secondSurname, email, identification, mainPhone, gender, maritalStatus, birthDate, accountCode } = customerProfile;
  const genderList = useSelector(state => state.catalogs.genderList);
  const maritalStatusList = useSelector(state => state.catalogs.maritalStatusList);
  
  const userProfile = {};
  const membershipList = customerProfile.memberships;
  const accontCode = customerProfile.code;

  const getGender = useCallback(genderCode => {
    const genderFiltered = genderList.find(current => current.code === genderCode);

    return genderFiltered? genderFiltered.description : genderCode;
  });

  const getMaritalStatus = useCallback(maritalStatusCode => {
    const maritalStatusFiltered = maritalStatusList.find(current => current.code === maritalStatusCode);

    return maritalStatusFiltered? maritalStatusFiltered.description : maritalStatusCode;
  });

  return (
    <Card className="overflow-hidden">
      <div className="bg-soft">
        <Row>
          <Col xs="7">
            <div className="text-primary p-3">
              
            </div>
          </Col>
          <Col xs="5" className="align-self-end">
            
          </Col>
        </Row>
      </div>
      <CardBody className="pt-0">
        <Row>

          {!hideAvatar ? (
          <Col md="1">
            <div className="avatar-md profile-user-wid mb-4">
              <img
                src={picture ? picture: images['avatar']}
                alt=""
                className="img-thumbnail rounded-circle"
              />
            </div>
          </Col>
          ) : null}

          <Col md="11">
            <h5 className="font-size-15 text-truncate">
            {firstName} {secondName} {firstSurname} {secondSurname}
            </h5>
            <p className="text-muted mb-0 text-truncate">
              {email}
            </p>
          </Col>
        </Row>

        <Row>
        <p className="text-muted mb-4">
          {userProfile.personalDetail}
        </p>
        <div className="table-responsive">
          <Table className="table-nowrap mb-0">
            <tbody>
            <tr>
                <th scope="row">{t('Identification')}:</th>
                <td><b>{identification}</b></td>
              </tr>
              <tr>
                <th scope="row">{t('Mobile')}:</th>
                <td><b>{mainPhone}</b></td>
              </tr>
              <tr>
                <th scope="row">{t('Genre')}:</th>
                <td><b>{getGender(gender)}</b></td>
              </tr>
              <tr>
                <th scope="row">{t('Marital Status')}:</th>
                <td><b>{getMaritalStatus(maritalStatus)}</b></td>
              </tr>
              <tr>
                <th scope="row">{t('GP Code')}:</th>
                <td style={{ color: "lightgrey" }}>{accountCode}</td>
              </tr>
            </tbody>
          </Table>
        </div>
        </Row>
      </CardBody>
    </Card>
  );
  
}

CustomerProfile.propTypes = {
  customerProfile: PropTypes.object,
  hideAvatar: PropTypes.bool
};

export default CustomerProfile;