import MembershipSummaryCard from 'pages/Management/Commons/membership-card/membership-summary';
import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row } from 'reactstrap';
import { getMemberships } from 'store/actions';
import upgradeMembershipContext from '../context/upgradeMembershipContext';

const MembershipSelect = ({ onNext }) => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { t } = useTranslation();

    const membershipContext = useContext(upgradeMembershipContext);
    const { setMembership, getCurrencySymbol } = membershipContext;

    const membershipList = useSelector(state => state.memberships.membershipList);

    useEffect(() => {
        dispatch(getMemberships());
    }, []);

    const selectMembership = membership => {
        setMembership(membership);
    }

    return (
        <React.Fragment>
            <Row>
                {membershipList && membershipList.map((currentMembership, i) => (
                    <MembershipSummaryCard
                        history={history}
                        membership={currentMembership}
                        key={"membershipSummaryCard" + i} 
                        selectText={t('Select')} 
                        onSelect={() => { selectMembership(currentMembership); onNext(); }} 
                        getCurrencySymbol={getCurrencySymbol}    
                    />
                ))}
            </Row>
        </React.Fragment>
    );
}

MembershipSelect.propTypes = {
    onNext: PropTypes.func
}

export default MembershipSelect;