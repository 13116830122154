/* ACCOUNT */
export const CLEAR_ACCOUNT_STATE = "CLEAR_ACCOUNT_STATE" 
export const LABORATORIES_SYNC = "LABORATORIES_SYNC"
export const LABORATORIES_SYNC_SUCCESS = "LABORATORIES_SYNC_SUCCESS"
export const LABORATORIES_SYNC_FAIL = "LABORATORIES_SYNC_FAIL"




export const GET_ACCOUNT_DETAIL = "GET_ACCOUNT_DETAIL"
export const GET_ACCOUNT_DETAIL_FAIL = "GET_ACCOUNT_DETAIL_FAIL"
export const GET_ACCOUNT_DETAIL_SUCCESS = "GET_ACCOUNT_DETAIL_SUCCESS"

export const GET_ACCOUNT_DETAIL_LOGIN = "GET_ACCOUNT_DETAIL_LOGIN"
export const GET_ACCOUNT_DETAIL_LOGIN_FAIL = "GET_ACCOUNT_DETAIL_LOGIN_FAIL"
export const GET_ACCOUNT_DETAIL_LOGIN_SUCCESS = "GET_ACCOUNT_DETAIL_LOGIN_SUCCESS"

export const GET_ACCOUNT_LIST = "GET_ACCOUNT_LIST"
export const GET_ACCOUNT_LIST_FAIL = "GET_ACCOUNT_LIST_FAIL"
export const GET_ACCOUNT_LIST_SUCCESS = "GET_ACCOUNT_LIST_SUCCESS"

export const UPDATE_ACCOUNT = "UPDATE_ACCOUNT"
export const UPDATE_ACCOUNT_FAIL = "UPDATE_ACCOUNT_FAIL"
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS"

export const DELETE_ACCOUNT = "DELETE_ACCOUNT"
export const CANCEL_ACCOUNT = "CANCEL_ACCOUNT"

/* DATES */

export const GET_DATE_FROM = "GET_DATE_FROM"
export const GET_DATE_TO = "GET_DATE_TO"