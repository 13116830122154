import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';
import DataTable from 'components/Common/DataTable/datatable';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const AffiliatedBeneficiaries = ({ beneficiaries, deleteRow }) => {

    const consanguinityLevelList = useSelector(state => state.catalogs.consanguinityLevelList);
    const { t } = useTranslation();
    const history = useHistory();
    const { account, membershipAffiliatedId } = useParams();
    const [pageOptions, setPageOptions] = useState({
        sizePerPage: 10,
        totalSize: 0,
        custom: true
    });

    const columns = [
        {
            text: t('Identification'),
            dataField: 'identification',
            sort: true,
            formatter: (cellContent, row) => <React.Fragment>{row.identification}</React.Fragment>
        },
        {
            text: t('Name'),
            dataField: 'fullName',
            sort: true,
            formatter: (cellContent, row) => <React.Fragment>{row.fullName}</React.Fragment>
        },
        {
            text: t('Mobile'),
            dataField: 'mobile',
            sort: true,
            formatter: (cellContent, row) => {
                //const unitType = businessUnitTypes.find(current => current.code == row.businessUnitType);
                //return (<React.Fragment>{unitType ? unitType.value : row.businessUnitType}</React.Fragment>);
                return (<React.Fragment>{row.mainPhone}</React.Fragment>);
            }
        },
        {
            text: t('Email'),
            dataField: 'email',
            sort: true,
            formatter: (cellContent, row) => {
                //const unitType = businessUnitTypes.find(current => current.code == row.businessUnitType);
                //return (<React.Fragment>{unitType ? unitType.value : row.businessUnitType}</React.Fragment>);
                return (<React.Fragment>{row.email}</React.Fragment>);
            }
        },
        {
            text: t('Relation'),
            dataField: 'consanguinityLevel',
            sort: true,
            formatter: (cellContent, row) => {
                const level = consanguinityLevelList.find(current => current.code == row.consanguinityLevel);
                //return (<React.Fragment>{unitType ? unitType.value : row.businessUnitType}</React.Fragment>);
                return (<React.Fragment>{level ? level.description : ''}</React.Fragment>);
            }
        },
        {
            dataField: 'menu',
            isDummyField: true,
            editable: false,
            text: '',
            formatter: (cellContent, row) => (
                <div className="d-flex gap-3">
                    <Link className="text-success" to="#">
                        <i
                            className="mdi mdi-pencil font-size-18"
                            id="edittooltip"
                            onClick={() => editRow(row)}
                        ></i>
                    </Link>
                    <Link className="text-danger" to="#">
                        <i
                            className="mdi mdi-delete font-size-18"
                            id="deletetooltip"
                            onClick={() => deleteRow(row)}
                        ></i>
                    </Link>
                </div>
            ),
        }
    ];
    const defaultSorted = [
        {
            dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
            order: "desc", // desc or asc
        }
    ];

    const editRow = row => {
        history.push(`/membership-beneficiary-edit/${account}/${membershipAffiliatedId}/${row.id}`);
    };

    return (
        <React.Fragment>
            <Row>
                <Col lg={12}>
                    <DataTable
                        columns={columns}
                        data={beneficiaries}
                        keyField='id'
                        sort={defaultSorted}
                    />
                </Col>
            </Row>
        </React.Fragment>
    );
}

AffiliatedBeneficiaries.propTypes = {
    beneficiaries: PropTypes.array.isRequired,
    deleteRow: PropTypes.func.isRequired
}
export default AffiliatedBeneficiaries;