import React from 'react';
import PropTypes from 'prop-types'
import { Button, Modal } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import AffiliatedSearch from 'components/Common/AffiliatedSearch/affiliated-search';

const PrescriptionSearchPatientForm = ({ isOpen, selectPatient, onCancel }) => {

    const { t } = useTranslation();

    return (
        <React.Fragment>
            <Modal
                size='xl'

                isOpen={isOpen}
                toggle={() => { onCancel() }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {t('Patients Search')}
                    </h5>
                    <button
                        type="button"
                        onClick={() => onCancel()}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div className="modal-body">
                    <AffiliatedSearch
                        onSelectAffiliated={selectPatient}
                    />
                </div>

                <div className="modal-footer button-items mt-1">
                    {/*<Button type='submit' color="primary" className="button"><i className="mdi mdi-content-save me-1"></i>{t('Save')}</Button>*/}
                    <Button color="light" className="button mr-1" onClick={() => { onCancel(); }}><i className="mdi mdi-close-thick me-1"></i>{t('Close')}</Button>
                </div>

            </Modal>
        </React.Fragment>
    );
}

PrescriptionSearchPatientForm.propTypes = {
    selectPatient: PropTypes.func,
    onCancel: PropTypes.func,
    isOpen: PropTypes.bool
}

export default PrescriptionSearchPatientForm;