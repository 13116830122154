import React, { useCallback } from 'react';
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { numberFormat } from 'helpers/utils';

const MedicalServiceCard = ({ service, onSelect }) => {

    const { t } = useTranslation();
    const currencyCodeList = useSelector(state => state.catalogs.currencyCodeList);

    const getCurrencySymbol = useCallback((currencyCode) => {
        const currency = currencyCodeList.find(current => current.code === currencyCode);
        return currency ? currency.currency : currencyCode;
    });

    return (
        <React.Fragment>
            <Col sm={6} md={3}>
                <Card className='task-box dashboard-option service-card' onClick={() => { onSelect(service) }}>
                    <CardBody>
                        <Row>
                            <Col xs={12}>
                                <h5>{service.description}</h5>
                                <h6>{t('Consultation Fee')}: {getCurrencySymbol(service.currencyCode)}{numberFormat(service.price)}</h6>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
}

MedicalServiceCard.propTypes = {
    service: PropTypes.object,
    onSelect: PropTypes.func
};

export default MedicalServiceCard;