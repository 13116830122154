import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import DataTable from 'components/Common/DataTable/datatable';
import { useTranslation } from 'react-i18next';
import { benefitTypes, businessUnitTypes, currencyTypes } from 'constants/list-types';
import { Button } from 'reactstrap';
import BenefitHistoricalModal from './benefit-historical-modal';
import SweetAlert from 'react-bootstrap-sweetalert';
import { addMembershipAffiliatedBenefit, getAccountDetailLogin } from 'store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { getAuthUser, getFullName, toFullDateISOStringFormat } from 'helpers/utils';

const AffiliatedMembershipBenefits = ({ membershipAffiliatedId, benefits, membershipAffiliatedBenefitList }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [data, setData] = useState([]);
    const [showHistorical, setShowHistorical] = useState(false);
    const [showApply, setShowApply] = useState(false);
    const [selectedBenefit, setSelectedBenefit] = useState(null);
    const [historical, setHistorical] = useState([]);
    const customerProfile = useSelector(state => state.affiliateds.affiliated);
    const accountDetail = useSelector(state => state.accounts.accountLogin);

    const defaultSorted = [
        {
            dataField: 'description', // if dataField is not match to any column you defined, it will be ignored.
            order: 'asc', // desc or asc
        },
    ];

    const columns = [{
        text: t('Business Unit'),
        dataField: 'businessUnitType',
        sort: true,
        formatter: (cellContent, row) => <React.Fragment>{row.businessUnitType}</React.Fragment>
    }, 
    {
        text: t('Description'),
        dataField: 'description',
        sort: true,
        formatter: (cellContent, row) => <React.Fragment>{row.description}</React.Fragment>
    },
    {
        text: t('Detail'),
        dataField: 'detail',
        sort: true,
        formatter: (cellContent, row) => <React.Fragment>
             <div style={{whiteSpace: 'pre-wrap', overflowWrap: 'break-word'}}>
            <p>{row.detail}
                </p></div>
        </React.Fragment>
    },
    {
        text: t('Type'),
        dataField: 'type',
        sort: true,
        formatter: (cellContent, row) => <React.Fragment>{row.type}</React.Fragment>
    },
    {
        text: t('Quantity'),
        dataField: 'value',
        sort: true,
        formatter: (cellContent, row) => <React.Fragment>{row.value}</React.Fragment>
    },
    {
        text: t('Remaining amount'),
        dataField: 'remaining',
        sort: true,
        formatter: (cellContent, row) => <React.Fragment>{row.remaining}</React.Fragment>
    },
    {
        dataField: 'menu',
        isDummyField: true,
        editable: false,
        text: '',
        formatter: (cellContent, row) => (
            <div className="d-flex gap-3">
                <Button
                    type="button"
                    color="success"
                    className="btn-md"
                    onClick={() => viewHistorical(row)}
                >
                    <i className="mdi mdi-history me-1"></i>{t('Historical')}
                </Button>

                <Button
                    type="button"
                    color="primary"
                    className="btn-md"
                    onClick={() => viewApplyBenefit(row)}
                    disabled={!row.isAvailable}
                >
                    <i className="mdi mdi-check-all me-1"></i>{t('Apply')}
                </Button>
            </div>
        ),
    }];

    useEffect(() => {
        const authUser = getAuthUser();
        if(authUser) {
            dispatch(getAccountDetailLogin({ accountCode: authUser.sub }));
        }
    }, []);

    useEffect(() => {
        if (benefits && membershipAffiliatedBenefitList) {
            setData(benefits.map(currentBenefit => { 
            
                const historical = membershipAffiliatedBenefitList.filter(currentBenefitHistorical => currentBenefitHistorical.membershipAffiliatedDetail.id === currentBenefit.id);
                
                return {
                    id: currentBenefit.id,
                    description: t(currentBenefit.description),
                    detail: currentBenefit.detail,
                    businessUnitType: getBusinessUnitType(currentBenefit.businessUnitType),
                    type: getBenefitType(currentBenefit.type),
                    restrictionLimit: currentBenefit.restrictionLimit,
                    value: currentBenefit.value,
                    remaining: currentBenefit.type == 'QTY' ? ((currentBenefit.value ? currentBenefit.value : 0) - historical.length) : '-',
                    historical: historical,
                    isAvailable: currentBenefit.type == 'QTY' ? (((currentBenefit.value ? currentBenefit.value : 0) - historical.length) > 0) : currentBenefit.type == 'TIM',
                };
            }));
        }
    }, [benefits, membershipAffiliatedBenefitList]);

    const viewHistorical = benefit => {
        const selected = benefits.find(current=> current.id == benefit.id);
        setSelectedBenefit(selected);
        setHistorical(benefit.historical);
        setShowHistorical(true);
    }

    const viewApplyBenefit = benefit => {
        const selected = benefits.find(current=> current.id == benefit.id);
        setSelectedBenefit(selected);
        setShowApply(true);
    }

    const applyBenefit = () => {
        
        console.info(selectedBenefit);
        
        const payload = {
            membershipAffiliatedId: membershipAffiliatedId,
            countryCode: customerProfile.countryCode,
            companyId: customerProfile.companyId,
            membershipAffiliatedDetailId: selectedBenefit.id,
            itemCode: selectedBenefit.itemCode,
            description: selectedBenefit.description,
            currencyCode: selectedBenefit.currencyCode,
            businessUnitType: selectedBenefit.businessUnitType,
            type: selectedBenefit.type,
            usageQuantity: 1,
            unitPrice: 0,
            registeredByAccount: customerProfile.accountCode,
            registeredByFullname: getFullName(customerProfile.firstName, customerProfile.secondName, customerProfile.firstSurname, customerProfile.secondSurname),
            registeredByIdentification: customerProfile.identification,
            registeredByEmail: customerProfile.email,
            usageChannel: 'Portal Admin',
            usageDate: toFullDateISOStringFormat(Date.now()),
            userEmail: accountDetail.email
        };

        dispatch(addMembershipAffiliatedBenefit(payload));
        setShowApply(false);
    }

    const getBenefitType = code => {
        const selected = benefitTypes.find(current => current.code === code);
        return selected ? t(selected.value) : '';
    }

    const getBusinessUnitType = code => {
        const selected = businessUnitTypes.find(current => current.code === code);
        return selected ? t(selected.value) : '';
    }

    return (
        <React.Fragment>
            <DataTable
                columns={columns}
                data={data}
                keyField={'id'}
                sort={defaultSorted}
            />
            {showHistorical ?
                <BenefitHistoricalModal
                    isOpen={showHistorical}
                    benefits={historical}
                    onCancel={() => { setShowHistorical(false); }}
                />
                : null}
            {showApply ? (
                <SweetAlert
                    title={t("confirm benefit apply")}
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    confirmBtnText={t("Yes")}
                    cancelBtnText={t("No")}
                    onConfirm={() => {
                        applyBenefit();
                    }}
                    onCancel={() => {
                        setShowApply(false);
                    }}
                ></SweetAlert>
            ) : null}
        </React.Fragment>
    );
}

AffiliatedMembershipBenefits.propTypes = {
    membershipAffiliatedId: PropTypes.number.isRequired,
    benefits: PropTypes.array.isRequired,
    membershipAffiliatedBenefitList: PropTypes.array.isRequired
}

export default AffiliatedMembershipBenefits;