import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { getCatalogs } from "store/actions";
import { Button, Col, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import PromotionProductCard from "./promotion-product-card";
import SweetAlert from "react-bootstrap-sweetalert";
import SearchProductForm from "components/Common/searchbox/product/search-product-form";
import PromotionSearchServiceForm from "./promotion-search-service-form";

const PromotionProducts = ({ products, setProducts, businessUnitType }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getCatalogs());
  }, []);

  const [showModal, setShowModal] = useState(false);
  const [showProductAdded, setShowProductAdded] = useState(false);

  const currencyCodeList = useSelector(
    state => state.catalogs.currencyCodeList
  );

  const getCurrencySymbol = currencyCode => {
    const found = currencyCodeList.find(
      current => current.code == currencyCode
    );
    return found ? found.currency : currencyCode;
  };

  const onDelete = productDetail => {
    setProducts(
      products.filter(current => current.itemCode != productDetail.itemCode)
    );
  };

  const onUpdate = productDetail => {
    const productIndex = products.findIndex(
      product => product.itemCode == productDetail.itemCode
    );
    if (productIndex >= 0) {
      const copy = [...products];
      copy[productIndex] = {
        ...copy[productIndex],
        quantity: productDetail.quantity,
        doctorPrescription: productDetail.doctorPrescription,
      };
      setProducts(copy);
    }
  };

  const onUpdateQuantity = (productDetail, quantity) => {
    
    const productIndex = products.findIndex(
      product => product.itemCode == productDetail.id
    );
    if (productIndex >= 0) {
      const copy = [...products];
      copy[productIndex] = {
        ...copy[productIndex],
        quantity: copy[productIndex].quantity + quantity,
      };
      setProducts(copy);
    }
  };

  const onAdd = productDetail => {
    
    const productIndex = products.findIndex(
      product => product.itemCode == productDetail.product.id
    );
    if (productIndex >= 0) {
      const copy = [...products];
      copy[productIndex] = {
        ...copy[productIndex],
        //quantity: copy[productIndex].quantity + productDetail.quantity,
      };
      setProducts(copy);
    } else {
      setProducts([
        ...products, 
        {
          ...productDetail.product, 
          itemCode: productDetail.product.id, 
          description: productDetail.product.description, 
          unitPrice: productDetail.product.price, 
          quantity: 1, 
          taxCode: productDetail.product.taxPercentage,
          discountPercentage: productDetail.product.discount
        }
      ]);
    }
    setShowProductAdded(true);
  };

  const onAddService = services => {
    
    let data = [...products];

    services.map(service => {

      const productIndex = products.findIndex(
        product => product.itemCode == service.id
      );

      if (productIndex >= 0) {

        data[productIndex] = {
          ...data[productIndex],
          //quantity: copy[productIndex].quantity + productDetail.quantity,
        };
        
      } else {
        data.push({
          //...service,
          itemCode: service.id, 
          currencyCode: service.currencyCode,
          description: service.description, 
          unitPrice: service.price, 
          quantity: 1, 
          taxCode: service.taxPercentage,
          doctorPrescription: service.medicalInstruction,
          discountPercentage: service.discount
        });
      }
    });

    setProducts(data);
    
    setShowProductAdded(true);
  };

  return (
    <React.Fragment>
      {products.length == 0 ? (
        <Row>
          <Col>
            <h5>{businessUnitType == 'PHA' ? t("Without Products") : t("Without Services")}</h5>
          </Col>
        </Row>
      ) : null}
      {products.map((productDetail, index) => (
        <PromotionProductCard
          key={index}
          productDetail={productDetail}
          onDelete={onDelete}
          getCurrencySymbol={getCurrencySymbol}
        />
      ))}
      <Row className="mt-1">
        <Col>
          <Button
            type="button"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              setShowModal(true);
            }}
            disabled={businessUnitType == ''}
          >
            <i className="mdi mdi-plus-circle" /> {businessUnitType == 'PHA' ? t("Add Product") : t('Add Service')}
          </Button>
        </Col>
      </Row>
      {showModal ? ( businessUnitType == 'PHA' ? 
        <SearchProductForm
          isOpen={showModal}
          addProduct={onAdd}
          getCurrencySymbol={getCurrencySymbol}
          onCancel={() => {
            setShowModal(false);
          }}
        />
        :

        <PromotionSearchServiceForm
          isOpen={showModal}
          addService={onAddService}
          getCurrencySymbol={getCurrencySymbol}
          businessUnitType={businessUnitType}
          onCancel={() => {
            setShowModal(false);
          }}
        />

      ) : null}
      {showProductAdded ?

        <SweetAlert
          title={businessUnitType == 'PHA' ? t('The product was added correctly') : t('The service was added correctly')}
          success
          confirmBtnBsStyle="success"
          confirmBtnText={t('Accept')}
          onConfirm={() => {
            setShowProductAdded(false);
          }}
        >

        </SweetAlert>
        : null}
    </React.Fragment>
  );
};

PromotionProducts.propTypes = {
  products: PropTypes.array.isRequired,
  setProducts: PropTypes.func.isRequired,
  businessUnitType: PropTypes.string.isRequired
};

export default PromotionProducts;
