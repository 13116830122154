import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { MetaTags } from 'react-meta-tags';
import { Button, Container } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getLaboratoryOrderDetail, sendLaboratoryExamResult } from 'store/actions';
import ExamPatientInfoCard from '../Chart/exam-patient-info-card';
import ExamPatientResultTabs from './exam-patient-result-tabs';
import PageTitle from 'components/Common/PageLayout/page-title';
import PageHeader from 'components/Common/PageLayout/page-header';
import { downloadFile } from 'helpers/utils';
import PageBody from 'components/Common/PageLayout/page-body';


const ExamPatientResultPage = () => {

    const { appointmentId, account, resultId} = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const order = useSelector(state => state.laboratoryOrders.orderDetail);

    const getResult = () => {
        return order.laboratoryOrders[0].laboratoryOrderResults.find(itemResult => itemResult.id === Number(resultId))
    }

    useEffect(() => {
        if (appointmentId) {
            dispatch(getLaboratoryOrderDetail({ id: appointmentId }));
        }
    }, [appointmentId]);

    const download = () => {
        if (order && order.laboratoryOrders && order.laboratoryOrders.length > 0) {
            const { resultFileLocation } = getResult();
            downloadFile(resultFileLocation, t('Result'));
        }
    }

    const sendEmail = () => {
        dispatch(sendLaboratoryExamResult({ id: id }));
    }

    return (
        <PageBody
            title='Exams Historical'
            actions={
                <>
                    <Button
                        color="primary"
                        className="button"
                        onClick={() => { sendEmail() }}>
                        {t('Send by email')} <i className="mdi mdi-email-send me-1"></i>
                    </Button>

                    <Button
                        color="primary"
                        className="button"
                        onClick={() => { download() }}>
                        {t('Download')} <i className="mdi mdi-cloud-download me-1"></i>
                    </Button>

                    <Button
                        color="light"
                        className="button"
                        onClick={() => { history.goBack();}}>
                        <i className="mdi mdi-arrow-left me-1"></i>{t('Back')}
                    </Button>
                </>
            }
        >
            {/* Patient Info Card */}
            {order ? <ExamPatientInfoCard order={order} result={Number(resultId)} /> : null}

            {/* Result Tabs */}
            {order ? <ExamPatientResultTabs order={order} result={Number(resultId)} /> : null}
        </PageBody>
    );

}

export default ExamPatientResultPage;
