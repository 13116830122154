import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { Row, Col, Button, Modal, Label } from 'reactstrap';
import { ErrorMessage, Form, Formik } from 'formik';
import * as Yup from 'yup';
import FormField from 'pages/Utility/form-field';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import InputMask from "react-input-mask"

const MembershipBeneficiaryForm = ({isOpen, onSubmit, onCancel, beneficiary}) => {

    const { t } = useTranslation();

    const genderList = useSelector(state => state.catalogs.genderList);
    const consanguinityLevelList = useSelector(state => state.catalogs.consanguinityLevelList);
    const identificationTypeList = useSelector(state => state.identificationTypes.identificationTypeList);
    
    const [identificationTypeSelected, setIdentificationTypeSelected] = useState(null);

    const [formData, setFormData] = useState({
        identificationTypeId: beneficiary && beneficiary.identificationTypeId ? beneficiary.identificationTypeId : '',
        identification: beneficiary && beneficiary.identification ? beneficiary.identification : '',
        firstName: beneficiary && beneficiary.firstName ? beneficiary.firstName : '',
        secondName: beneficiary && beneficiary.secondName ? beneficiary.secondName : '',
        firstSurname: beneficiary && beneficiary.firstSurname ? beneficiary.firstSurname : '',
        secondSurname: beneficiary && beneficiary.secondSurname ? beneficiary.secondSurname : '',
        email: beneficiary && beneficiary.email ? beneficiary.email : '',
        mainPhone: beneficiary && beneficiary.mainPhone ? beneficiary.mainPhone : '',
        secondaryPhone: beneficiary && beneficiary.secondaryPhone ? beneficiary.secondaryPhone : '',
        gender: beneficiary && beneficiary.gender ? beneficiary.gender : '',
        consanguinityLevel: beneficiary && beneficiary.consanguinityLevel ? beneficiary.consanguinityLevel : ''
    }); 
    
    const { 
        identificationTypeId, 
        identification, firstName, secondName, firstSurname, secondSurname,
        email, mainPhone, secondaryPhone, gender, consanguinityLevel } = formData;

    const handleOnChange = event => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }

    const prepareFormat = format => {
        return format.replace(/#/gi, '9').replace(/@/gi, '\*');
    }

    const handleOnChangeIdentificationType = event => {
        setIdentificationTypeSelected(identificationTypeList.find(current => current.id == event.target.value));
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    }

    useEffect(() => {
        if(beneficiary) {
            setIdentificationTypeSelected(identificationTypeList.find(current => current.id == beneficiary.identificationTypeId));
        }
    }, [beneficiary]);

    return ( 
        <React.Fragment>
            <Modal
                isOpen={isOpen}
                toggle={() => {onCancel()}}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {beneficiary? t('Edit Beneficiary') : t('New Beneficiary')}
                    </h5>
                    <button
                        type="button"
                        onClick={() => onCancel()}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
             
                <Formik
                    enableReinitialize={true}
                    initialValues={formData}
                    validationSchema={
                        Yup.object().shape({
                            identificationTypeId: Yup.string().required(),
                            identification: Yup.string().required(),
                            firstName: Yup.string().required(),
                            secondName: Yup.string(),
                            firstSurname: Yup.string().required(),
                            secondSurname: Yup.string().required(),
                            email: Yup.string().required().email(),
                            mainPhone: Yup.string().required(),
                            secondaryPhone: Yup.string(),
                            gender: Yup.string().required(),
                            consanguinityLevel: Yup.string().required()
                        })
                    }
                    onSubmit={(values, actions) => {
                        actions.setSubmitting(false);
                        onSubmit(formData);
                    }}
                >
                {({ errors, status, touched }) => (
                    <Form className='needs-validation' >
                        <div className="modal-body">
                            <Row>

                                <Col md={6}>

                                    <FormField
                                        key='identificationTypeId'
                                        type='select'
                                        fieldName='identificationTypeId'
                                        fieldValue={identificationTypeId}
                                        label={t('Identification Type')}
                                        errors={errors}
                                        touched={touched}
                                        handleOnChange={handleOnChangeIdentificationType}
                                    >
                                        <option key="identificationTypeNotSelected" value="">{t('Not Selected')}</option>
                                                {identificationTypeList.map(current => {
                                                    return (<option key={current.id} value={current.id}>{t(current.name)}</option>);
                                                })}
                                    </FormField>
                                    
                                </Col>
                                <Col md={6}>
                                    
                                    <div className='mb-3'>
                                        <Label className="form-label">{t('Identification')}</Label>
                                        <InputMask 
                                            type="text"
                                            mask={identificationTypeSelected? prepareFormat(identificationTypeSelected.format) : ''}
                                            className={"form-control input-mask" + (errors.identification && touched.identification ? " is-invalid": "")} 
                                            name="identification"
                                            value={identification}
                                            onChange={handleOnChange}
                                        />
                                        <ErrorMessage
                                            name="identification"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>

                                </Col>

                            </Row>
                            <Row>
                                <Col md={6}>
                                    
                                    <FormField
                                        key='firstName'
                                        type='text'
                                        maxLength={50}
                                        fieldName='firstName'
                                        fieldValue={firstName}
                                        label={t('First Name')}
                                        errors={errors}
                                        touched={touched}
                                        handleOnChange={handleOnChange}
                                    />
                                    
                                </Col>
                                <Col md={6}>

                                    <FormField
                                        key='secondName'
                                        type='text'
                                        maxLength={50}
                                        fieldName='secondName'
                                        fieldValue={secondName}
                                        label={t('Second Name')}
                                        errors={errors}
                                        touched={touched}
                                        handleOnChange={handleOnChange}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    
                                    <FormField
                                        key='firstSurname'
                                        type='text'
                                        maxLength={50}
                                        fieldName='firstSurname'
                                        fieldValue={firstSurname}
                                        label={t('First Surname')}
                                        errors={errors}
                                        touched={touched}
                                        handleOnChange={handleOnChange}
                                    />
                                    
                                </Col>
                                <Col md={6}>

                                    <FormField
                                        key='secondSurname'
                                        type='text'
                                        maxLength={50}
                                        fieldName='secondSurname'
                                        fieldValue={secondSurname}
                                        label={t('Second Surname')}
                                        errors={errors}
                                        touched={touched}
                                        handleOnChange={handleOnChange}
                                    />
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>
                                    
                                    <FormField
                                        key='email'
                                        type='text'
                                        maxLength={100}
                                        fieldName='email'
                                        fieldValue={email}
                                        label={t('Email')}
                                        errors={errors}
                                        touched={touched}
                                        handleOnChange={handleOnChange}
                                    />
                                    
                                </Col>
                                
                            </Row>

                            <Row>
                                <Col md={6}>
                                    <div className='mb-3'>
                                        <Label className="form-label">{t('Main Phone')}</Label>
                                        <InputMask 
                                            type="text"
                                            mask={'9999-9999'}
                                            className={"form-control input-mask" + (errors.mainPhone && touched.mainPhone ? " is-invalid": "")} 
                                            name="mainPhone"
                                            value={mainPhone}
                                            onChange={handleOnChange}
                                        />
                                        <ErrorMessage
                                            name="mainPhone"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    
                                </Col>
                                <Col md={6}>

                                    <div className='mb-3'>
                                        <Label className="form-label">{t('Secondary Phone')}</Label>
                                        <InputMask 
                                            type="text"
                                            mask={'9999-9999'}
                                            className={"form-control input-mask" + (errors.secondaryPhone && touched.secondaryPhone ? " is-invalid": "")} 
                                            name="secondaryPhone"
                                            value={secondaryPhone}
                                            onChange={handleOnChange}
                                        />
                                        <ErrorMessage
                                            name="secondaryPhone"
                                            component="div"
                                            className="invalid-feedback"
                                        />
                                    </div>
                                    
                                </Col>
                            </Row>

                            <Row>
                                <Col md={6}>

                                    <FormField
                                        key='gender'
                                        type='select'
                                        fieldName='gender'
                                        fieldValue={gender}
                                        label={t('Genre')}
                                        errors={errors}
                                        touched={touched}
                                        handleOnChange={handleOnChange}
                                    >
                                        <option key="genderNotSelected" value="">{t('Not Selected')}</option>
                                                {genderList.map(current => {
                                                    return (<option key={current.code} value={current.code}>{t(current.description)}</option>);
                                                    })}
                                    </FormField>

                                </Col>
                                <Col md={6}>

                                    <FormField
                                        key='consanguinityLevel'
                                        type='select'
                                        fieldName='consanguinityLevel'
                                        fieldValue={consanguinityLevel}
                                        label={t('Consanguinity Level')}
                                        errors={errors}
                                        touched={touched}
                                        handleOnChange={handleOnChange}
                                    >
                                        <option key="consanguinityNotSelected" value="">{t('Not Selected')}</option>
                                                {consanguinityLevelList.map(current => {
                                                    return (<option key={current.code} value={current.code}>{t(current.description)}</option>);
                                                    })}
                                    </FormField>
                                    
                                </Col>
                            </Row>
                        </div>
                        <div className="modal-footer button-items mt-1">
                            <Button type='submit' color="primary" className="button"><i className="mdi mdi-content-save me-1"></i>{t('Save')}</Button>
                            <Button color="light" className="button mr-1" onClick={() => { onCancel(); }}><i className="mdi mdi-close-thick me-1"></i>{t('Cancel')}</Button>
                        </div>
                    </Form>
                )}
                </Formik>
                      
            </Modal>
        </React.Fragment>
     );
}

MembershipBeneficiaryForm.propTypes = {
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func,
    isOpen: PropTypes.bool,
    beneficiary: PropTypes.any
}
 
export default MembershipBeneficiaryForm;