import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Row, Col, Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { toDateStringFormat } from "helpers/utils";

const LaboratoryOrderCard = ({ order, datetime, onRedirectDetail }) => {
  const { t } = useTranslation();
  const {
    orderCode,
    completionStatus,
  } = order;


  const getCompletionStatus = completionStatus => {
    switch (completionStatus) {
      case "PEN":
        return "Pending";
      case "DON":
        return "Done";
      case "FIN":
        return "Done";
      case "WAI":
        return "Waiting List";
      case "CAN":
        return "Cancelled";
      default:
        return completionStatus;
    }
  };

  return (
    <Col sm={6} md={4} lg={4}>
      <Card>
        <CardBody>
          <Row>
            <Col sm={8}>
              <div className="float-start">
                {orderCode ? <strong>No. {orderCode}</strong> : null}
              </div>
            </Col>
            <Col sm={8}>
              <div className="float-start">#{order.id}</div>
            </Col>
            <Col sm={4}>
              <div className="float-end">{toDateStringFormat(datetime)}</div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col sm={12}>
              <div className="float-end">
                <strong>{t(getCompletionStatus(completionStatus))}</strong>
              </div>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col sm={12}>
              <div className="align-middle float-end text-right mt-2">
                {order.laboratoryOrderResults.length > 0 ?
                <Button
                  color="light"
                  className="button"
                  onClick={() => {
                    onRedirectDetail(order);
                  }}
                >
                  {t("View Detail")} <i className="mdi mdi-arrow-right" />
                </Button>
                  : ""
                }
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

LaboratoryOrderCard.propTypes = {
  order: PropTypes.object,
  datetime: PropTypes.string,
  onRedirectDetail: PropTypes.func,
};

export default LaboratoryOrderCard;
