import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, CardImg, Button } from "reactstrap";
import { numberFormat } from "helpers/utils";
import { useTranslation } from "react-i18next";
import image from 'assets/images/vitae/Logos-vitae.png'

const ProductSearchCard = ({ product, getCurrencySymbol, onSelect }) => {
  const { t } = useTranslation();

  const getProductPicture = () => {
    let picture =
    product.productMedias && product.productMedias.length > 0 ? product.productMedias[0] : null;

    return picture ? picture.filePath : "";
  };

  return (
    <React.Fragment>
      <Col xs={12} sm={6} md={4} xl={3}>
        <Card className="plan-box">
          <CardBody className="p-2">
            <CardImg
              className="img-fluid product-image"
              src={getProductPicture()}
              onError={(evt) => (evt.target.onerror = null, evt.target.src = image)}
              alt="Product"
            />
            <div className="d-flex pt-2">
              <div className="flex-grow-1">
                <p className="text-muted">
                  {product.productCategories && product.productCategories.map(productCategory => (
                    <span key={`product-category-${productCategory.id}`}>
                      {productCategory.category.name}
                    </span>
                  ))}
                </p>
                <h5>{product.name}</h5>
              </div>
            </div>
            <div className="py-1">
              <h2>
                {getCurrencySymbol(product.currencyCode)}{" "}
                {numberFormat(product.price)}
              </h2>
            </div>
          </CardBody>
          <CardBody className="text-center">
            <Button
              color="primary"
              className="button"
              onClick={() => {
                onSelect(product);
              }}
            >
              {t("View Detail")} <i className="mdi mdi-arrow-right me-1"></i>
            </Button>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  );
};

ProductSearchCard.propTypes = {
  product: PropTypes.object.isRequired,
  getCurrencySymbol: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
};

export default ProductSearchCard;

