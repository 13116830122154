import { call, put, takeEvery } from "redux-saga/effects"

import {
  GET_SAMPLE_COLLECT_LIST,
  GET_SAMPLE_COLLECT_DETAIL,
  UPDATE_SAMPLE_PICKUP_STATUS,
} from "./actionTypes"

import {
  getSampleCollectListSuccess,
  getSampleCollectListFail,
  getSampleCollectDetailSuccess,
  getSampleCollectDetailFail,
  updateSamplePickupStatusSuccess,
  updateSamplePickupStatusFail
} from "./actions"

//API Calls helper
import {
  httpGetSampleCollectDetail,
  httpGetSampleCollectList,
  httpUpdateSamplePickupStatus
} from "../../helpers/Api/api-helper"

import { hideLoadingAlert, showLoadingAlert, showWarningAlert, showSuccessAlert } from "store/actions"
import { ALERT_MESSAGE } from "constants/constants"

function* fetchSampleCollects(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(httpGetSampleCollectList, request.payload)
    yield put(getSampleCollectListSuccess(response))
    yield put(hideLoadingAlert())
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_LIST_ERROR))
    yield put(getSampleCollectListFail(error))
  }
}

function* fetchSampleCollectDetail(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(httpGetSampleCollectDetail, request.payload)
    yield put(getSampleCollectDetailSuccess(response))
    yield put(hideLoadingAlert())
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.GET_DETAIL_ERROR))
    yield put(getSampleCollectDetailFail(error))
  }
}

function* fetchSamplePickupStatus(request) {
  try {
    yield put(showLoadingAlert());
    const response = yield call(httpUpdateSamplePickupStatus, request.payload)
    yield put(updateSamplePickupStatusSuccess(response))
    yield put(hideLoadingAlert())
    yield put(showSuccessAlert(ALERT_MESSAGE.UPDATED))
  } catch (error) {
    yield put(hideLoadingAlert())
    yield put(showWarningAlert(ALERT_MESSAGE.UPDATE_ERROR))
    yield put(updateSamplePickupStatusFail(error))
  }
}

function* sampleCollectsSaga() {
  yield takeEvery(GET_SAMPLE_COLLECT_LIST, fetchSampleCollects)
  yield takeEvery(GET_SAMPLE_COLLECT_DETAIL, fetchSampleCollectDetail)
  yield takeEvery(UPDATE_SAMPLE_PICKUP_STATUS, fetchSamplePickupStatus)
}

export default sampleCollectsSaga;
