import { CLEAR_BUSINESS_UNITS_STATE, 
         GET_BUSINESS_UNIT_LIST, 
         GET_BUSINESS_UNIT_LIST_FAIL, 
         GET_BUSINESS_UNIT_LIST_SUCCESS } from "./actionTypes"

//BusinessUnits
export const clearBusinessUnit = () => ({
    type: CLEAR_BUSINESS_UNITS_STATE
  })

  export const getBusinessUnits = (request) => ({
    type: GET_BUSINESS_UNIT_LIST,
    payload: request
  })
  
  export const getBusinessUnitsSuccess = invoice => ({
    type: GET_BUSINESS_UNIT_LIST_SUCCESS,
    payload: invoice,
  })
  
  export const getBusinessUnitsFail = error => ({
    type: GET_BUSINESS_UNIT_LIST_FAIL,
    payload: error,
  })