import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Button, Card, CardBody, Col, FormGroup, Label, Row } from "reactstrap";
import { useTranslation } from "react-i18next";
import { toDateISOStringFormat } from "helpers/utils";
import {
  clearPromotionList,
  deletePromotion,
  getPromotionsList,
  showSuccessAlert,
} from "store/actions";
import { businessUnitTypes, DEFAULT_COMPANY } from "constants/list-types";
import { ALERT_MESSAGE } from "constants/constants";
import { promotionSectionTypes } from 'constants/list-types';
import { toDateStringFormat } from 'helpers/utils';

const PromotionsList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const node = React.createRef();
  const history = useHistory();

  const [showDelete, setShowDelete] = useState(false);
  const [businessUnitType, setBusinessUnitType] = useState('');
  const [promotion, setPromotion] = useState(null);
  const { SearchBar } = Search;

  const promotionsList = useSelector(state => state.promotionsList.promotions);
  const promotionDeleted = useSelector(state => state.promotionsList.deleted);

  const [pageOptions, setPageOptions] = useState({
    sizePerPage: 10,
    totalSize: 0,
    custom: true,
  });

  useEffect(() => {
    dispatch(clearPromotionList());
    loadPromotions();
  }, []);

  useEffect(() => {
    if (promotionDeleted) {
      dispatch(
        showSuccessAlert(ALERT_MESSAGE.DELETED, () => {
          loadPromotions(businessUnitType);
        })
      );
    }
  }, [promotionDeleted]);

  useEffect(() => {
    if (promotionsList) {
      setPageOptions({
        ...pageOptions,
        totalSize: promotionsList.length,
      });
    }
  }, [promotionsList]);

  const loadPromotions = (businessUnit) => {
    dispatch(
      getPromotionsList({
        companyId: DEFAULT_COMPANY
      })
    );
  }

  const findCodeInList = (code, list) => {
    const value = list.find(current => current.code == code);
    return value ? value.value : code;
}

  const columns = [
    {
      text: t("Picture"),
      dataField: "picture",
      sort: false,
      formatter: (cellContent, row) => (
        <React.Fragment>
          <img className="avatar" height={100} src={row.picture} />
        </React.Fragment>
      ),
    },
    {
      text: t("Status"),
      dataField: "status",
      sort: true,
      formatter: (cellContent, row) => {
        return <React.Fragment>{row.status === 'ACT' ? t('Published') : t('Draft')}</React.Fragment>;
      },
    },
    {
      text: t("Display Section"),
      dataField: "sectionType",
      sort: true,
      formatter: (cellContent, row) => {
        return <React.Fragment>{t(findCodeInList(row.sectionType, promotionSectionTypes))}</React.Fragment>;
      },
    },
    {
      text: t("Date"),
      dataField: "date",
      sort: true,
      formatter: (cellContent, row) => {
        return <React.Fragment>{toDateStringFormat(row.fromDate)} - {toDateStringFormat(row.toDate)}</React.Fragment>;
      },
    },
    {
      dataField: "menu",
      isDummyField: true,
      editable: false,
      text: "",
      formatter: (cellContent, row) => (
        <div className="d-flex gap-3">
          <Button color="success" type="button" onClick={() => editRow(row)}>
            {t('View Detail')} 
            <i
              className="mdi mdi-arrow-right"
              id="edittooltip"
            />
          </Button>
          {/*
          <Link className="text-danger" to="#">
            <i
              className="mdi mdi-delete font-size-18"
              id="deletetooltip"
              onClick={() => deleteRow(row)}
            ></i>
          </Link>*/}
        </div>
      ),
    },
  ];

  const onPaginationPageChange = page => {
    if (
      node &&
      node.current &&
      node.current.props &&
      node.current.props.pagination &&
      node.current.props.pagination.options
    ) {
      node.current.props.pagination.options.onPageChange(page);
    }
  };

  const defaultSorted = [
    {
      dataField: "id", // if dataField is not match to any column you defined, it will be ignored.
      order: "desc", // desc or asc
    },
  ];

  const editRow = row => {
    history.push(`/management-promotions-detail/${row.id}`);
  };

  const deleteRow = row => {
    setPromotion(row);
    setShowDelete(true);
  };

  const onDelete = () => {
    setShowDelete(false);
    dispatch(deletePromotion(promotion));
  };

  const handleOnChange = e => {
    setBusinessUnitType(e.target.value);
  }

  return (
    <React.Fragment>
      {showDelete ? (
        <SweetAlert
          title={t("confirm.delete")}
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          confirmBtnText={t("Yes")}
          cancelBtnText={t("No")}
          onConfirm={() => {
            onDelete();
          }}
          onCancel={() => {
            setShowDelete(false);
          }}
        ></SweetAlert>
      ) : null}

      {/*<Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <div className="form-inline">
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label>{t("Business Unit")}</Label>
                      <select
                        className={"form-select"}
                        name="businessUnitType"
                        value={businessUnitType}
                        onChange={handleOnChange}>
                        {businessUnitTypes.map(current => {
                          return (<option key={current.code} value={current.code}>{t(current.value)}</option>);
                        })}

                      </select>
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <Button
                      type="button"
                      color="primary"
                      className="button mt-button"
                      onClick={() => { loadPromotions(businessUnitType); }}
                    >
                      <i className="bx bx-search-alt me-1"></i>
                      {t("Search")}
                    </Button>
                  </Col>
                </Row>
              </div>
            </CardBody>
          </Card>

        </Col>
                      </Row>*/}
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>

              <PaginationProvider
                pagination={paginationFactory(pageOptions)}
                keyField="id"
                columns={columns}
                data={promotionsList}
              >
                {({ paginationProps, paginationTableProps }) => (
                  <ToolkitProvider
                    keyField="id"
                    columns={columns}
                    data={promotionsList}
                    search
                  >
                    {toolkitprops => (
                      <React.Fragment>
                        <Row className="mb-2">
                          <Col sm={4}>
                            <div className="search-box ms-2 mb-2 d-inline-block">
                              <div className="position-relative">
                                <SearchBar
                                  {...toolkitprops.searchProps}
                                  placeholder={t("Search")}
                                />
                                <i className="bx bx-search-alt search-icon" />
                              </div>
                            </div>
                          </Col>
                          <Col sm="8"></Col>
                        </Row>
                        <Row>
                          <Col xl={12}>
                            <div className="table-responsive">
                              <BootstrapTable
                                {...toolkitprops.baseProps}
                                {...paginationTableProps}
                                defaultSorted={defaultSorted}
                                classes={
                                  "table align-middle table-nowrap table-hover"
                                }
                                bordered={false}
                                striped={false}
                                responsive
                                ref={node}
                              />
                            </div>
                          </Col>
                        </Row>
                        <Row className="align-items-md-center mt-30">
                          <Col className="pagination pagination-rounded justify-content-end mb-2">
                            <PaginationListStandalone {...paginationProps} />
                          </Col>
                        </Row>
                      </React.Fragment>
                    )}
                  </ToolkitProvider>
                )}
              </PaginationProvider>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PromotionsList;

