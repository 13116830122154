import {
  GET_FEE_LIST,
  GET_FEE_LIST_FAIL,
  GET_FEE_LIST_SUCCESS,
  SAVE_FEE_LIST,
  SAVE_FEE_LIST_FAIL,
  SAVE_FEE_LIST_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  feeList: [],
  saved: false,
  error: {},
  loading: false
}

const feeLists = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_FEE_LIST:
      return {
        ...state,
        feeList: [],
        loading: true,
      }         
    case GET_FEE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        feeList: action.payload,
      }
    case GET_FEE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
      case SAVE_FEE_LIST:
      return {
        ...state,
        saved: false,
        loading: true,
      }         
    case SAVE_FEE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        saved: true,
      }
    case SAVE_FEE_LIST_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }
    default:
      return state
  }
}

export default feeLists;
