import React, { useCallback, useEffect } from "react";
import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import AffiliatedSearch from "components/Common/AffiliatedSearch/affiliated-search";
import { useDispatch } from "react-redux";
import { clearGetAffiliated } from "store/actions";
import PageBody from "components/Common/PageLayout/page-body";

const PatientSearchPage = () => {

  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const onSelectAffiliated = useCallback((affiliated) => {
    history.push(`/laboratory-patient-exam-historical/${affiliated.accountCode}`);
  });

  useEffect(() => {
    dispatch(clearGetAffiliated());
  }, []);

  return (
    <React.Fragment>
      <PageBody
        title="Exams Historical"
        actions={(
          <Button
            color="light"
            className="button"
            onClick={() => { history.push('/dashboard') }}>
            <i className="mdi mdi-arrow-left me-1"></i>{t('Back')}
          </Button>
        )}
      >
        <AffiliatedSearch
          onSelectAffiliated={onSelectAffiliated}
          flagType={"examHistorical"}
        />
      </PageBody>
    </React.Fragment>
  );
}

export default PatientSearchPage;
