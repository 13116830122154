import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MetaTags from "react-meta-tags";
import { withRouter } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Link } from "react-router-dom";
import * as moment from "moment";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  Modal,
  Text,
  Spinner,
  Table,
  Label
} from "reactstrap";
import { getAffiliatedOrderList } from "store/Affiliated/actions"
import { saveInvoiceDetailDelivery } from "store/AffiliatedInvoice/actions"
import { Formik, Field, Form, ErrorMessage } from "formik";
import { joinSpans } from "@fullcalendar/react";

class CustomerOrderHistory extends Component {

  constructor(props) {
    super(props);
    this.node = React.createRef();

    this.state = {
      viewmodal: false,
      modal: false,
      modal_large: false,  
      selectedInvoice: null,    
      orders: [],
      order: "",
      currentUserAccount: null,
      deleteModal: false,
      tableColumns: [
        {
          dataField: "documentNumber",
          text: "No.",
          sort: true,
          formatter: (cellContent, row) => (
            <Link to="#" className="text-body fw-bold">
              {row.id}
            </Link>
          ),
        },
        {
          dataField: "date",
          text: "Fecha",
          sort: true,
          formatter: (cellContent, row) => this.handleValidDate(row.date),
        },
        {
          dataField: "businessUnitType",
          text: "Tipo",
          sort: true,
          formatter: (cellContent, row) => (
            <>
            {row.businessUnitType && row.businessUnitType === 'MEM' ? 
              'Membresia' : 
                (row.businessUnitType && row.businessUnitType === 'LAB' ? 
                  'Laboratorio' : null)}
            </>
          )
        },
        {
          dataField: "detail",
          text: "Detalle",
          sort: true,
          formatter: (cellContent, row) => (
            row.detailList && row.detailList.length > 0 ?
              (row.businessUnitType && row.businessUnitType === 'LAB' ? row.detailList[0].description + " / " + (row.detailList[0].quantity > 1 ? row.detailList[0].quantity  + " pruebas" : row.detailList[0].quantity + " prueba") : row.detailList[0].description)
                : null
          ),
        },        
        {
          dataField: "totalAmount",
          text: "Total",
          sort: true,
          style: {
            textAlign: 'right'
          },
          formatter: (cellContent, row) => (
            <>
            {row.totalAmount.toFixed(2) + " " + row.currencyCode}
            </>            
          ),
        },
        {
          dataField: "paymentStatus",
          text: "Pago",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              {row.paymentStatus === 'PAI' ? (
                <Badge
                  className={"font-size-12 badge-soft-success"}
                  color={"success"}
                  pill
                >
                  REALIZADO
                </Badge>
              ) : (
                <Badge
                  className={"font-size-12 badge-soft-danger"}
                  color={"danger"}
                  pill
                >
                  PENDIENTE
                </Badge>

              )}            
            </>
          ),
        },
        {
          dataField: "crossReference",
          text: "Factura",
          sort: true,
          formatter: (cellContent, row) => (
            <>
              {row.crossReference && row.crossReference.trim().length > 0 ? (
                <Badge
                  className={"font-size-12 badge-soft-success"}
                  color={"success"}
                  pill
                >
                  REALIZADA
                </Badge>
              ) : (
                <Badge
                  className={"font-size-12 badge-soft-danger"}
                  color={"danger"}
                  pill
                >
                  PENDIENTE
                </Badge>

              )}            
            </>
          ),
        },        
        {
          dataField: "view",
          isDummyField: true,
          text: "",
          sort: false,
          formatter: (cellContent, row) => (
            <>
              {row.paymentStatus === 'PAI' && row.businessUnitType === 'LAB' ? (
                <>
                  <Link to={"/membership-customer-order/" + row.id}>
                    <Button
                      type="button"
                      color="primary"
                      className="btn-sm btn-rounded"
                    >
                      Ver
                    </Button>              
                  </Link>&nbsp;
                  <Button
                    type="button"
                    onClick={()=> { this.showModal(row.id); }}
                    color="secondary"
                    className="btn-sm btn-rounded"
                  >
                    Registrar Prueba
                  </Button>              
                </>
              ) : (
                <Link to={"/membership-customer-order/" + row.id}>
                  <Button
                    type="button"
                    color="primary"
                    className="btn-sm btn-rounded"
                  >
                    Ver
                  </Button>              
                </Link>
              )}          


            </>

          ),
        }
      ],
    };
  }

  componentDidMount() {
    const {
      match: { params },
      onGetAffiliatedOrderList,
    } = this.props
    if (params && params.id) {
      this.setState({currentUserAccount: params.id});
      onGetAffiliatedOrderList(params.id)
    }
  }

  showModal(invoiceId) {
    console.log("showModal...!");
    const { affiliatedOrderList } = this.props;
    if(affiliatedOrderList && affiliatedOrderList.length > 0){
      const invoice = affiliatedOrderList.find((item) => {
        return item.id == invoiceId;
      });
      if(invoice){
        this.setState({ selectedInvoice: invoice });
        this.setState(prevState => ({
          modal_large: !prevState.modal_large,
        }));  
      }  
    }
  }

  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }));
  }

  handleOrderClicks = () => {
    this.setState({ order: "", isEdit: false });
    this.toggle();
  };

  toggleViewModal = () => {
    this.setState(prevState => ({
      viewmodal: !prevState.viewmodal,
    }));
  };

  onPaginationPageChange = page => {
    if (
      this.node &&
      this.node.current &&
      this.node.current.props &&
      this.node.current.props.pagination &&
      this.node.current.props.pagination.options
    ) {
      this.node.current.props.pagination.options.onPageChange(page);
    }
  };

  handleOrderClick = arg => {
    const order = arg;

    this.setState({
      order: {
        id: order.id,
        orderId: order.orderId,
        billingName: order.billingName,
        orderdate: order.orderdate,
        total: order.total,
        paymentStatus: order.paymentStatus,
        paymentMethod: order.paymentMethod,
        badgeclass: order.badgeclass,
      },
      isEdit: true,
    });

    this.toggle();
  };

  handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD/MM/yyyy");
    return date1;
  };

  handleBack = () => {
    this.props.history.goBack();
  }

  addNewDelivery = (values) => {
    const {
      match: { params },
      onAddNewTest,
    } = this.props
    const delivery = {
        invoiceId: this.state.selectedInvoice.id,
        invoiceDetailId: this.state.selectedInvoice.detailList[0].id,
        accountCode: this.state.currentUserAccount,
        email: null,
        identificationTypeId: 1,
        identification: values.identification,
        fullname: values.fullName,
        observations: values.observations,
        branch: values.deliveryPlace,

        receiverPersonIdentification: values.identification,
        receiverPersonFullname: values.fullName,
        deliveryPlace: values.deliveryPlace
    };


    const invoiceToAlter = this.state.selectedInvoice;
    if(invoiceToAlter.detailList && invoiceToAlter.detailList.length > 0) {
      if(invoiceToAlter.detailList[0].detailDeliveryList){
        invoiceToAlter.detailList[0].detailDeliveryList.push(delivery);
      } else {
        invoiceToAlter.detailList[0].detailDeliveryList = [];
        invoiceToAlter.detailList[0].detailDeliveryList.push(delivery);
      }
    }

    this.setState({selectedInvoice: invoiceToAlter});

    //console.log(delivery);
    onAddNewTest(delivery, this.props.history);
  };  

  render() {
    const { affiliatedOrderList } = this.props;

    const pageOptions = {
      sizePerPage: 10,
      totalSize: affiliatedOrderList.length,
      custom: true,
    };

    const defaultSorted = [
      {
        dataField: "orderId",
        order: "desc",
      },
    ];

    const selectRow = {
      mode: "checkbox",
    };

    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Vitae - Portal</title>
          </MetaTags>
          <Container fluid>
            <Row>
              <Col xl="9">
              <div className="mb-2"><Link to="/membership-customer-search" className="text-body fw-bold">Buscar</Link><i className="mdi mdi-chevron-right" /><Link className="text-body fw-bold">Afiliado</Link><i className="mdi mdi-chevron-right" /></div>
              <h2 className="mb-2 font-size-18">Historial de transacciones</h2>
              </Col>
              <Col xl="3" className="mb-2 align-right">
                  <Button
                      type="button"
                      color="secondary"                      
                      onClick={this.handleBack}
                    >
                      Regresar
                  </Button>                
              </Col>
            </Row>

            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <div className="text-center mt-2 mb-2">
                        {this.props.loading ? <Spinner animation="border" style={{ width: "3rem", height: "3rem" }}/> : null}
                    </div>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions || [])}
                      keyField="id"
                      columns={this.state.tableColumns || []}
                      data={affiliatedOrderList || []}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          data={affiliatedOrderList}
                          columns={this.state.tableColumns || []}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <div className="table-responsive">
                                <BootstrapTable
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  responsive
                                  defaultSorted={defaultSorted}
                                  bordered={false}
                                  striped={false}
                                  selectRow={selectRow}
                                  classes={
                                    "table align-middle table-nowrap table-check"
                                  }
                                  headerWrapperClasses={"table-light"}
                                  ref={this.node}
                                />
                              </div>
                              <div className="pagination pagination-rounded justify-content-end mb-2">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>

          <Modal
            size="xl"
            isOpen={this.state.modal_large}
            toggle={this.tog_large}
          >
            <div className="modal-header">
              <h5
                className="modal-title mt-0"
                id="myLargeModalLabel"
              >
                Registrar Prueba
              </h5>
              <button
                onClick={() =>
                  this.setState({ modal_large: false })
                }
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">

            {this.state.selectedInvoice && this.state.selectedInvoice.detailList 
                    && this.state.selectedInvoice.detailList.length > 0 
                    && this.state.selectedInvoice.detailList[0].detailDeliveryList 
                    && this.state.selectedInvoice.detailList[0].detailDeliveryList.length < this.state.selectedInvoice.detailList[0].quantity ? <>

                    <div className="mb-10">
                      <span>{
                        this.state.selectedInvoice.detailList[0].quantity + ' pruebas disponibles para el afiliado.'}</span>
                      <hr></hr>
                      <Formik
                            enableReinitialize={true}
                            initialValues={{
                              identification: '',
                              fullName: '',
                              observations: '',
                              deliveryPlace: '0'
                            }}
                            onSubmit={values => {
                              this.addNewDelivery(values);
                            }}
                          >

                          <Form className="row row-cols-lg-auto g-3 align-items-center">

                            <Col xs={12}>
                              <Label for="identification" className="form-label">Identificación</Label>
                              <Field id="identification" name="identification" type="text" placeholder="Identificación" className="form-control"/>
                            </Col>

                            <Col xs={12}>
                              <Label for="fullName" className="form-label">Nombre completo</Label>
                              <Field id="fullName" name="fullName" type="text" placeholder="Nombre completo" className="form-control"/>
                            </Col>

                            <Col xs={12}>
                              <Label for="observations" className="form-label">Observaciones</Label>
                              <Field id="observations" name="observations" type="text" placeholder="Observaciones" className="form-control"/>
                            </Col>

                            <Col xs={12}>
                              <Label for="deliveryPlace" className="form-label">Sede</Label>
                              <Field as="select" id="deliveryPlace" className="form-control" name="deliveryPlace">
                                  <option value="">Sede...</option>
                                  <option value="Escazú">Escazú</option>
                                  <option value="Pinares">Pinares</option>
                                  <option value="Sabana">Sabana</option>
                              </Field>
                            </Col>

                            <Col xs={12}>
                              <button type="submit" className="btn btn-primary w-md">Registrar</button>{' '}
                              <button type="button" className="btn btn-secondary w-md" 
                                onClick={() =>
                                  this.setState({ modal_large: false })
                                }>Cancelar</button>
                                {this.props.loading ? <>{"  "} <Spinner animation="border" style={{ width: "2rem", height: "2rem" }}/></> : null}
                            </Col>
                          </Form>

                      </Formik>
                    </div>
                    <hr></hr>
                    
                    </> :
                    null }

              {this.state.selectedInvoice && this.state.selectedInvoice.detailList 
                    && this.state.selectedInvoice.detailList.length > 0 
                    && this.state.selectedInvoice.detailList[0].detailDeliveryList 
                    && this.state.selectedInvoice.detailList[0].detailDeliveryList.length > 0 ? <>
                    
                    <div className="table-responsive">

                      <Table className="table mb-0">
                        <thead>
                          <tr>
                            <th>Identificaci&oacute;n</th>
                            <th>Nombre</th>
                            <th>Sede</th>
                            <th>Observaciones</th>
                          </tr>
                        </thead>
                        <tbody>

                        {this.state.selectedInvoice.detailList[0].detailDeliveryList.map((delivery, i) => (
                            <tr key={'delivery' + i}>
                              <td>{delivery.receiverPersonIdentification}</td>
                              <td>{delivery.receiverPersonFullname}</td>
                              <td>{delivery.deliveryPlace}</td>
                              <td>{delivery.observations}</td>
                            </tr>
                        ))}

                        </tbody>
                      </Table>
                    </div>
                    </> : null}

            </div>
          </Modal>

        </div>
      </React.Fragment>
    );
  }
}

CustomerOrderHistory.propTypes = {  
  affiliatedOrderList: PropTypes.array,
  onGetAffiliatedOrderList: PropTypes.func,
  onAddNewTest: PropTypes.func,
  match: PropTypes.object,
  t: PropTypes.any,
  className: PropTypes.any,
  history: PropTypes.any,
  loading: PropTypes.bool,
};

const mapStateToProps = ({ affiliateds }) => ({
  loading: affiliateds.loading,
  affiliatedOrderList: affiliateds.affiliatedOrderList,
})

const mapDispatchToProps = dispatch => ({
  onGetAffiliatedOrderList: accountCode => dispatch(getAffiliatedOrderList(accountCode)),
  onAddNewTest: (delivery, history) => dispatch(saveInvoiceDetailDelivery(delivery, history))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CustomerOrderHistory));
