import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { Input, InputGroup } from "reactstrap";

const SearchBox = ({ activeTab, onSearch, onClear, placeholder = "Type for search" , keyText, criteriaText}) => {
  const { t } = useTranslation();
  const [searchKey, setSearchKey] = useState("");

  const keyPress = e => {
    const { key } = e;
    setSearchKey(e.target.value);
    if (key === "Enter") {
      onSearch(searchKey);
    }
  };

  const onChange = e => {
    setSearchKey(e.target.value);
    onClear();
  };

  useEffect(() => {
    if(keyText && activeTab === "1" && criteriaText && criteriaText === "id"){
      setSearchKey(keyText);
    }
    if(keyText && activeTab === "2" && criteriaText && criteriaText === "name"){
      setSearchKey(keyText);
    }    
  }, [keyText]);

  useEffect(() => {
    setSearchKey('');
    if(keyText && activeTab === "1" && criteriaText && criteriaText === "id"){
      setSearchKey(keyText);
    }
    if(keyText && activeTab === "2" && criteriaText && criteriaText === "name"){
      setSearchKey(keyText);
    }
  }, [activeTab]);

  return (
    <React.Fragment>
      <div className="py-4">
        <div className="">
          <InputGroup>
            <Input
              autoComplete="off"
              onKeyUp={keyPress}
              value={searchKey}
              onChange={onChange}
              id="search-user"
              type="text"
              className="form-control"
              placeholder={t(placeholder) + "..."}
            />
            <div
              className="input-group-text dashboard-option bg-primary"
              onClick={() => {
                onSearch(searchKey);
              }}
            >
              <i className="bx bx-search-alt search-icon text-white" />
            </div>
          </InputGroup>
        </div>
      </div>
    </React.Fragment>
  );
};

SearchBox.propTypes = {
  activeTab: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  keyText: PropTypes.string,
  criteriaText: PropTypes.string,
};

export default SearchBox;

