import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'
import { Button, Modal } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getAllMedicalServiceList } from 'store/actions';
import { numberFormat } from 'helpers/utils';
import DataTable from '../DataTable/datatable';

const PrescriptionServiceForm = ({ isOpen, addService, services, onCancel, getCurrencySymbol }) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [result, setResult] = useState([]);
    const [selectedMedicalServices, setSelectedMedicalServices] = useState([]);
    const medicalServices = useSelector(
        state => state.medicalServices.medicalServiceList
    );

    const [pageOptions, setPageOptions] = useState({
        sizePerPage: 5,
        totalSize: 0,
        custom: true,
    });

    useEffect(() => {
        dispatch(getAllMedicalServiceList());
    }, []);

    useEffect(() => {
        if (services) {
            let data = services.map(
                current => current.medicalService.id
            );
            setSelectedMedicalServices(data);
        }
    }, [services]);

    useEffect(() => {
        if (medicalServices) {
            setPageOptions({
                ...pageOptions,
                totalSize: medicalServices.length,
            });

            setResult(medicalServices.map(current => ({
                id: current.id,
                category: current.medicalCategory.description,
                serviceName: current.description,
                currencyCode: current.currencyCode,
                price: current.price,
                discount: current.discount,
                priceWithDiscount: current.priceWithDiscount
            })));
        }
    }, [medicalServices]);

    const columns = [
        {
            text: t("Category"),
            dataField: "category",
            sort: true,
            formatter: (cellContent, row) => (
                <React.Fragment>{row.category}</React.Fragment>
            ),
        },
        {
            text: t("Name"),
            dataField: "serviceName",
            sort: true,
            formatter: (cellContent, row) => (
                <React.Fragment>{row.serviceName}</React.Fragment>
            ),
        },
        {
            text: t("Price"),
            dataField: "price",
            sort: true,
            formatter: (cellContent, row) => (
                <React.Fragment>{getCurrencySymbol(row.currencyCode)}{numberFormat(row.price)}</React.Fragment>
            ),
        },
    ];

    const selectRow = {
        mode: "checkbox",
        selected: selectedMedicalServices,
        onSelect: (row, isSelect, rowIndex, e) => {
            
            if (isSelect) {
                setSelectedMedicalServices([...selectedMedicalServices, row.id]);
            } else {
                const copy = [...selectedMedicalServices];
                setSelectedMedicalServices([
                    ...copy.filter(current => current != row.id),
                ]);
            }
        },
        onSelectAll: (isSelect, rows, e) => {
            
            if (isSelect) {
                setSelectedMedicalServices([...selectedMedicalServices, rows]);
            } else {
                let copy = [...selectedMedicalServices];
                rows.map(row => (copy = copy.filter(current => current != row.id)));
                setSelectedMedicalServices([...copy]);
            }
        },
    };

    const onAddClick = evt => {
        evt.preventDefault();
        addService(medicalServices.filter(medicalService => selectedMedicalServices.some(current => current == medicalService.id)));
    }

    return (
        <React.Fragment>
            <Modal
                size='xl'

                isOpen={isOpen}
                toggle={() => { onCancel() }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {t('Medical Services')}
                    </h5>
                    <button
                        type="button"
                        onClick={() => onCancel()}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div className="modal-body">
                    <DataTable
                        columns={columns}
                        data={result}
                        keyField={'id'}
                        selectRow={selectRow}
                        pageSize={5}
                    />
                </div>

                <div className="modal-footer button-items mt-1">
                    <Button type='submit' color="primary" className="button" onClick={onAddClick}><i className="mdi mdi-content-save me-1"></i>{t('Save')}</Button>
                    <Button color="light" className="button mr-1" onClick={() => { onCancel(); }}><i className="mdi mdi-close-thick me-1"></i>{t('Close')}</Button>
                </div>

            </Modal>
        </React.Fragment>
    );
}

PrescriptionServiceForm.propTypes = {
    addService: PropTypes.func,
    services: PropTypes.array,
    onCancel: PropTypes.func,
    isOpen: PropTypes.bool,
    getCurrencySymbol: PropTypes.func.isRequired
}

export default PrescriptionServiceForm;