import React, { useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button, Container } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import ExamPatientDetailCard from './exam-patient-detail-card';
import { getLaboratoryOrderDetail } from 'store/actions';
import PageTitle from 'components/Common/PageLayout/page-title';
import PageHeader from 'components/Common/PageLayout/page-header';

const ExamPatientDetailPage = () => {

    const { id, account } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const order = useSelector(state => state.laboratoryOrders.orderDetail);

    useEffect(() => {
        if (id) {
            dispatch(getLaboratoryOrderDetail({ id: id }));
        }
    }, [id]);


    return (
        <React.Fragment>
            <div className="page-content">
                <PageTitle />
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <PageHeader title='Exams Historical'>
                        <Button
                            color="light"
                            className="button"
                            onClick={() => { 
                                history.goBack();//push(`/laboratory-patient-exam-historical/${account}`) 
                            }}
                        >
                            <i className="mdi mdi-arrow-left me-1"></i>{t('Back')}
                        </Button>
                    </PageHeader>

                    {order ? <ExamPatientDetailCard order={order} account={account} id={id} /> : null}
                </Container>
            </div>
        </React.Fragment>
    );

}

export default ExamPatientDetailPage;
