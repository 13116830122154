import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import ReactApexChart from 'react-apexcharts';


const ExamPatientChartCard = ({ resultValues }) => {

  const { t } = useTranslation();

  const getOptions = (resultValue) => {

    return {
      chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
      annotations: {
        position: 'front',
        yaxis: [
          {
            y: resultValue.validRangeFrom,
            borderColor: '#999',
            label: {
              show: true,
              text: `${t('Low')}: ${resultValue.validRangeFrom}`,
              position: 'bottom',
              textAnchor: 'end',
              offsetX: 40,
              offsetY: 24,
              style: {
                color: '#000000',
                background: 'transparent'
              }
            }
          },
          {
            y: resultValue.numericValue,
            borderColor: 'transparent',
            label: {
              show: true,
              text: `${t('Result')}: ${resultValue.numericValue}`,
              position: 'top',
              offsetX: 155,
              offsetY: -10,
              style: {
                color: '#000000',
                background: 'transparent'
              }
            }
          },
          {
            y: resultValue.validRangeTo,
            borderColor: '#999',
            label: {
              show: true,
              text: `${t('High')}: ${resultValue.validRangeTo}`,
              position: 'top',
              offsetX: 240,
              offsetY: 24,
              style: {
                color: '#000000',
                background: 'transparent'
              }
            }
          }
        ]
      },
      colors: [resultValue.alarmIndicator == 'N' ? 'var(--bs-primary)' : '#dc1212'],
      dataLabels: {
        enabled: false
      },
      stroke: {
        width: [3, 3],
        curve: "smooth",
        colors: [resultValue.alarmIndicator == 'N' ? 'var(--bs-primary)' : '#dc1212']
      },
      title: {
        text: undefined,
        align: "left"
      },
      grid: {
        row: {
          colors: ["transparent", "transparent"],
          opacity: 0.2
        },
        borderColor: "#f1f1f1",
      },
      markers: {
        style: "inverted",
        size: 6,
        shape: 'circle',
      },
      yaxis: {
        show: false,
        title: {
          text: ''
        },
        forceNiceScale: true,
        min: resultValue.validRangeFrom - 10,
        max: resultValue.validRangeTo > resultValue.numericValue ? resultValue.validRangeTo : resultValue.numericValue
      },
      xaxis: {
        show: false,
        labels: { show: false },
        axisBorder: { show: false },
        title: { text: '' },
        type: 'category',
        overwriteCategories: [t('Low'), t('Result'), t('High')]
      },
      legend: {
        position: "top",
        horizontalAlign: "right",
        floating: !0,
        offsetY: -25,
        offsetX: -5,
      },
      fill: {
        colors: [resultValue.alarmIndicator == 'N' ? 'var(--bs-primary)' : '#dc1212'],
        type: 'gradient',
        gradient: {
          type: 'vertical',
          shade: 'dark',
          gradientFromColors: ['#FFFFFF'],
          gradientToColors: [resultValue.alarmIndicator == 'N' ? 'var(--bs-primary)' : '#dc1212'],
          shadeIntensity: 1,
          opacityFrom: 0.5,
          opacityTo: 0.9,
          stops: [0, resultValue.validRangeFrom, resultValue.validRangeTo > resultValue.numericValue ? resultValue.validRangeTo : resultValue.numericValue]
        }
      },
      responsive: [
        {
          breakpoint: 600,
          options: {
            chart: {
              toolbar: { show: !1 }
            },
            legend: { show: !1 }
          },
        },
      ],
    };
  }

  const getResultStatusColor = resultValue => {
    if (resultValue.alarmIndicator === "Y") {
      return "#f46a6a";
    }
  }

  return (
    <React.Fragment>

      <Row>
        <Col md={3} lg={3}>
          <h3>{t('Test')}</h3>
        </Col>

        <Col md={2} lg={2}>
          <h3>{t('Result')}</h3>
        </Col>

        <Col md={2} lg={2}>
          <h3>{t('Unit')}</h3>
        </Col>

        <Col md={2} lg={2}>
          <h3>{t('Refference Range')}</h3>
        </Col>

        <Col md={3} lg={3}>
          <div className="text-primary">
            <h3>{t('Chart')}</h3>
          </div>
        </Col>
      </Row>
      <br></br><br></br>
      {

        resultValues.map((resultValue) => {

          const series = [{ name: resultValue.rangeDescription, data: [resultValue.validRangeFrom, resultValue.numericValue, resultValue.validRangeTo] }];

          return (
            <>
              <Row>
                <Col sm="3">
                  <h5 style={{ color: getResultStatusColor(resultValue) }}>{resultValue.rangeDescription ? `${resultValue.description}` : (resultValue.description ? resultValue.description : t('Information'))}</h5>
                </Col>
                <Col sm="2">
                  <h5 style={{ color: getResultStatusColor(resultValue) }}>{resultValue.numericValue}</h5>
                </Col>
                <Col sm="2">
                  <h5 style={{ color: getResultStatusColor(resultValue) }}>{resultValue.measurementType}</h5>
                </Col>
                <Col sm="2">
                  <h5 style={{ color: getResultStatusColor(resultValue) }}>{resultValue.rangeDescription}</h5>
                </Col>
                <Col sm="3">
                  <div className="text-primary">
                    <ReactApexChart
                      options={getOptions(resultValue)}
                      series={series}
                      type={resultValue.alarmIndicator == 'N' ? 'line' : 'line'}
                      height={150}
                      width={270}
                    />
                  </div>
                </Col>
              </Row>
              <hr></hr>

            </>
          )
        })
      }
    </React.Fragment>
  );
}

ExamPatientChartCard.propTypes = {
  resultValues: PropTypes.array.isRequired
}

export default ExamPatientChartCard;

















// import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
// import { Card, CardBody, Row, Col } from 'reactstrap';
// import { useTranslation } from 'react-i18next';
// import ReactApexChart from 'react-apexcharts';
// import ExamPatientChartOption from './exam-patient-chart-option';

// const ExamPatientChartCard = ({ resultValues }) => {

//   const { t } = useTranslation();

//   const [selectedResult, setSelectedResult] = useState({
//     validRangeFrom: 0,
//     validRangeTo: 0,
//     numericValue: 0
//   });

//   const [series, setSeries] = useState([
//     { name: "", data: [0, 0, 0] }
//   ]);

//   const getOptions = (validRangeFrom, numericValue, validRangeTo) => {

//     return {
//       chart: { zoom: { enabled: !1 }, toolbar: { show: !1 } },
//       annotations: {
//         position: 'front',
//         yaxis: [
//           {
//             y: validRangeFrom,
//             borderColor: '#999',
//             label: {
//               show: true,
//               text: `${t('Low')}: ${validRangeFrom}`,
//               position: 'bottom',
//               textAnchor: 'end',
//               offsetX: 40,
//               offsetY: 24,
//               style: {
//                 color: '#000000',
//                 background: 'transparent'
//               }
//             }
//           },
//           {
//             y: numericValue,
//             borderColor: 'transparent',
//             label: {
//               show: true,
//               text: `${t('Result')}: ${numericValue}`,
//               position: 'top',
//               offsetX: 86,
//               offsetY: -5,
//               style: {
//                 color: '#000000',
//                 background: 'transparent'
//               }
//             }
//           },
//           {
//             y: validRangeTo,
//             borderColor: '#999',
//             label: {
//               show: true,
//               text: `${t('High')}: ${validRangeTo}`,
//               position: 'top',
//               offsetX: 240,
//               offsetY: 40,
//               style: {
//                 color: '#000000',
//                 background: 'transparent'
//               }
//             }
//           }
//         ]
//       },
//       colors: [selectedResult.alarmIndicator == 'N' ? 'var(--bs-primary)' : '#dc1212'],
//       dataLabels: {
//         enabled: false
//       },
//       stroke: {
//         width: [3, 3],
//         curve: "smooth",
//         colors: [selectedResult.alarmIndicator == 'N' ? 'var(--bs-primary)' : '#dc1212']
//       },
//       title: {
//         text: undefined,
//         align: "left"
//       },
//       grid: {
//         row: {
//           colors: ["transparent", "transparent"],
//           opacity: 0.2
//         },
//         borderColor: "#f1f1f1",
//       },
//       markers: {
//         style: "inverted",
//         size: 6,
//         shape: 'circle',
//       },
//       yaxis: {
//         show: false,
//         title: {
//           text: ''
//         },
//         forceNiceScale: true,
//         min: selectedResult.validRangeFrom - 10,
//         max: selectedResult.validRangeTo > selectedResult.numericValue ? selectedResult.validRangeTo : selectedResult.numericValue
//       },
//       xaxis: {
//         show: false,
//         labels: { show: false },
//         axisBorder: { show: false },
//         title: { text: '' },
//         type: 'category',
//         overwriteCategories: [t('Low'), t('Result'), t('High')]
//       },
//       legend: {
//         position: "top",
//         horizontalAlign: "right",
//         floating: !0,
//         offsetY: -25,
//         offsetX: -5,
//       },
//       fill: {
//         colors: [selectedResult.alarmIndicator == 'N' ? 'var(--bs-primary)' : '#dc1212'],
//         type: 'gradient',
//         gradient: {
//           type: 'vertical',
//           shade: 'dark',
//           gradientFromColors: ['#FFFFFF'],
//           gradientToColors: [selectedResult.alarmIndicator == 'N' ? 'var(--bs-primary)' : '#dc1212'],
//           shadeIntensity: 1,
//           opacityFrom: 0.5,
//           opacityTo: 0.9,
//           stops: [0, selectedResult.validRangeFrom, selectedResult.validRangeTo > selectedResult.numericValue ? selectedResult.validRangeTo : selectedResult.numericValue]
//         }
//       },
//       responsive: [
//         {
//           breakpoint: 600,
//           options: {
//             chart: {
//               toolbar: { show: !1 }
//             },
//             legend: { show: !1 }
//           },
//         },
//       ],
//     };
//   }

//   const onSelectResult = result => {
//     setSeries([{ name: result.rangeDescription, data: [result.validRangeFrom, result.numericValue, result.validRangeTo] }]);
//     setSelectedResult(result);
//   }

//   useEffect(() => {
//     if (resultValues && resultValues.length > 0) {
//       onSelectResult(resultValues[0]);
//     }
//   }, [resultValues]);

//   return (
//     <React.Fragment>
//       <Card>
//         <CardBody>
//           <Row>
//             <Col md={4} lg={4}>
//               <h4>{t('Select the criteria')}</h4>
//               <ExamPatientChartOption
//                 resultValues={resultValues}
//                 onSelect={onSelectResult}
//                 selectedResult={selectedResult}
//               />
//             </Col>
//             <Col md={8} lg={8}>
//               <div className="text-primary">
//                 <h4>{t('Chart')}:{' '} {selectedResult.rangeDescription ? `${selectedResult.description} ${selectedResult.rangeDescription}`: selectedResult.description}</h4>
//                 {selectedResult ?
//                   <ReactApexChart
//                     options={getOptions(selectedResult.validRangeFrom, selectedResult.numericValue, selectedResult.validRangeTo)}
//                     series={series}
//                     type={selectedResult.alarmIndicator == 'N' ? 'line' : 'area'}
//                     height={300}
//                     width={280}
//                   />
//                   : null}
//               </div>
//             </Col>

//           </Row>

//         </CardBody>
//       </Card>
//     </React.Fragment>
//   );
// }

// ExamPatientChartCard.propTypes = {
//   resultValues: PropTypes.array.isRequired
// }

// export default ExamPatientChartCard;

