import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardText, Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import ExamPatientChartCard from '../Chart/exam-patient-chart-card';
import ExamPatientDesiredCard from '../Desired/exam-patient-desired-card';
import { useTranslation } from 'react-i18next';

const ExamPatientResultTabs = ({ order, result }) => {


  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState('1');
  const { laboratoryOrders } = order;
  const [rangeResultValues, setRangeResultValues] = useState([])
  const [desiredResultValues, setDesiredResultValues] = useState([])

  const getResult = () => {
    return laboratoryOrders[0].laboratoryOrderResults.find(item => item.id === result)
  }

  useEffect(() => {
    if (laboratoryOrders && laboratoryOrders.length > 0) {
      setRangeResultValues(getResult().laboratoryOrderResultValues.filter(current => current.displayType == 'RNG'));
      setDesiredResultValues(getResult().laboratoryOrderResultValues.filter(current => current.displayType == 'VAL'));
    }
  }, [laboratoryOrders]);

  useEffect(() => {
    setActiveTab(rangeResultValues.length > 0 ? "1" : "2");
  }, [rangeResultValues]);

  return (
    <React.Fragment>
      <Card>
        <CardBody>

          <Nav tabs className="nav-tabs-custom nav-justified">
            <NavItem>
              <NavLink
                disabled={rangeResultValues.length == 0}
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  setActiveTab("1");
                }}
              >

                <span className="d-none d-sm-block">{t('View normal range')}</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                disabled={desiredResultValues.length == 0}
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  setActiveTab("2");
                }}
              >

                <span className="d-none d-sm-block">{t('View desired values')}</span>
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab} className="p-3 text-muted">
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <CardText className="mb-0">
                    <ExamPatientChartCard resultValues={rangeResultValues} />
                  </CardText>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <CardText className="mb-0">
                    <ExamPatientDesiredCard resultValues={desiredResultValues} />
                  </CardText>
                </Col>
              </Row>
            </TabPane>

          </TabContent>
        </CardBody>
      </Card>
    </React.Fragment>
  );

}
ExamPatientResultTabs.propTypes = {
  order: PropTypes.object.isRequired,
  result: PropTypes.number.isRequired
}

export default ExamPatientResultTabs;


